import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Progress,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";

import React, { useCallback, useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import Speration from "./Sepration/Sepration";
import styles from "./Dashboard.module.scss";
import "../../../styles/_global.scss";
import inboxImg from "../../../assets/inbox.svg";
import companyPhoto from "../../../assets/companyPhoto.svg";
import getScreenSize from "../../../utils/getScreenSize";
import request from "../../../utils/axios";
import { useDispatch } from "react-redux";
import { setUserProfileInRedux } from "../../../redux/reducer/UserProfileReducer";

import applyLeave from "../../../assets/applyLeave.png";
import addReimbursement from "../../../assets/addReimbursement.png";
import viewPayslip from "../../../assets/viewPayslip.png";
import averageDuration from "../../../assets/AvgDurantionDashboard.svg";
import workingPolicy from "../../../assets/Attendance/workingPolicy.svg";
import {
  DownOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  WarningFilled,
} from "@ant-design/icons";
import totalLeave from "../../../assets/Attendance/totalLeave.svg";
import workingDays from "../../../assets/Attendance/workingDays.svg";
import fullView from "../../../assets/fullView.svg";
import moment from "moment";
import salaryDetail from "../../../assets/Dashboard/salaryDetail.png";
import LeaveDetail from "../../Inbox/Leave/LeaveDetail/LeaveDetail";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import cameraFaceCapture from "../../../assets/Attendance/cameraFaceCapture.svg";
import cameraFaceSuccess from "../../../assets/Attendance/cameraFaceSuccess.svg";
import cameraFaceError from "../../../assets/Attendance/cameraFaceError.svg";
import Webcam from "react-webcam";
import addFaceImg from "../../../assets/Attendance/addFace.svg";
import mannual from "../../../assets/Attendance/mannual.svg";
import { useNavigate } from "react-router-dom";
import update from "immutability-helper";
import { NoStyleItemContext } from "antd/es/form/context";
import totalAbsentMyTeam from "../../../assets/total_absent_my_team.svg";
import totalPresentMyTeam from "../../../assets/total_present_my_team.svg";
import wfhMyTeam from "../../../assets/wfh_my_team.svg";
import workPolicyDashboard from "../../../assets/wfhDashboard.svg";
import LeaveDashboard from "../../../assets/LeaveDashboard.svg";
import WorkingDayDashboard from "../../../assets/WorkingDayDashboard.svg";
import mobileCaptureFrame from "../../../assets/Attendance/mobileCaptureFrame.png";
import mobileErrorFrame from "../../../assets/Attendance/mobileErrorFrame.png";
import mobileSucessFrame from "../../../assets/Attendance/mobileSucessFrame.png";
import ContainerLayout from "../../../component/ContainerLayout/ContainerLayout";

function Dashboard() {
  const screenSize = getScreenSize();
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [sepration, setSepration] = useState(false);
  const section1 = [
    {
      label: "Apply Leave",
      image: applyLeave,
      key: 1,
      path: "/leave/apply-leave",
    },
    {
      label: "Add Reimbursement",
      image: addReimbursement,
      key: 2,
      path: "/payroll/reimbursement/form",
    },
    {
      label: "View Payslip",
      image: viewPayslip,
      key: 3,
      path: "/payroll/payroll-summary",
    },
  ];

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const myRequestMenuList = [
    {
      label: "Leaves",
      key: "leaves",
    },
    {
      label: "Reimbursements",
      key: "reimbursements",
    },
    {
      label: "Requisitions",
      key: "requisitions",
    },
  ];

  const [employeeCTC, setEmployeeCTC] = useState();
  const fetchEmployeeCTC = async () => {
    const data = await request({
      method: "get",
      url: `payroll/get_employee_ctc`,
    });
    setEmployeeCTC(data.data);
  };

  const [selectedInbox, setSelectedInbox] = React.useState("leave");

  const [personalAttendanceDetails, setPersonalAttendanceDetails] = useState({
    average_working_duration: "",
    total_leaves: "",
    total_present_days: "",
    work_policy: "",
    face_present: "",
    attendance_type: 5,
    policy_id: "",
  });

  const [specificPolicyDetails, setSpecificPolicyDetails] = useState({});

  const fetchData = async (page, page_size) => {
    const personalAttendanceDetails = await request({
      method: "get",
      url: `attendance/employee/attendance/stats/monthly/`,
    });
    let formattedTime;

    if (personalAttendanceDetails.data.average_working_duration === "0:00") {
      formattedTime = personalAttendanceDetails.data.average_working_duration;
    } else {
      const time = personalAttendanceDetails.data.average_working_duration;
      const [hours, minutes] = time.split(":").slice(0, 2);
      formattedTime = `${hours}:${minutes}`;
    }

    // setIsLogin(personalAttendanceDetails.data.is_checked_in_today)
    // setIsCheckOut(personalAttendanceDetails.data.is_checked_out_today)
    setPersonalAttendanceDetails({
      ...personalAttendanceDetails.data,
      average_working_duration: formattedTime,
    });
  };

  const renderStandardWeeklyOff = (data) => {
    return (
      <>
        <div className="renderStandardWeeklyOffDiv">
          <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>
            All Weeks
          </p>
          <div>
            {specificPolicyDetails?.week_off_entries
              ?.slice(0, 1)
              .map((weekDetail, dayIndex) => {
                return (
                  <div key={dayIndex}>
                    <div
                      style={{
                        display: "grid",
                        gap: "10px",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      {weekDays.map((day, index) => {
                        const selected =
                          weekDetail.week_off[index] === 1 ||
                          weekDetail.week_off[index] === 0.5;

                        return (
                          <div
                            className={
                              selected
                                ? weekDetail.week_off[index] === 0.5
                                  ? "selectedHalfDay"
                                  : "selectedFullDay"
                                : "disabledFullDay"
                            }
                          >
                            {day.slice(0, 2)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const renderCustomWeeklyOff = (data) => {
    return (
      <>
        {specificPolicyDetails?.week_off_entries?.map(
          (weekDetail, weekIndex) => (
            <div key={weekIndex}>
              <div className="renderCustomWeeklyOffContainerDiv">
                <p className="weekNumberText">Week {weekIndex + 1}</p>

                {/* <div className='selectWeeksContainer'> */}
                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  }}
                >
                  {weekDays.map((day, index) => {
                    const isDaySelected =
                      weekDetail.week_off[index] === 1 ||
                      weekDetail.week_off[index] === 0.5;

                    return (
                      <div
                        key={index}
                        className={
                          isDaySelected
                            ? weekDetail.week_off[index] === 0.5
                              ? "selectedHalfDay"
                              : "selectedFullDay"
                            : "disabledFullDay"
                        }
                      >
                        {day.slice(0, 2)}
                      </div>
                    );
                  })}
                </div>
                {/* </div> */}
              </div>
            </div>
          )
        )}
      </>
    );
  };

  const workPolicyContent = (
    <div>
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Working Policy
      </p>
      <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>
        {specificPolicyDetails?.policy_name}
      </p>
      {/* <p style={{ color: "#2D2D2D", fontSize: "16px", fontWeight: "600", margin: "0px" }}>Work Location</p>
        <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>Gurugram</p> */}
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Attendance Type
      </p>
      <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>
        {specificPolicyDetails?.attendance_type_office === 0 &&
          "Face Attendance"}
        {specificPolicyDetails?.attendance_type_office === 1 &&
          "Manual Attendance"}
        {specificPolicyDetails?.attendance_type_office === 2 &&
          "Automatic Attendance"}
        {specificPolicyDetails?.attendance_type_office === 3 &&
          "Manual with Geolocation"}
        {specificPolicyDetails?.attendance_type_office === 4 &&
          "Face with Geolocation"}
        {specificPolicyDetails?.attendance_type_office === 5 &&
          "Biometric Attendance"}
        {specificPolicyDetails?.attendance_type_office === 6 &&
          "Field Attendance"}
        {specificPolicyDetails?.attendance_type_office === 7 &&
          "Field + Face Attendance"}
      </p>
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Shifts Timing
      </p>
      <div style={{ display: "flex" }}>
        <p style={{ color: "#2D2D2D", fontSize: "13px", fontWeight: "600" }}>
          {/* S1 <span style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}> 9:00am to 7:00pm</span> */}
          {specificPolicyDetails?.is_standard_shift ? (
            // true
            <>
              {specificPolicyDetails?.shifts[0]?.timings?.map((item, index) => {
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <span
                      style={{
                        color: "#626262",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {item.start_time} to {item.end_time}
                    </span>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {specificPolicyDetails?.shifts?.map((item, index) => {
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p style={{ margin: "0px" }}>Week {index + 1}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {item.timings?.map((el, elIndex) => {
                        return (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p
                              style={{
                                color: "#626262",
                                fontSize: "13px",
                                fontWeight: "400",
                              }}
                            >
                              {el.start_time} to {el.end_time}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </p>
      </div>
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Weekly Off
      </p>
      {specificPolicyDetails?.is_week_off_standard
        ? renderStandardWeeklyOff()
        : renderCustomWeeklyOff()}
    </div>
  );

  const fetchSpecificPolicy = async () => {
    try {
      console.log(personalAttendanceDetails?.policy_id, "policy_id");
      const data = await request({
        method: "get",
        url: `attendance/policy/details/?policy_id=${personalAttendanceDetails?.policy_id}`,
      });
      setSpecificPolicyDetails(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [attendanceDetails, setAttendanceDetails] = useState({
    date: "",
    day: "",
    is_standard_shift: true,
    shifts: [],
    work_duration_hours: "",
  });

  const [attendanceDetailsShift, setAttendanceDetailsShift] = useState(1);

  const fetchAttendanceDetails = async () => {
    const data = await request({
      method: "get",
      url: `attendance/dashboard/stats`,
    });
    // console.log("speration", data?.is_resignation_present);
    setSepration(data?.is_resignation_present);
    if (data && data?.data) {
      setAttendanceDetails(data?.data);
    }
  };

  const [holidayData, setHolidayData] = useState([]);

  const fetchHolidayData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/m-web/holiday/list/all`,
      });
      if (data && data?.data) {
        setHolidayData(data?.data);
      }
    } catch (error) { }
  };

  const [myAppliedLeaveDetails, setMyAppliedLeaveDetails] = useState([]);
  const fetchLeaveDetails = async () => {
    const data = await request({
      method: "get",
      url: `leave/leave-application/all/details/?page=1&page_size=4`,
    });

    if (data && data?.pagination_data && data?.pagination_data?.data) {
      setMyAppliedLeaveDetails([...data?.pagination_data?.data]);
    }
  };

  const [myAppliedLeaveApplication, setmyAppliedLeaveApplication] = useState(
    []
  );
  const fetchLeaveApplication = async () => {
    const data = await request({
      method: "get",
      url: `inbox/leave-applications/all/?page=1&page_size=3`,
    });

    if (data && data?.pagination_data && data?.pagination_data?.data) {
      setmyAppliedLeaveApplication(data?.pagination_data?.data);
    }
  };

  const [newDocumentForSigning, setnewDocumentForSigning] = useState([]);
  const fetchnewDocumentForSigning = async () => {
    const data = await request({
      method: "get",
      url: `/inbox/documents/unsigned?page=1&page_size=3`,
    });

    if (data && data?.pagination_data && data?.pagination_data?.data) {
      setnewDocumentForSigning(data?.pagination_data?.data);
    }
  };

  const [inboxResignList, setInboxResignList] = useState([]);

  const [EmployeeReimbursementData, setEmployeeReimbursementData] = useState({
    title: "",
    amount: "",
    merchant_name: "",
    description: "",
    invoice_id: "",
    date: "",
    attach_file: "",
  });

  const [employeeReimbursement, setEmployeeReimbursement] = useState(false);
  const onHandleChange = (key, value) => {
    setEmployeeReimbursementData({
      ...EmployeeReimbursementData,
      [key]: value,
    });
  };
  const fetchInboxResignList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `my-team/resignation/all?page=1&page_size=3`,
      });
      if (data && data?.pagination_data?.data) {
        setInboxResignList(data?.pagination_data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [mycelebrationsDashboard, setmycelebrationsDashboard] = useState([]);
  const fetchCelebrationsDashboard = async () => {
    const data = await request({
      method: "get",
      url: `dashboard/celebrations`,
    });
    if (data && data?.data) {
      setmycelebrationsDashboard(data?.data?.upcoming_events);
      console.log(data?.data?.upcoming_events);
    }
  };

  const [inboxMenuList, setInboxMenuList] = useState([]);
  const fetchInboxModules = async () => {
    const data = await request({
      method: "get",
      url: `inbox/modules/all`,
    });
    if (data && data?.data) {
      setInboxMenuList(data?.data);
    }
  };

  const [selectedMyRequestMenu, setSelectedMyRequestMenu] = useState("leaves");

  const [reimbursementRequestList, setReimbursementRequestList] = useState([]);

  const fetchReimbursementRequestList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `payroll/get-employee-reimbursement?page=1&page_size=3`,
      });

      setReimbursementRequestList([...data?.pagination_data?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const [requisitionsRequestList, setRequisitionsRequestList] = useState([]);
  const fetchRequisitionRequestList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `recruitment/employee/requisitions/all?page=1&page_size=3`,
      });

      setRequisitionsRequestList([...data?.pagination_data?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const [attendanceInboxData, setAttendanceInboxData] = useState([]);

  const fetchMyTeamAttendanceDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/exemption/list?page=1&page_size=3`,
      });

      setAttendanceInboxData(data?.pagination_data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectOrApproveAttendance = async (id, isRejected, type) => {
    try {
      console.log(id, isRejected, type);
      if (type === "Excemption") {
        await request({
          method: "post",
          url: `attendance/exemption/action?id=${id}`,
          data: {
            is_approved: isRejected,
          },
        });
      }

      if (type === "Regularization") {
        await request({
          method: "post",
          url: `attendance/regularization/action?id=${id}`,
          data: {
            is_approved: isRejected,
          },
        });
      }

      setTimeout(() => {
        fetchMyTeamAttendanceDetails();
      }, 1000);

      if (isRejected)
        toast.success("Exemption Approved", {
          position: "bottom-left",
        });
      else {
        toast.success("Exemption Rejected", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
      if (isRejected) {
        toast.error(
          "Exemption could not be approved, please try after some time",
          {
            position: "bottom-left",
          }
        );
      } else {
        toast.error(
          "Exemption could not be rejected, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  useEffect(() => {
    fetchMyTeamAttendanceDetails();
  }, []);

  useEffect(() => {
    fetchData();
    fetchHolidayData();
    fetchAttendanceDetails();
    fetchLeaveDetails();
    fetchLeaveApplication();
    fetchCelebrationsDashboard();
    fetchInboxModules();
    fetchInboxResignList();
    fetchnewDocumentForSigning();
    fetchReimbursementRequestList();
    fetchRequisitionRequestList();
    fetchEmployeeCTC();
  }, []);

  const menu = [
    // {
    //     label: "Attendance",
    //     key: "attendance",
    //     url: "/inbox/attendance",
    //     image: attendance
    // },
    {
      label: "Leave",
      key: "leave",
      url: "/inbox/leave",
      stateName: myAppliedLeaveApplication.length > 0,
      // image: leave,
      // component: <LeaveList />
    },
    // {
    //     label: "Performance Management",
    //     key: "performance",
    //     url: "/inbox/performance",
    //     image: performance
    // },
    {
      label: "New Documentation Signing",
      key: "documentation",
      url: "/inbox/documentation",
      stateName: newDocumentForSigning.length > 0,
      // image: documentation
    },
    {
      label: "Resignation",
      key: "resign",
      url: "/inbox/resign",
      stateName: inboxResignList.length > 0,
      // image: resign,
      // component: <Resignation />
    },
    {
      label: "Attendance",
      key: "attendance",
      url: "/inbox/attendance",
      stateName: attendanceInboxData.length > 0,
      // image: resign,
      // component: <Resignation />
    },
  ];

  useEffect(() => {
    if (personalAttendanceDetails.policy_id) {
      fetchSpecificPolicy();
    }
  }, [personalAttendanceDetails.policy_id]);

  const renderHolidayData = () => {
    let count = 0; // Counter to keep track of rendered items
    const renderedData = [];

    Object.keys(holidayData).forEach((month) => {
      if (count >= 3) return; // If 4 items are already rendered, exit loop

      const monthItems = holidayData[month];
      const renderedMonthItems = [];

      monthItems.forEach((item, index) => {
        if (count >= 4) return; // If 4 items are already rendered, exit loop
        renderedMonthItems.push(
          <div
            key={index}
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "15px",
              marginBottom: "0px",
              padding: "0px 16px",
            }}
          >
            <p
              style={{ color: "#292929", fontSize: "18px", fontWeight: "500" }}
            >
              {moment(item?.date).format("DD")}
            </p>
            <div className="vStack">
              <div>
                <p
                  style={{
                    color: "#292929",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "5px",
                  }}
                >
                  {item?.name}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: "#439292",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {item?.day}
                </p>
              </div>
            </div>
          </div>
        );
        count++; // Increment counter after rendering each item
      });

      renderedData.push(
        <div key={month}>
          <h4
            style={{
              color: "#5B5B5B",
              fontSize: "18px",
              fontWeight: "500",
              marginTop: "10px",
              marginBottom: "10px",
              padding: "0px 16px",
            }}
          >
            {month}
          </h4>
          <div className={styles.holidayListContainer}>
            {renderedMonthItems}
          </div>
          <hr
            style={{
              height: "1px",
              backgroundColor: "#F0F0F0",
              border: "none",
            }}
          />
        </div>
      );
    });

    return renderedData;
  };

  function renderCustomShiftTimings(shifts) {
    return shifts[0]?.timings?.map((shift, index) => (
      <div key={index}>
        <p>
          <span className={styles.shift}>Shift Time {index + 1} - </span>
          {shift?.start_time} - {shift?.end_time}
        </p>
      </div>
    ));
  }

  const [applyLeaveModel, setApplyLeaveModel] = useState(false);
  const [appliedLeaveId, setAppliedLeaveId] = useState(null);

  const [editLeaveDetails, setEditLeaveDetails] = useState({
    leave_type: "",
    start_date: "",
    from_second_half_start_date: false,
    end_date: "",
    till_first_half_end_date: false,
    reason: "",
  });

  const [selectedLeaveTypeCount, setSelectedLeaveTypeCount] = useState(null);

  const onFinish = async (values) => {
    try {
      const start_date = dayjs(values.start_date).format("YYYY-MM-DD");

      const end_date = dayjs(values.end_date).format("YYYY-MM-DD");

      editLeaveDetails.start_date = start_date;
      editLeaveDetails.end_date = end_date;

      if (appliedLeaveId) {
        await request({
          method: "post",
          url: `/leave/leave/update/?id=${appliedLeaveId}`,
          data: {
            ...editLeaveDetails,
            leave_left: selectedLeaveTypeCount,
          },
        });
        setAppliedLeaveId(null);

        toast.success("Leave Application updated", {
          position: "bottom-left",
        });
      } else {
        await request({
          method: "post",
          url: `/leave/leave_application/create/`,
          data: {
            ...editLeaveDetails,
            leave_left: selectedLeaveTypeCount,
          },
        });
        setAppliedLeaveId(null);
        toast.success("Leave Application Submitted", {
          position: "bottom-left",
        });
      }

      setApplyLeaveModel(false);
      fetchPersonalLeaveDetails();
      fetchLeaveDetails();
      setEditLeaveDetails({
        leave_type: "",
        start_date: "",
        from_second_half_start_date: false,
        end_date: "",
        till_first_half_end_date: false,
        reason: "",
      });
    } catch (error) {
      console.log(error);
      toast.error("Leave Application could not be submitted", {
        position: "bottom-left",
      });
    }
  };

  const [personalLeaveDetails, setPersonalLeaveDetails] = useState([]);

  const fetchPersonalLeaveDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/employee/stats/`,
      });

      setPersonalLeaveDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChange = (changedValues) => {
    setEditLeaveDetails({ ...editLeaveDetails, ...changedValues });
  };

  const [reportingManager, setReportingManager] = useState("");
  const fetchReportingManager = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/get/reporting_manager/`,
      });

      setReportingManager(data?.reporting_manager);
    } catch (error) {
      console.log(error);
    }
  };

  const [personalLeaveLeftDetails, setPersonalLeaveLeftDetails] = useState([]);
  const fetchPersonalLeaveLeftDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/employee/leave/stats/`,
      });

      setPersonalLeaveLeftDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [leaveRule, setLeaveRule] = useState({
    back_dated: "",
    doc_requried: "",
    leave_type_eligible: "",
  });

  const [leaveRuleByDate, setLeaveRuleByDate] = useState({
    back_dated: "",
    doc_requried: "",
    leave_type_eligible: "",
  });

  const checkLeaveRule = async (leaveType) => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_type/check?leave_type_id=${leaveType}`,
      });

      setLeaveRule(data);
    } catch (error) {
      console.log(error);
      setLeaveRule(error.response.data);
    }
  };

  const checkRuleByDate = async () => {
    try {
      const data = await request({
        method: "post",
        url: `leave/leave/application/check?leave_type_id=${editLeaveDetails.leave_type}`,
        data: {
          start_date: dayjs(editLeaveDetails.start_date).format("YYYY-MM-DD"),
          end_date: dayjs(editLeaveDetails.end_date).format("YYYY-MM-DD"),
          from_second_half_start_date:
            editLeaveDetails.from_second_half_start_date,
          till_first_half_end_date: editLeaveDetails.till_first_half_end_date,
          leave_left: selectedLeaveTypeCount,
        },
      });

      //   console.log("data....", data);
      setLeaveRuleByDate(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (editLeaveDetails.start_date && editLeaveDetails.end_date) {
      checkRuleByDate();
    }
  }, [
    editLeaveDetails.start_date,
    editLeaveDetails.end_date,
    editLeaveDetails.from_second_half_start_date,
    editLeaveDetails.till_first_half_end_date,
  ]);

  useEffect(() => {
    if (editLeaveDetails.leave_type) {
      checkLeaveRule(editLeaveDetails.leave_type);
    }
  }, [editLeaveDetails.leave_type]);

  useEffect(() => {
    fetchPersonalLeaveLeftDetails();
    fetchReportingManager();
  }, []);

  useEffect(() => {
    if (myAppliedLeaveApplication.length === 0 && inboxResignList.length > 0) {
      setSelectedInbox("resign");
    }
    if (
      myAppliedLeaveApplication.length === 0 &&
      newDocumentForSigning.length > 0
    ) {
      setSelectedInbox("documentation");
    }
    if (
      myAppliedLeaveApplication.length === 0 &&
      attendanceInboxData.length > 0
    ) {
      setSelectedInbox("attendance");
    }
  }, [inboxResignList, newDocumentForSigning, attendanceInboxData]);

  // ############################################################################################
  // Attendance
  // ############################################################################################

  const webcamRef = useRef(null);
  const [attendanceWithGeoLocation, setAttendanceWithGeoLocation] =
    useState(false);
  const [faceError, setFaceError] = useState("");
  const [days, setDays] = useState(7);
  const [tableData, setTableData] = useState();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [firstModelAddFace, setFirstModelAddFace] = useState(true);
  const [secondModelAddFace, setSecondModelAddFace] = useState(true);
  const [mannualModel, setMannualModel] = useState(false);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [faceIdModel, setFaceIdModel] = useState(false);
  const [attendanceError, setAttendanceError] = useState({
    isError: false,
    message: "",
  });
  const [faceStatus, setFaceStatus] = useState("capture");

  const handleModalClose = () => {
    setAttendanceError({
      ...attendanceError,
      isError: false,
    });
    setFaceIdModel(false);
    setFaceStatus("capture");
    setExcemptionAllowed(false);
  };

  const [capturePhotoStatus, setCapturePhotoStatus] = useState(false);

  const capture = async (attendanceWithGeoLocation, latitude, longitude) => {
    if (webcamRef.current) {
      setCapturePhotoStatus(true);
      const imageBlob = await fetch(webcamRef?.current?.getScreenshot()).then(
        (res) => res.blob()
      );

      const formData = new FormData();
      formData.append("image", imageBlob, "captured-image.jpeg");

      if (attendanceWithGeoLocation) {
        formData.append("latitude", latitude);
        formData.append("longitude", longitude);
      }

      try {
        if (personalAttendanceDetails?.attendance_type === 7) {
          await request({
            method: "post",
            url: `attendance/field-face-attendance/apply`,
            data: formData,
          });
        } else {
          await request({
            method: "post",
            url: `attendance/employee/face/recognize/`,
            data: formData,
          });
        }
        handleModalClose();
        fetchTableLogs();
        setAttendanceWithGeoLocation(false);
        setAttendanceError({
          ...attendanceError,
          isError: false,
        });
        setCapturePhotoStatus(false);
        {
          !personalAttendanceDetails?.is_checked_in_today
            ? toast.success("Clock In marked successfully", {
              position: "bottom-left",
            })
            : toast.success("Clock Out marked successfully", {
              position: "bottom-left",
            });
        }
        fetchData();
        fetchAttendanceDetails();

        if (!personalAttendanceDetails?.is_checked_in_today) {
          let startTracking = "startTracking";
          if (
            window.ReactNativeWebView &&
            typeof window.ReactNativeWebView.postMessage === "function"
          ) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ startTracking })
            );
          } else {
            console.warn("ReactNativeWebView is not defined.");
          }
        } else {
          let stopTracking = "stopTracking";
          if (
            window.ReactNativeWebView &&
            typeof window.ReactNativeWebView.postMessage === "function"
          ) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ stopTracking })
            );
          } else {
            console.warn("ReactNativeWebView is not defined.");
          }
        }
      } catch (error) {
        console.error(
          "Error sending image to the server:",
          error.response.data.error
        );
        setFaceError(error.response.data.error);
        setFaceStatus("error");
        setAttendanceError({
          ...attendanceError,
          isError: true,
          message: error.response.data.message,
        });
        setExcemptionAllowed(error.response.data.excemption_allowed);
        setCapturePhotoStatus(false);
        toast.error(
          "Could not mark your attendance, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  const fetchTableLogs = async () => {
    const data = await request({
      method: "get",
      url: `attendance/employee/attendance/details/?days=${days}`,
    });
    setTableData(data.data.attendance_data);

    // setLoading(false)
  };

  const handleAddFaceModalClose = () => {
    setFaceIdModel(false);
    setFirstModelAddFace(true);
    setFaceStatus("capture");
  };

  const [capturedImage, setCapturedImage] = useState(null);

  const addFace = useCallback(async () => {
    const imageBlob = await fetch(webcamRef.current.getScreenshot()).then(
      (res) => res.blob()
    );

    const formData = new FormData();
    formData.append("image", imageBlob, "captured-image.jpeg");

    try {
      const response = await request({
        method: "post",
        url: `attendance/face/create/`,
        data: formData,
      });

      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);

      setFaceStatus("success");
    } catch (error) {
      console.error("Error sending image to the server:", error);
      setFaceStatus("error");
    }
  }, [webcamRef]);

  const handleMannualModalClose = () => {
    setAttendanceError({
      ...attendanceError,
      isError: false,
    });
    setMannualModel(false);
  };

  const captureMannualAttendance = async () => {
    try {
      let data = {
        latitude: latitude,
        longitude: longitude,
      };

      setCapturePhotoStatus(true);

      if (personalAttendanceDetails?.attendance_type === 6) {
        await request({
          method: "post",
          url: `attendance/field-attendance/apply`,
          data: attendanceWithGeoLocation ? data : {},
        });
      } else {
        await request({
          method: "post",
          url: `attendance/employee/attendance/mannual/`,
          data: attendanceWithGeoLocation ? data : {},
        });
      }
      fetchData();
      fetchTableLogs();
      setCapturePhotoStatus(false);
      setMannualModel(false);
      setAttendanceError({
        ...attendanceError,
        isError: false,
      });
      {
        !personalAttendanceDetails?.is_checked_in_today
          ? toast.success("Clock In marked successfully", {
            position: "bottom-left",
          })
          : toast.success("Clock Out marked successfully", {
            position: "bottom-left",
          });
      }

      if (!personalAttendanceDetails?.is_checked_in_today) {
        let startTracking = "startTracking";
        if (
          window.ReactNativeWebView &&
          typeof window.ReactNativeWebView.postMessage === "function"
        ) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ startTracking })
          );
        } else {
          console.warn("ReactNativeWebView is not defined.");
        }
      } else {
        let stopTracking = "stopTracking";
        if (
          window.ReactNativeWebView &&
          typeof window.ReactNativeWebView.postMessage === "function"
        ) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ stopTracking })
          );
        } else {
          console.warn("ReactNativeWebView is not defined.");
        }
      }

      fetchData();
      fetchAttendanceDetails();
    } catch (error) {
      console.error("Error sending image to the server:", error);
      setCapturePhotoStatus(false);
      setAttendanceError({
        ...attendanceError,
        isError: true,
        message: error.response.data.message,
      });

      toast.error(
        "Could not mark your attendance, please try after some time",
        {
          position: "bottom-left",
        }
      );
    }
  };

  const markAttendance = () => {
    if (personalAttendanceDetails.attendance_type === 0) {
      setFaceIdModel(true);
      setFaceError("");
    } else if (personalAttendanceDetails.attendance_type === 1) {
      setMannualModel(true);
    } else if (personalAttendanceDetails.attendance_type === 3) {
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setMannualModel(true);
    } else if (personalAttendanceDetails.attendance_type === 4) {
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setFaceIdModel(true);
      setFaceError("");
    } else if (personalAttendanceDetails.attendance_type === 6) {
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setMannualModel(true);
    } else if (personalAttendanceDetails.attendance_type === 7) {
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setFaceIdModel(true);
      setFaceError("");
    }
  };

  const getLatAndLong = () => {
    if (!navigator.geolocation) {
      // setError('Geolocation is not supported by your browser');
      return;
    }

    // Fetch the user's current position
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("sdfsdfdsfds");
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.log("error", error);
        //   setError(`Error getting location: ${error.message}`);
      }
    );
  };

  // Attendance Summary
  const [attendanceSummary, setAttendanceSummary] = useState("self");
  const [myTeamAttendanceDetails, setMyTeamAttendanceDetails] = useState({
    date: "",
    absent_count: "",
    present_count: "",
  });

  const [isReporting, setIsReporting] = useState(false);
  const myTeamDetail = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/widget/`,
    });

    setIsReporting(data.is_reporting);
    setMyTeamAttendanceDetails({
      ...myTeamAttendanceDetails,
      date: data.date,
      absent_count: data.absent_count,
      present_count: data.present_count,
      wfh_count: data.work_from_home,
    });
  };

  const rejectOrApproveLeave = async (id, isRejected) => {
    try {
      await request({
        method: "post",
        url: `leave/my-team/leave_application/approval/?id=${id}`,
        data: {
          is_rejected: isRejected,
        },
      });

      setTimeout(() => {
        fetchLeaveApplication();
      }, 1000);

      if (isRejected)
        toast.success("Leave Rejected", {
          position: "bottom-left",
        });
      else {
        toast.success("Leave Approved", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
      if (isRejected) {
        toast.error("Leave could not be rejected, please try after some time", {
          position: "bottom-left",
        });
      } else {
        toast.error("Leave could not be approved, please try after some time", {
          position: "bottom-left",
        });
      }
    }
  };

  const [excemptionAllowed, setExcemptionAllowed] = useState(false);
  const [excemptionReason, setExcemptionReason] = useState("");

  const submitExcemptionRequest = async () => {
    if (excemptionAllowed) {
      try {
        await request({
          method: "post",
          url: `attendance/exemption/apply`,
          data: {
            // employee: employeeId,
            comment: excemptionReason,
          },
        });

        handleModalClose();
        setExcemptionAllowed(false);
        fetchTableLogs();
        setAttendanceWithGeoLocation(false);
        setAttendanceError({
          ...attendanceError,
          isError: false,
        });
        fetchData();
      } catch (error) {
        console.log(error);
        toast.error(
          "Could not mark your attendance, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  useEffect(() => {
    myTeamDetail();
    getLatAndLong();
  }, []);

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const isApproveReject = async (index, id, value) => {
    try {
      const data = await request({
        method: "post",
        url: `my-team/resignation/approval?sepration_id=${id}`,
        data: {
          is_approved: value,
        },
      });

      const updatedArray = update(inboxResignList, { $splice: [[index, 1]] });
      setInboxResignList(updatedArray);

      if (value)
        toast.success("Approved Successfully", {
          position: "bottom-left",
        });
      else {
        toast.success("Rejected Successfully", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <ContainerLayout>
      {" "}
      <div className={styles.userDashBoard}>
        {screenSize.width < 768 ? (
          <div>
            {/* <div className={styles.section1}>
            {section1.map((item) => {
              return (
                <div className={styles.section1Item}
                  onClick={() => {
                    navigate(item.path)
                  }}
                >
                  <center>
                    <img src={item.image} alt={item.image} style={{ height: "80px", width: '80px' }} />
                    <p>{item.label}</p>
                  </center>
                </div>
              )
            })
            }
          </div> */}

            <div style={{ marginTop: "80px" }}>
              <Row>
                {section1.map((link, index) => (
                  <Col span={8} key={index} onClick={() => navigate(link.path)}>
                    <center>
                      {/* <div className={styles.quickLink}> */}
                      <img
                        src={link.image}
                        alt={link.label}
                        style={{ height: "80px", width: "80px" }}
                      />
                      <p className={styles.label}>{link.label}</p>
                      {/* </div> */}
                    </center>
                  </Col>
                ))}
              </Row>
            </div>

            {/* divider */}
            <div className={styles.divider}></div>
            {sepration && (
              <Row>
                <Col span={24}>
                  <Speration />
                </Col>
              </Row>
            )}
            {/* divider */}
            <div className={styles.divider}></div>
            {(myAppliedLeaveApplication.length > 0 ||
              inboxResignList.length > 0 ||
              newDocumentForSigning.length > 0 ||
              attendanceInboxData?.length > 0) && (
                <div className={styles.inboxSection}>
                  <p className={styles.inboxHeading}>Inbox</p>
                  <div className={styles.inboxMenuListContainer}>
                    {menu?.map((item) => {
                      return (
                        <>
                          {item?.stateName && (
                            <div
                              className={
                                selectedInbox === item.key
                                  ? styles.selectedInboxMenuBox
                                  : styles.inboxMenuBox
                              }
                              onClick={() => setSelectedInbox(item.key)}
                            >
                              <p className={styles.inboxMenuLabel}>
                                {item.label}
                              </p>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div className={styles.inboxMessageContainer}>
                    {selectedInbox === "leave" && (
                      <>
                        {myAppliedLeaveApplication.map((item) => {
                          return (
                            <div>
                              <div
                                key={item.id}
                                className={styles.container}
                                onClick={() =>
                                  navigate(`/inbox/leave/detail/` + item.id, {
                                    replace: true,
                                  })
                                }
                              >
                                <div className={styles.userContainer}>
                                  <p className={styles.userName}>
                                    {item.employee_name}
                                  </p>
                                  <p className={styles.date}>{item.created_at}</p>
                                </div>

                                <p className={styles.dateWithDay}>
                                  {item.start_date} ({item.leave_day} Day)
                                </p>
                                <p className={styles.leaveType}>
                                  {item.leave_type}
                                </p>

                                <div className={styles.actionsBox}>
                                  <button
                                    className={styles.approveBtn}
                                    onClick={(e) => {
                                      rejectOrApproveLeave(item.id, false);
                                      e.stopPropagation();
                                    }}
                                  >
                                    Approve
                                  </button>
                                  <button
                                    className={styles.rejectBtn}
                                    onClick={(e) => {
                                      rejectOrApproveLeave(item.id, true);
                                      e.stopPropagation();
                                    }}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                              <hr
                                style={{
                                  height: "1px",
                                  backgroundColor: "#F0F0F0",
                                  border: "none",
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                    {selectedInbox === "resign" && (
                      <>
                        {inboxResignList.map((item) => {
                          return (
                            <div>
                              <div
                                onClick={() =>
                                  navigate(`/inbox/resign`, { replace: true })
                                }
                              >
                                <div>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      color: "#1A1A1A",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.employee}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#8A8A8A",
                                    }}
                                  >
                                    {item?.reason}
                                  </p>
                                </div>

                                <div className={styles.actionsBox}>
                                  <button
                                    style={{
                                      height: "40px",
                                      padding: "0px 20px",
                                      background: "#4AD471",
                                      borderRadius: "5px",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={() => isApproveReject(item.id, true)}
                                  >
                                    Approve
                                  </button>
                                  <button
                                    style={{
                                      height: "40px",
                                      padding: "0px 20px",
                                      background: "none",
                                      borderRadius: "5px",
                                      color: "#616161",
                                      border: "1px solid #616161",
                                      marginLeft: "10px",
                                    }}
                                    onClick={(e) =>
                                      isApproveReject(item.id, false)
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {selectedInbox === "documentation" && (
                      <>
                        {newDocumentForSigning.map((item) => {
                          return (
                            <>
                              <div>
                                <div
                                  className={styles.userImageNameBox}
                                  onClick={() =>
                                    navigate(
                                      `/inbox/documentation/detail/` + item.id,
                                      { replace: true }
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        color: "#1a1a1a",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color: "#2B2B2B",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {item.created_at}
                                    </p>
                                  </div>
                                  <p
                                    style={{
                                      marginTop: "10px",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      color: "#484848 ",
                                    }}
                                  >
                                    {item.file_type}
                                  </p>
                                </div>
                              </div>
                              <hr style={{ border: "1px solid #DEDEDE" }} />
                            </>
                          );
                        })}
                      </>
                    )}
                    {selectedInbox === "attendance" && (
                      <>
                        {attendanceInboxData.map((attendance) => (
                          <div>
                            <div
                              key={attendance.id}
                              className={styles.container}
                              onClick={() =>
                                navigate(
                                  `/inbox/attendance/detail/` +
                                  attendance.type +
                                  "/" +
                                  attendance.id,
                                  { replace: true }
                                )
                              }
                            >
                              <div className={styles.userContainer}>
                                <p className={styles.userName}>
                                  {attendance.employee_name}
                                </p>
                                <p className={styles.date}>
                                  {attendance.created_at}
                                </p>
                              </div>

                              {/* <p className={styles.dateWithDay}>{attendance.start_date} ({attendance.leave_day} Day)</p> */}
                              <p className={styles.leaveType}>
                                {attendance.comment}
                              </p>

                              <div className={styles.actionsBox}>
                                <button
                                  className={styles.approveBtn}
                                  onClick={(e) => {
                                    rejectOrApproveLeave(attendance.id, false);
                                    e.stopPropagation();
                                  }}
                                >
                                  Approve
                                </button>
                                <button
                                  className={styles.rejectBtn}
                                  onClick={(e) => {
                                    rejectOrApproveLeave(attendance.id, true);
                                    e.stopPropagation();
                                  }}
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )}

            {/* divider */}
            <div className={styles.divider}></div>

            <div className={styles.attendanceSection}>
              <p className={styles.attendanceHeading}>Attendance</p>
              <div className={styles.shiftContainer}>
                {attendanceDetails?.shift_number > 1 && (
                  <button
                    className={
                      attendanceDetailsShift === 1
                        ? styles.activeShiftBtn
                        : styles.shiftBtn
                    }
                    onClick={() => setAttendanceDetailsShift(1)}
                  >
                    Shift 1
                  </button>
                )}
                {attendanceDetails?.shift_number >= 2 && (
                  <button
                    className={
                      attendanceDetailsShift === 2
                        ? styles.activeShiftBtn
                        : styles.shiftBtn
                    }
                    onClick={() => setAttendanceDetailsShift(2)}
                  >
                    Shift 2
                  </button>
                )}
                {attendanceDetails?.shift_number >= 3 && (
                  <button
                    className={
                      attendanceDetailsShift === 3
                        ? styles.activeShiftBtn
                        : styles.shiftBtn
                    }
                    onClick={() => setAttendanceDetailsShift(3)}
                  >
                    Shift 3
                  </button>
                )}
              </div>
              <div>
                <p className={styles.attendanceDate}>
                  {attendanceDetails?.date} | {attendanceDetails?.day}{" "}
                </p>

                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className={styles.time}>Shift Time</p>
                    <p className={styles.timeValue}>
                      {
                        attendanceDetails?.shifts[attendanceDetailsShift - 1]
                          ?.shift_timming
                      }
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className={styles.time}>Clock in</p>
                    <p className={styles.timeValue}>
                      {
                        attendanceDetails?.shifts[attendanceDetailsShift - 1]
                          ?.attendance_logs
                      }
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className={styles.time}>Shift Duration</p>
                    <p className={styles.timeValue}>
                      {
                        attendanceDetails?.shifts[attendanceDetailsShift - 1]
                          ?.working_duration
                      }
                    </p>
                  </div>
                </div>
              </div>
              {personalAttendanceDetails?.attendance_type !== 5 && (
                <button
                  className={styles.button}
                  onClick={() => markAttendance()}
                >
                  {!personalAttendanceDetails?.is_checked_in_today
                    ? "Clock In"
                    : "Clock Out"}
                </button>
              )}
            </div>

            {/* divider */}
            <div className={styles.divider}></div>

            <div className={styles.attendanceSummay}>
              <div className="flexSpaceBetween">
                <p className={styles.summaryHeading}>Attendance Summary</p>
                <img
                  src={fullView}
                  alt="fullView"
                  onClick={() => navigate("/attendance", { replace: true })}
                />
              </div>
              {isReporting && (
                <div className={styles.summaryBtnContainer}>
                  <button
                    className={
                      attendanceSummary === "self"
                        ? styles.selectedBtn
                        : styles.unSelectedBtn
                    }
                    onClick={() => setAttendanceSummary("self")}
                  >
                    Attendance
                  </button>
                  <button
                    className={
                      attendanceSummary === "myTeam"
                        ? styles.selectedBtn
                        : styles.unSelectedBtn
                    }
                    onClick={() => setAttendanceSummary("myTeam")}
                  >
                    My Team
                  </button>
                </div>
              )}

              {attendanceSummary === "self" && (
                <Row gutter={[32, 32]}>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span className={styles.value}>
                          {personalAttendanceDetails?.average_working_duration}{" "}
                          hr
                        </span>

                        <img src={averageDuration} alt="" className="image" />
                      </div>
                      <p className={styles.label}>Average Duration</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span className={styles.value}>
                          {personalAttendanceDetails.work_policy}
                        </span>
                        <img
                          src={workPolicyDashboard}
                          alt="workingPolicy"
                          className="image"
                        />
                      </div>
                      <p className={styles.label}>
                        Working Policy{" "}
                        <Popover content={workPolicyContent} trigger="click">
                          <InfoCircleOutlined
                            style={{ color: "#696969" }}
                            onClick={() => {
                              fetchSpecificPolicy();
                            }}
                          />
                        </Popover>
                      </p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span className={styles.value}>
                          {personalAttendanceDetails.total_leaves}
                        </span>
                        <img
                          src={LeaveDashboard}
                          alt="totalLeave"
                          className="image"
                        />
                      </div>
                      <p className={styles.label}>Total Leaves</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span className={styles.value}>
                          {personalAttendanceDetails.total_present_days}
                        </span>
                        <img
                          src={WorkingDayDashboard}
                          alt="workingDays"
                          className="image"
                        />
                      </div>
                      <p className={styles.label}>Total Working Days</p>
                    </div>
                  </Col>
                </Row>
              )}
              {attendanceSummary === "myTeam" && (
                <Row gutter={[32, 32]}>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span
                          className={styles.value}
                          style={{ color: "#FA0D0D" }}
                        >
                          {myTeamAttendanceDetails.absent_count}
                        </span>

                        <img src={totalAbsentMyTeam} alt="" className="image" />
                      </div>
                      <p className={styles.label}>Absent</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span
                          className={styles.value}
                          style={{ color: "#145757" }}
                        >
                          {myTeamAttendanceDetails.present_count}
                        </span>

                        <img
                          src={totalPresentMyTeam}
                          alt=""
                          className="image"
                        />
                      </div>
                      <p className={styles.label}>Present</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.summaryContainer}>
                      <div className="flexSpaceBetween">
                        <span
                          className={styles.value}
                          style={{ color: "#0043FF" }}
                        >
                          {myTeamAttendanceDetails.wfh_count}
                        </span>

                        <img src={wfhMyTeam} alt="" className="image" />
                      </div>
                      <p className={styles.label}>WFH</p>
                    </div>
                  </Col>
                </Row>
              )}
            </div>

            {/* divider */}
            <div className={styles.divider}></div>
            {(myAppliedLeaveDetails.length > 0 ||
              reimbursementRequestList.length > 0 ||
              requisitionsRequestList.length > 0) && (
                <div>
                  <div className={styles.myRequestContainer}>
                    <p className={styles.myRequestHeading}>My Requests</p>
                    <div className={styles.myRequestMenuContainer}>
                      {myRequestMenuList.map((item) => {
                        return (
                          <div
                            className={
                              selectedMyRequestMenu === item.key
                                ? styles.myRequestMenuActive
                                : styles.myRequestMenu
                            }
                            onClick={() => setSelectedMyRequestMenu(item.key)}
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    {selectedMyRequestMenu === "leaves" && (
                      <>
                        {myAppliedLeaveDetails.map((item, index) => {
                          return (
                            <div>
                              <div style={{ padding: "0px 16px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // marginTop: "20px",
                                    gap: "20px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      margin: "0",
                                    }}
                                  >
                                    {item?.leave_type}
                                  </p>

                                  {item?.is_pending && (
                                    <button
                                      style={{
                                        border: "1px solid #8A8A8A",
                                        borderRadius: "21px",
                                        background: "none",
                                        color: "#8A8A8A",
                                        padding: "5px 10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Pending
                                    </button>
                                  )}
                                  {item?.is_approved && (
                                    <button
                                      style={{
                                        border: "none",
                                        borderRadius: "21px",
                                        background: "#86CF52",
                                        color: "white",
                                        padding: "5px 10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  )}
                                  {item?.is_rejected && (
                                    <button
                                      style={{
                                        border: "none",
                                        borderRadius: "21px",
                                        background: "#FA4141",
                                        color: "white",
                                        padding: "5px 10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}
                                </div>

                                <div>
                                  <p className="date">
                                    {moment(item?.start_date).format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    To{" "}
                                    {moment(item?.end_date).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                                <div className="leavesText">
                                  {item?.leave_day} day leaves{" "}
                                  <Popover
                                    placement="bottom"
                                    content={"day leaves"}
                                  >
                                    <DownOutlined style={{ cursor: "pointer" }} />
                                  </Popover>
                                </div>
                              </div>

                              {myAppliedLeaveDetails?.length - 1 !== index && (
                                <hr
                                  style={{
                                    height: "1px",
                                    backgroundColor: "#F0F0F0",
                                    border: "none",
                                  }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                    {selectedMyRequestMenu === "reimbursements" && (
                      <div>
                        {reimbursementRequestList?.map((item, index) => {
                          return (
                            <>
                              <div style={{ padding: "0px 16px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                    marginBottom: "15px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      margin: "0",
                                    }}
                                  >
                                    {item?.title}
                                  </p>
                                  <>
                                    {item?.status === "Pending" && (
                                      <button
                                        style={{
                                          border: "1px solid #8A8A8A",
                                          borderRadius: "21px",
                                          background: "none",
                                          color: "#8A8A8A",
                                          padding: "5px 10px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Pending
                                      </button>
                                    )}
                                    {item?.status === "Approved" && (
                                      <button
                                        style={{
                                          border: "none",
                                          borderRadius: "21px",
                                          background: "#86CF52",
                                          color: "white",
                                          padding: "5px 10px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Approved
                                      </button>
                                    )}
                                    {item?.status === "Partially-Approved" && (
                                      <button
                                        style={{
                                          border: "none",
                                          borderRadius: "21px",
                                          background: "#FA4141",
                                          color: "white",
                                          padding: "5px 10px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Partially Approved
                                      </button>
                                    )}
                                  </>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    Amount Claimed
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#161616",
                                    }}
                                  >
                                    ₹ {item?.claim_amount}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    Amount Approved
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#161616",
                                    }}
                                  >
                                    ₹ {item?.approved_amount}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    Date
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#161616",
                                    }}
                                  >
                                    {item?.date}
                                  </p>
                                </div>
                              </div>
                              {reimbursementRequestList?.length - 1 !== index && (
                                <hr
                                  style={{
                                    height: "1px",
                                    backgroundColor: "#F0F0F0",
                                    border: "none",
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}
                    {selectedMyRequestMenu === "requisitions" && (
                      <div>
                        {requisitionsRequestList?.map((item, index) => {
                          return (
                            <>
                              <div style={{ padding: "0px 16px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      margin: "0",
                                    }}
                                  >
                                    {item?.job_title}
                                  </p>
                                  <>
                                    {item?.status === "Pending" && (
                                      <button
                                        style={{
                                          border: "1px solid #8A8A8A",
                                          borderRadius: "21px",
                                          background: "none",
                                          color: "#8A8A8A",
                                          padding: "5px 10px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Pending
                                      </button>
                                    )}
                                    {item?.status === "Active" && (
                                      <button
                                        style={{
                                          border: "none",
                                          borderRadius: "21px",
                                          background: "#86CF52",
                                          color: "white",
                                          padding: "5px 10px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Approved
                                      </button>
                                    )}
                                    {item?.status === "Rejected" && (
                                      <button
                                        style={{
                                          border: "none",
                                          borderRadius: "21px",
                                          background: "#FA4141",
                                          color: "white",
                                          padding: "5px 10px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Rejected
                                      </button>
                                    )}
                                  </>
                                </div>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#333333",
                                  }}
                                >
                                  {item?.salary_range}
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#333333",
                                  }}
                                >
                                  Full time
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#333333",
                                  }}
                                >
                                  {item?.industry}
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#333333",
                                  }}
                                >
                                  Gurugram
                                </p>
                              </div>
                              {selectedMyRequestMenu?.length - 1 !== index && (
                                <hr
                                  style={{
                                    height: "1px",
                                    backgroundColor: "#F0F0F0",
                                    border: "none",
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* divider */}
            <div className={styles.divider}></div>

            <div className={styles.holidayContainer}>
              <div className="flexSpaceBetween">
                <p className={styles.holidayHeading}>Holidays</p>
                <div className={styles.holidayBtnContainer}>
                  {/* <button className={styles.holidayBtn}>Calendar</button> */}
                  <img
                    src={fullView}
                    alt="fullView"
                    onClick={() =>
                      navigate("/leave/holiday-list", { replace: true })
                    }
                  />
                </div>
              </div>
            </div>
            {renderHolidayData()}

            {/* divider */}
            <div className={styles.divider}></div>

            <div className={styles.compensationContainer}>
              <p className={styles.compensationHeading}>Compensation</p>
              <Row gutter={[32, 32]}>
                <Col span={12}>
                  <div
                    style={{
                      marginTop: "20px",
                      background: "#FAFAFA",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className={styles.compensationValue}>
                        {employeeCTC?.employee_ctc || 0}
                      </p>
                      <img src={salaryDetail} alt="salaryDetail" />
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#161616",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        navigate("/payroll/salary-details", { replace: true })
                      }
                    >
                      Salary Details
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            {/* divider */}
            <div className={styles.divider}></div>
            {mycelebrationsDashboard.length > 0 && (
              <div className={styles.celebrationsContainer}>
                <p className={styles.celebrationsHeading}>Celebrations</p>
                <div className={styles.celebrationsListContainer}>
                  {mycelebrationsDashboard.map((item, index) => (
                    <div key={index} className={styles.celebrationItem}>
                      <img
                        src={
                          item.profile_image
                            ? item.profile_image
                            : `https://ui-avatars.com/api/?name=${item.employee_name.charAt(
                              0
                            )}`
                        }
                        alt="profileImg"
                        className={styles.userImg}
                      />
                      <div>
                        <p className={styles.celebrationText}>
                          {item.ocassion}
                        </p>
                        <p className={styles.employeeName}>
                          {item.employee_name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div style={{ height: "80px" }}></div>
          </div>
        ) : (
          <div className={styles.dashboardContainer}>
            {sepration && (
              <Row>
                <Col span={24}>
                  <Speration />
                </Col>
              </Row>
            )}
            <Row gutter={[32, 30]}>
              <Col span={15}>
                <div className={styles.section1}>
                  {section1.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={styles.section1Item}
                        onClick={() => {
                          if (item.key === 1) {
                            setApplyLeaveModel(true);
                          }
                          if (item.key === 2) {
                            setEmployeeReimbursement(true);
                          }
                          if (item.key === 3) {
                            navigate("/payroll/payroll-summary", {
                              replace: true,
                            });
                          }
                        }}
                      >
                        <center>
                          <img
                            src={item.image}
                            alt={item.image}
                            style={{ height: "80px", width: "80px" }}
                          />
                          <p>{item.label}</p>
                        </center>
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col span={9}>
                <div className={styles.attendanceSection}>
                  <div className={styles.attendanceContainer}>
                    <div>
                      <p className={styles.attendanceHeading}>Attendance</p>
                      <div className={styles.shiftContainer}>
                        {attendanceDetails?.shift_number > 1 && (
                          <button
                            className={
                              attendanceDetailsShift === 1
                                ? styles.activeShiftBtn
                                : styles.shiftBtn
                            }
                            onClick={() => setAttendanceDetailsShift(1)}
                          >
                            Shift 1
                          </button>
                        )}
                        {attendanceDetails?.shift_number >= 2 && (
                          <button
                            className={
                              attendanceDetailsShift === 2
                                ? styles.activeShiftBtn
                                : styles.shiftBtn
                            }
                            onClick={() => setAttendanceDetailsShift(2)}
                          >
                            Shift 2
                          </button>
                        )}
                        {attendanceDetails?.shift_number >= 3 && (
                          <button
                            className={
                              attendanceDetailsShift === 3
                                ? styles.activeShiftBtn
                                : styles.shiftBtn
                            }
                            onClick={() => setAttendanceDetailsShift(3)}
                          >
                            Shift 3
                          </button>
                        )}
                      </div>
                      {/* <p className={styles.attendanceDate}>{attendanceDetails?.date} | {attendanceDetails?.day}  </p> */}
                    </div>
                    <div>
                      {personalAttendanceDetails?.attendance_type !== 5 && (
                        <button
                          className={styles.button}
                          onClick={() => markAttendance()}
                        >
                          {!personalAttendanceDetails?.is_checked_in_today
                            ? "Clock In"
                            : "Clock Out"}
                        </button>
                      )}
                    </div>
                  </div>

                  <div style={{ marginTop: "8px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className={styles.time}>Shift Time</p>
                      <p className={styles.timeValue}>
                        {
                          attendanceDetails?.shifts[attendanceDetailsShift - 1]
                            ?.shift_timming
                        }
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className={styles.time}>Clock in</p>
                      <p className={styles.timeValue}>
                        {
                          attendanceDetails?.shifts[attendanceDetailsShift - 1]
                            ?.attendance_logs
                        }
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className={styles.time}>Shift Duration</p>
                      <p className={styles.timeValue}>
                        {
                          attendanceDetails?.shifts[attendanceDetailsShift - 1]
                            ?.working_duration
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </Col>

              {(myAppliedLeaveApplication.length > 0 ||
                inboxResignList.length > 0 ||
                newDocumentForSigning.length > 0 ||
                attendanceInboxData?.length > 0) && (
                  <Col span={24}>
                    <div className={styles.inboxSection}>
                      <p className={styles.inboxHeading}>Inbox</p>
                      <div className={styles.inboxMenuListContainer}>
                        {menu?.map((item) => {
                          return (
                            <>
                              {item?.stateName && (
                                <div
                                  className={
                                    selectedInbox === item.key
                                      ? styles.selectedInboxMenuBox
                                      : styles.inboxMenuBox
                                  }
                                  onClick={() => setSelectedInbox(item.key)}
                                >
                                  <p className={styles.inboxMenuLabel}>
                                    {item.label}
                                  </p>
                                  {/* {item.count > 0 && <p className={styles.inboxMenuCount}>({item.count})</p>} */}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                      <div className={styles.inboxMessageContainer}>
                        {selectedInbox === "leave" && (
                          <>
                            {myAppliedLeaveApplication.map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={styles.container}
                                  onClick={() =>
                                    navigate(`/inbox/leave/detail/` + item.id, {
                                      replace: true,
                                    })
                                  }
                                >
                                  <div className={styles.userContainer}>
                                    <div className={styles.userBox}>
                                      <img
                                        loading="lazy"
                                        src={item.profile_image}
                                        alt={item.employee_name}
                                        width={35}
                                        height={35}
                                      />
                                      <div className={styles.userDetailBox}>
                                        <div className={styles.userName}>
                                          {item.employee_name}
                                        </div>
                                        <div className={styles.dateWithDay}>
                                          {item.start_date} ({item.leave_day} Day)
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles.date}>
                                      {item.created_at}
                                    </div>
                                  </div>
                                  <div className={styles.leaveType}>
                                    {item.leave_type}
                                  </div>
                                  <div className={styles.actionsBox}>
                                    <button
                                      className={styles.approveBtn}
                                      onClick={(e) => {
                                        rejectOrApproveLeave(item.id, false);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className={styles.rejectBtn}
                                      onClick={(e) => {
                                        rejectOrApproveLeave(item.id, true);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                        {selectedInbox === "resign" && (
                          <>
                            {inboxResignList.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div>
                                    <div
                                      onClick={() =>
                                        navigate(`/inbox/resign`, {
                                          replace: true,
                                        })
                                      }
                                    >
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#1A1A1A",
                                          fontWeight: "600",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {item.employee}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          color: "#8A8A8A",
                                        }}
                                      >
                                        {item?.reason}
                                      </p>
                                    </div>

                                    <div>
                                      <button
                                        style={{
                                          height: "40px",
                                          padding: "0px 20px",
                                          background: "#4AD471",
                                          borderRadius: "5px",
                                          color: "white",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          isApproveReject(index, item.id, true)
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        style={{
                                          height: "40px",
                                          padding: "0px 20px",
                                          background: "none",
                                          borderRadius: "5px",
                                          color: "#616161",
                                          border: "1px solid #616161",
                                          marginLeft: "10px",
                                        }}
                                        onClick={(e) =>
                                          isApproveReject(index, item.id, false)
                                        }
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                        {selectedInbox === "documentation" && (
                          <>
                            {newDocumentForSigning.map((item) => {
                              return (
                                <>
                                  <div
                                    onClick={() =>
                                      navigate(
                                        `/inbox/documentation/detail/` + item.id,
                                        { replace: true }
                                      )
                                    }
                                  >
                                    <div style={{ marginTop: "20px" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "18px",
                                            color: "#1a1a1a",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.name}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            color: "#2B2B2B",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {item.created_at}
                                        </p>
                                      </div>
                                      <p
                                        style={{
                                          marginTop: "10px",
                                          fontWeight: "400",
                                          fontSize: "16px",
                                          color: "#484848 ",
                                        }}
                                      >
                                        {item.file_type}
                                      </p>
                                    </div>
                                  </div>
                                  <hr style={{ border: "1px solid #DEDEDE" }} />
                                </>
                              );
                            })}
                          </>
                        )}

                        {selectedInbox === "attendance" && (
                          <>
                            {attendanceInboxData?.map((attendance) => (
                              <div
                                key={attendance.id}
                                className={styles.container}
                                onClick={() =>
                                  navigate(
                                    `/inbox/attendance/detail/` +
                                    attendance.type +
                                    "/" +
                                    attendance.id,
                                    { replace: true }
                                  )
                                }
                              >
                                <div className={styles.userContainer}>
                                  <div className={styles.userBox}>
                                    <img
                                      loading="lazy"
                                      src={
                                        attendance.profile_image
                                          ? attendance.profile_image
                                          : `https://ui-avatars.com/api/?name=${attendance.employee_name.charAt(
                                            0
                                          )}`
                                      }
                                      alt={attendance.employee_name}
                                      className={styles.userImg}
                                    />
                                    <div className={styles.userDetailBox}>
                                      <p className={styles.userName}>
                                        {attendance.employee_name}
                                      </p>
                                      {/* <div className={styles.dateWithDay}>
                                                                {attendance.check_in_time} - {attendance.check_out_time}
                                                            </div> */}
                                      <p className={styles.comment}>
                                        {attendance?.comment}
                                      </p>
                                    </div>
                                  </div>
                                  <p className={styles.date}>
                                    {attendance.attendance_date}
                                  </p>
                                </div>
                                {/* <div className={styles.leaveType}>{attendance.work_duration}</div> */}
                                <div className={styles.actionsBox}>
                                  <button
                                    className={styles.approveBtn}
                                    onClick={(e) => {
                                      rejectOrApproveAttendance(
                                        attendance?.id,
                                        true,
                                        attendance?.type
                                      );
                                      e.stopPropagation();
                                    }}
                                  >
                                    Approve
                                  </button>
                                  <button
                                    className={styles.rejectBtn}
                                    onClick={(e) => {
                                      rejectOrApproveAttendance(
                                        attendance?.id,
                                        false,
                                        attendance?.type
                                      );
                                      e.stopPropagation();
                                    }}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                )}

              <Col span={12}>
                <div className={styles.attendanceSummay}>
                  <div className="flexSpaceBetween">
                    <p className={styles.summaryHeading}>Attendance Summary</p>
                    <img
                      src={fullView}
                      alt="fullView"
                      onClick={() => navigate("/attendance", { replace: true })}
                    />
                  </div>

                  {isReporting && (
                    <div className={styles.summaryBtnContainer}>
                      <button
                        className={
                          attendanceSummary === "self"
                            ? styles.selectedBtn
                            : styles.unSelectedBtn
                        }
                        onClick={() => setAttendanceSummary("self")}
                      >
                        Attendance
                      </button>
                      <button
                        className={
                          attendanceSummary === "myTeam"
                            ? styles.selectedBtn
                            : styles.unSelectedBtn
                        }
                        onClick={() => setAttendanceSummary("myTeam")}
                      >
                        My Team
                      </button>
                    </div>
                  )}
                  <br />
                  {attendanceSummary === "self" && (
                    <Row gutter={[32, 32]}>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span className={styles.value}>
                              {
                                personalAttendanceDetails?.average_working_duration
                              }{" "}
                              hr
                            </span>

                            <img
                              src={averageDuration}
                              alt=""
                              className="image"
                            />
                          </div>
                          <p className={styles.label}>Average Duration</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span className={styles.value}>
                              {personalAttendanceDetails?.work_policy?.slice(
                                0,
                                16
                              )}
                              {personalAttendanceDetails?.work_policy?.length >
                                16 && ".."}
                            </span>
                            <img
                              src={workPolicyDashboard}
                              alt="workingPolicy"
                              className="image"
                            />
                          </div>
                          <p className={styles.label}>
                            Working Policy{" "}
                            <Popover
                              content={workPolicyContent}
                              trigger="click"
                            >
                              <InfoCircleOutlined
                                style={{ color: "#696969" }}
                                onClick={() => {
                                  fetchSpecificPolicy();
                                }}
                              />
                            </Popover>
                          </p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span className={styles.value}>
                              {personalAttendanceDetails.total_leaves}
                            </span>
                            <img
                              src={LeaveDashboard}
                              alt="totalLeave"
                              className="image"
                            />
                          </div>
                          <p className={styles.label}>Total Leaves</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span className={styles.value}>
                              {personalAttendanceDetails.total_present_days}
                            </span>
                            <img
                              src={WorkingDayDashboard}
                              alt="workingDays"
                              className="image"
                            />
                          </div>
                          <p className={styles.label}>Total Working Days</p>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {attendanceSummary === "myTeam" && (
                    <Row gutter={[32, 32]}>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span
                              className={styles.value}
                              style={{ color: "#FA0D0D" }}
                            >
                              {myTeamAttendanceDetails.absent_count}
                            </span>

                            <img
                              src={totalAbsentMyTeam}
                              alt=""
                              className="image"
                            />
                          </div>
                          <p className={styles.label}>Absent</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span
                              className={styles.value}
                              style={{ color: "#145757" }}
                            >
                              {myTeamAttendanceDetails.present_count}
                            </span>

                            <img
                              src={totalPresentMyTeam}
                              alt=""
                              className="image"
                            />
                          </div>
                          <p className={styles.label}>Present</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={styles.summaryContainer}>
                          <div className="flexSpaceBetween">
                            <span
                              className={styles.value}
                              style={{ color: "#0043FF" }}
                            >
                              {myTeamAttendanceDetails.wfh_count}
                            </span>

                            <img src={wfhMyTeam} alt="" className="image" />
                          </div>
                          <p className={styles.label}>WFH</p>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>

              <Col span={12}>
                <div className={styles.holidayContainer}>
                  <div
                    className="flexSpaceBetween"
                    style={{ marginTop: "10px" }}
                  >
                    <p className={styles.holidayHeading}>Holidays</p>
                    <div className={styles.holidayBtnContainer}>
                      {/* <button className={styles.holidayBtn}>Calendar</button> */}
                      <img
                        src={fullView}
                        alt="fullView"
                        onClick={() =>
                          navigate("/leave/holiday-list", { replace: true })
                        }
                      />
                    </div>
                  </div>
                  {renderHolidayData()}
                </div>
              </Col>

              {(myAppliedLeaveDetails.length > 0 ||
                reimbursementRequestList.length > 0 ||
                requisitionsRequestList.length > 0) && (
                  <Col span={12}>
                    <div className={styles.myRequestContainer}>
                      <p className={styles.myRequestHeading}>My Requests</p>
                      <div className={styles.myRequestMenuContainer}>
                        {myRequestMenuList.map((item) => {
                          return (
                            <div
                              className={
                                selectedMyRequestMenu === item.key
                                  ? styles.myRequestMenuActive
                                  : styles.myRequestMenu
                              }
                              onClick={() => setSelectedMyRequestMenu(item.key)}
                            >
                              {item.label}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {selectedMyRequestMenu === "leaves" && (
                          <>
                            {myAppliedLeaveDetails.map((item, index) => {
                              return (
                                <div>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "20px",
                                        gap: "20px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "500",
                                          margin: "0",
                                        }}
                                      >
                                        {item?.leave_type}
                                      </p>

                                      {item?.is_pending && (
                                        <button
                                          style={{
                                            border: "1px solid #8A8A8A",
                                            borderRadius: "21px",
                                            background: "none",
                                            color: "#8A8A8A",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Pending
                                        </button>
                                      )}
                                      {item?.is_approved && (
                                        <button
                                          style={{
                                            border: "none",
                                            borderRadius: "21px",
                                            background: "#86CF52",
                                            color: "white",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Approved
                                        </button>
                                      )}
                                      {item?.is_rejected && (
                                        <button
                                          style={{
                                            border: "none",
                                            borderRadius: "21px",
                                            background: "#FA4141",
                                            color: "white",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Rejected
                                        </button>
                                      )}
                                    </div>

                                    <div>
                                      <p className="date">
                                        {moment(item?.start_date).format(
                                          "DD-MM-YYYY"
                                        )}{" "}
                                        To{" "}
                                        {moment(item?.end_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="leavesText">
                                      {item?.leave_day} day leaves{" "}
                                      <Popover
                                        placement="bottom"
                                        content={"day leaves"}
                                      >
                                        <DownOutlined
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Popover>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                        {selectedMyRequestMenu === "reimbursements" && (
                          <div style={{ marginTop: "30px" }}>
                            {reimbursementRequestList?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      marginBottom: "15px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        margin: "0",
                                      }}
                                    >
                                      {item?.title}
                                    </p>
                                    <>
                                      {item?.status === "Pending" && (
                                        <button
                                          style={{
                                            border: "1px solid #8A8A8A",
                                            borderRadius: "21px",
                                            background: "none",
                                            color: "#8A8A8A",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Pending
                                        </button>
                                      )}
                                      {item?.status === "Approved" && (
                                        <button
                                          style={{
                                            border: "none",
                                            borderRadius: "21px",
                                            background: "#86CF52",
                                            color: "white",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Approved
                                        </button>
                                      )}
                                      {item?.status === "Partially-Approved" && (
                                        <button
                                          style={{
                                            border: "none",
                                            borderRadius: "21px",
                                            background: "#FA4141",
                                            color: "white",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Partially Approved
                                        </button>
                                      )}
                                    </>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#333333",
                                      }}
                                    >
                                      Amount Claimed
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#161616",
                                      }}
                                    >
                                      ₹ {item?.claim_amount}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#333333",
                                      }}
                                    >
                                      Amount Approved
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#161616",
                                      }}
                                    >
                                      ₹ {item?.approved_amount}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#333333",
                                      }}
                                    >
                                      Date
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#161616",
                                      }}
                                    >
                                      {item?.date}
                                    </p>
                                  </div>
                                  {reimbursementRequestList?.length - 1 !==
                                    index && (
                                      <hr style={{ border: "1px solid #DEDEDE" }} />
                                    )}
                                </>
                              );
                            })}
                          </div>
                        )}
                        {selectedMyRequestMenu === "requisitions" && (
                          <div style={{ marginTop: "30px" }}>
                            {requisitionsRequestList?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        margin: "0",
                                      }}
                                    >
                                      {item?.job_title}
                                    </p>
                                    <>
                                      {item?.status === "Pending" && (
                                        <button
                                          style={{
                                            border: "1px solid #8A8A8A",
                                            borderRadius: "21px",
                                            background: "none",
                                            color: "#8A8A8A",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Pending
                                        </button>
                                      )}
                                      {item?.status === "Active" && (
                                        <button
                                          style={{
                                            border: "none",
                                            borderRadius: "21px",
                                            background: "#86CF52",
                                            color: "white",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Approved
                                        </button>
                                      )}
                                      {item?.status === "Rejected" && (
                                        <button
                                          style={{
                                            border: "none",
                                            borderRadius: "21px",
                                            background: "#FA4141",
                                            color: "white",
                                            padding: "5px 10px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Rejected
                                        </button>
                                      )}
                                    </>
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    {item?.salary_range}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    Full time
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    {item?.industry}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#333333",
                                    }}
                                  >
                                    Gurugram
                                  </p>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                )}

              {mycelebrationsDashboard.length > 0 && (
                <Col span={12}>
                  <div className={styles.celebrationsContainer}>
                    <p className={styles.celebrationsHeading}>Celebrations</p>
                    <div className={styles.celebrationsListContainer}>
                      {mycelebrationsDashboard.map((item, index) => (
                        <div key={index} className={styles.celebrationItem}>
                          <img
                            src={
                              item.profile_image
                                ? item.profile_image
                                : `https://ui-avatars.com/api/?name=${item.employee_name.charAt(
                                  0
                                )}`
                            }
                            alt="profileImg"
                            className={styles.userImg}
                          />
                          <div>
                            <p className={styles.celebrationText}>
                              {item.ocassion}
                            </p>
                            <p className={styles.employeeName}>
                              {item.employee_name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              )}

              <Col span={12}>
                <div style={{ backgroundColor: "white", padding: "20px 30px" }}>
                  <p
                    style={{
                      color: "#161616",
                      fontSize: "24px",
                      fontWeight: "400",
                      margin: "0px",
                    }}
                  >
                    Compensation
                  </p>
                  <div style={{ padding: "20px 10px" }}>
                    <Row gutter={[32, 32]}>
                      <Col span={10}>
                        <div
                          style={{
                            background: "#FAFAFA",
                            padding: "25px 20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                color: "#145757",
                                fontSize: "24px",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              {employeeCTC?.employee_ctc || 0}
                            </p>
                            <img src={salaryDetail} alt="salaryDetail" />
                          </div>
                          <br />
                          <p
                            style={{ fontSize: "18px", color: "#161616" }}
                            onClick={() =>
                              navigate("/payroll/salary-details", {
                                replace: true,
                              })
                            }
                          >
                            Salary Details
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}

        <Modal
          title="Apply Leave"
          centered
          open={applyLeaveModel}
          // onOk={() => setOpen(false)}
          onCancel={() => {
            setApplyLeaveModel(false);
            setAppliedLeaveId(null);
            setEditLeaveDetails({
              leave_type: "",
              start_date: "",
              from_second_half_start_date: false,
              end_date: "",
              till_first_half_end_date: false,
              reason: "",
            });
          }}
          width={1200}
          footer={null}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleFormChange}
            initialValues={editLeaveDetails}
            fields={[
              {
                name: ["leave_type"],
                value: editLeaveDetails.leave_type,
              },
              {
                name: ["reporting_manager"],
                value: reportingManager,
              },
              {
                name: ["start_date"],
                value: editLeaveDetails.start_date,
              },
              {
                name: ["end_date"],
                value: editLeaveDetails.end_date,
              },
              {
                name: ["reason"],
                value: editLeaveDetails.reason,
              },
            ]}
          >
            <Row>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Leave Type"
                  name="leave_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select Leave Type!",
                    },
                  ]}
                >
                  <Select className="leaveInputField">
                    {personalLeaveLeftDetails?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.leave_id}>
                          <div
                            onClick={() =>
                              setSelectedLeaveTypeCount(item.balance_leave)
                            }
                          >
                            {item.leave_name} ( {item.balance_leave} leave left
                            )
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {/* <p style={{ color: "red" }}>{leaveRule?.message?.map((item, index) => {
                                    return (
                                        <p>{item}</p>
                                    )
                                })}</p> */}
                {leaveRule?.message?.length > 0 && (
                  <div
                    style={{
                      background: "#FDF3CF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <img src={Error} alt="" />
                      <p style={{ margin: "0px" }}>{leaveRule?.message[0]}</p>
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="reporting_manager" label="Approval Authority">
                  <Input className="leaveInputField" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (From)"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  {/* leaveRule.back_dated */}
                  <DatePicker
                    className="leaveInputField"
                    disabledDate={
                      !leaveRule.back_dated &&
                      function disabled(current) {
                        // Disable dates after today
                        // Disable dates before today
                        return current && current < moment().startOf("day");
                      }
                    }
                    disabled={editLeaveDetails.leave_type ? false : true}
                  />
                </Form.Item>
                {/* <br /> */}
                <div
                  style={{
                    marginBottom: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Form.Item name="from_second_half_start_date">
                    {" "}
                    <Switch
                      checked={editLeaveDetails.from_second_half_start_date}
                      onChange={() =>
                        setEditLeaveDetails({
                          ...editLeaveDetails,
                          from_second_half_start_date:
                            !editLeaveDetails.from_second_half_start_date,
                        })
                      }
                    />
                    Second half day
                  </Form.Item>
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (To)"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (To)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leaveInputField"
                    disabled={editLeaveDetails.leave_type ? false : true}
                  // initialValues={employmentDetails?.joining_date ? dayjs(employmentDetails["joining_date"], "YYYY-MM-DD") : null}
                  />
                </Form.Item>
                <div
                  style={{
                    marginBottom: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Form.Item
                    // label="Date (From)"
                    name="till_first_half_end_date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Date (From)!",
                      },
                    ]}
                  >
                    <Switch
                      checked={editLeaveDetails.till_first_half_end_date}
                      name="from_second_half_start_date"
                      onChange={() =>
                        setEditLeaveDetails({
                          ...editLeaveDetails,
                          till_first_half_end_date:
                            !editLeaveDetails.till_first_half_end_date,
                        })
                      }
                    />
                    First half day
                  </Form.Item>
                </div>
              </Col>
              {leaveRuleByDate?.message?.length > 0 && (
                <div
                  style={{
                    background: "#FDF3CF",
                    height: "40px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <img src={Error} alt="" />
                    <p style={{ margin: "0px" }}>
                      {leaveRuleByDate?.message[0]}
                    </p>
                  </div>
                </div>
              )}
            </Row>

            <Row gutter={[32, 32]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  label="Reason"
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: "Please add Reason!",
                    },
                  ]}
                >
                  <TextArea
                    rows={5}
                    placeholder="Enter Your Reason"
                    className="leaveTextArea"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button htmlType="submit" className="applyLeave">
              {appliedLeaveId ? "Edit Leave" : "Apply Leave"}
            </Button>
          </Form>
        </Modal>

        {/* 
                #####################################################################################################
                ###################################### Face Id Modal  ############################################
                #####################################################################################################
            */}
        <>
          {faceIdModel &&
            (personalAttendanceDetails.face_present ? (
              <Modal
                centered
                open={faceIdModel}
                footer={null}
                width={800}
                onCancel={handleModalClose}
                maskClosable={false}
                keyboard={false}
              >
                {screenSize.width < 768 ? (
                  <>
                    {
                      <center>
                        <div
                          style={{
                            backgroundImage: `url(${faceStatus === "capture"
                              ? mobileCaptureFrame
                              : faceStatus === "success"
                                ? cameraFaceSuccess
                                : mobileErrorFrame
                              })`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            height: "330px",
                            width: "auto",
                          }}
                        >
                          <div style={{}}>
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={true}
                              height={300}
                              width={220}
                              style={{
                                marginTop: "15px",
                              }}
                            />
                          </div>
                        </div>
                        {/* <p style={{ color: "red" }}>{faceError}</p> */}
                        {attendanceError.isError && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              border: "1px solid red",
                              padding: "10px",
                              borderRadius: "4px",
                              margin: "20px 0px",
                            }}
                          >
                            <WarningFilled
                              style={{ color: "red", fontSize: "20px" }}
                            />
                            <p style={{ color: "red", margin: "0px" }}>
                              {attendanceError.message}
                            </p>
                          </div>
                        )}
                        {excemptionAllowed ? (
                          <>
                            <TextArea
                              rows={4}
                              value={excemptionReason}
                              onChange={(e) =>
                                setExcemptionReason(e.target.value)
                              }
                            />

                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "20px",
                                justifyContent: "end",
                              }}
                            >
                              <button
                                style={{
                                  background: "#008080",
                                  color: "white",
                                  border: "none",
                                  height: "40px",
                                  borderRadius: "5px",
                                  padding: "0px 16px",
                                }}
                                onClick={() =>
                                  submitExcemptionRequest(
                                    attendanceWithGeoLocation,
                                    latitude,
                                    longitude
                                  )
                                }
                              >
                                Exemption Request
                              </button>
                              <button
                                style={{
                                  background: "#EEEEEE",
                                  color: "#545252",
                                  border: "none",
                                  height: "40px",
                                  borderRadius: "5px",
                                  padding: "0px 16px",
                                }}
                                onClick={() => {
                                  handleModalClose();
                                  setExcemptionAllowed(false);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (
                          <button
                            className="captureButton"
                            onClick={() =>
                              capture(
                                attendanceWithGeoLocation,
                                latitude,
                                longitude
                              )
                            }
                            disabled={capturePhotoStatus}
                            style={{ color: "white", background: "#008080", width: "170px", height: "40px", border: "none", borderRadius: "5px" }}
                          >
                            {capturePhotoStatus ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    spin
                                    style={{ color: "white" }}
                                  />
                                }
                              />
                            ) : (
                              "Capture Photo"
                            )}
                          </button>
                        )}
                      </center>
                    }
                  </>
                ) : (
                  <>
                    {excemptionAllowed ? (
                      <Row>
                        <Col span={10}>
                          <div
                            style={{
                              backgroundImage: `url(${faceStatus === "capture"
                                ? cameraFaceCapture
                                : faceStatus === "success"
                                  ? cameraFaceSuccess
                                  : cameraFaceError
                                })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "left",
                              height: "300px",
                              // display: "flex",
                              // justifyContent: "space-between",
                            }}
                          >
                            <div style={{ left: "6%", position: "absolute" }}>
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                mirrored={true}
                                height={300}
                                width={220}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col span={14}>
                          <p
                            style={{
                              fontSize: "28px",
                              fontWeight: "400",
                              margin: "0px",
                            }}
                          >
                            Face towards frame
                          </p>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "400",
                              color: "#717070",
                              margin: "0px",
                            }}
                          >
                            Face in the camera frame to mark your attendance
                          </p>
                          {attendanceError.isError && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                border: "1px solid red",
                                padding: "10px",
                                borderRadius: "4px",
                                marginBottom: "20px",
                              }}
                            >
                              <WarningFilled
                                style={{ color: "red", fontSize: "20px" }}
                              />
                              <p style={{ color: "red", margin: "0px" }}>
                                {attendanceError.message}
                              </p>
                            </div>
                          )}
                          <TextArea
                            rows={4}
                            placeholder="Enter Reason for Exemption"
                            value={excemptionReason}
                            onChange={(e) =>
                              setExcemptionReason(e.target.value)
                            }
                          />

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "20px",
                              justifyContent: "end",
                            }}
                          >
                            <button
                              style={{
                                background: "#008080",
                                color: "white",
                                border: "none",
                                height: "40px",
                                borderRadius: "5px",
                                padding: "0px 16px",
                              }}
                              onClick={() =>
                                submitExcemptionRequest(
                                  attendanceWithGeoLocation,
                                  latitude,
                                  longitude
                                )
                              }
                            >
                              Exemption Request
                            </button>
                            <button
                              style={{
                                background: "#EEEEEE",
                                color: "#545252",
                                border: "none",
                                height: "40px",
                                borderRadius: "5px",
                                padding: "0px 16px",
                              }}
                              onClick={() => {
                                handleModalClose();
                                setExcemptionAllowed(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <center>
                        <div
                          style={{
                            backgroundImage: `url(${faceStatus === "capture"
                              ? cameraFaceCapture
                              : faceStatus === "success"
                                ? cameraFaceSuccess
                                : cameraFaceError
                              })`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            height: "300px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ left: "36%", position: "absolute" }}>
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={true}
                              height={300}
                              width={220}
                            />
                          </div>
                        </div>
                        {/* <p style={{ color: "red" }}>{faceError}</p> */}
                        {attendanceError.isError && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              border: "1px solid red",
                              padding: "10px",
                              borderRadius: "4px",
                              marginBottom: "20px",
                            }}
                          >
                            <WarningFilled
                              style={{ color: "red", fontSize: "20px" }}
                            />
                            <p style={{ color: "red", margin: "0px" }}>
                              {attendanceError.message}
                            </p>
                          </div>
                        )}
                        <button
                          style={{ color: "white", background: "#008080", width: "170px", height: "40px", border: "none", borderRadius: "5px" }}
                          onClick={() =>
                            capture(
                              attendanceWithGeoLocation,
                              latitude,
                              longitude
                            )
                          }
                          disabled={capturePhotoStatus}
                        >
                          {capturePhotoStatus ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  spin
                                  style={{ color: "white" }}
                                />
                              }
                            />
                          ) : (
                            "Capture Photo"
                          )}
                        </button>
                      </center>
                    )}
                  </>
                )}
              </Modal>
            ) : (
              <Modal
                centered
                open={faceIdModel}
                footer={null}
                width={900}
                onCancel={handleAddFaceModalClose}
                maskClosable={false}
                keyboard={false}
                closable={faceStatus === "success" ? false : true}
              >
                {firstModelAddFace ? (
                  screenSize.width < 768 ? (
                    // mobile view
                    <div className="flexCenter">
                      <div style={{ padding: "10px 30px" }}>
                        <center>
                          <div>
                            <p style={{ fontSize: "20px" }}>
                              How to set Up Face ID
                            </p>
                            <p style={{ color: "#717070", fontSize: "14px" }}>
                              Look up into the camera till your face is getting
                              completely captured.
                            </p>
                            <img
                              src={addFaceImg}
                              alt="addFace"
                              style={{ marginTop: "20px" }}
                            />
                          </div>
                          <button
                            className="button"
                            style={{ marginTop: "50px" }}
                            onClick={() => {
                              setFirstModelAddFace(false);
                            }}
                          >
                            Get Started
                          </button>
                        </center>
                      </div>
                    </div>
                  ) : (
                    // desktop view
                    <div className="flexCenter" style={{ padding: "50px" }}>
                      <div style={{ paddingRight: "60px" }}>
                        <img src={addFaceImg} alt="addFace" />
                      </div>
                      <div>
                        <p style={{ fontSize: "28px" }}>
                          How to set Up Face ID
                        </p>
                        <p style={{ color: "#717070", fontSize: "18px" }}>
                          Look up into the camera till your face is getting
                          completely captured.
                        </p>
                        <button
                          className="button"
                          onClick={() => {
                            setFirstModelAddFace(false);
                          }}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  )
                ) : (
                  <>
                    {secondModelAddFace && (
                      <>
                        <div>
                          {screenSize.width < 768 ? (
                            // mobile view
                            <>
                              <div>
                                {faceStatus === "capture" && (
                                  <>
                                    <center>
                                      <p
                                        style={{
                                          fontSize: "20px",
                                          paddingTop: "0px",
                                        }}
                                      >
                                        Face towards frame
                                      </p>
                                      {/* <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      First position your face in the camera
                                      frame. Then move your head ina a circle to
                                      show all the angles of your face
                                    </span> */}
                                      <div
                                        style={{
                                          backgroundImage: `url(${faceStatus === "capture"
                                            ? mobileCaptureFrame
                                            : faceStatus === "success"
                                              ? cameraFaceSuccess
                                              : mobileErrorFrame
                                            })`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          height: "330px",
                                          // marginBottom: "20px",
                                        }}
                                      >
                                        <Webcam
                                          audio={false}
                                          ref={webcamRef}
                                          screenshotFormat="image/jpeg"
                                          height={300}
                                          width={220}
                                          mirrored={true}
                                          style={{
                                            marginTop: "15px",
                                          }}
                                        />
                                      </div>
                                      <button
                                        className="button"
                                        onClick={() => addFace()}
                                      >
                                        Add Face
                                      </button>
                                    </center>
                                  </>
                                )}
                                {faceStatus === "success" && (
                                  <>
                                    <center>
                                      <p
                                        style={{
                                          fontSize: "20px",
                                          paddingTop: "0px",
                                        }}
                                      >
                                        {/* Face towards frame */}
                                        Face added successfully
                                      </p>
                                      {/* <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Face added successfully
                                    </span> */}
                                      <div
                                        style={{
                                          backgroundImage: `url(${faceStatus === "capture"
                                            ? cameraFaceCapture
                                            : faceStatus === "success"
                                              ? mobileSucessFrame
                                              : mobileErrorFrame
                                            })`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          height: "330px",
                                          // marginBottom: "20px",
                                        }}
                                      >
                                        {capturedImage ? (
                                          <img
                                            src={capturedImage}
                                            alt="Captured"
                                            style={{ marginTop: "20px" }}
                                          />
                                        ) : (
                                          <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            height={300}
                                            width={220}
                                            mirrored={true}
                                            style={{
                                              marginTop: "15px",
                                            }}
                                          />
                                        )}
                                      </div>
                                      <button
                                        className="button"
                                        onClick={() => {
                                          setFaceIdModel(false);
                                          setFaceStatus("capture");
                                          fetchData();
                                        }}
                                      >
                                        Done
                                      </button>
                                    </center>
                                  </>
                                )}
                                {faceStatus === "error" && (
                                  <>
                                    <center>
                                      <p
                                        style={{
                                          fontSize: "20px",
                                          paddingTop: "0px",
                                        }}
                                      >
                                        Face towards frame
                                      </p>
                                      {/* <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "14px",
                                        margin: "0px",
                                      }}
                                    >
                                      Your face must match an existing Face
                                      appearance.
                                    </span> */}
                                      <div
                                        style={{
                                          backgroundImage: `url(${faceStatus === "capture"
                                            ? cameraFaceCapture
                                            : faceStatus === "success"
                                              ? cameraFaceSuccess
                                              : mobileErrorFrame
                                            })`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          height: "330px",
                                          // marginBottom: "20px",
                                        }}
                                      >
                                        <Webcam
                                          audio={false}
                                          ref={webcamRef}
                                          screenshotFormat="image/jpeg"
                                          height={300}
                                          width={220}
                                          mirrored={true}
                                          style={{
                                            marginTop: "15px",
                                          }}
                                        />
                                      </div>
                                      <button
                                        className="button"
                                        onClick={() => addFace()}
                                      >
                                        Add Face
                                      </button>
                                    </center>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            // desktop view
                            <div
                              style={{
                                backgroundImage: `url(${faceStatus === "capture"
                                  ? cameraFaceCapture
                                  : faceStatus === "success"
                                    ? cameraFaceSuccess
                                    : cameraFaceError
                                  })`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "left",
                                height: "300px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ width: "40%", marginLeft: "25px" }}>
                                {capturedImage ? (
                                  <img
                                    src={capturedImage}
                                    alt="Captured"
                                    style={{ marginTop: "70px" }}
                                  />
                                ) : (
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    height={300}
                                    width={220}
                                    mirrored={true}
                                  />
                                )}
                              </div>
                              <div style={{ width: "60%" }}>
                                {faceStatus === "capture" && (
                                  <>
                                    <p
                                      style={{
                                        fontSize: "28px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      Face towards frame
                                    </p>
                                    <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Look up into the camera till your face is
                                      getting completely captured.
                                    </span>
                                    <br />
                                    <br />
                                    <button
                                      className="button"
                                      onClick={() => addFace()}
                                    >
                                      Add Face
                                    </button>
                                  </>
                                )}
                                {faceStatus === "success" && (
                                  <>
                                    <p
                                      style={{
                                        fontSize: "28px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      Face towards frame
                                    </p>
                                    <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Face added successfully
                                    </span>
                                    <br />
                                    <br />
                                    <button
                                      className="button"
                                      onClick={() => {
                                        setFaceIdModel(false);
                                        setFaceStatus("capture");
                                        fetchData();
                                      }}
                                    >
                                      Done
                                    </button>
                                  </>
                                )}
                                {faceStatus === "error" && (
                                  <>
                                    <p
                                      style={{
                                        fontSize: "28px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      Face towards frame
                                    </p>
                                    <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Your face must match an existing Face
                                      appearance.
                                    </span>
                                    <br />
                                    <br />
                                    <button
                                      className="button"
                                      onClick={() => addFace()}
                                    >
                                      Add Face
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Modal>
            ))}
        </>

        {/* 
                #####################################################################################################
                ###################################### Manual Modal  ############################################
                #####################################################################################################
            */}
        <>
          {mannualModel && (
            <Modal
              centered
              open={mannualModel}
              footer={null}
              width={800}
              onCancel={handleMannualModalClose}
              maskClosable={false}
              keyboard={false}
            >
              {screenSize.width < 768 ? (
                // mobile view
                <>
                  {isCheckOut ? (
                    <div>
                      <center>
                        <img
                          src={mannual}
                          alt="mannual"
                          width={250}
                          height={80}
                        />
                        <br />
                        <br />
                        <p>Allready Marked Attendance</p>
                      </center>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <center>
                          <p
                            style={{
                              fontSize: "20px",
                              margin: "0px",
                              marginBottom: "10px",
                            }}
                          >
                            Mark Attendance
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              color: "#717070",
                              margin: "0px",
                              marginBottom: "40px",
                            }}
                          >
                            Click on button to mark
                            <br />
                            your
                            <span
                              style={{ color: "#008080", fontWeight: "600" }}
                            >
                              {isLogin ? " Out " : " In "}
                            </span>
                            Time
                          </p>
                          <img
                            src={mannual}
                            alt="mannual"
                            width={250}
                            height={80}
                          />

                          <div style={{ marginTop: "80px" }}>
                            {attendanceError.isError && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  border: "1px solid red",
                                  padding: "10px",
                                  borderRadius: "4px",
                                  marginBottom: "20px",
                                }}
                              >
                                <WarningFilled
                                  style={{ color: "red", fontSize: "20px" }}
                                />
                                <p style={{ color: "red", margin: "0px" }}>
                                  {attendanceError.message}
                                </p>
                              </div>
                            )}
                            <button
                              style={{ color: "white", background: "#008080", width: "70px", height: "70px", border: "none", borderRadius: "5px" }}
                              onClick={() => captureMannualAttendance()}
                              disabled={capturePhotoStatus}
                            >
                              {capturePhotoStatus ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      spin
                                      style={{ color: "white" }}
                                    />
                                  }
                                />
                              ) : (
                                <>
                                  {!personalAttendanceDetails?.is_checked_in_today
                                    ? "Start"
                                    : "End"}
                                </>
                              )}
                            </button>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                // desktop view
                <>
                  {isCheckOut ? (
                    <div>
                      <center>
                        <img
                          src={mannual}
                          alt="mannual"
                          width={250}
                          height={80}
                        />
                        <br />
                        <br />
                        <p>Allready Marked Attendance</p>
                      </center>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                      }}
                    >
                      <div>
                        <img src={mannual} alt="mannual" />
                      </div>
                      <div style={{ paddingLeft: "20px" }}>
                        <p
                          style={{
                            fontSize: "28px",
                            margin: "0px",
                            marginBottom: "10px",
                          }}
                        >
                          Mark Attendance
                        </p>
                        <p
                          style={{
                            fontSize: "20px",
                            color: "#717070",
                            margin: "0px",
                            marginBottom: "40px",
                          }}
                        >
                          Click on button to mark your
                          <span style={{ color: "#008080", fontWeight: "600" }}>
                            {isLogin ? " Out " : " In "}
                          </span>
                          Time
                        </p>
                        {attendanceError.isError && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              border: "1px solid red",
                              padding: "10px",
                              borderRadius: "4px",
                              marginBottom: "20px",
                            }}
                          >
                            <WarningFilled
                              style={{ color: "red", fontSize: "20px" }}
                            />
                            <p style={{ color: "red", margin: "0px" }}>
                              {attendanceError.message}
                            </p>
                          </div>
                        )}

                        <button
                          style={{ color: "white", background: "#008080", width: "60px", height: "40px", border: "none", borderRadius: "5px" }}
                          onClick={() => captureMannualAttendance()}
                          disabled={capturePhotoStatus}
                        >
                          {capturePhotoStatus ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  spin
                                  style={{ color: "white" }}
                                />
                              }
                            />
                          ) : (
                            <>
                              {!personalAttendanceDetails?.is_checked_in_today
                                ? "Start"
                                : "End"}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Modal>
          )}
        </>
        <Modal
          title="Reimbursement"
          centered
          open={employeeReimbursement}
          // onOk={() => setReviseSalary(false)}
          onCancel={() => setEmployeeReimbursement(false)}
          width={1000}
          footer={null}
        >
          <Form layout="vertical" onFinish={onFinish}>
            <Row gutter={[32, 32]}>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter title"
                    onChange={(e) => onHandleChange("title", e.target.value)}
                    value={EmployeeReimbursementData.title}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[32, 32]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter date!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leaveInputField"
                    onChange={(date, dateString) =>
                      onHandleChange("date", dateString)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Amount"
                    onChange={(e) => onHandleChange("amount", e.target.value)}
                    value={EmployeeReimbursementData.amount}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 32]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Invoice ID"
                  name="InvoiceId"
                  rules={[
                    {
                      required: true,
                      message: "Please enter invoice id!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Invoice Id"
                    onChange={(e) =>
                      onHandleChange("invoice_id", e.target.value)
                    }
                    value={EmployeeReimbursementData.invoice_id}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Merchant Name"
                  name="merchant_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter merchant name!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Merchant Name"
                    onChange={(e) =>
                      onHandleChange("merchant_name", e.target.value)
                    }
                    value={EmployeeReimbursementData.merchant_name}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Description"
                    onChange={(e) =>
                      onHandleChange("description", e.target.value)
                    }
                    value={EmployeeReimbursementData.description}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Upload File"
                name="file"
              // rules={[
              //     {
              //         required: true,
              //         message: 'Please enter your Date (To)!',
              //     },
              // ]}
              >
                <p>Upload File</p>
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeInBytes = 5 * 1024 * 1024;
                      {
                        console.log("e.target.files[0]", e.target.files[0]);
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          onHandleChange("attach_file", selectedFile);
                        }
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="file-upload"
                      className="file-upload-label"
                    ></label>
                    <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p>
                  </div>
                </div>
              </Form.Item>
            </Col>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        </Modal>
      </div >
    </ContainerLayout >
  );
}

export default Dashboard;
