import React, { useState, useEffect, useRef } from "react";
import Location from "../Location/Location";
import Setting from "../Setting/Setting";
import { Tabs } from "antd";
import Meeting from "../ My Meeting/Meeting";
import TeamLoction from "../TeamLocation/TeamLocation";
import TeamMeeting from "../TeamMeeting/TeamMeeting";
import Dashboard from "../Dashboard/Dashboard";
import Layout from "../../../../component/ContainerLayout/ContainerLayout";
import request from "../../../../utils/axios";
import { useParams } from "react-router-dom";
import TeamSetting from "../Setting/Setting";
function TrackingTabs() {
  const ref = useRef();
  const { id } = useParams();
  const [tabsValue, setTabsValue] = useState("1");
  const [reporting_manager, setReporting_manager] = useState();

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTabsValue(id);
  }, []);
  const changeTabsValue = (e) => {
    setTabsValue(e);
  };
  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    const apiUrl = `tracking/is_reporting_manager/`;
    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });
      // console.log(res);
      localStorage.setItem(
        "Is_Reporting_Mannger",
        JSON.stringify(res?.is_reporting_manager)
      );
      setReporting_manager(res.is_reporting_manager);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const items1 = [
    {
      key: "1",
      label: "Dashboard",
      children: (
        <Layout>
          <Dashboard changeTabsValue={changeTabsValue} />
        </Layout>
      ),
    },
    {
      key: "2",
      label: "Team Location",
      children: (
        <Layout>
          <TeamLoction tabsValue={tabsValue} />
        </Layout>
      ),
    },
    {
      key: "3",
      label: "Team Meeting",
      children: (
        <Layout>
          <TeamMeeting tabsValue={tabsValue} />
        </Layout>
      ),
    },
    {
      key: "4",
      label: "My Meeting",
      children: (
        <Layout>
          <Meeting changeTabsValue={changeTabsValue} tabsValue={tabsValue} />
        </Layout>
      ),
    },
    {
      key: "5",
      label: "Team Setting",
      children: (
        <Layout>
          <TeamSetting
            changeTabsValue={changeTabsValue}
            tabsValue={tabsValue}
          />
        </Layout>
      ),
    },
  ];
  const items2 = [
    {
      key: "1",
      label: "My Meeting",
      children: (
        <Layout>
          <Meeting tabsValue={tabsValue} />
        </Layout>
      ),
    },
  ];

  return (
    <div>
      <Tabs
        activeKey={tabsValue}
        items={reporting_manager ? items1 : items2}
        onChange={changeTabsValue}
      />
    </div>
  );
}

export default TrackingTabs;
