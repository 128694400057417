import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Steps } from 'antd';

import PersonalDetails from '../OnBoarding/PersonalDetails/PersonalDetails';
import EmploymentDetails from './EmployementDetails/EmploymentDetails';
import PersonalDetail from './PersonalDetails/PersonalDetails';

import { setOrgDetails, setEmploymentDetails, setPersonalDetails, setPolicies } from "../../redux/reducer/EmployeeReducer"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import { request } from '../../utils/axios';
import "./Employee.scss"
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Policies from './Policies/Policies';
import SalaryDetails from './SalaryDetails/SalaryDetails';
import dayjs from 'dayjs';
import CustomStepper from '../../component/CustomStepper/CustomStepper';
import getScreenSize from "../../utils/getScreenSize";
import ContainerLayout from '../../component/ContainerLayout/ContainerLayout';

const steps = [
  {
    title: 'Personal Details',
    // content: 'Please enter your personal details.',
    // icon: 'user',
  },
  {
    title: 'Employment Details',
    // content: 'Please enter your employment details.',
    // icon: 'briefcase',
  },
  {
    title: 'Policies',
    // content: <Policies />,
    // icon: 'briefcase',
  },
  {
    title: 'Salary Details',
    // content: 'Please enter your personal details.',
    // icon: 'user',
  }
];

function Employee() {
  const dispatch = useDispatch();
  const params = useParams();
  const org = useSelector((state) => state.employee.org)
  let personalDetails = useSelector((state) => state.employee.personalDetails)
  let employmentDetails = useSelector((state) => state.employee.employmentDetails)
  const policies = useSelector((state) => state.employee.policies)
  const screenSize = getScreenSize()
  const navigate = useNavigate();
  // let employmentDetails = useSelector((state) => state.employee.employmentDetails)

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };


  const fetchEmployeeDetails = async () => {
    try {
      let data = await request({
        method: "get",
        url: `/employee/employement/details/${params.id}`,

      })
      console.log("data", data);
      // const dob = moment(data.data?.dob).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      // want to convert to iso foramt 

      // data.data.dob = dob
      const dobDate = new Date(data.data.dob).toISOString();

      data.data.dob = dayjs(dobDate)

      const anniversaryDate = new Date(data.data.anniversary).toISOString();

      data.data.anniversary = dayjs(anniversaryDate)



      const joining_date = new Date(data.data?.joining_date).toISOString();
      data.data.joining_date = dayjs(joining_date)

      dispatch(setOrgDetails(data.data.org));
      dispatch(setPersonalDetails(data.data));
      dispatch(setEmploymentDetails(data.data));
      dispatch(setPolicies(data.data))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchEmployeeDetails();
    }

  }, [])

  useEffect(() => {
    console.log("org....", org === "");
    if (org === "") {
      console.log("back ");
      // navigate("/admin/employee")
      navigate(-2)
    }
  }, [org])





  return (
    <div className='employeeContainer'>
      {
        screenSize.width < 768 ?
          // mobile view
          <>
            <div className='mobileStepsContainer' >
              <CustomStepper steps={steps} current={current} setCurrent={setCurrent} />
            </div>

            <div>
              {current === 0 && (
                <PersonalDetail next={next} />
              )}
              {current === 1 && (
                <EmploymentDetails prev={prev} next={next} />
              )}
              {
                current === 2 && (
                  <Policies prev={prev} next={next} />
                )
              }
              {
                current === 3 && (
                  <SalaryDetails prev={prev} />
                )
              }
            </div>
          </>
          :
          <>
            <ContainerLayout>
              <p className='heading'>Add Employee</p>

              <Row gutter={[32, 32]}>
                <Col xs={{ span: 24 }} lg={{ span: 6 }} >
                  <div className='stepsContainer' >
                    <Steps direction='vertical' current={current} items={steps} />
                  </div>
                </Col>

                <Col xs={{ span: 24 }} lg={{ span: 18 }} >
                  <div  >
                    {current === 0 && (
                      <PersonalDetail next={next} />
                    )}
                    {current === 1 && (
                      <EmploymentDetails prev={prev} next={next} />
                    )}
                    {
                      current === 2 && (
                        <Policies prev={prev} next={next} />
                      )
                    }
                    {
                      current === 3 && (
                        <SalaryDetails prev={prev} />
                      )
                    }
                  </div>
                </Col>
              </Row>
            </ContainerLayout>
          </>
      }





    </div>
  )
}

export default Employee