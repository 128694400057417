import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import "./SearchBar.scss"

function SearchBar({
    setTableData, // set table data for search employee 
    setHasMore,
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
    setPagination,
    // placeholderText
}) {
    const [searchList, setSearchList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const handleSelectChange = async (id, name) => {
        try {
            setSearchValue(name)
            const response = await request({
                method: "get",
                url: `admin/employee-function/detail?id=${id}`,
            })
            setTableData([response.data])
            setHasMore(false)
            setSearchList([])
        }
        catch (err) {
            console.log(err);
        }
    }

    const search = async (data) => {
        try {
            setSearchValue(data);
            const response = await request({
                method: "get",
                url: `admin/employee-function/search?name=${data}`,
            })
            setSearchList(response.data)
            if (data === "") {
                setSearchList([])
                const newPagination = {
                    current: 1,
                    pageSize: 12,
                    total: 0,
                };
                setPagination(newPagination)
                const dataList = []
                setHasMore(true)
                fetchTableDataFromApi(newPagination, dataList);
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <Input
                type="text"
                // placeholder={placeholderText}
                onChange={(e) => search(e.target.value)}
                placeholder="Search Employee Functions"
                size='small'
                value={searchValue}
                prefix={<SearchOutlined />}
                allowClear
            />
            {searchList.length > 0 && (
                <ul>
                    {searchList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.id, item.name)}>
                            {item.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default SearchBar