import React, { useEffect, useRef, useState } from 'react';
import getScreenSize from "../../../../utils/getScreenSize";
import add from "../../../../assets/Org/add.svg";
import request from '../../../../utils/axios';
import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../component/CommonTable/CommonTable';

function CompOffFullView() {
  const screenSize = getScreenSize();
  const [addCompOffModal, setAddCompOffModal] = useState(false)
  const [form] = Form.useForm();
  const [employeeList, setEmployeeList] = useState([]);
  const [compOffLeaveTypeList, setCompOffLeaveTypeList] = useState([]);
  const navigate = useNavigate();
  const [compOffList, setCompOffList] = useState([]);
  const ref = useRef(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5, // Number of items per page
    total: 0,
})
  const compOffColumn = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Compensatory Off",
      dataIndex: "leaves_number",
      key: "leaves_number",
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
  ];
  const fetchCompOffList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/comp-off/leaves/all/?page=${pagination.current}&page_size=${pagination.pageSize}`,
      });

      setCompOffList(response?.pagination_data.data);
      setPagination({
        ...pagination,
        total: response?.pagination_data?.total,
      })
    } catch (err) {
      console.log(err);
    }
  }

  const onFinishCompOff = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `leave/employee/leave-type/add/`,
        data: values
      });

      setAddCompOffModal(false);
      fetchCompOffList();
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchEmployeeList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `/get-all-employees`,
      });

      setEmployeeList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchCompOffLeaveTypeList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/comp-off/leaves-types/all`,
      });

      setCompOffLeaveTypeList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchCompOffList();
    fetchEmployeeList();
    fetchCompOffLeaveTypeList();
  }, [pagination.current]);

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          ?
          <div>
            <div style={{ padding: "0px 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p style={{ fontSize: "24px", color: "#161616", fontWeight: "500px", margin: "0px" }}>Compensatory Off</p>
              <div style={{ display: "flex", gap: "10px" }}>
                <img src={add} alt="add" className="addImage"
                  onClick={() => {
                    navigate("/admin/leave/comp-off/form", { replace: true })
                  }}
                />


              </div>
            </div>

            <div>
              {
                compOffList?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div style={{ padding: "0px 16px", marginTop: "15px" }}>
                        <Row>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Employee Name</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.employee}</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Date</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.created_at}</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Compensatory off</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.leaves_number}</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Leave Type</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.leave_type}</p>
                          </Col>
                        </Row>
                      </div>
                      {
                        index !== compOffList?.length - 1 && (
                          <hr className="divider" />
                        )
                      }
                    </div>
                  )
                })
              }
            </div>

          </div>
          :
          <div>
            <div className="leavePolicyContainer">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // gap: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{
                    fontSize: "24px",
                    fontWeight: "500",
                  }}>Compensatory Off</p>

                  <Button
                    style={{
                      background: "#008080",
                      color: "white",
                      height: "40px",
                    }}
                    onClick={() => setAddCompOffModal(true)}
                  >
                    Add Compensatory Off
                  </Button>
                </div>
                <CommonTable
                  columns={compOffColumn}
                  data={compOffList}
                  pagination={pagination}
                  setPagination={setPagination}
                  handleRowClick={() => { }}
                />
                {/* <Table
                  columns={compOffColumn}
                  dataSource={compOffColumn}
                  pagination={false}
                /> */}
              </div>
            </div>
          </div>
      }

      <Modal
        open={addCompOffModal}
        onOk={() => { }}
        onCancel={() => {
          setAddCompOffModal(false);
        }}
        footer={null}
        centered
        width={540}
      >
        <p className="leavePolicyModelHeading">Comp Off</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishCompOff}
        >
          <Form.Item
            label="Employee Name"
            name="employee"
            rules={[
              {
                required: true,
                message: "Please enter your Employee Name!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
            >
              {employeeList.map((option, index) => (
                <Select.Option key={index} value={option.employement_number}>
                  {option.employee_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Leave Type"
            name="leave_type"
            rules={[
              {
                required: true,
                message: "Please enter your Leave Type!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
            >
              {compOffLeaveTypeList.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Number Of Comp Off"
            name="leaves_number"
            rules={[
              {
                required: true,
                message: "Please enter your Number Of Comp Off!",
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
          <Button
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              background: "#008080",
              height: "44px",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  )
}

export default CompOffFullView