import React, { useEffect, useState } from 'react'
import moment from 'moment';

import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import request from '../../../../utils/axios';
import useScreenSize from '../../../../utils/getScreenSize';
import calendar from "../../../../assets/calendar.svg"
import location from "../../../../assets/location.svg"
import InfiniteScroll from '../../../../component/InfiniteScroll/InfiniteScroll';
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import "../../User/ReferralOpening/ReferralOpening.scss"
import AddRequisition from '../AddRequisition/AddRequisition';
import mobileStyle from "./MobileRequisition.module.scss"
import rupee from "../../../../assets/activePayrollImg.svg"
import workMode from "../../../../assets/workMode.png"
import add from "../../../../assets/Org/add.svg";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function Requisition() {
    const navigate = useNavigate();

    const requisitionColumns = [
        {
            title: 'Job Ttile',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Salary Range',
            dataIndex: 'salary_range',
            key: 'salary_range',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text, record) =>
                <div>
                    {
                        record.location.map((item, index) => {
                            return (
                                <span key={index}>
                                    <span>{item}</span>
                                    {
                                        record?.location?.length - 1 !== index && <span> , </span>
                                    }
                                </span>

                            )
                        })
                    }
                </div>
        },
        {
            title: 'Function',
            dataIndex: 'job_function',
            key: 'job_function',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <button className={`${text}StatusButton`}>{text}</button>
            ),
        }
    ]

    const screenSize = useScreenSize();
    const [requisitionList, setRequisitionList] = useState([])

    const fetchRequisitionData = async (page, page_size) => {

        const data = await request({
            method: "get",
            url: `recruitment/employee/requisitions/all?page=${page}&page_size=${page_size}`,
        })
        setRequisitionList([...data.pagination_data.data])
        return data
    }

    useEffect(() => {
        fetchRequisitionData(1, 10);
    }, [])

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <>
                    <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                        <div>
                            <p className={mobileStyle.candidate}>
                                {item.raised_by}
                            </p>
                            <p className={mobileStyle.jobTitle}>{item.industry}</p>
                            <p className={mobileStyle.date}>
                                <img src={rupee} alt="rupee" width={20} height={20} />{" "}
                                {item.salary_range}
                            </p>
                            <p className={mobileStyle.time}>
                                <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                                {item?.job_function}
                            </p>
                            <p className={mobileStyle.location}>
                                <img src={location} alt="location" width={20} height={20} />{" "}
                                {
                                    item.location.map((el, elIndex) => {
                                        return <span key={elIndex}>{el}
                                            {
                                                item?.location?.length - 1 !== elIndex && <span> , </span>
                                            }
                                        </span>
                                    })
                                }
                            </p>
                        </div>
                    </div>
                    <hr />
                </>
            )
        })
    }
    return (
        <div>
            {
                screenSize.width < 768 ?  // mobile view

                    <div className={mobileStyle.internalJobOpening}>
                        <div className={mobileStyle.headerContainer}>
                            <p className={mobileStyle.heading}>Requisition</p>
                            {/* <img src={fullView} alt="fullView" /> */}
                            <img src={add} alt="add" width={32} height={32}
                                onClick={() => navigate("/recruitment/requisition-form", { replace: true })}
                            />
                        </div>
                        <InfiniteScrollComponent
                            apiEndpoint="recruitment/employee/requisitions/all"
                            renderUI={renderUI}
                            initialPage={1}
                        />

                    </div>

                    :

                    <div className='Container'>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                         */}

                        {/* <br /> */}
                        <div className='referralSubContainer'>
                            <div className="flexSpaceBetween">
                                <h1 style={{ margin: "0" }}>Requisition</h1>
                                <AddRequisition />
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <DynamicTable
                                    fetchData={fetchRequisitionData}
                                    columns={requisitionColumns}
                                    handleRowClick={() => { }}
                                    pageSize={5}
                                    showPagination={true}
                                />
                            </div>
                        </div>

                    </div>

            }

        </div>
    )
}

export default Requisition 