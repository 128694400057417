import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table, Tabs, Modal, Form, Input, Button, DatePicker } from 'antd'
import DashBoard from "../Dashboard/Dashboard";
import SalaryStruture from "../SalaryStructure/SalaryStructure";
import SalaryStructureFullView from '../SalaryStructureFullView/salaryStructureFullView';
import EmployeeSalaryManager from '../EmployeeSalaryManager/EmployeeSalaryManager';
import ReimbursementFullView from '../ReimbursementFullView/ReimbursementFullView';
import AdvanceSalaryFullView from '../AdvanceSalaryFullView/AdvanceSalaryFullView';
import LoanFullView from '../LoanFullView/LoanFullView';
import EmployeeDeclarationDetail from '../EmployeeDeclaration/EmployeeDeclarationDetail/EmployeeDeclarationDetail';
// import AdminPayRollProcessing from '../AdminPayRollProcessing/AdminPayRollProcessing';
import PayRollProcessing from '../PayRollProcessing/PayRollProcessing';
import PayrollSummary from '../PayrollSummary/PayrollSummary';
import HoldSalaryFullView from '../HoldSalaryFullView/HoldSalaryFullView';
import EmployeeDeclarationSummary from '../EmployeeDeclarationSummary/EmployeeDeclarationSummary';
import TaxSetting from '../TaxSetting/TaxSetting';
import Challan24Q from '../24QChallan/24QChallan';
import PayRegister from "../PayRegister/PayRegister"
import OvertimeSetting from '../OvertimeSetting/OvertimeSetting';
import { useParams } from 'react-router-dom';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';
function PayrollAdminTabs() {
    const ref = useRef(null);
    const [tabsValue, setTabsValue] = useState("1")
    const changeTabsValue = (e) => {
        console.log("e", e);
        setTabsValue(e)
    }

    const params = useParams();
    const items = [
        {
            key: '1',
            label: 'Dashboard',
            children: <ContainerLayout><DashBoard changeTabsValue={changeTabsValue} tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '2',
            label: 'Payroll Summary',
            children: <ContainerLayout><PayrollSummary tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '3',
            label: 'Salary Structure',
            children: <ContainerLayout><SalaryStructureFullView tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '4',
            label: 'Employee Salary Manager',
            children: <ContainerLayout><EmployeeSalaryManager tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '5',
            label: 'Reimbursements',
            children: <ContainerLayout><ReimbursementFullView tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '6',
            label: 'Advances',
            children: <ContainerLayout><AdvanceSalaryFullView tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '7',
            label: 'Loans',
            children: <ContainerLayout><LoanFullView tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '8',
            label: 'Hold Salary',
            children: <ContainerLayout><HoldSalaryFullView tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '9',
            label: 'Payroll Processing',
            children: <ContainerLayout><PayRollProcessing tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '10',
            label: 'Employee Declaration Summary',
            children: <ContainerLayout><EmployeeDeclarationSummary tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '11',
            label: 'Tax Setting',
            children: <ContainerLayout><TaxSetting tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '12',
            label: 'Challan 24Q',
            children: <ContainerLayout><Challan24Q tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '13',
            label: 'Pay Register',
            children: <ContainerLayout><PayRegister tabsValue={tabsValue} /></ContainerLayout>,
        },
        {
            key: '14',
            label: 'Overtime Setting',
            children: <ContainerLayout><OvertimeSetting tabsValue={tabsValue} /></ContainerLayout>,
        }
    ];



    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setTabsValue(params?.componentId)
    }, [])

    return (
        <div ref={ref}>
            <Tabs
                activeKey={tabsValue}
                items={items}
                onChange={changeTabsValue}
            />
        </div>
    )

}



export default PayrollAdminTabs