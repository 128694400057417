import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import "./LeavePolicyFullView.scss"
import request from '../../../../utils/axios';
import edit from "../../../../assets/edit.svg"
import dayjs from 'dayjs';
import update from 'immutability-helper';
import getScreenSize from "../../../../utils/getScreenSize"
import moment from 'moment';
import InfiniteScroll from "../../../../component/InfiniteScroll/InfiniteScroll"
import { toast } from "react-toastify";
import add from "../../../../assets/Org/add.svg";
import clone_icon from "../../../../assets/clone_icon.svg";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';


function LeavePolicyFullView() {
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const ref = useRef(null);
  const [isClone, setIsClone] = useState(false)

  const leavePolicyColumns = [
    {
      title: 'Policy',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Leave From',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'Leave To',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Leave Types',
      dataIndex: 'leave_type_details',
      key: 'leave_type_details',
      render: (text, record) => <span>{
        record.leave_type_details.map((item, index) => {
          return <>
            {
              item.leaves_numbers !== null
                ?
                <span>
                  {item.name} ({item.leaves_numbers})
                  {
                    record.leave_type_details?.length - 1 !== index && <span> , </span>
                  }
                </span>
                :
                ""
            }
          </>
        }
        )}
      </span>
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <img
            src={edit}
            alt="edit"
            style={{ cursor: "pointer", marginRight: "15px" }}
            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
            }}
          />
          <img
            src={clone_icon}
            alt="clone"
            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
              setIsClone(true)
            }}
          />
          {/* <Button
            style={{
              background: "#008080",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
            }}

            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
              setIsClone(true)

            }}
          >
            Clone
          </Button> */}
        </div>
      ),
    }
  ]
  const [openLeavePolicy, setOpenLeavePolicy] = useState(false);
  const [leavePolicyId, setLeavePolicyId] = useState(null);
  const [leavePolicyForm, setLeavePolicyForm] = useState({
    name: "",
    start_time: "",
    end_time: "",
    leave_type_details: [],
  })
  const [leavePolicyDataList, setLeavePolicyDataList] = useState([])
  const fetchAllLeavePolicy = async (page, page_size) => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_policy/all/?page=${page}&page_size=${page_size}`,
      })
      setLeavePolicyDataList(data.pagination_data.data)
      return data
    }
    catch (err) {
      console.log(err);
    }
  }

  const fetchLeaveType = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_types/all/",
      })

      setLeavePolicyForm({
        name: "",
        start_time: "",
        end_time: "",
        leave_type_details: data.map(item => ({ ...item, leaves_numbers: null })),
      })
    }
    catch (err) {
      console.log(err);
    }
  }

  const fetchSpecficLeavePolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_policy/details/?leave_id=${leavePolicyId}`,
      })

      const start_date = new Date(data.data.start_time).toISOString();
      const end_date = new Date(data.data.end_time).toISOString();

      const mergedArray = leavePolicyForm.leave_type_details.map((item1) => {
        const item2 = data.data.leave_type_details.find((i) => {
          return i.name === item1.name;
        });

        return { ...item1, ...item2 };
      });

      setLeavePolicyForm({
        ...leavePolicyForm,
        name: data.data.name,
        start_time: dayjs(start_date),
        end_time: dayjs(end_date),
        leave_type_details: mergedArray,
      })

    }
    catch (err) {
      console.log(err);
    }
  }


  const onFinishPolicyForm = async (values) => {
    // console.log('Success:', leavePolicyForm);

    try {
      const start_time = dayjs(leavePolicyForm.start_time).format("YYYY-MM-DD");
      const end_time = dayjs(leavePolicyForm.end_time).format("YYYY-MM-DD");
      // values = { ...values, dob }

      if (leavePolicyId) {
        if (isClone) {
          await request({
            method: "post",
            url: `leave/admin/leave-policy/clone`,
            data: {
              ...leavePolicyForm,
              start_time: start_time,
              end_time: end_time
            }
          })
        }
        else {
          await request({
            method: "put",
            url: `leave/leave_policy/update/?leave_id=${leavePolicyId}`,
            data: {
              ...leavePolicyForm,
              start_time: start_time,
              end_time: end_time,
            },
          });

          toast.success("Leave Policy updated", {
            position: "bottom-left",
          });
        }

      } else {
        await request({
          method: "post",
          url: "leave/leave_policy/create/",
          data: {
            ...leavePolicyForm,
            start_time: start_time,
            end_time: end_time,
          },
        });
        toast.success("New Leave Policy added", {
          position: "bottom-left",
        });
      }
      setLeavePolicyId(null);
      setOpenLeavePolicy(false);
      setTimeout(() => {
        fetchAllLeavePolicy();
      }, 1000);
      // fetchAllLeavePolicy()
      navigate("/admin/leave/leave-policy", { replace: true });
    } catch (err) {
      console.log(err);
      if (leavePolicyId) {
        toast.error(
          "Leave Type could not be updated, please try after some time",
          {
            position: "bottom-left",
          }
        );
      } else {
        toast.error(
          "New Leave Policy could not be added, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  useEffect(() => {
    if (leavePolicyId) {
      fetchSpecficLeavePolicy();
    }
  }, [leavePolicyId])



  const renderUI = (dataList) => (
    <>
      {dataList.map((item, index) => {
        return (
          <>
            <div style={{ padding: "0px 16px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                <div className="policyText">{item?.name}</div>
                <img src={edit} alt="edit" className="editImage"
                  onClick={() => {
                    navigate("/admin/leave/leave-policy/form/" + item?.id, { replace: true })
                  }}
                />
              </div>
              <div
                style={{
                  color: "#8A8A8A",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "15px",
                }}
              >
                {moment(item?.start_time).format("DD MMM YYYY")} To{" "}
                {moment(item?.end_time).format("DD MMM YYYY")}{" "}
              </div>

              {/* <div style={{ display: "flex", gap: "10px" }}> */}
              {/* {item?.leave_type_details.map((el, index) => {
                  return (
                    <>
                      <div
                        style={{
                          color: "#8A8A8A",
                          fontSize: "12px",
                          fontWeight: "400",
                          marginTop: "15px",
                          flexDirection: "row",
                        }}
                      >
                        <span> {el?.name}</span>
                        {index !== item?.leave_type_details?.length - 1 && (
                          <span>, </span>
                        )}
                      </div>
                    </>
                  );
                })} */}
              {/* </div> */}
              <div style={{ display: "flex", gap: "10px" }}>
                {item?.leave_type_details.map((el, index) => {
                  return (
                    <>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          marginTop: "15px",
                        }}
                      >
                        <span>
                          {el?.name}({el?.leaves_numbers})
                        </span>
                        {index !== item?.leave_type_details?.length - 1 && (
                          <span>, </span>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {index !== leavePolicyDataList?.length - 1 && (
              <hr className="divider" />
            )}
          </>
        );
      })}

    </>
  )

  // useEffect(() => {
  //   ref?.current?.scrollIntoView({ behavior: 'instant' })
  //   document.body.scrollTop = document.documentElement.scrollTop = 0;
  // }, []);

  const renderUIDesktop = (dataList) => {
    return <Table
      columns={leavePolicyColumns}
      dataSource={dataList}
      pagination={false}
    // handleRowClick={() => { }}
    // pageSize={5}
    // showPagination={true}
    />
  }

  useEffect(() => {
    fetchLeaveType();
  }, [])

  return (
    <div className='leavePolicyFullViewContainer' ref={ref}>
      {
        screenSize.width < 768
          ?
          <>
            {/* leave policy */}
            <div >
              <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                <div className="myTeamTextNew">Leave Policy</div>

                <img src={add} alt="add" className="addImage"
                  onClick={() => {
                    navigate("/admin/leave/leave-policy/form", { replace: true })
                  }}
                />
              </div>

              <InfiniteScrollComponent
                apiEndpoint="leave/leave_policy/all/"
                renderUI={renderUI}
                initialPage={1}
              />

            </div>
          </>
          :
          <>


            <div style={{ background: "white", padding: "16px" }}>
              <div className='flexSpaceBetween'>
                <p className='leavePolicyHeading'>Leave Policy</p>
                <Button className='addNewPolicyButton'
                  onClick={() => {
                    setOpenLeavePolicy(true)
                    // fetchLeaveType();
                  }}
                >
                  Add New Policy
                </Button>
              </div>
              <br />


              {/* <DynamicTable
                fetchData={fetchAllLeavePolicy}
                columns={leavePolicyColumns}
                handleRowClick={() => { }}
                pageSize={5}
                showPagination={true}
              /> */}

              <InfiniteScrollComponent
                apiEndpoint="leave/leave_policy/all/"
                renderUI={renderUIDesktop}
                initialPage={1}
              />
            </div>
          </>
      }

      {/* Leave Policy Form */}
      <Modal
        open={openLeavePolicy}
        onOk={() => { }}
        onCancel={() => {
          setOpenLeavePolicy(false)
          setLeavePolicyId(null)
          fetchLeaveType();
          setLeavePolicyForm({
            name: "",
            start_time: "",
            end_time: "",
            leave_type_details: [],
          })
        }}
        footer={null}
        centered
        width={1000}
      >
        <p className='leavePolicyModelHeading'>Leave Policy</p>
        <Form
          layout="vertical"
          onFinish={onFinishPolicyForm}
          fields={[
            {
              name: ['name'],
              value: leavePolicyForm.name
            },
            {
              name: ['start_time'],
              value: leavePolicyForm.start_time
            },
            {
              name: ['end_time'],
              value: leavePolicyForm.end_time
            }
          ]}
        >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Policy Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Policy Name!',
                  },
                ]}
              >
                <Input
                  className='leavePolicyInputField'
                  // placeholder='Enter Policy Name'
                  onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, name: e.target.value })}
                />
              </Form.Item>
            </Col>

          </Row>
          {
            screenSize.width < 768
              ?
              // mobile view
              <Row gutter={[0, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Date (From)"
                    name="start_time"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Date (From)!',
                      },
                    ]}
                  >
                    <DatePicker
                      className='leavePolicyInputField'
                      onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, start_time: e })}
                    />


                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Date (To)"
                    name="end_time"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Date (To)!',
                      },
                    ]}
                  >
                    <DatePicker
                      className='leavePolicyInputField'
                      onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, end_time: e })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              :
              // desktop view
              <Row gutter={[32, 32]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Date (From)"
                    name="start_time"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Date (From)!',
                      },
                    ]}
                  >
                    <DatePicker
                      className='leavePolicyInputField'
                      onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, start_time: e })}
                    />


                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Date (To)"
                    name="end_time"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Date (To)!',
                      },
                    ]}
                  >
                    <DatePicker
                      className='leavePolicyInputField'
                      onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, end_time: e })}
                    />
                  </Form.Item>
                </Col>
              </Row>
          }


          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <p className='noOfLeaveDaysHeadingModel'>No. of leave Days</p>
              {leavePolicyForm?.leave_type_details?.map((item, index) => (
                <div key={index}>


                  <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 5 }} >
                      <p className='leaveNameModel'>
                        {item.name}
                      </p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 19 }}>
                      <Form.Item style={{ margin: "0px" }}>
                        <Select size="default"
                          className='selectNoOfLeave'
                          onChange={(value) => {
                            if (leavePolicyForm.leave_type_details[index].leaves_numbers === null) {
                              setLeavePolicyForm(update(leavePolicyForm, {
                                leave_type_details: {
                                  [index]: {
                                    id: {
                                      $set: ""
                                    },
                                    leave_type_id: {
                                      $set: leavePolicyForm.leave_type_details[index].id
                                    },
                                    leaves_numbers: {
                                      $set: value
                                    }
                                  }
                                },
                              }));
                            }
                            else {
                              setLeavePolicyForm(update(leavePolicyForm, {
                                leave_type_details: {
                                  [index]: {
                                    leaves_numbers: {
                                      $set: value
                                    }
                                  }
                                },
                              }));
                            }

                          }}
                          value={item.leaves_numbers}
                        >
                          {[...Array(51)].map((_, i) => (
                            <Select.Option key={i}
                              value={i}
                            >
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
          {
            isClone ?
              <Button htmlType="submit" className="addNewPolicyFormButton">
                Clone Policy
              </Button>
              :
              <Button htmlType="submit" className="addNewPolicyFormButton">
                {leavePolicyId ? "Update Policy" : "Add New Policy"}
              </Button>
          }
        </Form>
      </Modal>
    </div>
  )
}

export default LeavePolicyFullView