import { Button, Col, Form, Input, Modal, Radio, Row, Select, Table, DatePicker, Switch, AutoComplete, Spin } from "antd";
import react from "react";
import request from '../../../../utils/axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'

import yellowTick from "../../../../assets/Payroll/yellowTick.svg";
import edit from "../../../../assets/edit.svg";
import add from "../../../../assets/Org/add.svg"
import arrowCircle from "../../../../assets/fullView.svg";
import calendarDays from "../../../../assets/Payroll/Admin/calendarDays.png"
import employee from "../../../../assets/Payroll/Admin/employee.png"
import inc from "../../../../assets/Payroll/Admin/inc.png"
import dec from "../../../../assets/Payroll/Admin/dec.png"
import file from "../../../../assets/file.png"
import bullet from "../../../../assets/bullet.svg"

import payrollProcessed from "../../../../assets/Payroll/Admin/payrollProcessed.png"
import styles from "./Dashboard.module.scss"
import mobileStyle from "./MobileDashboard.module.scss"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import getScreenSize from "../../../../utils/getScreenSize";

import leaveAttendance from "../../../../assets/Payroll/Admin/leaveAttendance.png";
import newJoinesAndExit from "../../../../assets/Payroll/Admin/newJoinesAndExit.png";
import bonusSalaryRevision from "../../../../assets/Payroll/Admin/bonusSalaryRevision.png";
import reimbursement from "../../../../assets/Payroll/Admin/reimbursement.png";
import salariesAndArrears from "../../../../assets/Payroll/Admin/salariesAndArrears.png";
import overrides from "../../../../assets/Payroll/Admin/overrides.png";
import circleEdit from "../../../../assets/Payroll/Admin/edit.jpg";
// import view from "../../../../assets/Payroll/Admin/view.jpg";

import view from "../../../../assets/view.png";
import activeDocumentImg from "../../../../assets/activeDocumentImg.svg";
import activeDownload from "../../../../assets/activeDownload.png";
import { saveAs } from 'file-saver';
import { LoadingOutlined } from "@ant-design/icons";

const DashBoard = ({ changeTabsValue, tabsValue }) => {
    const downloadLink = `${process.env.REACT_APP_IMG_BASE_URL}/media/Sample_Formats/sample.csv`;
    const [form] = Form.useForm()
    const [approvedModal, setApprovedModal] = useState(false)
    const [holdForm] = Form.useForm()
    const [holdSalaryModal, setHoldSalaryModal] = useState(false)
    const [employeeLoan, setEmployeeLoan] = useState(false)
    const [employeeAdvanceSalary, setEmployeeAdvanceSalary] = useState(false)
    const [reimbursementStatus, setReimbursementStatus] = useState('');
    const [employeeSalaryManagerModal, setEmployeeSalaryManagerModal] = useState(false)
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const [File, setFile] = useState(null)
    const [reimbursementModel, setReimbursementModel] = useState(false);

    const [selectedReimbursementData, setSelectedReimbursementData] = useState();

    const salaryStructureColumn = [
        {
            title: 'Name',
            dataIndex: 'structure_name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => navigate("/admin/payroll/salary-configuration-form/" + record.id, { replace: true })}
                    />
                </>
        },
    ]
    const handleChange = (e) => {
        const value = e.target.value;
        setReimbursementStatus(value);
        console.log("value :", value)
        if (value === "0") {
            setApprovedModal(true)
        }
    }

    const [employeeSalaryActionSelect, setEmployeeSalaryActionSelect] = useState("0")
    const EmployeeSalaryColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Employee Code',
            dataIndex: 'employement_number',
            key: 'employement_number',
        },
        {
            title: 'Salary Structure',
            dataIndex: 'structure_name',
            key: 'name',
        },
        {
            title: 'CTC',
            dataIndex: 'ctc',
            key: 'ctc',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <Select
                        onChange={(value) => onHandleChangeForReviseSalary(value, record)}
                        defaultValue="0"
                        style={{ width: 185 }}
                    >
                        <Select.Option value="0">Select</Select.Option>
                        <Select.Option value={1}>Revise Salary</Select.Option>
                        <Select.Option value={2} >Change salary structure</Select.Option>
                    </Select>
                </>
            // <Button
            //     style={{
            //         color: "#8A8A8A",
            //         background: "#F2F2F2",
            //         border: "none",
            //         borderRadius: "3px",
            //         fontSize: "16px",
            //         height: "35px",
            //         padding: "0 15px",
            //     }}
            //     onClick={() => {
            //         setselectedEmployeeSalaryId({
            //             ...selectedEmployeeSalary,
            //             id: record.employement_number,
            //             name: record.employee_name
            //         })
            //         setReviseSalary(true)

            //     }}>
            //     Revise Salary
            // </Button>
        },
    ]
    const EmployeeReimbursementColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Claimed On',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Amount Claimed',
            dataIndex: 'claim_amount',
            key: 'claim_amount',
        },
        {
            title: 'Amount Approved',
            dataIndex: 'approved_amount',
            key: 'approved_amount',
        },
        {
            title: 'Action',
            dataIndex: 'approved',
            key: 'approved',
            render: (text, record) => (
                <>
                    <Select
                        value={record.approved === null ? 0 : record.approved}
                        onChange={(value) => onHandleChangeForReimbursementAmount(value, record.id)}
                        style={{ width: 150 }}
                    >
                        <Select.Option value={0}>Approval Pending</Select.Option>
                        <Select.Option value={1}>Partially Approved</Select.Option>
                        <Select.Option value={2} >Approved</Select.Option>
                    </Select>
                    <img src={view} alt="view" width={18} height={18} style={{ marginLeft: "15px" }}
                        onClick={() => {
                            setReimbursementModel(true)
                            setSelectedReimbursementData(record)
                        }}
                    />
                </>
            ),
        },
    ]

    const emoloyeeLoanColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Issue Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
        {
            title: 'Tenure',
            dataIndex: 'tenure',
            key: 'tenure',
        }
    ]
    const form24QColumns = [
        {
            title: 'Quarter',
            dataIndex: 'quarter',
            key: 'quarter',
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Form 24Q',
            dataIndex: 'form24Q',
            key: 'form24Q',
        },
        {
            title: 'Form 27A',
            dataIndex: 'form27A',
            key: 'form27A',
        },
        {
            title: 'FVU',
            dataIndex: 'fvu',
            key: 'fvu',
        }
    ]
    const holdSalaryColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Employee Number',
            dataIndex: 'employee_number',
            key: 'employee_number',
        },
        {
            title: 'Hold Date',
            dataIndex: 'hold_date',
            key: 'hold_date',
        },
        {
            title: 'Hold status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Switch
                    size="small"
                    defaultChecked={record.status}
                    onChange={(checked) => handleToggleChange(record.id, checked)} // Call handleToggleChange function on change
                />
            )
        }
    ]
    const employeeDeclarationSummaryColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',

        },
        {
            title: 'Date of Joining',
            dataIndex: 'joining_date',
            key: 'joining_date',
        },
        {
            title: 'Claim Amount',
            dataIndex: 'declared_amount',
            key: 'declared_amount',
        },
        {
            title: 'Approved Amount',
            dataIndex: 'approved_amount',
            key: 'approved_amount',
        },
        {
            title: 'View Documents',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <button style={{
                        color: "#8A8A8A",
                        background: "#F2F2F2",
                        border: "none",
                        borderRadius: "3px",
                        fontSize: "16px",
                        height: "35px",
                        padding: "0 15px",
                    }}
                        onClick={() => navigate(`/admin/payroll/employee-declaration/${record.employee_number}`, { replace: true })}
                    >Review Claim</button>
                </>,
            width: 300,
        },
    ]
    const emoloyeeAdvanceSalaryColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Issue Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
    ]

    const componentsColumn = [
        {
            title: 'Name',
            dataIndex: 'component_name',
            key: 'name',
        },
        {
            title: 'Components',
            dataIndex: 'salary_component',
            key: 'components',
            render: (text, record) => {
                // Assuming 'salary_component' is an array of values
                return record.salary_component.join(', ');
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => {
                            setComponentModal(true)
                            setEditComponent(true)
                            fetchSpecficComponentDetail(record?.component_value)
                        }}
                    />
                </>,
            width: 100,
        },
    ]

    const payrollProcessingTypeList = [
        {
            name: "Leave & Attendance",
            image: leaveAttendance,
            key: "leaveAttendance",
            color: "#6732FF"
        },
        {
            name: "New Joines & Exit",
            image: newJoinesAndExit,
            key: "newJoinesAndExit",
            color: "#FBA602"
        },
        {
            name: "Bonus Salary Revision",
            image: bonusSalaryRevision,
            key: "bonusSalaryRevision",
            color: "#28B15F"
        },
        {
            name: "Reimbursement, Adhoc Payments & Deductions",
            image: reimbursement,
            key: "reimbursement",
            color: "#3354F4"
        },
        {
            name: "Salaries & Arrears",
            image: salariesAndArrears,
            key: "salariesAndArrears",
            color: "#FA0D0D"
        },
        {
            name: "Overrides ( PT, EST, T..)",
            image: overrides,
            key: "overrides",
            color: "#FF7B99"
        }
    ]

    const taxSettingsColumn = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'From Date',
            dataIndex: 'from_date',
            key: 'from_date',
        },
        {
            title: 'To Date',
            dataIndex: 'to_date',
            key: 'to_date',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => {
                            setEditTaxSetting(true)
                            setTaxSettingId(record.id)
                            if (record.type === "Regime Window") {
                                setRegimeModel(true)
                                setRegimeWindowFormState({
                                    ...regimeWindowFormState,
                                    from_date: dayjs(record.from_date),
                                    end_date: dayjs(record.to_date),
                                })
                            }
                            else {
                                setTaxDeclarationWindowModel(true)
                                setTaxDeclarationWindowFormState({
                                    ...taxDeclarationWindowFormState,
                                    from_date: dayjs(record.from_date),
                                    end_date: dayjs(record.to_date),
                                })
                            }
                        }}
                    />
                </>
        },
    ]

    const onFinishEmployeeSalaryManager = async (values) => {
        const formData = new FormData();

        try {
            formData.append("csv_file", File);
            const response = await request({
                method: "post",
                url: `payroll/admin/revised-employee-salary-csv`,
                data: formData
            })
            const file = new Blob([response], { type: "text/csv" });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = url;
            link.download = "revised_employee_data.csv";
            link.click();
            setEmployeeSalaryManagerModal(false);
            toast.success("Employees salary revised successfully", {
                position: "bottom-left",
            })
            setFile(null);
            fetchEmployeeList();

        }
        catch (error) {
            console.log("error")
            toast.error("Somethig went wrong, please try after some time", {
                position: 'bottom-left',
            });
        }
    }
    const handleToggleChange = async (id, is_checked) => {
        try {
            let data = await request({
                method: "post",
                url: `payroll/admin/salary-hold/update?id=${id}`,
                data: {
                    is_checked
                }
            })
            console.log(data)
            fetchemployeesHoldSalaryHistory();
            toast.success("Hold salary details updated successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const [salaryStructureData, setSalaryStructureData] = useState([])
    const fetchSalaryStrDetail = async () => {
        const data = await request({
            method: "get",
            url: `payroll/admin/fetch-salary-structure?page=1&page_size=5`,
        })
        console.log("data : ", data)
        setSalaryStructureData([...data.pagination_data.data])
        // setTableData(data.data.attendance_data)

        // setLoading(false)
    }
    const [revisedEmployeeData, setrevisedEmployeeData] = useState([])
    const onFinish = async (values) => {
        try {
            console.log("values : ", values)
            const data = await request({
                method: "post",
                url: `payroll/admin/revised-employee-salary/?id=${selectedEmployeeSalary.id}`,
                data: values

            })

            setReviseSalary(false)
            fetchEmployeeList();
            toast.success("Salary revised successfully", {
                position: "bottom-left",
            })

        } catch (err) {
            console.log(err)
            setReviseSalary(false)
            toast.error("Salary could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
        // console.log("data : ", data)
        // setrevisedEmployeeData([...data])
    }

    const [salaryStructureList, setSalaryStructureList] = useState([]);
    const fetchStructureList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/salary-structure/all`,
            });
            setSalaryStructureList(data.data);
        } catch (error) {
            console.log(error);
        } finally {
            // setReload(false)
        }
    };
    const onFinishForReimbursement = async (values) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/reimbursement-partially-approved`,
                data: {
                    id: selectedOptionEmployeeId,
                    approved_amount: values.amount
                }
            })
            fetchAllemployeeReimbursement();
            setApprovedModal(false);
            toast.success("Reimbursement Request Partially approved successfully", {
                position: "bottom-left",
            })

        }
        catch (error) {
            console.log(error)
            toast.error("Reimnursement request could not be Partially approved, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const [holdSalaryDetail, setHoldSalaryDetail] = useState({
        employement_number: "",
        name: "",
    })
    const onFinishHoldSalary = async (values) => {
        console.log("HoldSalaryvalues", moment(values.issue_date).format("DD-MM-YYYY"))
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/employee/salary-hold/add`,
                data: {
                    date: moment(values.issue_date).format("YYYY-MM-DD"),
                    employement_number: holdSalaryDetail.employement_number,
                    name: holdSalaryDetail.name
                }
            })
            setHoldSalaryModal(false);
            holdForm.resetFields();
            setHoldSalaryDetail({
                employement_number: "",
                name: "",
            });
            fetchemployeesHoldSalaryHistory();
            toast.success("Hold salary details added successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
            toast.error(err.response.data.message, {
                position: "bottom-left",
            })
        }

    }
    const [allEmployeeHoldSalarySummary, setAllEmployeeHoldSalarySummary] = useState([])
    const fetchemployeesHoldSalaryHistory = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/employees/salary-hold/all?page=1&page_size=5`,
            })
            console.log(data)
            setAllEmployeeHoldSalarySummary([...data.pagination_data.data])
        }
        catch (err) {
            console.log(err)
        }
    }


    const [componentData, setComponentData] = useState([])
    const fetchComponentsList = async () => {
        const data = await request({
            method: "get",
            url: `payroll/admin/fetchComponents`,
        })
        console.log("data : ", data)
        setComponentData([...data.data])
    }

    const [employeeSalaryManagerData, setEmployeeSalaryManagerComponentData] = useState([])
    const fetchEmployeeList = async () => {
        const data = await request({
            method: "get",
            url: `payroll/admin/get-all-employees?page=1&page_size=5`,
        })
        setEmployeeSalaryManagerComponentData([...data.pagination_data.data])

    }

    const [reviseSalary, setReviseSalary] = useState(false)
    const [changeSalaryStructure, setChangeSalaryStructure] = useState(false)
    const [selectedEmployeeSalary, setselectedEmployeeSalaryId] = useState({
        id: "",
        name: "",
        appraisal_type: "",
        component_choice: "",
        amount: ""

    })

    const [selectedEmployeeChangeSalary, setSelectedEmployeeChangeSalary] = useState({
        id: "",
        ctc: '',
        salary_structure: ''
    })
    const [allEmployeeReimbursementSummary, setAllEmployeeReimbursementSummary] = React.useState()

    const fetchAllemployeeReimbursement = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-reimbursement?page=1&page_size=5`,
            })
            console.log(data)
            setAllEmployeeReimbursementSummary([...data.pagination_data.data])
        }
        catch (err) {
            console.log(err)
        }
    }
    const [allEmployeeDeclarationSummary, setAllEmployeeDeclarationSummary] = React.useState([])

    const fetchAllemployeeDeclarationSummary = async () => {
        try {
            let data = await request({
                method: "get",
                url: `regim_tax_rules/admin/employee-declaration-summary?page=1&page_size=5`,
            })
            console.log("setAllEmployeeDeclarationSummary....", data.data.pagination_data.data)
            setAllEmployeeDeclarationSummary(data.data.pagination_data.data)
        }
        catch (err) {
            console.log(err)
        }
    }
    const [ctcBreakUp, setCtcBreakUp] = useState(null)
    const [breakupDetail, setBreakupDetail] = useState({
        ctc: '',
        salary_structure: ''
    })
    const onFinishCTC = async (values) => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/employee-salary-breakup?ctc=${values.ctc}&salary_id=${values.salary_structure}`,
            })
            setCtcBreakUp(data.data)
            setBreakupDetail({
                ctc: values.ctc,
                salary_structure: values.salary_structure
            })
        }
        catch (err) {
            console.log("err : ", err);
        }
    }
    const changeSalarysubmit = async () => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/employee/change-salary-structure/?id=${selectedEmployeeChangeSalary.id}`,
                data: breakupDetail
            })
            setChangeSalaryStructure(false)
            setSelectedEmployeeChangeSalary({
                id: "",
                ctc: '',
                salary_structure: ''
            })
            setCtcBreakUp(null)
            fetchEmployeeList();
            toast.success("Salary structure updated successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log("err", err)
            toast.error(err.response.data.message, {
                position: "bottom-left",
            })
        }

    }
    const [allEmployeesAdvanceSummary, setAllEmployeesAdvanceSummary] = React.useState()
    const fetchemployeesAdvanceSalaryHistory = async () => {
        try {
            let data = await request({
                method: "get",
                url: `loan/admin/get-all-employee-advance-history?page=1&page_size=5`,
            })
            console.log(data)
            setAllEmployeesAdvanceSummary([...data.pagination_data.data])
        }
        catch (err) {
            console.log(err)
        }
    }
    const [allEmployeeLoanSummary, setAllEmployeeLoanSummary] = React.useState()

    const fetchemployeesLoanHistory = async () => {
        try {
            let data = await request({
                method: "get",
                url: `loan/admin/get-all-employee-loan-history?page=1&page_size=5`,
            })
            console.log(data)
            setAllEmployeeLoanSummary([...data.pagination_data.data])
        }
        catch (err) {
            console.log(err)
        }
    }
    const [EmployeeLoanData, setEmployeeLoanData] = useState({
        employement_number: "",
        name: "",
        amount: "",
        tenure: "",
        issue_date: "",
        interest: ""
    })

    const onHandleChange = (key, value) => {
        setEmployeeLoanData({
            ...EmployeeLoanData,
            [key]: value
        });
    }
    const onFinishLoan = async (values) => {
        const formData = new FormData();
        try {
            formData.append("employement_number", EmployeeLoanData.employement_number);
            formData.append("name", EmployeeLoanData.name);
            formData.append("loan_amount", EmployeeLoanData.amount);
            formData.append("tenure", EmployeeLoanData.tenure);
            formData.append("issue_date", EmployeeLoanData.issue_date);
            formData.append('interest', EmployeeLoanData.interest)
            console.log("Form_data : ", formData)

            await request({
                method: "post",
                url: `loan/admin/create-employee-loan`,
                data: formData
            })
            // Close the modal after the request is successful
            setEmployeeLoan(false);
            fetchemployeesLoanHistory();
            toast.success("Loan details added successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log("error")
            toast.error("Loan details could not be added, please try after some time", {
                position: "bottom-left",
            })
        }

    }

    const [EmployeeAdvanceSalaryData, setEmployeeAdvanceSalaryData] = useState({
        employement_number: "",
        name: "",
        amount: "",
        issue_date: "",
    })
    const onHandleChangeForAdvance = (key, value) => {
        setEmployeeAdvanceSalaryData({
            ...EmployeeAdvanceSalaryData,
            [key]: value
        });
    }

    const onFinishAdvanceSalary = async (values) => {
        const formData = new FormData();
        try {
            formData.append("employement_number", EmployeeAdvanceSalaryData.employement_number);
            formData.append("name", EmployeeAdvanceSalaryData.name);
            formData.append("amount", EmployeeAdvanceSalaryData.amount);
            formData.append("issue_date", EmployeeAdvanceSalaryData.issue_date);

            await request({
                method: "post",
                url: `loan/admin/create-employee-advance-salary`,
                data: formData
            })
            // Close the modal after the request is successful
            setEmployeeAdvanceSalary(false);
            fetchemployeesAdvanceSalaryHistory();
            toast.success("New advance salary added successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log("error")
            toast.error("Advance salary details could not be added, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const [selectedOptionEmployeeId, setSelectedOptionEmployeeId] = useState("")
    // const [selectedOptionForReviseSalary, setselectedOptionForReviseSalary] = useState("")
    const onHandleChangeForReimbursementAmount = (value, id) => {
        //   partially approved
        if (value === 1) {
            setApprovedModal(true)
            setSelectedOptionEmployeeId(id)
        }
        // approved
        else if (value === 2) {
            approveEmployeeReimbursementAmount(id)
        }
    }
    const onHandleChangeForReviseSalary = (value, record) => {
        if (value === 1) {
            setselectedEmployeeSalaryId({
                ...selectedEmployeeSalary,
                id: record.employement_number,
                name: record.employee_name
            })
            setReviseSalary(true)
        }
        else if (value === 2) {
            setSelectedEmployeeChangeSalary({
                ...selectedEmployeeChangeSalary,
                id: record.employement_number,
                ctc: record.ctc
            })
            setChangeSalaryStructure(true)
        }
    }

    const approveEmployeeReimbursementAmount = async (id) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/reimbursement-approved`,
                data: {
                    id
                }
            })
            fetchAllemployeeReimbursement();
            toast.success("Reimbursement Request approved successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Reimnursement request could not be approved, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const options = [
        {
            label: 'Salary Structure',
            value: 'salaryStructure',
        },
        {
            label: 'Components',
            value: 'components',
        },
    ];

    const [salaryStructureType, setSalaryStructureType] = useState('salaryStructure')

    const [searchEmployeeList, setSearchEmployeeList] = useState([])

    // #####################################################################
    // Loan
    // #####################################################################
    const handleSelectChangeForLoan = async (employement_number, name) => {
        try {
            setEmployeeLoanData({
                ...EmployeeLoanData,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const searchForLoan = async (data) => {
        onHandleChange('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }

    // #####################################################################
    // Advance Salary
    // #####################################################################

    const handleSelectChangeForAdvanceSalary = async (employement_number, name) => {
        try {
            setEmployeeAdvanceSalaryData({
                ...EmployeeAdvanceSalaryData,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const searchForAdvanceSalary = async (data) => {
        onHandleChangeForAdvance('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }

    // #####################################################################
    // Hold Salary
    // #####################################################################

    const handleSelectChangeForHoldSalary = async (employement_number, name) => {
        try {
            setHoldSalaryDetail({
                ...holdSalaryDetail,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const searchForHoldSalary = async (data) => {
        setHoldSalaryDetail({
            ...holdSalaryDetail,
            name: data
        });

        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }


    // #####################################################################
    // Payroll Summary
    // #####################################################################

    const [availableMonthPaySlip, setAvailableMonthPaySlip] = useState([])
    const [paySlipMonthYear, setPaySlipMonthYear] = useState({
        month: "",
        year: dayjs(new Date()).format('YYYY'),
    })
    const getAvailableMonthPaySlip = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/employee-monthly-paylist?year=${paySlipMonthYear.year}`,
            })
            setAvailableMonthPaySlip([...response.data])

            let lastPaySlipAvailableMonth;
            response.data.forEach(item => {
                if (item.status) {
                    lastPaySlipAvailableMonth = item
                }
            })

            setSelectedMonthForDownloadPayslip(lastPaySlipAvailableMonth.month)
            setPaySlipMonthYear({ ...paySlipMonthYear, month: lastPaySlipAvailableMonth.month_id })
        }
        catch (error) {
            console.log(error)
        }
    }



    const [selectedMonthForDownloadPayslip, setSelectedMonthForDownloadPayslip] = useState("")


    const downloadPaySlip = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/employee-monthly-payslip?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
                responseType: 'arraybuffer',
            })

            const file = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'file.pdf';
            link.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }

    const [monthlyPayrollSummary, setMonthlyPayrollSummary] = React.useState()
    const fetchMonthlyPayrollSummary = async () => {
        if (paySlipMonthYear.month && paySlipMonthYear.year) {
            const data = await request({
                method: "get",
                url: `payroll/admin/get-payroll-monthly-summary?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
            })
            console.log("monthly payroll summary @@@@", data)
            setMonthlyPayrollSummary(data.data)
        }

    }

    const runPayroll = [
        {
            title: "Leave & Attendance",
            route: "/admin/payroll/leave-attendance",
            color: "#7246F0",
        },
        {
            title: "New Joinies & Exit",
            route: "/admin/payroll/new-joinies-exit",
            color: "#FBA602",
        },
        {
            title: "Payroll Summary",
            route: "/admin/payroll/summary",
            color: "#28B15F",
        },
        {
            title: "Reimbursement & Adhoc Deduction ",
            route: "/admin/payroll",
            color: "#3354F4",
        },
        {
            title: "Salaries & Arrears",
            route: "/admin/payroll/loan-salary",
            color: "#D14848",
        },
        {
            title: "Overrides ( PT, ESI, TDS, LWF )",
            route: "/admin/payroll/tax-summary",
            color: "#F9A1B5",
        }
    ]

    const [regimeModel, setRegimeModel] = useState(false);
    const [taxDeclarationWindowModel, setTaxDeclarationWindowModel] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    // #####################################################################
    // Total Payroll Cost
    // #####################################################################
    const [totalPayrollCostDetail, setTotalPayrollCostDetail] = useState({
        total_cost: "",
        total_salary: "",
        bonus_amount: "",
        tds_amount: "",
        total_cost_percentage: "",
        total_cost_change_indicator: "",
        total_salary_percentage: "",
        total_salary_change_indicator: "",
        bonus_percentage: "",
        bonus_change_indicator: "",
        tds_percentage: "",
        tds_change_indicator: ""
    })

    const fetchTotalPayrollCost = async () => {
        try {
            if (paySlipMonthYear.month) {
                const response = await request({
                    method: "get",
                    url: `payroll/admin/get-monthly-payroll-cost?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
                })
                setTotalPayrollCostDetail(response.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    // #####################################################################
    const [regimeWindowForm] = Form.useForm()
    const [regimeWindowFormState, setRegimeWindowFormState] = useState({
        from_date: "",
        end_date: "",
    })

    const regimeWindow = async (values) => {
        try {
            setRegimeWindowLoader(true)
            if (editTaxSetting) {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/update-windows-status`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                        type: "Regime Window",
                        id: taxSettingId
                    }
                })
            }
            else {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/regim-selection-window-open`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                    }
                })
            }
            getTaxSettingWindowStatus();
            setRegimeWindowLoader(false)
            setRegimeModel(false)
            setEditTaxSetting(false)
            regimeWindowForm.resetFields()
            toast.success("Regime declaration window updated succesfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Regime declaration window could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const [editTaxSetting, setEditTaxSetting] = useState(false)
    const [taxSettingId, setTaxSettingId] = useState(null)
    const [texDeclarationWindowLoader, setTaxDeclarationWindowLoader] = useState(false)
    const [regimeWindowLoader, setRegimeWindowLoader] = useState(false)
    const [taxDeclarationWindowForm] = Form.useForm()
    const [taxDeclarationWindowFormState, setTaxDeclarationWindowFormState] = useState({
        from_date: "",
        end_date: "",
    })
    const taxDeclarationWindow = async (values) => {
        try {
            setTaxDeclarationWindowLoader(true)
            if (editTaxSetting) {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/update-windows-status`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                        type: "Tax Declaration Window",
                        id: taxSettingId
                    }
                })
            }
            else {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/tax-declaration-window-open`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                    }
                })
            }
            getTaxSettingWindowStatus();
            setTaxDeclarationWindowLoader(false)

            setTaxDeclarationWindowModel(false)
            setEditTaxSetting(false)
            taxDeclarationWindowForm.resetFields()
            toast.success("Tax declaration window updated sucessfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Tax declaration window could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const [taxSettingData, setTaxSettingData] = useState()

    const fetchTaxSettingData = async () => {
        try {
            const response = await request({
                method: "get",
                url: `regim_tax_rules/admin/get-regime-window-open-history?page=1&page_size=5`,
            })
            setTaxSettingData(response.pagination_data.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const [taxSetingWindowStatusData, setTaxSettingWindowStatusData] = useState({
        regimeWindow: false,
        taxDeclarationWindow: false
    })

    const getTaxSettingWindowStatus = async () => {
        try {
            const response = await request({
                method: "get",
                url: `regim_tax_rules/admin/get-windows-status`,
            })

            setTaxSettingWindowStatusData({
                ...taxSetingWindowStatusData,
                regimeWindow: response.data.is_regime_window_open,
                taxDeclarationWindow: response.data.is_tax_declaration_window_open
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const [componentModel, setComponentModal] = useState(false)
    const [editComponent, setEditComponent] = useState(false)
    const [componentDetail, setComponentDetail] = useState({
        name: "",
        components: []
    })

    const [componentNameList, setComponentNameList] = useState()

    const fetchComponentNameList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/get-salary-components-list`,
            })

            setComponentNameList(response?.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const [componentTypeList, setComponentTypeList] = useState();

    const fetchComponentTypeList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/get-salary-component-type-list`,
            })

            setComponentTypeList(response?.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchSpecficComponentDetail = async (id) => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/get-salary-components?component_name=${id}`,
            })

            setComponentDetail({
                ...componentDetail,
                name: response?.data?.component_name,
                components: response?.data?.component_type
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const [componentForm] = Form.useForm();

    const componentsSubmit = async (values) => {
        try {
            console.log("values", values);
            if (editComponent) {
                await request({
                    method: "post",
                    url: `payroll/admin/edit-salary-components`,
                    data: {
                        component_name: values?.name,
                        component_type: values?.components
                    }
                })

                setEditComponent(false)
            }
            else {
                await request({
                    method: "post",
                    url: `payroll/admin/create-salary-components`,
                    data: {
                        name: values?.name,
                        type: values?.components
                    }
                })
            }
            componentForm.resetFields()
            setComponentDetail({
                ...componentDetail,
                name: "",
                components: []
            })
            setComponentModal(false)
            fetchComponentsList()
        }
        catch (error) {
            console.log(error)
            setEditComponent(false)
            toast.error(error.response.data.message, {
                position: "bottom-left",
            })
        }
    }

    const challanColumns = [
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Tax Deducted',
            dataIndex: 'total_tds_amount',
            key: 'total_tds_amount',
        },
        {
            title: 'Challan Payment',
            dataIndex: 'challan_payments',
            key: 'challan_payments',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <button
                        style={{
                            border: "none",
                            background: "#008080",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        onClick={() => navigate(`/admin/payroll/form24Q/challan/${record.id}`, { replace: true })}
                    >Add Challan</button>
                </>
        },
    ]

    const [selectedQuarter, setSelectedQuarter] = useState(null)
    const [quarterList, setQuarterList] = useState([])

    const fetchQuarterList = async () => {
        try {
            const data = await request({
                method: "GET",
                url: "tax-management/admin/get-quarterly-details",
            })

            setQuarterList([...data.data])
        }
        catch (error) {
            console.log("error : ", error)
        }
    }

    const [quarterTableData, setQuarterTableData] = useState([])

    const fetchQuarterData = async (id) => {
        try {
            const data = await request({
                method: "GET",
                url: `tax-management/admin/get-quarterly-challan-details?id=${id}`,
            })

            setQuarterTableData([...data.data])
        }
        catch (error) {
            console.log("error : ", error)
        }
    }

    useEffect(() => {
        if (tabsValue === "1") {
            fetchSalaryStrDetail()
            fetchComponentsList()
            fetchEmployeeList()
            fetchAllemployeeReimbursement()
            fetchemployeesLoanHistory();
            fetchemployeesAdvanceSalaryHistory()
            fetchemployeesHoldSalaryHistory()
            fetchAllemployeeDeclarationSummary()
            fetchStructureList()
            fetchQuarterList()
            getAvailableMonthPaySlip();
            fetchTaxSettingData()
            getTaxSettingWindowStatus()
            fetchComponentNameList()
            fetchComponentTypeList()
        }
    }, [tabsValue])

    useEffect(() => {
        if (tabsValue === "1") {
        }
        fetchTotalPayrollCost()
        fetchMonthlyPayrollSummary();
    }, [paySlipMonthYear.month, paySlipMonthYear.year, tabsValue])

    useEffect(() => {
        console.log("tabsValue....", tabsValue);
    }, [])

    return (
        <>
            {
                screenSize.width < 768
                    ?
                    <div ref={ref}>
                        {/* Payroll summary */}
                        <div className={mobileStyle.payRollSummaryContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Payroll Summary</p>
                                {/* <DatePicker
                                    size="medium"
                                    picker="year"
                                    style={{
                                        borderColor: "#D5D9DD",
                                        borderWidth: 1,
                                        background: "#FFFFFF",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#000000",
                                        height: "36px",
                                        width: "120px",
                                    }}
                                // placeholder="Today"
                                // onChange={(e) => {
                                //     fetchDataWithRespectedMonth(e);
                                // }}
                                // defaultValue={dayjs().startOf('month')}
                                // format={"MMMM YYYY"}
                                /> */}
                                <img
                                    onClick={() => changeTabsValue("2")}
                                    src={arrowCircle}
                                    alt="arrow"
                                    className="employeeArrowImage"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div style={{ display: "flex", gap: "30px", overflowX: "auto" }}>
                                {
                                    availableMonthPaySlip?.map((item, index) => {
                                        return (
                                            <>
                                                {
                                                    selectedMonthForDownloadPayslip === item.month
                                                        ?
                                                        <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "5px 10px", borderRadius: "4px" }}
                                                            onClick={() => {
                                                                setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                setSelectedMonthForDownloadPayslip(item.month)
                                                            }
                                                            }
                                                        >
                                                            <center>
                                                                {
                                                                    item.status
                                                                        ?


                                                                        <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} width={30} height={30} />


                                                                        :
                                                                        <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                            {index + 1}
                                                                        </div>
                                                                }
                                                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                            </center>
                                                        </div>
                                                        :
                                                        <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                                            onClick={() => {
                                                                setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                if (item.status) {
                                                                    setSelectedMonthForDownloadPayslip(item.month)
                                                                }
                                                            }}
                                                        >
                                                            <center>
                                                                {
                                                                    item.status
                                                                        ?


                                                                        <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} width={30} height={30} />


                                                                        :
                                                                        <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                            {index + 1}
                                                                        </div>
                                                                }
                                                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                            </center>
                                                        </div>
                                                }
                                            </>

                                        )
                                    })
                                }
                            </div>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p>Total Payroll</p>
                                    <p>₹ {totalPayrollCostDetail.total_cost}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p>Salary</p>
                                    <p>₹ {totalPayrollCostDetail.total_salary}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p>Bonus & Reimbursement</p>
                                    <p>₹ {totalPayrollCostDetail.bonus_amount}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p>Tax Payment</p>
                                    <p>₹ {totalPayrollCostDetail.tds_amount}</p>
                                </div>
                            </div>
                        </div>

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        {/* Tax Settings */}
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "500",
                                        color: "#161616",
                                        padding: "0px 16px"
                                    }}
                                >
                                    Tax Settings
                                </p>
                                <img src={arrowCircle} alt="" style={{ cursor: "pointer" }} onClick={() => changeTabsValue("11")} />
                            </div>

                            <div>

                                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 16px" }}>
                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#4A4A4A", margin: "0px" }}>
                                        Regime Window
                                    </p>
                                    <div>
                                        {
                                            taxSetingWindowStatusData?.regimeWindow
                                                ?
                                                <Switch
                                                    defaultChecked
                                                    checked={true}
                                                />
                                                :
                                                <Switch
                                                    // defaultChecked
                                                    checked={regimeModel}
                                                    onClick={() => {
                                                        setRegimeModel(!regimeModel)
                                                    }}
                                                />
                                        }
                                    </div>
                                </div>
                                {
                                    regimeModel
                                    &&
                                    <div style={{ padding: "0px 16px" }}>
                                        <Form
                                            layout="vertical"
                                            onFinish={regimeWindow}
                                            form={regimeWindowForm}
                                            fields={[
                                                {
                                                    name: "from_date",
                                                    value: regimeWindowFormState.from_date,
                                                },
                                                {
                                                    name: "end_date",
                                                    value: regimeWindowFormState.end_date,
                                                },
                                            ]}
                                        >
                                            <Row gutter={[32, 16]}>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="From"
                                                        name="from_date"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please enter employee number!',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <DatePicker
                                                            size="large"
                                                            format="DD-MM-YYYY"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="To"
                                                        name="end_date"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please enter amount!',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <DatePicker
                                                            size="large"
                                                            format="DD-MM-YYYY"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Button style={{ width: "100%", background: "#008080", color: "white", height: "40px", fontWeight: "600", marginTop: "10px" }} htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                }

                                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 16px" }}>
                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#4A4A4A", margin: "0px" }}>
                                        Tax Declaration Window
                                    </p>
                                    <div>
                                        {
                                            taxSetingWindowStatusData?.taxDeclarationWindow
                                                ?
                                                <Switch
                                                    // defaultChecked
                                                    checked={true}
                                                />
                                                :
                                                <Switch
                                                    // defaultChecked
                                                    checked={taxDeclarationWindowModel}
                                                    onClick={() => {
                                                        console.log("taxDeclarationWindowModel", taxDeclarationWindowModel)
                                                        setTaxDeclarationWindowModel(!taxDeclarationWindowModel)
                                                    }}
                                                />
                                        }
                                    </div>
                                </div>
                                {
                                    taxDeclarationWindowModel
                                    &&
                                    <div style={{ padding: "0px 16px" }}>
                                        <Form
                                            layout="vertical"
                                            onFinish={taxDeclarationWindow}
                                            form={taxDeclarationWindowForm}
                                            fields={[
                                                {
                                                    name: "from_date",
                                                    value: taxDeclarationWindowFormState.from_date,
                                                },
                                                {
                                                    name: "end_date",
                                                    value: taxDeclarationWindowFormState.end_date,
                                                },
                                            ]}
                                        >
                                            <Row gutter={[32, 16]}>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="From"
                                                        name="from_date"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please enter employee number!',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <DatePicker
                                                            size="large"
                                                            format="DD-MM-YYYY"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="To"
                                                        name="end_date"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please enter amount!',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <DatePicker
                                                            size="large"
                                                            format="DD-MM-YYYY"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Button style={{ width: "100%", background: "#008080", color: "white", height: "40px", fontWeight: "600", marginTop: "10px" }} htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                }

                                <div style={{ marginTop: "20px" }}>
                                    {
                                        taxSettingData?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                                                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>{item.type}</p>
                                                        <img src={edit} alt="" width={16} height={16} style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setEditTaxSetting(true)
                                                                setTaxSettingId(item.id)
                                                                if (item.type === "Regime Window") {
                                                                    setRegimeModel(true)
                                                                    setRegimeWindowFormState({
                                                                        ...regimeWindowFormState,
                                                                        from_date: dayjs(item.from_date),
                                                                        end_date: dayjs(item.to_date),
                                                                    })
                                                                }
                                                                else {
                                                                    setTaxDeclarationWindowModel(true)
                                                                    setTaxDeclarationWindowFormState({
                                                                        ...taxDeclarationWindowFormState,
                                                                        from_date: dayjs(item.from_date),
                                                                        end_date: dayjs(item.to_date),
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>From Date</p>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>{item.from_date}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>To Date</p>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>{item.to_date}</p>
                                                    </div>
                                                    {
                                                        taxSettingData.length - 1 !== index
                                                        &&
                                                        <hr />
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </div>

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        {/* Run Payroll */}
                        <div className={mobileStyle.runPayrollContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Run Payroll</p>
                                <img
                                    // onClick={() => navigate("/admin/payroll/employee-salary-manager")}
                                    src={arrowCircle}
                                    alt="arrow"
                                    // onClick={() => changeTabsValue("9")}
                                    className="employeeArrowImage"
                                    style={{ cursor: "pointer", }
                                    }
                                />
                            </div>
                            <div style={{ padding: "0px 16px" }}>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                    gap: "15px",
                                }}>
                                    {
                                        payrollProcessingTypeList?.map((item, index) => {
                                            return (
                                                <div style={{
                                                    cursor: "pointer",
                                                    // border: `1px solid ${item.color}`,
                                                    height: "100px",
                                                    borderRadius: "4px",
                                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}>
                                                    <div>
                                                        <center>
                                                            <img src={item.image} alt={item.image} />
                                                            <p
                                                                style={{
                                                                    fontSize: "12px",
                                                                    color: item.color,
                                                                    marginTop: "5px"
                                                                }}
                                                            >{item.name}</p>
                                                        </center>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        {/* Challan */}
                        <div style={{ marginTop: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginBottom: "20px" }}>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Challan</p>
                                <img
                                    src={arrowCircle}
                                    alt="arrow"
                                    onClick={() => changeTabsValue("12")}
                                    className="employeeArrowImage"
                                    style={{ cursor: "pointer", }
                                    }
                                />
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Quarters</p>
                                <Select
                                    placeholder="Select Quarter"
                                    defaultValue={selectedQuarter}
                                    size='large'
                                    onChange={(value) => {
                                        fetchQuarterData(value)
                                        // setSelectedQuarter(value)
                                    }}
                                >
                                    {
                                        quarterList?.map((item, index) => {
                                            return (
                                                <Select.Option value={item.id}>{item.monthly_quarters}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div>
                                {
                                    quarterTableData?.map((item, index) => {
                                        return (
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginBottom: "10px", marginTop: "20px" }}>
                                                    <p style={{
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                        color: "#161616",
                                                        margin: "0px"
                                                    }}>{item?.month}</p>
                                                    <button
                                                        style={{
                                                            border: "none",
                                                            background: "#008080",
                                                            color: "white",
                                                            padding: "5px 10px",
                                                            borderRadius: "5px",
                                                            cursor: "pointer"
                                                        }}
                                                    >Add Challan</button>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginBottom: "10px" }}>
                                                    <p style={{ fontSize: "18px", fontWeight: "500", color: "#4A4A4A", margin: "0px" }}>Tax Deducted</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px" }} >{item?.total_tds_amount}</p>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                                                    <p style={{ fontSize: "18px", fontWeight: "500", color: "#4A4A4A", margin: "0px" }}>Challan Payment</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px" }}>{item?.challan_payments}</p>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        <div className={mobileStyle.employeeSalaryContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Employee Declaration Summary</p>
                                <img
                                    onClick={() => changeTabsValue("10")}
                                    src={arrowCircle}
                                    alt="arrow"
                                    className="employeeArrowImage"
                                    style={{ cursor: "pointer", }}
                                />
                            </div>
                            <div>
                                {
                                    allEmployeeDeclarationSummary?.map((item, index) => {
                                        return (
                                            <div>
                                                <div className={mobileStyle.card}>
                                                    <div className={mobileStyle.header}>
                                                        <p className={mobileStyle.name}>{item.employee_name}</p>
                                                        <button
                                                            onClick={() => navigate(`/admin/payroll/employee-declaration/${item.employee_number}`, { replace: true })}
                                                            style={{
                                                                background: "#F2F2F2",
                                                                border: "none",
                                                                color: "#8A8A8A",
                                                                padding: "5px 12px",
                                                                fontSize: "16px",
                                                                borderRadius: "4px"
                                                            }}
                                                        >
                                                            Review Claim
                                                        </button>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row' }}>
                                                        <p className={mobileStyle.title}>Date of Joining</p>
                                                        <p className={mobileStyle.value}>{item.joining_date}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row' }}>
                                                        <p className={mobileStyle.title}>Claim Amount</p>
                                                        <p className={mobileStyle.value}>{item.declared_amount}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row' }}>
                                                        <p className={mobileStyle.title}>Approved Amount</p>
                                                        <p className={mobileStyle.value}>₹{item.approved_amount}</p>
                                                    </div>
                                                </div>
                                                {
                                                    index < allEmployeeDeclarationSummary.length - 1
                                                    &&
                                                    <hr className={mobileStyle.line} />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                        {/* Salary Structure */}
                        {/* <div className={mobileStyle.salaryStructureContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <p className={mobileStyle.heading}>Salary Structure</p>
                                    <img
                                        onClick={() => changeTabsValue("3")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                                <center>
                                    <Radio.Group
                                        value={salaryStructureType}
                                        buttonStyle="solid"
                                        size='large'
                                        onChange={e => setSalaryStructureType(e.target.value)}
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <Radio.Button value="salaryStructure"
                                            style={{
                                                width: "50%"
                                            }}>Salary Structure</Radio.Button>
                                        <Radio.Button value="referral" style={{
                                            width: "50%"
                                        }}>Components</Radio.Button>
                                    </Radio.Group>
                                </center>
                            </div>


                            {
                                salaryStructureType === 'salaryStructure'
                                    ?
                                    <div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                                            <p className={mobileStyle.subHeading}>Salary Structure</p>
                                            <img src={add} alt="add"
                                                onClick={() => navigate("/admin/payroll/salary-configuration-form", { replace: true })}
                                            />
                                        </div>
                                        <div>
                                            {
                                                salaryStructureData.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <div className={mobileStyle.card}>
                                                                <p className={mobileStyle.name}>{item.structure_name}</p>
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    <img src={circleEdit} alt="edit"
                                                                        onClick={() => navigate("/admin/payroll/salary-configuration-form/" + item.id, { replace: true })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <br />
                                        <div style={{ padding: "0px 16px 16px 16px", display: "flex", justifyContent: "space-between" }}>
                                            <p className={mobileStyle.subHeading}>Components</p>
                                            <img src={add} alt="add"
                                                onClick={() => {
                                                    setComponentModal(true)
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {
                                                componentData.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                padding: "0px 16px"
                                                            }}>
                                                                <div>
                                                                    <p style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "600",
                                                                        color: "#161616",
                                                                        margin: "0",
                                                                        marginBottom: "10px"
                                                                    }}>{item.component_name}</p>

                                                                </div>

                                                                <img src={circleEdit} alt="edit"
                                                                    onClick={() => {
                                                                        setComponentModal(true)
                                                                        setEditComponent(true)
                                                                        fetchSpecficComponentDetail(item?.component_value)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div style={{ padding: "0px 16px" }}>
                                                                <p>{item.salary_component.join(', ')}</p>
                                                            </div>

                                                            <hr />
                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                            }
                        </div> */}

                        {/* divider */}
                        {/* <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div> */}

                        {/* Employee Salary Manager */}
                        {/* <div className={mobileStyle.employeeSalaryContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Employee Salary Manager</p>
                                <img style={{ height: "36px", width: "36px" }} src={file} alt="file" onClick={() => {
                                    setEmployeeSalaryManagerModal(true)
                                }} />

                                <img
                                    onClick={() => changeTabsValue("4")}
                                    src={arrowCircle}
                                    alt="arrow"
                                    className="employeeArrowImage"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div>
                                {
                                    employeeSalaryManagerData.map((item, index) => {
                                        return (
                                            <div>
                                                <div className={mobileStyle.card}>
                                                    <div className={mobileStyle.header}>
                                                        <p className={mobileStyle.name}>{item.employee_name}</p>
                                                        <button
                                                            onClick={() => {
                                                                setselectedEmployeeSalaryId({
                                                                    ...selectedEmployeeSalary,
                                                                    id: item.employement_number,
                                                                    name: item.employee_name
                                                                })
                                                                setReviseSalary(true)

                                                            }}
                                                            style={{
                                                                background: "#F2F2F2",
                                                                border: "none",
                                                                color: "#8A8A8A",
                                                                padding: "5px 12px",
                                                                fontSize: "16px",
                                                                borderRadius: "4px"
                                                            }}
                                                        >
                                                            Revise Salary
                                                        </button>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p className={mobileStyle.title}>Employee Code</p>
                                                        <p className={mobileStyle.value}>{item.employement_number}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p className={mobileStyle.title}>Salary Structure</p>
                                                        <p className={mobileStyle.value}>{item.structure_name}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p className={mobileStyle.title}>CTC</p>
                                                        <p className={mobileStyle.value}>₹{item.ctc}</p>
                                                    </div>
                                                </div>
                                                {
                                                    index < employeeSalaryManagerData.length - 1
                                                    &&
                                                    <hr className={mobileStyle.line} />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        <div className={mobileStyle.reimbursementContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Reimbursement</p>
                                <img
                                    onClick={() => changeTabsValue("5")}
                                    src={arrowCircle}
                                    alt="arrow"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            {
                                allEmployeeReimbursementSummary?.map((item, index) => {
                                    return (
                                        <div>
                                            <div className={mobileStyle.cardContainer}>
                                                <Row>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.employeeName}> {item.merchant_name}</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div style={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}>
                                                            <Select
                                                                value={item.approved === null ? 0 : item.approved}
                                                                onChange={(value) => onHandleChangeForReimbursementAmount(value, item.id)}
                                                                style={{ width: 150 }}
                                                                size='middle'
                                                            >
                                                                <Select.Option value={0}>Select</Select.Option>
                                                                <Select.Option value={1}>Partially Approved</Select.Option>
                                                                <Select.Option value={2} >Approved</Select.Option>
                                                            </Select>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.title}>Claim Title</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.value}>{item.title}</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.title}>Claimed On</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.value}>{item.date}</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.title}>Amount Claimed</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.value}>{item.claim_amount}</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.title}>Amount Approved</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.value}>{item.approved_amount}</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.title}>Approved On</p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className={mobileStyle.value}></p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {
                                                index < allEmployeeReimbursementSummary.length - 1 && <hr />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* divider */}
                        {/* <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div> */}

                        {/* Advance Salary */}
                        {/* <div className={mobileStyle.advanceSalary}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Advances</p>
                                <div>
                                    <img
                                        onClick={() => changeTabsValue("6")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </div>
                            <div>
                                {
                                    allEmployeesAdvanceSummary?.map((item, index) => {
                                        return (
                                            <div>
                                                <div className={mobileStyle.card}>
                                                    <div className={mobileStyle.header}>
                                                        <p className={mobileStyle.name}>{item.employee_name}</p>
                                                    </div>
                                                    <div className={mobileStyle.box}>
                                                        <p className={mobileStyle.title}>Issue Date</p>
                                                        <p className={mobileStyle.value}>₹{item.issue_date}</p>
                                                    </div>
                                                    <div className={mobileStyle.box}>
                                                        <p className={mobileStyle.title}>Amount</p>
                                                        <p className={mobileStyle.value}>₹{item.amount}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}

                        {/* divider */}
                        {/* <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div> */}



                        {/* Hold Salary */}
                        {/* <div>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 16px" }}>
                                <p
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "#161616",
                                        margin: "0px",
                                    }}
                                >Hold Salary</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img src={add} alt="add" width={30} onClick={() => setHoldSalaryModal(true)} />
                                    <img
                                        onClick={() => changeTabsValue("8")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer", }}
                                    />
                                </div>
                            </div>
                            <div>
                                {
                                    allEmployeeHoldSalarySummary?.map((item, index) => {
                                        return (
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 16px" }}>
                                                    <div className={mobileStyle.header}>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#161616",
                                                            margin: "0px",
                                                        }}>{item.employee_name}</p>
                                                    </div>
                                                    <div className={mobileStyle.box}>
                                                        <Switch
                                                            defaultChecked={item.status}
                                                            onChange={(checked) => handleToggleChange(item.id, checked)} // Call handleToggleChange function on change
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    index < allEmployeeHoldSalarySummary.length - 1 && <hr className={mobileStyle.line} />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}

                        {/* divider */}
                        {/* <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div> */}

                        {/* Loan */}
                        {/* <div className={mobileStyle.loanContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Loan</p>
                                <div>
                                    <img
                                        onClick={() => changeTabsValue("7")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer", }}
                                    />
                                </div>
                            </div>
                            <div>
                                {
                                    allEmployeeLoanSummary?.map((item, index) => {
                                        return (
                                            <div>
                                                <div className={mobileStyle.card}>
                                                    <div className={mobileStyle.header}>
                                                        <p className={mobileStyle.name}>{item.employee}</p>
                                                    </div>
                                                    <div className={mobileStyle.box}>
                                                        <p className={mobileStyle.title}>Month</p>
                                                        <p className={mobileStyle.value}>₹{item.issue_date}</p>
                                                    </div>
                                                    <div className={mobileStyle.box}>
                                                        <p className={mobileStyle.title}>Amount</p>
                                                        <p className={mobileStyle.value}>₹{item.amount}</p>
                                                    </div>
                                                    <div className={mobileStyle.box}>
                                                        <p className={mobileStyle.title}>Tenure</p>
                                                        <p className={mobileStyle.value}>{item.tenure}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}

                        {/* divider */}
                        {/* <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div> */}


                        {/* divider */}
                        {/* <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div> */}




                    </div>
                    :
                    <div className={styles.payRollContainer} ref={ref}>
                        {/* payroll summary */}
                        <div className={styles.subContainer}>
                            <div className={styles.headerContainer}>
                                <p className={styles.heading}>Payroll Summary</p>
                                <img src={arrowCircle} alt="arrow" onClick={() => changeTabsValue("2")} />
                            </div>
                            {/* month  */}
                            <div>
                                <div style={{ display: "flex", gap: "50px" }}>
                                    {
                                        availableMonthPaySlip?.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        selectedMonthForDownloadPayslip === item.month
                                                            ?
                                                            <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid  #008080", padding: "10px 15px", borderRadius: "4px" }}
                                                                onClick={() => {
                                                                    setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                    setSelectedMonthForDownloadPayslip(item.month)
                                                                }
                                                                }
                                                            >
                                                                <center>
                                                                    {
                                                                        item.status
                                                                            ?


                                                                            <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />


                                                                            :
                                                                            <div style={{ width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                                {index + 1}
                                                                            </div>
                                                                    }
                                                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                                </center>
                                                            </div>
                                                            :
                                                            <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                                                onClick={() => {
                                                                    setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                    if (item.status) {
                                                                        setSelectedMonthForDownloadPayslip(item.month)
                                                                    }
                                                                }}
                                                            >
                                                                <center>
                                                                    {
                                                                        item.status
                                                                            ?


                                                                            <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />


                                                                            :
                                                                            <div style={{ width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                                {index + 1}
                                                                            </div>
                                                                    }
                                                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                                </center>
                                                            </div>
                                                    }
                                                </>

                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className={styles.payrollSummaryContainer}>
                                <div className={styles.payrollSummaryCard}>
                                    <div className={styles.innerContainer}>
                                        <p className={styles.value}>{monthlyPayrollSummary?.calender_days}</p>
                                        <img src={calendarDays} alt="calendarDays" />
                                    </div>
                                    <p className={styles.title}>Calendar Days</p>
                                </div>
                                <div className={styles.payrollSummaryCard}>
                                    <div className={styles.innerContainer}>
                                        <p className={styles.value}>{monthlyPayrollSummary?.total_employees}</p>
                                        <img src={employee} alt="employee" />
                                    </div>
                                    <p className={styles.title}>Total Employees
                                        <img src={inc} alt="inc" width={15} height={15} />
                                        {monthlyPayrollSummary?.total_new_joiner}
                                        <img src={dec} alt="dec" width={15} height={15} />
                                        {monthlyPayrollSummary?.total_full_And_Final_employees}</p>

                                </div>
                                <div className={styles.payrollSummaryCard}>
                                    <div className={styles.innerContainer}>
                                        <p className={styles.value}>{monthlyPayrollSummary?.current_month_salary_processed}/{monthlyPayrollSummary?.total_employees}</p>
                                        <img src={payrollProcessed} alt="payrollProcessed" />
                                    </div>
                                    <p className={styles.title}>Payroll Processed</p>
                                </div>
                            </div>

                            <div>
                                <div style={{ display: "flex", alignItems: "center", border: "1px solid #F0F0F0", padding: "20px 10px", marginTop: "5vh" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", width: "100%" }}>
                                        <div className={styles.appresalBox}  >
                                            <div>
                                                <p className={styles.appresalHeading}>Total Payroll Cost</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.total_cost}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.total_cost_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.total_cost_percentage}%
                                                </p>
                                            </div>
                                            <p className={styles.appresalSign}>
                                                =
                                            </p>
                                        </div>
                                        <div className={styles.appresalBox}>
                                            <div>
                                                <p className={styles.appresalHeading}>Salary</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.total_salary}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.total_salary_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.total_salary_percentage}%
                                                </p>
                                            </div>
                                            <p className={styles.appresalSign}>
                                                +
                                            </p>
                                        </div>
                                        <div className={styles.appresalBox}>
                                            <div>
                                                <p className={styles.appresalHeading}>Bonus</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.bonus_amount}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.bonus_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.bonus_percentage}%
                                                </p>
                                            </div>
                                            <p className={styles.appresalSign}>
                                                +
                                            </p>
                                        </div>
                                        <div className={styles.appresalBox}>
                                            <div>
                                                <p className={styles.appresalHeading}>Tax</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.tds_amount}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.tds_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.tds_percentage}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tax Settings */}
                        <div className={styles.subContainer}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex" }}>
                                    <p className={styles.heading}>Tax Settings</p>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex", gap: "20px", padding: "10px 35px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#4A4A4A", margin: "0px" }}>
                                                Regime Window
                                            </p>
                                            <div>
                                                {
                                                    taxSetingWindowStatusData.regimeWindow
                                                        ?
                                                        <Switch
                                                            // defaultChecked
                                                            checked={true}
                                                        />
                                                        :
                                                        <Switch
                                                            // defaultChecked
                                                            checked={regimeModel}
                                                            onClick={() => {
                                                                setRegimeModel(!regimeModel)
                                                            }}
                                                        />

                                                }

                                            </div>
                                        </div>
                                        <div style={{ display: "flex", gap: "20px", padding: "10px 35px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#4A4A4A", margin: "0px" }}>
                                                Tax Declaration Window
                                            </p>
                                            <div>
                                                {
                                                    taxSetingWindowStatusData.taxDeclarationWindow
                                                        ?
                                                        <Switch
                                                            // defaultChecked
                                                            checked={true}
                                                        />
                                                        :
                                                        <Switch
                                                            // defaultChecked
                                                            checked={taxDeclarationWindowModel}
                                                            onClick={() => {
                                                                setTaxDeclarationWindowModel(!taxDeclarationWindowModel)
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    onClick={() => changeTabsValue("11")}
                                    src={arrowCircle}
                                    alt="arrow"
                                    className="employeeArrowImage"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <Table
                                columns={taxSettingsColumn}
                                dataSource={taxSettingData}
                                pagination={false}
                            />
                        </div>

                        {/* Process Payroll */}
                        <div className={styles.subContainer} style={{ marginTop: "30px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p className={styles.heading}>Process Payroll</p>
                                <Button style={{ width: "140px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
                                    onClick={() => {
                                        changeTabsValue("9")
                                    }}
                                >
                                    Run Payroll
                                </Button>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", gap: "20px", padding: "10px 35px", }}>
                                {
                                    runPayroll.map((item, index) => {
                                        return (
                                            <div style={{ padding: "10px 15px", border: `1px solid #F0F0F0`, width: "80%", borderRadius: "21px", display: "flex", gap: "20px", alignItems: "center" }} key={index}>
                                                <div style={{ width: "40px", height: "40px", border: `2px solid ${item.color}`, color: `${item.color}`, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", fontWeight: "600" }}>
                                                    {index + 1}
                                                </div>
                                                <p style={{ fontSize: "18px", fontWeight: "400", margin: "0px", color: `${item.color}` }}>
                                                    {item.title}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* challan */}
                        <div className={styles.container}>

                            <div className={styles.tableContainer} >
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                                    <p className={styles.title}>24Q Challan</p>
                                    <img
                                        onClick={() => changeTabsValue("12")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                                <Row>
                                    <Col span={4}>
                                        <div className={styles.leftContainer} >
                                            <p className={styles.title}>Financial Quarters</p>
                                            {
                                                quarterList?.map((item, index) => {
                                                    return (
                                                        <div className={styles.quarterContainer}
                                                            onClick={() => {
                                                                fetchQuarterData(item.id)
                                                                setSelectedQuarter(index)
                                                            }}
                                                        >
                                                            <div>
                                                                <p className={styles.monthDuration}>{item.monthly_quarters}</p>
                                                                <p className={styles.status}>{item.status}</p>
                                                            </div>
                                                            <img src={bullet} alt="bullet" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <div className={styles.rightContainer}>
                                            <p className={styles.title}>{
                                                selectedQuarter !== null &&
                                                <span>
                                                    Q{selectedQuarter + 1} ({quarterList[selectedQuarter]?.monthly_quarters})
                                                </span>
                                            }
                                            </p>
                                            <Table
                                                dataSource={quarterTableData}
                                                columns={challanColumns}
                                                pagination={false}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>

                        {/* Employee Declaration Summary */}
                        <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px", marginBottom: "30px" }}>
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Employee Declaration Summary</p>
                                <img src={arrowCircle} alt="arrowCircle"
                                    onClick={() => changeTabsValue("10")}
                                />
                            </div>
                            <Table
                                columns={employeeDeclarationSummaryColumn}
                                dataSource={allEmployeeDeclarationSummary}
                                pagination={false}
                            />
                        </div>

                        {/* Reimbursement */}
                        <div className={styles.subContainer}>

                            <div className="flexSpaceBetween">
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Reimbursement</p>
                                <img src={arrowCircle} alt="arrowCircle"
                                    onClick={() => changeTabsValue("5")}
                                />
                            </div>

                            <Table
                                columns={EmployeeReimbursementColumn}
                                dataSource={allEmployeeReimbursementSummary}
                                // loading={loading}
                                pagination={false}
                            />
                        </div>

                        {/* Salary Structure */}
                        {/* <div className={styles.subContainer}>
                            <div style={{ alignItems: "center", gap: "16px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h2 className={styles.heading}>Salary Structure</h2>
                                    <img
                                        onClick={() => changeTabsValue("3")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                                <br />
                                <Radio.Group
                                    options={options}
                                    onChange={(e) => setSalaryStructureType(e.target.value)}
                                    value={salaryStructureType}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                            {
                                salaryStructureType === 'salaryStructure'
                                    ?
                                    <div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className={styles.subHeading}>Salary Structure</p>
                                            <img src={add} alt="add"
                                                onClick={() => navigate("/admin/payroll/salary-configuration-form", { replace: true })}
                                            />
                                        </div>
                                        <Table
                                            columns={salaryStructureColumn}
                                            dataSource={salaryStructureData}
                                            // loading={loading}
                                            pagination={false}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className={styles.subHeading}>Components</p>
                                            <img src={add} alt="add"
                                                onClick={() => {
                                                    setComponentModal(true)
                                                }}
                                            />
                                        </div>
                                        <Table
                                            columns={componentsColumn}
                                            dataSource={componentData}
                                            // loading={loading}
                                            pagination={false}
                                        />
                                    </div>
                            }

                        </div> */}

                        {/* Employee Salary Manager */}
                        {/* <div className={styles.subContainer}>
                            <div
                                className="flexSpaceBetween"
                            >
                                <p className={styles.subHeading}>Employee Salary Manager</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img style={{ height: "30px", width: "30px" }} src={file} alt="file" onClick={() => {
                                        setEmployeeSalaryManagerModal(true)
                                    }} />
                                    <img src={arrowCircle} alt="arrowCircle"
                                        onClick={() => changeTabsValue("4")}
                                    />
                                </div>
                            </div>
                            <Table
                                columns={EmployeeSalaryColumn}
                                dataSource={employeeSalaryManagerData}
                                // loading={loading}
                                pagination={false}
                            />
                        </div> */}

                        {/* Advance Salary */}
                        {/* <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Advance Salary</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img src={add} alt="add" width={35} onClick={() => setEmployeeAdvanceSalary(true)} />
                                    <img src={arrowCircle} alt="arrowCircle"
                                        onClick={() => changeTabsValue("6")}
                                    />
                                </div>
                            </div>
                            <Table
                                columns={emoloyeeAdvanceSalaryColumn}
                                dataSource={allEmployeesAdvanceSummary}
                                pagination={false}
                            />
                        </div> */}

                        {/* Loan */}
                        {/* <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Loan</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img src={add} alt="add" width={35} onClick={() => setEmployeeLoan(true)} />
                                    <img src={arrowCircle} alt="arrowCircle"
                                        onClick={() => changeTabsValue("7")}
                                    />
                                </div>
                            </div>
                            <Table
                                columns={emoloyeeLoanColumn}
                                dataSource={allEmployeeLoanSummary}
                                pagination={false}
                            />
                        </div> */}

                        {/* Hold Salary */}
                        {/* <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Hold Salary</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img src={add} alt="add" width={35} onClick={() => setHoldSalaryModal(true)} />
                                    <img src={arrowCircle} alt="arrowCircle"
                                        onClick={() => changeTabsValue("8")}
                                    />
                                </div>
                            </div>
                            <Table
                                columns={holdSalaryColumns}
                                dataSource={allEmployeeHoldSalarySummary}
                                pagination={false}
                            />
                        </div> */}

                        {/* <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                            <div className='flexSpaceBetween'>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Form 24Q</p>
                                    <button style={{ background: "transparent", border: "1px solid #008080", borderRadius: "4px", color: "#008080", height: "40px", padding: "0 20px" }}
                                        onClick={() => navigate("/admin/payroll/form24Q/setting", { replace: true })}
                                    >Settings</button>
                                </div>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <button style={{ background: "#008080", border: "1px solid #008080", borderRadius: "4px", color: "white", height: "40px", padding: "0 20px" }}
                                        onClick={() => navigate("/admin/payroll/form24Q", { replace: true })}
                                    >Generate Form 24Q</button>
                                </div>
                            </div>
                            <Table
                                columns={form24QColumns}
                                dataSource={[]}
                                pagination={false}
                            />
                        </div> */}
                    </div>
            }

            {/* Hold Salary */}
            <Modal
                title="Hold Salary"
                centered
                open={holdSalaryModal}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => {
                    setHoldSalaryModal(false)
                    holdForm.resetFields();
                    setHoldSalaryDetail({
                        employement_number: "",
                        name: "",
                    });
                }}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishHoldSalary}
                    form={holdForm}
                >
                    <Row gutter={[32, 12]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={holdSalaryDetail.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForHoldSalary(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForHoldSalary(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChangeForAdvance("date", dateString)}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>

            </Modal>

            {/* Revise Salary */}
            <Modal
                title="Revise Salary"
                centered
                open={reviseSalary}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setReviseSalary(false)}
                width={400}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={selectedEmployeeSalary}
                    // onFinishFailed={onFinishFailed}
                    fields={[
                        {
                            name: ["name"],
                            value: selectedEmployeeSalary?.name
                        }
                    ]}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>Current CTC</p>
                            <Form.Item
                                label=""
                                name="appraisal_type"
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Increment',
                                            value: 'increment',
                                        },
                                        {
                                            label: 'Decrement',
                                            value: 'decrement',
                                        },
                                    ]}
                                    // onChange={onChange3}
                                    // value={value3}
                                    optionType="button"
                                    buttonCheckedBg="red"
                                    buttonStyle="solid"
                                    colorBgContainer="#E2F6F6"
                                    size="large"
                                />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="component_choice"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter Name!',
                            //     },
                            // ]}
                            >
                                <Select
                                    size='large'
                                    onChange={(value) => setselectedEmployeeSalaryId({
                                        ...selectedEmployeeSalary,
                                        component_choice: value
                                    })}
                                >
                                    <Select.Option value="">Select</Select.Option>
                                    <Select.Option value={0}>Flat</Select.Option>
                                    <Select.Option value={1}>Percentage</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter Name!',
                            //     },
                            // ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>

                        </Col>
                        {selectedEmployeeSalary.component_choice === 1
                            &&
                            <Col span={24}>
                                <Form.Item
                                // name=""
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please enter Name!',
                                //     },
                                // ]}
                                >
                                    <Input
                                        size='large'
                                        placeholder="Enter Amount"
                                        value="CTC"
                                        disabled={true}
                                    />
                                </Form.Item>

                            </Col>
                        }

                    </Row>
                    <Button style={{ width: "100%", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>

                </Form>
            </Modal>

            <Modal
                title="Change Salary Structure"
                centered
                open={changeSalaryStructure}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => {
                    setChangeSalaryStructure(false)
                    setSelectedEmployeeChangeSalary({
                        id: "",
                        ctc: '',
                        salary_structure: ''
                    })
                    setCtcBreakUp(null)
                }}
                width={600}
                footer={null}>
                <Form
                    // form={form}
                    layout="vertical"
                    onFinish={onFinishCTC}
                    initialValues={selectedEmployeeChangeSalary}
                    autoComplete="true"
                    fields={[
                        {
                            name: ["ctc"],
                            value: selectedEmployeeChangeSalary?.ctc

                        },
                        {
                            name: ['salary_structure'],
                            value: selectedEmployeeChangeSalary?.salary_structure
                        }
                    ]}
                >
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item
                                label="CTC"
                                name="ctc"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter CTC!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter CTC"
                                    disabled="true"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item
                                label="Salary Structure"
                                name="salary_structure"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Salary Structure!",
                                    },
                                ]}
                            >
                                <Select size="large"
                                    onChange={(value) => {
                                        setSelectedEmployeeChangeSalary({
                                            ...selectedEmployeeChangeSalary,
                                            salary_structure: value
                                        })
                                    }}
                                >
                                    {salaryStructureList?.map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.id}
                                            >
                                                {item.structure_name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <button type="submit" style={{
                                padding: "0px 15px",
                                height: "40px",
                                background: "#008080",
                                color: "white",
                                border: "none",
                                borderRadius: "5px"
                            }}>Get BreakUp</button>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {
                            ctcBreakUp?.fixed.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Fixed</p>
                                </Col>

                                {
                                    ctcBreakUp?.fixed?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }

                    </Row>

                    <Row gutter={16}>
                        {
                            ctcBreakUp?.flexi.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Flexi</p>
                                </Col>
                                {
                                    ctcBreakUp?.flexi?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }
                    </Row>

                    <Row gutter={16}>
                        {
                            ctcBreakUp?.retirals?.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Retirals</p>
                                </Col>
                                {
                                    ctcBreakUp?.retirals?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }
                    </Row>

                    <Row gutter={16}>
                        {
                            ctcBreakUp?.variable?.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Variable</p>
                                </Col>
                                {
                                    ctcBreakUp?.variable?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }


                    </Row>
                    {ctcBreakUp?.fixed.length > 0
                        && <div className="buttonContainer">
                            <Button style={{
                                padding: "0px 15px",
                                height: "40px",
                                background: "#008080",
                                color: "white",
                                fontSize: "16px",
                                marginTop: "10px"
                            }} onClick={() => {
                                setChangeSalaryStructure(false)
                                setSelectedEmployeeChangeSalary({
                                    id: "",
                                    ctc: '',
                                    salary_structure: ''
                                })
                                setCtcBreakUp(null)
                            }} >Cancel</Button>

                            <Button style={{
                                padding: "0px 15px",
                                height: "40px",
                                background: "#008080",
                                color: "white",
                                fontSize: "16px",
                                marginTop: "10px"
                            }} onClick={() => changeSalarysubmit()}>Submit</Button>


                        </div>}

                </Form>


            </Modal>
            {/* Revise Salary Using CSV */}

            <Modal
                open={employeeSalaryManagerModal}
                onOk={() => { }}
                onCancel={() => {
                    setEmployeeSalaryManagerModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>Upload CSV</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinishEmployeeSalaryManager}
                // onValuesChange={onValuesChange}
                >

                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <p>Upload File</p>
                            <Form.Item
                                label="Upload File"
                                name="end_time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Date (To)!',
                                    },
                                ]}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className="input-file"
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            if (selectedFile && selectedFile.type === 'text/csv') {
                                                if (selectedFile.size <= maxSizeInBytes) {
                                                    setFile(e.target.files && selectedFile)
                                                }
                                                else {
                                                    setFile(null);
                                                    alert('File size exceeds the limit of 5MB.');
                                                }
                                            } else {
                                                // Reset the file state and show an error message
                                                setFile(null);
                                                alert('Please upload a CSV file.');
                                            }
                                        }}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="file-upload" className="file-upload-label">

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]}>
                        <Col span={10}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                                <a href={downloadLink} download style={{ color: "#008080", textDecoration: "none" }}>
                                    <Button
                                        htmlType='button'
                                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                                    >
                                        Download Sample CSV
                                    </Button>

                                </a>

                                <Button
                                    htmlType='submit'
                                    style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                                >
                                    Submit
                                </Button>

                            </div>
                        </Col>
                    </Row>

                </Form>
            </Modal>

            {/* Approve Reimbursement Amount */}
            <Modal
                title="Approve Reimbursement Amount"
                centered
                footer={null}
                onCancel={() => setApprovedModal(false)}
                open={approvedModal}>
                <Form layout="vertical"
                    onFinish={onFinishForReimbursement} >
                    <Col span={24}>
                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Amount!',
                                }
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder="Enter Amount"
                            />
                        </Form.Item>

                    </Col>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>

            {/* Advance Salary */}
            <Modal
                title="Advance Salary"
                centered
                open={employeeAdvanceSalary}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setEmployeeAdvanceSalary(false)}
                width={1000}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={onFinishAdvanceSalary} >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Number"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={EmployeeAdvanceSalaryData.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForAdvanceSalary(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForAdvanceSalary(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChangeForAdvance("amount", e.target.value)}
                                    value={EmployeeAdvanceSalaryData.amount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="issue_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChangeForAdvance("issue_date", dateString)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>

            {/* Employee Loan */}
            <Modal
                title="Loan"
                centered
                open={employeeLoan}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setEmployeeLoan(false)}
                width={1000}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={onFinishLoan}

                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Number"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={EmployeeLoanData.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForLoan(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForLoan(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChange("amount", e.target.value)}
                                    value={EmployeeLoanData.amount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="issue_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChange("issue_date", dateString)}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Tenure(In Months)"
                                name="tenure"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tenure!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                    placeholder="Enter tenure"
                                    onChange={(e) => onHandleChange("tenure", e)}
                                    value={EmployeeLoanData.tenure}
                                >
                                    {
                                        [...Array(36).keys()].map((item, index) => {
                                            return (
                                                <Select.Option value={index + 1}>{index + 1}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>

                                {/* <Input
                                    size='large'
                                    placeholder="Enter tenure"
                                    onChange={(e) => onHandleChange("tenure", e.target.value)}
                                    value={EmployeeLoanData.tenure}
                                /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Interest(In %)"
                                name="interest"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter interest value!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter interest"
                                    onChange={(e) => onHandleChange("interest", e.target.value)}
                                    value={EmployeeLoanData.interest}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>

            {/* Tax Settings */}
            {/* Regime Declaration Window */}
            <Modal
                title="Regime Declaration Window"
                centered
                open={regimeModel}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setRegimeModel(false)}
                width={800}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={regimeWindow}
                    form={regimeWindowForm}
                    fields={[
                        {
                            name: "from_date",
                            value: regimeWindowFormState.from_date,
                        },
                        {
                            name: "end_date",
                            value: regimeWindowFormState.end_date,
                        },
                    ]}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="From"
                                name="from_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter employee number!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="To"
                                name="end_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter amount!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        {
                            regimeWindowLoader
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                                :
                                "Submit"
                        }
                    </Button>
                </Form>
            </Modal>

            {/* Tax Settings */}
            {/* Tax Declaration Window */}
            <Modal
                title="Tax Declaration Window"
                centered
                open={taxDeclarationWindowModel}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setTaxDeclarationWindowModel(false)}
                width={800}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={taxDeclarationWindow}
                    form={taxDeclarationWindowForm}
                    fields={[
                        {
                            name: "from_date",
                            value: taxDeclarationWindowFormState.from_date,
                        },
                        {
                            name: "end_date",
                            value: taxDeclarationWindowFormState.end_date,
                        },
                    ]}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="From"
                                name="from_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter employee number!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="To"
                                name="end_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter amount!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        {
                            texDeclarationWindowLoader
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                                :
                                "Submit"
                        }
                    </Button>
                </Form>
            </Modal>

            <Modal
                centered
                open={reimbursementModel}
                onOk={() => setReimbursementModel(false)}
                onCancel={() => setReimbursementModel(false)}
                width={1000}
                footer={null}
            >
                <p className={styles.reimbursementModelTitle}>Reimbursement Details</p>
                <Row gutter={[32, 12]}>
                    <Col span={11}>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Name</p>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.employee_name}</p>
                        </div>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Title</p>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.title}</p>
                        </div>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Claimed On</p>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.date}</p>
                        </div>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Amount Claimed</p>
                            <p className={styles.reimbursementModelValue}>₹{selectedReimbursementData?.claim_amount}</p>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Status</p>
                            <Select
                                value={selectedReimbursementData?.approved === null ? 0 : selectedReimbursementData?.approved}
                                onChange={(value) => onHandleChangeForReimbursementAmount(value, selectedReimbursementData.id)}
                                style={{ width: 150 }}
                                size='middle'
                            >
                                <Select.Option value={0}>Select</Select.Option>
                                <Select.Option value={1}>Partially Approved</Select.Option>
                                <Select.Option value={2} >Approved</Select.Option>
                            </Select>
                        </div>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Invoice ID</p>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.invoice_id}</p>
                        </div>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Merchant Name</p>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.merchant_name}</p>
                        </div>
                        <div className={styles.reimbursementModelKeyValueContainer}>
                            <p className={styles.reimbursementModelKey}>Amount Approved</p>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.approved_amount && `₹${selectedReimbursementData?.approved_amount}`}</p>
                        </div>
                    </Col>
                    <Col span={24}>
                        <p className={styles.reimbursementModelKey}>Description</p>
                        <div style={{ border: "1px solid #DDDDDD", background: "#F8F8F8", padding: "10px" }}>
                            <p className={styles.reimbursementModelValue}>{selectedReimbursementData?.description}</p>
                        </div>
                    </Col>
                    <Col span={24}>
                        <p className={styles.reimbursementModelKey}>Download documents</p>


                        {
                            selectedReimbursementData?.reimbursement_files?.map((item, index) => {
                                return (
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between", padding: "10px", background: "#F8F8F8", width: "50%", marginBottom: "10px" }}>
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <img src={activeDocumentImg} alt="activeDocumentImg" />
                                            <p style={{ margin: "0px", color: "#505050", fontWeight: "600", fontSize: "16px" }}>{item?.file_name}</p>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <img src={view} alt="view" width={18} height={18}
                                                onClick={() => {
                                                    window.open(item?.file_upload)
                                                }}
                                            />
                                            {/* download  pdf*/}

                                            <a href={item.file_upload} target="_blank" download>
                                                <img src={activeDownload} alt="activeDownload" width={18} height={18}
                                                    onClick={async () => {

                                                        try {
                                                            const response = await fetch(item.file_upload);
                                                            const blob = await response.blob();
                                                            saveAs(blob, `${item.file_name}.pdf`);
                                                        } catch (error) {
                                                            console.error('Error downloading PDF:', error);
                                                        }
                                                    }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Modal>

            {/* Salary Structure => component */}
            <Modal
                centered
                open={componentModel}
                onOk={() => {
                    setComponentModal(false)
                    componentForm.resetFields()
                    setComponentDetail({
                        ...componentDetail,
                        name: "",
                        components: []
                    })
                }}
                onCancel={() => {
                    setComponentModal(false)
                    componentForm.resetFields()
                    setComponentDetail({
                        ...componentDetail,
                        name: "",
                        components: []
                    })
                }}
                width={1000}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={componentsSubmit}
                    form={componentForm}
                    fields={[
                        {
                            name: "name",
                            value: componentDetail?.name,
                        },
                        {
                            name: "components",
                            value: componentDetail?.components,
                        },
                    ]}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select name!',
                                    },
                                ]}
                            >
                                <Select
                                    // className='multiSelectDropDown'
                                    // showSearch={false}
                                    size="large"
                                    dafaultValue={componentDetail.name}
                                >
                                    {
                                        componentNameList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.name}{item.id}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Components"
                                name="components"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select component!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    className='multiSelectDropDown'
                                    showSearch={false}
                                    size="large"
                                    dafaultValue={componentDetail?.components}
                                >
                                    {
                                        componentTypeList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
        </>
    )

}

export default DashBoard;