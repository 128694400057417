import React from "react";
import { useMemo, useEffect, useState } from "react";
import request from "../../../../utils/axios";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Spin, Input, Timeline, Switch } from "antd";
import duration from "../../../../assets/Attendance/Admin/duration.svg";
import mylocation from "../../../../assets/mylocation.png";
// import Leaflet from "leaflet";
// import "leaflet/dist/leaflet.css";
import mapLocation from "../../../../assets/location.svg";
import Search from "../../SearchBar/Search";
import getScreenSize from "../../../../utils/getScreenSize";
import MobileStyle from "./MobileStyle.module.scss";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
function Location() {
  const screenSize = getScreenSize();
  const [employeeList, setEmployeeList] = useState([]);
  const [tabs, setTabs] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    loadData();
  }, []);
  const [showMap, setShowMap] = useState(false);

  // useEffect(() => {
  //   if (coordinates?.length >= 1) {
  //     // Initialize DirectionsService to calculate the route
  //     const directionsService = new window.google.maps.DirectionsService();

  //     const origin = coordinates[0]; // Start from the first coordinate
  //     const destination = coordinates[coordinates.length - 1]; // End at the last coordinate
  //     const waypoints = coordinates.slice(1, -1).map((point) => ({
  //       location: { lat: point.lat, lng: point.lng },
  //       stopover: true,
  //     }));

  //     // Create a request object for the route
  //     const request = {
  //       origin,
  //       destination,
  //       waypoints,
  //       travelMode: window.google.maps.TravelMode.DRIVING, // Driving mode for road route
  //     };

  //     // Call DirectionsService to get the route
  //     directionsService.route(request, (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         setDirectionsResponse(result);
  //       } else {
  //         console.error("Directions request failed due to " + status);
  //       }
  //     });
  //   } else {
  //     setDirectionsResponse(null);
  //   }
  // }, [coordinates]);
  const loadData = async (employement_number) => {
    const currentDate = moment().format("YYYY-MM-DD");
    let emp_Id = employement_number || "";
    const apiUrl = `tracking/reporting-manager/employee/tracking-list?employee_id=${emp_Id}&date=${currentDate}`;
    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });
      console.log(res.data);
      setEmployeeList(res?.data);
      setCoordinates(res?.data[0].cordinates);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const search = async (data) => {
    try {
      setSearchValue(data);
      const response = await request({
        method: "get",
        url: `tracking/reporting-manager/employee/search?employee_name=${data}`,
      });

      setSearchEmployeeList(response);
      if (data === "") {
        loadData();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchSelectChange = async (
    employement_number,
    employee_name
  ) => {
    console.log(employement_number);
    loadData(employement_number);
    setSearchEmployeeList([]);
  };
  const handleTrackLocation = (item) => {
    console.log("item", item);
    setCoordinates(item.cordinates);
    fetchEmployeeTimeLine(item.employement_number);
  };

  const [employeeDetails, setEmployeeDetails] = useState({
    employee_name: "",
    designation: "",
    meeting_details: [],
    total_distance: "",
    total_duration: "",
    total_visits: "",
  })
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

  const fetchEmployeeTimeLine = async (employement_number) => {
    try {
      const response = await request({
        method: "get",
        url: `tracking/admin/employee-tracking/details?employement_number=${employement_number}&date=2024-10-07`,
      })
      console.log("response", response.data);
      setEmployeeDetails({
        ...employeeDetails,
        ...response.data,
        meeting_details: response.data.meeting_details.map((item, index) => {
          return {
            dot: <div style={{ width: "24px", height: "24px", backgroundColor: "#008080", borderRadius: "50%", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</div>,
            children: <div>
              <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px", marginBottom: "5px" }}>{item.client_name}</p>
              <div style={{ display: "flex", alignItems: "start", gap: "10px" }}>
                <img src={mylocation} alt="mylocation" width={17} height={17} />
                <p>{item.address}</p>
              </div>
            </div>
          }
        })
      });
    }
    catch (error) {
      console.log("error", error);
    }
  }


  return (
    <div style={{ background: "white" }}>
      {screenSize.width < 768 ? (
        // moible View
        <div className={MobileStyle.container}>
          <h2>Team Location</h2>

          <div className={MobileStyle.search}>
            <div className="search-container">
              <Input
                size="small"
                type="text"
                placeholder="Search for employees"
                onChange={(e) => search(e.target.value)}
                value={searchValue}
                prefix={<SearchOutlined />}
                allowClear
              />
              {searchEmployeeList.length > 0 && (
                <ul>
                  {searchEmployeeList.map((item, index) => (
                    <li
                      key={index}
                      onClick={() =>
                        handleSearchSelectChange(
                          item.employement_number,
                          item.employee_name
                        )
                      }
                    >
                      {item.employee_name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {tabs == 1 && (
            <div className={MobileStyle.EmployeeDiv}>
              {employeeList.map((item, index) => {
                return (
                  <div key={index} className={MobileStyle.employee_element}>
                    <div className={MobileStyle.top}>
                      <div className={MobileStyle.name}>
                        {item.employee_name}
                      </div>
                      <div className={MobileStyle.buttonContainer}>
                        <button
                          onClick={() => {
                            navigate(`/timeline/${item.employement_number}`)
                          }}
                        >
                          Track
                        </button>
                      </div>
                    </div>
                    <div className={MobileStyle.middle}>
                      <img
                        src={duration}
                        alt="duration"
                        width={18}
                        height={18}
                      />
                      <span>Sales manager</span>
                    </div>
                    <div className={MobileStyle.bottom}>
                      <img
                        src={mylocation}
                        alt="mylocation"
                        width={18}
                        height={18}
                      />
                      <span>
                        BLOCK-F, F61, South City II, Sector 50, Gurugram,
                        Haryana 122018
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {tabs == 2 && (
            <div className={MobileStyle.mapContainer}>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  center={coordinates[0]} // Ensure you have valid coordinates
                  zoom={6}
                >
                  {coordinates.length >= 1 && (
                    <DirectionsService
                      options={{
                        origin: coordinates[0],
                        destination: coordinates[coordinates.length - 1],
                        waypoints: coordinates.slice(1, -1).map((point) => ({
                          location: { lat: point.lat, lng: point.lng },
                          stopover: true,
                        })),
                        travelMode: "DRIVING", // Driving mode for road route
                      }}
                      callback={(result, status) => {
                        if (status === "OK") {
                          setDirectionsResponse(result);
                        } else {
                          console.error(
                            "Directions request failed due to " + status
                          );
                        }
                      }}
                    />
                  )}

                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            height: "80%",
            gridTemplateColumns: "1fr 2fr",
          }}
        >
          <div
            style={{
              padding: "30px",
              borderRadius: "5px",

              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div
              style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}
            >
              Employees
            </div>
            <div>
              {" "}
              <div className="search-container" style={{ width: "25vw" }}>
                <Input
                  size="small"
                  type="text"
                  placeholder="Search for employees"
                  onChange={(e) => search(e.target.value)}
                  value={searchValue}
                  prefix={<SearchOutlined />}
                  allowClear
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSearchSelectChange(
                            item.employement_number,
                            item.employee_name
                          )
                        }
                      >
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div
              style={{
                overflow: "scroll",
                height: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {employeeList.map((item, index) => {
                return (
                  <div key={index} style={{ width: "25vw" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#161616",
                          margin: "0",
                        }}
                      >
                        {item.employee_name}
                      </p>
                      <button
                        style={{
                          border: "1px solid #008080",
                          background: "#008080",
                          cursor: "pointer",
                          color: "white",
                          height: "36px",
                          width: "80px",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          handleTrackLocation(item);
                        }}
                      >
                        Track
                      </button>
                    </div>
                    <div>
                      <img
                        src={duration}
                        alt="duration"
                        width={18}
                        height={18}
                        style={{ marginRight: "10px" }}
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#616161",
                        }}
                      >
                        {item.designation}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={mylocation}
                        alt="mylocation"
                        width={18}
                        height={18}
                        style={{ marginRight: "10px" }}
                      />
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#616161",
                        }}
                      >
                        {item.location}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ width: "28px", height: "28px", backgroundColor: "#E6F9F5", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span style={{ color: "#008080" }}>{employeeDetails?.employee_name?.slice(0, 1).toUpperCase()}</span>
                </div>
                <div>
                  <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px", marginTop: "18px" }}>{employeeDetails?.employee_name}</p>
                  <p style={{ fontSize: "14px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>{employeeDetails?.designation}</p>
                </div>
              </div>
              <div >
                <Switch
                  size='medium'
                  onChange={() => setShowMap(!showMap)}
                />
                <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px", marginLeft: "10px" }}>Map view</span>
              </div>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "7px", marginTop: "20px" }}>
              <div style={{ backgroundColor: "#FAFAFA", padding: "10px", borderRadius: "5px" }}>
                <p style={{ fontSize: "14px", fontWeight: "500", color: "#008080", margin: "0px" }}>{Math.ceil(employeeDetails?.total_distance * 100) / 100}</p>
                <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>Dist. Travelled</span>
              </div>
              <div style={{ backgroundColor: "#FAFAFA", padding: "10px", borderRadius: "5px" }}>
                <p style={{ fontSize: "14px", fontWeight: "500", color: "#008080", margin: "0px" }}>{employeeDetails?.total_duration}</p>
                <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>Time Duration</span>
              </div>
              <div style={{ backgroundColor: "#FAFAFA", padding: "10px", borderRadius: "5px" }}>
                <p style={{ fontSize: "14px", fontWeight: "500", color: "#008080", margin: "0px" }}>{employeeDetails?.total_visits}</p>
                <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>Client Visit</span>
              </div>
            </div>
            {
              showMap
                ?
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                >
                  <GoogleMap
                    mapContainerStyle={{ height: "550px", width: "100%" }}
                    center={coordinates && coordinates[0]} // Center the map around the first coordinate
                    zoom={6}
                  >
                    {coordinates.length >= 1 && (
                      <DirectionsService
                        options={{
                          origin: coordinates[0],
                          destination: coordinates[coordinates.length - 1],
                          waypoints: coordinates.slice(1, -1).map((point) => ({
                            location: { lat: point.lat, lng: point.lng },
                            stopover: true,
                          })),
                          travelMode: "DRIVING", // Driving mode for road route
                        }}
                        callback={(result, status) => {
                          if (status === "OK") {
                            setDirectionsResponse(result);
                          } else {
                            console.error(
                              "Directions request failed due to " + status
                            );
                          }
                        }}
                      />
                    )}
                    {directionsResponse && (
                      <DirectionsRenderer directions={directionsResponse} />
                    )}
                  </GoogleMap>
                </LoadScript>
                :
                <div style={{ padding: "16px" }}>
                  <Timeline
                    items={employeeDetails?.meeting_details}
                  />
                </div>
            }
          </div>


        </div>
      )}
    </div>
  );
}

export default Location;
