import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';


import './PersonalDetails.scss';
// import "../../../styles/_global.scss";

import { useSelector, useDispatch } from 'react-redux'
import { setPersonalDetails, setEmploymentDetails } from "../../../redux/reducer/OnboardingReducer"

// import onboardingImg from "../../assets/onboardingImg.svg";
import onboardingImg from "../../../assets/onboardingImg.svg";
import moment from 'moment';
import request from '../../../utils/axios';
import dayjs from 'dayjs';


// import DatePicker from "../../component/Date/DatePicker"
function PersonalDetails({ next }) {
    const ref = useRef();
    let personalDetails = useSelector((state) => state.onboarding.personalDetails)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const [form] = Form.useForm();
    const { TextArea } = Input;

    useEffect(() => {
        // Set the initial value for the "Anniversary" field based on the current value of "Marital Status"
        // handleMaritalStatusChange(form.getFieldValue('marital_status'));
        fetchData();
    }, []);

    const [personalDetailsData, setPersonalDetailsData] = useState({});
    const fetchData = async () => {
        try {
            const employee_number = localStorage.getItem("employee_number")

            let data = await request({
                method: "get",
                url: `/get-employee-details/${employee_number}`,
            })
            const dob = new Date(data.data.dob).toISOString();
            console.log(dob);
            console.log("days", dayjs(dob));
            let anniversary;
            if (data.data.anniversary) {
                anniversary = new Date(data.data.anniversary).toISOString();
            }

            setPersonalDetailsData({
                // ...data.data,
                employee_name: personalDetails.employee_name ? personalDetails.employee_name : data.data.employee_name,
                anniversary: personalDetails.anniversary ? personalDetails.anniversary : anniversary,
                marital_status: personalDetails.marital_status !== null ? personalDetails.marital_status : data.data.marital_status,
                gender: personalDetails.gender !== null ? personalDetails.gender : data.data.gender,
                address: personalDetails.address ? personalDetails.address : data.data.address,
                contact: personalDetails.contact ? personalDetails.contact : data.data.contact,
                personal_email: personalDetails.personal_email ? personalDetails.personal_email : data.data.personal_email,
                // dob: dayjs(dob)
                dob: personalDetails.dob ? personalDetails.dob : dayjs(dob),
            })
            if (data.data.maritalStatus === 0 || personalDetails.marital_status === 0) {
                setShow(true)
            } else {
                setShow(false)
            }
            dispatch(setEmploymentDetails(data.data));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onValuesChange = (changedValues, allValues) => {
        const fieldName = Object.keys(changedValues)[0];
        const fieldValue = changedValues[fieldName];

        if (fieldName === "marital_status") {
            if (fieldValue === 0) {
                setPersonalDetailsData({ ...personalDetailsData, [fieldName]: fieldValue })
                setShow(true)
            } else {
                setShow(false)
                setPersonalDetailsData({ ...personalDetailsData, [fieldName]: fieldValue, anniversary: null })
            }
        }
        else {
            setPersonalDetailsData({ ...personalDetailsData, [fieldName]: fieldValue })
        }


    }

    const onFinish = (values) => {

        dispatch(setPersonalDetails(values));

        next();
    };

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);


    return (
        <div className='personalDetailsContainer' ref={ref}>
            <div className='formContainer'>
                <p className='heading'>Ready to Start</p>
                <p className='subHeading'>Fill up your personal details</p>
                <div style={{ width: "100%" }}>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={personalDetails}
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                        autoComplete="true"
                        fields={
                            [
                                {
                                    name: ["employee_name"],
                                    value: personalDetailsData?.employee_name
                                },
                                {
                                    name: ["personal_email"],
                                    value: personalDetailsData?.personal_email
                                },
                                {
                                    name: ["contact"],
                                    value: personalDetailsData?.contact
                                },
                                {
                                    name: ["dob"],
                                    value: personalDetailsData?.dob
                                },
                                {
                                    name: ["address"],
                                    value: personalDetailsData?.address
                                },
                                {
                                    name: ["marital_status"],
                                    value: personalDetailsData?.marital_status
                                },
                                {
                                    name: ["gender"],
                                    value: personalDetailsData?.gender
                                },
                                {
                                    name: ["anniversary"],
                                    value: personalDetailsData?.anniversary
                                },
                            ]
                        }
                    >
                        <Row gutter={[32, 8]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Name"
                                    name="employee_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Personal Email Id"
                                    name="personal_email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your email!',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Contact No."
                                    name="contact"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your contact!',
                                        },
                                        {
                                            pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                                            message: 'Please enter a valid 10-digit contact number!',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                        type='number'
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Date of Birth"
                                    name="dob"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your Date of Birth!',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        size='large'
                                        disabledDate={(current) => current && current > dayjs().endOf('day')}
                                    // className='inputField'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your address!',
                                        },
                                    ]}
                                >
                                    <TextArea rows={5}
                                        // className='textArea' 
                                        size='large'
                                    // style={{
                                    //     width: "70%"
                                    // }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Marital Status"
                                    name="marital_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your Marital Status!',
                                        },
                                    ]}
                                >
                                    <Select
                                        // className='dropDown'
                                        size='large'
                                    // onChange={(value) => handleMaritalStatusChange(value)}
                                    >
                                        <Select.Option value={0}>Married</Select.Option>
                                        <Select.Option value={1}>Single</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Gender"
                                    name="gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your gender!',
                                        },
                                    ]}
                                >
                                    <Select
                                        // className='dropDown'
                                        size='large'
                                    // style={{
                                    //     width: "70%"
                                    // }}
                                    >
                                        <Select.Option value={0}>Male</Select.Option>
                                        <Select.Option value={1}>Female</Select.Option>
                                        <Select.Option value={2}>Other</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                {show && (
                                    <Form.Item
                                        label="Anniversary"
                                        name="anniversary"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your Anniversary!',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            size='large'
                                            style={{
                                                width: "70%"
                                            }}
                                            disabledDate={(current) => current && current > dayjs().endOf('day')}
                                        // className='inputField'
                                        // initialValues={personalDetails?.dob ? dayjs(personalDetails["dob"], "YYYY-MM-DD") : ""}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Button className='button' htmlType='submit' >Next</Button>
                    </Form>
                </div>
            </div>
            {/* <div className='sideImage'> */}
            {/* <img src={onboardingImg} alt="side image" /> */}
            {/* </div> */}
        </div>
    )
}

export default PersonalDetails

