import React, { useState, useEffect } from 'react';
import styles from './LmsDetails.module.scss';
import request from '../../../../utils/axios';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import leave from "../../../../assets/Inbox/leave.png";
// import performance from "../../../assets/Inbox/Increase.png";
import resign from "../../../../assets/Inbox/resignation.png";
import LeaveList from '../../Leave/LeaveList/LeaveList';
import Resignation from '../../Resignation/Resignation';
import documentation from "../../../../assets/Inbox/newDocumentSigning.png";
import dashboardStyles from "../../Dashboard/Dashboard.module.scss"
import getScreenSize from "../../../../utils/getScreenSize";
import { Tabs } from 'antd';
import Documentation from '../../Documentation/Documentation';
import AttendanceList from '../../Attendance/AttendanceList/AttendanceList';
import training from "../../../../assets/Training.svg";
import Attendance from "../../../../assets/Inbox/attendance.png";
import InboxLmsList from '../InboxLmsList';


function InboxLmsDetail({ leaveId, isListView, setIsListView }) {
    const { width } = getScreenSize();

    const menu = [
        {
            label: "Leave",
            key: "leave",
            url: "/inbox/leave",
            image: leave,
            component: <LeaveList />,
          },
          {
            label: "New Documentation Signing",
            key: "documentation",
            url: "/inbox/documentation",
            image: documentation,
            component: <Documentation />,
          },
          {
            label: "Resignation",
            key: "resign",
            url: "/inbox/resign",
            image: resign,
            component: <Resignation />,
          },
          {
            label: "Attendance",
            key: "attendance",
            url: "/inbox/attendance",
            image: Attendance,
            component: <AttendanceList />,
          },
          // {
          //     label: "Performance Management",
          //     key: "performance",
          //     url: "/inbox/performance",
          //     image: performance
          // },
          {
            label: "Training",
            key: "lms",
            url: "/inbox/lms",
            image: training,
            component: <InboxLmsList />,
          },
    ]

    const items = [
        {
            key: "leave",
            label: "Leave",
            children: <LeaveList />,
          },
          {
            key: "documentation",
            label: "Documentation",
            children: <Documentation />,
          },
          {
            key: "resign",
            label: "Resignation",
            children: <Resignation />,
          },
          {
            key: "attendance",
            label: "Attendance",
            children: <AttendanceList />,
          },
          {
            key: "training",
            label: "Training",
            children: <InboxLmsList />,
          },
    ];


    const navigate = useNavigate();
    const [lmsDetails, setLmsDetail] = useState(null);
    const params = useParams();
    const [selectedMenu, setSelectedMenu] = useState("lms");

    useEffect(() => {
        if (params.id) {
            fetchLmsDetail(params.id);
        }
    }, [params.id]);

    const fetchLmsDetail = async (id) => {
        try {
            const data = await request({
                method: "get",
                url: `inbox/training-video/details?id=${id}`,
            });

            setLmsDetail(data?.data);
            // console.log(data.data);
        } catch (error) {
            console.log(error);
        }
    };
    // const rejectOrApproveLeave = async (id, isRejected) => {
    //     try {
    //         await request({
    //             method: "post",
    //             url: `leave/my-team/leave_application/approval/?id=${id}`,
    //             data: {
    //                 is_rejected: isRejected
    //             }
    //         });

    //         setTimeout(() => {
    //             // setIsListView(true);
    //             window.location.reload();
    //         }, 1000);

    //         if (isRejected)
    //             toast.success("Leave Rejected", {
    //                 position: "bottom-left",
    //             });
    //         else {
    //             toast.success("Leave Approved", {
    //                 position: "bottom-left",
    //             });
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         if (isRejected) {
    //             toast.error("Leave could not be rejected, please try after some time", {
    //                 position: "bottom-left",
    //             });
    //         } else {
    //             toast.error("Leave could not be approved, please try after some time", {
    //                 position: "bottom-left",
    //             });
    //         }

    //     }
    // };
    // function LeaveDetail({ isListView, setIsListView }) {
    return (
        <>
            {
                width < 768
                    // mobile view
                    ?
                    <>
                        <div style={{ width: "100%" }}>
                            <Tabs activeKey={selectedMenu} items={items} style={{ width: "100%" }}
                                onChange={(value) => {
                                    setSelectedMenu(value);
                                    navigate(`/inbox/${value}`, { replace: true })
                                }}
                            />
                            <div className={styles.leaveDetailContainer}>

                                <div className={styles.userImageNameBox}>
                                    <img
                                        loading="lazy"
                                        srcSet={lmsDetails?.profile_image ? lmsDetails.profile_image : `https://ui-avatars.com/api/?name=${lmsDetails?.added_by.charAt(0)}`}
                                        className={styles.userImage}
                                    />
                                    <div className={styles.userName}>{lmsDetails?.added_by}</div>
                                </div>
                                <div className={styles.leaveDetails}>
                                    <p>
                                        <span className={styles.hi}>Hi</span>{" "}
                                        <span className={styles.reportingManager}>{lmsDetails?.employee_name},</span>
                                    </p>
                                    <p className={styles.leaveInfo}>
                                        {lmsDetails?.added_by} has uploaded a new training video.
                                    </p>
                                    {/* <div className={styles.leaveDuration}>
                                        <p className={styles.leaveLabel}>No. of Leaves</p>
                                        <p className={styles.leaveValue}>{lmsDetails?.leave_day} Leaves</p>
                                    </div>
                                    <div className={styles.leaveDate}>Details</div>
                                    {lmsDetails?.leave_dates?.map((dateInfo, index) => (
                                        <div key={index} className={styles.leaveDateContainer}>
                                            <p key={index} className={styles.leaveDay}>
                                                {dateInfo.date}
                                            </p>
                                            <p className={styles.leaveType}>{dateInfo.leave_for === 'full_day' ? 'Full Day' : 'Half Day'}</p>
                                        </div>
                                    ))} */}
                                    <div className={styles.actions}>
                                        <button className={styles.approve}
                                            onClick={() => {
                                                navigate(`/lms/instruction/${params.id}` , { replace: true });
                                            }}
                                        >Watch Now</button>
                                        {/* <button className={styles.reject}
                                            onClick={() => {
                                                // rejectOrApproveLeave(lmsDetails.id, true);
                                            }}
                                        >
                                            Later</button> */}
                                    </div>
                                    <div className={styles.regards}>Regards</div>
                                    <div className={styles.hrTeam}>Team HR</div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className={dashboardStyles.dashboardContainer}>
                        <div className={dashboardStyles.dashboardInnerContainer}>
                            <div>
                                <p className={dashboardStyles.inboxHeading}>Inbox</p>
                                {
                                    menu?.map((item) => {
                                        return (
                                            <div
                                                className={selectedMenu === item.key ? dashboardStyles.selectedItemBox : dashboardStyles.itemBox}
                                                onClick={() => navigate(item.url)}
                                            >
                                                <img src={item.image} alt={item.image} />
                                                <p className={dashboardStyles.label}>{item.label}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ width: "100%" }}>
                                <div className={styles.leaveDetailContainer}>
                                    <div className={styles.backBox} onClick={() => navigate('/inbox/lms')}>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/05cfa04f1b309ee35d7f2d1c66a1927dcfeded1810a60d950eda8db082c37e71?"
                                            className={styles.img}
                                        />
                                        <div className={styles.backText}
                                        >Back</div>
                                    </div>
                                    <div className={styles.userImageNameBox}>
                                        <img
                                            loading="lazy"
                                            srcSet={lmsDetails?.profile_image ? lmsDetails.profile_image : `https://ui-avatars.com/api/?name=${lmsDetails?.added_by.charAt(0)}`}
                                            className={styles.userImage}
                                        />
                                        <div className={styles.userName}>{lmsDetails?.added_by}</div>
                                    </div>
                                    <div className={styles.leaveDetails}>
                                        <div className={styles.leaveHeader}>
                                            <span className={styles.leaveInfo}>Hello</span>{" "}
                                            <span className={styles.recipient}>{lmsDetails?.employee_name},</span>
                                        </div>
                                        <div className={styles.leaveInfo}>
                                            {lmsDetails?.added_by} has uploaded a new training video.
                                        </div>

                                        <div className={styles.actions}>
                                            <div className={styles.approve}
                                                onClick={() => {
                                                    navigate(`/lms/instruction/${params.id}` , { replace: true });
                                                }}
                                            >Watch Now</div>
                                            {/* <div className={styles.reject}
                                                onClick={() => {
                                                    // rejectOrApproveLeave(lmsDetails.id, true);
                                                }}
                                            >
                                                Later</div> */}
                                        </div>
                                        <div className={styles.regards}>Best Regards</div>
                                        <div className={styles.hrTeam}>Team HR</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>



    )
}

export default InboxLmsDetail