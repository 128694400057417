import { Tabs } from 'antd'
import React, { useState } from 'react'
import Dashboard from '../Dashboard/Dashboard';
import Posts from '../Posts/Posts';
import SurveyAnalysis from '../SurveyAnalysis/SurveyAnalysis';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';

function FeedsTab() {
    const [currentTab, setCurrentTab] = useState("1")
    const tab_items = [
        {
            key: '1',
            label: 'Dashboard',
            children: <ContainerLayout><Dashboard setCurrentTab={setCurrentTab} /></ContainerLayout>,
        },
        {
            key: '2',
            label: 'Posts',
            children: <ContainerLayout> <Posts /></ContainerLayout>,
        },
        {
            key: '3',
            label: 'Survey Analysis',
            children: <ContainerLayout><SurveyAnalysis /></ContainerLayout>,
        },
    ];

    return (
        <div>
            <Tabs
                activeKey={currentTab}
                items={tab_items}
                onChange={(e) => setCurrentTab(e)}
            />
        </div>
    )
}

export default FeedsTab