import React, { useEffect, useRef, useState } from "react";
// import Location from "../Location/Location";
import TeamLocation from "./Location/Location";
import TeamMeeting from "./TeamMeeting/TeamMeeting";
import MyMeeting from "./Mymeeting/MyMeeting";
import getScreenSize from "../../../../utils/getScreenSize";
const Dashboard = ({ changeTabsValue }) => {
  const screenSize = getScreenSize();
  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent.includes("MyAppWebView/1.0");
  };
  const [isApp, setIsApp] = useState(false);
  const [tracking, setTrackikng] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log("cccccc", userAgent.includes("MyAppWebView/1.0"));

    // const userAgent = window.navigator.userAgent.toLowerCase();
    // const isRunningInWebView =
    //     userAgent.includes('iphone') ||
    //     userAgent.includes('android') ||
    //     userAgent.includes('ipad') ||
    //     userAgent.includes('ipod') ||
    //     userAgent.includes('webview');
    // console.log('isRunningInWebView', isRunningInWebView);
    // setIsApp(isRunningInWebView);
    setIsApp(userAgent.includes("MyAppWebView/1.0"));
  }, []);

  function sendMessageToReactNative() {
    const EmployeeId = "EmployeeId";
    const employee_id = localStorage.getItem("employee_number");
    console.log("message....:", EmployeeId);
    console.log("message....:", employee_id);
    if (
      window.ReactNativeWebView &&
      typeof window.ReactNativeWebView.postMessage === "function"
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ EmployeeId, employee_id })
      );
    } else {
      console.warn("ReactNativeWebView is not defined.");
    }
  }
  const handleStartTracking = () => {
    console.log(localStorage?.getItem("employee_number"));
    const employee_id = localStorage?.getItem("employee_number");
    console.log("tracking start", employee_id);
    setTrackikng(!tracking);
  };
  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div style={{ padding: "20px" }}>
            {isApp ? (
              <button
                onClick={() => sendMessageToReactNative()}
                style={{
                  border: "1px solid #008080",
                  backgroundColor: "#008080",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "500",
                  height: "36px",
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                Start Tracking
              </button>
            ) : (
              <button
                onClick={() => handleStartTracking()}
                style={{
                  border: "1px solid #008080",
                  backgroundColor: "#008080",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "500",
                  height: "36px",
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                {!tracking ? "Start Tracking" : "Stop Tracking"}
              </button>
            )}
          </div>
          <TeamLocation changeTabsValue={changeTabsValue} />
          <div
            style={{
              height: "12px",
              backgroundColor: "rgb(247, 248, 252)",
              marginTop: "20px",
            }}
          ></div>
          <MyMeeting changeTabsValue={changeTabsValue} />
          <div
            style={{
              height: "12px",
              backgroundColor: "rgb(247, 248, 252)",
              marginTop: "20px",
            }}
          ></div>
          <TeamMeeting changeTabsValue={changeTabsValue} />{" "}
        </div>
      ) : (
        //desktop view
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TeamLocation changeTabsValue={changeTabsValue} />

            <MyMeeting changeTabsValue={changeTabsValue} />
            <TeamMeeting changeTabsValue={changeTabsValue} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
// rgb(247, 248, 252)
