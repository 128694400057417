import React, { useState, useEffect } from "react";
// import LeaveDetail from '../LeaveDetail/LeaveDetail'
import styles from "./AttendanceList.module.scss";
import request from "../../../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getScreenSize from "../../../../utils/getScreenSize";

// images
import leave from "../../../../assets/Inbox/leave.png";
import documentation from "../../../../assets/Inbox/newDocumentSigning.png";
import resign from "../../../../assets/Inbox/resignation.png";
import activeAttendance from "../../../../assets/activeInbox.svg";
import attendance from "../../../../assets/Inbox/attendance.png";

import dashboardStyles from "../../Dashboard/Dashboard.module.scss";
import { Tabs } from "antd";
import LeaveList from "../../Leave/LeaveList/LeaveList";
import Documentation from "../../Documentation/Documentation";
import Resignation from "../../Resignation/Resignation";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";

// Import statements remain the same...

function AttendanceList() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [isListView, setIsListView] = useState(true);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState(null);
  const navigate = useNavigate();
  const { width } = getScreenSize();
  const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

  const menu = [
    {
      label: "Leave",
      key: "leave",
      url: "/inbox/leave",
      image: leave,
      // component: <LeaveList />
    },
    {
      label: "New Documentation Signing",
      key: "documentation",
      url: "/inbox/documentation",
      image: documentation,
    },
    {
      label: "Resignation",
      key: "resign",
      url: "/inbox/resign",
      image: resign,
      // component: <Resignation />
    },
    {
      label: "Attendance",
      key: "attendance",
      url: "/inbox/attendance",
      image: attendance,
      activeImage: activeAttendance,
    },
    // {
    //     label: "Performance Management",
    //     key: "performance",
    //     url: "/inbox/performance",
    //     image: performance
    // },
  ];

  const [selectedMenu, setSelectedMenu] = useState("attendance");

  useEffect(() => {
    const selectedMenu = window.location.href
      ?.split(frontend_base_url)[1]
      ?.split("/")[3];

    setSelectedMenu(selectedMenu);
  }, [window.location.href]);

  useEffect(() => {
    fetchMyTeamAttendanceDetails();
  }, []);

  const fetchMyTeamAttendanceDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/exemption/list?page=1&page_size=10`,
      });

      setAttendanceData(data?.pagination_data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectOrApproveLeave = async (id, isRejected, type) => {
    try {
      console.log(id, isRejected, type);
      if (type === "Excemption") {
        await request({
          method: "post",
          url: `attendance/exemption/action?id=${id}`,
          data: {
            is_approved: isRejected,
          },
        });
      }

      if (type === "Regularization") {
        await request({
          method: "post",
          url: `attendance/regularization/action?id=${id}`,
          data: {
            is_approved: isRejected,
          },
        });
      }

      setTimeout(() => {
        fetchMyTeamAttendanceDetails();
      }, 1000);

      if (isRejected)
        toast.success("Exemption Approved", {
          position: "bottom-left",
        });
      else {
        toast.success("Exemption Rejected", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
      if (isRejected) {
        toast.error(
          "Exemption could not be approved, please try after some time",
          {
            position: "bottom-left",
          }
        );
      } else {
        toast.error(
          "Exemption could not be rejected, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  const renderUI = (dataList) => {
    return dataList.map((attendance) => {
      return (
        <div>
          <div
            key={attendance.id}
            className={styles.container}
            onClick={() =>
              navigate(
                `/inbox/attendance/detail/` +
                  attendance.type +
                  "/" +
                  attendance.id,
                { replace: true }
              )
            }
          >
            <div className={styles.userContainer}>
              <p className={styles.userName}>{attendance.employee_name}</p>
              <p className={styles.date}>{attendance.attendance_date}</p>
            </div>

            <p className={styles.dateWithDay}>{attendance?.comment}</p>
            <p className={styles.leaveType}></p>

            <div className={styles.actionsBox}>
              <button
                className={styles.approveBtn}
                onClick={(e) => {
                  rejectOrApproveLeave(attendance.id, false, attendance?.type);
                  e.stopPropagation();
                }}
              >
                Approve
              </button>
              <button
                className={styles.rejectBtn}
                onClick={(e) => {
                  rejectOrApproveLeave(attendance.id, true, attendance?.type);
                  e.stopPropagation();
                }}
              >
                Reject
              </button>
            </div>
          </div>
          <hr />
        </div>
      );
    });
  };

  const renderUIDesktop = (dataList) => {
    return dataList?.map((attendance, index) => (
      <div
        key={attendance.id}
        className={styles.container}
        onClick={() =>
          navigate(
            `/inbox/attendance/detail/` +
              attendance.type +
              "/" +
              attendance.id +
              "/",
            { replace: true }
          )
        }
      >
        <div className={styles.userContainer}>
          <div className={styles.userBox}>
            <img
              loading="lazy"
              src={
                attendance.profile_image
                  ? attendance.profile_image
                  : `https://ui-avatars.com/api/?name=${attendance.employee_name.charAt(
                      0
                    )}`
              }
              alt={attendance.employee_name}
              className={styles.userImg}
            />
            <div className={styles.userDetailBox}>
              <p className={styles.userName}>{attendance.employee_name}</p>
              {/* <div className={styles.dateWithDay}>
                            {attendance.check_in_time} - {attendance.check_out_time}
                        </div> */}
              <p className={styles.comment}>{attendance?.comment}</p>
            </div>
          </div>
          <p className={styles.date}>{attendance.attendance_date}</p>
        </div>
        {/* <div className={styles.leaveType}>{attendance.work_duration}</div> */}
        <div className={styles.actionsBox}>
          <button
            className={styles.approveBtn}
            onClick={(e) => {
              rejectOrApproveLeave(attendance.id, true, attendance?.type);
              e.stopPropagation();
            }}
          >
            Approve
          </button>
          <button
            className={styles.rejectBtn}
            onClick={(e) => {
              rejectOrApproveLeave(attendance.id, false, attendance?.type);
              e.stopPropagation();
            }}
          >
            Reject
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div>
      {width < 768 ? (
        // mobile view
        <div style={{ width: "100%" }}>
          <InfiniteScrollComponent
            apiEndpoint="attendance/exemption/list"
            renderUI={renderUI}
            initialPage={1}
          />
          {}
        </div>
      ) : (
        // desktop view
        <>
          <div>
            <div className={dashboardStyles.dashboardInnerContainer}>
              <div style={{ width: "100%" }}>
                <div className={styles.leaveDetailContainer}>
                  <InfiniteScrollComponent
                    apiEndpoint="attendance/exemption/list"
                    renderUI={renderUIDesktop}
                    initialPage={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AttendanceList;
