import React, {useState} from 'react';
import Location from '../Location/Location';
import Setting from '../Setting/Setting';
import Summary from '../Summary/Summary';
import {Tabs} from 'antd';
import Layout from '../../../../component/ContainerLayout/ContainerLayout';
function TrackingTabs() {
  const items = [
    {
      key: '1',
      label: 'Meeting Summary',
      children: (
        <Layout>
          <Summary />
        </Layout>
      ),
    },
    {
      key: '2',
      label: 'Location',
      children: (
        <Layout>
          <Location />
        </Layout>
      ),
    },
    {
      key: '3',
      label: 'Setting',
      children: (
        <Layout>
          <Setting />
        </Layout>
      ),
    },

    // {
    //   key: '4',
    //   label: 'Team Leave Requests',
    //   children: <TeamLeaveRequests />,
    // }
  ];

  const [tabsValue, setTabsValue] = useState('1');

  const changeTabsValue = e => {
    setTabsValue(e);
  };

  return (
    <div>
      <Tabs activeKey={tabsValue} items={items} onChange={changeTabsValue} />
    </div>
  );
}

export default TrackingTabs;
