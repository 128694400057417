import React, {useState, useEffect, useRef} from 'react';
import Meeting from './Meeting';
import Setting from '../Setting/Setting';
import back from '../../../../assets/Recruitment/back.svg';
import getScreenSize from '../../../../utils/getScreenSize';

const TeamMeeting = ({changeTabsValue, tabsValue}) => {
  const screenSize = getScreenSize();
  const ref = useRef();
  useEffect(() => {
    ref?.current?.scrollIntoView({behavior: 'instant'});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      {screenSize.width < 768 ? (
        <>
          <Meeting changeTabsValue={changeTabsValue} tabsValue={tabsValue} />
        </>
      ) : (
        <>
          <div
            style={{
              background: screenSize.width < 768 ? '' : 'white',
              padding: screenSize.width < 768 ? '' : '30px 30px',
            }}>
            <Meeting changeTabsValue={changeTabsValue} tabsValue={tabsValue} />
          </div>
        </>
      )}
    </>
  );
};

export default TeamMeeting;
