const industryList = [
    {
        label: "Agriculture and Allied Industries",
        value: 1
    },
    {
        label: "Auto Components",
        value: 2
    },
    {
        label: "Automobiles",
        value: 3
    },
    {
        label: "Aviation",
        value: 4
    },
    {
        label: "Ayush",
        value: 5
    },
    {
        label: "Banking",
        value: 6
    },
    {
        label: "Biotechnology",
        value: 7
    },
    {
        label: "Cement",
        value: 8
    },
    {
        label: "Chemicals",
        value: 9
    },
    {
        label: "Consumer Durables",
        value: 10
    },
    {
        label: "Defence Manufacturing",
        value: 11
    },
    {
        label: "E-Commerce",
        value: 12
    },
    {
        label: "Education and Training",
        value: 13
    },
    {
        label: "Electric Vehicle",
        value: 14
    },
    {
        label: "Electronics System Design Manufacturing",
        value: 15
    },
    {
        label: "Engineering and Capital Goods",
        value: 16
    },
    {
        label: "Financial Services",
        value: 17
    },
    {
        label: "FMCG",
        value: 18
    },
    {
        label: "Food Processing",
        value: 19
    },
    {
        label: "Gems and Jewellery",
        value: 20
    },
    {
        label: "Healthcare",
        value: 21
    },
    {
        label: "Infrastructure",
        value: 22
    },
    {
        label: "Insurance",
        value: 23
    },
    {
        label: "IT BPM",
        value: 24
    },
    {
        label: "Manufacturing",
        value: 25
    },
    {
        label: "Media and Entertainment",
        value: 26
    },
    {
        label: "Medical Devices",
        value: 27
    },
    {
        label: "Metals and Mining",
        value: 28
    },
    {
        label: "MSME",
        value: 29
    },
    {
        label: "Oil and Gas",
        value: 30
    },
    {
        label: "Pharmaceuticals",
        value: 31
    },
    {
        label: "Ports",
        value: 32
    },
    {
        label: "Power",
        value: 33
    },
    {
        label: "Railways",
        value: 34
    },
    {
        label: "Real Estate",
        value: 35
    },
    {
        label: "Renewable Energy",
        value: 36
    },
    {
        label: "Retail",
        value: 37
    },
    {
        label: "Roads",
        value: 38
    },
    {
        label: "Science and Technology",
        value: 39
    },
    {
        label: "Services",
        value: 40
    },
    {
        label: "Steel",
        value: 41
    },
    {
        label: "Telecommunications",
        value: 42
    },
    {
        label: "Textiles",
        value: 43
    },
    {
        label: "Tourism and Hospitality",
        value: 44
    }
]

export default industryList