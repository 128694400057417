import { Checkbox, Col, Form, Input, Row, Switch, Table, Spin, Radio, Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import request from "../../../../utils/axios";
import videoIcon from "../../../../assets/lms/videoIcon.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getScreenSize from "../../../../utils/getScreenSize";
import ContainerLayout from "../../../../component/ContainerLayout/ContainerLayout";
import { LoadingOutlined } from "@ant-design/icons";
import documentLMS from "../../../../assets/DocumentLMS.svg";

function TrainingForm() {
  const { TextArea } = Input;
  const navigate = useNavigate()
  const screenSize = getScreenSize();
  const [loading, setLoading] = useState(false);
  const [submitForm] = Form.useForm()
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const [trainingFormData, setTrainingFormData] = useState({
    title: "",
    video: null,
    quiz: [],
    document: null,
  });

  const [quizFinalData, setQuizFinalData] = useState({
    completion_time: 0,
    min_correct_responses: 0
  })


  const selectiveColumns = [
    {
      title: "ID",
      dataIndex: "employement_number",
      key: "employement_number"
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
    }
  ]

  const [quizStatus, setQuizStatus] = useState(false);

  const [specificStatus, setSpecificStatus] = useState(false);

  const specificSwitch = (value) => {
    console.log("specificStatus............................", value);
    setSpecificStatus(value);

  }

  const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
  const rowSelection = {
    selectedSelectiveRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys, selectedRows...", selectedRowKeys, selectedRows);
      setSelectedSelectiveRowKeys(selectedRowKeys)
    }
  };

  const [selectiveEmployeeList, setSelectiveEmployeeList] = useState([]);

  const [selectedType, setSelectedType] = useState("video");


  const quizSwitch = (value) => {
    setQuizStatus(value);
    setTrainingFormData({
      ...trainingFormData,
      quiz: [],
    })
  };

  const fetchEmployeesList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `lms/admin/employees/all`,
      })
      setSelectiveEmployeeList(data.data)

    }
    catch (error) {
      console.log("error", error)
    }
  }

  const onHandleQuizChange = (value, name, index) => {
    const updatedQuiz = update(trainingFormData, {
      quiz: {
        [index]: {
          [name]: { $set: value },
        },
      },
    });
    setTrainingFormData(updatedQuiz);
  };

  const onHandleOptionsChange = (value, name, index) => {
    const updatedQuiz = update(trainingFormData, {
      quiz: {
        [index]: {
          options: {
            [name]: { $set: value },
          },
        },
      },
    });
    setTrainingFormData(updatedQuiz);
  };

  useEffect(() => {
    console.log("trainingFormData", trainingFormData);
  }, [trainingFormData]);

  useEffect(() => {
    fetchEmployeesList();
    // fetchChangeByDropdownList();
  }, [])

  const addQuestion = () => {
    setTrainingFormData({
      ...trainingFormData,
      quiz: [
        ...trainingFormData.quiz,
        {
          question: "",
          options: {
            option1: "",
            option2: "",
            option3: "",
            option4: "",
          },
        },
      ],
    });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", trainingFormData.title);
      formData.append("video", trainingFormData.video);
      formData.append("document", trainingFormData.document);
      formData.append("quiz", JSON.stringify(trainingFormData.quiz));
      formData.append("is_quiz_available", quizStatus);
      if (specificStatus) {
        formData.append("is_for_specific_employees", 1);
        formData.append("applicable_employees", selectedSelectiveRowKeys);
      }
      else {
        formData.append("is_for_specific_employees", 2);
      }
      if (selectedType === "document") {
        formData.append('is_doc_type', true)
      }
      if (quizStatus) {
        formData.append('completion_time', quizFinalData?.completion_time)
        formData.append('min_correct_responses', quizFinalData?.min_correct_responses)
      }
      const response = await request({
        method: "post",
        url: `/lms/admin/training-videos/add`,
        data: formData,
      });
      navigate("/admin/lms/list", { replace: true });
      toast.success("Training video added successfully", {
        position: "bottom-left",
      });
    } catch (error) {
      toast.error(error.response?.data?.message, {
        position: "bottom-left",
      })
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };


  return (
    <div>
      {
        screenSize.width < 768
          ?
          <div>
            <div style={{ padding: "0px 16px" }} >
              <p style={{ fontSize: "24px", fontWeight: "500", marginTop: "80px" }}>
                Add Training video
              </p>
              <div>
                <Form
                  name="validateOnly"
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Training Title"
                        name="Training Title"
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder="Enter Training Title"
                          size="large"
                          onChange={(e) =>
                            setTrainingFormData({
                              ...trainingFormData,
                              title: e.target.value,
                            })
                          }
                          value={trainingFormData.title}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Upload Video"
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: 'Please Upload Video',
                      },
                    ]}
                  >
                    <div
                      className="file-upload-container"
                      style={{ height: "150px" }}
                    >
                      <input
                        type="file"
                        id="file-upload"
                        className="input-file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const maxSizeInBytes = 5 * 1024 * 1024;
                          {
                            const selectedFile = e.target.files[0];
                            if (selectedFile) {
                              setTrainingFormData({
                                ...trainingFormData,
                                video: selectedFile,
                              });
                            }
                          }
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label
                          htmlFor="file-upload"
                          className="file-upload-label"
                          style={{ height: "150px" }}
                        >
                          <div
                            style={{
                              zIndex: 1,
                              margin: "0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "150px",
                            }}
                          >
                            <div>
                              <center>
                                {
                                  trainingFormData?.video
                                    ?
                                    <>
                                      <p>{trainingFormData?.video?.name}</p>
                                    </>
                                    :
                                    <>
                                      <img
                                        src={videoIcon}
                                        alt={videoIcon}
                                        width={34}
                                        height={34}
                                      />
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          margin: "0px",
                                        }}
                                      >
                                        Select a video or drag and drop here
                                      </p>
                                    </>
                                }

                              </center>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Form.Item>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <span style={{ fontSize: "24px", fontWeight: "600" }}>Quiz</span>
                    <Switch onChange={quizSwitch} />
                  </div>

                  {quizStatus && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ fontSize: "24px", fontWeight: "600" }}>
                          Quiz Question
                        </p>

                        <button
                          type="button"
                          style={{
                            background: "#008080",
                            color: "white",
                            padding: "7px 15px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => addQuestion()}
                        >
                          Add
                        </button>
                      </div>

                      {trainingFormData?.quiz?.map((item, index) => {
                        return (
                          <Row gutter={[32, 0]}>
                            <Col span={24}>
                              <Form.Item
                                label={`Question ${index + 1}`}
                                rules={[{ required: true }]}
                              >
                                <TextArea
                                  size="large"
                                  rows={3}
                                  onChange={(e) =>
                                    onHandleQuizChange(
                                      e.target.value,
                                      "question",
                                      index
                                    )
                                  }
                                  value={item.question}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 1"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option1",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option1}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 2"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option2",
                                      index
                                    )
                                  }
                                  // value={item.option2}
                                  value={trainingFormData?.quiz[index]?.options?.option2}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 3"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option3",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option3}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 4"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option4",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option4}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item
                                label="Answer"
                                rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Answer"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "correct_option",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.correct_option}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}

                  <Form.Item>
                    <button
                      type="submit"
                      style={{
                        background: "#008080",
                        color: "white",
                        padding: "10px 35px",
                        borderRadius: "5px",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      Upload
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          :
          <ContainerLayout>
            <div>
              <div
                style={{ padding: "30px", background: "white", borderRadius: "10px" }}
              >
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  Add Training
                </p>
                <div>

                  <Form
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                  // onFinish={onFinish}
                  >
                    <p style={{ fontSize: "16px", fontWeight: "600" }}>
                      Document Type
                    </p>
                    <Radio.Group
                      size='large'
                      options={[
                        {
                          label: 'Video',
                          value: 'video',
                        },
                        {
                          label: 'Document',
                          value: 'document',
                        }
                      ]}
                      onChange={(e) => setSelectedType(e.target.value)}
                      value={selectedType}
                      optionType="button"
                    />


                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "15px", marginTop: "15px" }}>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>Specific Employee</span>
                      <Switch onChange={specificSwitch} />
                    </div>

                    {specificStatus && (
                      <div style={{ marginBottom: "30px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* <p style={{ fontSize: "24px", fontWeight: "600" }}>
                            Select Employees
                          </p> */}
                        </div>
                        <div style={{ marginTop: "20px", height: "300px", overflowY: "scroll" }}>
                          <Table
                            // rowKey={(record) => `selective-${record.key}`}
                            rowSelection={rowSelection}
                            columns={selectiveColumns}
                            dataSource={selectiveEmployeeList}
                            pagination={false}
                          />
                        </div>

                      </div>
                    )}
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Training Title"
                          name="Training Title"
                          rules={[{ required: true }]}
                        >
                          <Input
                            placeholder="Enter Training Title"
                            size="large"
                            onChange={(e) =>
                              setTrainingFormData({
                                ...trainingFormData,
                                title: e.target.value,
                              })
                            }
                            value={trainingFormData.title}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        {selectedType === "video" ? (
                          <Form.Item
                            label="Upload Video"
                            name="file"
                            rules={[
                              {
                                required: true,
                                message: 'Please Upload Video',
                              },
                            ]}
                          >
                            <div
                              className="file-upload-container"
                              style={{ height: "150px" }}
                            >
                              <input
                                type="file"
                                id="file-upload"
                                className="input-file"
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  const maxSizeInBytes = 5 * 1024 * 1024;
                                  {
                                    console.log("e.target.files[0]", e.target.files[0]);
                                    const selectedFile = e.target.files[0];
                                    console.log("selectedFile", selectedFile);
                                    if (selectedFile) {
                                      setTrainingFormData({
                                        ...trainingFormData,
                                        video: selectedFile,
                                      });
                                    }
                                  }
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  htmlFor="file-upload"
                                  className="file-upload-label"
                                  style={{ height: "150px" }}
                                >
                                  <div
                                    style={{
                                      zIndex: 1,
                                      margin: "0px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "150px",
                                    }}
                                  >
                                    <div>
                                      <center>
                                        {
                                          trainingFormData?.video
                                            ?
                                            <>
                                              <p>{trainingFormData?.video?.name}</p>
                                            </>
                                            :
                                            <>
                                              <img
                                                src={videoIcon}
                                                alt={videoIcon}
                                                width={50}
                                                height={50}
                                              />
                                              <p
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                  margin: "0px",
                                                }}
                                              >
                                                Select a video or drag and drop here
                                              </p>
                                            </>
                                        }

                                      </center>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </Form.Item>
                        ) :
                          (
                            <Form.Item
                              label="Upload Document"
                              name="file"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Upload Document',
                                },
                              ]}
                            >
                              <div
                                className="file-upload-container"
                                style={{ height: "150px" }}
                              >
                                <input
                                  type="file"
                                  id="file-upload"
                                  className="input-file"
                                  onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    const maxSizeInBytes = 5 * 1024 * 1024;
                                    {
                                      console.log("e.target.files[0]", e.target.files[0]);
                                      const selectedFile = e.target.files[0];
                                      console.log("selectedFile", selectedFile);
                                      if (selectedFile) {
                                        setTrainingFormData({
                                          ...trainingFormData,
                                          document: selectedFile,
                                        });
                                      }
                                    }
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <label
                                    htmlFor="file-upload"
                                    className="file-upload-label"
                                    style={{ height: "150px" }}
                                  >
                                    <div
                                      style={{
                                        zIndex: 1,
                                        margin: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "150px",
                                      }}
                                    >
                                      <div>
                                        <center>
                                          {
                                            trainingFormData?.document
                                              ?
                                              <>
                                                <p>{trainingFormData?.document?.name}</p>
                                              </>
                                              :
                                              <>
                                                <img
                                                  src={documentLMS}
                                                  alt={documentLMS}
                                                  width={50}
                                                  height={50}
                                                />
                                                <p
                                                  style={{
                                                    fontSize: "18px",
                                                    fontWeight: "400",
                                                    margin: "0px",
                                                  }}
                                                >
                                                  Select a document or drag and drop here <br />
                                                  <span style={{ fontSize: "12px", fontWeight: "400" }}>Supported Format: PDF files only</span>
                                                </p>
                                              </>
                                          }

                                        </center>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </Form.Item>
                          )
                        }
                      </Col>
                    </Row>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <span style={{ fontSize: "24px", fontWeight: "500" }}>Quiz</span>
                      <Switch onChange={quizSwitch} />
                    </div>

                    {quizStatus && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                            margin: "10px 0px"
                          }}
                        >
                          <p style={{ fontSize: "24px", fontWeight: "500", margin: "0px" }}>
                            Quiz Question
                          </p>

                          <button
                            type="button"
                            style={{
                              background: "#008080",
                              color: "white",
                              padding: "7px 15px",
                              borderRadius: "5px",
                              border: "none",
                            }}
                            onClick={() => addQuestion()}
                          >
                            Add
                          </button>
                        </div>

                        {trainingFormData?.quiz?.map((item, index) => {
                          return (
                            <Row gutter={[32, 0]}>
                              <Col span={24}>
                                <Form.Item
                                  label={`Question ${index + 1}`}
                                  rules={[{ required: true }]}
                                >
                                  <TextArea
                                    size="large"
                                    rows={3}
                                    onChange={(e) =>
                                      onHandleQuizChange(
                                        e.target.value,
                                        "question",
                                        index
                                      )
                                    }
                                    value={item.question}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    size="large"
                                    placeholder="Option 1"
                                    onChange={(e) =>
                                      onHandleOptionsChange(
                                        e.target.value,
                                        "option1",
                                        index
                                      )
                                    }
                                    value={trainingFormData?.quiz[index]?.options?.option1}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    size="large"
                                    placeholder="Option 2"
                                    onChange={(e) =>
                                      onHandleOptionsChange(
                                        e.target.value,
                                        "option2",
                                        index
                                      )
                                    }
                                    // value={item.option2}
                                    value={trainingFormData?.quiz[index]?.options?.option2}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    size="large"
                                    placeholder="Option 3"
                                    onChange={(e) =>
                                      onHandleOptionsChange(
                                        e.target.value,
                                        "option3",
                                        index
                                      )
                                    }
                                    value={trainingFormData?.quiz[index]?.options?.option3}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    size="large"
                                    placeholder="Option 4"
                                    onChange={(e) =>
                                      onHandleOptionsChange(
                                        e.target.value,
                                        "option4",
                                        index
                                      )
                                    }
                                    value={trainingFormData?.quiz[index]?.options?.option4}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item
                                  label="Answer"
                                  rules={[{ required: true }]}>
                                  <Input
                                    size="large"
                                    placeholder="Answer"
                                    onChange={(e) =>
                                      onHandleOptionsChange(
                                        e.target.value,
                                        "correct_option",
                                        index
                                      )
                                    }
                                    value={trainingFormData?.quiz[index]?.options?.correct_option}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    )}

                    <Form.Item>
                      {quizStatus ? (
                        <button
                          // type="submit"
                          style={{
                            background: "#008080",
                            color: "white",
                            padding: "10px 35px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => setOpenSubmitModal(true)}
                        > Continue
                        </button>
                      ) : (

                        <button
                          type="submit"
                          style={{
                            background: "#008080",
                            color: "white",
                            padding: "10px 35px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => onFinish()}
                        >
                          {loading ? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} /> : "Upload"}
                        </button>
                      )}
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </ContainerLayout>
      }
      <Modal
        centered
        open={openSubmitModal}
        footer={null}
        onCancel={() => setOpenSubmitModal(false)}
      >
        <p
          className="forgotPasswordText"
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '20px',
            color: '#333',
          }}
        >
          Quiz Passing Score
        </p>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={submitForm}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="No of Question"
                // name="total_question"
                style={{ marginBottom: '16px' }}
              >
                <Input
                  type="text"
                  size="large"
                  disabled
                  value={trainingFormData.quiz.length}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Passing Score"
                name="min_correct_responses"
                rules={[
                  {
                    required: true,
                    message: 'Please Minimum Score to Pass!',
                  },
                ]}
                style={{ marginBottom: '16px' }}
              >
                <Input
                  type="number"
                  size="large"
                  onChange={(e) =>
                    setQuizFinalData({
                      ...quizFinalData,
                      min_correct_responses: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Quiz Completion Time"
                name="completion_time"
                rules={[
                  {
                    required: true,
                    message: 'Please Minimum Score to Pass!',
                  },
                ]}
                style={{ marginBottom: '16px' }}
              >
                <input
                  type="number"
                  placeholder="In Mins"
                  onChange={(e) =>
                    setQuizFinalData({
                      ...quizFinalData,
                      completion_time: e.target.value,
                    })
                  }
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    transition: 'border-color 0.3s',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="submit"
            style={{
              width: '100%',
              padding: '6px',
              fontSize: '16px',
              fontWeight: 'bold',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#008080',
              color: '#fff',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              height: '40px',
              marginTop: '20px',
            }}
          >
            {loading ? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} /> : "Done"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default TrainingForm;
