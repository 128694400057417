import { Tabs } from 'antd'
import React, { useState } from 'react'
import OfficeLocation from './OfficeLocation/OfficeLocation';
import CompanyLogo from './CompanyLogo/CompanyLogo';
import getScreenSize from "../../../../utils/getScreenSize";
import DeparttmentList from '../DepartmentList/DepartmentList';
import DesignationList from '../DesignationList/DesignationList';
import EmployeeFunction from '../EmployeeFunction/EmployeeFunction';
import GradeList from '../GradeList/GradeList';
import WorkLocation from '../WorkLocation/WorkLocationList';

function General() {
    const items = [
        {
            key: '1',
            label: 'Office Location',
            children: <OfficeLocation />,
        },
        {
            key: '2',
            label: 'Company Logo',
            children: <CompanyLogo />,
        },
        {
            key: '3',
            label: 'Department',
            children: <DeparttmentList />,
        },
        {
            key: '4',
            label: 'Designation',
            children: <DesignationList />
        },
        {
            key: '5',
            label: 'Employee Function',
            children: <EmployeeFunction />,
        },
        {
            key: '6',
            label: 'Grade',
            children: <GradeList />,
        },
        {
            key: '7',
            label: 'Work Location',
            children: <WorkLocation />,
        },
    ];

    const screenSize = getScreenSize();
    const changeTabsValue = (e) => {
        setTabsValue(e)
    }

    const [tabsValue, setTabsValue] = useState("1")

    return (
        <div>
            {
                screenSize.width < 768 ?  // mobile view
                    <div style={{ background: "white" }}>
                        <div style={{ padding: "0px 16px", display: "flex", alignItems: "center", flexWrap: "nowrap", overflow: "auto" }}>
                            {
                                items.map((item) => {
                                    return (
                                        <div key={item.key} style={{
                                            padding: "5px 10px",
                                            cursor: "pointer",
                                            border: item.key === tabsValue ? "1px solid #008080" : "1px solid #C3C3C3",
                                            width: "fit-content",
                                            borderRadius: "21px",
                                            fontSize: "14px",
                                            color: item.key === tabsValue ? "#008080" : "#C3C3C3",
                                            float: "left",
                                            marginRight: "8px",
                                            marginBottom: "8px",
                                            fontSize: "14px",
                                            whiteSpace: "nowrap",
                                        }}
                                            onClick={() => setTabsValue(item.key)}
                                        >
                                            {item.label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                items[tabsValue - 1].children
                            }
                        </div>
                    </div>
                    :
                    <div>
                        <div style={{ padding: "20px", background: "white" }}>
                            <Tabs
                                activeKey={tabsValue}
                                items={items}
                                onChange={changeTabsValue}
                            />
                        </div>
                    </div>
            }
        </div>

    )
}

export default General