import { Button, Col, Form, Input, Modal, Radio, Row, Select, Space, Switch, TimePicker, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import "./PolicyForm.scss"
import update from 'immutability-helper';
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs';
import back from "../../../../assets/Recruitment/back.svg"
import { useGeolocated } from "react-geolocated";
import { toast } from 'react-toastify';
import newLocation from "../../../../assets/Attendance/Admin/newLocation.svg"
import mapLocation from "../../../../assets/location.svg"
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import getScreenSize from "../../../../utils/getScreenSize";

function PolicyForm() {
    const params = useParams();
    const navigate = useNavigate();
    const [locationForm] = Form.useForm();
    const numberOfShift = [1, 2, 3]
    const ref = useRef(null);
    const screenSize = getScreenSize();
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]
    const [name, setName] = useState("")
    const [location, setLocation] = useState("")
    const [attendanceType, setAttendanceType] = useState("")
    const [shift, setShift] = useState([])
    const [selectedNumberOfShift, setSelectedNumberOfShift] = useState(1);
    const [weeklyOffTemplate, setWeeklyOffTemplate] = useState("")
    const [shiftTimeTemplate, setShiftTimeTemplate] = useState("")
    const [standardWeeklyOff, setStandardWeeklyOff] = useState({
        total_leaves: "",
        week_off: [0, 0, 0, 0, 0, 0, 0]
    })
    const [weeklyOff, setWeeklyOff] = useState([
        {
            week_number: 1,
            total_leaves: "",
            week_off: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            week_number: 2,
            total_leaves: "",
            week_off: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            week_number: 3,
            total_leaves: "",
            week_off: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            week_number: 4,
            total_leaves: "",
            week_off: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            week_number: 5,
            total_leaves: "",
            week_off: [0, 0, 0, 0, 0, 0, 0]
        }
    ]);

    const [hybridDay, setHybridDay] = useState([
        0, 0, 0, 0, 0, 0, 0
    ])

    const [customShiftTime, setCustomShiftTime] = useState([
        {
            week_number: 1,
            shift_number: "",
            timings: []
        },
        {
            week_number: 2,
            shift_number: "",
            timings: []
        },
        {
            week_number: 3,
            shift_number: "",
            timings: []
        },
        {
            week_number: 4,
            shift_number: "",
            timings: []
        },
        {
            week_number: 5,
            shift_number: "",
            timings: []
        }
    ])

    const [attendance_type_home, setAttendance_type_home] = useState(null)

    useEffect(() => {
        console.log("attendance_type_home..", attendance_type_home);
    }, [attendance_type_home])
    const standardLeaveDay = [1, 1.5, 2]

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const [address, setAddress] = useState('');

    const [showGeoLocationModel, setShowGeoLocationModel] = useState(false)

    const [mapInitialized, setMapInitialized] = useState(false)
    const [error, setError] = useState(false);
    const [locationName, setLocationName] = useState('')
    const locationCondinateAddress = async () => {
        try {
            const data = await request({
                method: "get",
                url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`,
            })
            setAddress(data.display_name)
        }
        catch (error) {
            console.log("error", error);
        }
    }



    useEffect(() => {
        // Check if Geolocation is supported by the browser
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser');
            return;
        }

        // Fetch the user's current position
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            },
            (error) => {
                console.log("error", error);
                //   setError(`Error getting location: ${error.message}`);
            }
        );
    }, [showGeoLocationModel]);

    useEffect(() => {
        if (showGeoLocationModel && latitude && longitude) {
            if (!mapInitialized) {
                // Create a map centered at the specified latitude and longitude
                const map = Leaflet.map('map').setView([latitude, longitude], 13);

                // Add a tile layer (you can choose different providers, like OpenStreetMap)
                Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '© OpenStreetMap contributors',
                }).addTo(map);

                // Create a custom icon
                const locationIcon = Leaflet.icon({
                    iconUrl: mapLocation,
                    iconSize: [38, 95],
                    iconAnchor: [22, 94],
                    popupAnchor: [-3, -76],
                });

                // Add a marker at the specified latitude and longitude
                Leaflet.marker([latitude, longitude], { icon: locationIcon }).addTo(map);
                setMapInitialized(true);
                locationCondinateAddress();
            }
        }
    }, [showGeoLocationModel, latitude, longitude]);

    const renderStandardWeeklyOff = () => {
        return (
            <>
                {
                    standardLeaveDay.map((item, dayIndex) => {
                        return (
                            <div className='renderStandardWeeklyOffContainer' key={dayIndex}>
                                <Radio
                                    value={item}
                                    onClick={() => setStandardWeeklyOff({ ...standardWeeklyOff, total_leaves: item, week_off: [0, 0, 0, 0, 0, 0, 0] })}
                                >
                                    <p className='standardLeaveDayText'>{item} weekly off</p>
                                </Radio>
                                <div className='renderStandardWeeklyDaysContainer'>
                                    {
                                        days.map((day, index) => {
                                            const selected = standardWeeklyOff.total_leaves === item && (standardWeeklyOff.week_off[index] === 1 || standardWeeklyOff.week_off[index] === 0.5);

                                            return (
                                                <div>
                                                    <div
                                                        className={selected ? standardWeeklyOff.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                                        onClick={() => {
                                                            let sum = standardWeeklyOff.week_off.reduce((accumulator, currentValue) => {
                                                                return accumulator + currentValue
                                                            }, 0);

                                                            if (standardWeeklyOff.total_leaves === item) {
                                                                const isDaySelected = standardWeeklyOff.total_leaves === item && (standardWeeklyOff.week_off[index] === 1 || standardWeeklyOff.week_off[index] === 0.5);


                                                                if (sum < standardWeeklyOff.total_leaves) {

                                                                    if (standardWeeklyOff.total_leaves - sum === standardWeeklyOff.total_leaves || standardWeeklyOff.total_leaves - sum === 1) {
                                                                        const updatedWeeklyOff = update(standardWeeklyOff, {
                                                                            week_off: {
                                                                                [index]: {
                                                                                    $set: 1
                                                                                }
                                                                            }
                                                                        });
                                                                        setStandardWeeklyOff(updatedWeeklyOff)
                                                                    }

                                                                    // half day
                                                                    else if (standardWeeklyOff.total_leaves - sum === 0.5) {
                                                                        const updatedWeeklyOff = update(standardWeeklyOff, {
                                                                            week_off: {
                                                                                [index]: {
                                                                                    $set: 0.5
                                                                                }
                                                                            }
                                                                        });
                                                                        setStandardWeeklyOff(updatedWeeklyOff);
                                                                    }
                                                                }


                                                                if (isDaySelected) {
                                                                    const updatedWeeklyOff = update(standardWeeklyOff, {
                                                                        week_off: {
                                                                            [index]: {
                                                                                $set: 0
                                                                            }
                                                                        }
                                                                    });
                                                                    setStandardWeeklyOff(updatedWeeklyOff);
                                                                }
                                                            }
                                                        }
                                                        }
                                                    >
                                                        {day.slice(0, 2)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    error && weeklyOffTemplate === "standard" && standardWeeklyOff.total_leaves === "" && (
                        <div style={{ color: "red" }}>Please select a valid number of weekly offs.</div>
                    )
                }

                {
                    error && weeklyOffTemplate === "standard" && standardWeeklyOff?.week_off?.reduce((a, b) => a + b, 0) === 0 && (
                        <div style={{ color: "red" }}>Please select a day.</div>
                    )
                }
            </>
        )
    }

    const renderCustomWeeklyOff = () => {
        return (
            <>
                <div className='flexSpaceBetween renderCustomWeeklyOffHeader'>
                    <p className='weekNumberText'>Select no. of leaves</p>
                    <p className='selectNoOfDaysText'>Select days</p>
                </div>
                {weeklyOff?.map((week_number, weekIndex) => (
                    <div key={weekIndex} >

                        <div className='renderCustomWeeklyOffContainer'>

                            <div className='selectNoOfDaysContainer'>
                                <div className='selectNoOfDaysInnerContainer'>
                                    <p className='weekNumberText'>Week {weekIndex + 1}</p>
                                    <p className='selectNoOfDaysText'>Select no. of days</p>
                                    <div style={{ display: "flex", gap: 20 }}>

                                        {[1, 1.5, 2, 3].map((day, index) => (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    const updatedWeeklyOff = update(weeklyOff, {
                                                        [weekIndex]: {
                                                            total_leaves: { $set: day },
                                                            week_off: { $set: [0, 0, 0, 0, 0, 0, 0] },
                                                        }
                                                    });
                                                    setWeeklyOff(updatedWeeklyOff);
                                                }}
                                                className={
                                                    weeklyOff[weekIndex].total_leaves === day ? "selectedFullDay" : "disabledFullDay"
                                                }
                                            >
                                                {day}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='selectWeeksContainer'>
                                <p className='selectWeeksText'>Select weeks</p>
                                <div style={{ display: "flex", gap: 20 }}>
                                    {days.map((day, index) => {
                                        const isDaySelected = weeklyOff[weekIndex].week_off[index] === 1 || weeklyOff[weekIndex].week_off[index] === 0.5;

                                        return (

                                            <div
                                                key={index}
                                                onClick={() => {
                                                    let sum = weeklyOff[weekIndex].week_off.reduce((accumulator, currentValue) => {
                                                        return accumulator + currentValue
                                                    }, 0);
                                                    if (sum < weeklyOff[weekIndex].total_leaves) {
                                                        // nothing is select yet

                                                        // full day
                                                        if (weeklyOff[weekIndex].total_leaves - sum === weeklyOff[weekIndex].total_leaves || weeklyOff[weekIndex].total_leaves - sum === 1 || weeklyOff[weekIndex].total_leaves - sum === 2) {
                                                            const updatedWeeklyOff = update(weeklyOff, {
                                                                [weekIndex]: {
                                                                    week_off: {
                                                                        [index]: {
                                                                            $set: 1
                                                                        }
                                                                    }
                                                                }
                                                            });
                                                            setWeeklyOff(updatedWeeklyOff);
                                                        }
                                                        // half day
                                                        else if (weeklyOff[weekIndex].total_leaves - sum === 0.5) {
                                                            const updatedWeeklyOff = update(weeklyOff, {
                                                                [weekIndex]: {
                                                                    week_off: {
                                                                        [index]: {
                                                                            $set: 0.5
                                                                        }
                                                                    }
                                                                }
                                                            });
                                                            setWeeklyOff(updatedWeeklyOff);
                                                        }


                                                    }
                                                    if (isDaySelected) {
                                                        const updatedWeeklyOff = update(weeklyOff, {
                                                            [weekIndex]: {
                                                                week_off: {
                                                                    [index]: {
                                                                        $set: 0
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        setWeeklyOff(updatedWeeklyOff);
                                                    }

                                                }}
                                                className={isDaySelected ? weeklyOff[weekIndex].week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                            >
                                                {day.slice(0, 2)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="" style={{ padding: "5px 25px " }}>
                            {error && weeklyOffTemplate === "custom" && weeklyOff[weekIndex].total_leaves === "" && (
                                <div style={{ color: "red" }}>Please select a valid number of weekly offs.</div>
                            )}
                            {
                                error && weeklyOff[weekIndex].week_off.reduce((a, b) => a + b, 0) === 0 && (
                                    <div style={{ color: "red" }}>Please select a day.</div>
                                )
                            }
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const [bufferDetails, setBufferDetails] = useState({
        buffer_time_before_check_in: null,
        buffer_time_after_check_in: null,
        buffer_time_before_check_out: null,
        buffer_time_after_check_out: null
    })

    const convertInMinute = (timeString) => {
        if (timeString !== null) {
            const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
            return hours * 60 + minutes;
        } else {
            return null; // or any other appropriate value for null timeString
        }
    }
    const onFinish = async (values) => {
        try {
            if (params.id) {
                let sendData;
                if (weeklyOffTemplate === "standard" && shiftTimeTemplate === "standard") {
                    sendData = {
                        shifts: shift,
                        standard_entry: standardWeeklyOff,
                    }
                }
                else if (weeklyOffTemplate === "custom" && shiftTimeTemplate === "custom") {
                    sendData = {
                        shifts: shift,
                        custom_shifts: customShiftTime,
                        week_off_entries: weeklyOff,
                    }
                }
                else if (weeklyOffTemplate === "standard" && shiftTimeTemplate === "custom") {
                    sendData = {
                        shifts: shift,
                        standard_entry: standardWeeklyOff,
                        custom_shifts: customShiftTime,
                    }
                }
                else {
                    sendData = {
                        shifts: shift,
                        week_off_entries: weeklyOff,
                    }
                }
                if (params.action) {
                    const data = await request({
                        method: "post",
                        url: `attendance/admin/attendance-policy/clone`,
                        data: {
                            ...sendData,
                            name: name,
                            location: location,
                            // attendance_type: attendanceType,
                            // shift: shift,
                            week_off_entries: weeklyOff,
                            standard_entry: standardWeeklyOff,
                            is_week_off_standard: weeklyOffTemplate === "standard" ? true : false,

                            // add new exemption
                            exemption_type: values.exemption_type ? values.exemption_type : null,
                            max_no_of_exemption_allowed: values.max_no_of_exemption_allowed ? values.max_no_of_exemption_allowed : null,
                            leave_type_deduction_after_exemption: values.leave_type_deduction_after_exemption ? values.leave_type_deduction_after_exemption : null,
                            no_of_leaves_deducted_after_exemption: values.no_of_leaves_deducted_after_exemption ? values.no_of_leaves_deducted_after_exemption : null,

                            // add new late
                            late_check_in_type: values.late_check_in_type ? values.late_check_in_type : null,
                            late_check_in_allowed: values.late_check_in_allowed ? values.late_check_in_allowed : null,
                            leave_type_deduction_late_check_in: values.leave_type_deduction_late_check_in ? values.leave_type_deduction_late_check_in : null,
                            no_of_leaves_deducted_after_late_check_in: values.no_of_leaves_deducted_after_late_check_in ? values.no_of_leaves_deducted_after_late_check_in : null,


                            // max_no_of_exemption_allowed: values.max_no_of_exemption_allowed ? values.max_no_of_exemption_allowed : null,
                            is_max_no_of_exemption_allowed_in_year: values.is_max_no_of_exemption_allowed_in_year,
                            // leave_type_deduction_after_exemption: values.leave_type_deduction_after_exemption ? values.leave_type_deduction_after_exemption : null,
                            // no_of_leaves_deducted_after_exemption: values.no_of_leaves_deducted_after_exemption ? values.no_of_leaves_deducted_after_exemption : null,
                            late_check_in_allowed: values.late_check_in_allowed ? values.late_check_in_allowed : null,
                            late_check_in_allowed_in_year: values.late_check_in_allowed_in_year,
                            buffer_time_before_check_in: convertInMinute(bufferDetails?.buffer_time_before_check_in),
                            buffer_time_after_check_in: convertInMinute(bufferDetails?.buffer_time_after_check_in),
                            buffer_time_before_check_out: convertInMinute(bufferDetails?.buffer_time_before_check_out),
                            buffer_time_after_check_out: convertInMinute(bufferDetails?.buffer_time_after_check_out),
                            longitude: longitude,
                            latitude: latitude,
                            address: locationName,
                            hybrid_working_days: hybridDay,
                            policy_location: values.policy_location ? values.policy_location : null,
                            working_policy: workingPolicy,
                            attendance_type_office: attendance_type_office,
                            attendance_type_home: attendance_type_home,
                            is_standard_shift: shiftTimeTemplate === "standard" ? true : false,
                        }
                    })
                    toast.success("Attendance Policy cloned", {
                        position: "bottom-left",
                    });
                    navigate("/admin/attendance/2", { replace: true })
                }
                else {
                    console.log("abc..............");
                    const data = await request({
                        method: "put",
                        url: `attendance/policy/update/?policy_id=${params.id}`,
                        data: {
                            ...sendData,
                            name: name,
                            location: location,
                            // attendance_type: attendanceType,
                            // shift: shift,
                            week_off_entries: weeklyOff,
                            standard_entry: standardWeeklyOff,
                            is_week_off_standard: weeklyOffTemplate === "standard" ? true : false,


                            // add new exemption
                            exemption_type: values.exemption_type ? values.exemption_type : null,
                            max_no_of_exemption_allowed: values.max_no_of_exemption_allowed ? values.max_no_of_exemption_allowed : null,
                            leave_type_deduction_after_exemption: values.leave_type_deduction_after_exemption ? values.leave_type_deduction_after_exemption : null,
                            no_of_leaves_deducted_after_exemption: values.no_of_leaves_deducted_after_exemption ? values.no_of_leaves_deducted_after_exemption : null,

                            // add new late
                            late_check_in_type: values.late_check_in_type ? values.late_check_in_type : null,
                            late_check_in_allowed: values.late_check_in_allowed ? values.late_check_in_allowed : null,
                            leave_type_deduction_late_check_in: values.leave_type_deduction_late_check_in ? values.leave_type_deduction_late_check_in : null,
                            no_of_leaves_deducted_after_late_check_in: values.no_of_leaves_deducted_after_late_check_in ? values.no_of_leaves_deducted_after_late_check_in : null,
                            is_lop_for_short_duration_only: values.is_lop_for_short_duration_only,


                            //add new Short Duration
                            short_duration_type: values.short_duration_type ? values.short_duration_type : null,
                            short_duration_allowed: values.short_duration_allowed ? values.short_duration_allowed : null,
                            leave_type_deduction_short_duration: values.leave_type_deduction_short_duration ? values.leave_type_deduction_short_duration : null,
                            no_of_leaves_deducted_after_short_duration: values.no_of_leaves_deducted_after_short_duration ? values.no_of_leaves_deducted_after_short_duration : null,

                            // max_no_of_exemption_allowed: values.max_no_of_exemption_allowed ? values.max_no_of_exemption_allowed : null,
                            is_max_no_of_exemption_allowed_in_year: values.is_max_no_of_exemption_allowed_in_year,
                            // leave_type_deduction_after_exemption: values.leave_type_deduction_after_exemption ? values.leave_type_deduction_after_exemption : null,
                            // no_of_leaves_deducted_after_exemption: values.no_of_leaves_deducted_after_exemption ? values.no_of_leaves_deducted_after_exemption : null,
                            // late_check_in_allowed: values.late_check_in_allowed ? values.late_check_in_allowed : null,
                            late_check_in_allowed_in_year: values.late_check_in_allowed_in_year,
                            buffer_time_before_check_in: convertInMinute(bufferDetails?.buffer_time_before_check_in),
                            buffer_time_after_check_in: convertInMinute(bufferDetails?.buffer_time_after_check_in),
                            buffer_time_before_check_out: convertInMinute(bufferDetails?.buffer_time_before_check_out),
                            buffer_time_after_check_out: convertInMinute(bufferDetails?.buffer_time_after_check_out),
                            longitude: longitude,
                            latitude: latitude,
                            address: locationName,
                            hybrid_working_days: hybridDay,
                            policy_location: values.policy_location ? values.policy_location : null,
                            working_policy: workingPolicy,
                            attendance_type_office: attendance_type_office,
                            attendance_type_home: attendance_type_home,
                            is_standard_shift: shiftTimeTemplate === "standard" ? true : false,
                        }
                    })
                    toast.success("Attendance Policy updated", {
                        position: "bottom-left",
                    });
                    navigate("/admin/attendance/2", { replace: true })
                }
            }
            else {
                let sendData = {
                    name: name,
                    location: location,
                    working_policy: workingPolicy,
                    attendance_type_office: attendance_type_office,
                    attendance_type_home: attendance_type_home,
                    // max_no_of_exemption_allowed: values.max_no_of_exemption_allowed ? values.max_no_of_exemption_allowed : null,
                    // add new exemption
                    exemption_type: values.exemption_type ? values.exemption_type : null,
                    max_no_of_exemption_allowed: values.max_no_of_exemption_allowed ? values.max_no_of_exemption_allowed : null,
                    leave_type_deduction_after_exemption: values.leave_type_deduction_after_exemption ? values.leave_type_deduction_after_exemption : null,
                    no_of_leaves_deducted_after_exemption: values.no_of_leaves_deducted_after_exemption ? values.no_of_leaves_deducted_after_exemption : null,

                    // add new late
                    late_check_in_type: values.late_check_in_type ? values.late_check_in_type : null,
                    late_check_in_allowed: values.late_check_in_allowed ? values.late_check_in_allowed : null,
                    leave_type_deduction_late_check_in: values.leave_type_deduction_late_check_in ? values.leave_type_deduction_late_check_in : null,
                    no_of_leaves_deducted_after_late_check_in: values.no_of_leaves_deducted_after_late_check_in ? values.no_of_leaves_deducted_after_late_check_in : null,

                    //add new Short Duration
                    short_duration_type: values.short_duration_type ? values.short_duration_type : null,
                    short_duration_allowed: values.short_duration_allowed ? values.short_duration_allowed : null,
                    leave_type_deduction_short_duration: values.leave_type_deduction_short_duration ? values.leave_type_deduction_short_duration : null,
                    no_of_leaves_deducted_after_short_duration: values.no_of_leaves_deducted_after_short_duration ? values.no_of_leaves_deducted_after_short_duration : null,

                    is_max_no_of_exemption_allowed_in_year: values.is_max_no_of_exemption_allowed_in_year,
                    // leave_type_deduction_after_exemption: values.leave_type_deduction_after_exemption ? values.leave_type_deduction_after_exemption : null,
                    no_of_leaves_deducted_after_exemption: values.no_of_leaves_deducted_after_exemption ? values.no_of_leaves_deducted_after_exemption : null,
                    late_check_in_allowed: lateCheckInAllowed.late_check_in_allowed,
                    late_check_in_allowed_in_year: lateCheckInAllowed.late_check_in_allowed_in_year,
                    is_lop_for_short_duration_only: lateCheckInAllowed.is_lop_for_short_duration_only,
                    buffer_time_before_check_in: convertInMinute(bufferDetails?.buffer_time_before_check_in),
                    buffer_time_after_check_in: convertInMinute(bufferDetails?.buffer_time_after_check_in),
                    buffer_time_before_check_out: convertInMinute(bufferDetails?.buffer_time_before_check_out),
                    buffer_time_after_check_out: convertInMinute(bufferDetails?.buffer_time_after_check_out),
                    longitude: longitude,
                    latitude: latitude,
                    address: locationName,
                    policy_location: values.policy_location ? values.policy_location : null,
                    hybrid_working_days: hybridDay,
                    is_week_off_standard: weeklyOffTemplate === "standard" ? true : false,
                    is_standard_shift: shiftTimeTemplate === "standard" ? true : false,
                }
                if (weeklyOffTemplate === "standard" && shiftTimeTemplate === "standard") {

                    const data = await request({
                        method: "post",
                        url: `attendance/policy/create/?week_off_type=${weeklyOffTemplate}&shift_type=${shiftTimeTemplate}`,
                        data: {
                            ...sendData,
                            shift: shift,
                            standard_entry: standardWeeklyOff,
                        }
                    })

                }
                else if (weeklyOffTemplate === "custom" && shiftTimeTemplate === "custom") {

                    const data = await request({
                        method: "post",
                        url: `attendance/policy/create/`,
                        data: {
                            ...sendData,
                            shift: shift,
                            // standard_entry: standardWeeklyOff,
                            custom_shifts: customShiftTime,
                            week_off_entries: weeklyOff,
                        }
                    })

                }
                else if (weeklyOffTemplate === "standard" && shiftTimeTemplate === "custom") {

                    const data = await request({
                        method: "post",
                        url: `attendance/policy/create/?week_off_type=${weeklyOffTemplate}`,
                        data: {
                            ...sendData,
                            shift: shift,
                            standard_entry: standardWeeklyOff,
                            custom_shifts: customShiftTime,
                        }
                    })

                }
                else {
                    const data = await request({
                        method: "post",
                        url: `attendance/policy/create/?shift_type=${shiftTimeTemplate}`,
                        data: {
                            ...sendData,
                            shift: shift,
                            week_off_entries: weeklyOff,
                        }
                    })
                }
                toast.success("New Attendance Policy added", {
                    position: "bottom-left",
                });
                navigate("/admin/attendance/2", { replace: true })
            }

        }
        catch (error) {
            console.log(error)
            if (params.id) {
                toast.error("Attendance Type could not be updated, please try after some time");
            }
            else {
                toast.error("New Attendance Policy could not be added, please try after some time");
            }
        }
    }

    const onFinishFailed = () => {
        setError(true);
    }

    const convertToHoursMins = (time) => {
        const hours = Math.floor(time / 60);
        const mins = time % 60;
        const data = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
        return data;
    }

    const [lateCheckInAllowed, setLateCheckInAllowed] = useState({
        late_check_in_type: null,
        late_check_in_allowed: null,
        leave_type_deduction_late_check_in: null,
        no_of_leaves_deducted_after_late_check_in: null,
        is_lop_for_short_duration_only: true,

    })

    const [shortDurationAllowed, setShortDurationAllowed] = useState({
        short_duration_type: null,
        short_duration_allowed: null,
        leave_type_deduction_short_duration: null,
        no_of_leaves_deducted_after_short_duration: null,
    })

    const [leaveTypeDeduction, setLeaveTypeDeduction] = useState({
        // leave_type_deduction_after_exemption: null,
        // no_of_leaves_deducted_after_exemption: null,
    })
    const [maxNoOfExemptionAllowed, setMaxNoOfExemptionAllowed] = useState({
        exemption_type: null,
        max_no_of_exemption_allowed: null,
        leave_type_deduction_after_exemption: null,
        no_of_leaves_deducted_after_exemption: null
    })


    const [policy_location, setPolicy_location] = useState(null)
    const fetchPolicyData = async () => {
        try {
            const data = await request({
                method: "get",
                url: `attendance/policy/details/?policy_id=${params.id}`,
            })
            // const total_leaves = data.data.week_off_entries[0].total_leaves;
            setName(data?.data?.policy_name)
            setLocation(data?.data?.location)
            setPolicy_location(data?.data?.policy_location)
            setAttendance_type_office(data?.data?.attendance_type_office)
            setWorkingPolicy(data?.data?.working_policy)
            setShiftTimeTemplate(data?.data?.is_standard_shift ? "standard" : "custom")
            setLateCheckInAllowed({
                ...lateCheckInAllowed,
                late_check_in_type: data?.data?.late_check_in_type,
                late_check_in_allowed: data?.data?.late_check_in_allowed,
                leave_type_deduction_late_check_in: data?.data?.leave_type_deduction_late_check_in,
                no_of_leaves_deducted_after_late_check_in: data?.data?.no_of_leaves_deducted_after_late_check_in,
                is_lop_for_short_duration_only: data?.data?.is_lop_for_short_duration_only
                // late_check_in_allowed: data?.data?.late_check_in_allowed,
                // late_check_in_allowed_in_year: data?.data?.late_check_in_allowed_in_year
            })
            setShortDurationAllowed({
                ...shortDurationAllowed,
                short_duration_type: data?.data?.short_duration_type,
                short_duration_allowed: data?.data?.short_duration_allowed,
                leave_type_deduction_short_duration: data?.data?.leave_type_deduction_short_duration,
                no_of_leaves_deducted_after_short_duration: data?.data?.no_of_leaves_deducted_after_short_duration
            })
            setLeaveTypeDeduction({
                ...leaveTypeDeduction,
                // late_check_in_exemption_type: data?.data?.late_check_in_exemption_type,
                // no_of_exemption_allowed_in_late_check_in: data?.data?.no_of_exemption_allowed_in_late_check_in,
                // leave_type_for_late_check_in: data?.data?.leave_type_for_late_check_in,
                // no_of_leaves_deducted_after_exemption_in_late_check_in: data?.data?.no_of_leaves_deducted_after_exemption_in_late_check_in
                // leave_type_deduction_after_exemption: data?.data?.leave_type_deduction_after_exemption,
                // no_of_leaves_deducted_after_exemption: data?.data?.no_of_leaves_deducted_after_exemption
            })
            setAttendance_type_home(data?.data?.attendance_type_home)
            setMaxNoOfExemptionAllowed({
                ...maxNoOfExemptionAllowed,
                exemption_type: data?.data?.exemption_type,
                max_no_of_exemption_allowed: data?.data?.max_no_of_exemption_allowed,
                leave_type_deduction_after_exemption: data?.data?.leave_type_deduction_after_exemption,
                no_of_leaves_deducted_after_exemption: data?.data?.no_of_leaves_deducted_after_exemption
                // max_no_of_exemption_allowed: data?.data?.max_no_of_exemption_allowed,
                // is_max_no_of_exemption_allowed_in_year: data?.data?.is_max_no_of_exemption_allowed_in_year
            })

            if (data?.data?.hybrid_working_days) {
                setHybridDay(data?.data?.hybrid_working_days)
            }

            // Format hours and minutes into HH:mm format

            setBufferDetails({
                ...bufferDetails,
                buffer_time_before_check_in: convertToHoursMins(data.data.buffer_time_before_check_in),
                buffer_time_after_check_in: convertToHoursMins(data.data.buffer_time_after_check_in),
                buffer_time_before_check_out: convertToHoursMins(data.data.buffer_time_before_check_out),
                buffer_time_after_check_out: convertToHoursMins(data.data.buffer_time_after_check_out)
            })
            if (data.data.is_standard_shift) {
                setSelectedNumberOfShift(data.data.shifts[0].timings.length)
                setShift([...data?.data?.shifts[0].timings])
            }
            else {
                setCustomShiftTime(data?.data?.shifts)
            }


            if (data.data.is_week_off_standard) {
                setWeeklyOffTemplate("standard")
                setStandardWeeklyOff({
                    ...standardWeeklyOff,
                    total_leaves: data.data.week_off_entries[0].total_leaves,
                    week_off: data.data.week_off_entries[0].week_off
                })
            }
            else {
                setWeeklyOffTemplate("custom")
                setWeeklyOff(data.data.week_off_entries)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (params.id) {
            fetchPolicyData();
        }
    }, [])

    const handleTimeChange = (index, start_time, end_time) => {
        // Update the shift array with the new time
        const updatedShift = [...shift];
        updatedShift[index] = { start_time, end_time };
        setShift(updatedShift);
    };
    const fields = shift.map((_, index) => ([
        {
            name: `start_time`,
            value: shift[index]?.start_time,
        },
        {
            name: `end_time`,
            value: shift[index]?.end_time,
        }
    ])).flat();

    const handleCustomTimeChange = (index, shiftIndex, start_time, end_time) => {

        const updatedData = update(customShiftTime, {
            [index]: {
                timings: {
                    [shiftIndex]: {
                        $set: { start_time, end_time }
                    }
                }
            }
        })
        setCustomShiftTime(updatedData);
    }

    useEffect(() => {
        console.log("customShiftTime...", customShiftTime)
    }, [customShiftTime])

    const [workingPolicyList, setWorkingPolicyList] = useState([])
    const fetchAllWorkingPolicy = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/attendance/policy/working-policy/all`,
            })
            setWorkingPolicyList(data.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [officeAttendanceTypeList, setOfficeAttendanceTypeList] = useState([])
    const fetchOfficeAttendanceTypeList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/attendance/policy/attendance-type/office/all`,
            })
            setOfficeAttendanceTypeList(data.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [homeAttendanceTypeList, setHomeAttendanceTypeList] = useState([])
    const fetchHomeAttendanceTypeList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/attendance/policy/attendance-type/home/all`,
            })
            setHomeAttendanceTypeList(data.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [workingPolicy, setWorkingPolicy] = useState(null)
    const [attendance_type_office, setAttendance_type_office] = useState(null)

    useEffect(() => {
        console.log("attendance_type_office", attendance_type_office);
    }, [attendance_type_office])

    const [leaveTypeList, setLeaveTypeList] = useState([])
    const fetchLeaveTypeList = async () => {
        try {
            const data = await request({
                method: "get",
                url: "/leave/leave_types/all/"
            })
            setLeaveTypeList(data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [savedLocationList, setSavedLocationList] = useState([])
    const fetchSavedLocation = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/attendance/saved-location/all`,
            })
            setSavedLocationList(data.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        fetchAllWorkingPolicy();
        fetchOfficeAttendanceTypeList();
        fetchHomeAttendanceTypeList();
        fetchLeaveTypeList();
        fetchSavedLocation();
    }, [])

    const addLocation = async (values) => {
        try {
            setLocationName(values.address)
            setShowGeoLocationModel(false)
            locationForm.resetFields();
            await request({
                method: "post",
                url: `/attendance/location/create`,
                data: {
                    address: values.address,
                    longitude: longitude,
                    latitude: latitude
                }
            })

            fetchSavedLocation();
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div className='policyFormContainer' ref={ref}>
            {screenSize.width > 768 && <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>}


            <div className='policyForm'>
                <p className='policyFormHeading'>New Policy Form</p>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    fields={[
                        {
                            name: ["name"],
                            value: name,
                        },
                        {
                            name: ["work_policy"],
                            value: workingPolicy,
                        },
                        {
                            name: ["attendance_type_office"],
                            value: attendance_type_office,
                        },
                        {
                            name: "attendance_type_home",
                            value: attendance_type_home,
                        },
                        {
                            name: ["location"],
                            value: location,
                        },
                        {
                            name: ["policy_location"],
                            value: policy_location,
                        },
                        {
                            name: "start_time0",
                            value: shift[0]?.start_time ? dayjs(shift[0]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "end_time0",
                            value: shift[0]?.end_time ? dayjs(shift[0]?.end_time, "HH:mm") : "",
                        },
                        {
                            name: "start_time1",
                            value: shift[1]?.start_time ? dayjs(shift[1]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "end_time1",
                            value: shift[1]?.end_time ? dayjs(shift[1]?.end_time, "HH:mm") : '',
                        },
                        {
                            name: "start_time2",
                            value: shift[2]?.start_time ? dayjs(shift[2]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "end_time2",
                            value: shift[2]?.end_time ? dayjs(shift[2]?.end_time, "HH:mm") : "",
                        },
                        // week 1
                        {
                            name: "custom_shift0_start_time0",
                            value: customShiftTime[0]?.timings[0]?.start_time ? dayjs(customShiftTime[0]?.timings[0]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift0_end_time0",
                            value: customShiftTime[0]?.timings[0]?.end_time ? dayjs(customShiftTime[0]?.timings[0]?.end_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift0_start_time1",
                            value: customShiftTime[0]?.timings[1]?.start_time ? dayjs(customShiftTime[0]?.timings[1]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift0_end_time1",
                            value: customShiftTime[0]?.timings[1]?.end_time ? dayjs(customShiftTime[0]?.timings[1]?.end_time, "HH:mm") : '',
                        },
                        {
                            name: "custom_shift0_start_time2",
                            value: customShiftTime[0]?.timings[2]?.start_time ? dayjs(customShiftTime[0]?.timings[2]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift0_end_time2",
                            value: customShiftTime[0]?.timings[2]?.end_time ? dayjs(customShiftTime[0]?.timings[2]?.end_time, "HH:mm") : "",
                        },

                        // week 2

                        {
                            name: "custom_shift1_start_time0",
                            value: customShiftTime[1]?.timings[0]?.start_time ? dayjs(customShiftTime[1]?.timings[0]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift1_end_time0",
                            value: customShiftTime[1]?.timings[0]?.end_time ? dayjs(customShiftTime[1]?.timings[0]?.end_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift1_start_time1",
                            value: customShiftTime[1]?.timings[1]?.start_time ? dayjs(customShiftTime[1]?.timings[1]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift1_end_time1",
                            value: customShiftTime[1]?.timings[1]?.end_time ? dayjs(customShiftTime[1]?.timings[1]?.end_time, "HH:mm") : '',
                        },
                        {
                            name: "custom_shift1_start_time2",
                            value: customShiftTime[1]?.timings[2]?.start_time ? dayjs(customShiftTime[1]?.timings[2]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift1_end_time2",
                            value: customShiftTime[1]?.timings[2]?.end_time ? dayjs(customShiftTime[1]?.timings[2]?.end_time, "HH:mm") : "",
                        },

                        // week 3

                        {
                            name: "custom_shift2_start_time0",
                            value: customShiftTime[2]?.timings[0]?.start_time ? dayjs(customShiftTime[2]?.timings[0]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift2_end_time0",
                            value: customShiftTime[2]?.timings[0]?.end_time ? dayjs(customShiftTime[2]?.timings[0]?.end_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift2_start_time1",
                            value: customShiftTime[2]?.timings[1]?.start_time ? dayjs(customShiftTime[2]?.timings[1]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift2_end_time1",
                            value: customShiftTime[2]?.timings[1]?.end_time ? dayjs(customShiftTime[2]?.timings[1]?.end_time, "HH:mm") : '',
                        },
                        {
                            name: "custom_shift2_start_time2",
                            value: customShiftTime[2]?.timings[2]?.start_time ? dayjs(customShiftTime[2]?.timings[2]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift2_end_time2",
                            value: customShiftTime[2]?.timings[2]?.end_time ? dayjs(customShiftTime[2]?.timings[2]?.end_time, "HH:mm") : "",
                        },

                        // week 4

                        {
                            name: "custom_shift3_start_time0",
                            value: customShiftTime[3]?.timings[0]?.start_time ? dayjs(customShiftTime[3]?.timings[0]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift3_end_time0",
                            value: customShiftTime[3]?.timings[0]?.end_time ? dayjs(customShiftTime[3]?.timings[0]?.end_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift3_start_time1",
                            value: customShiftTime[3]?.timings[1]?.start_time ? dayjs(customShiftTime[3]?.timings[1]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift3_end_time1",
                            value: customShiftTime[3]?.timings[1]?.end_time ? dayjs(customShiftTime[3]?.timings[1]?.end_time, "HH:mm") : '',
                        },
                        {
                            name: "custom_shift3_start_time2",
                            value: customShiftTime[3]?.timings[2]?.start_time ? dayjs(customShiftTime[3]?.timings[2]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift3_end_time2",
                            value: customShiftTime[3]?.timings[2]?.end_time ? dayjs(customShiftTime[3]?.timings[2]?.end_time, "HH:mm") : "",
                        },

                        // week 5

                        {
                            name: "custom_shift4_start_time0",
                            value: customShiftTime[4]?.timings[0]?.start_time ? dayjs(customShiftTime[4]?.timings[0]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift4_end_time0",
                            value: customShiftTime[4]?.timings[0]?.end_time ? dayjs(customShiftTime[4]?.timings[0]?.end_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift4_start_time1",
                            value: customShiftTime[4]?.timings[1]?.start_time ? dayjs(customShiftTime[4]?.timings[1]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift4_end_time1",
                            value: customShiftTime[4]?.timings[1]?.end_time ? dayjs(customShiftTime[4]?.timings[1]?.end_time, "HH:mm") : '',
                        },
                        {
                            name: "custom_shift4_start_time2",
                            value: customShiftTime[4]?.timings[2]?.start_time ? dayjs(customShiftTime[4]?.timings[2]?.start_time, "HH:mm") : "",
                        },
                        {
                            name: "custom_shift4_end_time2",
                            value: customShiftTime[4]?.timings[2]?.end_time ? dayjs(customShiftTime[4]?.timings[2]?.end_time, "HH:mm") : "",
                        },



                        {
                            name: "buffer_time_before_check_in",
                            value: bufferDetails?.buffer_time_before_check_in ? dayjs(bufferDetails?.buffer_time_before_check_in, "HH:mm") : '',
                        },
                        {
                            name: "buffer_time_after_check_in",
                            value: bufferDetails?.buffer_time_after_check_in ? dayjs(bufferDetails?.buffer_time_after_check_in, "HH:mm") : '',
                        },
                        {
                            name: "buffer_time_before_check_out",
                            value: bufferDetails?.buffer_time_before_check_out ? dayjs(bufferDetails?.buffer_time_before_check_out, "HH:mm") : '',
                        },
                        {
                            name: "buffer_time_after_check_out",
                            value: bufferDetails?.buffer_time_after_check_out ? dayjs(bufferDetails?.buffer_time_after_check_out, "HH:mm") : '',
                        },
                        // {
                        //     name: "late_check_in_allowed",
                        //     value: lateCheckInAllowed.late_check_in_allowed
                        // },
                        // {
                        //     name: "late_check_in_allowed_in_year",
                        //     value: lateCheckInAllowed.late_check_in_allowed_in_year
                        // },
                        // {
                        //     name: "leave_type_deduction_after_exemption",
                        //     value: leaveTypeDeduction.leave_type_deduction_after_exemption
                        // },
                        // {
                        //     name: "no_of_leaves_deducted_after_exemption",
                        //     value: leaveTypeDeduction.no_of_leaves_deducted_after_exemption
                        // },
                        {
                            name: "exemption_type",
                            value: maxNoOfExemptionAllowed.exemption_type
                        },
                        {
                            name: "max_no_of_exemption_allowed",
                            value: maxNoOfExemptionAllowed.max_no_of_exemption_allowed
                        },
                        {
                            name: "leave_type_deduction_after_exemption",
                            value: maxNoOfExemptionAllowed.leave_type_deduction_after_exemption
                        },
                        {
                            name: "no_of_leaves_deducted_after_exemption",
                            value: maxNoOfExemptionAllowed.no_of_leaves_deducted_after_exemption
                        },
                        {
                            name: "late_check_in_type",
                            value: lateCheckInAllowed.late_check_in_type
                        },
                        {
                            name: "late_check_in_allowed",
                            value: lateCheckInAllowed.late_check_in_allowed
                        },
                        {
                            name: "leave_type_deduction_late_check_in",
                            value: lateCheckInAllowed.leave_type_deduction_late_check_in
                        },
                        {
                            name: "no_of_leaves_deducted_after_late_check_in",
                            value: lateCheckInAllowed.no_of_leaves_deducted_after_late_check_in
                        },
                        {
                            name: "is_lop_for_short_duration_only",
                            value: lateCheckInAllowed.is_lop_for_short_duration_only
                        },

                        {
                            name: "short_duration_type",
                            value: shortDurationAllowed.short_duration_type
                        },
                        {
                            name: "short_duration_allowed",
                            value: shortDurationAllowed.short_duration_allowed
                        },
                        {
                            name: "leave_type_deduction_short_duration",
                            value: shortDurationAllowed.leave_type_deduction_short_duration
                        },
                        {
                            name: "no_of_leaves_deducted_after_short_duration",
                            value: shortDurationAllowed.no_of_leaves_deducted_after_short_duration
                        },
                    ]}
                >
                    <Row gutter={[32, 2]}>
                        {/* Policy Name */}
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Policy Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Policy Name!',
                                    },
                                ]}
                            // how to change its fontsize
                            // inputFontSize="180px"

                            // style={{ fontSize: "240px !important", fontWeight: "600" }}
                            >
                                <Input
                                    // className='inputField'
                                    // inputFontSize="18px"
                                    placeholder="Policy Name"
                                    size='large'
                                    name='name'
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="work_policy"
                                label="Working Policy"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Working Policy!',
                                    },
                                ]}
                            >
                                <Select
                                    // name='work_policy' 
                                    placeholder="Working Policy"
                                    size='large'
                                    onChange={(e) => {
                                        setAttendance_type_home(null)
                                        setAttendance_type_office(null)
                                        setWorkingPolicy(e)
                                    }}
                                >
                                    {
                                        workingPolicyList?.map((item, index) => {
                                            return (
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            workingPolicy === 2 &&
                            <Col span={24}>
                                <p style={{ margin: "0px", fontSize: "24px", fontWeight: "600" }}>Hybrid Day</p>
                                <div style={{ display: "flex", gap: 20, marginTop: "16px" }}>
                                    {days.map((day, index) => {
                                        const isDaySelected = hybridDay[index] === 1;

                                        return (

                                            <div
                                                key={index}
                                                onClick={() => {
                                                    // full day
                                                    if (hybridDay[index] === 0) {
                                                        const data = update(hybridDay, {
                                                            [index]: {
                                                                $set: 1
                                                            }
                                                        });
                                                        setHybridDay(data);
                                                    }

                                                    if (isDaySelected) {
                                                        const data = update(hybridDay, {
                                                            [index]: {
                                                                $set: 0
                                                            }
                                                        });
                                                        setHybridDay(data);
                                                    }

                                                }}
                                                className={isDaySelected ? "selectedFullDay" : "disabledFullDay"}
                                            >
                                                {day.slice(0, 2)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        }

                        {/* <Col span={24}>
                            <p style={{ margin: "0px", fontSize: "24px", fontWeight: "600", marginTop: "20px", marginBottom: "10px" }}>Attendance Type</p>
                        </Col> */}

                        {
                            (workingPolicy === 1 || workingPolicy === 2)
                            &&
                            < Col xs={24} lg={12}>

                                <Form.Item
                                    name="attendance_type_office"
                                    label="Office"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Working Policy!',
                                        },
                                    ]}
                                >
                                    <Select
                                        // name='work_policy'
                                        size='large'
                                        // className='inputField'
                                        onChange={(e) => {
                                            console.log("e...", e);
                                            setAttendance_type_office(e)
                                        }}
                                    >
                                        {
                                            officeAttendanceTypeList?.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {
                            (workingPolicy === 0 || workingPolicy === 2)
                            &&
                            <Col xs={24} lg={12}>

                                <Form.Item
                                    name="attendance_type_home"
                                    label="Home"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Working Policy!',
                                        },
                                    ]}
                                >
                                    <Select
                                        name='work_policy'
                                        size='large'
                                        // className='inputField'
                                        onChange={(e) => setAttendance_type_home(e)}
                                    >
                                        {
                                            homeAttendanceTypeList?.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                        }

                        {/* Location */}
                        {
                            (attendance_type_office === 3 || attendance_type_office === 4)
                            &&
                            <>
                                <Col xs={24} xl={12} xxl={12}>
                                    <Form.Item
                                        name="policy_location"
                                        label="Location"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please upload Location!',
                                    //     },
                                    // ]}
                                    >
                                        <Select
                                            //  className='inputField'
                                            size='large'
                                            onChange={(e) => { setPolicy_location(e) }}

                                        >
                                            {
                                                savedLocationList?.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item.id}>{item.address}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {
                                    screenSize.width < 768
                                        ?
                                        <Col xs={24} xl={5} xxl={3} style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                            <Button style={{
                                                height: "40px",
                                                background: "#008080",
                                                color: "white"
                                            }}
                                                onClick={() => setShowGeoLocationModel(true)}
                                            >
                                                <img src={newLocation} alt="newLocation" style={{ marginRight: "10px" }} />   Add New Location
                                            </Button>
                                        </Col>
                                        :
                                        <Col xs={24} xl={5} xxl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Button style={{
                                                height: "40px",
                                                background: "#008080",
                                                color: "white",
                                                marginTop: "16px"
                                            }}
                                                onClick={() => setShowGeoLocationModel(true)}
                                            >
                                                <img src={newLocation} alt="newLocation" style={{ marginRight: "10px" }} />   Add New Location
                                            </Button>
                                        </Col>
                                }
                            </>
                        }


                        <Col span={24}>
                            <p className='shiftTimeText'>Shift Time</p>
                            <div className='shiftTimeBox'>
                                <p className='headingText'>Select a template</p>
                                <div>
                                    <Radio.Group
                                        size='large'
                                        options={[
                                            {
                                                label: 'Standard',
                                                value: 'standard',
                                            },
                                            {
                                                label: 'Custom',
                                                value: 'custom',
                                            }
                                        ]}
                                        onChange={(e) => {
                                            if (e.target.value === "standard") {
                                                setCustomShiftTime([
                                                    {
                                                        week_number: 1,
                                                        shift_number: "",
                                                        timings: []
                                                    },
                                                    {
                                                        week_number: 2,
                                                        shift_number: "",
                                                        timings: []
                                                    },
                                                    {
                                                        week_number: 3,
                                                        shift_number: "",
                                                        timings: []
                                                    },
                                                    {
                                                        week_number: 4,
                                                        shift_number: "",
                                                        timings: []
                                                    },
                                                    {
                                                        week_number: 5,
                                                        shift_number: "",
                                                        timings: []
                                                    }
                                                ])
                                            }
                                            if (e.target.value === "custom") {
                                                setShift([])
                                            }
                                            setShiftTimeTemplate(e.target.value)
                                        }}
                                        value={shiftTimeTemplate}
                                        optionType="button"
                                    />
                                </div>
                            </div>
                            {
                                shiftTimeTemplate === "standard"
                                &&
                                <div>
                                    <div className='numberOfShiftsContainer' >
                                        <p className='selectNoOfShifts'>Select no. of shifts</p>
                                        <div style={{ display: "flex", gap: "30px" }}>
                                            {
                                                numberOfShift.map((item, index) => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                setSelectedNumberOfShift(item)
                                                                setShift(shift.splice(0, item))
                                                            }
                                                            }
                                                            key={index}
                                                            className={item === selectedNumberOfShift ? 'selectedShift' : 'numberOfShifts'}>
                                                            <span>{item}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <div className='shiftTimePickerContainer'>
                                        {[...Array(selectedNumberOfShift)].map((_, index) => {
                                            return (

                                                <React.Fragment key={index}>
                                                    <div className='flexSpaceBetween'>
                                                        <div>
                                                            <Form.Item
                                                                name={`start_time${index}`}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select start time',
                                                                    },
                                                                ]}
                                                            >
                                                                <TimePicker
                                                                    hourStep={1}
                                                                    minuteStep={15}
                                                                    format="HH:mm"
                                                                    onChange={(time, timeString) =>
                                                                        handleTimeChange(index, timeString, shift[index]?.end_time)
                                                                    }
                                                                    className='shiftTimePicker'
                                                                    placeholder={`Shift ${index + 1} time (From)`}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item
                                                                name={`end_time${index}`}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select end time',
                                                                    },
                                                                ]}
                                                            >
                                                                <TimePicker
                                                                    hourStep={1}
                                                                    minuteStep={15}
                                                                    format="HH:mm"
                                                                    onChange={(time, timeString) => {
                                                                        handleTimeChange(index, shift[index]?.start_time, timeString)
                                                                    }
                                                                    }
                                                                    className='shiftTimePicker'
                                                                    placeholder={`Shift ${index + 1} time (To)`}
                                                                    disabled={shift[index]?.start_time ? false : true}
                                                                    disabledTime={(current) => {
                                                                        const currentHour = parseInt(shift[index]?.start_time.split(":")[0]) + 1;

                                                                        return {
                                                                            disabledHours: () => [...Array(currentHour).keys()],
                                                                            // disabledMinutes: () => [...Array(60).keys()],
                                                                            // disabledSeconds: () => [...Array(60).keys()],
                                                                        };
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                shiftTimeTemplate === "custom"
                                &&
                                < div style={{ marginTop: "30px" }}>
                                    <div>
                                        {
                                            customShiftTime.map((item, index) => {
                                                return (
                                                    <div style={{ width: "100%" }}>
                                                        <div >
                                                            <Row>
                                                                <Col span={5}>
                                                                    <p>Week {item.week_number}</p>
                                                                </Col>
                                                                <Col span={19}>
                                                                    <Form.Item
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter your Attendance Type!',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            size='large'
                                                                            placeholder="Select no. of Shifts"
                                                                            // className='attendanceType'
                                                                            onChange={(value) => {
                                                                                // console.log("e", e);
                                                                                const data = update(customShiftTime, {
                                                                                    [index]: {
                                                                                        shift_number: { $set: value },
                                                                                        timings: { $set: [] }
                                                                                    }
                                                                                })
                                                                                setCustomShiftTime(data)
                                                                            }
                                                                            }
                                                                            defaultValue={item.shift_number}
                                                                        // value={item.shift_number}


                                                                        >
                                                                            <Select.Option value={1}>1</Select.Option>
                                                                            <Select.Option value={2}>2</Select.Option>
                                                                            <Select.Option value={3}>3</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>


                                                        </div>
                                                        <div>
                                                            {[...Array(item?.shift_number ? item?.shift_number : 0)].map((shiftTime, shiftIndex) => {
                                                                return (


                                                                    <div style={{ width: "100%" }}>
                                                                        <Row>
                                                                            <Col span={5}>
                                                                                <p>Shift {shiftIndex + 1}</p>
                                                                            </Col>
                                                                            <Col span={8}>
                                                                                <Form.Item
                                                                                    name={`custom_shift${index}_start_time${shiftIndex}`}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: 'Please select start time',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <TimePicker
                                                                                        hourStep={1}
                                                                                        minuteStep={15}
                                                                                        format="HH:mm"
                                                                                        onChange={(time, timeString) =>
                                                                                            handleCustomTimeChange(index, shiftIndex, timeString, customShiftTime[index]?.timings[shiftIndex]?.end_time)
                                                                                        }
                                                                                        className='shiftTimePicker'
                                                                                        placeholder={`Shift ${shiftIndex + 1} time (From)`}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={11}>
                                                                                <Form.Item
                                                                                    name={`custom_shift${index}_end_time${shiftIndex}`}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: 'Please select end time',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <TimePicker
                                                                                        hourStep={1}
                                                                                        minuteStep={15}
                                                                                        format="HH:mm"
                                                                                        onChange={(time, timeString) => {
                                                                                            handleCustomTimeChange(index, shiftIndex, customShiftTime[index]?.timings[shiftIndex]?.start_time, timeString)
                                                                                        }
                                                                                        }
                                                                                        className='shiftTimePicker'
                                                                                        placeholder={`Shift ${shiftIndex + 1} time (To)`}
                                                                                        disabled={customShiftTime[index]?.timings[shiftIndex]?.start_time ? false : true}
                                                                                        disabledTime={(current) => {
                                                                                            const currentHour = parseInt(customShiftTime[index]?.timings[shiftIndex]?.start_time.split(":")[0]) + 1;

                                                                                            return {
                                                                                                disabledHours: () => [...Array(currentHour).keys()],
                                                                                                // disabledMinutes: () => [...Array(60).keys()],
                                                                                                // disabledSeconds: () => [...Array(60).keys()],
                                                                                            };
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                        </Col>

                        <Col xs={24} lg={20}>

                            <p className='weeklyOffText'>Weekly off</p>
                            <div className='weeklyOffContainer'>
                                <p className='selectTemplateText'>Select a template</p>
                                <Radio.Group
                                    size='large'
                                    options={[
                                        {
                                            label: 'Standard',
                                            value: 'standard',
                                        },
                                        {
                                            label: 'Custom',
                                            value: 'custom',
                                        }
                                    ]}
                                    onChange={(e) => {
                                        if (e.target.value === "standard") {
                                            setWeeklyOffTemplate("standard")
                                            setStandardWeeklyOff({ total_leaves: "", week_off: [0, 0, 0, 0, 0, 0, 0] })
                                        }
                                        if (e.target.value === "custom") {
                                            setWeeklyOffTemplate("custom")
                                        }
                                    }}
                                    value={weeklyOffTemplate}
                                    optionType="button"
                                />
                                <div style={{ display: "flex", gap: "30px" }}>
                                    {/* <Button
                                        onClick={() => {
                                            setWeeklyOffTemplate("standard")
                                            setStandardWeeklyOff({ total_leaves: "", week_off: [0, 0, 0, 0, 0, 0, 0] })
                                        }}
                                        className={weeklyOffTemplate === "standard" ? "selectedTemplate" : "notSelectedTemplate"}>
                                        Standard
                                    </Button>
                                    <Button
                                        onClick={() => setWeeklyOffTemplate("custom")}
                                        className={weeklyOffTemplate === "custom" ? "selectedTemplate" : "notSelectedTemplate"}
                                    >
                                        Custom
                                    </Button> */}

                                </div>
                            </div>
                            <br />
                            {
                                weeklyOffTemplate === "standard"
                                &&
                                <Radio.Group
                                    value={standardWeeklyOff.total_leaves}
                                >
                                    <div style={{ width: "70vw" }}>
                                        {
                                            renderStandardWeeklyOff()
                                        }
                                    </div>
                                </Radio.Group>

                            }
                            {
                                weeklyOffTemplate === "custom"
                                &&
                                <div className='renderCustomWeeklyOffOutterContainer'>
                                    {
                                        renderCustomWeeklyOff()
                                    }
                                </div>
                            }



                            <div style={{ width: "70vw" }}>
                                {
                                    error && weeklyOffTemplate === "standard" && standardWeeklyOff.total_leaves === "" && (
                                        <div style={{ color: "red" }}>Please select a valid number of weekly offs.</div>
                                    )
                                }

                                {
                                    error && weeklyOffTemplate === "standard" && standardWeeklyOff?.week_off?.reduce((a, b) => a + b, 0) === 0 && (
                                        <div style={{ color: "red" }}>Please select a day.</div>
                                    )
                                }
                            </div>
                        </Col>
                        <Col span={24}>
                            <p style={{ margin: "0px", fontSize: "24px", fontWeight: "600", marginBottom: "10px", marginTop: "20px" }}>Exemption Rules</p>
                            <p style={{ margin: "0px", fontWeight: "500", fontSize: "16px", marginBottom: "10px" }}>Location Exemption allowed</p>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="exemption_type"
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'No-Limit',
                                            value: 1,
                                        },
                                        {
                                            label: 'Max Cap',
                                            value: 2,
                                        }
                                    ]}

                                    onChange={(e) => setMaxNoOfExemptionAllowed({
                                        ...maxNoOfExemptionAllowed,
                                        exemption_type: e.target.value,
                                        max_no_of_exemption_allowed: null,
                                        leave_type_deduction_after_exemption: null,
                                        no_of_leaves_deducted_after_exemption: null
                                    })}
                                    value={maxNoOfExemptionAllowed.exemption_type}
                                // defaultValue={1}
                                />
                                {/* <Radio value={1}></Radio>
                                    <Radio value={2}></Radio>
                                </Radio.Group> */}

                            </Form.Item>
                        </Col>

                        {
                            maxNoOfExemptionAllowed.exemption_type !== null
                            &&
                            <>
                                <Col xs={24} xl={8}>

                                    <Form.Item
                                        name="max_no_of_exemption_allowed"
                                    >
                                        <Input
                                            size='large'
                                            placeholder="Enter Max no. of Exemption allowed"
                                            onChange={(e) => setMaxNoOfExemptionAllowed({ ...maxNoOfExemptionAllowed, max_no_of_exemption_allowed: e.target.value })}
                                        />

                                    </Form.Item>
                                </Col>
                                {
                                    maxNoOfExemptionAllowed.exemption_type === 1
                                    &&
                                    <>
                                        <Col xs={24} xl={8}>

                                            <Form.Item
                                                name="leave_type_deduction_after_exemption"
                                                label=""
                                            >
                                                <Select
                                                    size='large'
                                                    placeholder="Leave Type"
                                                    onSelect={(e) => setMaxNoOfExemptionAllowed({ ...maxNoOfExemptionAllowed, leave_type_deduction_after_exemption: e })}
                                                >
                                                    {
                                                        leaveTypeList?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} xl={8}>

                                            <Form.Item
                                                name="no_of_leaves_deducted_after_exemption"
                                            >
                                                <Input
                                                    size='large'
                                                    placeholder="No. of Leaves Deducted After Exemption"
                                                    onChange={(e) => setMaxNoOfExemptionAllowed({ ...maxNoOfExemptionAllowed, no_of_leaves_deducted_after_exemption: e.target.value })}
                                                />

                                            </Form.Item>
                                        </Col>
                                    </>
                                }

                            </>
                        }
                        {/* <Select
                                    // name='location'
                                    size='large'
                                    placeholder="Select max Allowed"
                                    onChange={(value) => setMaxNoOfExemptionAllowed({ ...maxNoOfExemptionAllowed, max_no_of_exemption_allowed: value })}
                                >
                                    {
                                        [...Array(10).keys()].map((item, index) => {
                                            return (
                                                <Select.Option value={index + 1}>{index + 1}</Select.Option>
                                            )
                                        })
                                    }

                                </Select> */}
                        {/* <Col xs={24} xl={12}>
                            <Form.Item
                                name="is_max_no_of_exemption_allowed_in_year"
                                label=""
                            >


                                <Radio.Group
                                    size='large'
                                    options={[
                                        {
                                            label: 'Month',
                                            value: 1,
                                        },
                                        {
                                            label: 'Year',
                                            value: 0,
                                        }
                                    ]}
                                    optionType="button"
                                    defaultValue={maxNoOfExemptionAllowed.is_max_no_of_exemption_allowed_in_year}
                                    onChange={(e) => setMaxNoOfExemptionAllowed({ ...maxNoOfExemptionAllowed, is_max_no_of_exemption_allowed_in_year: e.target.value })}
                                />
                            </Form.Item>
                        </Col> */}
                        {/* <Col span={24}>
                        <p style={{ margin: "0px", fontWeight: "500", fontSize: "20px" }}>Leave Type Deduction</p>
                    </Col> */}
                        {/* <Col xs={24} xl={12}>

                        <Form.Item
                            name="leave_type_deduction_after_exemption"
                            label=""
                        >
                            <Select
                                size='large'
                                placeholder="Leave Type"
                                onSelect={(e) => setLeaveTypeDeduction({ ...leaveTypeDeduction, leave_type_deduction_after_exemption: e })}
                            >
                                {
                                    leaveTypeList?.map((item, index) => {
                                        return (
                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={12}>

                        <Form.Item
                            name="no_of_leaves_deducted_after_exemption"
                            label=""
                        >
                            <Select
                                size='large'
                                placeholder="No. Of Leaves"
                                onChange={(e) => setLeaveTypeDeduction({ ...leaveTypeDeduction, no_of_leaves_deducted_after_exemption: e })}
                            >
                                {
                                    [...Array(10).keys()].map((item, index) => {
                                        return (
                                            <Select.Option value={index + 1}>{index + 1}</Select.Option>
                                        )
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col> */}
                        <Col span={24}>
                            <p style={{ margin: "0px", fontWeight: "500", fontSize: "16px", marginBottom: "10px" }}>Late Clock In Allowed</p>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="late_check_in_type"
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'No-Limit',
                                            value: 1,
                                        },
                                        {
                                            label: 'Max Cap',
                                            value: 2,
                                        }
                                    ]}
                                    onChange={(e) => setLateCheckInAllowed({
                                        ...lateCheckInAllowed,
                                        late_check_in_type: e.target.value,
                                        late_check_in_allowed: null,
                                        leave_type_deduction_late_check_in: null,
                                        no_of_leaves_deducted_after_late_check_in: null,
                                    })}
                                    value={lateCheckInAllowed.late_check_in_type}
                                />
                                {/* <Radio value={1}>No-Limit</Radio>
                                    <Radio value={2}>Max Cap</Radio>
                                </Radio.Group> */}
                                {/* {typeof lateCheckInAllowed.late_check_in_type}
                                {lateCheckInAllowed.late_check_in_type} */}
                            </Form.Item>
                        </Col>
                        {
                            lateCheckInAllowed.late_check_in_type !== null &&
                            <>
                                <Col xs={24} xl={8}>

                                    {/* <Form.Item
                                        name="late_check_in_allowed"
                                        label=""
                                    > */}

                                    <Form.Item
                                        name="late_check_in_allowed"
                                    >
                                        <Input
                                            size='large'
                                            placeholder="Enter Max no. of Exemption allowed"
                                            onChange={(e) => setLateCheckInAllowed({ ...lateCheckInAllowed, late_check_in_allowed: e.target.value })}
                                        />

                                    </Form.Item>

                                </Col>
                                {
                                    lateCheckInAllowed.late_check_in_type === 1
                                    &&
                                    <>
                                        <Col xs={24} xl={8}>

                                            <Form.Item
                                                name="leave_type_deduction_late_check_in"
                                                label=""
                                            >
                                                <Select
                                                    size='large'
                                                    placeholder="Leave Type"
                                                    onSelect={(e) => setLateCheckInAllowed({ ...lateCheckInAllowed, leave_type_deduction_late_check_in: e })}
                                                >
                                                    {
                                                        leaveTypeList?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} xl={8}>

                                            <Form.Item
                                                name="no_of_leaves_deducted_after_late_check_in"
                                            >
                                                <Input
                                                    size='large'
                                                    placeholder="No. of Leaves Deducted After Exemption"
                                                    onChange={(e) => setLateCheckInAllowed({ ...lateCheckInAllowed, no_of_leaves_deducted_after_late_check_in: e.target.value })}
                                                />

                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                {
                                    lateCheckInAllowed.late_check_in_type === 2
                                    &&
                                    <>
                                        <Col xs={24} >

                                            <Form.Item 
                                                name="is_lop_for_short_duration_only" 
                                                label="Short Duration LOP Only" 
                                                // valuePropName="checked"
                                            >
                                            <Switch
                                                checked = {lateCheckInAllowed.is_lop_for_short_duration_only}
                                                onChange={(e) => setLateCheckInAllowed({ ...lateCheckInAllowed, is_lop_for_short_duration_only: e })}
       
                                            />
                                            </Form.Item>

                                        </Col>
                                    </>
                                }
                            </>
                        }


                        <Col span={24}>
                            <p style={{ margin: "0px", fontWeight: "500", fontSize: "16px", marginBottom: "10px" }}>Short Duration Allowed</p>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="short_duration_type"
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'No-Limit',
                                            value: 1,
                                        },
                                        {
                                            label: 'Max Cap',
                                            value: 2,
                                        }
                                    ]}
                                    onChange={(e) => setShortDurationAllowed({
                                        ...shortDurationAllowed,
                                        short_duration_type: e.target.value,
                                        short_duration_allowed: null,
                                        leave_type_deduction_short_duration: null,
                                        no_of_leaves_deducted_after_short_duration: null,
                                    })}
                                    value={shortDurationAllowed.short_duration_type}
                                />
                                {/* <Radio value={1}>No-Limit</Radio>
                                    <Radio value={2}>Max Cap</Radio>
                                </Radio.Group> */}
                                {/* {typeof lateCheckInAllowed.late_check_in_type}
                                {lateCheckInAllowed.late_check_in_type} */}
                            </Form.Item>
                        </Col>
                        {
                            shortDurationAllowed.short_duration_type !== null &&
                            <>
                                <Col xs={24} xl={8}>

                                    <Form.Item
                                        name="short_duration_allowed"
                                    >
                                        <Input
                                            size='large'
                                            placeholder="Enter Max no. of Short Duration allowed"
                                            onChange={(e) => setShortDurationAllowed({ ...shortDurationAllowed, short_duration_allowed: e.target.value })}
                                        />

                                    </Form.Item>

                                </Col>
                                {
                                    shortDurationAllowed.short_duration_type === 1
                                    &&
                                    <>
                                        <Col xs={24} xl={8}>

                                            <Form.Item
                                                name="leave_type_deduction_short_duration"
                                                label=""
                                            >
                                                <Select
                                                    size='large'
                                                    placeholder="Leave Type"
                                                    onSelect={(e) => setShortDurationAllowed({ ...shortDurationAllowed, leave_type_deduction_short_duration: e })}
                                                >
                                                    {
                                                        leaveTypeList?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} xl={8}>

                                            <Form.Item
                                                name="no_of_leaves_deducted_after_short_duration"
                                            >
                                                <Input
                                                    size='large'
                                                    placeholder="No. of Leaves Deducted After Short Duration"
                                                    onChange={(e) => setShortDurationAllowed({ ...shortDurationAllowed, no_of_leaves_deducted_after_short_duration: e.target.value })}
                                                />

                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </>
                        }

                        {/* <Select
                                    size='large'
                                    placeholder="Select max Allowed"
                                    // name="late_check_in_allowed"
                                    // value={lateCheckInAllowed.late_check_in_allowed}
                                    onChange={(e) => setLateCheckInAllowed({ ...lateCheckInAllowed, late_check_in_allowed: e })}
                                >
                                    {
                                        [...Array(10).keys()].map((item, index) => {
                                            return (
                                                <Select.Option value={index + 1}>{index + 1}</Select.Option>
                                            )
                                        })
                                    }

                                </Select> */}
                        {/* </Form.Item> */}
                        {/* <Col xs={24} xl={12}>
                            <Form.Item
                                name="late_check_in_allowed_in_year"
                                label=""
                            >
                                <Radio.Group
                                    size='large'
                                    options={[
                                        {
                                            label: 'Month',
                                            value: 1,
                                        },
                                        {
                                            label: 'Year',
                                            value: 0,
                                        }
                                    ]}
                                    optionType="button"
                                    defaultValue={lateCheckInAllowed.late_check_in_allowed_in_year}
                                    onChange={(e) => setLateCheckInAllowed({ ...lateCheckInAllowed, late_check_in_allowed_in_year: e.target.value })}
                                />
                            </Form.Item>
                        </Col> */}
                        <Col span={24}>
                            <p style={{ margin: "0px", fontWeight: "500", fontSize: "16px", marginBottom: "10px" }}>Buffer Time For Clock In</p>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Form.Item
                                name="buffer_time_before_check_in"
                                label=""
                            >

                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time);
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                        setBufferDetails({ ...bufferDetails, buffer_time_before_check_in: timeString })
                                    }}
                                />


                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Form.Item
                                name="buffer_time_after_check_in"
                                label=""
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    placeholder="+ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                        setBufferDetails({ ...bufferDetails, buffer_time_after_check_in: timeString })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <p style={{ margin: "0px", fontWeight: "500", fontSize: "16px", marginBottom: "10px" }}>Buffer Time For Clock Out</p>
                        </Col>
                        <Col xs={24} xl={12}>

                            <Form.Item
                                name="buffer_time_before_check_out"
                                label=""
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                        setBufferDetails({ ...bufferDetails, buffer_time_before_check_out: timeString })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Form.Item
                                name="buffer_time_after_check_out"
                                label=""
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    placeholder="+ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                        setBufferDetails({ ...bufferDetails, buffer_time_after_check_out: timeString })
                                    }}
                                    value={bufferDetails.buffer_time_after_check_out}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        className='addNewPolicyButton'
                        htmlType='submit'
                    >
                        {
                            params.id ? params.action ? "Clone Policy" : "Update Policy" : "Add New Policy"
                        }

                    </Button>
                </Form>
            </div>

            {/* geo location model */}
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={showGeoLocationModel}
                // onOk={() => setShowGeoLocationModel(false)}
                onCancel={() => setShowGeoLocationModel(false)}
                footer={null}
                width={1100}
                maskClosable={false}
                keyboard={false}
                // maskClosable={false}
                closable={true}
            >
                {
                    screenSize.width < 768
                        ?
                        // mobile view
                        <div>
                            <div id="map" style={{ height: '420px', width: "100%" }} />
                            <div>
                                <p style={{ fontSize: "18px", fontWeight: "600", margin: "0px", marginTop: "10px" }}>Attendance Location</p>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#BBBBBB" }}>This location will allow us to Mark your GPS Attendance</p>
                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                    <img src={mapLocation} alt="mapLocation" style={{ marginRight: "10px" }} />
                                    <p style={{ fontSize: "12px", fontWeight: "400", color: "#9C9C9C" }}>{address}</p>
                                </div>
                                <Form
                                    name="basic"
                                    onFinish={addLocation}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={locationForm}
                                >
                                    <Form.Item
                                        label=""
                                        name="address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter location name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            placeholder='Save Location Name'
                                            style={{
                                                marginTop: "0px"
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your location name!',
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                    <button
                                        style={{
                                            background: "#008080",
                                            color: "white",
                                            width: "100%",
                                            height: "40px",
                                            border: "none",
                                            borderRadius: "3px",
                                            marginTop: "10px"
                                        }}
                                        htmlType='submit'
                                    >Save Location</button>
                                </Form>

                            </div>
                        </div>
                        :
                        // desktop view
                        <div style={{ display: "flex" }}>
                            <div id="map" style={{ height: '500px', width: "550px" }} />
                            <div style={{
                                padding: "0px 20px"
                            }}>
                                <p style={{ fontSize: "24px", fontWeight: "600" }}>Attendance Location</p>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#BBBBBB" }}>This location will allow us to Mark your GPS Attendance</p>
                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                    <img src={mapLocation} alt="mapLocation" style={{ marginRight: "10px" }} />
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#9C9C9C" }}>{address}</p>
                                </div>
                                <Form
                                    name="basic"
                                    onFinish={addLocation}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={locationForm}
                                >
                                    <Form.Item
                                        label=""
                                        name="address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter location name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            placeholder='Save Location Name'
                                            style={{
                                                marginTop: "20px"
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your location name!',
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                    <button
                                        style={{
                                            background: "#008080",
                                            color: "white",
                                            width: "100%",
                                            height: "40px",
                                            border: "none",
                                            borderRadius: "3px",
                                            marginTop: "170px"
                                        }}
                                        htmlType='submit'
                                    >Save Location</button>
                                </Form>

                            </div>
                        </div>
                }
            </Modal>


            <div style={{ height: "100px" }}></div>

        </div >
    )
}

export default PolicyForm