import React, {useEffect, useState} from 'react';
import {DatePicker, Space, Spin,Input} from 'antd';
import Search from '../../SearchBar/Search';
import MeetingOverview from './MeetingOverview';
import moment from 'moment';
import dayjs from 'dayjs';
import {Table} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import request from '../../../../utils/axios';
import MobileStyle from './MoibileSummary.module.scss';
import getScreenSize from '../../../../utils/getScreenSize';
import CalenderIcon from '../../../../assets/tracking/calendericon.svg';
import TimeIcon from '../../../../assets/tracking/time.svg';
import LocationIcon from '../../../../assets/tracking/LocationIcomn.svg';
import clientIcon from '../../../../assets/tracking/Client.svg';
import {SearchOutlined} from '@ant-design/icons';
const Summary = () => {
  const screenSize = getScreenSize();
  const [searchValue, setSearchValue] = useState('');
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [month, setMonth] = useState(moment(new Date()).format('YYYY-MM'));
  const [dataList, setDataList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });

  // hande month chnage Function
  const onChange = (date, dateString) => {
 setPagination( {
            current: 1,
            pageSize: 10,
            total: 0,
          });
          setDataList([])
          setHasMore(true)
    setMonth(dateString);
    
  };

  const LoadData = async (employement_number ,newpagination, list) => {
    // console.log(employement_number,newpagination,list,dateString)
    const {current, pageSize} = newpagination || pagination;
    
     let emp_Id = employement_number || '';
    const MonthPayload =  month.split('-');
    const apiUrl = `tracking/admin/employee-meeting/details/all?employee_id=${emp_Id}&page=${current}&page_size=${pageSize}&month=${MonthPayload[1]}&year=${MonthPayload[0]}`;
    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      if (newpagination) {
        setDataList([...list, ...res.data?.pagination_data?.data]);
      } else {
        setDataList([...dataList, ...res.data?.pagination_data?.data]);
      }

      setPagination({
        ...pagination,
        current: pagination.current + 1,
        total: res?.data.pagination_data?.total,
      });

      if (res?.data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const internalJobColumns = [
    {
      title: 'Employee name',
      dataIndex: 'employee_name',
      key: 'employee_name',
      width: 200,
      //   render: (text, record) => (
      //     <span>{moment(text).format("DD-MM-YYYY")}</span>
      //   ),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      width: 200,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (text, record) => (
        <span>{moment(text).format('DD-MM-YYYY')}</span>
      ),
    },
    {
      title: 'Time Duration',
      dataIndex: 'meeting_time',
      key: 'meeting_time',
      width: 250,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div
          style={{
            backgroundColor: text == 'Attended' ? '#D4EDDA' : '#FFDBDB',
            color: text == 'Attended' ? '#28B15F' : '#FD3434',
            borderRadius: '24px',
            padding: '6px 15px',
            fontSize: '16px',
            font: '400',
            textAlign: 'center',
          }}>
          {text}
        </div>
      ),
    },
  ];

  useEffect(() => {
    LoadData();
  }, [month]);
  const search = async data => {
    try {
      setSearchValue(data);
      const response = await request({
        method: 'get',
        
        url: `leave/search/employees/?employee_name=${data}&type=admin`,
      });

      setSearchEmployeeList(response);
      if (data === '') {
         setHasMore(true)
         setPagination( {
            current: 1,
            pageSize: 10,
            total: 0,
          });
        LoadData(
          '',
          {
            current: 1,
            pageSize: 10,
            total: 0,
          },
          []
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchSelectChange = async (
    employement_number,
    employee_name,
  ) => {
    try {
      LoadData(
        employement_number,
        {
          current: 1,
          pageSize: 10, // Number of items per page
          total: 0,
        },
        [],
      );
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {screenSize.width < 768 ? (
        <div className={MobileStyle.container}>
          <div className={MobileStyle.header}>
            <div className={MobileStyle.text}> Meeting Summary</div>
            <div>
              <DatePicker
                onChange={onChange}
                // onSelect={handleSelectMonth}
                picker="month"
                defaultValue={dayjs()}
              />
            </div>
          </div>
          <div className={MobileStyle.search}>
            <div className="search-container">
                <Input
                  size="small"
                  type="text"
                  placeholder="Search for employees"
                  onChange={e => search(e.target.value)}
                  value={searchValue}
                  prefix={<SearchOutlined />}
                  allowClear
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSearchSelectChange(
                            item.employement_number,
                            item.employee_name,
                          )
                        }>
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
          </div>
          <div className={MobileStyle.overviewdiv}>
            <MeetingOverview month={month} />
          </div>
          <div className={MobileStyle.contentContainer}>
            <div
              id="scrollableDiv"
              style={{
                height: 'auto',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <InfiniteScroll
                dataLength={
                  pagination.total - dataList?.length * pagination.current
                }
                next={LoadData}
                hasMore={hasMore}
                endMessage={
                  <p style={{textAlign: 'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                loader={<Spin size="large" />}
                scrollableTarget="scrollableDiv"
                height="100vh">
                {dataList.map((item, index) => {
                  return (
                    <div className={MobileStyle.meetingDiv} key={index}>
                      <div className={MobileStyle.top}>
                        <div className={MobileStyle.namecontainer}>
                          <div className={MobileStyle.name}>
                            {item.employee_name}
                          </div>
                          <div
                            className={MobileStyle.status}
                            style={{
                              backgroundColor:
                                item?.status == 'Attended'
                                  ? '#D4EDDA'
                                  : '#FFDBDB',
                              color:
                                item.status == 'Attended'
                                  ? '#28B15F'
                                  : '#FD3434',
                            }}>
                            {item.status}
                          </div>
                        </div>
                      </div>
                      <div className={MobileStyle.bottom}>
                        <div className={MobileStyle.clientContainer}>
                          <img src={clientIcon} alt="clientIcon" />
                          <span>{item.client_name}</span>
                        </div>
                        <div className={MobileStyle.dateContainer}>
                          <img src={CalenderIcon} alt="calendericon" />
                          <span>{moment(item.date).format('DD-MM-YYYY')}</span>
                        </div>
                        <div className={MobileStyle.duration}>
                          {' '}
                          <img src={TimeIcon} alt="calendericon" />
                          <span>{item.meeting_time}</span>
                        </div>
                        <div className={MobileStyle.containerlocation}>
                          <div className={MobileStyle.leftcontainer}>
                            <img src={LocationIcon} alt="locationicon" />
                            <div className={MobileStyle.location}>
                              <div>{item.location}</div>
                              {/* <div>Gurugram, Haryana 122018</div> */}
                            </div>
                          </div>
                          <div className={MobileStyle.rightcontainer}>
                            <img src={LocationIcon} alt="locationicon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      ) : (
        <div style={{background: 'white', padding: '30px 30px'}}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}>
            <div style={{display: 'flex', alignItems: 'center', gap: '24px'}}>
              <div
                style={{fontSize: '20px', fontWeight: '500', color: '#161616'}}>
                Meeting Summary
              </div>
              <div>
                <div style={{width: '350px'}}>
                  <div className="search-container">
                <Input
                  size="small"
                  type="text"
                  placeholder="Search for employees"
                  onChange={e => search(e.target.value)}
                  value={searchValue}
                  prefix={<SearchOutlined />}
                  allowClear
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSearchSelectChange(
                            item.employement_number,
                            item.employee_name,
                          )
                        }>
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
                </div>
              </div>
            </div>
            <DatePicker
              onChange={onChange}
              // onSelect={handleSelectMonth}
              picker="month"
              defaultValue={dayjs()}
            />
          </div>
          <MeetingOverview month={month} />
          {/* meeting_table */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '32px',
              // border: "1px solid red",
              // padding: "50px",
            }}>
            <div
              id="scrollableDiv"
              style={{
                height: 'auto',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <InfiniteScroll
                dataLength={
                  pagination.total - dataList?.length * pagination.current
                }
                next={LoadData}
                hasMore={hasMore}
                endMessage={
                  <p style={{textAlign: 'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                loader={
                  <p style={{textAlign: 'center'}}>
                    <b>Loading...</b>
                  </p>
                }
                scrollableTarget="scrollableDiv"
                height="70vh">
                <Table
                  columns={internalJobColumns}
                  dataSource={dataList}
                  pagination={false}
                  sticky={{offsetHeader: 0}}
                />
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Summary;
