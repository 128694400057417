import React, { useEffect, useState } from 'react'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import edit from "../../../../assets/edit.svg";
import { useNavigate } from 'react-router-dom';
import request from '../../../../utils/axios';
import add from "../../../../assets/Org/add.svg";
import clone_icon from "../../../../assets/clone_icon.svg"

function SalaryStructure() {
    const navigate = useNavigate();

    const salaryStructureColumn = [
        {
            title: 'Name',
            dataIndex: 'structure_name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => navigate("/admin/payroll/salary-configuration-form/" + record.id, { replace: true })}
                    />
                    <img src={clone_icon} alt="clone"
                        onClick={() => navigate(`/admin/payroll/salary-configuration-form/${record.id}/clone`, { replace: true })}
                    />
                </>
        },
    ]

    const [salaryStructureData, setSalaryStructureData] = useState([])

    const fetchSalaryStrDetail = async (page,pagSize) => {
        const data = await request({
            method: "get",
            url: `payroll/admin/fetch-salary-structure?page=${page}&page_size=${pagSize}`,
        })
        
        setSalaryStructureData([...data.pagination_data.data])
        return data;
    }

    useEffect(() => {
        // fetchSalaryStrDetail()
    }, [])

    return (
        <div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Salary Structure</p>
                <img src={add} alt="add"
                    onClick={() => navigate("/admin/payroll/salary-configuration-form", { replace: true })}
                />
            </div>

            <DynamicTable
                fetchData={fetchSalaryStrDetail}
                columns={salaryStructureColumn}
                handleRowClick={() => { }}
                pageSize={5}
                showPagination={true}
            />

        </div>
    )
}

export default SalaryStructure