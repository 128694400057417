import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import { Button, Col, DatePicker, Input, Modal, Pagination, Popover, Row, Select, Spin, Table } from 'antd';
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import "./LeaveReportFullView.scss"
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
// import download from "../../../../assets/downlaod.svg"
import request from '../../../../utils/axios';
import getScreenSize from "../../../../utils/getScreenSize"
import InfiniteScroll from "react-infinite-scroll-component";
import date from "../../../../assets/Attendance/Admin/date.svg"
import moment from 'moment';
// import SearchBar from '../../../../component/SearchBar/SearchBar';
import LeaveReportSearchBar from '../LeaveReportSearchBar/LeaveReportSearchBar';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import searchImage from "../../../../assets/searchImage.svg";
import time from "../../../../assets/time.svg";
import downloadImg from "../../../../assets/downlaod.svg";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import download from "../../../../assets/download.png"


function LeaveReportFullView() {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const [leaveReportList, setLeaveReportList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const page_size = 10
    const ref = useRef(null);
    const { RangePicker } = DatePicker;

    const [dataLength, setDataLength] = useState(null);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [downloadReportLoading, setDownloadReportLoading] = useState(false)

    const [leaveReportColums, setLeaveReportColums] = useState([
        {
            title: 'ID',
            dataIndex: 'employement_number',
            key: 'employement_number',
        },
        {
            title: 'Employee',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
    ])
    const [mobileLeaveColumList, setMobileLeaveColumList] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })

    const fetchLeaveRoportColumn = async () => {
        try {
            const data = await request({
                method: "get",
                url: "leave/leave_type/columns/",
            })
            setMobileLeaveColumList(data.data)
            setLeaveReportColums([...leaveReportColums, ...data.data])
            // fetchLeaveReportData();
        }
        catch (err) {
            console.log(err);
        }
    }

    const fetchLeaveReportData = async (paginationParams, dataList) => {
        try {
            const { current } = paginationParams || pagination;
            if (leaveReportDaysForTable === "custom") {
                const data = await request({
                    method: "get",
                    url: `leave/admin/leave-report/all?page=${current}&page_size=10&start_date=${leaveReportCustomDateForTable.start_date}&end_date=${leaveReportCustomDateForTable.end_date}`,
                });
                setLeaveReportList(data.pagination_data.data);
                if (paginationParams) {
                    setItems([...dataList, ...data?.pagination_data?.data]);
                }
                else {
                    setItems([...items, ...data?.pagination_data?.data]);
                }
                // setDataLength(data?.pagination_data?.total);
                // setPage(page + 1);
                setPagination({
                    ...pagination,
                    total: data?.pagination_data?.total,
                    current: current + 1
                })
                if (data?.pagination_data?.next === null) {
                    setHasMore(false);
                    return;
                }
            }
            else {
                const data = await request({
                    method: "get",
                    url: `leave/admin/leave-report/all?page=${current}&page_size=10&days=${leaveReportDaysForTable}`,
                });
                setLeaveReportList(data.pagination_data.data);
                if (paginationParams) {
                    setItems([...dataList, ...data?.pagination_data?.data]);
                }
                else {
                    setItems([...items, ...data?.pagination_data?.data]);
                }
                // setDataLength(data?.pagination_data?.total);
                // setPage(page + 1);
                setPagination({
                    ...pagination,
                    total: data?.pagination_data?.total,
                    current: current + 1
                })
                if (data?.pagination_data?.next === null) {
                    setHasMore(false);
                    return;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchLeaveRoportColumn();
    }, [])

    const downloadReport = async () => {
        setDownloadReportLoading(true)
        if (leaveReportDays === "custom") {
            const response = await request({
                method: "get",
                url: `leave/admin/leave-report/download?start_date=${leaveReportCustomDate.start_date}&end_date=${leaveReportCustomDate.end_date}`,
                responseType: "arraybuffer",
            });
            const file = new Blob([response], { type: "application/pdf" });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = url;
            link.download = "file.pdf";
            link.click();
        }
        else {
            const response = await request({
                method: "get",
                url: `leave/admin/leave-report/download?days=${leaveReportDays}`,
                responseType: "arraybuffer",
            });
            const file = new Blob([response], { type: "application/pdf" });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = url;
            link.download = "file.pdf";
            link.click();
        }
        setDownloadReportLoading(false)
    };


    const [open, setOpen] = useState(false)

    const [dateRange, setDateRange] = useState([]);
    const [leaveReportDays, setLeaveReportDays] = useState(7);
    const [leaveReportCustomDate, setLeaveReportCustomDate] = useState({
        start_date: "",
        end_date: ""
    });

    const handleDateChange = async (value) => {
        const start_date = value[0]?.format("YYYY-MM-DD");
        const end_date = value[1]?.format("YYYY-MM-DD");
        setDateRange(value);
        setLeaveReportCustomDate({ ...leaveReportCustomDate, start_date, end_date })
    };

    const [leaveReportDaysForTable, setLeaveReportDaysForTable] = useState(7);
    const [leaveReportCustomDateForTable, setLeaveReportCustomDateForTable] = useState({
        start_date: "",
        end_date: ""
    });

    const handleDateChangeForTable = async (value) => {
        const start_date = value[0]?.format("YYYY-MM-DD");
        const end_date = value[1]?.format("YYYY-MM-DD");
        setDateRange(value);
        setLeaveReportCustomDateForTable({ ...leaveReportCustomDateForTable, start_date, end_date })
        setPage(1);
        setItems([]);
        setHasMore(true);
    };

    const getPopupContainer = (triggerNode) => {
        return triggerNode.parentNode;
    };

    const handleDaysChange = (value) => {
        console.log("value", value);
        if (value === "custom") {
            // setShowDatePicker(true)
            setLeaveReportDays(value);
            setOpen(true);
        } else {
            // setShowDatePicker(false)
            setLeaveReportDays(value);
        }
    };

    const handleDaysChangeForTable = (value) => {
        console.log("value", value);
        if (value === "custom") {
            // setShowDatePicker(true)
            setLeaveReportDaysForTable(value);
            setOpen(true);
            setPage(1);
            setItems([]);
            setHasMore(true);
        } else {
            // setShowDatePicker(false)
            setLeaveReportDaysForTable(value);
            setPage(1);
            setItems([]);
            setHasMore(true);
        }
    };

    useEffect(() => {
        fetchLeaveReportData();
    }, [leaveReportDaysForTable, leaveReportCustomDateForTable]);

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <>
                    <div style={{ padding: "0px 16px" }}>
                        <div className="employeeLeavesContainer">
                            <div className="policyText">{item?.employee_name}</div>
                        </div>
                        <div
                            style={{
                                fontWeight: "400px",
                                fontSize: "16px",
                                color: "#8A8A8A",
                                marginTop: "10px",
                            }}
                        >
                            {item?.employement_number}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                                alignItems: "center",
                            }}
                        >
                            <img src={time} alt="downlaod" className="downlaodImage" />
                            <div
                                style={{
                                    marginLeft: "8px",
                                    color: "#8A8A8A",
                                    fontWeight: "400px",
                                    fontSize: "12px",
                                }}
                            >
                                {moment(item?.date).format("DD MMM YYYY")}
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: "12px",

                                justifyContent: "space-between",
                                // width: "45%",
                            }}
                        >
                            {mobileLeaveColumList && mobileLeaveColumList?.map((el, elIndex) => {
                                return (
                                    <>
                                        {item[el?.dataIndex] === "N/A"
                                            ?
                                            <></>
                                            :
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "400px",
                                                        marginTop: "5px",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    {el?.title}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "400px",
                                                        color: "#8A8A8A",
                                                        marginTop: "5px",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    {item[el?.dataIndex]}
                                                </div>
                                            </div>
                                        }
                                    </>
                                );
                            })}
                        </div>
                    </div>

                    {index !== leaveReportList?.length - 1 && (
                        <hr className="divider" />
                    )}


                </>
            );
        })
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !loading) {

    //             if (Math.ceil(total / page_size) >= page) {
    //                 fetchLeaveReportData();
    //             }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [loading]);

    // useEffect(() => {
    //     fetchLeaveReportData();
    // }, []); // Fetch initial data

    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const handleSelectChange = async (employement_number, employee_name) => {
        try {
            setSearchValue(employee_name)
            const response = await request({
                method: "get",
                url: `leave/admin/search/employees/leave/details/?employement_number=${employement_number}`,
            })
            console.log("response...1...", response);
            // fetchLeaveReportData();
            setItems([response])
            setHasMore(false);
            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            setSearchValue(data)
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`
            })

            setSearchEmployeeList(response)
            if (data === "") {
                // const data = await request({
                //     method: "get",
                //     url: `leave/admin/leave-report/all?page=1&page_size=${page_size}`,
                // })
                setHasMore(true)
                // setItems(data.pagination_data.data)
                const pagination = {
                    current: 1,
                    pageSize: 10,
                    total: 0
                }
                const dataList = [];
                fetchLeaveReportData(pagination, dataList);

            }
        }
        catch (err) {
            console.log(err);
        }
    }



    // useEffect(() => {
    //     ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const [downloadModal, setDownloadModal] = useState(false)

    const leaveReportDownloadPopover = (
        <>
            <Select
                style={{ width: 300, height: 40 }}
                placeholder="Select an option"
                onChange={handleDaysChange}
                value={leaveReportDays}
            >
                <Select.Option value={7}>Last 7 days</Select.Option>
                <Select.Option value={14}>Last 14 days</Select.Option>
                <Select.Option value={30}>Last 30 days</Select.Option>
                <Select.Option value="custom">
                    <Popover
                        title=""
                        content={
                            <div className="modal-content">
                                <RangePicker
                                    value={dateRange}
                                    onChange={handleDateChange}
                                    format="YYYY-MM-DD"
                                    disabledDate={(current) =>
                                        current && current > moment().endOf("day")
                                    }
                                />
                            </div>
                        }
                        getPopupContainer={getPopupContainer}
                        placement="right"
                    >
                        <p
                            style={{
                                cursor: "pointer",
                                width: "90%",
                                margin: "0px",
                            }}
                        >
                            Custom Date
                        </p>
                    </Popover>
                </Select.Option>
            </Select>
            <br /><br />
            <button
                style={{
                    border: "none",
                    color: "white",
                    background: "#008080",
                    borderRadius: "4px",
                    height: "36px",
                    width: "120px"
                }}
                onClick={() => downloadReport()}
            >
                {downloadReportLoading ? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} /> : "Download"}
            </button>
        </>
    )

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={leaveReportColums}
            dataSource={dataList}
            pagination={false}
        />
    }


    return (
        <div className='leaveReportFullViewContainer' ref={ref}>
            {
                screenSize.width < 768
                    ?
                    <>
                        <div style={{ padding: "0px 16px" }}>

                            <div className="myTeamTextNew" >Leave Report</div>


                            <div style={{ marginTop: "20px", marginBlock: "20px" }}>
                                <LeaveReportSearchBar
                                    setTableDataForSearchEmployee={setLeaveReportList}
                                    fetchTableDataFromApi={fetchLeaveReportData}
                                    placeholderText="Search for employees"
                                    empType="admin"
                                />
                            </div>
                            {/* <img
                                src={downloadImg}
                                alt="downlaod"
                                className="downlaodImage"
                                onClick={() => {
                                    setDownloadModal(true);
                                }}
                            /> */}

                            <img src={download} alt="download" width={35} height={35}
                                onClick={() => {
                                    setDownloadModal(true);
                                }}
                            />
                        </div>


                        <InfiniteScrollComponent
                            apiEndpoint="leave/admin/leave-report/all"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </>
                    :
                    <>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        <p className='heading'>Leave</p> */}
                        <div className='leaveReportContainer'>
                            <div className='flexSpaceBetween'>
                                <p className='leaveReportHeading'>Leave Report</p>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <Select
                                        style={{ width: 300, height: 40 }}
                                        placeholder="Select an option"
                                        onChange={handleDaysChangeForTable}
                                        value={leaveReportDaysForTable}
                                    >
                                        <Select.Option value={7}>Last 7 days</Select.Option>
                                        <Select.Option value={14}>Last 14 days</Select.Option>
                                        <Select.Option value={30}>Last 30 days</Select.Option>
                                        <Select.Option value="custom">
                                            <Popover
                                                title=""
                                                content={
                                                    <div className="modal-content">
                                                        <RangePicker
                                                            value={dateRange}
                                                            onChange={handleDateChangeForTable}
                                                            format="YYYY-MM-DD"
                                                            disabledDate={(current) =>
                                                                current && current > moment().endOf("day")
                                                            }
                                                        />
                                                    </div>
                                                }
                                                getPopupContainer={getPopupContainer}
                                                placement="right"
                                            >
                                                <p
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "90%",
                                                        margin: "0px",
                                                    }}
                                                >
                                                    Custom Date
                                                </p>
                                            </Popover>
                                        </Select.Option>
                                    </Select>


                                    <div className="search-container">
                                        <Input
                                            type="text"
                                            placeholder="Search employee"
                                            onChange={(e) => search(e.target.value)}
                                            size='small'
                                            value={searchValue}
                                            prefix={<SearchOutlined />}
                                            allowClear
                                        />
                                        {searchEmployeeList.length > 0 && (
                                            <ul>
                                                {searchEmployeeList.map((item, index) => (
                                                    <li key={index} onClick={() => handleSelectChange(item.employement_number, item.employee_name)}>
                                                        {item.employee_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                    <Popover placement="bottomRight" trigger="click" title="Download report" content={leaveReportDownloadPopover} arrow={false}>
                                        <img src={download} alt="download" width={35} height={35}
                                        />
                                    </Popover>
                                </div>
                            </div>
                            <br />
                            {/* <CommonTable
                                columns={leaveReportColums}
                                data={leaveReportList}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            /> */}

                            <InfiniteScroll
                                dataLength={dataLength - (items.length)}
                                next={fetchLeaveReportData}
                                hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                loader={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Loading...</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv"
                                height="70vh"
                            >
                                {
                                    renderUIDesktop(items)
                                }
                            </InfiniteScroll>

                        </div>
                    </>
            }

            <Modal
                title="Download Report"
                centered
                open={downloadModal}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setDownloadModal(false)}
                width={800}
                footer={null}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                    <Select
                        style={{ width: "100%", marginTop: "20px" }}
                        placeholder="Select an option"
                        onChange={handleDaysChange}
                        defaultValue={leaveReportDays}
                        size='large'
                    >
                        <Select.Option value={7}>Last 7 days</Select.Option>
                        <Select.Option value={14}>Last 14 days</Select.Option>
                        <Select.Option value={30}>Last 30 days</Select.Option>
                        <Select.Option value="custom">
                            <span
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                Custom Date
                            </span>
                        </Select.Option>
                    </Select>

                    <Modal
                        title="Select Date Range"
                        centered
                        open={open}
                        onCancel={() => setOpen(false)}
                        onOk={async () => {

                            setOpen(false);
                        }}
                    >
                        <RangePicker
                            value={dateRange}
                            onChange={handleDateChange}
                            format="YYYY-MM-DD"
                            disabledDate={(current) =>
                                current && current > moment().endOf("day")
                            }
                            style={{ color: "black !important" }}
                        />
                    </Modal>


                </div>

                <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
                    onClick={() => downloadReport()}
                >
                    {downloadReportLoading ? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} /> : "Download"}
                </Button>

            </Modal>
        </div>
    )
}

export default LeaveReportFullView