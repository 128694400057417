import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Table, DatePicker, Modal, Select, Spin } from "antd";
import CalenderIcon from "../../../../../assets/tracking/calendericon.svg";
import LocationIcon from "../../../../../assets/tracking/LocationIcomn.svg";
import TimeIcon from "../../../../../assets/tracking/time.svg";
import Add from "../../../../../assets/tracking/add.svg";
import fullviewmoile from "../../../../../assets/tracking/fullviewMoile.svg";
import request from "../../../../../utils/axios";
import fullView from "../../../../../assets/fullView.svg";
import { useLocation, useNavigate } from "react-router-dom";
import AttendMeeting from "../../../AttendMeeting/AttendMeeting";
import { toast } from "react-toastify";
import update from "immutability-helper";
import getScreenSize from "../../../../../utils/getScreenSize";
import MobileStyle from "./MoileStyle.module.scss";
const MyMeeting = ({ changeTabsValue, tabsValue }) => {
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const screenSize = getScreenSize();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [open, setOpen] = useState(false);
  const [attendPopup, setAtttendPop] = useState(false);
  const [attendRecord, setAtttendRecord] = useState({});
  const [days, setDays] = useState("");
  const [reporting_manager, setReporting_manager] = useState();
  const [tracking, setTrackikng] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4, // Number of items per page
    total: 0,
  });
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  useEffect(() => {
    let Reporting_Manager = localStorage?.getItem("Is_Reporting_Mannger");
    setReporting_manager(Reporting_Manager);
    LoadData();
  }, [days]);

  const LoadData = async () => {
    const apiUrl = `/tracking/employee/employee-meeting/details?page=${pagination.current}&page_size=${pagination.pageSize}&days=${days}`;

    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });
      setDataList(res.data?.pagination_data?.data);

      // console.log(res.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const handleDelete = async (record, index) => {
    console.log(record, index);
    setLoading(true);
    const apiUrl = `tracking/employee/employee-meeting/delete-meeting`;

    try {
      const res = await request({
        method: "post",
        url: apiUrl,
        data: {
          id: record.id,
        },
      });
      setLoading(false);
      toast.success(res.message, { position: "bottom-left" });
      const updatedArray = update(dataList, { $splice: [[index, 1]] });
      setDataList(updatedArray);
    } catch (error) {
      toast.error(error.message, { position: "bottom-left" });
      console.error("Error fetching data: ", error);
    }
  };
  function sendMessageToReactNative(id) {
    console.log(id);
    const AttendendPayload = {
      AttendMetting: "triggerAttendMeeting",
      MeetingId: id,
    };
    if (
      window.ReactNativeWebView &&
      typeof window.ReactNativeWebView.postMessage === "function"
    ) {
      window.ReactNativeWebView.postMessage(JSON.stringify(AttendendPayload));
    } else {
      console.warn("ReactNativeWebView is not defined.");
    }
  }
  const handleFilterSelectChange = (value) => {
    console.log("value", value);
    setDataList([]);
    if (value === "custom") {
      setOpen(true);
    } else {
      setOpen(false);
      setDays(value);
    }
  };
  const handleDateChange = (value) => {
    setDateRange(value);
  };
  const handleSelectChange = (value, record, index, key) => {
    console.log(value);
    if (value == "Attend") {
      setAtttendRecord({ ["index"]: index, ["key"]: key, ...record });
      setAtttendPop(true);
    }
    if (value == "Delete") {
      handleDelete(record, index);
    }
    if (value == "Edit") {
      navigate(`/tracking/editmeeting/mymeeting/${record.id}`);
    }
    // console.log(`selected ${value}`);
  };
  const handleAttendMeeting = async (longitude, latitude, record) => {
    console.log(record);
    const apiUrl = `tracking/employee/employee-meeting/marked?longitude=${longitude}&latitude=${latitude}&id=${record.id}`;

    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });

      const updatedArray = update(dataList, {
        [record.index]: {
          [record.key]: {
            $set: "Attended",
          },
        },
      });
      setDataList(updatedArray);
      toast.success(res.message, { position: "bottom-left" });

      // console.log(res);
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("You are not at Meeting Location", {
        position: "bottom-left",
      });
    } finally {
      setAtttendPop(false);
    }
  };
  const internalJobColumns = [
    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
      //   render: (text, record) => (
      //     <span>{moment(text).format("DD-MM-YYYY")}</span>
      //   ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Time Duration",
      dataIndex: "meeting_time",
      key: "meeting_time",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div>{text}</div>
          {/* <img src={LocationIcon} /> */}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          style={{
            backgroundColor: text == "Attended" ? "#D4EDDA" : "#FFDBDB",
            color: text == "Attended" ? "#28B15F" : "#FD3434",
            borderRadius: "24px",
            padding: "6px 15px",
            fontSize: "16px",
            font: "400",
            textAlign: "center",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (text, record, index) => {
        // Determine if the 'Attend' option should be disabled
        const isAttendDisabled = record.status === "Attended";

        return (
          <Select
            defaultValue="Select"
            style={{ width: 180 }}
            onChange={(value) =>
              handleSelectChange(value, record, index, "status")
            }
            options={[
              {
                value: "Attend",
                label: "Attend",
                disabled: isAttendDisabled, // Disable 'Attend' option if status is 'attended'
              },
              {
                value: "Delete",
                label: "Delete",
              },
              {
                value: "Edit",
                label: "Edit",
              },
            ]}
          />
        );
      },
    },
  ];
  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent.includes("MyAppWebView/1.0");
  };
  const handleAttend = async (record) => {
    if (isWebView()) {
      sendMessageToReactNative(record.id);
      setLoading(true);
    } else {
      navigate(`/tracking/attendmeeting/${record.id}`, {
        state: { record: record },
      });
    }
  };
  const handleSelectChangeMoible = (value, record, index, key) => {
    console.log(value, record, index, key);
    if (value == "Attend") {
      handleAttend(record);
    }
    if (value == "Delete") {
      handleDelete(record, index);
    }
    if (value == "Edit") {
      navigate(`/tracking/editmeeting/mymeeting/${record.id}`);
    }
  };
  const handleStartTracking = () => {
    console.log(localStorage?.getItem("employee_number"));
    const employee_id = localStorage?.getItem("employee_number");
    setTrackikng(!tracking);
    console.log("tracking start", employee_id);
  };
  return (
    <>
      {screenSize.width < 768 ? (
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vw",
              }}
            >
              {" "}
              <Spin size="large" />
            </div>
          ) : (
            <div className={MobileStyle.container}>
              <div className={MobileStyle.headerContainer}>
                <h2>My Meeting</h2>
                <div className={MobileStyle.headerleftcontainer}>
                  {/* <div
                    className={MobileStyle.filterdiv}
                    onClick={() => {
                      setOpen(true);
                    }}>
                    <img src={CalenderIcon} />
                    {filterDate.length > 0
                      ? `${filterDate[0]} To ${filterDate[1]}`
                      : 'Today'}
                  </div> */}
                  <Select
                    style={{ width: 120, height: 44 }}
                    placeholder="Select an option"
                    onChange={handleFilterSelectChange}
                    value={days}
                  >
                    <Option value="" disabled>
                      Select Dates
                    </Option>
                    <Option value={7}>Last 7 days</Option>
                    <Option value={14}>Last 14 days</Option>
                    <Option value={30}>Last 30 days</Option>
                    <Option value="custom">
                      <span
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Custom Date
                      </span>
                    </Option>
                  </Select>
                  {reporting_manager && (
                    <img
                      src={Add}
                      alt="add"
                      onClick={() => {
                        navigate("/tracking/addmeeting/mymeeting", {
                          state: { MeetingType: "MyMeeting" },
                        });
                      }}
                    />
                  )}
                  {tabsValue != "4" && tabsValue != "1" && (
                    <img
                      src={fullView}
                      alt="fullviewmoile"
                      className="fullview"
                      onClick={() => {
                        changeTabsValue("4");
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={MobileStyle.contentContainer}>
                <div
                  id="scrollableDiv"
                  style={{
                    height: "auto",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                >
                  {dataList.map((item, index) => {
                    return (
                      <div className={MobileStyle.meetingDiv} key={index}>
                        <div className={MobileStyle.top}>
                          <div className={MobileStyle.namecontainer}>
                            <div className={MobileStyle.name}>
                              {item.client_name}
                            </div>
                            <div
                              className={MobileStyle.status}
                              style={{
                                backgroundColor:
                                  item?.status == "Attended"
                                    ? "#D4EDDA"
                                    : "#FFDBDB",
                                color:
                                  item.status == "Attended"
                                    ? "#28B15F"
                                    : "#FD3434",
                              }}
                            >
                              {item.status}
                            </div>
                          </div>
                          <div className={MobileStyle.selectcontainer}>
                            <Select
                              defaultValue="Select"
                              style={{ width: 120, height: 44 }}
                              onChange={(value) =>
                                handleSelectChangeMoible(
                                  value,
                                  item,
                                  index,
                                  "status"
                                )
                              }
                              options={[
                                {
                                  value: "Attend",
                                  label: "Attend",
                                  // Disable 'Attend' option if status is 'attended'
                                },
                                {
                                  value: "Delete",
                                  label: "Delete",
                                },
                                {
                                  value: "Edit",
                                  label: "Edit",
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className={MobileStyle.bottom}>
                          <div className={MobileStyle.dateContainer}>
                            <img src={CalenderIcon} alt="calendericon" />
                            <span>
                              {moment(item.date).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className={MobileStyle.duration}>
                            {" "}
                            <img src={TimeIcon} alt="calendericon" />
                            <span>{item.meeting_time}</span>
                          </div>
                          <div className={MobileStyle.containerlocation}>
                            <div className={MobileStyle.leftcontainer}>
                              <img src={LocationIcon} alt="locationicon" />
                              <div className={MobileStyle.location}>
                                <div>{item.location}</div>
                                {/* <div>Gurugram, Haryana 122018</div> */}
                              </div>
                            </div>
                            <div className={MobileStyle.rightcontainer}>
                              {/* <img src={LocationIcon} alt="locationicon" /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Modal
                title="Select Date Range"
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={async () => {
                  const page = {
                    current: 1,
                    pageSize: 4,
                    total: 0,
                  }; // reset pagination

                  const apiUrl = `/tracking/employee/employee-meeting/details?page=${
                    page.current
                  }&page_size=${page.pageSize}&from_date=${dateRange[0]?.format(
                    "YYYY-MM-DD"
                  )}&to_date=${dateRange[1]?.format("YYYY-MM-DD")}`;

                  const res = await request({
                    method: "get",
                    url: apiUrl,
                  });
                  setDataList(res.data?.pagination_data?.data);

                  setOpen(false);
                }}
              >
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </Modal>
            </div>
          )}
        </>
      ) : (
        <div style={{ background: "white", padding: "30px 30px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // border: "1px solid red",
              // padding: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#161616",
                }}
              >
                My Meeting
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => handleStartTracking()}
                  style={{
                    backgroundColor: "#008080",
                    color: "#fff",
                    fontSize: "16px",
                    font: 400,
                    borderRadius: "5px",
                    padding: "9px 40px",
                    display: "flex",
                    gap: "10px",
                    justifyCointent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {!tracking ? "Start Tracking" : "Stop Tracking"}
                </div>
                <div
                  style={{
                    backgroundColor: "#008080",
                    color: "#fff",
                    fontSize: "16px",
                    font: 400,
                    borderRadius: "5px",
                    padding: "9px 40px",
                    display: "flex",
                    gap: "10px",
                    justifyCointent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/tracking/addmeeting/mymeeting", {
                      state: { MeetingType: "MyMeeting" },
                    });
                  }}
                >
                  Add Meeting
                </div>

                <Select
                  style={{ width: 120, height: 44 }}
                  placeholder="Select an option"
                  onChange={handleFilterSelectChange}
                  value={days}
                >
                  <Option value="" disabled>
                    Select Dates
                  </Option>
                  <Option value={7}>Last 7 days</Option>
                  <Option value={14}>Last 14 days</Option>
                  <Option value={30}>Last 30 days</Option>
                  <Option value="custom">
                    <span
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Custom Date
                    </span>
                  </Option>
                </Select>
                {tabsValue != "4" && tabsValue != "1" && (
                  <img
                    src={fullView}
                    alt="fullView"
                    width={40}
                    height={40}
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("4");
                    }}
                  />
                )}
              </div>
            </div>
            <div
              id="scrollableDiv"
              style={{
                height: "auto",
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Table
                columns={internalJobColumns}
                dataSource={dataList}
                pagination={false}
              />
            </div>

            {attendPopup && (
              <AttendMeeting
                attendPopup={attendPopup}
                attendRecord={attendRecord}
                setAtttendPop={setAtttendPop}
                handleAttendMeeting={handleAttendMeeting}
              />
            )}
          </div>
          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {
              const page = {
                current: 1,
                pageSize: 4,
                total: 0,
              }; // reset pagination

              const apiUrl = `/tracking/employee/employee-meeting/details?page=${
                page.current
              }&page_size=${page.pageSize}&from_date=${dateRange[0]?.format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1]?.format("YYYY-MM-DD")}`;

              const res = await request({
                method: "get",
                url: apiUrl,
              });
              setDataList(res.data?.pagination_data?.data);

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default MyMeeting;
