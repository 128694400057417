import React, { useEffect, useState } from 'react';
import styles from "./NewJoinesAndExit.module.scss"
import { Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import request from '../../../../../utils/axios';
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

function NewJoinesAndExit({ processingDate, setSelectProcessingType, reviewed, fetchReviewDetails }) {
    const selectedEmployees = useSelector((state) => state.selectedEmployeePayrollProcessing);
    const [newJoinersReview, setNewJoinersReview] = React.useState([])
    const [fullAndFinalsettlementReview, setfullAndFinalSettlementReview] = React.useState([])
    const [allEmployesSalarySummary, setAllEmployesSalarySummary] = React.useState([])
    const [hasMore1, setHasMore1] = useState(true);
    const [hasMore2, setHasMore2] = useState(true);
    const [hasMore3, setHasMore3] = useState(true);

    const [newJoinersPagination, setNewJoinersPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const fetchNewJoinersData = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/new-joiners-data-review?page=${newJoinersPagination.current}&page_size=${newJoinersPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            setNewJoinersReview([...newJoinersReview, ...data.pagination_data.data])
            // if (newJoinersPagination.total !== data.pagination_data.total) {
            setNewJoinersPagination({
                ...newJoinersPagination,
                total: data.pagination_data.total,
                current: newJoinersPagination.current + 1
            });
            // }

            if (data?.pagination_data?.next === null) {
                setHasMore1(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore1(false);
        }
    }
    // useEffect(() => {
    //     fetchNewJoinersData();
    // }, [newJoinersPagination.current])

    const [fullAndFinalsettlementPagination, setFullAndFinalsettlementPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const fetchFullAndFinalSettlementData = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/full-final-settlement-review?page=${fullAndFinalsettlementPagination.current}&page_size=${fullAndFinalsettlementPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            setfullAndFinalSettlementReview([
                ...fullAndFinalsettlementReview,
                ...data.pagination_data.data
            ])
            // if (fullAndFinalsettlementPagination.total !== data.pagination_data.total) {
            setFullAndFinalsettlementPagination({
                ...fullAndFinalsettlementPagination,
                total: data.pagination_data.total,
                current: fullAndFinalsettlementPagination.current + 1
            });
            // }

            if (data?.pagination_data?.next === null) {
                setHasMore2(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore2(false);
        }
    }

    const [payoutSummaryPagination, setPayoutSummaryPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })


    const fetchEmployeesSalarySummaryData = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/summary-review?page=${payoutSummaryPagination.current}&page_size=${payoutSummaryPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })

            setAllEmployesSalarySummary([
                ...allEmployesSalarySummary,
                ...data.pagination_data.data
            ])
            // if (fullAndFinalsettlementPagination.total !== data.pagination_data.total) {
            setPayoutSummaryPagination({
            ...payoutSummaryPagination,
            total: data.pagination_data.total,
            current: payoutSummaryPagination.current + 1
            });
            if (data?.pagination_data?.next === null) {
                setHasMore3(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore3(false);
        }
    }


    const [shouldFetch1, setShouldFetch1] = useState(false);
    const [shouldFetch2, setShouldFetch2] = useState(false);
    const [shouldFetch3, setShouldFetch3] = useState(false);

    useEffect(() => {
        // 1
        setNewJoinersPagination({
            ...newJoinersPagination,
            current: 1
        });
        setNewJoinersReview([]);
        setHasMore1(true);
        setShouldFetch1(true);

        // 2
        setFullAndFinalsettlementPagination({
            ...fullAndFinalsettlementPagination,
            current: 1
        });
        setfullAndFinalSettlementReview([]);
        setHasMore2(true);
        setShouldFetch2(true);
        // 3
        setPayoutSummaryPagination({
            ...payoutSummaryPagination,
            current: 1
        });
        setAllEmployesSalarySummary([]);
        setHasMore3(true);
        setShouldFetch3(true);

    }, [processingDate]);

    useEffect(() => {
        if (shouldFetch1) {
            fetchNewJoinersData();
            setShouldFetch1(false);
        }
        if (shouldFetch2) {
            fetchFullAndFinalSettlementData();
            setShouldFetch2(false);
        }
        if (shouldFetch3) {
            fetchEmployeesSalarySummaryData();
            setShouldFetch3(false);
        }
        // fetchAllEmployeesSalarySummaryData();
    }, [shouldFetch1, shouldFetch2, shouldFetch3]);


    const newJoinersColumns = [
        {
            title: "Employee",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Date Of Joining",
            dataIndex: "joining_date",
            key: "joining_date",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_salary",
            key: "gross_salary",
        },
        {
            title: "Net Payable",
            dataIndex: "payout",
            key: "payout",
        },
        {
            title: "Loss Of Pay",
            dataIndex: "LOP",
            key: "LOP",
        },
        {
            title: "Payable Days",
            dataIndex: "payable_days",
            key: "payable_days",
        },
        {
            title: "Total Leaves",
            dataIndex: "absent_count",
            key: "absent_count",
        },
        {
            title: "Approved Leaves",
            dataIndex: "approval_leave_count",
            key: "approval_leave_count",
        },
        // {
        //     title: "Payout",
        //     dataIndex: "payout",
        //     key: "payout",
        // },
        // {
        //     title: "Action",
        //     dataIndex: "action",
        //     key: "action",
        // },
    ]
    const fullAndFinalSettlementColumns = [
        {
            title: "Employee",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        // {
        //     title: "Resignation",
        //     dataIndex: "resignation_date",
        //     key: "resignation_date",
        // },
        {
            title: "Last Working Day",
            dataIndex: "last_working_date",
            key: "last_working_date",
        },
        {
            title: "Payable Days",
            dataIndex: "payable_days",
            key: "payable_days",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_salary",
            key: "gross_salary",
        },
        {
            title: "Net Payable",
            dataIndex: "payout",
            key: "payout",
        },
        {
            title: "No. Of Working Days",
            dataIndex: "total_working_days",
            key: "total_working_days",
        },
        {
            title: "Total Leaves",
            dataIndex: "absent_count",
            key: "absent_count",
        },
        // {
        //     title: "Payout",
        //     dataIndex: "payout",
        //     key: "payout",
        // },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
    ]
    const summaryColumns = [
        {
            title: "Employee",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_salary",
            key: "gross_salary",
        },
        {
            title: "Final Payout",
            dataIndex: "final_payout",
            key: "final_payout",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },

    ]
    const newjoinesAndExitTabs = [
        {
            name: "New Joines",
            key: "newJoinesReview",
            value: 1,
            reviewed: reviewed?.new_joines
        },
        {
            name: "Full & Final Settlement",
            key: "fullAndFinalSettlement",
            value: 2,
            reviewed: reviewed?.full_and_final
        },
        {
            name: "Summary",
            key: "summary",
            value: 3,
            reviewed: reviewed?.summary
        }
    ]
    const [selectTab, setSelectTab] = useState(1)

    const payrollSummaryReviewed = async () => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-review-history`,
                data: {
                    payroll_review_tab: "newJoineesAndExits",
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
            setSelectProcessingType("bonusSalaryRevision")
        }
        catch (error) {
            console.log(error)
        }
    }

    const payrollSummaryChild = async (childName) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-tabs-history`,
                data: {
                    parent_tab: "newJoineesAndExits",
                    child_tab: childName,
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
        }
        catch (error) {
            console.log(error)
        }
    }

    const renderUI1 = (dataList) => {
        return <Table
            columns={newJoinersColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI2 = (dataList) => {
        return <Table
            columns={fullAndFinalSettlementColumns}
            dataSource={dataList}
            pagination={false}
        />
    }


    const renderUI3 = (dataList) => {
        return <Table
            columns={summaryColumns}
            dataSource={dataList}
            pagination={false}
        />
    }


    return (
        <div className={styles.newJoinesAndExitContainer}>
            <div className={styles.container}>
                <div className={styles.tabButtonContainer}>
                    {
                        newjoinesAndExitTabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={
                                        item?.reviewed
                                            ?
                                            styles.viewedTabButton + " " + styles.tabButton
                                            :
                                            selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}

                                >
                                    <div className={
                                        item?.reviewed
                                            ?
                                            styles.viewedIndexBox
                                            :
                                            selectTab === index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                item?.reviewed
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    // selectTab > index + 1
                                                    //     ?
                                                    //     <CheckOutlined  />
                                                    //     :
                                                    item.value
                                            }
                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            {/* <CommonTable
                                columns={newJoinersColumns}
                                data={newJoinersReview}
                                pagination={newJoinersPagination}
                                setPagination={setNewJoinersPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("new_joines")
                                        setSelectTab(2)
                                    }}>Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={newJoinersPagination.total - (newJoinersReview?.length)}
                                    next={fetchNewJoinersData}
                                    hasMore={hasMore1}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI1(newJoinersReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 2
                        &&
                        <>

                            {/* <CommonTable
                                columns={fullAndFinalSettlementColumns}
                                data={fullAndFinalsettlementReview}
                                pagination={fullAndFinalsettlementPagination}
                                setPagination={setFullAndFinalsettlementPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setSelectTab(1)}
                                >Previous</button>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("full_and_final")
                                        setSelectTab(3)
                                    }}>Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={newJoinersPagination.total - (fullAndFinalsettlementReview?.length)}
                                    next={fetchFullAndFinalSettlementData}
                                    hasMore={hasMore2}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI2(fullAndFinalsettlementReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 3
                        &&
                        <>

                            {/* <CommonTable
                                columns={summaryColumns}
                                data={fullAndFinalsettlementReview}
                                pagination={fullAndFinalsettlementPagination}
                                setPagination={setFullAndFinalsettlementPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        setSelectTab(2)
                                    }}>Previous</button>

                                <button className={styles.btn} onClick={() => {
                                    payrollSummaryChild("summary")
                                    payrollSummaryReviewed()
                                }}>
                                    Submit
                                </button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={newJoinersPagination.total - (allEmployesSalarySummary?.length)}
                                    next={fetchEmployeesSalarySummaryData}
                                    hasMore={hasMore3}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI3(allEmployesSalarySummary)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
            </div>


        </div>
    )

}
export default NewJoinesAndExit