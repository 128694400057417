import {
  DownOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  theme,
  Tooltip,
  TreeSelect,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import fullView from "../../../../assets/fullView.svg";
import edit from "../../../../assets/edit.svg";

import "./Dashboard.scss";
import { useNavigate, useParams } from "react-router-dom";
import downloadImg from "../../../../assets/downlaod.svg";
import getScreenSize from "../../../../utils/getScreenSize";
import request from "../../../../utils/axios";
import dayjs from "dayjs";
import update from "immutability-helper";
import moment from "moment";
import date from "../../../../assets/Attendance/Admin/date.svg";
import LeaveReportSearchBar from "../LeaveReportSearchBar/LeaveReportSearchBar";
import { toast } from "react-toastify";

// leave type form,
import annual from "../../../../assets/Leave/leaveType/annual.svg";
import approvalRequired from "../../../../assets/Leave/leaveType/approvalRequired.svg";
import contractual from "../../../../assets/Leave/leaveType/contractual.svg";
import coolingOffPeriod from "../../../../assets/Leave/leaveType/coolingOffPeriod.svg";
import disallowed from "../../../../assets/Leave/leaveType/disallowed.svg";
import documentUpload from "../../../../assets/Leave/leaveType/documentUpload.svg";
import encashment from "../../../../assets/Leave/leaveType/encashment.svg";
import female from "../../../../assets/Leave/leaveType/female.svg";
import halfDay from "../../../../assets/Leave/leaveType/halfDay.svg";
import male from "../../../../assets/Leave/leaveType/male.svg";
import married from "../../../../assets/Leave/leaveType/married.svg";
import maxAllowed from "../../../../assets/Leave/leaveType/maxAllowed.svg";
import month from "../../../../assets/Leave/leaveType/month.svg";
import newJoinee from "../../../../assets/Leave/leaveType/newJoinee.svg";
import notice from "../../../../assets/Leave/leaveType/notice.svg";
import onceDuringEmployment from "../../../../assets/Leave/leaveType/onceDuringEmployment.svg";
import other from "../../../../assets/Leave/leaveType/other.svg";
import permanent from "../../../../assets/Leave/leaveType/permanent.svg";
import proRata from "../../../../assets/Leave/leaveType/proRata.svg";
import single from "../../../../assets/Leave/leaveType/single.svg";
import waitingPeriod from "../../../../assets/Leave/leaveType/waitingPeriod.svg";
import weeklyOff from "../../../../assets/Leave/leaveType/weeklyOff.svg";
import casualleaves from "../../../../assets/casualleaves.svg";
import Dragger from "antd/es/upload/Dragger";
import arrowCircle from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg";
import searchImage from "../../../../assets/searchImage.svg";
import time from "../../../../assets/time.svg";
import clone_icon from "../../../../assets/clone_icon.svg";
import LeaveDashboardView from "../DashboardView/DashboardView";
import EmployeeLeavesFullView from "../EmployeeLeavesFullView/EmployeeLeavesFullView";
import LeavePolicyFullView from "../LeavePolicyFullView/LeavePolicyFullView";
import LeaveReportFullView from "../LeaveReportFullView/LeaveReportFullView";
import LeaveTypeFullView from "../LeaveTypeFullView/LeaveTypeFullView";
import HolidayFullView from "../HolidayFullView/HolidayFullView";
import LeavePolicyManagerFullView from "../LeavePolicyManagerFullView/EmployeeLeavesFullView";
import CompOffFullView from "../CompOffFullView/CompOffFullView";
import AdminLeaveTypeFullView from "../AdminLeaveTypeFullView/AdminLeaveTypeFullView";
import LeaveTypeForm from "../LeaveTypeForm/LeaveTypeForm";
import ContainerLayout from "../../../../component/ContainerLayout/ContainerLayout"

function Dashboard() {
  const screenSize = getScreenSize();
  const changeTabsValue = (e) => {
    console.log("e", e);
    setTabsValue(e)
  }
  const params = useParams()
  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <ContainerLayout><LeaveDashboardView changeTabsValue={changeTabsValue} /></ContainerLayout>,
    },
    {
      key: '2',
      label: 'Employee Leaves',
      children: <ContainerLayout><EmployeeLeavesFullView /></ContainerLayout>
    },
    {
      key: '3',
      label: 'Leave policy',
      children: <ContainerLayout><LeavePolicyFullView /></ContainerLayout>,
    },
    {
      key: '4',
      label: 'Leave report',
      children: <ContainerLayout><LeaveReportFullView /></ContainerLayout>,
    },
    {
      key: '5',
      label: 'Employee Leave Manager',
      children: <ContainerLayout><LeavePolicyManagerFullView /></ContainerLayout>,
    },
    {
      key: '6',
      label: 'Holidays',
      children: <ContainerLayout><HolidayFullView /></ContainerLayout>,
    },
    {
      key: '7',
      label: 'Compensatory Leaves',
      children: <ContainerLayout><CompOffFullView /></ContainerLayout>,
    },
    {
      key: '8',
      label: 'Leave types',
      children: <LeaveTypeForm changeTabsValue={changeTabsValue} />
    }
    // {
    //   key: '7',
    //   label: 'Leave types',
    //   children: <LeaveTypeFullView />,
    // },
  ];
  const mweb_items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <ContainerLayout><LeaveDashboardView changeTabsValue={changeTabsValue} /></ContainerLayout>,
    },
    {
      key: '2',
      label: 'Employee Leaves',
      children: <ContainerLayout><EmployeeLeavesFullView /></ContainerLayout>
    },
    {
      key: '3',
      label: 'Leave policy',
      children: <ContainerLayout><LeavePolicyFullView /></ContainerLayout>,
    },
    {
      key: '4',
      label: 'Leave report',
      children: <ContainerLayout><LeaveReportFullView /></ContainerLayout>,
    },
    {
      key: '5',
      label: 'Employee Leave Manager',
      children: <ContainerLayout><LeavePolicyManagerFullView /></ContainerLayout>,
    },
    {
      key: '6',
      label: 'Holidays',
      children: <ContainerLayout><HolidayFullView /></ContainerLayout>,
    },
    {
      key: '7',
      label: 'Comp Off',
      children: <ContainerLayout><CompOffFullView /></ContainerLayout>,
    },
    {
      key: '8',
      label: 'Leave types',
      children: <ContainerLayout><AdminLeaveTypeFullView /></ContainerLayout>,
    },
  ];

  const [tabsValue, setTabsValue] = useState("1")


  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTabsValue(params?.componentId)
  }, []);

  return (
    <div className="" ref={ref}>
      {screenSize.width < 768 ? (
        // Mobile View
        <Tabs
          activeKey={tabsValue}
          items={mweb_items}
          onChange={changeTabsValue}
        />
      ) : (
        <>
          <Tabs
            activeKey={tabsValue}
            items={items}
            onChange={changeTabsValue}
          />
        </>
      )}
    </div>
  );
}

export default Dashboard;
