import React, { useState, useEffect } from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  InfoWindow,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import duration from "../../../../assets/Attendance/Admin/duration.svg";
import mylocation from "../../../../assets/location.svg";
// import Leaflet from "leaflet";
// import "leaflet/dist/leaflet.css";
import Search from "../../SearchBar/Search";
import request from "../../../../utils/axios";
import getScreenSize from "../../../../utils/getScreenSize";
import MobileStyle from "./MobileStyle.module.scss";
import { Switch, Table, Spin, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
function Location() {
  const screenSize = getScreenSize();
  const [employeeList, setEmployeeList] = useState([]);
  const [tabs, setTabs] = useState(1);
  const [activeMarker, setActiveMarker] = useState(null); // State to manage which marker is active
  const [coordinates, setCoordinates] = useState([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (coordinates.length >= 1) {
      // Initialize DirectionsService to calculate the route
      const directionsService = new window.google.maps.DirectionsService();

      const origin = coordinates[0]; // Start from the first coordinate
      const destination = coordinates[coordinates.length - 1]; // End at the last coordinate
      const waypoints = coordinates.slice(1, -1).map((point) => ({
        location: { lat: point.lat, lng: point.lng },
        stopover: true,
      }));

      // Create a request object for the route
      const request = {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING, // Driving mode for road route
      };

      // Call DirectionsService to get the route
      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
        } else {
          console.error("Directions request failed due to " + status);
        }
      });
    } else {
      setDirectionsResponse(null);
    }
  }, [coordinates]);
  const loadData = async () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const apiUrl = `tracking/admin/employee/tracking-list?date=${currentDate}`;
    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });
      console.log(res.data);
      setEmployeeList(res.data);
      console.log(res.data[0].coordinates);
      setCoordinates(res.data[0].coordinates);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleTrackLocation = (item) => {
    console.log("item", item);
    setCoordinates(item.coordinates);
  };

  return (
    <div style={{ background: "white" }}>
      {screenSize.width < 768 ? (
        // moible View
        <div className={MobileStyle.container}>
          <h2>Team Location</h2>

          <div className={MobileStyle.search}>
            <Search />
          </div>
          {tabs == 1 && (
            <div className={MobileStyle.EmployeeDiv}>
              {employeeList?.map((item, index) => {
                return (
                  <div key={index} className={MobileStyle.employee_element}>
                    <div className={MobileStyle.top}>
                      <div className={MobileStyle.name}>
                        {item.employee_name}
                      </div>
                      <div className={MobileStyle.buttonContainer}>
                        <button
                          onClick={() => {
                            setTabs(2);
                          }}
                        >
                          Track
                        </button>
                      </div>
                    </div>
                    <div className={MobileStyle.middle}>
                      <img
                        src={duration}
                        alt="duration"
                        width={18}
                        height={18}
                      />
                      <span>Sales manager</span>
                    </div>
                    <div className={MobileStyle.bottom}>
                      <img
                        src={mylocation}
                        alt="mylocation"
                        width={18}
                        height={18}
                      />
                      <span>
                        BLOCK-F, F61, South City II, Sector 50, Gurugram,
                        Haryana 122018
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {tabs == 2 && (
            <div className={MobileStyle.mapContainer}>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  center={coordinates && coordinates[0]} // Center the map around the first coordinate
                  zoom={6}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            height: "80%",
            gridTemplateColumns: "1fr 2fr",
          }}
        >
          <div
            style={{
              padding: "30px",
              borderRadius: "5px",

              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div
              style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}
            >
              Employees
            </div>
            <div>
              {" "}
              <Search />
            </div>
            <div
              style={{
                overflow: "scroll",
                height: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {employeeList.map((item, index) => {
                return (
                  <div key={index} style={{ width: "25vw" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#161616",
                          margin: "0",
                        }}
                      >
                        {item.employee_name}
                      </p>
                      <button
                        style={{
                          border: "1px solid #008080",
                          background: "#008080",
                          cursor: "pointer",
                          color: "white",
                          height: "36px",
                          width: "80px",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          handleTrackLocation(item);
                        }}
                      >
                        Track
                      </button>
                    </div>
                    <div>
                      <img
                        src={duration}
                        alt="duration"
                        width={18}
                        height={18}
                        style={{ marginRight: "10px" }}
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#616161",
                        }}
                      >
                        {item.designation}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={mylocation}
                        alt="mylocation"
                        width={18}
                        height={18}
                        style={{ marginRight: "10px" }}
                      />
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#616161",
                        }}
                      >
                        {item.location}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {" "}
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={{ height: "550px", width: "100%" }}
                center={coordinates && coordinates[0]} // Center the map around the first coordinate
                zoom={6}
              >
                {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}
              </GoogleMap>
            </LoadScript>
          </div>
          {/* <MapContainer
            center={[28.7041, 77.1025]}
            zoom={10}
            style={containerStyle}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {peopleLocations.map((person, index) => (
              <Marker
                key={index}
                position={[person.lat, person.lng]}
                icon={customIcon}
              >
                <Popup>
                  <strong>{person.name}</strong>
                  <br />
                  Role: {person.role}
                </Popup>
              </Marker>
            ))}
          </MapContainer> */}
        </div>
      )}
    </div>
  );
}

export default Location;
