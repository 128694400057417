import React, { useState } from 'react';
import like from "../../../../assets/Feeds/like.png";
import comment from "../../../../assets/Feeds/comment.png";
import heart from "../../../../assets/Feeds/heart.png";
import good from "../../../../assets/Feeds/good.png";
import party from "../../../../assets/Feeds/party.png";
import funny from "../../../../assets/Feeds/funny.png";
import { Input, Popover } from 'antd';
import getScreenSize from '../../../../utils/getScreenSize'
import request from '../../../../utils/axios';
import reaction from "../../../../assets/Feeds/Admin/like.png";

function Post({ item }) {
  const screenSize = getScreenSize();
  const { TextArea } = Input;
  const reactionList = [
    {
      image: heart,
      value: 1
    },
    {
      image: good,
      value: 2
    },
    {
      image: party,
      value: 3
    },
    {
      image: funny,
      value: 4
    }
  ]

  const likeContent = (
    <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
      {
        reactionList.map((reactionItem, index) => (
          <img src={reactionItem.image} alt={reactionItem.image} width={25} height={25}
            key={index}
            onClick={() => addReaction(reactionItem.value, item.id)}
          />
        ))
      }
    </div>
  )

  const [showComment, setShowComment] = useState(false)
  const [commentDetails, setCommentDetails] = useState({
    comment: "",
    post: item.id
  });

  const reactOnFeed = async (id) => {
    try {
      await request({
        method: "POST",
        url: `feeds/post/react?post=${id}`,
        data: {
          feed_id: 1
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const addComment = async () => {
    try {
      await request({
        method: "POST",
        url: `feeds/employee/post/add-post-comments/`,
        data: commentDetails
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const addReaction = async (reactionType, postId) => {
    try {
      await request({
        method: "POST",
        url: `feeds/employee/post/add-post-reactions/`,
        data: {
          post: postId,
          reaction_type: reactionType
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {
        screenSize.width < 768
          ?
          <div style={{ padding: "0px 16px", marginTop: "20px" }}>
            <div style={{ border: "1px solid #F2F2F2", padding: "7px" }}>
              <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                <img src="https://picsum.photos/200" alt="" width={50} height={50} style={{ borderRadius: "5px" }} />
                <div>
                  <p style={{ color: "#008080", fontSize: "18px", fontWeight: "600", margin: "0px", marginBottom: "5px" }}>Davis Curtis</p>
                  <p style={{ fontSize: "16px", color: "#A8A8A8", margin: "0px" }}>HR Manager</p>
                </div>
              </div>
              <p
                style={{
                  color: "#727272", fontSize: "12px", fontWeight: "400", lineHeight: "18px", marginTop: "20px"
                }}
              >Figma ipsum component variant main layer. Edit edit background underline layer overflow ellipse. Overflow bullet link scale italic. Reesizing figma horizontal editor figjam.</p>

              <img src="https://picsum.photos/800" alt="" height={300} style={{ width: "100%", borderRadius: "5px" }} />

            </div>
            <div style={{ border: "1px solid #F2F2F2", display: "flex", justifyContent: "space-around", padding: "10px 0px" }}>
              <Popover placement="top" content={likeContent} arrow={false}>
                <p style={{ fontSize: "16px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}><img src={like} alt="like" width={16} height={14} /> Like</p>
              </Popover>

              <p style={{ fontSize: "16px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}
                onClick={() => setShowComment(!showComment)}
              ><img src={comment} alt="comment" width={16} height={14} />Comment</p>
            </div>

            {/* comment */}
            {
              showComment
              &&
              <div style={{ border: "1px solid #F2F2F2", padding: "20px" }}>
                <div style={{ display: "flex", gap: "10px", alignItems: "flex-start", width: "100%" }}>
                  <img src="https://picsum.photos/200" alt="" width={22} height={22} style={{ borderRadius: "5px" }} />
                  <div style={{ width: "100%", border: "1px solid #F3F3F3", padding: "10px", background: "#FAFAFA" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", }}>
                      <p style={{ color: "#008080", fontSize: "14px", fontWeight: "500", margin: "0", marginBottom: "5px" }}>Davis Curtis</p>
                      <p style={{ color: "#A8A8A8", fontSize: "12px", margin: "0px" }}>1 Hr</p>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px", color: "#A8A8A8", margin: "0px", marginBottom: "5px" }}>HR Manager</p>
                      <p style={{ fontSize: "12px", color: "#727272", margin: "0px" }}>Lorem ipsum dolor sit amet consectetur. Fermentum nunc auctor imperdiet ullamcorper proin sed sociis. </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          :
          <div>
            <div style={{ border: "1px solid #F2F2F2", padding: "20px" }}>
              <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                <img src={item?.employee_image} alt="" width={66} height={66} style={{ borderRadius: "5px" }} />
                <div>
                  <p style={{ color: "#008080", fontSize: "22px", fontWeight: "600", margin: "0px", marginBottom: "5px" }}>{item?.added_by}</p>
                  <p style={{ fontSize: "16px", color: "#A8A8A8", margin: "0px" }}>{item?.designation}</p>
                </div>
              </div>
              <p
                style={{
                  color: "#727272", fontSize: "18px", fontWeight: "400", lineHeight: "27px", marginTop: "20px"
                }}
              >{item?.text}</p>

              <img src={item.image} alt="" width={600} height={400} style={{ borderRadius: "5px", objectFit: "contain" }} />
              <div style={{ marginTop: "15px", color: "#B0B0B0", fontSize: "20px" }}>
                <img src={reaction} alt="reaction" /><span>{item?.reaction_count}</span>
                <span style={{ marginLeft: "30px" }}>{item.comments_count} Comments</span>
              </div>
            </div>
            <div style={{ border: "1px solid #F2F2F2", display: "flex", justifyContent: "space-around", padding: "20px" }}>
              <Popover placement="top" content={likeContent} arrow={false}>
                <p style={{ fontSize: "20px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}><img src={like} alt="like" width={25} height={25} /> Like</p>
              </Popover>

              <p style={{ fontSize: "20px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}
                onClick={() => setShowComment(!showComment)}
              ><img src={comment} alt="comment" width={25} height={25} /> Comment</p>
            </div>
            {/* comment */}
            {
              showComment
              &&
              <div style={{ border: "1px solid #F2F2F2", padding: "20px" }}>
                <div style={{ border: "1px solid #F3F3F3", padding: "0px", background: "#FAFAFA", borderRadius: "5px" }}>
                  <TextArea placeholder="Write..." rows={4}
                    onChange={(e) => setCommentDetails({
                      ...commentDetails,
                      comment: e.target.value
                    })}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "15px" }}>
                    <button style={{ color: "white", fontSize: "16px", fontWeight: "500", border: "none", background: "#008080", padding: "10px", borderRadius: "5px", marginTop: "10px" }}
                      onClick={() => addComment()}
                    >Comment</button>
                  </div>
                </div>
                {
                  item?.comments.map((el, elIndex) => {
                    return (
                      <div style={{ display: "flex", gap: "10px", alignItems: "flex-start", width: "100%", marginTop: "20px" }}>
                        <img src={el.employee_profile} alt="" width={34} height={34} style={{ borderRadius: "5px" }} />
                        <div style={{ width: "100%", border: "1px solid #F3F3F3", padding: "10px", background: "#FAFAFA" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", }}>
                            <p style={{ color: "#008080", fontSize: "20px", fontWeight: "500", margin: "0", marginBottom: "5px" }}>{el?.commented_by}</p>
                            <p style={{ color: "#A8A8A8", fontSize: "16px", margin: "0px" }}>1 Hr</p>
                          </div>
                          <div>
                            <p style={{ fontSize: "16px", color: "#A8A8A8" }}>HR Manager</p>
                            <p style={{ fontSize: "16px", color: "#727272", margin: "0px" }}>{el.comment}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            }

          </div>
      }
    </div>
  )
}

export default Post