import React, { useEffect, useState } from "react";
import "./Sepration.scss";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Steps } from "antd";
import getScreenSize from "../../../../utils/getScreenSize";
import MobileStyle from "./mwebsepration.module.scss";
import request from "../../../../utils/axios";
const Sepration = () => {
  const screenSize = getScreenSize();
  const [openDropDawn, setOpenDropDawn] = useState(false);
  const [current, setCurrent] = useState(null);
  const [details, setDetails] = useState({});
  const getStatus = async () => {
    const apiUrl = `employee/resignation/insight`;

    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });
      console.log(res.data);
      setDetails(res.data);
      setCurrent(res.data.status);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    getStatus();
  }, []);
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: screenSize.width < 768 ? "10px 10px" : "20px 20px",
        marginBottom: "20px",
      }}
    >
      {screenSize.width < 768 ? (
        <div>
          <div className={MobileStyle.contentlayout}>
            <div className={MobileStyle.top}>
              <div className={MobileStyle.left}>
                <div className={MobileStyle.topictext}>Separation Approval</div>
                <div
                  className={MobileStyle.status}
                  style={{
                    backgroundColor: details?.is_pending
                      ? "#fa4141"
                      : "#008080",
                  }}
                >
                  {details?.is_pending ? "In Process" : "Completed"}
                </div>
              </div>
              <div className={MobileStyle.right}>
                {openDropDawn ? (
                  <UpOutlined
                    onClick={() => {
                      setOpenDropDawn(!openDropDawn);
                    }}
                  />
                ) : (
                  <DownOutlined
                    onClick={() => {
                      setOpenDropDawn(!openDropDawn);
                    }}
                  />
                )}
              </div>
            </div>
            <div className={MobileStyle.bottom}>
              <div className={MobileStyle.element}>
                Notice Period:<span>{details.notice_period}</span>
              </div>
              <div className={MobileStyle.element}>
                LWD:<span>{details?.last_working_date}</span>
              </div>
              <div className={MobileStyle.element}>
                Request LWD:<span>{details?.requested_date}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="contentlayout">
            <div className="left">
              <div className="topictext">Separation Approval</div>
              <div
                className="status"
                style={{
                  backgroundColor: details?.is_pending ? "#fa4141" : "#008080",
                }}
              >
                {details?.is_pending ? "In Process" : "Completed"}
              </div>
            </div>
            <div className="right">
              <div className="leftcontent">
                <div className="element">
                  <div>Notice Period:</div> <div>{details.notice_period}</div>
                </div>
                <div className="element">
                  <div>LWD:</div> <div>{details?.last_working_date}</div>
                </div>
                <div className="element">
                  <div>Request LWD:</div> <div>{details?.requested_date}</div>
                </div>
              </div>
              <div className="rightcontent">
                {openDropDawn ? (
                  <UpOutlined
                    onClick={() => {
                      setOpenDropDawn(!openDropDawn);
                    }}
                  />
                ) : (
                  <DownOutlined
                    onClick={() => {
                      setOpenDropDawn(!openDropDawn);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openDropDawn && (
        <div style={{ marginTop: "20px" }}>
          <Steps
            current={current}
            // onChange={(newCurrent) => setCurrent(newCurrent)} // Allowing user to navigate steps
            items={[
              {
                title: (
                  <span
                    style={{
                      color: details.status >= 1 ? "#008080" : "#8F8F8F",

                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Separation Initiation
                  </span>
                ),
                description: (
                  <span
                    style={{
                      color: "#161616",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {details?.resignation_date}
                  </span>
                ),
                subTitle: (
                  <span
                    style={{
                      backgroundColor: "#86cf52", // Use camelCase for backgroundColor
                      color: "white",
                      padding: "4px 10px",
                      fontSize: "12px",
                      fontWeight: 400,
                      textAlign: "center",
                      borderRadius: "23px",
                    }}
                  >
                    {details.is_pending ? "completed" : "Initiated"}
                  </span>
                ),
                className:
                  details.status >= 1 ? "step-completed" : "step-pending",
              },
              {
                title: (
                  <span
                    style={{
                      color: details.status >= 2 ? "#008080" : "#8F8F8F",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Manager Approval
                  </span>
                ),

                description: (
                  <span
                    style={{
                      color: "#161616",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {details?.reporting_manager}
                  </span>
                ),
                subTitle: (
                  <span
                    style={{
                      backgroundColor: details.is_approved
                        ? "#86cf52"
                        : "#f8d51f",
                      color: "white",
                      padding: "4px 10px",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      textAlign: "center",
                      borderRadius: "23px",
                    }}
                  >
                    {details.is_approved ? "completed" : "Pending"}
                  </span>
                ),

                className:
                  details.status >= 1 ? "step-completed" : "step-pending",
              },
              {
                title: (
                  <span
                    style={{
                      color: current >= 3 ? "#008080" : "#8F8F8F",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    HRBP
                  </span>
                ),
                description: (
                  <span
                    style={{
                      color: "#161616",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {details?.hrbp_name}
                  </span>
                ),
                subTitle: (
                  <span
                    style={{
                      backgroundColor: details.is_approved_by_hr
                        ? "#86cf52"
                        : "#f8d51f", // Use camelCase for backgroundColor
                      color: "white",
                      padding: "4px 10px",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      textAlign: "center",
                      borderRadius: "23px",
                    }}
                  >
                    {details?.is_approved_by_hr ? "completed" : "pending"}
                  </span>
                ),
                className:
                  details.status >= 2 ? "step-completed" : "step-pending",
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default Sepration;

// {
//     "employee": "Komal",
//     "resignation_date": "2024-09-26",
//     "requested_date": "2024-09-27",
//     "recovery_days": 58,
//     "last_working_date": "2024-11-25",
//     "reason": "fghjk",
//     "is_pending": true,
//     "is_approved": false,
//     "is_approved_by_hr": false,
//     "final_acceptance_date": null,
//     "acceptance_date_by_manager": null
// }
