import React, {useState, useEffect} from 'react';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import {Switch, Table, Spin, Input} from 'antd';
import request from '../../../../utils/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import update from 'immutability-helper';
import SearchBar from '../../SearchBar/Search';
import getScreenSize from '../../../../utils/getScreenSize';
import MobileStyle from './MobileStyle.module.scss';
import {SearchOutlined} from '@ant-design/icons';
function Setting() {
  const screenSize = getScreenSize();
  const [dataList, setDataList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });
  const ToggleTracking = async (record, index, key) => {
    setLoading(true);
    const apiUrl = `/tracking/admin/employee-tracking/action?employement_number=${record.employement_number}`;

    try {
      const res = await request({
        method: 'post',
        url: apiUrl,
        data: {
          is_on: !record.is_field_tracking_enabled,
        },
      });

      const updatedArray = update(dataList, {
        [index]: {
          [key]: {
            $set: !record.is_field_tracking_enabled,
          },
        },
      });
      setDataList(updatedArray);
      console.log(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  // useEffect(() => {
  //   console.table('dataList...', dataList);
  // }, [dataList]);

  const LoadData = async (
    employement_number = '',
    newPaginaiton,
    data = [],
  ) => {
    const {current, pageSize} = newPaginaiton || pagination;
    let emp_Id = employement_number || '';
    const apiUrl = `tracking/reporting-manager/employee-list/all?employee_id=${emp_Id}&page=${current}&page_size=${pageSize}`;
    setLoading(true);
    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      if (newPaginaiton) {
        setDataList([...data, ...res.data?.pagination_data?.data]);
      } else {
        setDataList([...dataList, ...res.data?.pagination_data?.data]);
      }
      setPagination({
        ...pagination,
        current: current + 1,
        total: res.data?.pagination_data?.total,
      });

      if (res.data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }

      // setDataList(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };

  const EmployeeListColmns = [
    {
      title: 'Employee Id',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },

    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    // {
    //   title: 'Location',
    //   dataIndex: 'location',
    //   key: 'location',
    // },
    {
      title: 'Field Tracking',
      dataIndex: 'is_field_tracking_enabled',
      key: 'is_field_tracking_enabled',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.is_field_tracking_enabled}
            onClick={() => {
              ToggleTracking(record, index, 'is_field_tracking_enabled');
            }}
            style={{
              backgroundColor: record.is_field_tracking_enabled
                ? '#008080'
                : '#d9d9d9', // Teal for 'true' and default gray for 'false'
            }}
          />
        </div>
      ),
    },
  ];
  const search = async data => {
    try {
      setSearchValue(data);
      const response = await request({
        method: 'get',
        url: `tracking/reporting-manager/employee/search?employee_name=${data}`,
      });

      setSearchEmployeeList(response);
      if (data === '') {
        LoadData(
          '',
          {
            current: 1,
            pageSize: 10,
            total: 0,
          },
          [],
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchSelectChange = async (
    employement_number,
    employee_name,
  ) => {
    try {
      LoadData(
        employement_number,
        {
          current: 1,
          pageSize: 10, // Number of items per page
          total: 0,
        },
        [],
      );
      // const response = await request({
      //   method: 'get',
      //   url: `attendance/admin/employee/attendance/details/?employement_number=${employement_number}`,
      // });
      // datalist([...response]);
      // setSearchValue(employee_name);
      // setHasMore(false);
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {screenSize.width < 768 ? (
        <>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vw',
              }}>
              {' '}
              <Spin size="large" />
            </div>
          ) : (
            <div className={MobileStyle.container}>
              <h2>Setting</h2>

              <div className={MobileStyle.search}>
               <div className="search-container">
                <Input
                  size="small"
                  type="text"
                  placeholder="Search for employees"
                  onChange={e => search(e.target.value)}
                  value={searchValue}
                  prefix={<SearchOutlined />}
                  allowClear
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSearchSelectChange(
                            item.employement_number,
                            item.employee_name,
                          )
                        }>
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              </div>

              <div className={MobileStyle.EmployeeDiv}>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 'auto',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  }}>
                  <InfiniteScroll
                    dataLength={
                      pagination.total - dataList?.length * pagination.current
                    }
                    next={LoadData}
                    hasMore={hasMore}
                    endMessage={
                      <p style={{textAlign: 'center'}}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    loader={
                      <p style={{textAlign: 'center'}}>
                        <Spin size="large" />
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                    height="70vh">
                    {dataList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={MobileStyle.employee_element}>
                          <div className={MobileStyle.top}>
                            <div className={MobileStyle.name}>
                              {item.employee_name}
                            </div>

                            <Switch
                              checked={item.is_field_tracking_enabled}
                              style={{
                                backgroundColor: item.is_field_tracking_enabled
                                  ? '#008080'
                                  : '#d9d9d9', // Teal for 'true' and default gray for 'false'
                              }}
                              onClick={() => {
                                ToggleTracking(
                                  item,
                                  index,
                                  'is_field_tracking_enabled',
                                );
                              }}
                            />
                          </div>
                          <div className={MobileStyle.middle}>
                            {item.employement_number}
                          </div>
                          <div className={MobileStyle.bottom}>
                            {item.designation}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{backgroundColor: 'white', padding: '30px'}}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '24px',
            }}>
            <div
              style={{fontSize: '20px', fontWeight: '500', color: '#161616'}}>
              Employee List
            </div>
            <div style={{width: '350px'}}>
               <div className="search-container">
                <Input
                  size="small"
                  type="text"
                  placeholder="Search for employees"
                  onChange={e => search(e.target.value)}
                  value={searchValue}
                  prefix={<SearchOutlined />}
                  allowClear
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSearchSelectChange(
                            item.employement_number,
                            item.employee_name,
                          )
                        }>
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div
            id="scrollableDiv"
            style={{
              height: 'auto',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}>
            <InfiniteScroll
              dataLength={
                pagination.total - dataList?.length * pagination.current
              }
              next={LoadData}
              hasMore={hasMore}
              endMessage={
                <p style={{textAlign: 'center'}}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              loader={
                <p style={{textAlign: 'center'}}>
                  <Spin size="large" />
                </p>
              }
              scrollableTarget="scrollableDiv"
              height="70vh">
              <Table
                columns={EmployeeListColmns}
                dataSource={dataList}
                pagination={false}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
}

export default Setting;
