import React, { useEffect, useState } from 'react';
import styles from "./LeaveAttendance.module.scss"
import { Table, Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import request from '../../../../../utils/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

function LeaveAttendance({ processingDate, setSelectProcessingType, reviewed, fetchReviewDetails }) {
    const selectedEmployees = useSelector((state) => state.selectedEmployeePayrollProcessing);
    const [employeeLeaveAttendanceReview, setEmployeeLeaveAttendanceReview] = React.useState([])

    const [hasMore1, setHasMore1] = useState(true);

    const onHandleChangeLeaveApplication = async (value, id) => {
        if (value === 1 || value === 2) {
            let data = await request({
                method: "post",
                url: `payroll/admin/leave-application/approval`,
                data: {
                    id: id,
                    statusValue: value
                }
            })
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0
            }
            const dataList = [];
            fetchLeaveAttendanceData(pagination, dataList);
        }
    }

    const leaveAttendanceReviewColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "From",
            dataIndex: "start_date",
            key: "start_date",
        },
        {
            title: "To",
            dataIndex: "end_date",
            key: "end_date",
        },
        {
            title: "Total Leaves",
            dataIndex: "leave_day",
            key: "leave_day",
        },
        {
            title: "Leave Type ",
            dataIndex: "leave_type",
            key: "leave_type",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (

                <Select
                    value={record.status === null ? 0 : record.status}
                    onChange={(value) => onHandleChangeLeaveApplication(value, record.id)}
                    style={{ width: 150 }}
                >
                    <Select.Option value={0}>Pending</Select.Option>
                    <Select.Option value={1}>Approved</Select.Option>
                    <Select.Option value={2} >Rejected</Select.Option>
                </Select>
            ),
        }
    ]
    const [leaveAndAttendancePagination, setLeaveAndAttendancePagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })

    const fetchLeaveAttendanceData = async (paginationParams, dataList) => {
        const { current } = paginationParams || leaveAndAttendancePagination;

        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-leaves-attendance-data?page=${current}&page_size=${leaveAndAttendancePagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })

            if (paginationParams) {
                setEmployeeLeaveAttendanceReview([...dataList, ...data.pagination_data.data])
            }
            else {
                setEmployeeLeaveAttendanceReview([...employeeLeaveAttendanceReview, ...data.pagination_data.data])
            }

            setLeaveAndAttendancePagination({
                ...leaveAndAttendancePagination,
                total: data.pagination_data.total,
                current: current + 1
            });

            if (data?.pagination_data?.next === null) {
                setHasMore1(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore1(false);
        }
    }
    useEffect(() => {
        fetchLeaveAttendanceData();
    }, [])
    const [employeeFinalReview, setEmployeeFinalReview] = useState([])
    const [hasMore2, setHasMore2] = useState(true);

    const [finalReviewPagination, setFinalReviewPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })

    const fetchFinalReviewData = async (paginationParams, dataList) => {
        const { current } = paginationParams || finalReviewPagination;
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/all-data-review?page=${current}&page_size=${finalReviewPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            if (paginationParams) {
                setEmployeeFinalReview([...dataList, ...data?.pagination_data?.data])
            }
            else {
                setEmployeeFinalReview([...employeeFinalReview, ...data?.pagination_data?.data])
            }

            setFinalReviewPagination({
                ...finalReviewPagination,
                total: data.pagination_data.total,
                current: current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore2(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore2(false);
        }
    }

    useEffect(() => {
        // 1
        if (selectTab === 1) {
            setHasMore1(true);
            fetchLeaveAttendanceData({ current: 1, pageSize: 10 }, []);
        }

        // 2
        if (selectTab === 2) {
            setHasMore2(true);
            fetchFinalReviewData({ current: 1, pageSize: 10 }, []);
        }
    }, [processingDate]);


    const reviewAndSubmitColumns = [
        {
            title: "Employee",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_salary",
            key: "gross_salary",
        },
        {
            title: "Net Payable",
            dataIndex: "payout",
            key: "payout",
        },
        {
            title: "Loss Of Pay",
            dataIndex: "LOP",
            key: "LOP",
        },
        {
            title: "Payable Days",
            dataIndex: "payable_days",
            key: "payable_days",
        },
        {
            title: "Total Leaves",
            dataIndex: "absent_count",
            key: "absent_count",
        },
        {
            title: "Approved Leaves",
            dataIndex: "approval_leave_count",
            key: "approval_leave_count",
        },
    ]

    const leaveAttendanceTabs = [
        {
            name: "Leaves & Attendance Review",
            key: "leaveAttendanceReview",
            value: 1,
            reviewed: reviewed?.leave_and_attendance
        },
        {
            name: "Review & Submit",
            key: "reviewAndSubmit",
            value: 2,
            reviewed: reviewed?.review_and_submit
        }
    ]

    const [selectTab, setSelectTab] = useState(1)

    const payrollSummaryReviewed = async () => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-review-history`,
                data: {
                    payroll_review_tab: "leaveAttendance",
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
            setSelectProcessingType("newJoinesAndExit")
        }
        catch (error) {
            console.log(error)
        }
    }

    const payrollSummaryChild = async (childName) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-tabs-history`,
                data: {
                    parent_tab: "leaveAttendance",
                    child_tab: childName,
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
        }
        catch (error) {
            console.log(error)
        }
    }

    const renderUI1 = (dataList) => {
        return <Table
            columns={leaveAttendanceReviewColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI2 = (dataList) => {
        return <Table
            columns={reviewAndSubmitColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    return (
        <div className={styles.leaveAttendanceContainer}>
            <div className={styles.container}>
                <div className={styles.tabButtonContainer}>
                    {
                        leaveAttendanceTabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={
                                        item.reviewed
                                            ?
                                            styles.viewedTabButton + " " + styles.tabButton
                                            :
                                            selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}

                                >
                                    <div className={
                                        item.reviewed
                                            ?
                                            styles.viewedIndexBox
                                            :
                                            selectTab === index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                item?.reviewed
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    // selectTab > index + 1
                                                    //     ?
                                                    //     <CheckOutlined  />
                                                    //     :
                                                    item.value
                                            }
                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("leave_and_attendance")
                                        fetchFinalReviewData(
                                            {
                                                current: 1,
                                                pageSize: 10,
                                                total: 0
                                            },
                                            []
                                        );
                                        setSelectTab(2)
                                    }}>Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={leaveAndAttendancePagination.total - (employeeLeaveAttendanceReview?.length)}
                                    next={fetchLeaveAttendanceData}
                                    hasMore={hasMore1}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI1(employeeLeaveAttendanceReview)
                                    }
                                </InfiniteScroll>
                            </div>

                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 2
                        &&
                        <>

                            {/* <CommonTable
                                columns={reviewAndSubmitColumns}
                                data={employeeFinalReview}
                                pagination={finalReviewPagination}
                                setPagination={setFinalReviewPagination}
                                handleRowClick={() => { }}
                            /> */}
                            <div style={{ display: "flex", gap: "30px", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setSelectTab(1)}
                                >Previous</button>
                                <button className={styles.btn} onClick={() => {
                                    payrollSummaryChild("review_and_submit")
                                    payrollSummaryReviewed()
                                }}>
                                    Submit
                                </button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={finalReviewPagination?.total - (employeeFinalReview?.length)}
                                    next={fetchFinalReviewData}
                                    hasMore={hasMore2}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="63vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI2(employeeFinalReview)
                                    }
                                </InfiniteScroll>
                            </div>

                        </>
                    }
                </div>
            </div>


        </div>
    )
}

export default LeaveAttendance