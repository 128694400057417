import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../component/CommonTable/CommonTable'
import { useNavigate, useParams } from 'react-router-dom';
import request from '../../../../utils/axios';
import './LmsDetail.scss'
import { toast } from 'react-toastify';
import { Select, Table } from 'antd';
import getScreenSize from '../../../../utils/getScreenSize';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';
import InfiniteScroll from 'react-infinite-scroll-component';

function LmsDetail() {
    const navigate = useNavigate()
    const params = useParams()
    const screenSize = getScreenSize();
    const [hasMore, setHasMore] = useState(true);

    const columns = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Employee Id',
            dataIndex: 'employement_number',
            key: 'employement_number',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

            render: (text, record) => <button className={`${text}Button`}>{text}</button>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <Select
                        defaultValue={1}
                        onChange={(value) => onHandleChangeForStatus(value, record.employement_number)}
                        style={{ width: "120px" }}
                    >
                        <Select.Option value={1}>Select</Select.Option>
                        <Select.Option value={2}>Reminder</Select.Option>
                    </Select>
                </>
        }
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })

    const [detailList, setDetailList] = useState([])

    const fetchData = async () => {
        try {
            const response = await request({
                method: "get",
                url: `lms/admin/training-video/insight?id=${params.id}&page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            setDetailList([...detailList, ...response?.pagination_data?.data])

            setPagination({
                ...pagination,
                total: response?.pagination_data?.total,
                current: pagination.current + 1
            })

            if (response?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const onHandleChangeForStatus = (value, employement_number) => {
        try {
            if (value === 2) {
                const response = request({
                    method: "post",
                    url: `/lms/admin/training-video/reminder?id=${params.id}`,
                    data: {
                        employement_number: employement_number
                    }
                })
                toast.success("Training Video Reminder sent successfully", {
                    position: 'bottom-left',
                })
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const renderUI = (dataList) => {
        return (
            <div style={{}}>
                {dataList?.map((item, index) => {
                    return (
                        <div key={index} style={{ marginTop: "20px" }}>
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0px 16px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            color: "#161616",
                                            margin: "0",
                                            marginTop: "10px"
                                        }}
                                    >
                                        {item?.employee_name}
                                    </p>
                                    <Select
                                        defaultValue={1}
                                        onChange={(value) => onHandleChangeForStatus(value, item.employement_number)}
                                        style={{
                                            width: "130px",
                                        }}
                                    >
                                        <Select.Option value={1}>Select</Select.Option>
                                        <Select.Option value={2}>Reminder</Select.Option>
                                    </Select>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0px 16px",
                                    }}
                                >
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#4A4A4A" }}>ID</p>
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>{item?.employement_number}</p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0px 16px",
                                    }}
                                >
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#4A4A4A" }}>Score</p>
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>{item?.score}</p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0px 16px",
                                    }}
                                >
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#4A4A4A" }}>Status</p>
                                    <button className={`${item.status}Button`}>{item.status}</button>
                                </div>
                            </div>
                            <hr />
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={columns}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", padding: "0px 16px", marginTop: "80px" }}>Personal development </p>

                        <div style={{ marginTop: "20px" }}>
                            <InfiniteScrollComponent
                                apiEndpoint="/lms/admin/training-videos/list"
                                renderUI={renderUI}
                                initialPage={1}
                            />
                        </div>
                    </div>
                    :
                    <ContainerLayout>
                        <div style={{ padding: "30px", background: "white", borderRadius: "10px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Training Video Details </p>

                            {/* <CommonTable
                                columns={columns}
                                data={detailList}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            /> */}
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={pagination.total - (detailList.length)}
                                    next={fetchData}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="75vh"
                                    className="customScrollbar"
                                >
                                    {
                                        renderUIDesktop(detailList)
                                    }
                                </InfiniteScroll>
                            </div>
                        </div>
                    </ContainerLayout>
            }
        </div>

    )
}

export default LmsDetail