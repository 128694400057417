import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import useScreenSize from '../../../../utils/getScreenSize';
import { useNavigate, useParams } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
// import "../ReferralOpening/ReferralOpening.scss"
import "../../../Recruitment/User/ReferralOpening/ReferralOpening.scss"
import { toast } from 'react-toastify';
import { Button, Col, DatePicker, Input, Modal, Popover, Row, Select, Switch, Form, Table } from 'antd';
import add from "../../../../assets/Org/add.svg"
import upload from "../../../../assets/Org/upload.png"
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import edit from "../../../../assets/Org/edit.png";
import CommonTable from '../../../../component/CommonTable/CommonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBar from "./SearchBar/SearchBar";

function DeparttmentList() {
    const [form] = Form.useForm()
    const [addDepartmentForm] = Form.useForm();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [addDepartmentModel, setAddDepartmentModel] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 12, // Number of items per page
        total: 0,
    })
    const ref = useRef(null);
    const params = useParams();
    const [hasMore, setHasMore] = useState(true);

    const departmentcolumns = [
        {
            title: 'Serial No',
            dataIndex: 'serial_number',
            key: 'serial_number',
        },
        {
            title: 'Department Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <img src={edit} alt="edit" width={20} height={20} />
            ),
        },


    ]

    const screenSize = useScreenSize();

    const [departmentList, setDepartmentList] = useState([])

    const getalldepartments = async (paginationParams, dataList) => {
        const { current, pageSize } = paginationParams || pagination;

        const data = await request({
            method: "get",
            url: `admin/department/list?org=${params.id}&page=${current}&page_size=${pagination.pageSize}`,
        })

        if (paginationParams) {
            setDepartmentList([...dataList, ...data?.pagination_data.data])
        }
        else {
            setDepartmentList([...departmentList, ...data?.pagination_data.data])
        }

        // return data
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total,
            current: current + 1
        })
        if (data?.pagination_data?.next === null) {
            setHasMore(false);
            return;
        }
    }
    const [File, setFile] = useState(null)

    const onFinish = (values) => {
        const formData = new FormData();

        try {
            formData.append("org", params.id);
            formData.append("csv_file", File);

            request({
                method: "post",
                url: `/admin/department/upload`,
                data: formData
            })

            setOpenModal(false);
            setTimeout(() => {
                getalldepartments()
            }, 500)
            toast.success("Department Uploaded Successfully", {
                position: 'bottom-left',
            });
        }
        catch (error) {
            console.log("error")
            toast.error("Could not upload Department, please try after some time", {
                position: 'bottom-left',
            });
        }
    }

    const onDeptartmentFinish = async (values) => {
        const data = await request({
            method: "post",
            url: `admin/department/add`,
            data: { ...values, org: params.id }
        })
        const paginationParams = {
            current: 1,
            pageSize: 12,
        }
        setHasMore(true);
        getalldepartments(paginationParams, [])
        setAddDepartmentModel(false);
        addDepartmentForm.resetFields()
        toast.success("Department Added Successfully", {
            position: 'bottom-left',
        });
    }

    useEffect(() => {
        getalldepartments()
    }, [])

    // useEffect(() => {
    //     ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const renderDesktopUI = (dataList) => {
        return <Table
            columns={departmentcolumns}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }

    return (
        <div ref={ref}>
            {
                screenSize.width < 768 ?  // mobile view


                    <div>
                        <div style={{ padding: "0px 16px", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Departments</p>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <img src={add} alt="add" width={30} height={30}
                                    onClick={() => {
                                        setAddDepartmentModel(true)
                                    }}
                                />
                                <img src={upload} alt="upload" width={30} height={30}
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            id="scrollableDiv"
                            style={{
                                height: "auto",
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column-reverse',
                            }}
                        >
                            <InfiniteScroll
                                dataLength={pagination.total - (departmentList.length * pagination.current)}
                                next={getalldepartments}
                                hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                loader={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Loading...</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv"
                                height="70vh"
                            >
                                {
                                    departmentList?.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div style={{ padding: "0px 16px", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px 0px" }}>
                                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616", margin: "0" }}>{item?.name}</p>
                                                    <img src={edit} alt="edit" width={20} height={20} />
                                                </div>
                                                {
                                                    departmentList?.length - 1 !== index && <hr />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>
                    </div>


                    :

                    <>
                        <div style={{ background: "white", borderRadius: "5px", padding: "0px 30px", marginTop: "3vh" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "30px", }}>
                                {/* <h1>External Recruiters</h1> */}


                            </div>
                            <div className='referralSubContainer'>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: "30px", marginBottom: "2vh" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "500", margin: "0px" }}>Department</p>
                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center" }}>
                                        <SearchBar
                                            setTableData={setDepartmentList}
                                            setHasMore={setHasMore}
                                            fetchTableDataFromApi={getalldepartments}
                                            setPagination={setPagination}
                                        />
                                        <img
                                            src={add}
                                            alt="add"
                                            onClick={() => {
                                                setAddDepartmentModel(true)
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                setOpenModal(true)
                                            }}
                                            className='postJobBtn' style={{ width: "135px", marginBottom: "10px", marginRight: "10px" }}>
                                            Upload Doc
                                        </Button>
                                    </div>
                                </div>
                                {/* <div style={{ marginTop: "2vh" }}>
                                    <CommonTable
                                        columns={departmentcolumns}
                                        data={departmentList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        handleRowClick={() => { }}
                                    />
                                </div> */}
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        height: "auto",
                                        overflow: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column-reverse',
                                    }}
                                >
                                    <InfiniteScroll
                                        dataLength={pagination.total - (departmentList.length)}
                                        next={getalldepartments}
                                        hasMore={hasMore}
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        }
                                        loader={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>Loading...</b>
                                            </p>
                                        }
                                        scrollableTarget="scrollableDiv"
                                        height="70vh"
                                    >
                                        {
                                            renderDesktopUI(departmentList)
                                        }
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>

                    </>

            }
            <Modal
                open={openModal}
                onOk={() => { }}
                onCancel={() => {
                    setOpenModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>Upload Document</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                // onValuesChange={onValuesChange}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <p>Upload File</p>
                            <Form.Item
                                label="Upload File"
                                name="end_time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Date (To)!',
                                    },
                                ]}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className="input-file"
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            if (selectedFile && selectedFile.type === 'text/csv') {
                                                if (selectedFile.size <= maxSizeInBytes) {
                                                    setFile(e.target.files && selectedFile)
                                                }
                                                else {
                                                    setFile(null);
                                                    alert('File size exceeds the limit of 5MB.');
                                                }
                                            } else {
                                                // Reset the file state and show an error message
                                                setFile(null);
                                                alert('Please upload a CSV file.');
                                            }
                                        }}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="file-upload" className="file-upload-label">

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>



                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>

            <Modal
                centered
                open={addDepartmentModel}
                footer={null}
                onCancel={() => {
                    setAddDepartmentModel(false);
                    addDepartmentForm.resetFields();
                }}
            >
                <p className="forgotPasswordText">Add Department</p>
                <Form
                    layout="vertical"
                    onFinish={onDeptartmentFinish}
                    form={addDepartmentForm}
                    autoComplete="on"
                >
                    <Form.Item
                        name="name"
                        label="Department"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the Department Name!",
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            size='large'
                            placeholder="Enter Department Name"
                        />
                    </Form.Item>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}

export default DeparttmentList