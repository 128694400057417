import React, { useEffect, useState } from 'react';
import styles from "./Reimbursement.module.scss"
import { Button, Col, Form, Input, Modal, Row, Select, Table } from "antd";
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import request from '../../../../../utils/axios';
import { toast } from 'react-toastify';
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import edit from "../../../../../assets/edit.svg";


function Reimbursement({ processingDate, setSelectProcessingType, reviewed, fetchReviewDetails }) {

    const [overtimeOverrideModal, setOvertimeOverrideModal] = useState(false)
    const [reimbursementReview, setReimbursementReview] = React.useState([])
    const [overtimeReview, setOvertimeReview] = React.useState([])
    const [adHocPaymentReview, setAdHocPaymentReview] = React.useState([])
    const [adHocdeductionReview, setAdHocDeductionReview] = React.useState([])
    const [employeeAdHocPaymentsModal, setEmployeeAdHocPaymentsModal] = useState(false)
    const [employeeAdHocDeductionsModal, setEmployeeAdHocDeductionsModal] = useState(false)
    const [approvedModal, setApprovedModal] = useState(false)
    const selectedEmployees = useSelector((state) => state.selectedEmployeePayrollProcessing);
    const [hasMore1, setHasMore1] = useState(true);
    const [hasMore2, setHasMore2] = useState(true);
    const [hasMore3, setHasMore3] = useState(true);
    const [hasMore4, setHasMore4] = useState(true);

    const onFinishAdHocPayments = async (values) => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-adhoc-payments`,
                data: AdHocPayments

            })
            setEmployeeAdHocPaymentsModal(false);
            const pagination = {
                current: 1,
                pageSize: 5, // Number of items per page
                total: 0,
            }
            const dataList = [];
            fetchadhocPayementsData(pagination, dataList);
            // fetchadhocPayementsData();
        }
        catch (err) {
            console.log(err)
        }

        // console.log("data : ", data)
        // setrevisedEmployeeData([...data])
    }
    const onFinishAdHocPayDeduction = async (values) => {
        console.log("values : ", values)
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/deduct-adhoc-deduction`,
                data: AdHocDeductions

            })
            setEmployeeAdHocDeductionsModal(false);
            const pagination = {
                current: 1,
                pageSize: 5, // Number of items per page
                total: 0,
            }
            const dataList = [];
            fetchadhocDeductionsData(pagination, dataList);
            // fetchadhocDeductionsData();
        }
        catch (err) {
            console.log(err)
        }
    }
    const [reimbursementPagination, setReimbursementPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const [overtimePagination, setOvertimePagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const fetchReimbursementData = async (paginationParam, dataList) => {
        const { current } = paginationParam || reimbursementPagination;
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-reimbursement-review?page=${current}&page_size=${reimbursementPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if (paginationParam) {
                setReimbursementReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else {
                setReimbursementReview([
                    ...reimbursementReview,
                    ...data.pagination_data.data
                ])
            }

            // if (reimbursementPagination.total !== data.pagination_data.total) {
            setReimbursementPagination({
                ...reimbursementPagination,
                total: data.pagination_data.total,
                current: current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore2(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore2(false);
        }
    }

    const fetchOvertimeData = async (paginationParam, dataList) => {
        const { current } = paginationParam || overtimePagination;
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/payroll/get-overtime-override?page=${current}&page_size=${overtimePagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if (paginationParam) {
                setOvertimeReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else {
                setOvertimeReview([
                    ...overtimeReview,
                    ...data.pagination_data.data
                ])
            }

            // if (reimbursementPagination.total !== data.pagination_data.total) {
            setOvertimePagination({
                ...overtimePagination,
                total: data.pagination_data.total,
                current: current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore1(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore1(false);
        }
    }
    // useEffect(() => {
    //     fetchReimbursementData();
    // }, [])

    const [adhocPaymentPagination, setAdhocPaymentPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const fetchadhocPayementsData = async (paginationParam, dataList) => {
        const { current } = paginationParam || adhocPaymentPagination;

        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-adhoc-payments?page=${current}&page_size=${adhocPaymentPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if (paginationParam) {
                setAdHocPaymentReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else {
                setAdHocPaymentReview([
                    ...adHocPaymentReview,
                    ...data.pagination_data.data
                ])
            }

            // if (adhocPaymentPagination.total !== data.pagination_data.total) {
            setAdhocPaymentPagination({
                ...adhocPaymentPagination,
                total: data.pagination_data.total,
                current: adhocPaymentPagination.current + 1
            });

            if (data?.pagination_data?.next === null) {
                setHasMore3(false);
                return;
            }
            // }
            // setAdhocPaymentPagination({
            //     ...adhocPaymentPagination,
            //     total: data?.pagination_data?.total
            // })
        }
        catch (err) {
            console.log(err)
            setHasMore3(false);
        }
    }
    // useEffect(() => {
    //     fetchadhocPayementsData();
    // }, [adhocPaymentPagination.current])
    const [adhocDeductionPagination, setAdhocDeductionPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const fetchadhocDeductionsData = async (paginationParam, dataList) => {
        const { current } = paginationParam || adhocDeductionPagination;

        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-adhoc-deductions?page=${current}&page_size=${adhocDeductionPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if (paginationParam) {
                setAdHocDeductionReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else {
                setAdHocDeductionReview([
                    ...adHocdeductionReview,
                    ...data.pagination_data.data
                ])
            }

            // if (adhocDeductionPagination.total !== data.pagination_data.total) {
            setAdhocDeductionPagination({
                ...adhocDeductionPagination,
                total: data.pagination_data.total,
                current: adhocDeductionPagination.current + 1
            });

            if (data?.pagination_data?.next === null) {
                setHasMore4(false);
                return;
            }
            // }
            // setAdhocDeductionPagination({
            //     ...adhocDeductionPagination,
            //     total: data?.pagination_data?.total
            // })
        }
        catch (err) {
            console.log(err)
            setHasMore4(false);
        }
    }
    // useEffect(() => {
    //     fetchadhocDeductionsData();
    // }, [adhocDeductionPagination.current])
    const adhocPayemntsRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/delete-adhoc-payments?id=${id}`,
            })
            console.log(data)
            const paginationParam = {
                current: 1,
                pageSize: 5,
                total: 0,
            }
            const dataList = []
            fetchadhocPayementsData(paginationParam, dataList);
        }
        catch (err) {
            console.log(err)
        }
    }
    const adhocDeductionsRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/delete-adhoc-deduction?id=${id}`,
            })

            const paginationParam = {
                current: 1,
                pageSize: 5,
                total: 0,
            }
            const dataList = []
            fetchadhocDeductionsData(paginationParam, dataList);
        }
        catch (err) {
            console.log(err)
        }
    }
    const [selectedOptionEmployeeId, setSelectedOptionEmployeeId] = useState("")
    const onHandleChangeForReimbursementAmount = (value, id) => {
        //   partially approved
        if (value === 1) {
            setApprovedModal(true)
            setSelectedOptionEmployeeId(id)
        }
        // approved
        else if (value === 2) {
            approveEmployeeReimbursementAmount(id)
        }
    }
    const approveEmployeeReimbursementAmount = async (id) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/reimbursement-approved`,
                data: {
                    id
                }
            })
            const paginationParam = {
                current: 1,
                pageSize: 5,
                total: 0,
            }
            const dataList = []
            fetchReimbursementData(paginationParam, dataList);
        }
        catch (error) {
            console.log(error)
        }
    }
    const onFinishForReimbursement = async (values) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/reimbursement-partially-approved`,
                data: {
                    id: selectedOptionEmployeeId,
                    approved_amount: values.amount
                }
            })
            const paginationParam = {
                current: 1,
                pageSize: 5,
                total: 0,
            }
            const dataList = []
            fetchReimbursementData(paginationParam, dataList);
            setApprovedModal(false);
            toast.success("Reimbursement Request Partially approved successfully", {
                position: "bottom-left",
            })

        }
        catch (error) {
            console.log(error)
            toast.error("Reimnursement request could not be Partially approved, please try after some time", {
                position: "bottom-left",
            })
        }
    }
    const onFinishOvertimeOverride = async (values) => {
        console.log("values : ", values)
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/payroll/override-overtime`,
                data: {
                    ...values,
                    id: overtimeOverride.id,
                    employment_number: overtimeOverride.employment_number
                }

            })
            setOvertimeOverrideModal(false);
            const paginationParam = {
                current: 1,
                pageSize: 5,
                total: 0,
            }
            const dataList = []
            fetchOvertimeData(paginationParam, dataList);
        }
        catch (err) {
            console.log(err)
        }
        
    }
    // useEffect(() => {
    //     fetchReimbursementData();
    //     fetchadhocPayementsData();
    //     fetchadhocDeductionsData();

    // }, [processingDate])

    const [shouldFetch1, setShouldFetch1] = useState(false);
    const [shouldFetch2, setShouldFetch2] = useState(false);
    const [shouldFetch3, setShouldFetch3] = useState(false);
    const [shouldFetch4, setShouldFetch4] = useState(false);


    useEffect(() => {

        // 1
        setOvertimePagination({
            ...overtimePagination,
            current: 1
        });
        setOvertimeReview([]);
        setHasMore1(true);
        setShouldFetch1(true);

        // 2
        setReimbursementPagination({
            ...reimbursementPagination,
            current: 1
        });
        setReimbursementReview([]);
        setHasMore2(true);
        setShouldFetch2(true);

        // 3
        setAdhocPaymentPagination({
            ...adhocPaymentPagination,
            current: 1
        });
        setAdHocPaymentReview([]);
        setHasMore3(true);
        setShouldFetch3(true);

        // 4
        setAdhocDeductionPagination({
            ...adhocDeductionPagination,
            current: 1
        });
        setAdHocDeductionReview([]);
        setHasMore4(true);
        setShouldFetch4(true);
    }, [processingDate]);

    useEffect(() => {
        if (shouldFetch1) {
            fetchOvertimeData();
            setShouldFetch1(false);
        }
        if (shouldFetch2) {
            fetchReimbursementData();
            setShouldFetch2(false);
        }
        if (shouldFetch3) {
            fetchadhocPayementsData();
            setShouldFetch3(false);
        }
        if (shouldFetch4) {
            fetchadhocDeductionsData();
            setShouldFetch4(false);
        }
    }, [shouldFetch1, shouldFetch2, shouldFetch3, shouldFetch4]);
    const overtimeOverrideColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_salary",
            key: "gross_salary",
        },
        {
            title: "Overtime Duration",
            dataIndex: "overtime_duration",
            key: "overtime_duration",
        },
        {
            title: "Payable Overtime Amount ",
            dataIndex: "regular_overtime_amount",
            key: "regular_overtime_amount",
        },
        {
            title: "Overtime Override Amount",
            dataIndex: "overtime_override_amount",
            key: "overtime_override_amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <button className={styles.btn} 
            onClick={() => {
                setOvertimeOverride({
                    ...overtimeOverride,
                    id: record.id,
                    employment_number: record.employee,
                    employee_name: record.employee_name,
                    regular_overtime_amount: record.regular_overtime_amount,
                    gross_amount: record.gross_salary,
                    overtime_override_amount: record.overtime_override_amount
                })
                setOvertimeOverrideModal(true)
            }}
            >Approved</button>
            
            
        

        }
    ]

    const reimbursementColumns = [
        {
            title: "Employee",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Claim No.",
            dataIndex: "invoice_id",
            key: "invoice_id",
        },
        {
            title: "Claim Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Amount",
            dataIndex: "claim_amount",
            key: "claim_amount",
        },
        {
            title: "Claim On",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Amount Approved",
            dataIndex: "approved_amount",
            key: "approved_amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <Select
                    value={record.approved === null ? 0 : record.approved}
                    onChange={(value) => onHandleChangeForReimbursementAmount(value, record.id)}
                    style={{ width: 150 }}
                >
                    <Select.Option value={0}>Select</Select.Option>
                    <Select.Option value={1}>Partially Approved</Select.Option>
                    <Select.Option value={2} >Approved</Select.Option>
                </Select>
            ),

        },

    ]
    const adHocPaymentsColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Ad-Hoc Payment Type",
            dataIndex: "get_adhoc_payments_display",
            key: "get_adhoc_payments_display",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <DeleteOutlined style={{ color: 'red', fontSize: '20px' }}
                onClick={() => adhocPayemntsRecordDelete(record.id)} />,
        },

    ]
    const adHocDeductionsColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Ad-Hoc Deduction Type",
            dataIndex: "get_adhoc_deductions_display",
            key: "get_adhoc_deductions_display",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <DeleteOutlined style={{ color: 'red', fontSize: '20px' }}
                onClick={() => adhocDeductionsRecordDelete(record.id)} />,
        },

    ]
    const reimbursementTabs = [
        {
            name: "Overtime",
            key: "overtime",
            value: 1,
            reviewed: reviewed?.overtime
        },
        {
            name: "Reimbursement",
            key: "reimbursement",
            value: 2,
            reviewed: reviewed?.reimbursement
        },
        {
            name: "Ad-hoc Payments",
            key: "adHocPayments",
            value: 3,
            reviewed: reviewed?.adhoc_payment
        },
        {
            name: "Ad-hoc Deductions",
            key: "adHocDeductions",
            value: 4,
            reviewed: reviewed?.adhoc_deduction
        }
    ]

    const [selectTab, setSelectTab] = useState(1)
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const [AdHocPayments, setAdHocPayments] = useState({
        employement_number: "",
        name: "",
        amount: "",
        component_choice: "",
        comment: ""
    })
    const [AdHocDeductions, setAdHocDeductions] = useState({
        employement_number: "",
        name: "",
        amount: "",
        component_choice: "",
        comment: ""
    })
    const [overtimeOverride, setOvertimeOverride] = useState({
        id: "",
        employment_number: "",
        gross_amount: "",
        regular_overtime_amount: "",
        overtime_override_amount: ""
    })

    const onHandleAdhocDeductionsChange = (key, value) => {
        setAdHocDeductions({
            ...AdHocDeductions,
            [key]: value
        });
    }
    const onHandleChange = (key, value) => {
        setAdHocPayments({
            ...AdHocPayments,
            [key]: value
        });
    }

    const searchForAdHocPayments = async (data) => {
        onHandleChange('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }
    const searchForAdhocDeductions = async (data) => {
        onHandleAdhocDeductionsChange('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleSelectChangeForAdHocPayments = async (employement_number, name) => {
        try {
            setAdHocPayments({
                ...AdHocPayments,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }
    const handleSelectChangeForAdHocDeductions = async (employement_number, name) => {
        try {
            setAdHocDeductions({
                ...AdHocDeductions,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const payrollSummaryReviewed = async () => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-review-history`,
                data: {
                    payroll_review_tab: "reimbursementAndAdhocs",
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
            setSelectProcessingType("salariesAndArrears")
        }
        catch (error) {
            console.log(error)
        }
    }

    const payrollSummaryChild = async (childName) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-tabs-history`,
                data: {
                    parent_tab: "reimbursementAndAdhocs",
                    child_tab: childName,
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
        }
        catch (error) {
            console.log(error)
        }
    }
    const renderUI1 = (dataList) => {
        return <Table
            columns={overtimeOverrideColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI2 = (dataList) => {
        return <Table
            columns={reimbursementColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI3 = (dataList) => {
        return <Table
            columns={adHocPaymentsColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI4 = (dataList) => {
        return <Table
            columns={adHocDeductionsColumns}
            dataSource={dataList}
            pagination={false}
        />
    }
    return (
        <div className={styles.reimbursementContainer}>
            <div className={styles.container}>
                <div className={styles.tabButtonContainer}>
                    {
                        reimbursementTabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={
                                        item?.reviewed
                                            ?
                                            styles.viewedTabButton + " " + styles.tabButton
                                            :
                                            selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}

                                >
                                    <div className={
                                        item?.reviewed
                                            ?
                                            styles.viewedIndexBox
                                            :
                                            selectTab === index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                item?.reviewed
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    // selectTab > index + 1
                                                    //     ?
                                                    //     <CheckOutlined  />
                                                    //     :
                                                    item.value
                                            }

                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            {/* <CommonTable
                                columns={reimbursementColumns}
                                data={reimbursementReview}
                                pagination={reimbursementPagination}
                                setPagination={setReimbursementPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("overtime")
                                        setSelectTab(2)
                                    }}>Next</button>
                            </div>

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={overtimePagination.total - (overtimeReview?.length)}
                                    next={fetchOvertimeData}
                                    hasMore={hasMore1}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI1(overtimeReview)
                                    }
                                </InfiniteScroll>
                            </div>

                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 2
                        &&
                        <>
                            {/* <CommonTable
                                columns={reimbursementColumns}
                                data={reimbursementReview}
                                pagination={reimbursementPagination}
                                setPagination={setReimbursementPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                        className={styles.btn}
                                        onClick={() => setSelectTab(1)}
                                    >Previous</button>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("reimbursement")
                                        setSelectTab(3)
                                    }}>Next</button>
                            </div>

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={reimbursementPagination.total - (reimbursementReview?.length)}
                                    next={fetchReimbursementData}
                                    hasMore={hasMore2}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI2(reimbursementReview)
                                    }
                                </InfiniteScroll>
                            </div>

                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 3
                        &&
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    style={{ background: "#008080", color: "white", height: "40px" }}
                                    onClick={() => setEmployeeAdHocPaymentsModal(true)}>Create</Button>
                                {/* <CommonTable
                                columns={adHocPaymentsColumns}
                                data={adHocPaymentReview}
                                pagination={adhocPaymentPagination}
                                setPagination={setAdhocPaymentPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                                <div>
                                    <button
                                        className={styles.btn}
                                        onClick={() => setSelectTab(2)}
                                    >Previous</button>
                                    <button
                                        className={styles.btn}
                                        onClick={() => {
                                            payrollSummaryChild("adhoc_payment")
                                            setSelectTab(4)
                                        }}
                                    >Next</button>
                                </div>
                            </div>

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={adhocPaymentPagination.total - (adHocPaymentReview?.length)}
                                    next={fetchadhocPayementsData}
                                    hasMore={hasMore3}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI3(adHocPaymentReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 4
                        &&
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    style={{ background: "#008080", color: "white", height: "40px" }}
                                    onClick={() => setEmployeeAdHocDeductionsModal(true)}>Create</Button>

                                {/* <CommonTable
                                columns={adHocDeductionsColumns}
                                data={adHocdeductionReview}
                                pagination={adhocDeductionPagination}
                                setPagination={setAdhocDeductionPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                                <div>
                                    <button
                                        className={styles.btn}
                                        onClick={() => setSelectTab(3)}
                                    >Previous</button>

                                    <button className={styles.btn} onClick={() => {
                                        payrollSummaryChild("adhoc_deduction")
                                        payrollSummaryReviewed()
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={adhocDeductionPagination.total - (adHocdeductionReview?.length)}
                                    next={fetchadhocDeductionsData}
                                    hasMore={hasMore4}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI4(adHocdeductionReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal
                title="Add adhoc-payments"
                centered
                open={employeeAdHocPaymentsModal}
                onCancel={() => setEmployeeAdHocPaymentsModal(false)}
                width={1000}
                footer={null}

            >
                <Form layout="vertical"
                    onFinish={onFinishAdHocPayments} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={AdHocPayments.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForAdHocPayments(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForAdHocPayments(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="AdHoc-Type"

                                name="component_choice"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter Name!',
                            //     },
                            // ]}
                            >
                                <Select size='large'
                                    onChange={(value) => onHandleChange("component_choice", value)}
                                >
                                    <Select.Option value={0}>Over Time</Select.Option>
                                    <Select.Option value={1}>Shift Allowance</Select.Option>
                                    <Select.Option value={2}>Webinar Allowance</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChange("amount", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Comment"
                                name="comment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Comment!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter comment"
                                    onChange={(e) => onHandleChange("comment", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>


            </Modal>
            <Modal
                title="Adhoc-Deductions"
                centered
                open={employeeAdHocDeductionsModal}
                onCancel={() => setEmployeeAdHocDeductionsModal(false)}
                width={1000}
                footer={null}
            >
                <Form layout="vertical"
                    onFinish={onFinishAdHocPayDeduction} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={AdHocDeductions.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForAdhocDeductions(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForAdHocDeductions(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="AdHoc-Deduction-Type"

                                name="component_choice"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter Name!',
                            //     },
                            // ]}
                            >
                                <Select size='large'
                                    onChange={(value) => onHandleAdhocDeductionsChange("component_choice", value)}>
                                    <Select.Option value={0}>Other Deductions</Select.Option>
                                    <Select.Option value={2}>Webinar Deductions</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleAdhocDeductionsChange("amount", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Comment"
                                name="comment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Comment!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter comment"
                                    onChange={(e) => onHandleAdhocDeductionsChange("comment", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>


            </Modal>
            <Modal
                title="Approve Reimbursement Amount"
                centered
                footer={null}
                onCancel={() => setApprovedModal(false)}
                open={approvedModal}>
                <Form layout="vertical"
                    onFinish={onFinishForReimbursement} >
                    <Col span={24}>
                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Amount!',
                                }
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder="Enter Amount"
                            />
                        </Form.Item>

                    </Col>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
            <Modal
                title="Overtime Override"
                centered
                open={overtimeOverrideModal}
                onCancel={() => setOvertimeOverrideModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishOvertimeOverride}
                    initialValues={overtimeOverride}
                    fields={[
                        {
                            name: ["gross_amount"],
                            value: overtimeOverride?.gross_amount
                        },
                        {
                            name: ["regular_overtime_amount"],
                            value: overtimeOverride?.regular_overtime_amount
                        }
                    ]} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="employee_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    size='large'
                                    placeholder="Enter employment number"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Gross Amount"
                                name="gross_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Regular Overtime Amount"
                                name="regular_overtime_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Overtime Override Amount"
                                name="overtime_override_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Overtime Override Amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Overtime Override Amount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>



        </div>

    );
}
export default Reimbursement