import { Button, Col, Form, Input, Modal, Row, Select, Spin, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios'
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import { toast } from 'react-toastify';
import getScreenSize from "../../../../utils/getScreenSize";
import mobileStyle from "./mobileReimbursementFullView.module.scss";
import desktopStyle from "./ReimbursementFullView.module.scss";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import view from "../../../../assets/view.png";
import activeDocumentImg from "../../../../assets/activeDocumentImg.svg";
import activeDownload from "../../../../assets/activeDownload.png";
import { saveAs } from 'file-saver';
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

function ReimbursementFullView({ tabsValue }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const ref = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const [selectedReimbursementData, setSelectedReimbursementData] = useState();
    const EmployeeReimbursementColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Claimed On',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Amount Claimed',
            dataIndex: 'claim_amount',
            key: 'claim_amount',
        },
        {
            title: 'Amount Approved',
            dataIndex: 'approved_amount',
            key: 'approved_amount',
        },
        {
            title: 'Status',
            dataIndex: 'approved',
            key: 'approved',
            render: (text, record) => (
                <>
                    <Select
                        value={record.approved_amount}
                        onChange={(value) => onHandleChangeForReimbursementAmount(value, record.id)}
                        style={{ width: 150 }}
                    >
                        <Select.Option value={null} disabled>Select</Select.Option>
                        <Select.Option value={1}>Partially Approved</Select.Option>
                        <Select.Option value={2} >Approved</Select.Option>
                    </Select>
                    <img src={view} alt="view" width={18} height={18} style={{ marginLeft: "15px" }}
                        onClick={() => {
                            setReimbursementModel(true)
                            setSelectedReimbursementData(record)
                        }}
                    />
                </>
            ),
        },
    ]
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    })

    const [reimbursementModel, setReimbursementModel] = useState(false);
    const onHandleChangeForReimbursementAmount = (value, id) => {
        //   partially approved
        if (value === 1) {
            setApprovedModal(true)
            setSelectedOptionEmployeeId(id)
        }
        // approved
        else if (value === 2) {
            approveEmployeeReimbursementAmount(id)
        }
    }

    const approveEmployeeReimbursementAmount = async (id) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/reimbursement-approved`,
                data: {
                    id
                }
            })
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            const dataList = [];
            setHasMore(true);
            fetchAllemployeeReimbursement(pagination, dataList);
        }
        catch (error) {
            console.log(error)
        }
    }

    const [approvedModal, setApprovedModal] = useState(false)
    const [selectedOptionEmployeeId, setSelectedOptionEmployeeId] = useState("")

    const [allEmployeeReimbursementSummary, setAllEmployeeReimbursementSummary] = useState([])

    const fetchAllemployeeReimbursement = async (paginationParams, dataList) => {
        const { current } = paginationParams || pagination;
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-reimbursement?page=${current}&page_size=${pagination.pageSize}`,
            })
            if (paginationParams) {
                setAllEmployeeReimbursementSummary([...dataList, ...data?.pagination_data.data])
            }
            else {
                setAllEmployeeReimbursementSummary([...allEmployeeReimbursementSummary, ...data.pagination_data.data])
            }

            setPagination({
                ...pagination,
                total: data?.pagination_data?.total,
                current: current + 1,
            })
            if (data?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const [partiallyApprovedLoader, setPartiallyApprovedLoader] = useState(false)
    const onFinishForReimbursement = async (values) => {
        try {
            setPartiallyApprovedLoader(true)
            await request({
                method: "post",
                url: `payroll/admin/reimbursement-partially-approved`,
                data: {
                    id: selectedOptionEmployeeId,
                    approved_amount: values.amount
                }
            })
            setPartiallyApprovedLoader(false)
            setHasMore(true)
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            const dataList = [];
            fetchAllemployeeReimbursement(pagination, dataList);
            setApprovedModal(false);
            toast.success("Reimbursement Request Partially approved successfully", {
                position: "bottom-left",
            })

        }
        catch (error) {
            console.log(error)
            setPartiallyApprovedLoader(false)
            toast.error("Reimnursement request could not be Partially approved, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    useEffect(() => {
        if (tabsValue === "5") {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            const dataList = [];
            fetchAllemployeeReimbursement(pagination, dataList);
        }
    }, [tabsValue])

    // useEffect(() => {
    //     // ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <div onClick={() => { navigate("/admin/payroll/reimbursement/" + item.id, { replace: true }) }}>
                    <div className={mobileStyle.cardContainer}>
                        <Row>
                            <Col span={12}>
                                <p className={mobileStyle.employeeName}> {item.merchant_name}</p>
                            </Col>
                            <Col span={12}>
                                <div style={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}>
                                    <Select
                                        value={item.approved === null ? 0 : item.approved}
                                        onChange={(value) => onHandleChangeForReimbursementAmount(value, item.id)}
                                        style={{ width: 150 }}
                                        size='middle'
                                    >
                                        <Select.Option value={0}>Select</Select.Option>
                                        <Select.Option value={1}>Partially Approved</Select.Option>
                                        <Select.Option value={2} >Approved</Select.Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.title}>Claim Title</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.value}>{item.title}</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.title}>Claimed On</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.value}>{item.date}</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.title}>Amount Claimed</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.value}>{item.claim_amount}</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.title}>Amount Approved</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.value}>{item.approved_amount}</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.title}>Approved On</p>
                            </Col>
                            <Col span={12}>
                                <p className={mobileStyle.value}></p>
                            </Col>
                        </Row>
                    </div>
                    {
                        index < allEmployeeReimbursementSummary?.length - 1 && <hr />
                    }
                </div>
            )
        })
    }

    const renderDesktopUI = (dataList) => {
        return <Table
            dataSource={dataList}
            columns={EmployeeReimbursementColumn}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }

    return (
        <div ref={ref}>
            {
                screenSize.width < 768 ?
                    <div className={mobileStyle.container}>
                        <p className={mobileStyle.heading}>Reimbursement</p>
                        <InfiniteScrollComponent
                            apiEndpoint="payroll/admin/get-all-employee-reimbursement"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                        {

                        }
                    </div>
                    :
                    <div>
                        {/* <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Payroll</p> */}
                        <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>

                            {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                                <img src={back} alt="back" />
                                <span style={{ paddingLeft: "10px" }}>Back</span>
                            </p> */}

                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Reimbursement</p>
                            {/* <InfiniteScrollComponent
                                apiEndpoint="payroll/admin/get-all-employee-reimbursement"
                                renderUI={renderDesktopUI}
                                initialPage={1}
                            /> */}
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={pagination.total - (allEmployeeReimbursementSummary?.length)}
                                    next={fetchAllemployeeReimbursement}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                // className={styles.customScrollbar}
                                >
                                    {
                                        renderDesktopUI(allEmployeeReimbursementSummary)
                                    }
                                </InfiniteScroll>
                            </div>
                        </div>

                        {/* Approve Reimbursement Amount */}
                        <Modal
                            title="Approve Reimbursement Amount"
                            centered
                            footer={null}
                            onCancel={() => setApprovedModal(false)}
                            open={approvedModal}>
                            <Form layout="vertical"
                                onFinish={onFinishForReimbursement} >
                                <Col span={24}>
                                    <Form.Item
                                        name="amount"
                                        label="Amount"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Amount!',
                                            }
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            placeholder="Enter Amount"
                                        />
                                    </Form.Item>

                                </Col>
                                <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                                    {
                                        partiallyApprovedLoader
                                            ?
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{ fontSize: "15px", color: "white" }}
                                                        spin
                                                    />
                                                }
                                            />
                                            :
                                            "Submit"
                                    }
                                </Button>
                            </Form>
                        </Modal>
                    </div>
            }

            <Modal
                centered
                open={reimbursementModel}
                onOk={() => setReimbursementModel(false)}
                onCancel={() => setReimbursementModel(false)}
                width={1000}
                footer={null}
            >
                <p className={desktopStyle.reimbursementModelTitle}>Reimbursement Details</p>
                <Row gutter={[32, 12]}>
                    <Col span={11}>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Name</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.employee_name}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Title</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.title}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Claimed On</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.date}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Amount Claimed</p>
                            <p className={desktopStyle.reimbursementModelValue}>₹{selectedReimbursementData?.claim_amount}</p>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Status</p>
                            <Select
                                value={selectedReimbursementData?.approved === null ? 0 : selectedReimbursementData?.approved}
                                onChange={(value) => onHandleChangeForReimbursementAmount(value, selectedReimbursementData.id)}
                                style={{ width: 150 }}
                                size='middle'
                            >
                                <Select.Option value={0}>Select</Select.Option>
                                <Select.Option value={1}>Partially Approved</Select.Option>
                                <Select.Option value={2} >Approved</Select.Option>
                            </Select>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Invoice ID</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.invoice_id}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Merchant Name</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.merchant_name}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Amount Approved</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.approved_amount && `₹${selectedReimbursementData?.approved_amount}`}</p>
                        </div>
                    </Col>
                    <Col span={24}>
                        <p className={desktopStyle.reimbursementModelKey}>Description</p>
                        <div style={{ border: "1px solid #DDDDDD", background: "#F8F8F8", padding: "10px" }}>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedReimbursementData?.description}</p>
                        </div>
                    </Col>
                    <Col span={24}>
                        <p className={desktopStyle.reimbursementModelKey}>Download documents</p>


                        {
                            selectedReimbursementData?.reimbursement_files?.map((item, index) => {
                                return (
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between", padding: "10px", background: "#F8F8F8", width: "50%", marginBottom: "10px" }}>
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <img src={activeDocumentImg} alt="activeDocumentImg" />
                                            <p style={{ margin: "0px", color: "#505050", fontWeight: "600", fontSize: "16px" }}>{item?.file_name}</p>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <img src={view} alt="view" width={18} height={18}
                                                onClick={() => {
                                                    window.open(item?.file_upload)
                                                }}
                                            />
                                            {/* download  pdf*/}

                                            <a href={item.file_upload} target="_blank" download>
                                                <img src={activeDownload} alt="activeDownload" width={18} height={18}
                                                    onClick={async () => {

                                                        try {
                                                            const response = await fetch(item.file_upload);
                                                            const blob = await response.blob();
                                                            saveAs(blob, `${item.file_name}.pdf`);
                                                        } catch (error) {
                                                            console.error('Error downloading PDF:', error);
                                                        }
                                                    }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Modal>
        </div>

    )
}

export default ReimbursementFullView