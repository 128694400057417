import { Button, Col, Form, Input, Modal, Radio, Row, Select, Table, DatePicker, Switch, AutoComplete } from "antd";
import React, { useEffect, useState, useRef } from 'react'
import request from '../../../../utils/axios'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import { useNavigate } from 'react-router-dom';
import getScreenSize from '../../../../utils/getScreenSize';
import back from "../../../../assets/Recruitment/back.svg"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import add from "../../../../assets/Org/add.svg"
import edit from "../../../../assets/edit.svg";
import styles from "./salaryStructureFullView.module.scss"
import mobileStyle from "./MobileSalaryStructureFullView.module.scss"
import arrowCircle from "../../../../assets/fullView.svg";
import view from "../../../../assets/Payroll/Admin/view.jpg";
import circleEdit from "../../../../assets/Payroll/Admin/edit.jpg";
import { toast } from "react-toastify";
import clone_icon from "../../../../assets/clone_icon.svg"

function SalaryStructureFullView({ tabsValue }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const [salaryStructureType, setSalaryStructureType] = useState('salaryStructure')

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const salaryStructureColumn = [
        {
            title: 'Name',
            dataIndex: 'structure_name',
            key: 'structure_name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => navigate("/admin/payroll/salary-configuration-form/" + record.id, { replace: true })}
                    />
                     <img src={clone_icon} alt="clone"
                        onClick={() => navigate(`/admin/payroll/salary-configuration-form/${record.id}/clone`, { replace: true })}
                    />
                </>
        },
    ]
    const componentsColumn = [
        {
            title: 'Name',
            dataIndex: 'component_name',
            key: 'name',
        },
        {
            title: 'Components',
            dataIndex: 'salary_component',
            key: 'components',
            render: (text, record) => {
                // Assuming 'salary_component' is an array of values
                return record.salary_component.join(', ');
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => {
                            setComponentModal(true)
                            setEditComponent(true)
                            fetchSpecficComponentDetail(record?.component_value)
                        }}
                    />
                </>,
            width: 100,
        },
    ]


    const [componentData, setComponentData] = useState([])
    const fetchComponentsList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/fetchComponents`,
            })
            console.log("data : ", data)
            setComponentData([...data.data])
        }
        catch (err) {
            console.log(err)
        }
    }
    const options = [
        {
            label: 'Salary Structure',
            value: 'salaryStructure',
        },
        {
            label: 'Components',
            value: 'components',
        },
    ];

    const [salaryStructureData, setSalaryStructureData] = useState([])
    const fetchSalaryStrDetail = async (page, pageSize) => {
        const data = await request({
            method: "get",
            url: `payroll/admin/fetch-salary-structure?page=${pagination.current}&page_size=${pagination.pageSize}`,
        })
        console.log("data : ", data)
        setSalaryStructureData([...data.pagination_data.data])
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
        return data
        // setTableData(data.data.attendance_data)

        // setLoading(false)
    }

    const [componentModel, setComponentModal] = useState(false)
    const [editComponent, setEditComponent] = useState(false)
    const [componentDetail, setComponentDetail] = useState({
        name: "",
        components: []
    })

    const [componentNameList, setComponentNameList] = useState()

    const fetchComponentNameList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/get-salary-components-list`,
            })

            setComponentNameList(response?.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const [componentTypeList, setComponentTypeList] = useState();

    const fetchComponentTypeList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/get-salary-component-type-list`,
            })

            setComponentTypeList(response?.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchSpecficComponentDetail = async (id) => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/get-salary-components?component_name=${id}`,
            })

            setComponentDetail({
                ...componentDetail,
                name: response?.data?.component_name,
                components: response?.data?.component_type
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const [componentForm] = Form.useForm();

    const componentsSubmit = async (values) => {
        try {
            console.log("values", values);
            if (editComponent) {
                await request({
                    method: "post",
                    url: `payroll/admin/edit-salary-components`,
                    data: {
                        component_name: values?.name,
                        component_type: values?.components
                    }
                })

                setEditComponent(false)
            }
            else {
                await request({
                    method: "post",
                    url: `payroll/admin/create-salary-components`,
                    data: {
                        name: values?.name,
                        type: values?.components
                    }
                })
            }
            componentForm.resetFields()
            setComponentDetail({
                ...componentDetail,
                name: "",
                components: []
            })
            setComponentModal(false)
            fetchComponentsList()
        }
        catch (error) {
            console.log(error)
            setEditComponent(false)
            toast.error(error.response.data.message, {
                position: "bottom-left",
            })
        }
    }

    useEffect(() => {
        if (tabsValue === "3") {
            fetchComponentsList();
            fetchComponentNameList()
            fetchComponentTypeList()
        }
    }, [tabsValue])

    useEffect(() => {
        if (tabsValue === "3") {
            fetchSalaryStrDetail();
        }
    }, [pagination.current, tabsValue])

    const ref = useRef(null);
    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    <div>
                        <div className={mobileStyle.salaryStructureContainer}>
                            <div className={mobileStyle.headerContainer}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <p className={mobileStyle.heading}>Salary Structure</p>
                                    {/* <img
                                        onClick={() => navigate("/admin/payroll/salary-structure/full-view")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer" }}
                                    /> */}
                                </div>
                                {/* <Radio.Group  
                                    options={options}
                                    onChange={(e) => setSalaryStructureType(e.target.value)}
                                    value={salaryStructureType}
                                    optionType="button"
                                    buttonStyle="solid"
                                />  */}

                                <center>
                                    <Radio.Group
                                        value={salaryStructureType}
                                        buttonStyle="solid"
                                        size='large'
                                        onChange={e => setSalaryStructureType(e.target.value)}
                                        style={{
                                            width: "100%"
                                        }}
                                    // options={options}
                                    //                 onChange={(e) => setSalaryStructureType(e.target.value)}
                                    //                 value={salaryStructureType}
                                    //                 optionType="button" style={{width:"100%"}}
                                    // buttonStyle="solid"
                                    >
                                        <Radio.Button value="salaryStructure"
                                            style={{
                                                width: "50%"
                                            }}>Salary Structure</Radio.Button>
                                        <Radio.Button value="referral" style={{
                                            width: "50%"
                                        }}>Components</Radio.Button>
                                    </Radio.Group>
                                </center>
                            </div>


                            {
                                salaryStructureType === 'salaryStructure'
                                    ?
                                    <div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                                            <p className={mobileStyle.subHeading}>Salary Structure</p>
                                            <img src={add} alt="add"
                                                onClick={() => navigate("/admin/payroll/salary-configuration-form", { replace: true })}
                                            />
                                        </div>
                                        <div>
                                            {
                                                salaryStructureData.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <div className={mobileStyle.card}>
                                                                {/* <div className={mobileStyle.header}> */}
                                                                <p className={mobileStyle.name}>{item.structure_name}</p>
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    {/* <img src={view} alt="view"
                                                                     onClick={() => navigate("/admin/payroll/salary-configuration-form/" + item.id)}
                                                                    /> */}
                                                                    <img src={circleEdit} alt="edit"
                                                                        onClick={() => navigate("/admin/payroll/salary-configuration-form/" + item.id, { replace: true })}
                                                                    />
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <br />
                                        <div style={{ padding: "0px 16px 16px 16px", display: "flex", justifyContent: "space-between" }}>
                                            <p className={mobileStyle.subHeading}>Components</p>
                                            <img src={add} alt="add"
                                                onClick={() => {
                                                    setComponentModal(true)
                                                }}
                                            />
                                        </div>
                                        {/* <Table
                                            columns={componentsColumn}
                                            dataSource={componentData}
                                            // loading={loading}
                                            pagination={false}
                                        /> */}
                                        <div>
                                            {
                                                componentData.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                padding: "0px 16px"
                                                            }}>
                                                                {/* <div className={mobileStyle.header}> */}
                                                                <div>
                                                                    <p style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "600",
                                                                        color: "#161616",
                                                                        margin: "0",
                                                                        marginBottom: "10px"
                                                                    }}>{item.component_name}</p>

                                                                </div>

                                                                <img src={circleEdit} alt="edit"
                                                                    onClick={() => {
                                                                        setComponentModal(true)
                                                                        setEditComponent(true)
                                                                        fetchSpecficComponentDetail(item?.component_value)
                                                                    }}
                                                                />
                                                                {/* </div> */}
                                                            </div>
                                                            <div style={{ padding: "0px 16px" }}>
                                                                <p>{item.salary_component.join(', ')}</p>
                                                            </div>

                                                            <hr />
                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                    :

                    <div className={styles.payRollContainer}>
                        <div className={styles.subContainer}>
                            <div style={{ alignItems: "center", gap: "16px" }}>
                                <h2 className={styles.heading}>Salary Structure</h2>
                                <br />
                                <Radio.Group
                                    options={options}
                                    onChange={(e) => setSalaryStructureType(e.target.value)}
                                    value={salaryStructureType}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                            {
                                salaryStructureType === 'salaryStructure'
                                    ?
                                    <div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className={styles.subHeading}>Salary Structure</p>
                                            <img src={add} alt="add"
                                                onClick={() => navigate("/admin/payroll/salary-configuration-form", { replace: true })}
                                            />
                                        </div>
                                        <CommonTable
                                            columns={salaryStructureColumn}
                                            data={salaryStructureData}
                                            // loading={loading}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            handleRowClick={() => { }}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className={styles.subHeading}>Components</p>
                                            <img src={add} alt="add"
                                                onClick={() => {
                                                    setComponentModal(true)
                                                }}
                                            />
                                        </div>
                                        <Table
                                            columns={componentsColumn}
                                            dataSource={componentData}
                                            // loading={loading}
                                            pagination={false}
                                        />
                                    </div>
                            }

                        </div>

                    </div>
            }

            {/* Salary Structure => component */}
            <Modal
                centered
                open={componentModel}
                onOk={() => {
                    setComponentModal(false)
                    componentForm.resetFields()
                    setComponentDetail({
                        ...componentDetail,
                        name: "",
                        components: []
                    })
                }}
                onCancel={() => {
                    setComponentModal(false)
                    componentForm.resetFields()
                    setComponentDetail({
                        ...componentDetail,
                        name: "",
                        components: []
                    })
                }}
                width={1000}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={componentsSubmit}
                    form={componentForm}
                    fields={[
                        {
                            name: "name",
                            value: componentDetail?.name,
                        },
                        {
                            name: "components",
                            value: componentDetail?.components,
                        },
                    ]}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select name!',
                                    },
                                ]}
                            >
                                <Select
                                    // className='multiSelectDropDown'
                                    // showSearch={false}
                                    placeholder="Select Component header"
                                    size="large"
                                    dafaultValue={componentDetail.name}
                                >
                                    {
                                        componentNameList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.name}{item.id}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Components"
                                name="components"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select component!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    className='multiSelectDropDown'
                                    showSearch={false}
                                    size="large"
                                    dafaultValue={componentDetail?.components}
                                    placeholder="Select Components"
                                >
                                    {
                                        componentTypeList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}

export default SalaryStructureFullView