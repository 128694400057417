import React, { useEffect } from "react";
import styles from "./Dashboard.module.scss";
import LeaveList from "../Leave/LeaveList/LeaveList";

import attendance from "../../../assets/Inbox/attendance.png";
import leave from "../../../assets/Inbox/leave.png";
import performance from "../../../assets/Inbox/Increase.png";
import resign from "../../../assets/Inbox/resignation.png";
import documentation from "../../../assets/Inbox/newDocumentSigning.png";
import activeLeave from "../../../assets/Inbox/Activeleave.png";
import activeNewDocSigning from "../../../assets/Inbox/ActiveNewDocumentSigning.png";
import activeResign from "../../../assets/Inbox/ActiveResignation.png";
import Resignation from "../Resignation/Resignation";
import { useNavigate } from "react-router-dom";
import Documentation from "../Documentation/Documentation";
import getScreenSize from "../../../utils/getScreenSize";
import { Tabs } from "antd";
import AttendanceList from "../Attendance/AttendanceList/AttendanceList";
import LmsList from "../Lms/InboxLmsList";
import trainingActive from "../../../assets/Training.svg";
import training from "../../../assets/Training-inactive.svg";
import InboxLmsList from "../Lms/InboxLmsList";
import ContainerLayout from "../../../component/ContainerLayout/ContainerLayout"

function InboxDashboard() {
  const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;
  const navigate = useNavigate();
  const { width } = getScreenSize();
  const menu = [
    {
      label: "Leave",
      key: "leave",
      url: "/inbox/leave",
      image: leave,
      activeImage: activeLeave,
      component: <LeaveList />,
    },
    {
      label: "New Documentation Signing",
      key: "documentation",
      url: "/inbox/documentation",
      image: documentation,
      activeImage: activeNewDocSigning,
      component: <Documentation />,
    },
    {
      label: "Resignation",
      key: "resign",
      url: "/inbox/resign",
      image: resign,
      activeImage: activeResign,
      component: <Resignation />,
    },
    {
      label: "Attendance",
      key: "attendance",
      url: "/inbox/attendance",
      image: attendance,
      activeImage: activeLeave,
      component: <AttendanceList />,
    },
    // {
    //     label: "Performance Management",
    //     key: "performance",
    //     url: "/inbox/performance",
    //     image: performance
    // },
    {
      label: "Training",
      key: "lms",
      url: "/inbox/lms",
      image: training,
      activeImage: trainingActive,
      component: <InboxLmsList />,
    },
  ];

  const items = [
    {
      key: "leave",
      label: "Leave",
      children: <LeaveList />,
    },
    {
      key: "documentation",
      label: "Documentation",
      children: <Documentation />,
    },
    {
      key: "resign",
      label: "Resignation",
      children: <Resignation />,
    },
    {
      key: "attendance",
      label: "Attendance",
      children: <AttendanceList />,
    },
    {
      key: "lms",
      label: "Training",
      children: <InboxLmsList />,
    },
  ];

  const [selectedMenu, setSelectedMenu] = React.useState("leave");

  useEffect(() => {
    const tenantName = window.location.href
      ?.split(frontend_base_url)[1]
      .split("/")[1];
    const selectedMenu = window.location.href
      ?.split(frontend_base_url)[1]
      .split(`/${tenantName}/inbox/`)[1];
    setSelectedMenu(selectedMenu);
  }, [window.location.href]);

  return (
    <>
      {width < 768 ? (
        // mobile view
        <>
          <Tabs
            activeKey={selectedMenu}
            items={items}
            onChange={(value) => {
              setSelectedMenu(value);
              navigate(`/inbox/${value}`, { replace: true });
            }}
          />
        </>
      ) : (
        // desktop view
        <ContainerLayout>
          <div className={styles.dashboardContainer}>
            <div style={{ background: "white" }}>
              <p className={styles.inboxHeading}>Inbox</p>
              <div className={styles.dashboardInnerContainer}>
                <div>
                  {menu.map((item) => {
                    return (
                      <div
                        className={
                          selectedMenu === item.key
                            ? styles.selectedItemBox
                            : styles.itemBox
                        }
                        onClick={() => navigate(item.url)}
                      >
                        <img
                          src={
                            selectedMenu === item.key
                              ? item.activeImage
                              : item.image
                          }
                          alt={item.image}
                        />
                        <p className={styles.label}>{item.label}</p>
                      </div>
                    );
                  })}
                </div>
                <div style={{ width: "100%" }}>
                  {menu.map((item) => {
                    if (item.key === selectedMenu) {
                      return item.component;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </ContainerLayout>
      )}
    </>
  );
}

export default InboxDashboard;
