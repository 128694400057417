import React, { useEffect, useState } from 'react';
import styles from "./Overrides.module.scss"
import { Button, Col, Form, Input, Modal, Row, Select, Table } from "antd";
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import request from '../../../../../utils/axios';
import { setLoader } from "../../../../../redux/reducer/LoaderReducer"
import { useDispatch, useSelector } from 'react-redux';
import edit from "../../../../../assets/edit.svg";
import { toast } from 'react-toastify';
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
function Overrides({ processingDate, reviewed, fetchReviewDetails }) {
    const disptach = useDispatch();
    const selectedEmployees = useSelector((state) => state.selectedEmployeePayrollProcessing);

    const [ptOverrideModal, setPtOverrideModal] = useState(false)
    const [esiOverrideModal, setEsiOverrideModal] = useState(false)
    const [lwfOverrideModal, setLWFOverrideModal] = useState(false)
    const [tdsOverrideModal, setTDSOverrideModal] = useState(false)


    const onFinishPTOverride = async (values) => {
        console.log("values : ", values)
        console.log("ptOverride : ", ptOverride.employment_number)
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-ptOverride/?month=${processingDate.month}&year=${processingDate.year}`,
                data: {
                    ...values,
                    employment_number: ptOverride.employment_number
                }

            })
            setPtOverrideModal(false);
            fetchEmployeePTOverride();
        }
        catch (err) {
            console.log(err)
        }

    }
    const onFinishESIOverride = async (values) => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-esiOverride/?month=${processingDate.month}&year=${processingDate.year}`,
                data: {
                    ...values,
                    employment_number: esiOverride.employment_number
                }

            })
            setEsiOverrideModal(false);
            fetchEmployeeESIOverride();
        }
        catch (err) {
            console.log(err)
        }

    }
    const onFinishLWFOverride = async (values) => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-lwfOverride/?month=${processingDate.month}&year=${processingDate.year}`,
                data: {
                    ...values,
                    employment_number: lwfOverride.employment_number
                }

            })
            setLWFOverrideModal(false);
            fetchEmployeeLWFOverride();
        }
        catch (err) {
            console.log(err)
        }

    }
    const onFinishTDSOverride = async (values) => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-tdsOverride/?month=${processingDate.month}&year=${processingDate.year}`,
                data: {
                    ...values,
                    employment_number: tdsOverride.employment_number
                }

            })
            setTDSOverrideModal(false);
            fetchEmployeeTDSOverride();
        }
        catch (err) {
            console.log(err)
        }

    }

    const [employeePTOverride, setEmployeePTOverride] = useState([])
    const [employeeESIOverride, setEmployeeESIOverride] = useState([])
    const [employeeLWFOverride, setEmployeeLWFOverride] = useState([])
    const [employeeTDSOverride, setEmployeeTDSOverride] = useState([])

    const [hasMore1, setHasMore1] = useState(true);
    const [hasMore2, setHasMore2] = useState(true);
    const [hasMore3, setHasMore3] = useState(true);
    const [hasMore4, setHasMore4] = useState(true);

    // Pagination of PT Override
    const [ptPagination, setPtPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const fetchEmployeePTOverride = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-ptOverride?page=${ptPagination.current}&page_size=${ptPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            setEmployeePTOverride([
                ...employeePTOverride,
                ...data.pagination_data.data
            ])
            setPtPagination({
                ...ptPagination,
                total: data?.pagination_data?.total,
                current: ptPagination.current + 1
            })
            if (data?.pagination_data?.next === null) {
                setHasMore1(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore1(false);
        }
    }
    // Pagination of ESI Override
    const [esiPagination, setEsiPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const fetchEmployeeESIOverride = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-esiOverride?page=${esiPagination.current}&page_size=${esiPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            setEmployeeESIOverride([
                ...employeeESIOverride,
                ...data?.pagination_data?.data
            ])
            setEsiPagination({
                ...esiPagination,
                total: data?.pagination_data?.total,
                current: esiPagination.current + 1
            })
            if (data?.pagination_data?.next === null) {
                setHasMore2(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore2(false);
        }
    }
    // Pagination of LWF Override
    const [lwfPagination, setLwfPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const fetchEmployeeLWFOverride = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-lwfOverride?page=${lwfPagination.current}&page_size=${lwfPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            setEmployeeLWFOverride([
                ...employeeLWFOverride,
                ...data?.pagination_data?.data
            ])
            setLwfPagination({
                ...lwfPagination,
                total: data?.pagination_data?.total,
                current: lwfPagination.current + 1
            })
            if (data?.pagination_data?.next === null) {
                setHasMore4(false);
                return;
            }
            // return data

        }
        catch (err) {
            console.log(err)
            setHasMore4(false);
        }
    }
    // Pagination of TDS Override
    const [tdsPagination, setTdsPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const fetchEmployeeTDSOverride = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employee-tdsOverride?page=${tdsPagination.current}&page_size=${tdsPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            setEmployeeTDSOverride([
                ...employeeTDSOverride,
                ...data.pagination_data.data
            ])
            // if (tdsPagination.total !== data.pagination_data.total) {
            setTdsPagination({
                ...tdsPagination,
                total: data.pagination_data.total,
                current: tdsPagination.current + 1
            });
            // }
            // setTdsPagination({
            //     ...tdsPagination,
            //     total: data?.pagination_data?.total
            // })
            // return data
            if (data?.pagination_data?.next === null) {
                setHasMore3(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore3(false);
        }
    }
    // useEffect(() => {
    //     fetchEmployeePTOverride();
    //     fetchEmployeeESIOverride();
    //     fetchEmployeeLWFOverride();
    //     fetchEmployeeTDSOverride();
    // }, [processingDate])

    const [shouldFetch1, setShouldFetch1] = useState(false);
    const [shouldFetch2, setShouldFetch2] = useState(false);
    const [shouldFetch3, setShouldFetch3] = useState(false);
    const [shouldFetch4, setShouldFetch4] = useState(false);

    useEffect(() => {
        // 1
        setPtPagination({
            ...ptPagination,
            current: 1
        })
        setEmployeePTOverride([]);
        setHasMore1(true);
        setShouldFetch1(true);

        // 2
        setEsiPagination({
            ...esiPagination,
            current: 1
        })
        setEmployeeESIOverride([]);
        setHasMore2(true);
        setShouldFetch2(true);

        // 3
        setLwfPagination({
            ...lwfPagination,
            current: 1
        })
        setEmployeeLWFOverride([]);
        setHasMore3(true);
        setShouldFetch3(true);

        // 4
        setTdsPagination({
            ...tdsPagination,
            current: 1
        })
        setEmployeeTDSOverride([]);
        setHasMore4(true);
        setShouldFetch4(true);
    }, [processingDate]);

    useEffect(() => {
        if (shouldFetch1) {
            fetchEmployeePTOverride();
            setShouldFetch1(false);
        }
        if (shouldFetch2) {
            fetchEmployeeESIOverride();
            setShouldFetch2(false);
        }
        if (shouldFetch3) {
            fetchEmployeeLWFOverride();
            setShouldFetch3(false);
        }
        if (shouldFetch4) {
            fetchEmployeeTDSOverride();
            setShouldFetch4(false);
        }
    }, [shouldFetch1, shouldFetch2, shouldFetch3, shouldFetch4]);


    const [employeeNumber, setEmployeeNumber] = useState('');

    const [ptOverride, setPTOverride] = useState({
        employment_number: "",
        gross_amount: "",
        regular_pt_amount: "",
        pt_override_amount: ""
    })
    const [esiOverride, setESIOverride] = useState({
        employment_number: "",
        gross_amount: "",
        regular_esi_amount: "",
        esi_override_amount: ""
    })
    const [lwfOverride, setLWFOverride] = useState({
        employment_number: "",
        gross_amount: "",
        regular_lwf_amount: "",
        lwf_override_amount: ""
    })
    const [tdsOverride, setTDSOverride] = useState({
        employment_number: "",
        gross_amount: "",
        regular_tds_amount: "",
        tds_override_amount: ""
    })


    const payrollProcessing = async () => {
        disptach(setLoader({ loading: true }))
        try {
            let data = await request({
                method: "post",
                url: `payroll/admin/create-employee-monthly-salary/?month=${processingDate.month}&year=${processingDate.year}`,
            })
            console.log(data)
            toast.success(`Payroll processed for ${processingDate.month} successfully`, {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to process payroll, please try after some time", {
                position: "bottom-left",
            })
        }
        disptach(setLoader({ loading: false }))
    }
    const ptOverrideRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/delete-employee-pt-override?id=${id}`,
            })
            console.log(data)
            fetchEmployeePTOverride();
        }
        catch (err) {
            console.log(err)
        }
    }
    const esiOverrideRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "delete",
                url: `payroll/admin/delete-employee-esi-override?id=${id}`,
            })
            console.log(data)
            fetchEmployeeESIOverride();
        }
        catch (err) {
            console.log(err)
        }
    }

    // useEffect(() => {
    //     fetchEmployeePTOverride();
    // }, [ptPagination.current])
    // useEffect(() => {
    //     fetchEmployeeESIOverride();
    // }, [esiPagination.current])
    // useEffect(() => {
    //     fetchEmployeeTDSOverride();
    // }, [processingDate])



   
    const ptOverrideColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_amount",
            key: "gross_amount",
        },
        {
            title: "regular PT",
            dataIndex: "regular_pt_amount",
            key: "regular_pt_amount",
        },
        {
            title: "PT Override Amount",
            dataIndex: "pt_override_amount",
            key: "pt_override_amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <img src={edit} alt="edit"
                onClick={() => {
                    setPTOverride({
                        ...ptOverride,
                        employment_number: record.employee,
                        employee_name: record.employee_name,
                        regular_pt_amount: record.regular_pt_amount,
                        gross_amount: record.gross_amount,
                        pt_override_amount: record.pt_override_amount
                    })
                    setPtOverrideModal(true)
                }}
            />,

        }
    ]
    const esiOverrideColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_amount",
            key: "gross_amount",
        },
        {
            title: "regular ESI",
            dataIndex: "regular_esi_amount",
            key: "regular_esi_amount",
        },
        {
            title: "ESI Override Amount",
            dataIndex: "esi_override_amount",
            key: "esi_override_amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <img src={edit} alt="edit"
                onClick={() => {
                    setESIOverride({
                        ...esiOverride,
                        employment_number: record.employee,
                        employee_name: record.employee_name,
                        regular_esi_amount: record.regular_esi_amount,
                        gross_amount: record.gross_amount,
                        esi_override_amount: record.esi_override_amount
                    })
                    setEsiOverrideModal(true)
                }}
            />,

        }
    ]
    const tdsOverrideColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_amount",
            key: "gross_amount",
        },
        {
            title: "regular TDS",
            dataIndex: "regular_tds_amount",
            key: "regular_tds_amount",
        },
        {
            title: "TDS Override Amount",
            dataIndex: "tds_override_amount",
            key: "tds_override_amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <img src={edit} alt="edit"
                onClick={() => {
                    setTDSOverride({
                        ...tdsOverride,
                        employment_number: record.employee,
                        employee_name: record.employee_name,
                        regular_tds_amount: record.regular_tds_amount,
                        gross_amount: record.gross_amount,
                        tds_override_amount: record.tds_override_amount
                    })
                    setTDSOverrideModal(true)
                }}
            />,
        }
    ]
    const lwfOverrideColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Gross Salary",
            dataIndex: "gross_amount",
            key: "gross_amount",
        },
        {
            title: "regular LWF",
            dataIndex: "regular_lwf_amount",
            key: "regular_lwf_amount",
        },
        {
            title: "LWF Override Amount",
            dataIndex: "lwf_override_amount",
            key: "lwf_override_amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <img src={edit} alt="edit"
                onClick={() => {
                    setLWFOverride({
                        ...lwfOverride,
                        employment_number: record.employee,
                        employee_name: record.employee_name,
                        regular_lwf_amount: record.regular_lwf_amount,
                        gross_amount: record.gross_amount,
                        lwf_override_amount: record.lwf_override_amount
                    })
                    setLWFOverrideModal(true)
                }}
            />,

        }
    ]
    const overridesTabs = [
        {
            name: "PT Override",
            key: "ptOverride",
            value: 1,
            reviewed: reviewed.pt_override
        },
        {
            name: "ESI Override",
            key: "esiOverride",
            value: 2,
            reviewed: reviewed.esi_override
        },
        {
            name: "TDS Override",
            key: "tdsOverride",
            value: 3,
            reviewed: reviewed.tds_override
        },
        {
            name: "LWF Override",
            key: "lwfOverride",
            value: 4,
            reviewed: reviewed.lwf_override
        }
    ]
    const [selectTab, setSelectTab] = useState(1)

    const payrollSummaryReviewed = async () => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-review-history`,
                data: {
                    payroll_review_tab: "overrides",
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
            payrollProcessing()
        }
        catch (error) {
            console.log(error)
        }
    }

    const payrollSummaryChild = async (childName) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-tabs-history`,
                data: {
                    parent_tab: "overrides",
                    child_tab: childName,
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
        }
        catch (error) {
            console.log(error)
        }
    }

    const renderUI1 = (dataList) => {
        return <Table
            columns={ptOverrideColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI2 = (dataList) => {
        return <Table
            columns={esiOverrideColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI3 = (dataList) => {
        return <Table
            columns={tdsOverrideColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI4 = (dataList) => {
        return <Table
            columns={lwfOverrideColumns}
            dataSource={dataList}
            pagination={false}
        />
    }


    return (

        <div className={styles.overridesContainer}>
            <div className={styles.container}>
                <div className={styles.tabButtonContainer}>
                    {
                        overridesTabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={
                                        item?.reviewed
                                            ?
                                            styles.viewedTabButton + " " + styles.tabButton
                                            :
                                            selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}

                                >
                                    <div className={
                                        item?.reviewed
                                            ?
                                            styles.viewedIndexBox
                                            :
                                            selectTab === index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                item?.reviewed
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    // selectTab > index + 1
                                                    //     ?
                                                    //     <CheckOutlined  />
                                                    //     :
                                                    item.value
                                            }

                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            {/* <CommonTable
                                columns={ptOverrideColumns}
                                data={employeePTOverride}
                                pagination={ptPagination}
                                setPagination={setPtPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("pt_override")
                                        setSelectTab(2)
                                    }}>Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={ptPagination.total - (employeePTOverride?.length)}
                                    next={fetchEmployeePTOverride}
                                    hasMore={hasMore1}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="63vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI1(employeePTOverride)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 2
                        &&
                        <>
                            {/* <CommonTable
                                columns={esiOverrideColumns}
                                data={employeeESIOverride}
                                pagination={esiPagination}
                                setPagination={setEsiPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setSelectTab(1)}
                                >Previous</button>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("esi_override")
                                        setSelectTab(3)
                                    }}
                                >Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={esiPagination.total - (employeeESIOverride?.length)}
                                    next={fetchEmployeeESIOverride}
                                    hasMore={hasMore2}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="63vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI2(employeeESIOverride)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 3
                        &&
                        <>
                            {/* <button onClick={()=> setEmployeeAdHocPaymentsModal(true)}>Create</button> */}
                            {/* <CommonTable
                                columns={tdsOverrideColumns}
                                data={employeeTDSOverride}
                                pagination={tdsPagination}
                                setPagination={setTdsPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setSelectTab(2)}
                                >Previous</button>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("tds_override")
                                        setSelectTab(4)
                                    }}
                                >Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={tdsPagination.total - (employeeTDSOverride?.length)}
                                    next={fetchEmployeeTDSOverride}
                                    hasMore={hasMore3}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="63vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI3(employeeTDSOverride)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 4
                        &&
                        <>
                            {/* <CommonTable
                                columns={lwfOverrideColumns}
                                data={employeeLWFOverride}
                                pagination={lwfPagination}
                                setPagination={setLwfPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setSelectTab(3)}
                                >Previous</button>

                                <button onClick={() => {
                                    payrollSummaryChild("lwf_override")
                                    payrollSummaryReviewed()
                                }}
                                    className={styles.btn}
                                // onClick={() => setSelectTab(1)}
                                >Submit</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={lwfPagination.total - (employeeLWFOverride?.length)}
                                    next={fetchEmployeeLWFOverride}
                                    hasMore={hasMore4}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="63vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI4(employeeLWFOverride)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal
                title="PT Override"
                centered
                open={ptOverrideModal}
                onCancel={() => setPtOverrideModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishPTOverride}
                    initialValues={ptOverride}
                    fields={[
                        {
                            name: ["gross_amount"],
                            value: ptOverride?.gross_amount
                        },
                        {
                            name: ["regular_pt_amount"],
                            value: ptOverride?.regular_pt_amount
                        }
                    ]} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="employee_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    size='large'
                                    placeholder="Enter employment number"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Gross Amount"
                                name="gross_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Regular PT Amount"
                                name="regular_pt_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="PT Override Amount"
                                name="pt_override_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter PT Override Amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter PT Override Amount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
            <Modal
                title="ESI Override"
                centered
                open={esiOverrideModal}
                onCancel={() => setEsiOverrideModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishESIOverride}
                    initialValues={esiOverride}
                    fields={[
                        {
                            name: ["gross_amount"],
                            value: esiOverride?.gross_amount
                        },
                        {
                            name: ["regular_esi_amount"],
                            value: esiOverride?.regular_esi_amount
                        }
                    ]} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="employee_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter employment number"

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Gross Amount"
                                name="gross_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Regular ESI Amount"
                                name="regular_esi_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="ESI Override Amount"
                                name="esi_override_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter ESI Override Amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter ESI Override Amount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
            <Modal
                title="LWF Override"
                centered
                open={lwfOverrideModal}
                onCancel={() => setLWFOverrideModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishLWFOverride}
                    initialValues={lwfOverride}
                    fields={[
                        {
                            name: ["gross_amount"],
                            value: lwfOverride?.gross_amount
                        },
                        {
                            name: ["regular_lwf_amount"],
                            value: lwfOverride?.regular_lwf_amount
                        }
                    ]} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="employee_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter employment number"

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Gross Amount"
                                name="gross_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Regular LWF Amount"
                                name="regular_lwf_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="LWF Override Amount"
                                name="lwf_override_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter LWF Override Amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter LWF Override Amount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
            <Modal
                title="TDS Override"
                centered
                open={tdsOverrideModal}
                onCancel={() => setTDSOverrideModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishTDSOverride}
                    initialValues={tdsOverride}
                    fields={[
                        {
                            name: ["gross_amount"],
                            value: tdsOverride?.gross_amount
                        },
                        {
                            name: ["regular_tds_amount"],
                            value: tdsOverride?.regular_tds_amount
                        }
                    ]} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="employee_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter employment number"

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Gross Amount"
                                name="gross_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Regular TDS Amount"
                                name="regular_tds_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="TDS Override Amount"
                                name="tds_override_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter TDS Override Amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter TDS Override Amount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>


        </div>
    )
}

export default Overrides