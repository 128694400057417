import React, { useEffect, useRef, useState } from 'react'
import useScreenSize from '../../../../utils/getScreenSize';
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import { Button, Form, Col, DatePicker, Input, Modal, Popover, Row, Select, Switch, Table, TimePicker, Spin } from 'antd';
import request from '../../../../utils/axios';
import moment from 'moment';
import location from "../../../../assets/location.svg"
import edit from "../../../../assets/edit.svg"
// import "./AttendancePolicyFullView.scss"
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import { DownloadOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import CalendarScheduler from '../../../../component/CalendarScheduler/CalendarScheduler';
import "./AttendanceReportFullView.scss"
import date from "../../../../assets/Attendance/Admin/date.svg";
import leave from "../../../../assets/Attendance/Admin/leave.svg";
import duration from "../../../../assets/Attendance/Admin/duration.svg"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import AttendanceCalendarReportSearchBar from '../AttendanceCalendarReportSearchBar/AttendanceCalendarReportSearchBar';
import mobileStyles from "./MobileAttendanceReportFullView.module.scss";
import dateImg from "../../../../assets/Dashboard/Admin/date.png";
import timeduration from "../../../../assets/Dashboard/Admin/duration.png";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import calendar from "../../../../assets/calendar.svg";
import download from "../../../../assets/download.png"
import InfiniteScroll from 'react-infinite-scroll-component';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs'
import { toast } from 'react-toastify';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';

function AttendanceReportFullView() {
    const ref = useRef(null);

    const [regularizationForm] = Form.useForm();

    const [openRegularizationModal, setOpenRegularizationModal] = useState(false)

    const [selectedDate, setSelectedDate] = useState(new Date())

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)

    const [selectedShift, setSelectedShift] = useState(null)
    const [downloadReportLoading, setDownloadReportLoading] = useState(false)

    const attendanceReportColumns = [
        {
            title: 'Id',
            dataIndex: 'employee_id',
            key: 'employee_id',
        },
        {
            title: ' Date',
            dataIndex: 'attendance_date',
            key: 'attendance_date',
        },
        {
            title: 'Employee',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Role/Department',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => <>
                {
                    record.designation + " / " + record.department
                }
            </>
        },
        {
            title: ' Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <>
                {
                    text === "Present" && <div style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>{text}</div>
                }
                {
                    text === "Absent" && <div style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>{text}</div>
                }
                {
                    text === "On Leave" && <div style={{ backgroundColor: record.color, padding: "5px 10px", color: "white", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>{record.leave_type}</div>
                }
                {
                    text === "Weekly Off" && <div style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>{text}</div>
                }
                {
                    text === "Yet to checkin" && <div style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Pending</div>
                }
                {
                    text === "Holiday" && <div style={{ backgroundColor: "#33b249", padding: "5px 10px", color: "white", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>{record.holiday_name}</div>
                }
                {
                    text === "Late ClockIn" && <div style={{ backgroundColor: "#f53444", padding: "5px 10px", color: "white", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>{text}</div>
                }
                {
                    text === "Early Departure" && <div style={{ backgroundColor: "rgb(0, 96, 255)", padding: "5px 10px", color: "rgb(233, 241, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Short Duration</div>
                }
                {
                    text === "LOP With Late ClockIn" && <div style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Late ClockIn/LOP</div>
                }
                {
                    text === "LOP With Short Duration" && <div style={{ backgroundColor: "rgb(0, 96, 255)", padding: "5px 10px", color: "rgb(233, 241, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Short Duration/LOP</div>
                }
            </>
        },
        {
            title: 'Check-in',
            dataIndex: 'check_in_time',
            key: 'check_in_time',
        },
        {
            title: 'Check-out',
            dataIndex: 'check_out_time',
            key: 'check_out_time',
            render: (text, record) => <span>
                {
                    text === null ? "Pending..." : text
                }
            </span>
        },
        {
            title: 'Work hours',
            dataIndex: 'working_duration',
            key: 'working_duration',
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <div>
                    {
                        !record.is_holiday && !record.is_weekly_holiday && !record.on_leave && !record.is_pending &&

                        <Button className="button" onClick={() => {
                            setSelectedDate(record.attendance_date)
                            setSelectedEmployeeId(record.employee_id)
                            setSelectedShift(record.shift_type)
                            setOpenRegularizationModal(true)
                        }}>
                            Regularise
                        </Button>


                    }
                </div>)
        }
    ]

    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const [calendarView, setCalendarView] = useState(false);
    const { Option } = Select;
    // const [loading, setLoading] = useState(true);
    const [calendarData, setCalendarData] = useState([])
    const [calendarMonth, setCalendarMonth] = useState('');
    const [calendarYear, setCalendarYear] = useState('');
    const [employeeList, setEmployeeList] = useState([]);
    const [attendanceReport, setAttendanceReport] = useState([]);
    const [employeeId, setEmployeeId] = useState('');
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const [dataLength, setDataLength] = useState(null);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);


    const fetchEmployeeList = async () => {
        const data = await request({
            method: "get",
            url: `get-all-employees`,
        })

        setEmployeeList(data.data)
        // setLoading(false)
    }

    const fetchCalendarData = async () => {
        try {
            const data = await request({
                method: "get",
                url: `attendance/admin/employee/calender/monthly/?employement_number=${employeeId}&month=${calendarMonth}&year=${calendarYear}`,
            })
            setCalendarData(data.data.monthly_attendance)
        } catch (error) {
            console.log(error)
        }
    }


    const handleSelectChange = (value) => {
        setEmployeeId(value)
    }

    const handleSwitchChange = (checked) => {
        setCalendarView(checked);
    }


    const fetchAttendanceReportMobile = async () => {
        try {
            const data = await request({
                method: "get",
                url: `attendance/admin/employees/attendance/details/?page=${page}&page_size=10`,
            })
            setItems([...items, ...data?.pagination_data?.data]);
            setDataLength(data?.pagination_data?.total);
            setPage(page + 1);
            if (data?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
        } catch (error) {
            console.log(error)
            setHasMore(false);
        }
    }

    const fetchAttendanceReport = async (pageParams, dataList) => {
        try {
            const currentPage = pageParams || page;

            if (attendanceReportDaysForTable === "custom") {
                if (attendanceReportCustomDateForTable.start_date && attendanceReportCustomDateForTable.end_date) {
                    const data = await request({
                        method: "get",
                        url: `attendance/admin/employees/attendance/details/?page=${currentPage}&page_size=10&employement_number=${searchselectedEmployeeId}&start_date=${attendanceReportCustomDateForTable.start_date}&end_date=${attendanceReportCustomDateForTable.end_date}`,
                    })
                    if (pageParams) {
                        setItems([...dataList, ...data?.pagination_data?.data]);
                    }
                    else {
                        setItems([...items, ...data?.pagination_data?.data]);
                    }

                    setDataLength(data?.pagination_data?.total);
                    setPage(currentPage + 1);
                    if (data?.pagination_data?.next === null) {
                        setHasMore(false);
                        return;
                    }
                }
            }
            else {
                const data = await request({
                    method: "get",
                    url: `attendance/admin/employees/attendance/details/?page=${currentPage}&page_size=10&employement_number=${searchselectedEmployeeId}&days=${attendanceReportDaysForTable}`,
                })
                if (pageParams) {
                    console.log("aaa///");
                    setItems([...dataList, ...data?.pagination_data?.data]);
                }
                else {
                    setItems([...items, ...data?.pagination_data?.data]);
                }
                // setItems([...items, ...data?.pagination_data?.data]);
                setDataLength(data?.pagination_data?.total);
                setPage(currentPage + 1);
                if (data?.pagination_data?.next === null) {
                    setHasMore(false);
                    return;
                }
            }
        } catch (error) {
            console.log(error)
            setHasMore(false);
        }
    }


    useEffect(() => {
        fetchEmployeeList()
    }, [])

    useEffect(() => {
        if (employeeId) {
            fetchCalendarData();
        }
    }, [employeeId, calendarMonth, calendarYear])



    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [searchselectedEmployeeId, setSearchSelectedEmployeeId] = useState("")

    // 
    const handleSearchSelectChange = async (employement_number, employee_name) => {

        setSearchValue(employee_name)
        setSearchSelectedEmployeeId(employement_number)
        // setItems([...response])
        setHasMore(false);
        setSearchEmployeeList([])

    }


    const regularizeRequest = async (values) => {
        console.log("values .....", values);
        const data = await request({
            method: "post",
            url: `attendance/admin/regularization/action?shift=${selectedShift}&employement_number=${selectedEmployeeId}`,
            data: {
                attendance_date: selectedDate,
                ...values,
                check_in_time: dayjs(values.check_in_time).format(" HH:mm:ss"),
                check_out_time: values.check_out_time ? dayjs(values.check_out_time).format(" HH:mm:ss") : null,

            },
        });
        setOpenRegularizationModal(false);
        const page = 1;
        setPage(page);
        const dataList = [];
        setHasMore(true)
        {
            screenSize.width < 768
                // mobile
                ?
                fetchAttendanceReportMobile()
                :
                // desktop
                fetchAttendanceReport(page, dataList)
        }
        regularizationForm.resetFields();
        toast.success("Regularization request submitted successfully", {
            position: "bottom-left",
        });
    }


    const search = async (data) => {
        try {
            setSearchValue(data)
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,

            })

            setSearchEmployeeList(response)
            if (data === "") {
                const page = 1;
                setPage(page);
                const dataList = [];
                setHasMore(true)
                {
                    screenSize.width < 768
                        // mobile
                        ?
                        fetchAttendanceReportMobile()
                        :
                        // desktop
                        fetchAttendanceReport(page, dataList)
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }
    // 

    const downloadReport = async () => {
        try {
            setDownloadReportLoading(true)
            if (attendanceReportDays === "custom") {
                const response = await request({
                    method: "get",
                    url: `attendance/admin/attendance-report-by-date/download?start_date=${attendanceReportCustomDate.start_date}&end_date=${attendanceReportCustomDate.end_date}`,
                    responseType: "arraybuffer",
                });
                const file = new Blob([response], { type: "application/pdf" });
                const url = window.URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = url;
                link.download = "file.pdf";
                link.click();
            }
            else {
                const response = await request({
                    method: "get",
                    url: `attendance/admin/attendance-report-by-date/download?days=${attendanceReportDays}`,
                    responseType: "arraybuffer",
                });
                const file = new Blob([response], { type: "application/pdf" });
                const url = window.URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = url;
                link.download = "file.pdf";
                link.click();
            }
            setDownloadReportLoading(false)
        }
        catch (error) {
            console.log("error...", error);
        }
    };

    const { RangePicker } = DatePicker;
    const [dateRange, setDateRange] = useState([]);
    const [attendanceReportDays, setAttendanceReportDays] = useState(7);
    const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
        start_date: "",
        end_date: ""
    });
    const [open, setOpen] = useState(false)

    const handleDateChange = async (value) => {
        const start_date = value[0]?.format("YYYY-MM-DD");
        const end_date = value[1]?.format("YYYY-MM-DD");
        setDateRange(value);
        setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
    };


    const [attendanceReportDaysForTable, setAttendanceReportDaysForTable] = useState(7);
    const [attendanceReportCustomDateForTable, setAttendanceReportCustomDateForTable] = useState({
        start_date: "",
        end_date: ""
    });

    const handleDaysChangeForTable = (value) => {
        console.log("value", value);
        if (value === "custom") {
            // setShowDatePicker(true)
            setAttendanceReportDaysForTable(value);
            setOpen(true);
            setPage(1);
            setItems([]);
            setHasMore(true);
        } else {
            // setShowDatePicker(false)
            setAttendanceReportDaysForTable(value);
            setPage(1);
            setItems([]);
            setHasMore(true);
        }
    };

    const handleDateChangeForTable = async (value) => {
        const start_date = value[0]?.format("YYYY-MM-DD");
        const end_date = value[1]?.format("YYYY-MM-DD");
        setDateRange(value);
        setAttendanceReportCustomDateForTable({ ...attendanceReportCustomDateForTable, start_date, end_date })
        setPage(1);
        setItems([]);
        setHasMore(true);
    };

    useEffect(() => {
        const page = 1;
        const dataList = [];
        {
            screenSize.width < 768
                ?
                fetchAttendanceReportMobile()
                :
                fetchAttendanceReport(page, dataList)
        }
    }, [attendanceReportDaysForTable, attendanceReportCustomDateForTable, searchselectedEmployeeId])



    const getPopupContainer = (triggerNode) => {
        return triggerNode.parentNode;
    };

    const handleDaysChange = (value) => {
        console.log("value", value);
        if (value === "custom") {
            // setShowDatePicker(true)
            setAttendanceReportDays(value);
            setOpen(true);
        } else {
            // setShowDatePicker(false)
            setAttendanceReportDays(value);
        }
    };

    // useEffect(() => {
    //     ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const [downloadModal, setDownloadModal] = useState(false)

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <>
                    <div className={mobileStyles.cardContainer}>
                        <div className={mobileStyles.box}>
                            <div className='flexSpaceBetween'>
                                <p className={mobileStyles.employeeName}>{item?.employee_name}</p>
                                {/* <button className={mobileStyles.workingPolicy}>{item.working_policy}</button> */}
                                <>
                                    {
                                        item.status === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "On Leave" && <span style={{ backgroundColor: item.color, padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{item.leave_type}</span>
                                    }
                                    {
                                        item.status === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>Pending</span>
                                    }
                                    {
                                        item.status === "Holiday" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{item.holiday_name}</span>
                                    }
                                    {
                                        item.status === "Late ClockIn" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "Early Departure" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>Short Duration</span>
                                    }
                                </>
                            </div>
                        </div>
                        <div>
                            <p className={mobileStyles.department} style={{ margin: "0px", marginBottom: "5px" }}>{item.department}</p>
                            <p className={mobileStyles.date}>
                                <img src={calendar} alt="" width={18} height={18} style={{ marginRight: "5px" }} />
                                {item.attendance_date}
                            </p>
                            <p className={mobileStyles.shift}> <img src={dateImg} alt="" style={{ marginRight: "5px" }} /><span className={mobileStyles.shiftTiming}>{item.shift_timing}</span></p>
                            <p className={mobileStyles.shift}> <img src={dateImg} alt="" style={{ marginRight: "5px" }} /><span className={mobileStyles.shiftTiming}>{item.check_in_time} - {item.check_out_time}</span></p>
                            <p className={mobileStyles.workingDuration}>
                                <img src={timeduration} alt="" style={{ marginRight: "5px" }} />
                                {item.working_duration}
                            </p>


                        </div>
                    </div>
                    <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                </>
            )
        })
    }

    const attendanceReportDownloadPopover = (
        <>
            <Select
                style={{ width: 300, height: 40, marginBottom: "15px" }}
                placeholder="Select an option"
                onChange={handleDaysChange}
                dafaultValue={attendanceReportDays}
            >
                <Select.Option value={7}>Last 7 days</Select.Option>
                <Select.Option value={14}>Last 14 days</Select.Option>
                <Select.Option value={30}>Last 30 days</Select.Option>
                <Select.Option value="custom">
                    <Popover
                        title=""
                        content={
                            <div className="modal-content">
                                <RangePicker
                                    value={dateRange}
                                    onChange={handleDateChange}
                                    format="YYYY-MM-DD"
                                    disabledDate={(current) =>
                                        current && current > moment().endOf("day")
                                    }
                                />
                            </div>
                        }
                        getPopupContainer={getPopupContainer}
                        placement="right"
                    >
                        <p
                            style={{
                                cursor: "pointer",
                                width: "90%",
                                margin: "0px",
                            }}
                        >
                            Custom Date
                        </p>
                    </Popover>
                </Select.Option>
            </Select>
            <br />
            <button
                style={{
                    border: "none",
                    color: "white",
                    background: "#008080",
                    borderRadius: "4px",
                    height: "36px",
                    // padding: "0px 10px",
                    width: "120px",
                }}
                onClick={() => downloadReport()}
            >
                {downloadReportLoading ? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} /> : "Download"}
            </button>
        </>
    )

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={attendanceReportColumns}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }

    return (
        <div className='attendanceReportFullViewContainer' ref={ref}>
            {
                screenSize.width < 768
                    ?
                    // mobile view
                    <div className='referralSubContainer'>
                        <div style={{ padding: "10px", background: "white" }}>
                            <div className='flexSpaceBetween' style={{ marginBottom: "20px" }}>
                                <p
                                    style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}
                                >Attendance Report</p>
                                <img src={download} alt="download" width={25} height={25}
                                    onClick={() => {
                                        setDownloadModal(true);
                                    }}
                                />
                            </div>


                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                                <div>
                                    <Switch
                                        checked={calendarView}
                                        onChange={handleSwitchChange}
                                        size='small'
                                    />
                                    <span style={{ marginLeft: "10px", fontSize: "12px", color: "#616161" }}>
                                        {
                                            calendarView ? "Calendar View" : "Calendar View"
                                        }
                                    </span>
                                </div>
                            </div>
                            <div>

                                {
                                    !calendarView &&
                                    <div className="search-container">
                                        <input
                                            type="text"
                                            placeholder="Search for employees"
                                            onChange={(e) => search(e.target.value)}
                                            style={{
                                                height: "40px",
                                                width: "100%",
                                            }}
                                        />
                                        {searchEmployeeList.length > 0 && (
                                            <ul>
                                                {searchEmployeeList.map((item, index) => (
                                                    <li key={index} onClick={() => handleSearchSelectChange(item.employement_number, item.employee_name)}>
                                                        {item.employee_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                }
                            </div>
                            {
                                calendarView
                                &&
                                <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                                    <AttendanceCalendarReportSearchBar
                                        setTableDataForSearchEmployee={setCalendarData}
                                        fetchTableDataFromApi={null}
                                        placeholderText="Search for employees"
                                        empType="admin"
                                        setEmployeeId={setEmployeeId}
                                    />
                                </div>
                            }


                        </div>
                        <div >
                            {
                                calendarView
                                    ?
                                    <CalendarScheduler
                                        data={calendarData}
                                        setCalendarMonth={setCalendarMonth}
                                        setCalendarYear={setCalendarYear}
                                        employeeId={employeeId}
                                    />
                                    :
                                    // <InfiniteScrollComponent
                                    //     apiEndpoint="attendance/admin/employees/attendance/details/"
                                    //     renderUI={renderUI}
                                    //     initialPage={1}
                                    // />
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: "auto",
                                            overflow: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                        }}
                                    >
                                        <InfiniteScroll
                                            dataLength={dataLength - (items.length)}
                                            next={fetchAttendanceReportMobile}
                                            hasMore={hasMore}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                            loader={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Loading...</b>
                                                </p>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            height="70vh"
                                        >
                                            {
                                                renderUI(items)
                                            }
                                        </InfiniteScroll>
                                    </div>
                            }
                        </div>
                    </div>

                    :
                    // Desktop View
                    <>
                        <ContainerLayout>
                            <div style={{ padding: "30px", background: "white" }}>
                                <div className='flexSpaceBetween'>
                                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                                        <p
                                            style={{ fontSize: "20px", fontWeight: "600" }}
                                        >Attendance Report</p>
                                        <span>
                                            <Switch
                                                checked={calendarView}
                                                onChange={handleSwitchChange}
                                            />
                                            {" "}
                                            <span style={{ paddingLeft: "10px" }}>
                                                {
                                                    calendarView ? "Calendar View" : "Calendar View"
                                                }
                                            </span>
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "30px", marginBottom: "30px" }}>
                                        <>

                                            <Select
                                                style={{ width: 300, height: 40 }}
                                                placeholder="Select an option"
                                                onChange={handleDaysChangeForTable}
                                                // dafaultValue={attendanceReportDaysForTable}
                                                value={attendanceReportDaysForTable}
                                            >
                                                <Select.Option value={7}>Last 7 days</Select.Option>
                                                <Select.Option value={14}>Last 14 days</Select.Option>
                                                <Select.Option value={30}>Last 30 days</Select.Option>
                                                <Select.Option value="custom">
                                                    <Popover
                                                        title=""
                                                        content={
                                                            <div className="modal-content">
                                                                <RangePicker
                                                                    value={dateRange}
                                                                    onChange={handleDateChangeForTable}
                                                                    format="YYYY-MM-DD"
                                                                    disabledDate={(current) =>
                                                                        current && current > moment().endOf("day")
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                        getPopupContainer={getPopupContainer}
                                                        placement="right"
                                                    >
                                                        <p
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "90%",
                                                                margin: "0px",
                                                            }}
                                                        >
                                                            Custom Date
                                                        </p>
                                                    </Popover>
                                                </Select.Option>
                                            </Select>

                                            {
                                                !calendarView &&
                                                <div className="search-container">
                                                    <Input
                                                        size='small'
                                                        type="text"
                                                        placeholder="Search for employees"
                                                        onChange={(e) => search(e.target.value)}
                                                        value={searchValue}
                                                        prefix={<SearchOutlined />}
                                                        allowClear
                                                    />
                                                    {searchEmployeeList.length > 0 && (
                                                        <ul>
                                                            {searchEmployeeList.map((item, index) => (
                                                                <li key={index} onClick={() => handleSearchSelectChange(item.employement_number, item.employee_name)}>
                                                                    {item.employee_name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            }

                                        </>

                                        <Popover placement="bottomRight" trigger="click" title="Download report" content={attendanceReportDownloadPopover} arrow={false}>
                                            <img src={download} alt="download" width={35} height={35}
                                            />
                                        </Popover>
                                    </div>
                                </div>
                                <div>
                                    <>
                                        {
                                            calendarView
                                            &&
                                            <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                                                <AttendanceCalendarReportSearchBar
                                                    setTableDataForSearchEmployee={setCalendarData}
                                                    fetchTableDataFromApi={null}
                                                    placeholderText="Search for employees"
                                                    empType="admin"
                                                    setEmployeeId={setEmployeeId}
                                                />
                                            </div>
                                        }
                                    </>

                                </div>
                                {
                                    calendarView
                                        ?
                                        <CalendarScheduler
                                            data={calendarData}
                                            setCalendarMonth={setCalendarMonth}
                                            setCalendarYear={setCalendarYear}
                                            employeeId={employeeId}
                                        />
                                        :
                                        <div
                                            id="scrollableDiv"
                                            style={{
                                                height: "auto",
                                                overflow: 'auto',
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                            }}
                                        >
                                            <InfiniteScroll
                                                dataLength={dataLength - (items.length)}
                                                next={fetchAttendanceReport}
                                                hasMore={hasMore}
                                                endMessage={
                                                    <p style={{ textAlign: 'center' }}>
                                                        <b>Yay! You have seen it all</b>
                                                    </p>
                                                }
                                                loader={
                                                    <p style={{ textAlign: 'center' }}>
                                                        <b>Loading...</b>
                                                    </p>
                                                }
                                                scrollableTarget="scrollableDiv"
                                                height="70vh"
                                            >
                                                {
                                                    renderUIDesktop(items)
                                                }
                                            </InfiniteScroll>
                                        </div>

                                }

                            </div>
                        </ContainerLayout>

                    </>

            }

            <Modal
                title="Download Report"
                centered
                open={downloadModal}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setDownloadModal(false)}
                width={800}
                footer={null}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                    <Select
                        style={{ width: "100%", marginTop: "20px" }}
                        placeholder="Select an option"
                        onChange={handleDaysChange}
                        defaultValue={attendanceReportDays}
                        size='large'
                    >
                        <Select.Option value={7}>Last 7 days</Select.Option>
                        <Select.Option value={14}>Last 14 days</Select.Option>
                        <Select.Option value={30}>Last 30 days</Select.Option>
                        <Select.Option value="custom">
                            <span
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                Custom Date
                            </span>
                        </Select.Option>
                    </Select>

                    <Modal
                        title="Select Date Range"
                        centered
                        open={open}
                        onCancel={() => setOpen(false)}
                        onOk={async () => {

                            setOpen(false);
                        }}
                    >
                        <RangePicker
                            value={dateRange}
                            onChange={handleDateChange}
                            format="YYYY-MM-DD"
                            disabledDate={(current) =>
                                current && current > moment().endOf("day")
                            }
                            style={{ color: "black !important" }}
                        />
                    </Modal>


                </div>

                <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
                    onClick={() => downloadReport()}
                >
                    {downloadReportLoading ? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} /> : "Download"}
                </Button>

            </Modal>

            {/* regularization request */}
            <Modal
                centered
                open={openRegularizationModal}
                footer={null}
                onCancel={() => {
                    setOpenRegularizationModal(false);
                    setSelectedEmployeeId(null);
                    setSelectedShift(null);
                    regularizationForm.resetFields();
                }}

            >
                <p style={{ fontSize: "20px", lineHeight: "30px", fontWeight: "500" }}>Regularisation Request</p>
                <Form
                    form={regularizationForm}
                    onFinish={regularizeRequest}
                    layout="vertical"
                // fields={[
                //   {
                //     name: ['start_date'],
                //     value: GetJoiningDateForEmployee ? dayjs(GetJoiningDateForEmployee) : null
                //   }
                // ]}
                >
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Check In"
                                name="check_in_time"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    // placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time, "time.....................");
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                    }}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Check Out"
                                name="check_out_time"
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    // placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time, "time.....................22222222222222");
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please add Reason!",
                                },
                            ]}
                        >
                            <TextArea
                                rows={5}
                                placeholder="Enter Your Reason"
                                className="leaveTextArea"
                                onChange={(e) =>
                                    "reason"
                                    // handleFormChange("reason", e.target.value)
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Button
                        htmlType="submit"
                        className="forgotPasswordButton"
                    >
                        Update
                    </Button>
                </Form>
            </Modal>


        </div>
    )
}

export default AttendanceReportFullView