import { Badge, Button, Checkbox, Col, ColorPicker, Form, Input, Radio, Row, Select, Switch } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import { toast } from 'react-toastify';
import "./LeaveTypeForm.scss"
import { useNavigate, useParams } from 'react-router-dom'
import { EditFilled, EditOutlined } from '@ant-design/icons';
import infoImage from "../../../../assets/info.png"
import { Tooltip } from 'antd';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';
function LeaveTypeForm({ changeTabsValue }) {
    const navigate = useNavigate();
    const ref = useRef(null);
    const [leavTypeForm, setLeaveTypeForm] = useState({
        name: "",
        description: "",
        is_for_male: false,
        is_for_female: false,
        is_for_others: false,
        is_for_full_time: false,
        is_for_part_time: false,
        is_for_contractual: false,
        is_for_internship: false,
        is_for_married: false,
        is_for_single: false,
        is_predefined: false,
        is_pro_rata: false,
        is_comp_off_leave_type: false,
        is_once_during_employement_year: false,
        is_of_monthly_basis: false,
        is_document_required: false,
        is_allowed_during_notice_period: false,
        is_allowed_during_probation_period: false,
        is_half_day_interval_allowed: false,
        is_waiting_period: false,
        waiting_period: "",
        is_weekly_off_inclusion: false,
        is_back_dated_allowed: false,
        is_carry_forward_leaves: false,
        carry_forward_amount: "",
        is_encashement: false,
        enchashement_amount: "",
        is_cooling_off_period: false,
        cooling_off_period: "",
        is_applicable_for_new_joinee: false,
        new_joinee_days: "",
        is_disallowed: false,
        in_conjunction_with: "",
        in_conditional_with: "",
        conditional_balance: "",
        is_max_allowed: false,
        consecutive_max_allowed_days: "",
        defined_period_max_allowed: "",
        is_defined_period_max_allowed_month: false,
        is_minimum_allowed: false,
        minimum_allowed_days: "",
        color: "",
        image: null
    });
    const bgColor = useMemo(() => (typeof leavTypeForm.color === 'string' ? leavTypeForm.color : leavTypeForm?.color?.toHexString()), [leavTypeForm.color]);

    const btnStyle = {
        backgroundColor: bgColor,
    };
    const [editImage, setEditImage] = useState(null)
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const params = useParams();
    const handleFormChange = (changedValues, allValues) => {
        if (!changedValues.image) {
            if (changedValues?.color) {
                setLeaveTypeForm({ ...leavTypeForm, ...changedValues, color: changedValues?.color?.toHexString().substring(0, 7) })
            }
            else {
                setLeaveTypeForm({ ...leavTypeForm, ...changedValues })
            }
        }
    }

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append('name', leavTypeForm.name);
        formData.append('description', leavTypeForm.description);
        formData.append('is_for_male', leavTypeForm.is_for_male);
        formData.append('is_for_female', leavTypeForm.is_for_female);
        formData.append('is_for_others', leavTypeForm.is_for_others);
        formData.append('is_for_full_time', leavTypeForm.is_for_full_time);
        formData.append('is_for_part_time', leavTypeForm.is_for_part_time);
        formData.append('is_for_contractual', leavTypeForm.is_for_contractual);
        formData.append('is_for_internship', leavTypeForm.is_for_internship);
        formData.append('is_for_married', leavTypeForm.is_for_married);
        formData.append('is_for_single', leavTypeForm.is_for_single);
        formData.append('is_predefined', leavTypeForm.is_predefined);
        formData.append('is_comp_off_leave_type', leavTypeForm.is_comp_off_leave_type);
        formData.append('is_pro_rata', leavTypeForm.is_pro_rata);
        formData.append('is_once_during_employement_year', leavTypeForm.is_once_during_employement_year);
        formData.append('is_of_monthly_basis', leavTypeForm.is_of_monthly_basis);
        formData.append('is_document_required', leavTypeForm.is_document_required);
        formData.append('is_allowed_during_notice_period', leavTypeForm.is_allowed_during_notice_period);
        formData.append('is_allowed_during_probation_period', leavTypeForm.is_allowed_during_probation_period);
        formData.append('is_half_day_interval_allowed', leavTypeForm.is_half_day_interval_allowed);
        formData.append('is_waiting_period', leavTypeForm.is_waiting_period);
        formData.append('waiting_period', leavTypeForm.waiting_period ? leavTypeForm.waiting_period : "");
        formData.append('is_weekly_off_inclusion', leavTypeForm.is_weekly_off_inclusion);
        formData.append('is_back_dated_allowed', leavTypeForm.is_back_dated_allowed);
        formData.append('is_carry_forward_leaves', leavTypeForm.is_carry_forward_leaves);
        formData.append('carry_forward_amount', leavTypeForm.carry_forward_amount ? leavTypeForm.carry_forward_amount : "");
        formData.append('is_encashement', leavTypeForm.is_encashement);
        formData.append('enchashement_amount', leavTypeForm.enchashement_amount ? leavTypeForm.enchashement_amount : "");
        formData.append('is_cooling_off_period', leavTypeForm.is_cooling_off_period);
        formData.append('cooling_off_period', leavTypeForm.cooling_off_period ? leavTypeForm.cooling_off_period : "");
        formData.append('is_applicable_for_new_joinee', leavTypeForm.is_applicable_for_new_joinee);
        formData.append('new_joinee_days', leavTypeForm.new_joinee_days ? leavTypeForm.new_joinee_days : "");
        formData.append('is_disallowed', leavTypeForm.is_disallowed);
        formData.append('in_conjunction_with', leavTypeForm.in_conjunction_with ? leavTypeForm.in_conjunction_with : "");
        formData.append('in_conditional_with', leavTypeForm.in_conditional_with ? leavTypeForm.in_conditional_with : "");
        formData.append('conditional_balance', leavTypeForm.conditional_balance ? leavTypeForm.conditional_balance : "");
        formData.append('is_max_allowed', leavTypeForm.is_max_allowed);
        formData.append('consecutive_max_allowed_days', leavTypeForm.consecutive_max_allowed_days ? leavTypeForm.consecutive_max_allowed_days : "");
        formData.append('defined_period_max_allowed', leavTypeForm.defined_period_max_allowed === null ? "" : leavTypeForm.defined_period_max_allowed);
        formData.append('is_defined_period_max_allowed_month', leavTypeForm.is_defined_period_max_allowed_month);
        formData.append('is_minimum_allowed', leavTypeForm.is_minimum_allowed);
        formData.append('minimum_allowed_days', leavTypeForm.minimum_allowed_days ? leavTypeForm.minimum_allowed_days : "");
        formData.append('color', leavTypeForm.color);
        formData.append('image', typeof leavTypeForm.image === "object" ? leavTypeForm.image : null);

        try {
            if (params.id) {
                await request({
                    method: "put",
                    url: `leave/leave_type/update/?id=${params.id}`,
                    data: formData
                })

                toast.success("Leave Type updated", {
                    position: "bottom-left",
                });

                navigate(`/admin/leave`)
            }
            else {
                await request({
                    method: "post",
                    url: "leave/leave_type/create/",
                    data: formData
                })

                toast.success("New leave Type added", {
                    position: "bottom-left",
                });

                changeTabsValue("1")
            }


        }
        catch (err) {
            console.log(err);
            if (params.id) {
                toast.success("Leave Type could not be updated, please try after some time", {
                    position: "bottom-left",
                });
            }
            else {
                toast.success("New Leave Type could not be added, please try after some time", {
                    position: "bottom-left",
                });
            }
        }
    }

    const fetchSpecficLeaveType = async () => {
        try {
            const data = await request({
                method: "get",
                url: `leave/leave_type/detail/?id=${params.id}`,
            })

            setLeaveTypeForm(data.data)
        }
        catch (err) {
            console.log(err);
        }
    }

    const [leaveTypeList, setLeaveTypeList] = useState([])
    const fetchLeaveTypeList = async () => {
        try {
            const data = await request({
                method: "get",
                url: "/leave/leave_types/all/",
            })

            setLeaveTypeList(data)
        }
        catch (error) {
            console.log("error...", error);
        }
    }
    useEffect(() => {
        if (params.id) {
            fetchSpecficLeaveType();
        }
        fetchLeaveTypeList();
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div className='leaveTypeFormContainer' ref={ref}>
            <ContainerLayout>
                <h1 className='heading'>Leave Type</h1>
                <div className='leaveTypeForm'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        onValuesChange={handleFormChange}
                        initialValues={leavTypeForm}
                        fields={[
                            {
                                name: ['name'],
                                value: leavTypeForm.name
                            },
                            {
                                name: ['description'],
                                value: leavTypeForm.description
                            },
                            {
                                name: ['is_for_male'],
                                value: leavTypeForm.is_for_male
                            },
                            {
                                name: ['is_for_female'],
                                value: leavTypeForm.is_for_female
                            },
                            {
                                name: ['is_for_others'],
                                value: leavTypeForm.is_for_others
                            },
                            {
                                name: ['is_for_full_time'],
                                value: leavTypeForm.is_for_full_time
                            },
                            {
                                name: ['is_for_part_time'],
                                value: leavTypeForm.is_for_part_time
                            },
                            {
                                name: ['is_for_contractual'],
                                value: leavTypeForm.is_for_contractual
                            },
                            {
                                name: ['is_for_temporary'],
                                value: leavTypeForm.is_for_temporary
                            },
                            {
                                name: ['is_predefined'],
                                value: leavTypeForm.is_predefined
                            },
                            {
                                name: ['is_comp_off_leave_type'],
                                value: leavTypeForm.is_comp_off_leave_type
                            },
                            {
                                name: ['is_pro_rata'],
                                value: leavTypeForm.is_pro_rata
                            },
                            {
                                name: ['is_once_during_employement_year'],
                                value: leavTypeForm.is_once_during_employement_year
                            },
                            {
                                name: ['is_of_monthly_basis'],
                                value: leavTypeForm.is_of_monthly_basis
                            },
                            {
                                name: ['is_document_required'],
                                value: leavTypeForm.is_document_required
                            },
                            {
                                name: ['is_allowed_during_notice_period'],
                                value: leavTypeForm.is_allowed_during_notice_period
                            },
                            {
                                name: ['is_allowed_during_probation_period'],
                                value: leavTypeForm.is_allowed_during_probation_period
                            },
                            {
                                name: ['is_half_day_interval_allowed'],
                                value: leavTypeForm.is_half_day_interval_allowed
                            },
                            {
                                name: ['is_waiting_period'],
                                value: leavTypeForm.is_waiting_period
                            },
                            {
                                name: ['waiting_period'],
                                value: leavTypeForm.waiting_period
                            },
                            {
                                name: ['is_weekly_off_inclusion'],
                                value: leavTypeForm.is_weekly_off_inclusion
                            },
                            {
                                name: ['is_back_dated_allowed'],
                                value: leavTypeForm.is_back_dated_allowed
                            },
                            {
                                name: ['is_carry_forward_leaves'],
                                value: leavTypeForm.is_carry_forward_leaves
                            },
                            {
                                name: ['carry_forward_amount'],
                                value: leavTypeForm.carry_forward_amount
                            },
                            {
                                name: ['is_encashement'],
                                value: leavTypeForm.is_encashement
                            },
                            {
                                name: ['enchashement_amount'],
                                value: leavTypeForm.enchashement_amount
                            },
                            {
                                name: ['is_cooling_off_period'],
                                value: leavTypeForm.is_cooling_off_period
                            },
                            {
                                name: ['cooling_off_period'],
                                value: leavTypeForm.cooling_off_period
                            },
                            {
                                name: ['is_applicable_for_new_joinee'],
                                value: leavTypeForm.is_applicable_for_new_joinee
                            },
                            {
                                name: ['new_joinee_days'],
                                value: leavTypeForm.new_joinee_days
                            },
                            {
                                name: ['is_disallowed'],
                                value: leavTypeForm.is_disallowed
                            },
                            {
                                name: ['in_conjunction_with'],
                                value: leavTypeForm.in_conjunction_with
                            },
                            {
                                name: ['in_conditional_with'],
                                value: leavTypeForm.in_conditional_with
                            },
                            {
                                name: ['conditional_balance'],
                                value: leavTypeForm.conditional_balance
                            },
                            {
                                name: ['is_max_allowed'],
                                value: leavTypeForm.is_max_allowed
                            },
                            {
                                name: ['consecutive_max_allowed_days'],
                                value: leavTypeForm.consecutive_max_allowed_days
                            },
                            {
                                name: ['defined_period_max_allowed'],
                                value: leavTypeForm.defined_period_max_allowed
                            },
                            {
                                name: ['is_defined_period_max_allowed_month'],
                                value: leavTypeForm.is_defined_period_max_allowed_month
                            },
                            {
                                name: ['is_minimum_allowed'],
                                value: leavTypeForm.is_minimum_allowed
                            },
                            {
                                name: ['minimum_allowed_days'],
                                value: leavTypeForm.minimum_allowed_days
                            },
                            {
                                name: ['color'],
                                value: leavTypeForm.color
                            },
                            {
                                name: ['image'],
                                // value: leavTypeForm.image
                            }
                        ]}
                        form={form}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        //  className='leaveInputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    name="description"
                                    label="Description"
                                >
                                    <TextArea
                                        rows={5}
                                        placeholder="Enter Description"
                                        // className='leaveTextArea'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                                <p>Gender</p>
                                <Row>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_male"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_male}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_male: !leavTypeForm.is_for_male
                                                })}
                                            >Male</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_female"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_female}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_female: !leavTypeForm.is_for_female
                                                })}
                                            >Female</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_others"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_others}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_others: !leavTypeForm.is_for_others
                                                })}
                                            >Other</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} lg={24}>
                                <p>Employment Type</p>
                                <Row>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_contractual"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_contractual}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_contractual: !leavTypeForm.is_for_contractual
                                                })}
                                            >Contractual</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_full_time"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_full_time}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_full_time: !leavTypeForm.is_for_full_time
                                                })}
                                            >Permanent</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_part_time"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_part_time}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_part_time: !leavTypeForm.is_for_part_time
                                                })}
                                            >Part Time</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_internship"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_internship}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_internship: !leavTypeForm.is_for_internship
                                                })}
                                            >Temporary</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} lg={24}>
                                <p>Marital Status</p>
                                <Row>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_married"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_married}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_married: !leavTypeForm.is_for_married
                                                })}
                                            >
                                                Married
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col sx={8} md={4}>
                                        <Form.Item
                                            name="is_for_single"
                                        >
                                            <Checkbox
                                                checked={leavTypeForm.is_for_single}
                                                onChange={() => setLeaveTypeForm({
                                                    ...leavTypeForm,
                                                    is_for_single: !leavTypeForm.is_for_single
                                                })}
                                            >Unmarried</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_predefined"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={!leavTypeForm.is_predefined}
                                        name="is_predefined"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_predefined: !leavTypeForm.is_predefined
                                        })}
                                    />Approval Required

                                    <Tooltip color='white' placement="bottom" title="Leave type requires approval from the reporting manager" overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>

                                    {/* <img src={infoImage} alt="noimg" style={{height:"20px",width:"20px",marginLeft:"5px"}}/> */}
                                </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                <Form.Item
                                    name="is_comp_off_leave_type"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_comp_off_leave_type}
                                        name="is_comp_off_leave_type"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_comp_off_leave_type: !leavTypeForm.is_comp_off_leave_type
                                        })}
                                    />Compensatory Off
                                    <Tooltip color='white' placement="bottom" title="This is a tooltip" overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>                            </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_pro_rata"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_pro_rata}
                                        name="is_pro_rata"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_pro_rata: !leavTypeForm.is_pro_rata
                                        })}
                                    />Pro Rata
                                    <Tooltip color='white' placement="bottom" title="Leaves will be accumulated for the user on a pro-rata basis from the date of joining." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>                            </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_once_during_employement_year"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_once_during_employement_year}
                                        name="is_once_during_employement_year"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_once_during_employement_year: !leavTypeForm.is_once_during_employement_year
                                        })}
                                    />Once During Employment Period
                                    <Tooltip color='white' placement="bottom" title="These leaves can be availed only once per employment period." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>                            </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="add"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={true}
                                        name="add"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            add: !leavTypeForm.add,
                                            is_of_monthly_basis: false
                                        })}
                                    />Leave Type
                                    <Tooltip color='white' placement="bottom" title="The allocated leave quote is supposed to be monthly or annual" overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>                            </Form.Item>
                                {
                                    true &&
                                    <Form.Item
                                        name="is_of_monthly_basis"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select one!',
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                        // value={size}
                                        >
                                            <Radio.Button value={true}>Month</Radio.Button>
                                            <Radio.Button value={false}>Annual</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                }

                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_document_required"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_document_required}
                                        name="is_document_required"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_document_required: !leavTypeForm.is_document_required
                                        })}
                                    />
                                    Document Upload
                                    <Tooltip color='white' placement="bottom" title="Employees need to upload supporting documents to avail leaves." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>                            </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_allowed_during_notice_period"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_allowed_during_notice_period}
                                        name="is_allowed_during_notice_period"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_allowed_during_notice_period: !leavTypeForm.is_allowed_during_notice_period
                                        })}
                                    />
                                    Notice Period
                                    <Tooltip color='white' placement="bottom" title="Employee can take this leave type or not during notice period" overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                <Form.Item
                                    name="is_allowed_during_probation_period"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_allowed_during_probation_period
                                        }
                                        name="is_allowed_during_probation_period"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_allowed_during_probation_period
                                                : !leavTypeForm.is_allowed_during_probation_period
                                        })}
                                    />
                                    Probation Period
                                    <Tooltip color='white' placement="bottom" title="Employee can take this leave type or not during probation period" overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_half_day_interval_allowed"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_half_day_interval_allowed
                                        }
                                        name="is_half_day_interval_allowed"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_half_day_interval_allowed
                                                : !leavTypeForm.is_half_day_interval_allowed
                                        })}
                                    />
                                    Half-day Allowed
                                    <Tooltip color='white' placement="bottom" title="Half-Day leaves are allowed." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>



                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }}>

                                <Form.Item
                                    name="is_waiting_period"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_waiting_period
                                        }
                                        name="is_waiting_period"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_waiting_period
                                                : !leavTypeForm.is_waiting_period,
                                            waiting_period: ""
                                        })}
                                    />
                                    Waiting Period
                                    <Tooltip color='white' placement="bottom" title="The employee has to work for a certain period before the leave starts to be applicable." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>
                                {
                                    leavTypeForm.is_waiting_period
                                    &&
                                    <Form.Item
                                        name="waiting_period"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select one!',
                                            }
                                        ]}
                                    >
                                        <Select style={{ marginRight: "5px" }}
                                            size="large"
                                            // value={leavTypeForm.waiting_period}
                                            placeholder="No. of Days"
                                        >
                                            {[...Array(50)].map((_, i) => (
                                                <Select.Option key={i + 1}
                                                    value={i + 1}
                                                >
                                                    {i + 1}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_weekly_off_inclusion"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_weekly_off_inclusion
                                        }
                                        name="is_weekly_off_inclusion"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_weekly_off_inclusion
                                                : !leavTypeForm.is_weekly_off_inclusion
                                        })}
                                    />
                                    Weekly Off
                                    <Tooltip color='white' placement="bottom" title="Include weekly off leaves for the employee if they come in the middle of the employee leave application." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_back_dated_allowed"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_back_dated_allowed
                                        }
                                        name="is_back_dated_allowed"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_back_dated_allowed
                                                : !leavTypeForm.is_back_dated_allowed
                                        })}
                                    />
                                    Back Dated Allowed
                                    <Tooltip color='white' placement="bottom" title="Allows Employees to mark back datesd leaves." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }}>

                                <Form.Item
                                    name="is_carry_forward_leaves"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_carry_forward_leaves
                                        }
                                        name="is_carry_forward_leaves"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_carry_forward_leaves: !leavTypeForm.is_carry_forward_leaves,
                                            carry_forward_amount: ""
                                        })}
                                    />
                                    Carry Forward
                                    <Tooltip color='white' placement="bottom" title="No. of leaves" overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>
                                {
                                    leavTypeForm.is_carry_forward_leaves
                                    &&
                                    <Form.Item
                                        name="carry_forward_amount"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select one!',
                                            }
                                        ]}
                                    >
                                        <Select style={{ marginRight: "5px" }}
                                            size="large"
                                            // value={item.leaves_numbers}
                                            placeholder="No. of Days"
                                        >
                                            {[...Array(50)].map((_, i) => (
                                                <Select.Option key={i + 1}
                                                    value={i + 1}
                                                >
                                                    {i + 1}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }


                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                <Form.Item
                                    name="is_encashement"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_encashement
                                        }
                                        name="is_encashement"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_encashement: !leavTypeForm.is_encashement,
                                            enchashement_amount: "",

                                        })}
                                    />
                                    Encashment
                                    <Tooltip color='white' placement="bottom" title="These leaves are available for encashment at the end of employment period." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>
                                {
                                    leavTypeForm.is_encashement
                                    &&
                                    <Row>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                            <div style={{
                                                border: "1px solid #D7D7D7",
                                                borderRadius: "5px",
                                                // width: "100%",
                                                padding: "10px 15px"
                                            }}>
                                                <Form.Item
                                                    name="enchashement_amount"
                                                    label="Max No. Of Encashment"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select one!',
                                                        }
                                                    ]}
                                                >
                                                    <Select style={{ marginRight: "5px" }}
                                                        size="large"
                                                        // value={item.leaves_numbers}
                                                        placeholder="No. Of Leaves"
                                                    >
                                                        {[...Array(50)].map((_, i) => (
                                                            <Select.Option key={i + 1}
                                                                value={i + 1}
                                                            >
                                                                {i + 1}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <p>Eligibility</p>
                                                <Row>
                                                    <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                                                        <Form.Item
                                                            name=""
                                                            // label="Max No. Of Encashment"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select one!',
                                                                }
                                                            ]}
                                                        >
                                                            <Select style={{ marginRight: "5px", width: "90%" }}
                                                                size="large"
                                                                // value={item.leaves_numbers}
                                                                placeholder="Month"

                                                            >
                                                                {[...Array(50)].map((_, i) => (
                                                                    <Select.Option key={i + 1}
                                                                        value={i + 1}
                                                                    >
                                                                        {i + 1}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                                                        <Form.Item
                                                            name=""
                                                            // label="Max No. Of Encashment"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select one!',
                                                                }
                                                            ]}
                                                        >
                                                            <Select style={{ marginRight: "5px" }}
                                                                size="large"
                                                                // value={item.leaves_numbers}
                                                                placeholder="Year"
                                                            >
                                                                {[...Array(50)].map((_, i) => (
                                                                    <Select.Option key={i + 1}
                                                                        value={i + 1}
                                                                    >
                                                                        {i + 1}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div></Col>

                                    </Row>
                                }

                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }}>

                                <Form.Item
                                    name="is_cooling_off_period"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_cooling_off_period
                                        }
                                        name="is_cooling_off_period"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_cooling_off_period: !leavTypeForm.is_cooling_off_period,
                                            cooling_off_period: ""
                                        })}
                                    />
                                    Cooling off Period
                                    <Tooltip color='white' placement="bottom" title="Minimum Gap required before taking the same type of leave for the second instance." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                                {
                                    leavTypeForm.is_cooling_off_period
                                    &&
                                    <Form.Item
                                        name="cooling_off_period"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select one!',
                                            }
                                        ]}
                                    >
                                        <Select style={{ marginRight: "5px" }}
                                            size="large"
                                            // value={item.leaves_numbers}
                                            placeholder="No. of Days"
                                        >
                                            {[...Array(50)].map((_, i) => (
                                                <Select.Option key={i + 1}
                                                    value={i + 1}
                                                >
                                                    {i + 1}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }


                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                <Form.Item
                                    name="is_applicable_for_new_joinee"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_applicable_for_new_joinee
                                        }
                                        name="is_applicable_for_new_joinee"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_applicable_for_new_joinee
                                                : !leavTypeForm.is_applicable_for_new_joinee,
                                            new_joinee_days: ""
                                        })}
                                    />
                                    New Joinee
                                    <Tooltip color='white' placement="bottom" title="Full or prorata as per date of joining or confirmation date." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                                {
                                    leavTypeForm.is_applicable_for_new_joinee
                                    &&
                                    <Row>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                            <Form.Item
                                                name="new_joinee_days"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select one!',
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    size='large'
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }



                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_disallowed"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_disallowed
                                        }
                                        name="is_disallowed"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_disallowed: !leavTypeForm.is_disallowed,
                                            in_conjunction_with: "",
                                            in_conditional_with: "",
                                            conditional_balance: ""
                                        })}
                                    />
                                    Disallowed
                                    <Tooltip color='white' placement="bottom" title={
                                        <>In Conjunction
                                            <br />
                                            such leaves can't be taken in conjunction with the other leave types
                                            <br />
                                            <br />
                                            Conditional
                                            <br />
                                            Leave application to be conditional on another leave type & its balance
                                        </>
                                    } overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>

                                <Row >
                                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                        {
                                            leavTypeForm.is_disallowed
                                            &&
                                            <div style={{
                                                border: "1px solid #D7D7D7",
                                                borderRadius: "5px",
                                                width: "100%",
                                                padding: "10px 15px"
                                            }}>
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <div style={{ display: "flex", flexDirection: "row" }}></div>

                                                    <Form.Item
                                                        name="in_conjunction_with"
                                                        label="In Conjunction"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please select one!',
                                                    //     }
                                                    // ]}
                                                    >
                                                        <Select
                                                            size="large"
                                                            // value={item.leaves_numbers}
                                                            placeholder="Leave Type"
                                                        >
                                                            {leaveTypeList.map((item, i) => (
                                                                <Select.Option key={i + 1}
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <div style={{ display: "flex", flexDirection: "row" }}><p>Conditional</p>
                                                </div>



                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                                    <Form.Item
                                                        name="in_conditional_with"
                                                    // label="In Conjunction"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please select one!',
                                                    //     }
                                                    // ]}
                                                    >
                                                        <Select style={{ marginRight: "5px" }}
                                                            size="large"
                                                            // value={item.leaves_numbers}
                                                            placeholder="Leave Type"
                                                        >
                                                            {leaveTypeList.map((item, i) => (
                                                                <Select.Option key={i + 1}
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                                    <Form.Item
                                                        name="conditional_balance"
                                                    // label="In Conjunction"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please select one!',
                                                    //     }
                                                    // ]}
                                                    >
                                                        <Select style={{ marginRight: "5px" }}
                                                            size="large"
                                                            // value={item.leaves_numbers}
                                                            placeholder="Balance"
                                                        >
                                                            {[...Array(50)].map((_, i) => (
                                                                <Select.Option key={i + 1}
                                                                    value={i + 1}
                                                                >
                                                                    {i + 1}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                <Form.Item
                                    name="is_max_allowed"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_max_allowed
                                        }
                                        name="is_max_allowed"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_max_allowed: !leavTypeForm.is_max_allowed,
                                            consecutive_max_allowed_days: "",
                                            defined_period_max_allowed: null,
                                            is_defined_period_max_allowed_month: ""
                                        })}
                                    />
                                    Max Allowed
                                    <Tooltip color='white' placement="bottom" title={<>
                                        Consecutive
                                        <br />
                                        Maximum No. of consecutive leaves which the employee can leave for
                                        <br />
                                        <br />
                                        Conditional
                                        <br />
                                        Leave application to be conditional basis no. of times employee has availed this leave type in a year/month
                                    </>} overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                        {
                                            leavTypeForm.is_max_allowed
                                            &&
                                            <div style={{
                                                border: "1px solid #D7D7D7",
                                                borderRadius: "5px",
                                                width: "100%",
                                                padding: "10px 15px"
                                            }}>
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>


                                                    <Form.Item
                                                        name="consecutive_max_allowed_days"
                                                        label="Consecutive"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select one!',
                                                            }
                                                        ]}
                                                    >
                                                        <Select style={{ marginRight: "5px" }}
                                                            size="large"
                                                            // value={item.leaves_numbers}
                                                            placeholder="Leave Type"
                                                        >
                                                            {[...Array(50)].map((_, i) => (
                                                                <Select.Option key={i + 1}
                                                                    value={i + 1}
                                                                >
                                                                    {i + 1}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <div style={{ display: "flex", flexDirection: 'row' }}>
                                                    <p>Defined Period</p>

                                                </div>

                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <Form.Item

                                                        name="is_defined_period_max_allowed_month"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select one!',
                                                            }
                                                        ]}
                                                    >
                                                        <Radio.Group
                                                        // value={size}
                                                        >
                                                            <Radio.Button value={true}>Month</Radio.Button>
                                                            <Radio.Button value={false}>Annual</Radio.Button>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                                    <Form.Item
                                                        name="defined_period_max_allowed"
                                                        // label="In Conjunction"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select one!',
                                                            }
                                                        ]}
                                                    >
                                                        <Select style={{ marginRight: "5px" }}
                                                            size="large"
                                                            // value={item.leaves_numbers}
                                                            placeholder="No. Of Times"
                                                        >
                                                            {[...Array(50)].map((_, i) => (
                                                                <Select.Option key={i + 1}
                                                                    value={i + 1}
                                                                >
                                                                    {i + 1}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                                <Form.Item
                                    name="is_minimum_allowed"
                                >
                                    <Switch style={{ marginRight: "5px" }}
                                        checked={leavTypeForm.is_minimum_allowed
                                        }
                                        name="is_minimum_allowed"
                                        onChange={() => setLeaveTypeForm({
                                            ...leavTypeForm,
                                            is_minimum_allowed: !leavTypeForm.is_minimum_allowed,
                                            minimum_allowed_days: ""
                                        })}
                                    />
                                    Minimum Allowed
                                    <Tooltip color='white' placement="bottom" title="Employees need to apply for atleast these minimum numbers of leaves if applicable." overlayInnerStyle={{ color: 'black' }}>
                                        <img
                                            src={infoImage}
                                            alt="noimg"
                                            style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />

                                    </Tooltip>
                                </Form.Item>
                                {
                                    leavTypeForm.is_minimum_allowed
                                    &&
                                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>

                                        <Form.Item
                                            name="minimum_allowed_days"
                                            // label="In Conjunction"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select one!',
                                                }
                                            ]}
                                        >
                                            <Select style={{ marginRight: "5px" }}
                                                size="large"
                                                // value={item.leaves_numbers}
                                                placeholder="No. Of Days"
                                            >
                                                {[...Array(50)].map((_, i) => (
                                                    <Select.Option key={i + 1}
                                                        value={i + 1}
                                                    >
                                                        {i + 1}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item
                                    name="color"
                                    label="Color"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Color!',
                                        },
                                    ]}
                                >
                                    <ColorPicker
                                        value={leavTypeForm?.color}
                                    // onChange={setColor}
                                    >
                                        <Button type="primary" style={btnStyle}>
                                        </Button>
                                    </ColorPicker>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Row>
                                    {
                                        params.id
                                            ?
                                            <Form.Item
                                                name="image"
                                                label="Icon"
                                            >
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <div style={{ position: "relative", width: "70px", height: "70px" }} >
                                                        <Badge
                                                            style={{ position: 'relative' }}
                                                            onClick={() => document.getElementById('file-upload').click()}
                                                        >
                                                            {
                                                                editImage
                                                                    ?
                                                                    <img
                                                                        src={editImage}
                                                                        alt="icon"
                                                                        width={70}
                                                                        height={70}
                                                                        style={{ position: "relative" }}
                                                                    />
                                                                    :
                                                                    <img
                                                                        src={(leavTypeForm?.image)}
                                                                        alt="icon"
                                                                        width={70}
                                                                        height={70}
                                                                        style={{ position: "relative" }}
                                                                    />
                                                            }
                                                            <EditOutlined style={{ position: 'absolute', top: 0, right: 0, fontSize: '24px', color: 'black' }} />
                                                        </Badge>
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => {
                                                                if (e.target.files[0]) {
                                                                    // Check if the file size is within the limit (50KB in this example)
                                                                    if (e.target.files[0].size < 50 * 1024) {


                                                                        const file = e.target.files[0];
                                                                        const reader = new FileReader();

                                                                        reader.onloadend = () => {
                                                                            setEditImage(reader.result);
                                                                        };

                                                                        if (file) {
                                                                            reader.readAsDataURL(file);
                                                                        }



                                                                        console.log("abc");
                                                                        setLeaveTypeForm({
                                                                            ...leavTypeForm,
                                                                            image: e.target.files && e.target.files[0]
                                                                        })

                                                                    }
                                                                    else {
                                                                        toast.error("File size exceeds the limit of 50KB!");
                                                                        form.setFieldsValue({
                                                                            image: undefined, // Set the value to undefined or any default value you want
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Form.Item>
                                            :
                                            <Form.Item
                                                name="image"
                                                label="Icon"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please upload Icon!',
                                                    },
                                                ]}
                                            >
                                                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                                    <input
                                                        type='file'
                                                        style={{
                                                            width: "100%",
                                                            height: "70px",
                                                            border: "1px solid #B7B7B7"
                                                        }}
                                                        name='image'
                                                        onChange={(e) => {
                                                            if (e.target.files[0]) {
                                                                // Check if the file size is within the limit (50KB in this example)
                                                                if (e.target.files[0].size < 50 * 1024) {
                                                                    console.log("abc");
                                                                    setLeaveTypeForm({
                                                                        ...leavTypeForm,
                                                                        image: e.target.files && e.target.files[0]
                                                                    })

                                                                }
                                                                else {
                                                                    toast.error("File size exceeds the limit of 50KB!");
                                                                    form.setFieldsValue({
                                                                        image: undefined, // Set the value to undefined or any default value you want
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Item>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Button
                            htmlType='submit'
                            className='addLeaveType'>
                            {
                                params.id
                                    ?
                                    "Update Leave Type"
                                    :
                                    "Add Leave Type"
                            }
                        </Button>
                    </Form>
                </div>
            </ContainerLayout>
        </div >
    )
}

export default LeaveTypeForm