import React, { useEffect, useState } from 'react';
import desktopStyle from "./Dashboard.module.scss"
import mobileStyle from "./MobileDashboard.module.scss";
import { DatePicker, Form, Input, Modal, Select, Table } from 'antd';
import cardIcon from "../../../../assets/Feeds/Admin/cardIcon.png";
import fullView from "../../../../assets/fullView.svg";
import view from "../../../../assets/view.png";
import like from "../../../../assets/Feeds/Admin/like.png";
import comment from "../../../../assets/Feeds/Admin/message.png";
import activeDownload from "../../../../assets/activeDownload.png";
import getScreenSize from "../../../../utils/getScreenSize";
import request from '../../../../utils/axios';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function Dashboard({ setCurrentTab }) {
    const navigate = useNavigate();
    const { TextArea } = Input;
    const screenSize = getScreenSize();
    const postColumns = [
        {
            title: 'Post Title',
            dataIndex: 'post_type',
            key: 'post_type',
            render: (text, record) => <span>{record?.post_type?.slice(0, 20)}
                {
                    record?.post_type?.length > 20 && <span>...</span>
                }
            </span>
        },
        {
            title: 'Reaction',
            dataIndex: 'reaction',
            key: 'reaction',
            render: (text, record) => <div>
                <img src={like} alt="like" width={50} />
                <span style={{ fontWeight: "600", marginLeft: "5px" }}>{record.total_reaction}</span>
                <img src={comment} alt="comment" width={20} style={{ marginLeft: "15px" }} />
                <span style={{ fontWeight: "600", marginLeft: "5px" }}>{record.total_comments}</span>
            </div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <img src={view} alt="view" width={25}
                onClick={() => {
                    navigate(`/admin/feeds/display-post/${record.id}`)
                    // admin/feeds/display-post/d
                }}
            />
        }
    ]

    const surveyAnalysisColumns = [
        {
            title: 'Survey/Polls',
            dataIndex: 'survey',
            key: 'survey',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <img src={fullView} alt="view" />
        }
    ]
    const [addPostModal, setAddPostModal] = useState(false)
    const [summayDateFilter, setSummayDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })
    const onSummaryDateChange = (date, dateString) => {
        setSummayDateFilter({
            ...summayDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [postDetails, setPostDetails] = useState({
        visible_to_departments: null,
        choseTitle: "",
        text: "",
        uploadType: "",
        image: "",
        questionList: [
            {
                question: "",
                options: {
                    option1: "",
                    option2: "",
                },
            }
        ]
    })

    const [postDateFilter, setPostDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })

    const onPostDateChange = (date, dateString) => {
        setPostDateFilter({
            ...postDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [surveyAnalysisDateFilter, setSurveyAnalysisDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })


    const onSurveyAnalysisDateChange = (date, dateString) => {
        setSurveyAnalysisDateFilter({
            ...surveyAnalysisDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [postSummaryCardDetails, setPostSummaryCardDetails] = useState({
        totalPost: "",
        appreciationPosts: "",
        rewardsPosts: "",
        celebrationPosts: "",
        festivePosts: "",
        Polls_Survey: ""
    })

    const postSummaryCard = [
        {
            title: "Total Posts",
            value: postSummaryCardDetails.totalPost,
            image: cardIcon
        },
        {
            title: "Appreciation Posts",
            value: postSummaryCardDetails.appreciationPosts,
            image: cardIcon
        },
        {
            title: "Rewards Posts",
            value: postSummaryCardDetails.rewardsPosts,
            image: cardIcon
        },
        {
            title: "Celebration Posts",
            value: postSummaryCardDetails.celebrationPosts,
            image: cardIcon
        },
        {
            title: "Festive Posts",
            value: postSummaryCardDetails.festivePosts,
            image: cardIcon
        },
        {
            title: "Polls/Survey",
            value: postSummaryCardDetails.Polls_Survey,
            image: cardIcon
        }
    ]
    const fetchSummaryDetails = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/employees-post/summary?month=${summayDateFilter.month}&year=${summayDateFilter.year}`,
            })
            console.log("response", response.data);
            setPostSummaryCardDetails({
                ...postSummaryCardDetails,
                totalPost: response.data.total_post,
                appreciationPosts: response.data.appreciation_posts,
                rewardsPosts: response.data.rewards_posts,
                celebrationPosts: response.data.celebration_posts,
                festivePosts: response.data.festive_posts,
                Polls_Survey: response.data.polls_survey
            })
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [postList, setPostList] = useState([])
    const fetchPostList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/posts/all?month=${postDateFilter.month}&year=${postDateFilter.year}`,
            })
            setPostList(response.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("visible_to_departments", postDetails.visible_to_departments);
            formData.append("post_type", postDetails.post_type);
            formData.append("text", postDetails.text);
            formData.append("image", postDetails.image);
            formData.append("added_by_admin", true);

            const response = await request({
                method: "post",
                url: `feeds/admin/feeds/add`,
                data: formData
            })

            setAddPostModal(false)
            fetchPostList();
            toast.success("Post Created Successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log("error", error)
            toast.error("Something went wrong", {
                position: "bottom-left",
            })
        }
    }

    const [postType, setPostType] = useState([])
    const fetchPostVisibleList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/post-type/dropdown`,
            })
            setPostType(response.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [departmentList, setDepartmentList] = useState([])

    const fetchDepartmentList = async () => {
        try {
            const res = await request(
                {
                    method: "get",
                    url: `feeds/admin/departments/all`,
                }
            );

            setDepartmentList(res.data)
        }
        catch (error) {
            console.log("error ", error)
        }
    }

    useEffect(() => {
        fetchPostVisibleList();
        fetchDepartmentList();
    }, [])

    useEffect(() => {
        fetchPostList();
    }, [postDateFilter])

    useEffect(() => {
        fetchSummaryDetails()
    }, [summayDateFilter])




    return (
        <div>
            {
                screenSize.width < 768
                    // mobile
                    ?
                    <div className={mobileStyle.container}>
                        <div className={mobileStyle.postSummaryContainer}>
                            <div className={mobileStyle.flexSpaceBetween} style={{ margin: "15px 0px" }}>
                                <p className={mobileStyle.heading}>Post Summary</p>
                                <DatePicker
                                    size='medium'
                                    style={{ width: "150px" }}
                                    onChange={onSummaryDateChange}
                                />
                            </div>
                            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                <button className={mobileStyle.activeBtn}>Admin Posts</button>
                                <button className={mobileStyle.inactiveBtn}>Employee Posts</button>
                            </div>
                            <div className={mobileStyle.cardContainer}>
                                {
                                    postSummaryCard?.map((item, index) => {
                                        return (
                                            <div className={mobileStyle.card}>
                                                <div className={mobileStyle.flexSpaceBetween}>
                                                    <p className={mobileStyle.value}>{item.value}</p>
                                                    <img src={item.image} alt="" width={22} height={22} />
                                                </div>
                                                <p className={mobileStyle.title}>{item.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* divider */}
                        <div className={mobileStyle.divider}>
                        </div>

                        <div className={mobileStyle.postContainer}>
                            <div className={mobileStyle.flexSpaceBetween}>
                                <p className={mobileStyle.heading}>Posts</p>

                                <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: "0px 16px" }}>
                                    <DatePicker
                                        placeholder="Select Date"
                                        size='medium'
                                        style={{ width: "150px" }}
                                        onChange={onPostDateChange}
                                    />
                                    <img src={fullView} alt="view"
                                        onClick={() => setCurrentTab("2")}
                                    />
                                </div>
                            </div>
                            {/* use map */}
                            <div>
                                {
                                    postList?.map((item) => {
                                        return (
                                            <div>
                                                <div className={mobileStyle.postCard}>
                                                    <p className={mobileStyle.postHeading}>{item.post_type}</p>
                                                    <div className={mobileStyle.flexSpaceBetween}>
                                                        <div>
                                                            <span className={mobileStyle.countText}>Reaction</span>
                                                            <span className={mobileStyle.countValue}>{item.total_reaction}</span>
                                                        </div>
                                                        <div>
                                                            <span className={mobileStyle.countText}>Comment</span>
                                                            <span className={mobileStyle.countValue}>{item?.total_comments}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* divider */}
                        <div className={mobileStyle.divider}>
                        </div>

                        <div className={mobileStyle.surveyAnalysisContainer}>
                            <div className={mobileStyle.flexSpaceBetween}>
                                <p className={mobileStyle.heading}>Survey Analysis</p>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <DatePicker
                                        size='medium'
                                        style={{ width: "150px" }}
                                        onChange={onSurveyAnalysisDateChange}
                                    />
                                    <img src={fullView} alt="fullView"
                                        onClick={() => setCurrentTab("3")}
                                    />
                                </div>
                            </div>
                            <div className={mobileStyle.surveyAnalysisCard}>
                                <div className={mobileStyle.flexSpaceBetween}>
                                    <p className={mobileStyle.surveyAnalysisHeading}>Survey 1</p>
                                    <img src={activeDownload} alt="activeDownload" />
                                </div>
                                <div className={mobileStyle.flexSpaceBetween}>
                                    <p className={mobileStyle.status}>Status</p>
                                    <button className={mobileStyle.onGoing}>View Details</button>
                                </div>
                                <div className={mobileStyle.flexSpaceBetween} style={{ marginTop: "15px" }}>
                                    <p className={mobileStyle.status}>Results</p>
                                    <button className={mobileStyle.resultBtn}>View Details</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    :
                    // desktop
                    <div className={desktopStyle.container}>

                        <div className={desktopStyle.postSummaryContainer}>
                            <p className={desktopStyle.heading}>Posts Summary</p>
                            <div className={desktopStyle.flexSpaceBetween}>
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                                    <div className={desktopStyle.activeBtn}>
                                        <p>Admin Posts</p>
                                    </div>
                                    <div className={desktopStyle.inactiveBtn}>
                                        <p>Employee Posts</p>
                                    </div>
                                </div>
                                <DatePicker
                                    size='large'
                                    onChange={onSummaryDateChange}
                                    picker="month"
                                    placeholder='Select'
                                />
                            </div>
                            <div className={desktopStyle.cardContainer}>
                                {
                                    postSummaryCard?.map((item, index) => {
                                        return (
                                            <div className={desktopStyle.card}>
                                                <div className={desktopStyle.flexSpaceBetween}>
                                                    <p className={desktopStyle.value}>{item.value}</p>
                                                    <img src={item.image} alt="" width={40} height={40} />
                                                </div>
                                                <p className={desktopStyle.title}>{item.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                        <div className={desktopStyle.postContainer}>
                            <div className={desktopStyle.flexSpaceBetween}>
                                <p className={desktopStyle.heading}>Post</p>
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <button style={{ background: "#008080", color: "white", padding: "7px 15px", borderRadius: "5px", border: "none" }}
                                        onClick={() => setAddPostModal(true)}
                                    >Add Post</button>
                                    <DatePicker
                                        size='large'
                                        onChange={onPostDateChange}
                                    />
                                    <img src={fullView} alt="fullView" />
                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Table
                                    columns={postColumns}
                                    dataSource={postList}
                                    pagination={false}
                                />
                            </div>
                        </div>

                        <div className={desktopStyle.surveyAnalysisContainer}>
                            <div className={desktopStyle.flexSpaceBetween}>
                                <p className={desktopStyle.heading}>Survey Analysis</p>
                                <DatePicker
                                    size='large'
                                    onChange={onSurveyAnalysisDateChange}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Table
                                    columns={surveyAnalysisColumns}
                                    dataSource={[]}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
            }

            <Modal
                centered
                open={addPostModal}
                onOk={() => setAddPostModal(false)}
                onCancel={() => {
                    setAddPostModal(false);
                    setPostDetails({
                        visible_to_departments: "",
                        post_type: "",
                        text: "",
                        uploadType: "",
                        image: "",
                    })
                }}
                footer={null}
            >
                <div>
                    <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", lineHeight: "27px" }}>Add Post</p>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        style={{ marginTop: "20px" }}
                        fields={[
                            {
                                name: "visible_to_departments",
                                value: postDetails.visible_to_departments
                            },
                            {
                                name: "choseTitle",
                                value: postDetails.choseTitle
                            },
                            {
                                name: "text",
                                value: postDetails.text
                            },
                            {
                                name: "uploadType",
                                value: postDetails.uploadType
                            },
                        ]}
                    >
                        <Form.Item
                            label="Department"
                            name="visible_to_departments"
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                                placeholder="Select Department"
                                value={postDetails.visible_to_departments}
                                onChange={(value) => setPostDetails({
                                    ...postDetails,
                                    visible_to_departments: value
                                })}
                            >
                                {
                                    departmentList?.map((item) => {
                                        return (
                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Post Type"
                            name="post_type"
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                                placeholder="Select Post Type"
                                value={postDetails.post_type}
                                onChange={(value) => setPostDetails({
                                    ...postDetails,
                                    post_type: value
                                })}
                            >
                                {
                                    postType?.map((item) => {
                                        return (
                                            <Select.Option value={item.value}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                        {
                            postDetails.post_type === 4 ?
                                <>
                                    <Form.Item
                                        label="Question"
                                        name="question"
                                    >
                                        <TextArea
                                            placeholder="Write text here"
                                            size='large'
                                            rows={4}
                                            onChange={(e) => {
                                                const updatedQuestionList = [...postDetails.questionList];
                                                updatedQuestionList[0] = {
                                                    ...updatedQuestionList[0],
                                                    question: e.target.value
                                                }
                                                setPostDetails({
                                                    ...postDetails,
                                                    questionList: updatedQuestionList
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="option1"
                                    >
                                        <Input
                                            value={postDetails.questionList[0].options.option1}
                                            placeholder="Option 1"
                                            size='large'
                                            onChange={(e) => {
                                                const updatedQuestionList = [...postDetails.questionList];
                                                updatedQuestionList[0] = {
                                                    ...updatedQuestionList[0],
                                                    options: {
                                                        ...updatedQuestionList[0].options,
                                                        option1: e.target.value
                                                    }
                                                };
                                                setPostDetails({
                                                    ...postDetails,
                                                    questionList: updatedQuestionList
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="option2"
                                    >
                                        <Input
                                            placeholder="Option 2"
                                            size='large'
                                            onChange={(e) => {
                                                const updatedQuestionList = [...postDetails.questionList];
                                                updatedQuestionList[0] = {
                                                    ...updatedQuestionList[0],
                                                    options: {
                                                        ...updatedQuestionList[0].options,
                                                        option2: e.target.value
                                                    }
                                                }
                                                setPostDetails({
                                                    ...postDetails,
                                                    questionList: updatedQuestionList
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </>
                                :
                                <Form.Item
                                    label="Text"
                                    name="text"
                                >
                                    <TextArea
                                        placeholder="Write text here"
                                        size='large'
                                        rows={4}
                                        onChange={(e) => setPostDetails({
                                            ...postDetails,
                                            text: e.target.value
                                        })}
                                    />
                                </Form.Item>
                        }

                        <Form.Item
                            label="Upload image"
                            name="uploadType"
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                                placeholder="Select Category"
                                value={postDetails.uploadType}
                                onChange={(value) => setPostDetails({
                                    ...postDetails,
                                    uploadType: value
                                })}
                            >
                                <Select.Option value="1">Default Image</Select.Option>
                                <Select.Option value="2">Upload Image</Select.Option>
                                <Select.Option value="3">None</Select.Option>
                            </Select>
                        </Form.Item>

                        {
                            postDetails.uploadType === "1" &&
                            <div style={{ marginBottom: "20px" }}>
                                <img src="https://picsum.photos/200" alt="" width={100} height={100} />
                            </div>
                        }

                        {
                            postDetails.uploadType === "2" &&
                            <Form.Item
                                // label="Upload File"
                                name="document"

                            >
                                <div className={desktopStyle.fileUploadContainer}>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className={desktopStyle.inputFile}
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                                            // Check if the selected file is an allowed image type
                                            if (allowedImageTypes.includes(selectedFile.type)) {
                                                // Check if the file size is within the allowed limit
                                                if (selectedFile.size <= maxSizeInBytes) {
                                                    console.log(selectedFile);
                                                    setPostDetails({
                                                        ...postDetails,
                                                        image: selectedFile
                                                    })
                                                } else {
                                                    // File size exceeds the limit
                                                    // setFile(null);
                                                    setPostDetails({
                                                        ...postDetails,
                                                        image: ""
                                                    })
                                                    alert('File size exceeds the limit of 5MB.');
                                                }
                                            } else {
                                                // File is not an allowed image type
                                                setPostDetails({
                                                    ...postDetails,
                                                    image: ""
                                                })
                                                alert('Please upload a valid image file (JPEG, PNG, GIF).');
                                            }
                                        }
                                        }
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="file-upload" className={desktopStyle.fileUploadLabel}>

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        }

                        <button
                            style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                        >Post</button>
                    </Form>
                </div>
            </Modal>
        </div>

    )
}

export default Dashboard