import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Badge, Button, Col, DatePicker, Form, Input, Modal, Row, Select, Steps, Switch, Tabs, Timeline } from 'antd';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import update from "immutability-helper";

// Internal import
import request from '../../utils/axios';

// CSS
import "./UserProfile.scss"

// Image
import profileImg from "../../assets/Profile/profileImg.svg"
import reportingManager from "../../assets/Profile/reportingManager.png"
import department from "../../assets/Profile/department.svg"
import location from "../../assets/Profile/Location.svg"
import hrbp from "../../assets/Profile/hrbp.png"
import status from "../../assets/Profile/status.png"
import editImg from "../../assets/edit.svg"
import profileLocation from "../../assets/location.svg"

// component
import PersonalDetails from './PersonalDetails/PersonalDetails';
import EmploymentDetails from "./EmploymentDetails/EmploymentDetails";
import Salary from './Salary/Salary';
import Documents from './Documents/Documents';
import HrLetters from './HR Letters/HrLetters';

import useScreenSize from '../../utils/getScreenSize';
import { CaretDownFilled, CaretUpFilled, ClockCircleOutlined, CloseOutlined, DownloadOutlined, EditOutlined, EyeFilled, UploadOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useDispatch } from 'react-redux'
import { setUserProfileInRedux } from '../../redux/reducer/UserProfileReducer';
import { saveAs } from 'file-saver';
import MyDocs from './MyDocs/MyDocs';

import activeDownload from "../../assets/activeDownload.png"
import view from "../../assets/view.png"
import upload from "../../assets/upload.png"
import pdf from "../../assets/PDF.png"
import back from "../../assets/Recruitment/back.svg"
import ContainerLayout from '../../component/ContainerLayout/ContainerLayout';

function UserProfile() {
    const employee_numberx = localStorage.getItem("employee_number");
    const params = useParams();
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const ref = useRef(null);
    const [dobDisplay, setDobDisplay] = useState("");
    const [anniversaryDisplay, setAnniversaryDisplay] = useState("");
    const [userProfile, setUserProfile] = useState({
        employee_name: "",
        personal_email: "",
        contact: "",
        address: "",
        dob: "",
        gender: "",
        marital_status: "",
        anniversary: "",
        employee_type: "",
        department: "",
        reporting_manager: "",
        joining_date: "",
        work_mode: "",
        work_location: "",
        notice_period: "",
        employement_number: "",
        empfunction: "",
        location: "",
        profile_image: "",
        status: "",
    })
    const [isApp, setIsApp] = useState(false)
    const sectionViewList = [
        {
            key: "personalDetails",
            label: "Personal Details",
            children: <PersonalDetails />
        },
        {
            key: "employmentDetails",
            label: "Employment Details",
            children: <EmploymentDetails />
        },
        {
            key: "salary",
            label: "Salary",
            children: <Salary />
        },
        {
            key: "docs",
            label: "Policies",
            children: <Documents />
        },
        {
            key: "hrLetters",
            label: "HR Letters",
            children: <HrLetters />
        },
        {
            key: "myDocs",
            label: "My Documents",
            children: <MyDocs />
        }
    ]
    const sectionViewListForMobile = [
        {
            key: "personalDetails",
            label: "Personal Details",
        },
        {
            key: "employmentDetails",
            label: "Employment Details",
        },
        {
            key: "salary",
            label: "Salary",
        },
        {
            key: "docs",
            label: "Policies",
        },
        {
            key: "hrLetters",
            label: "HR Letters",
        },
        {
            key: "myDocs",
            label: "My Documents",
        }
    ]

    const [selectedView, setSelectedView] = useState("personalDetails");

    const [loading, setLoading] = useState(true);

    const { TextArea } = Input;

    const [editPersonalDetails, setEditPersonalDetails] = useState(false);


    const fetchProfileData = async () => {
        let data;
        if (params?.id) {
            // console.log("on_admin_click")
            data = await request({
                method: "get",
                url: `admin/profile/details/${params.id}/`,
            })
        }
        else {
            data = await request({
                method: "get",
                url: `profile/details/`,
            })
        }

        setDobDisplay(data.data.data.dob);
        if (data.data.data.anniversary) {
            setAnniversaryDisplay(data.data.data.anniversary);
        }
        const dobDate = new Date(data.data.data.dob).toISOString();

        data.data.data = { ...data.data.data, dob: dayjs(dobDate) }

        if (data.data.data.anniversary) {
            const anniversaryDate = new Date(data.data.data.anniversary);
            const anniversary = anniversaryDate.toISOString();
            data.data.data = { ...data.data.data, anniversary: dayjs(anniversary) }
        }

        if (data.data.data.profile_image) {
            const imageBaseUrl = process.env.REACT_APP_IMG_BASE_URL
            const userImage = data.data.data.profile_image;
            data.data.data = { ...data.data.data, profile_image: userImage }
        }

        setUserProfile({
            ...data.data.data,
        })

        if (!params?.id) {
            dispatch(setUserProfileInRedux(data.data.data))
        }
    }



    useEffect(() => {
        setLoading(false);
    }, [userProfile])


    const handleMaritalStatusChange = (maritalStatus) => {
        if (maritalStatus === 'MARRIED') {
            setUserProfile({ ...userProfile, marital_status: "MARRIED" });
        } else {
            setUserProfile({ ...userProfile, anniversary: "", marital_status: "SINGLE" });
        }
    };

    const handleFormChange = (changedValues, allValues) => {
        setUserProfile(allValues);
    }


    const onFinish = async (values) => {
        const dob = dayjs(values.dob).format('YYYY-MM-DD');
        values = { ...values, dob }

        if (values?.anniversary) {
            const anniversary = dayjs(values.anniversary).format('YYYY-MM-DD');
            values = { ...values, dob, anniversary }
        }

        await request({
            method: "put",
            url: `profile/details/update/${employee_numberx}/`,
            data: values,
        })

        fetchProfileData()
        setEditPersonalDetails(!editPersonalDetails)
    };

    const scrollableDivRef = useRef(null);
    const scrollToId = (id) => {
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [fullView, setFullView] = useState({
        workLocation: false,
        empType: false,
        grade: false,
        reportingManager: false,
        hrbp: false,
    });

    const uploadUserImage = async (e) => {
        try {
            const formData = new FormData();
            formData.append('profile_image', e.target.files[0]);
            await request({
                method: "post",
                url: `/profile/pic/upload/`,
                data: formData,
            })
            fetchProfileData()
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const workLocationFullView = () => {

        return userProfile?.work_location.map((item, index) => {
            return (
                {
                    children:
                        <div style={{ marginTop: "10px" }}>
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                margin: "0px",
                                padding: "0px",
                                color: "#4E4E4E"
                            }}>
                                {item.work_location}
                                {
                                    index === 0 &&
                                    <CaretUpFilled style={{
                                        fontSize: "20px",
                                        color: "#008080",
                                    }}
                                        onClick={() => setFullView({ ...fullView, workLocation: !fullView.workLocation })}
                                    />
                                }
                            </p>
                            <p
                                style={{ fontSize: "12px", fontWeight: "600", margin: "0px", padding: "0px", color: "#838383" }}>
                                {item.assigned_on}
                            </p>
                        </div>
                }
            )
        })
    }

    const graderFullView = () => {

        return userProfile?.grade.map((item, index) => {
            return (
                {
                    children: <div style={{ marginTop: "10px" }}>
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            margin: "0px",
                            padding: "0px",
                            color: "#4E4E4E"
                        }}>
                            {item.grade}
                            {
                                index === 0 &&
                                <CaretUpFilled style={{
                                    fontSize: "20px",
                                    color: "#008080",
                                }}
                                    onClick={() => setFullView({ ...fullView, grade: !fullView.grade })}
                                />
                            }
                        </p>
                        <p
                            style={{ fontSize: "12px", fontWeight: "600", margin: "0px", padding: "0px", color: "#838383" }}>
                            {item.assigned_on}
                        </p>
                    </div>
                }
            )
        })
    }

    const hrbpFullView = () => {

        return userProfile?.hrbp.map((item, index) => {
            return (
                {
                    children: <div style={{ marginTop: "10px" }}>
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            margin: "0px",
                            padding: "0px",
                            color: "#4E4E4E"
                        }}>
                            {item.hrbp}
                            {
                                index === 0 &&
                                <CaretUpFilled style={{
                                    fontSize: "20px",
                                    color: "#008080",
                                }}
                                    onClick={() => setFullView({ ...fullView, hrbp: !fullView.hrbp })}
                                />
                            }
                        </p>
                        <p
                            style={{ fontSize: "12px", fontWeight: "600", margin: "0px", padding: "0px", color: "#838383" }}>
                            {item.assigned_on}
                        </p>
                    </div>
                }
            )
        })
    }

    const designationFullView = () => {

        return userProfile?.designation.map((item, index) => {
            return (
                {
                    children: <div style={{ marginTop: "10px" }}>
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            margin: "0px",
                            padding: "0px",
                            color: "#4E4E4E"
                        }}>
                            {item.designation}
                            {
                                index === 0 &&
                                <CaretUpFilled style={{
                                    fontSize: "20px",
                                    color: "#008080",
                                }}
                                    onClick={() => setFullView({ ...fullView, designation: !fullView.designation })}
                                />
                            }
                        </p>
                        <p
                            style={{ fontSize: "12px", fontWeight: "600", margin: "0px", padding: "0px", color: "#838383" }}>
                            {item.assigned_on}
                        </p>
                    </div>
                }
            )
        })
    }

    const reportingManagerFullView = () => {

        return userProfile?.reporting_manager?.map((item, index) => {
            return (
                {
                    children: <div style={{ marginTop: "10px" }}>
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            margin: "0px",
                            padding: "0px",
                            color: "#4E4E4E"
                        }}>
                            {item?.reporting_manager}
                            {
                                index === 0 &&
                                <CaretUpFilled style={{
                                    fontSize: "20px",
                                    color: "#008080",
                                }}
                                    onClick={() => setFullView({ ...fullView, reportingManager: !fullView.reportingManager })}
                                />
                            }
                        </p>
                        <p
                            style={{ fontSize: "12px", fontWeight: "600", margin: "0px", padding: "0px", color: "#838383" }}>
                            {item?.assigned_on}
                        </p>
                    </div>
                }
            )
        })
    }

    const [salaryDetails, setSalaryDetails] = useState({
        bank_name: '',
        ifsc_code: '',
        account_number: '',
        payment_mode: '',
        pan_no: '',
        uan_no: '',
    })

    const fetchBankDetails = async () => {
        try {
            if (params.id) {
                const data = await request({
                    method: "get",
                    url: `/admin/profile/details/${params.id}`,
                })

                setSalaryDetails(data.data.data)
            }
            else {
                const data = await request({
                    method: "get",
                    url: `/profile/bank/details/`,
                })

                setSalaryDetails(data.data.data)
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [documentList, setDocumentList] = useState([])

    const fetchDocuments = async () => {
        try {

            const data = await request({
                method: "get",
                url: `/profile/policy/document/?page=1&page_size=3`,
            })

            console.log("data", data.pagination_data.data);
            setDocumentList(data.pagination_data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const [hrLetterList, setHRLetterList] = useState([])

    const fetchHRLetters = async () => {
        try {

            if (params.id) {
                const data = await request({
                    method: "get",
                    url: `/admin/profile/hr-letters?employement_number=${params.id}`,
                })

                setHRLetterList(data.data)
            }
            else {
                const data = await request({
                    method: "get",
                    url: `/profile/hr-letters`,
                })

                setHRLetterList(data.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const [salaryBreakup, setSalaryBreakup] = useState()

    const fetchSalaryBreakup = async () => {
        try {
            if (params?.id) {
                let data = await request({
                    method: "get",
                    url: `payroll/admin/profile/salary-breakup?employement_number=${params.id}`,
                })
                console.log(data)
                setSalaryBreakup(data?.data)
            }
            else {
                let data = await request({
                    method: "get",
                    url: `payroll/profile/salary-breakup`,
                })
                console.log(data)
                setSalaryBreakup(data?.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const [showMonthlySalaryDetails, setShowMonthlySalaryDetails] = useState(false)

    const [docs, setDocs] = useState([
        {
            name: "Experience Letter",
            key: "experience_letter",
            file: "",
        },
        {
            name: "Education certificates",
            key: "educational_certificate",
            file: "",
        },
        {
            name: "Appointment letter of previous employer",
            key: "appointment_letter",
            file: "",
        },
        {
            name: "Resignation Acceptance",
            key: "resignation_acceptance",
            file: "",
        },
        {
            name: "Resignation Acceptance letter",
            key: "resignation_acceptance_letter",
            file: "",
        },
        {
            name: "Last 3 months payslip",
            key: "salary_slip_three_months",
            file: "",
        },
        {
            name: "Bank statement",
            key: "bank_statement",
            file: "",
        },
        {
            name: "Resume",
            key: "resume",
            file: "",
        },
        {
            name: "Adhaar Card",
            key: "aadhar_card",
            file: "",
        },
        {
            name: "PAN Card",
            key: "pan",
            file: "",
        },
        {
            name: "Cancelled Cheque",
            key: "cancelled_cheque",
            file: "",
        },
        {
            name: "Passport size photo",
            key: "passport_size_photo",
            file: "",
        },
    ]);

    useEffect(() => {
        console.log("docs...", docs);
    }, [docs])

    const fetchUploadedDocs = async () => {
        try {
            if (params.id) {
                const res = await request({
                    method: "get",
                    url: `/admin/employee/profile/personal-documents/all?employement_number=${params.id}`,
                });

                if (res.data.length > 0) {

                    const check = docs?.map((doc, docIndex) => {
                        const matchedData = res?.data?.find(item => item.document_type === doc.key);

                        if (matchedData) {
                            return { ...doc, file: matchedData.filename, path: matchedData.file };
                        }
                        return doc;
                    });


                    setDocs(check)
                }
            }
            else {
                const res = await request({
                    method: "get",
                    url: `/onbaording/employee/personal-documents/all`,
                });
                const data = docs?.map(doc => {
                    const matchedData = res?.data?.find(item => item.document_type === doc.key);
                    if (matchedData) {
                        return { ...doc, file: matchedData.filename, path: matchedData.file };
                    }
                    return doc;
                });

                console.log("check", data);

                setDocs(data)
            }
        }
        catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchProfileData();
        fetchDocuments();
        fetchBankDetails();
        fetchHRLetters();
        fetchSalaryBreakup();
        fetchUploadedDocs()

        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [window.location.pathname]);

    const handleImageUpload = async (file, index) => {
        try{
            console.log("file", file, index);
            const updatedDocs = update(docs, {
                [index]: {
                    file: { $set: file },
                },
            });
    
            console.log("updatedDocs", updatedDocs);
            setDocs(updatedDocs);
    
            const formData = new FormData();
            formData.append(docs[index].key, file);
    
            await request({
                method: "post",
                url: `/onboarding/employee/document/upload`,
                data: formData
            })
        }
        catch(error){
            console.log("error", error);
        }
    };

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        setIsApp(userAgent.includes('MyAppWebView/1.0'));
    }, []);

    function sendMessageToReactNative(file) {
        const pdfDownload = "pdfDownload";
        const fileName = file;
        console.log("message....:", pdfDownload + "," + fileName);
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ pdfDownload, fileName }));
        } else {
            console.warn('ReactNativeWebView is not defined.');
        }
    }

    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    <div className="userProfileContainer">
                        <div>
                            <div
                                style={{
                                    width: "100%",
                                    height: "180px",
                                    background: "#F5FFFF",
                                    position: "absolute",
                                    top: "-1px",
                                }}
                            >

                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    height: "300px",
                                    background: "#F5FFFF",
                                    position: "absolute",
                                    top: "0px",
                                    borderRadius: "50%",
                                }}
                            >
                            </div>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: "83px",
                                width: "100%"
                            }}
                        >
                            <center>
                                {
                                    userProfile?.profile_image
                                        ?
                                        <>
                                            <Badge
                                                style={{ position: 'relative' }}
                                                onClick={() => document.getElementById('file-upload').click()}
                                            >
                                                <img src={userProfile?.profile_image} alt="profileImg" width={70} height={70} style={{ borderRadius: '50%' }} />
                                                <EditOutlined style={{ position: 'absolute', top: 0, right: 0, fontSize: '24px', color: 'black' }} />
                                            </Badge>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                onChange={uploadUserImage}
                                                style={{ display: 'none' }}
                                            />
                                        </>

                                        :
                                        <label htmlFor="file-upload">
                                            <Avatar size={100} shape="square" icon={<UploadOutlined />} />
                                            <input
                                                id="file-upload"
                                                type="file"
                                                onChange={uploadUserImage}
                                                style={{ display: 'none' }} // Hide the default file input styling
                                            />
                                        </label>

                                }
                                <p
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "600",
                                        margin: "0px",
                                        marginTop: "10px"
                                    }}
                                >
                                    {userProfile?.employee_name}
                                </p>
                                <p
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        color: "#505050",
                                        margin: "0px",
                                        marginTop: "5px"
                                    }}
                                >
                                    {userProfile?.designation && userProfile?.designation[0]?.designation}
                                </p>
                                <p
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        color: "#505050",
                                        margin: "0px",
                                        marginTop: "5px"
                                    }}
                                >
                                    {userProfile?.official_email}
                                </p>
                            </center>
                        </div>
                        <div style={{
                            position: "absolute",
                            top: "300px",
                            // width: "100%",
                            padding: "20px",
                            // height: "500px",
                            zIndex: "999",
                            background: "white"
                        }}>
                            <Row gutter={[10, 10]}>
                                <Col span={13}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                        <img src={reportingManager} alt="reportingManager" width={25} height={25} />
                                        <span style={{ fontSize: "16px", fontWeight: "500" }}>Manager</span>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className='detailContainer' >
                                        <span className='headerTextValue'>{userProfile?.reporting_manager && userProfile?.reporting_manager?.length > 0 && userProfile?.reporting_manager[0]?.reporting_manager}</span>
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}   >
                                        <img src={department} alt="department" width={25} height={25} />
                                        <span style={{ fontSize: "16px", fontWeight: "500" }}>Department</span>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className='detailContainer'>
                                        <span style={{ fontSize: "16px", fontWeight: "400", color: "#787878" }}>{userProfile?.department}</span>
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <img src={hrbp} alt="hrbp" width={25} height={25} />
                                        <span style={{ fontSize: "16px", fontWeight: "500" }}>HRBP</span>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className='detailContainer'>
                                        <span style={{ fontSize: "16px", fontWeight: "400", color: "#787878" }}>{userProfile?.hrbp && userProfile?.hrbp?.length > 0 && userProfile?.hrbp[0]?.hrbp}</span>
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                        <img src={location} alt="location" width={25} height={25} />
                                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                                            Location</span>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className='detailContainer' >
                                        <span style={{ fontSize: "16px", fontWeight: "400", color: "#787878" }}>{userProfile?.work_location.length > 0 && userProfile?.work_location[0]?.work_location}</span>
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                        <img src={status} alt="status" width={25} height={25} />
                                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                                            Status
                                        </span>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className='detailContainer' >
                                        <span style={{ fontSize: "16px", fontWeight: "400", color: "#787878" }}>{userProfile?.status}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div style={{ position: "relative", top: "445px" }}>

                            <div style={{
                                // padding: "0px 4%",
                                position: "sticky",
                                top: "62px",
                                width: "100%",
                                overflow: "auto",
                                background: "white",
                                zIndex: 9
                            }}>
                                <Tabs
                                    defaultActiveKey="1"
                                    items={sectionViewListForMobile}
                                    onChange={(key) => {
                                        setSelectedView(key)
                                        scrollToId(key)
                                    }}
                                />
                            </div>


                            <div style={{
                                position: "relative",
                                top: "-90px"
                            }}>
                                <div>
                                    <div id="personalDetails" >
                                        <div style={{
                                            position: "relative",
                                            top: "90px",
                                            padding: "10px 16px",
                                            background: "white",
                                        }}
                                        >
                                            <div className="personalDetailsInnerContainer" style={{ marginTop: "20px" }} ref={scrollableDivRef}>
                                                <div className='flexSpaceBetween'>
                                                    <p style={{
                                                        fontSize: "24px",
                                                        fontWeight: "600",
                                                        color: "#252C58"
                                                    }}>Personal Details</p>
                                                    {
                                                        !params.id && <img
                                                            src={editImg}
                                                            alt={editImg}
                                                            width={15}
                                                            height={15}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setEditPersonalDetails(!editPersonalDetails)
                                                                navigate(`/user-profile/personal-details/${employee_numberx}`, { replace: true });
                                                            }}
                                                        />
                                                    }

                                                </div>
                                                <div style={{
                                                    width: "100%",
                                                    marginTop: "20px",
                                                }}>
                                                    <Row gutter={[20, 20]}>
                                                        <Col span={24}>
                                                            <p style={{
                                                                fontSize: "18px",
                                                                margin: "0px",
                                                                padding: "0px",

                                                            }}>Gender</p>
                                                            <p style={{
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                                marginTop: "7px",
                                                                color: "#6E6E6E"
                                                            }}>{userProfile.gender}</p>
                                                        </Col>

                                                        <Col span={24}>
                                                            <p style={{
                                                                fontSize: "18px",
                                                                margin: "0px",
                                                                padding: "0px",

                                                            }}>Marital Status</p>
                                                            <p style={{
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                                marginTop: "7px",
                                                                color: "#6E6E6E"
                                                            }}>{userProfile.marital_status}</p>
                                                        </Col>

                                                        <Col span={24}>
                                                            <p style={{
                                                                fontSize: "18px",
                                                                margin: "0px",
                                                                padding: "0px",

                                                            }}>Address</p>
                                                            <p style={{
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                                marginTop: "7px",
                                                                color: "#6E6E6E"
                                                            }}>{userProfile.address}</p>
                                                        </Col>

                                                        <Col span={24}>
                                                            <p style={{
                                                                fontSize: "18px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}>Date of Birth</p>
                                                            <p style={{
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                                marginTop: "7px",
                                                                color: "#6E6E6E"
                                                            }}>{moment(dobDisplay).format("DD-MM-YYYY")}</p>
                                                        </Col>

                                                        <Col span={24}>
                                                            <p style={{
                                                                fontSize: "18px",
                                                                margin: "0px",
                                                                padding: "0px",

                                                            }}>Anniversary</p>
                                                            <p style={{
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                                marginTop: "7px",
                                                                color: "#6E6E6E"
                                                            }}>{anniversaryDisplay ? moment(anniversaryDisplay).format("DD-MM-YYYY") : "N/A"}</p>
                                                        </Col>

                                                        <Col span={24}>
                                                            <p style={{
                                                                fontSize: "18px",
                                                                margin: "0px",
                                                                padding: "0px",

                                                            }}>Contact No.</p>
                                                            <p style={{
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                                marginTop: "7px",
                                                                color: "#6E6E6E"
                                                            }}>{userProfile.contact}</p>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{
                                        position: "relative",
                                        top: "92px",
                                        height: "8px",
                                        background: "#EEF2FF",
                                        zIndex: 1
                                    }}>

                                    </div>

                                    <div id="employmentDetails">
                                        <div style={{
                                            position: "relative",
                                            top: "84px",
                                            padding: "10px 16px",
                                            background: "white",
                                        }}

                                        >
                                            <div ref={scrollableDivRef} >
                                                <div className='flexSpaceBetween'>
                                                    <p style={{
                                                        fontSize: "24px",
                                                        fontWeight: "600",
                                                        color: "#252C58",
                                                        marginTop: "30px"
                                                    }}

                                                    >Employment Details</p>
                                                    {
                                                        params.id && <img
                                                            src={editImg}
                                                            alt={editImg}
                                                            width={15}
                                                            height={15}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                // setEditPersonalDetails(!editPersonalDetails)
                                                                navigate(`/admin/employee/employee-details/${employee_numberx}`, { replace: true });
                                                            }}
                                                        />
                                                    }

                                                </div>
                                                <Row gutter={[20, 20]}>

                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Date Of Joining</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{userProfile?.joining_date}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Notice Period</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{userProfile?.notice_period}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Employment No.</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{userProfile?.employement_number}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Function</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{userProfile?.empfunction}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Working Location</p>
                                                        {
                                                            fullView.workLocation
                                                                ?
                                                                <Timeline
                                                                    items={workLocationFullView()}
                                                                />
                                                                :
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    <img src={profileLocation} alt="" />
                                                                    <p style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        margin: "0px",
                                                                        padding: "0px",
                                                                        marginTop: "7px",
                                                                        color: "#4E4E4E"
                                                                    }}>
                                                                        {userProfile?.work_location.length > 0 && userProfile?.work_location[0].work_location}

                                                                    </p>
                                                                    <CaretDownFilled
                                                                        style={{
                                                                            fontSize: "20px",
                                                                            color: "#008080",

                                                                        }}
                                                                        onClick={() => setFullView({ ...fullView, workLocation: !fullView.workLocation })} />
                                                                </div>
                                                        }

                                                    </Col>
                                                    {/* <Col span={16}> */}




                                                    {/* </Col> */}


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Employment Type</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{userProfile?.employee_type}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Grade</p>

                                                        {
                                                            fullView.grade
                                                                ?
                                                                <Timeline
                                                                    items={graderFullView()}
                                                                />
                                                                :
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    {/* <img src={profileLocation} alt="" /> */}
                                                                    <p style={{
                                                                        fontSize: "16px",
                                                                        margin: "0px",
                                                                        padding: "0px",
                                                                        marginTop: "7px",
                                                                        color: "#6E6E6E"
                                                                    }} >
                                                                        {userProfile?.grade && userProfile?.grade[0]?.grade}
                                                                    </p>
                                                                    <CaretDownFilled
                                                                        style={{
                                                                            fontSize: "20px",
                                                                            color: "#008080",
                                                                        }}
                                                                        onClick={() => setFullView({ ...fullView, grade: !fullView.grade })}
                                                                    />
                                                                </div>
                                                        }
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Reporting Manager</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>


                                                            {
                                                                userProfile?.reporting_manager.length !== 0
                                                                &&
                                                                <>
                                                                    {
                                                                        fullView.reportingManager
                                                                            ?
                                                                            <Timeline
                                                                                items={reportingManagerFullView()}
                                                                            />
                                                                            :
                                                                            <div style={{ display: "flex", gap: "10px" }}>

                                                                                <p style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "400",
                                                                                    margin: "0px",
                                                                                    padding: "0px",
                                                                                    color: "#4E4E4E"
                                                                                }} >
                                                                                    {userProfile?.reporting_manager[0]?.reporting_manager}

                                                                                </p>
                                                                                <CaretDownFilled
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#008080",
                                                                                    }}
                                                                                    onClick={() => setFullView({ ...fullView, reportingManager: !fullView.reportingManager })}
                                                                                />
                                                                            </div>
                                                                    }
                                                                </>
                                                            }
                                                        </p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Designation</p>
                                                        {
                                                            fullView.designation
                                                                ?
                                                                <Timeline
                                                                    items={designationFullView()}
                                                                />
                                                                :
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    {/* <img src={profileLocation} alt="" /> */}
                                                                    <p style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        margin: "0px",
                                                                        padding: "0px",
                                                                        color: "#4E4E4E",
                                                                        marginTop: "7px",
                                                                    }} >
                                                                        {userProfile?.designation?.length > 0 && userProfile?.designation[0]?.designation}

                                                                    </p>
                                                                    <CaretDownFilled
                                                                        style={{
                                                                            fontSize: "20px",
                                                                            color: "#008080",
                                                                        }}
                                                                        onClick={() => setFullView({ ...fullView, designation: !fullView.designation })}
                                                                    />
                                                                </div>
                                                        }
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>HRBP</p>
                                                        <p >
                                                            {

                                                                <>
                                                                    {
                                                                        fullView.hrbp
                                                                            ?
                                                                            <Timeline
                                                                                items={hrbpFullView()}
                                                                            />
                                                                            :
                                                                            <div style={{ display: "flex", gap: "10px" }}>
                                                                                {/* <img src={profileLocation} alt="" /> */}
                                                                                <p style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "400",
                                                                                    margin: "0px",
                                                                                    padding: "0px",
                                                                                    color: "#4E4E4E",
                                                                                    marginTop: "7px",
                                                                                }} >
                                                                                    {userProfile?.hrbp?.length > 0 && userProfile?.hrbp[0]?.hrbp}

                                                                                </p>
                                                                                <CaretDownFilled
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#008080",
                                                                                    }}
                                                                                    onClick={() => setFullView({ ...fullView, hrbp: !fullView.hrbp })}
                                                                                />
                                                                            </div>
                                                                    }
                                                                </>
                                                            }
                                                        </p>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        position: "relative",
                                        top: "100px",
                                        height: "8px",
                                        background: "#EEF2FF",
                                        zIndex: 1
                                    }}>

                                    </div>

                                    <div id="salary">
                                        <div style={{
                                            position: "relative",
                                            top: "76px",
                                            padding: "10px 16px",
                                            background: "white"
                                        }}

                                        >
                                            <div ref={scrollableDivRef}  >
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "50px", marginBottom: "15px" }}>
                                                    <p
                                                        style={{
                                                            fontSize: "24px",
                                                            fontWeight: "600",
                                                            color: "#252C58",
                                                            margin: "0px",
                                                            // marginTop: "50px"
                                                        }}
                                                        id="salary"
                                                    >Salary</p>
                                                    {
                                                        !params.id
                                                        &&
                                                        <img
                                                            src={editImg}
                                                            alt={editImg}
                                                            width={15}
                                                            height={15}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {

                                                                navigate(`/user-profile/bank-details/${employee_numberx}`, { replace: true });
                                                            }}
                                                        />
                                                    }
                                                </div>


                                                <Row gutter={[20, 20]}>

                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Bank Name</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{salaryDetails?.bank_name}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>IFSC Code</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{salaryDetails?.ifsc_code}</p>
                                                    </Col>


                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Account No.</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{salaryDetails?.account_number}</p>
                                                    </Col>

                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Mode Of Payment</p>

                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{salaryDetails?.payment_mode}</p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>Pan No.</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{salaryDetails?.pan_no}</p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            margin: "0px",
                                                            padding: "0px",

                                                        }}>UAN No.</p>
                                                        <p style={{
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                            marginTop: "7px",
                                                            color: "#6E6E6E"
                                                        }}>{salaryDetails?.uan_no}</p>
                                                    </Col>


                                                </Row>

                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginTop: "30px" }}>
                                                        <p style={{
                                                            fontSize: "18px",
                                                            fontWeight: "600",
                                                            color: "#0B0B0B"
                                                        }}>Salary Details</p>
                                                        <div>
                                                            <Switch
                                                                size='small'
                                                                checked={showMonthlySalaryDetails}
                                                                onChange={() => {
                                                                    setShowMonthlySalaryDetails(!showMonthlySalaryDetails)
                                                                }} />{" "}
                                                            <span style={{ fontSize: "12px", fontWeight: "400", color: "#333333" }}>Monthly</span>
                                                        </div>
                                                    </div>
                                                    {/* Fixed */}
                                                    {
                                                        salaryBreakup?.fixed.length > 0 &&
                                                        <p style={{
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            color: "#161616"
                                                        }}>Fixed</p>
                                                    }
                                                    {
                                                        salaryBreakup?.fixed.map((item, index) => {
                                                            return (
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>{item?.name}</p>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        {
                                                                            !showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.yearly_amount}</p>
                                                                        }
                                                                        {
                                                                            showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.monthly_amount}</p>
                                                                        }</Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }

                                                    {/* Flexi */}
                                                    {
                                                        salaryBreakup?.flexi.length > 0 &&

                                                        <p style={{
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            color: "#161616"
                                                        }}>Flexi</p>

                                                    }

                                                    {
                                                        salaryBreakup?.flexi.map((item, index) => {
                                                            return (
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>{item?.name}</p>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        {
                                                                            !showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.yearly_amount}</p>
                                                                        }
                                                                        {
                                                                            showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.monthly_amount}</p>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }

                                                    {/* Variable */}
                                                    {
                                                        salaryBreakup?.variable.length > 0 &&

                                                        <p style={{
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            color: "#161616"
                                                        }}>Variable</p>

                                                    }
                                                    {
                                                        salaryBreakup?.variable.map((item, index) => {
                                                            return (
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>{item?.name}</p>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        {
                                                                            !showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.yearly_amount}</p>
                                                                        }
                                                                        {
                                                                            showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.monthly_amount}</p>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        salaryBreakup?.retirals.length > 0 &&

                                                        <p style={{
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            color: "#161616"
                                                        }}>Retirals</p>

                                                    }

                                                    {
                                                        salaryBreakup?.retirals.map((item, index) => {
                                                            return (
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>{item?.name}</p>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        {
                                                                            !showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.yearly_amount}</p>
                                                                        }
                                                                        {
                                                                            showMonthlySalaryDetails &&
                                                                            <p style={{ fontSize: "16px", color: "#8A8A8A", fontWeight: "500", textAlign: "end" }}>₹ {item?.monthly_amount}</p>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        position: "relative",
                                        top: "100px",
                                        height: "8px",
                                        background: "#EEF2FF",
                                        zIndex: 1
                                    }}>

                                    </div>

                                    <div id="docs">
                                        <div style={{
                                            position: "relative",
                                            top: "76px",
                                            padding: "10px 16px",
                                            background: "white"
                                        }}

                                        >
                                            <div ref={scrollableDivRef} >
                                                <p style={{
                                                    fontSize: "24px",
                                                    fontWeight: "600",
                                                    color: "#252C58",
                                                    marginTop: "50px"
                                                }}
                                                    id="salary"
                                                >Policies</p>

                                                {
                                                    documentList?.map((document, index) => {
                                                        return (
                                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
                                                                <p style={{
                                                                    fontSize: "20px",
                                                                    color: "#161616",
                                                                    fontWeight: "400",
                                                                    margin: "0px"
                                                                }}>{document.name}</p>

                                                                <div style={{ display: "flex", gap: "30px" }}>
                                                                    <img src={view} alt="view" width={25} height={25}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            window.open(document.document, "_blank");
                                                                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'PDF_LINK', url: document.document }));
                                                                        }}
                                                                    />

                                                                    <img src={activeDownload} alt="activeDownload" width={25} height={25}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={async () => {
                                                                            const documentUrl = document.document;

                                                                            try {
                                                                                const response = await fetch(documentUrl);
                                                                                const blob = await response.blob();
                                                                                saveAs(blob, `${document.name}.pdf`);
                                                                            } catch (error) {
                                                                                console.error('Error downloading PDF:', error);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        position: "relative",
                                        top: "100px",
                                        height: "8px",
                                        background: "#EEF2FF",
                                        zIndex: 1
                                    }}>

                                    </div>

                                    <div id="hrLetters">
                                        <div style={{
                                            position: "relative",
                                            top: "76px",
                                            padding: "10px 16px",
                                            background: "white"
                                        }}

                                        >
                                            <div ref={scrollableDivRef} >
                                                <p style={{
                                                    fontSize: "24px",
                                                    fontWeight: "600",
                                                    color: "#252C58",
                                                    marginTop: "50px"
                                                }}
                                                    id="hrLetters"
                                                >HR Letters</p>

                                                {
                                                    hrLetterList?.map((document, index) => {
                                                        return (
                                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
                                                                <p style={{
                                                                    fontSize: "20px",
                                                                    color: "#161616",
                                                                    fontWeight: "400",
                                                                    margin: "0px"
                                                                }}>{document.name}</p>

                                                                <div style={{ display: "flex", gap: "30px" }}>
                                                                    <EyeFilled style={{ fontSize: "25px", color: "#757575" }}
                                                                        onClick={() => {
                                                                            window.open(document.document, "_blank");
                                                                        }}
                                                                    />
                                                                    <DownloadOutlined style={{ fontSize: "25px", color: "#757575" }}
                                                                        onClick={async () => {
                                                                            const documentUrl = document.document;

                                                                            try {
                                                                                const response = await request({
                                                                                    method: "get",
                                                                                    url: documentUrl,
                                                                                    responseType: 'arraybuffer',
                                                                                });

                                                                                const file = new Blob([response.data], { type: 'application/pdf' });
                                                                                const url = window.URL.createObjectURL(file);
                                                                                const link = document.createElement('a');
                                                                                link.href = url;
                                                                                link.download = 'file.pdf';
                                                                                link.click();
                                                                            } catch (error) {
                                                                                console.error('Error downloading PDF:', error);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div style={{ height: "150px" }}></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        position: "relative",
                                        top: "150px",
                                        height: "8px",
                                        background: "#EEF2FF",
                                        zIndex: 1
                                    }}>

                                    </div>

                                    <div id="myDocs">
                                        <div style={{
                                            position: "relative",
                                            top: "116px",
                                            padding: "10px 16px",
                                            background: "white",
                                            marginTop: "50px"
                                        }}

                                        >
                                            <div ref={scrollableDivRef}   >
                                                <p style={{
                                                    fontSize: "24px",
                                                    fontWeight: "600",
                                                    color: "#252C58",
                                                    // marginTop: "50px"
                                                }}

                                                >My Documents</p>

                                                {docs?.map((item, docIndex) => {
                                                    return (
                                                        <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", marginTop: "10px", marginBottom: "15px" }}>
                                                            <div>
                                                                <p style={{ fontSize: "20px", fontWeight: "400", color: "#161616", margin: "0px", marginBottom: "5px" }}>
                                                                    {item.name}
                                                                </p>
                                                                {
                                                                    item?.file
                                                                    &&
                                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center", border: "1px dotted #008080", width: "fit-content", padding: "2px 5px", borderRadius: "4px" }}>
                                                                        <img src={pdf} alt="pdf" width={15} height={15} />
                                                                        <p style={{ fontSize: "14px", fontWeight: "400", color: "#333333", margin: "0px" }}>
                                                                            {item?.file?.name ? item?.file?.name : item?.file}
                                                                            {/* {doc?.file && } */}
                                                                        </p>
                                                                        <CloseOutlined style={{ fontSize: "12px", color: "red" }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            {
                                                                item?.file ?
                                                                    <div

                                                                        style={{ display: "flex", gap: "20px" }}>

                                                                        <img src={view} alt="view" width={25} height={25}
                                                                            onClick={() => window.open(item?.path)}
                                                                        />

                                                                        {
                                                                            isApp
                                                                                ?
                                                                                <img src={activeDownload} alt="activeDownload"
                                                                                    width={25} height={25}
                                                                                    onClick={() => sendMessageToReactNative(item?.path)}
                                                                                />
                                                                                :
                                                                                <img src={activeDownload} alt="activeDownload"
                                                                                    width={25} height={25}
                                                                                    onClick={async () => {
                                                                                        const documentUrl = item.file;

                                                                                        try {
                                                                                            const response = await request({
                                                                                                method: "get",
                                                                                                url: documentUrl,
                                                                                                responseType: 'arraybuffer',
                                                                                            });

                                                                                            const file = new Blob([response.data], { type: 'application/pdf' });
                                                                                            const url = window.URL.createObjectURL(file);
                                                                                            const link = document.createElement('a');
                                                                                            link.href = url;
                                                                                            link.download = 'file.pdf';
                                                                                            link.click();
                                                                                        } catch (error) {
                                                                                            console.error('Error downloading PDF:', error);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                        }

                                                                    </div>
                                                                    :

                                                                    <div
                                                                    // className={mobileStyles.attachFileBox}
                                                                    >
                                                                        <input
                                                                            type="file"
                                                                            // className={mobileStyles.inputFile}
                                                                            style={{
                                                                                position: "absolute",
                                                                                opacity: 0,
                                                                            }}
                                                                            onChange={(event) => handleImageUpload(event.target.files[0], docIndex)
                                                                            }
                                                                        />
                                                                        <div>

                                                                            <img src={upload} alt="upload" width={25} height={25} />

                                                                        </div>
                                                                    </div>
                                                            }

                                                        </div>
                                                    )
                                                })}

                                                <div style={{ height: "60px" }}></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <ContainerLayout>
                        <div className='userProfileContainer'>
                            {/* <h1 className='profileHeading'>Profile</h1> */}
                            <p onClick={() => {
                                navigate(-2)
                            }} style={{ cursor: "pointer" }}>
                                <img src={back} alt="back" />
                                <span style={{ paddingLeft: "10px" }}>Back</span>
                            </p>
                            <div className='profileContainer'>
                                {/* header */}
                                <Row gutter={[32, 32]}>
                                    <Col span={4}>
                                        <center>
                                            {
                                                userProfile?.profile_image
                                                    ?
                                                    <>
                                                        <Badge
                                                            style={{ position: 'relative' }}
                                                            onClick={() => document.getElementById('file-upload').click()}
                                                        >
                                                            <img src={userProfile?.profile_image} alt="profileImg" width={150} height={150} style={{ borderRadius: '50%' }} />
                                                            <EditOutlined style={{ position: 'absolute', top: 0, right: 0, fontSize: '24px', color: 'black' }} />
                                                        </Badge>
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            onChange={uploadUserImage}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </>

                                                    :
                                                    <label htmlFor="file-upload">
                                                        <Avatar size={100} shape="square" icon={<UploadOutlined />} />
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            onChange={uploadUserImage}
                                                            style={{ display: 'none' }} // Hide the default file input styling
                                                        />
                                                    </label>

                                            }

                                        </center>
                                    </Col>
                                    <Col span={6}>
                                        <p className='userName'>{userProfile?.employee_name}</p>
                                        <p className='designation'>{userProfile?.designation && userProfile?.designation[0]?.designation}</p>
                                        <p className='officialEmail'>{userProfile?.official_email}</p>
                                    </Col>
                                    <Col span={6}>
                                        <div className='detailContainer' >
                                            <img src={reportingManager} alt="reportingManager" width={20} height={20} />
                                            <p className='headerTextField'>Reporting Manager :
                                                <span className='headerTextValue'>{userProfile?.reporting_manager[0]?.reporting_manager}</span>
                                            </p>
                                        </div>
                                        <div className='detailContainer'>
                                            <img src={department} alt="department" width={20} height={20} />
                                            <p className='headerTextField'>Department :
                                                <span className='headerTextValue'>{userProfile?.department}</span>
                                            </p>
                                        </div>
                                        <div className='detailContainer'>
                                            <img src={hrbp} alt="hrbp" width={20} height={20} />
                                            <p className='headerTextField'>HRBP :
                                                <span className='headerTextValue'>{userProfile?.hrbp && userProfile?.hrbp?.length > 0 && userProfile?.hrbp[0]?.hrbp}</span></p>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className='detailContainer' >
                                            <img src={location} alt="location" width={20} height={20} />
                                            <p className='headerTextField'>
                                                Location :
                                                <span className='headerTextValue'>{userProfile?.work_location.length > 0 && userProfile?.work_location[0]?.work_location}</span>
                                            </p>
                                        </div>
                                        <div className='detailContainer' >
                                            <img src={status} alt="status" width={20} height={20} />
                                            <p className='headerTextField'>
                                                Status:
                                                <span className='headerTextValue'>{userProfile?.status}</span>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                {/* View Section */}

                                <div style={{ padding: "0px 4%", width: "95%" }}>
                                    <Tabs defaultActiveKey="1" items={sectionViewList} onChange={(key) => setSelectedView(key)} />
                                </div>

                            </div>

                        </div>
                    </ContainerLayout>
            }

        </div>
    )
}

export default UserProfile


