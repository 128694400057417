import React, { useEffect, useState } from 'react'
import Filter from './Filter/Filter'
import Post from './Post/Post';
import Poll from './Poll/Poll';
import { Checkbox, Col, Form, Input, Modal, Row, Select, Switch } from 'antd';
import desktopStyle from './Feeds.module.scss';
import getScreenSize from '../../../utils/getScreenSize';
import filterImg from "../../../assets/filter.png";
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import request from '../../../utils/axios';
import useWebSocket from 'react-use-websocket';

function Feeds() {
    const screenSize = getScreenSize();
    const [trendingPost, setTrendingPost] = useState(false)
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [filter, setFilter] = useState([])
    const [addPostModal, setAddPostModal] = useState(false)
    const { TextArea } = Input;
    const navigate = useNavigate()
    const [postList, setPostList] = useState([])
    const [postDetails, setPostDetails] = useState({
        title: "",
        searchEmployee: "",
        text: "",
        uploadType: "",
        image: "",
    })

    const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
        `wss://ws-stage.hrengage.ai/ws/feeds/1?tenant-header=eta&token=057e69f2085f14c2d839dffbc4380b5caea388d2`,
        {
            onOpen: () => {
                console.log('WebSocket connection established.');
            }
        },
    )

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage?.data)

            setPostList([data.data.message, ...postList])
        }
    }, [lastMessage]);


    const postType = ["Appreciation Post", "Rewards Post", "Promotion Post", "Polls/survey", "Festive Post", "Polls/survey"]
    const timeDuration = ["Latest Post"]
    const postedBy = ["Admin", "Employee"]
    const postByDepartment = ["Human Resources", "Sales", "Marketing", "IT", "Finance", "Operations", "Customer Support"]


    const onChange = (checkedValues) => {
        setFilter(checkedValues)
    };

    const onChangeToggle = (checked) => {
        setTrendingPost(checked)
    };



    const [employeeList, setEmployeeList] = useState([])

    const getEmployeeList = async () => {
        let data = await request({
            method: "get",
            url: `get-all-employees`,
        })
        setEmployeeList(data.data)
    }

    const fetchPostList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/employees/post-details/all`,
            })
            console.log("response  ....  //", response.data);
            setPostList(response.data)
        }
        catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getEmployeeList();
        fetchPostList();
    }, [])

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            // formData.append("visible_to_departments", postDetails.visible_to_departments);
            formData.append("post_type", postDetails.post_type);
            formData.append("text", postDetails.text);
            formData.append("image", postDetails.image);
            formData.append("added_by_admin", false);

            const response = await request({
                method: "post",
                url: `feeds/employee/feeds/add`,
                data: formData
            })
            setAddPostModal(false);
        }
        catch (error) {
            console.log("error", error)
            setAddPostModal(false);
        }
    }

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginTop: "80px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Feeds</p>
                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <img src={filterImg} alt="filter" width={31} height={31} onClick={() => setOpenFilterModal(true)} />
                                <button style={{ backgroundColor: "#008080", color: "white", height: "40px", width: "100px", borderRadius: "5px", border: "none", fontSize: "16px" }}
                                    onClick={() => { navigate('/feeds/add-post') }}
                                >Add Post</button>
                            </div>
                        </div>
                        <Post />
                        <Poll />
                        {
                            openFilterModal
                            &&
                            <div style={{ width: "100%", height: "100vh", position: "fixed", top: "0", zIndex: "99999", background: "rgba(0, 0, 0, 0.5)" }}>
                                <div style={{ width: "100%", height: "320px", background: "white", position: "absolute", bottom: "0", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
                                    <div style={{ width: "100%", height: "300px", background: "white", position: "absolute", bottom: "0", overflow: "auto" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 25px 0px 25px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "600" }}>Filter</p>
                                            <CloseOutlined style={{ width: "20px", height: "20px" }} onClick={() => setOpenFilterModal(false)} />
                                        </div>
                                        <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px" }}>
                                            <Switch
                                                size='small'
                                                onChange={onChangeToggle}
                                            />
                                            <span style={{ color: "#161616", marginLeft: "10px", fontWeight: "500", fontSize: "18px" }}>Trending post</span>
                                        </div>
                                        <Checkbox.Group
                                            style={{ width: '100%' }}
                                            onChange={onChange}
                                        >
                                            <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px", width: "100%" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Post Type</p>


                                                <Row>
                                                    {
                                                        postType.map((item, index) => {
                                                            return (
                                                                <Col span={24} style={{ marginBottom: "10px" }}>
                                                                    <Checkbox value={item} key={index}>{item}</Checkbox>
                                                                </ Col>
                                                            )
                                                        })
                                                    }
                                                </Row>

                                            </div>
                                            <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px", width: "100%" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Time Duration</p>
                                                <Row>
                                                    {
                                                        timeDuration.map((item, index) => {
                                                            return (
                                                                <Col span={24} style={{ marginBottom: "10px" }}>
                                                                    <Checkbox value={item} key={index}>{item}</Checkbox>
                                                                </ Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                            <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px", width: "100%" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Posted by</p>
                                                <Row>
                                                    {
                                                        postedBy.map((item, index) => {
                                                            return (
                                                                <Col span={24} style={{ marginBottom: "10px" }}>
                                                                    <Checkbox value={item} key={index}>{item}</Checkbox>
                                                                </ Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                            <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Post by Department</p>
                                                <Row>
                                                    {
                                                        postByDepartment.map((item, index) => {
                                                            return (
                                                                <Col span={24} style={{ marginBottom: "10px" }}>
                                                                    <Checkbox value={item} key={index}>{item}</Checkbox>
                                                                </ Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        </Checkbox.Group>
                                    </div >
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div style={{ padding: "50px" }}>
                        <div style={{ padding: "30px", background: "white", borderRadius: "5px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                                <p style={{ fontSize: "32px", fontWeight: "500", color: "#161616", margin: "0" }}>Feeds</p>
                                <button style={{ backgroundColor: "#008080", color: "white", height: "40px", width: "100px", borderRadius: "5px", border: "none" }}
                                    onClick={() => setAddPostModal(true)}
                                >Add Post</button>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr", gap: "5vw" }}>
                                <Filter setFilter={setFilter} setTrendingPost={setTrendingPost} />
                                <div style={{ width: "700px" }}>
                                    {
                                        postList.map((item, index) => {
                                            return (
                                                <Post item={item} />
                                            )
                                        })
                                    }
                                    {/* <Post /> */}
                                    <Poll />
                                </div>
                            </div>
                        </div>

                        <Modal
                            centered
                            open={addPostModal}
                            onOk={() => setAddPostModal(false)}
                            onCancel={() => {
                                setAddPostModal(false);
                                setPostDetails({
                                    title: "",
                                    searchEmployee: "",
                                    text: "",
                                    uploadType: "",
                                    image: "",
                                })
                            }}
                            footer={null}
                        >
                            <div>
                                <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", lineHeight: "27px" }}>Add Post</p>
                                <Form
                                    layout="vertical"
                                    onFinish={onFinish}
                                    style={{ marginTop: "20px" }}
                                    fields={[
                                        {
                                            name: "title",
                                            value: postDetails.title
                                        },
                                        {
                                            name: "searchEmployee",
                                            value: postDetails.searchEmployee
                                        },
                                        {
                                            name: "text",
                                            value: postDetails.text
                                        },
                                        {
                                            name: "uploadType",
                                            value: postDetails.uploadType
                                        },
                                    ]}
                                >
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                    >
                                        <Input
                                            placeholder="Appreciation Post "
                                            size='large'
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                title: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Search Employee"
                                        name="post_type"
                                    >
                                        <Select
                                            size='large'
                                            style={{ width: "100%" }}
                                            placeholder="Select Employee"
                                            onChange={(value) => setPostDetails({
                                                ...postDetails,
                                                post_type: value
                                            })}
                                        >
                                            {
                                                employeeList?.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                                                    )
                                                }
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Text"
                                        name="text"
                                    >
                                        <TextArea
                                            placeholder="Write text here"
                                            size='large'
                                            rows={4}
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                text: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Upload image"
                                        name="uploadType"
                                    >
                                        <Select
                                            size='large'
                                            style={{ width: "100%" }}
                                            placeholder="Select Category"
                                            value={postDetails.uploadType}
                                            onChange={(value) => setPostDetails({
                                                ...postDetails,
                                                uploadType: value
                                            })}
                                        >
                                            <Select.Option value="1">Default Image</Select.Option>
                                            <Select.Option value="2">Upload Image</Select.Option>
                                            <Select.Option value="3">None</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    {
                                        postDetails.uploadType === "1" &&
                                        <div style={{ marginBottom: "20px" }}>
                                            <img src="https://picsum.photos/200" alt="" width={100} height={100} />
                                        </div>
                                    }

                                    {
                                        postDetails.uploadType === "2" &&
                                        <Form.Item
                                            // label="Upload File"
                                            name="document"

                                        >
                                            <div className={desktopStyle.fileUploadContainer}>
                                                <input
                                                    type="file"
                                                    id="file-upload"
                                                    className={desktopStyle.inputFile}
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files[0];
                                                        const maxSizeInBytes = 5 * 1024 * 1024;

                                                        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                                                        // Check if the selected file is an allowed image type
                                                        if (allowedImageTypes.includes(selectedFile.type)) {
                                                            // Check if the file size is within the allowed limit
                                                            if (selectedFile.size <= maxSizeInBytes) {
                                                                setPostDetails({
                                                                    ...postDetails,
                                                                    image: selectedFile
                                                                })
                                                            } else {
                                                                // File size exceeds the limit
                                                                // setFile(null);
                                                                setPostDetails({
                                                                    ...postDetails,
                                                                    image: ""
                                                                })
                                                                alert('File size exceeds the limit of 5MB.');
                                                            }
                                                        } else {
                                                            // File is not an allowed image type
                                                            setPostDetails({
                                                                ...postDetails,
                                                                image: ""
                                                            })
                                                            alert('Please upload a valid image file (JPEG, PNG, GIF).');
                                                        }
                                                    }
                                                    }
                                                />
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                                    <label htmlFor="file-upload" className={desktopStyle.fileUploadLabel}>

                                                    </label>
                                                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                                    <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    }

                                    <button
                                        style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                                        button="submit"
                                    >Post</button>
                                </Form>
                            </div>
                        </Modal>
                    </div>
            }


        </div>
    )
}

export default Feeds