import { Button, Col, Dropdown, Form, Input, Modal, Row, Select, Spin, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import fullView from "../../../assets/fullView.svg"
import { FilterTwoTone, LoadingOutlined } from '@ant-design/icons';
import getScreenSize from "../../../utils/getScreenSize"
import { Tabs } from 'antd';
// import "./DocumentsDashboard.scss"
import signature from "../../../assets/signature.svg"
import { useNavigate } from 'react-router-dom';
import request from '../../../utils/axios';
import { toast } from 'react-toastify';
import back from "../../../assets/Recruitment/back.svg"
import CommonTable from '../../../component/CommonTable/CommonTable';
import PolicySearch from '../PolicySearch/PolicySearch';
import ContainerLayout from '../../../component/ContainerLayout/ContainerLayout';

function PoliciesFullView() {
    const [form] = Form.useForm()
    const screenSize = getScreenSize();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const [openModal, setOpenModal] = useState(false);
    const [modelFor, setModelFor] = useState("")
    const [hrLetterModel, setHrLetterModel] = useState(false);
    const [requireSign, setRequireSign] = useState(false);
    const PoliciesColumns = [
        {
            title: 'File Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'File Type',
            dataIndex: 'file_type',
            key: 'file_type',
        },
        {
            title: 'Signed By',
            dataIndex: 'signed_by',
            key: 'signed_by',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <Select
                defaultValue={null}
                onChange={(e) => onHandleSelectPolicy(e)}
                placeholder="Select"
                style={{ width: '180px' }}
            >
                <Select.Option value={null} disabled >Select</Select.Option>
                <Select.Option value={1}>Upload New Version</Select.Option>
            </Select>
        }
    ]



    const [File, setFile] = useState(null)

    const onHandleSelectPolicy = (value) => {
        console.log("value", value);
        if (value === 1) {
            console.log("value111", value);
            setOpenModal(true)
        }
    }

    const [policyLoader, setPolicyLoader] = useState(false)

    const onFinish = async (values) => {
        const formData = new FormData();
        setPolicyLoader(true)
        try {
            if (modelFor === "HRLetters") {

                formData.append("name", values.name);
                formData.append("file_type", values.file_type);
                formData.append("document_for", values.document_for);
                formData.append("document", File);

                await request({
                    method: "post",
                    url: `/documentation/hr/letters/upload`,
                    data: formData
                })

                setHrLetterModel(false)



                await getPolicyList();
                toast.success("HR letter uploaded", {
                    position: "bottom-left",
                })
                navigate("/admin/docs/hr-letter", { replace: true })
            }
            else {

                formData.append("name", values.name);
                formData.append("file_type", values.file_type);
                formData.append("document", File);
                formData.append("is_signed_required", requireSign);

                await request({
                    method: "post",
                    url: `/documentation/policy/document/upload`,
                    data: formData
                })

                setOpenModal(false)
                toast.success("New Policy uploaded", {
                    position: "bottom-left",
                })
                await getPolicyList();
                navigate("/admin/docs/policies", { replace: true })
            }
        }
        catch (error) {
            console.log("error")
            if (modelFor === "HRLetters") {
                toast.error("Could not upload HR letter, please try after some time", {
                    position: "bottom-left",
                })
            }
            else {
                toast.error("Could not upload policy, please try after some time", {
                    position: "bottom-left",
                })
            }
        }
        finally {
            form.resetFields()
            setFile(null)
            setPolicyLoader(false)
        }

    }

    const { TabPane } = Tabs;

    // CSS style object to add space between tabs
    const tabStyle = {
        marginRight: '100px', // Adjust the margin as per your preference
    };

    const [employeeList, setEmployeeList] = useState([])
    const fetchAllEmployeeList = async () => {

        const data = await request({
            method: "get",
            url: `/get-all-employees`,
        })
        setEmployeeList(data.data)
        // console.log("data", data);
    }

    const [policiesList, setPoliciesList] = useState([])
    const getPolicyList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/documentation/policy/document/list?page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            console.log("data");
            setPoliciesList(data.pagination_data.data)
            setPagination({
                ...pagination,
                total: data?.pagination_data?.total
            })
        }
        catch (error) {
            console.log("error");
        }
    }



    const [fileTypeList, setFileType] = useState([])
    const fetchFileType = async () => {
        try {
            if (modelFor) {
                const data = await request({
                    method: "get",
                    url: `/documentation/file_type/all?type=${modelFor}`,
                })
                setFileType(data)
            }

        }
        catch (error) {
            console.log("error");
        }
    }

    useEffect(() => {
        getPolicyList();
        fetchAllEmployeeList();

    }, [pagination.current])

    useEffect(() => {
        fetchFileType();
    }, [modelFor])


    const policiesSelectOption = [
        {
            key: '1',
            label:
                <a>
                    1st menu item
                </a>

        },
        {
            key: '2',
            label:
                <a>
                    1st menu item
                </a>

        },
    ]
    const policiesView = () => {

        return (
            <div>
                <div style={{ padding: "16px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Policies</p>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                                style={{
                                    background: "white",
                                    color: "#008080",
                                    border: "1px solid #008080",
                                    height: "25px",
                                    fontSize: "11px",
                                    borderRadius: "2px"
                                }}
                            >
                                Filtter
                                <FilterTwoTone twoToneColor="#008080" style={{ fontSize: "10px" }} />
                            </Button>
                            <Button
                                style={{
                                    background: "#008080",
                                    color: "white",
                                    height: "25px",
                                    fontSize: "10px",
                                    borderRadius: "2px"
                                }}
                                onClick={() => {
                                    navigate("/admin/docs/add/policies", { replace: true })
                                }}
                            >
                                Add New Document
                            </Button>
                        </div>
                    </div>
                    <input
                        type="text"
                        placeholder="Search"
                        style={{
                            border: "1px solid #DDDDDD",
                            width: "100%",
                            height: "40px",
                            borderRadius: "20px",
                            marginBottom: "15px",
                            marginTop: "10px"
                        }}
                    />
                    {
                        policiesList?.map((item, index) => {
                            return (
                                <div style={{ background: "white", marginTop: "8px", borderRadius: "8px" }}>
                                    <div style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)", padding: "15px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <p style={{
                                                color: "#313131",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                                margin: "0px"
                                            }}>{item.name}</p>
                                            <Dropdown
                                                menu={
                                                    {
                                                        items: policiesSelectOption
                                                    }
                                                }
                                                placement="bottom"
                                            >
                                                <Button style={{ background: "#F2F2F2", height: "25px", fontSize: "10px" }}>Select</Button>
                                            </Dropdown>
                                        </div>
                                        <p style={{
                                            color: "#696969",
                                            fontSize: "12px",
                                            fontWeight: "400"
                                        }}>{item.file_type}</p>
                                        <p style={{
                                            color: "#3C3C3C",
                                            fontSize: "12px",
                                            fontWeight: "400"
                                        }}><img src={signature} alt="signature" />{item.signed_by}</p>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        )
    }




    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div style={{ height: "100vh" }}>
                        <div style={{ width: "100%", background: "white", height: "100vh" }}>
                            {policiesView()}
                        </div>

                    </div>
                    :
                    <ContainerLayout>
                        <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        {/* <p className='heading'>Documents</p> */}
                        <div style={{
                            background: "white",
                            padding: "40px"
                        }}>
                            <div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <p style={{ fontSize: "20px", fontWeight: "500" }}>Policies</p>

                                    <div style={{ display: "flex", gap: "40px" }}>
                                        <PolicySearch
                                            setPoliciesList={setPoliciesList}
                                            fetchTableDataFromApi={getPolicyList}
                                        />
                                        <Button
                                            style={{
                                                background: "#008080",
                                                color: "white",
                                                height: "44px"
                                            }}
                                            onClick={() => {
                                                setOpenModal(true)
                                                setModelFor("PolicyDocuments")
                                            }}
                                        >Add New Document</Button>
                                        {/* <img src={fullView} alt="fullView" width={35} height={35}

                                        /> */}
                                    </div>
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                    <CommonTable
                                        columns={PoliciesColumns}
                                        data={policiesList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        handleRowClick={() => { }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ContainerLayout>
            }


            <Modal
                open={openModal}
                onOk={() => { }}
                onCancel={() => {
                    setOpenModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>New Policies</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                // onValuesChange={onValuesChange}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label="File Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your File Name!',
                                    },
                                ]}
                            >
                                <Input size='large' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24}>
                        <Form.Item
                            label="File Type"
                            name="file_type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your File Type!',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                size='large'
                            >
                                {
                                    fileTypeList?.map((option) => {
                                        return (
                                            <Select.Option value={option.value} >
                                                {option.label}
                                            </Select.Option>

                                        )
                                    })
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <p>Upload File</p>
                        <Form.Item
                            label="Upload File"
                            name="document"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Date (To)!',
                                },
                            ]}
                        >
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="input-file"
                                    onChange={(e) =>
                                    // setFile(e.target.files && e.target.files[0])
                                    {
                                        // if (e.target.files[0] && e.target.files[0].type === 'text/csv') {
                                        //     setFile(e.target.files && e.target.files[0])
                                        // } else {
                                        //     // Reset the file state and show an error message
                                        //     setFile(null);
                                        //     alert('Please upload a CSV file.');
                                        // }

                                        const selectedFile = e.target.files[0];
                                        const maxSizeInBytes = 5 * 1024 * 1024;

                                        if (selectedFile && selectedFile.type === 'application/pdf') {
                                            if (selectedFile.size <= maxSizeInBytes) {
                                                setFile(e.target.files && selectedFile)
                                            }
                                            else {
                                                setFile(null);
                                                alert('File size exceeds the limit of 5MB.');
                                            }
                                        } else {
                                            // Reset the file state and show an error message
                                            setFile(null);
                                            alert('Please upload a PDF file.');
                                        }
                                    }
                                    }
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <label htmlFor="file-upload" className="file-upload-label">

                                    </label>
                                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                    <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ marginTop: "60px" }}>
                            <Switch
                                checked={requireSign}
                                size='medium'
                                onChange={(checked) => setRequireSign(checked)}
                            />
                            {" "}
                            <span style={{ color: "#9F9F9F", fontSize: "12px" }}>Requires Signing Off By Employee</span>
                        </div>
                    </Col>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        {
                            policyLoader
                                ?
                                <Spin
                                    indicator={
                                        <LoadingOutlined
                                            style={{ fontSize: "15px", color: "white" }}
                                            spin
                                        />
                                    }
                                />
                                :
                                "Submit"
                        }
                    </Button>
                </Form>
            </Modal>

        </div>
    )
}

export default PoliciesFullView