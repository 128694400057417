import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../component/CommonTable/CommonTable'
import request from '../../../../utils/axios';
import { toast } from 'react-toastify';
import getScreenSize from '../../../../utils/getScreenSize';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function TeamLeaveRequests() {
    const screenSize = getScreenSize();
    const myTeamAppliedLeaveDetailsColumns = [
        {
            title: "Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "From",
            dataIndex: "start_date",
            key: "start_date",
        },
        {
            title: "To",
            dataIndex: "end_date",
            key: "end_date",
        },
        {
            title: "No. Of Leave",
            dataIndex: "leave_day",
            key: "leave_day",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <button
                            style={{ background: "green", color: "white", border: "none" }}
                            onClick={() => {
                                rejectOrApproveLeave(record.id, false);
                            }}
                        >
                            Approve
                        </button>
                        <button
                            style={{ background: "red", color: "white", border: "none" }}
                            onClick={() => {
                                rejectOrApproveLeave(record.id, true);
                            }}
                        >
                            Reject
                        </button>
                    </div>
                </>
            ),
        },
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const rejectOrApproveLeave = async (id, isRejected) => {
        try {
            await request({
                method: "post",
                url: `leave/my-team/leave_application/approval/?id=${id}`,
                data: {
                    is_rejected: isRejected,
                },
            });

            setTimeout(() => {
                fetchMyTeamAppliedLeaveDetails();
            }, 1000);
            if (isRejected)
                toast.success("Leave Rejected", {
                    position: "bottom-left",
                });
            else {
                toast.success("Leave Approved", {
                    position: "bottom-left",
                });
            }
        } catch (error) {
            console.log(error);
            if (isRejected) {
                toast.error("Leave could not be rejected, please try after some time", {
                    position: "bottom-left",
                });
            } else {
                toast.error("Leave could not be approved, please try after some time", {
                    position: "bottom-left",
                });
            }
        }
    };

    const [myTeamAppliedLeaveDetails, setMyTeamAppliedLeaveDetails] = useState([]);

    const fetchMyTeamAppliedLeaveDetails = async () => {
        try {
            const data = await request({
                method: "get",
                url: `leave/my-team/leave_application/all/?page=1&page_size=3`,
            });
            if (data && data?.pagination_data && data?.pagination_data?.data) {
                setMyTeamAppliedLeaveDetails(data?.pagination_data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMyTeamAppliedLeaveDetails();
    }, [])

    const renderUI = (dataList) => {
        return (
            <div style={{ marginTop: "20px" }}>
                {
                    dataList?.map((item) => {
                        return (
                            <div>
                                <div style={{ padding: "0px 16px" }}>
                                    <p style={{ fontSize: "18px", color: "#161616", fontWeight: "600" }}>{item?.employee_name}</p>
                                    <p>{item?.start_date} - {item?.end_date}</p>
                                    <p>{item?.leave_day} day</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "20px" }}>
                                        <button
                                            style={{ background: "green", color: "white", border: "none", width: "100%", height: "40px", borderRadius: "5px", fontWeight: "600" }}
                                            onClick={() => {
                                                rejectOrApproveLeave(item.id, false);
                                            }}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            style={{ background: "red", color: "white", border: "none", width: "100%", height: "40px", borderRadius: "5px", fontWeight: "600" }}
                                            onClick={() => {
                                                rejectOrApproveLeave(item.id, true);
                                            }}
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div>
                        <InfiniteScrollComponent
                            apiEndpoint="leave/my-team/leave_application/all/"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </div>
                    :
                    <div>
                        <p style={{ fontSize: "28px" }}>My Team Leave Requests</p>
                        <div style={{ padding: "30px", background: "white", borderRadius: "5px" }}>
                            <CommonTable
                                columns={myTeamAppliedLeaveDetailsColumns}
                                data={myTeamAppliedLeaveDetails}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                        </div>
                    </div>
            }

        </div>
    )
}

export default TeamLeaveRequests