import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios';
import activeDownload from "../../../assets/activeDownload.png"
import view from "../../../assets/view.png"
import upload from "../../../assets/upload.png"
import update from "immutability-helper";
import pdf from "../../../assets/PDF.png"
import { CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

function MyDocs() {
    const params = useParams();
    const [docs, setDocs] = useState([
        {
            name: "Experience Letter",
            key: "experience_letter",
            file: "",
        },
        {
            name: "Education certificates",
            key: "educational_certificate",
            file: "",
        },
        {
            name: "Appointment letter of previous employer",
            key: "appointment_letter",
            file: "",
        },
        {
            name: "Resignation Acceptance",
            key: "resignation_acceptance",
            file: "",
        },
        {
            name: "Resignation Acceptance letter",
            key: "resignation_acceptance_letter",
            file: "",
        },
        {
            name: "Last 3 months payslip",
            key: "salary_slip_three_months",
            file: "",
        },
        {
            name: "Bank statement",
            key: "bank_statement",
            file: "",
        },
        {
            name: "Resume",
            key: "resume",
            file: "",
        },
        {
            name: "Adhaar Card",
            key: "aadhar_card",
            file: "",
        },
        {
            name: "PAN Card",
            key: "pan",
            file: "",
        },
        {
            name: "Cancelled Cheque",
            key: "cancelled_cheque",
            file: "",
        },
        {
            name: "Passport size photo",
            key: "passport_size_photo",
            file: "",
        },
    ]);

    const fetchUploadedDocs = async () => {
        try {
            if (params.id) {
                const res = await request({
                    method: "get",
                    url: `/admin/employee/profile/personal-documents/all?employement_number=${params.id}`,
                });

                if (res.data.length > 0) {

                    const check = docs?.map((doc, docIndex) => {
                        const matchedData = res?.data?.find(item => item.document_type === doc.key);

                        if (matchedData) {
                            return { ...doc, file: matchedData.filename, path: matchedData.file };
                        }
                        return doc;
                    });


                    setDocs(check)
                }
            }
            else {
                const res = await request({
                    method: "get",
                    url: `/onbaording/employee/personal-documents/all`,
                });

                if (res.data.length > 0) {

                    const check = docs?.map((doc, docIndex) => {
                        const matchedData = res?.data?.find(item => item.document_type === doc.key);

                        if (matchedData) {
                            return { ...doc, file: matchedData.filename, path: matchedData.file };
                        }
                        return doc;
                    });


                    setDocs(check)
                }
            }

        }
        catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchUploadedDocs()
    }, [])

    const handleImageUpload = async (file, index) => {

        const updatedDocs = update(docs, {
            [index]: {
                file: { $set: file },
            },
        });

        console.log("updatedDocs", updatedDocs);
        setDocs(updatedDocs);

        const formData = new FormData();
        formData.append(docs[index].key, file);

        await request({
            method: "post",
            url: `/onboarding/employee/document/upload`,
            data: formData
        })

    };


    return (
        <div style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", borderRadius: "23px", padding: "20px 50px" }}>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>My Documents</p>
            {docs?.map((item, index) => {
                return (
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "10px" }}>
                        <div>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616", margin: "0px", marginBottom: "8px" }}>
                                {item.name}
                            </p>
                            {
                                item?.file
                                &&
                                <div style={{ display: "flex", gap: "10px", alignItems: "center", border: "1px dotted #008080", width: "fit-content", borderRadius: "5px", padding: "2px 10px" }} >
                                    <img src={pdf} alt="pdf" width={20} height={20} />
                                    <p style={{ margin: "0", fontSize: "16px", color: "#161616" }} >
                                        {item?.file?.name ? item?.file?.name : item?.file}
                                        {/* {doc?.file && } */}
                                    </p>
                                    <CloseOutlined />
                                </div>
                            }
                        </div>
                        {
                            item?.file ?
                                <div

                                    style={{ display: "flex", gap: "20px" }}>
                                    <img src={activeDownload} alt="activeDownload"
                                        width={25} height={25}
                                        onClick={async () => {
                                            const documentUrl = item.path;
                                            console.log("DocumentUrl :", documentUrl)
                                            try {
                                                // Fetch the file using the pre-signed S3 URL, ensuring no extra headers are added
                                                const response = await fetch(documentUrl, {
                                                    method: 'GET',
                                                    headers: {
                                                        // Ensure no extra headers like Authorization are sent
                                                        'Content-Type': 'application/pdf',
                                                        'Accept': 'application/pdf'
                                                    }
                                                });
                                            
                                                if (!response.ok) {
                                                    throw new Error(`HTTP error! status: ${response.status}`);
                                                }
                                            
                                                // Read the response as a Blob (binary data)
                                                const blob = await response.blob();
                                            
                                                // Create a URL for the blob and trigger the download
                                                const url = window.URL.createObjectURL(blob);
                                                console.log("Generated file URL: ", url);
                                            
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.download = 'file.pdf';  // Set the filename
                                                link.click();
                                            
                                                // Clean up the object URL after the download
                                                window.URL.revokeObjectURL(url);
                                            
                                            } catch (error) {
                                                console.error('Error downloading PDF:', error);
                                            }

                                            // try {
                                            //     const response = await request({
                                            //         method: "get",
                                            //         url: documentUrl,
                                            //         responseType: 'arraybuffer',
                                            //         headers: {  // Make sure no additional headers like 'Authorization' are being sent
                                            //             'Content-Type': 'application/pdf',  // If you expect a PDF, set the correct content type
                                            //             'Accept': 'application/pdf',        // Accept header to handle PDFs
                                            //         }
                                            //     });

                                            //     const file = new Blob([response.data], { type: 'application/pdf' });
                                            //     const url = window.URL.createObjectURL(file);
                                            //     console.log("url...........", url)
                                            //     const link = document.createElement('a');
                                            //     link.href = url;
                                            //     link.download = 'file.pdf';
                                            //     link.click();
                                            // } catch (error) {
                                            //     console.error('Error downloading PDF:', error);
                                            // }
                                        }}
                                    />
                                    {/* </a> */}

                                    <img src={view} alt="view" width={25} height={25}
                                        onClick={() => window.open(item?.path)}
                                    />
                                </div>
                                :
                                // <img src={upload} alt="upload" width={25} height={25} />
                                <div
                                // className={mobileStyles.attachFileBox}
                                >
                                    <input
                                        type="file"
                                        // className={mobileStyles.inputFile}
                                        style={{
                                            position: "absolute",
                                            opacity: "0",
                                        }}
                                        onChange={(event) => handleImageUpload(event.target.files[0], index)
                                        }
                                    />
                                    <div>

                                        <img src={upload} alt="upload" />

                                    </div>
                                </div>
                        }

                    </div>
                )
            })}
        </div>
    )
}

export default MyDocs