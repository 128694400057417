import { Radio } from 'antd'
import React, { useState } from 'react'
import Selective from './Selective/Selective';
import EveryOne from './EveryOne/EveryOne';
import { useNavigate } from 'react-router-dom';
import back from '../../../../assets/Recruitment/back.svg';

function BulkLeavePolicy() {
    const navigate = useNavigate();
    const [bulkUploadType, setBulkUploadType] = useState("selective");
    const [selectiveDataList, setSelectiveDataList] = useState([]);
    // const [everyoneDataList, setEveryoneDataList] = useState([]);

    return (
        <div style={{ padding: "50px" }}>
            <p onClick={() => { navigate('/admin/leave/5') }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
            <div style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                <div>
                    <p style={{ color: "#161616", fontSize: "24px", fontWeight: "600" }}>Select Employees for Leave Policy Change</p>

                    <div >
                        <Radio.Group
                            size='large'
                            value={bulkUploadType}
                            onChange={(e) => {
                                // setSelectedSelectiveRowKeys([]);
                                setSelectiveDataList([]);
                                // setTimeout(() => {
                                setBulkUploadType(e.target.value)
                                // }, 500)
                            }}
                        >
                            <Radio.Button value="selective">Selective</Radio.Button>
                            <Radio.Button value="everyone">Everyone</Radio.Button>
                        </Radio.Group>

                    </div>
                </div>
                <div>
                    {
                        bulkUploadType === "selective"
                            ?

                            <Selective />
                            :
                            <EveryOne />
                    }
                </div>
            </div>
        </div>
    )
}

export default BulkLeavePolicy