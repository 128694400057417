import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react';
import request from '../../../../utils/axios';
import "./PayrollSummary.scss"
import { useNavigate, useParams } from 'react-router-dom';
import getScreenSize from '../../../../utils/getScreenSize';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';
import back from '../../../../assets/Recruitment/back.svg';

function PayrollSummary() {
    const params = useParams();
    const [salaryBreakup, setSalaryBreakup] = useState()
    const screenSize = getScreenSize();
    const navigate = useNavigate();
    const fetchSalaryBreakup = async () => {
        try {
            if (params.userId) {
                let data = await request({
                    method: "get",
                    url: `payroll/get-salary-breakup/?date=${params.date}&employee_id=${params.userId}`,
                })
                setSalaryBreakup(data?.data)
            }
            else {
                let data = await request({
                    method: "get",
                    url: `payroll/get-salary-breakup/?date=${params.date}`,
                })
                setSalaryBreakup(data?.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchSalaryBreakup();
    }, [])


    return (
        <div className='salaryBreakup'>
            {
                screenSize.width < 768
                    ?
                    <div style={{ padding: "0px 16px", marginTop: "80px" }}>
                        <p style={{ fontSize: "24px ", fontWeight: "500", color: "#161616" }}>Salary Breakup</p>
                        <Row>
                            {
                                salaryBreakup?.fixed && salaryBreakup?.fixed.length > 0 &&
                                <>
                                    <Col span={24}><p className="salaryBreakupHeading">Fixed</p></Col>
                                    {
                                        salaryBreakup?.fixed.map((item, index) => {
                                            return (
                                                <>
                                                    <Col span={24}><p className='salaryBreakupSubComponent' >{item?.name}</p></Col>
                                                    <Col span={24}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }

                            {
                                salaryBreakup?.flexi && salaryBreakup?.flexi.length > 0 &&
                                <>
                                    <Col span={24}><p className="salaryBreakupHeading">Flexi</p></Col>
                                    {
                                        salaryBreakup?.flexi.map((item, index) => {
                                            return (
                                                <>
                                                    <Col span={24}><p className='salaryBreakupSubComponent' >{item?.name}</p></Col>
                                                    <Col span={24}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }

                            {
                                salaryBreakup?.variable && salaryBreakup?.variable.length > 0 &&
                                <>
                                    <Col span={24}><p className="salaryBreakupHeading">Variable</p></Col>
                                    {
                                        salaryBreakup?.variable.map((item, index) => {
                                            return (
                                                <>
                                                    <Col span={24}><p className='salaryBreakupSubComponent' >{item?.name}</p></Col>
                                                    <Col span={24}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }
                            {
                                salaryBreakup?.retirals && salaryBreakup?.retirals.length > 0 &&
                                <>
                                    <Col span={24}><p className="salaryBreakupHeading">Retirals</p></Col>
                                    {
                                        salaryBreakup?.retirals.map((item, index) => {
                                            return (
                                                <>
                                                    <Col span={24}><p className='salaryBreakupSubComponent' >{item?.name}</p></Col>
                                                    <Col span={24}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }

                        </Row>
                    </div>
                    :
                    <ContainerLayout>
                        <p onClick={() => { navigate('/payroll/salary-details') }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        {/* <p style={{ fontSize: "32px ", fontWeight: "500", color: "#161616" }}>Payroll</p> */}
                        <div className='salaryBreakupComponentContainer'>
                            <p style={{ fontSize: "24px ", fontWeight: "500", color: "#161616" }}>Salary Breakup</p>
                            <br />
                            <Row>
                                <Col span={16} className="salaryBreakupHeading">
                                    Component
                                </Col>
                                <Col span={4} className="salaryBreakupHeading">
                                    Annually
                                </Col>
                                <Col span={4} className="salaryBreakupHeading">
                                    Monthly
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                {
                                    salaryBreakup?.fixed.length > 0
                                    &&
                                    <Col span={24}><p className="salaryBreakupHeading">Fixed</p></Col>
                                }

                                {
                                    salaryBreakup?.fixed.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={16}><p className='salaryBreakupSubComponent' >{item?.name}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.monthly_amount}</p></Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                            <Row>
                                {
                                    salaryBreakup?.flexi.length > 0
                                    &&
                                    <Col span={24}><p className="salaryBreakupHeading">Flexi</p></Col>
                                }
                                {
                                    salaryBreakup?.flexi.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={16}><p className='salaryBreakupSubComponent'>{item?.name}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.monthly_amount}</p></Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                            <Row>
                                {
                                    salaryBreakup?.variable.length > 0
                                    &&
                                    <Col span={24}><p className="salaryBreakupHeading">Variable</p></Col>
                                }
                                {
                                    salaryBreakup?.variable.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={16}><p className='salaryBreakupSubComponent'>{item?.name}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.monthly_amount}</p></Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                            <Row>
                                {
                                    salaryBreakup?.retirals.length > 0
                                    &&
                                    <Col span={24}><p className="salaryBreakupHeading">Retirals</p></Col>
                                }
                                {
                                    salaryBreakup?.retirals.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={16}><p className='salaryBreakupSubComponent'>{item?.name}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.yearly_amount}</p></Col>
                                                <Col span={4}><p className='salaryBreakupSubComponentValue'>₹{item?.monthly_amount}</p></Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </ContainerLayout>
            }



        </div>
    )
}

export default PayrollSummary
