import React from 'react';
import MeetingForm from './MeetingForm';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import back from '../../../assets/Recruitment/back.svg';
import getScreenSize from '../../../utils/getScreenSize';
const AddMeeting = () => {
  const screenSize = getScreenSize();
  const location = useLocation();
  const navigate = useNavigate();
  const {type} = useParams();

  console.log(type);
  return (
    <>
      {screenSize.width < 768 ? (
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}>
          <MeetingForm MeetingType={type} />
        </div>
      ) : (
        // desktop view
        <div style={{padding: '50px'}}>
          <p
            onClick={() => {
              navigate(-1);
            }}
            style={{cursor: 'pointer'}}>
            <img src={back} alt="back" />
            <span style={{paddingLeft: '10px'}}>Back</span>
          </p>

          <p style={{fontSize: '32px', font: 500}}>
            {type == 'teammeeting' ? 'Add Team Meeting' : 'Add My Meeting'}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}>
            <MeetingForm MeetingType={type} />
          </div>
        </div>
      )}
    </>
  );
};

export default AddMeeting;
