import React, { useEffect, useRef, useState } from 'react';
import getScreenSize from '../../../../utils/getScreenSize';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Table } from 'antd';
import add from "../../../../assets/Org/add.svg"
import downloadImg from "../../../../assets/downlaod.svg";
import back from "../../../../assets/Recruitment/back.svg"

import { saveAs } from 'file-saver';
import request from '../../../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function ReimbursementFullView() {

    const reimbursementColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Claim Amount ',
            dataIndex: 'claim_amount',
            key: 'amount',
        },
        {
            title: 'Approved Amount ',
            dataIndex: 'approved_amount',
            key: 'approved_amount',
        },
        {
            title: 'Merchant Name',
            dataIndex: 'merchant_name',
            key: 'merchant_name',
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice_id',
            key: 'invoice ',
        },
        {
            title: 'Status',
            dataIndex: 'approved',
            key: 'approved'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Documents',
            dataIndex: 'file_upload',
            key: 'file_upload',
            render: (file_upload, record) => (
                <p style={{
                    borderRadius: "15px",
                    textAlign: "center",
                    cursor: "pointer",
                }}
                    // download pdf (getting url from api)
                    onClick={async () => {
                        try {
                            record?.reimbursement_files.map(async (item, index) => {
                                const file_upload = item.file_upload
                                const response = await fetch(file_upload); // Fetch the PDF file
                                const blob = await response.blob();
                                const filename = file_upload.substring(file_upload.lastIndexOf('/') + 1);
                                saveAs(blob, filename); // Save the blob as a PDF file
                            })
                        } catch (error) {
                            console.error('Error downloading PDF:', error);
                        }
                    }}>
                    <img src={downloadImg} alt="downloadImg" />
                </p>
            )
        }
    ]
    const ref = useRef();
    const screenSize = getScreenSize();
    const navigate = useNavigate();
    const [employeeReimbursement, setEmployeeReimbursement] = useState(false)
    const [employeeReimbursementSummary, setEmployeeReimbursementSummary] = useState()
    const [EmployeeReimbursementData, setEmployeeReimbursementData] = useState({
        title: "",
        amount: "",
        merchant_name: "",
        description: "",
        invoice_id: "",
        date: "",
        attach_file: []
    })
    const [reimbursementForm] = Form.useForm()
    const [formSubmit, setFormSubmit] = useState(false)


    const fetchemployeeReimbursement = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/get-employee-reimbursement?page=1&page_size=5`,
            })
            console.log(data)
            setEmployeeReimbursementSummary([...data.pagination_data.data])
        }
        catch (err) {
            console.log(err)
        }
    }

    const onFinish = async (values) => {
        const formData = new FormData();
        try {
            formData.append("title", EmployeeReimbursementData.title);
            formData.append("amount", EmployeeReimbursementData.amount);
            formData.append("merchant_name", EmployeeReimbursementData.merchant_name);
            formData.append("description", EmployeeReimbursementData.description);
            formData.append("invoice_id", EmployeeReimbursementData.invoice_id);
            // formData.append("file", EmployeeReimbursementData.attach_file)
            EmployeeReimbursementData.attach_file.forEach(file => {
                formData.append("files", file); // Append each file
            });
            formData.append("date", EmployeeReimbursementData.date)
            console.log("Form_data : ", formData)

            await request({
                method: "post",
                url: `payroll/create-employee-reimbursement`,
                data: formData
            })
            await fetchemployeeReimbursement();
            setEmployeeReimbursement(false);
            reimbursementForm.resetFields();
            toast.success("Reimbursement Request submitted successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Reimnursement request could not be submitted, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const onHandleChange = (key, value) => {
        if (key === "attach_file") {
            setEmployeeReimbursementData({
                ...EmployeeReimbursementData,
                "attach_file": [...EmployeeReimbursementData.attach_file, value]
            });
        }
        else {
            setEmployeeReimbursementData({
                ...EmployeeReimbursementData,
                [key]: value
            });
        }
    }

    useEffect(() => {
        fetchemployeeReimbursement();
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        console.log("EmployeeReimbursementData...", EmployeeReimbursementData);
    }, [EmployeeReimbursementData])

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <div>
                    <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>{item?.title}</p>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>₹ {item?.date}</p>
                        <Row>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Amount</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>₹ {item?.claim_amount}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Invoice ID</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item?.invoice_id}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Merchant</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item?.merchant_name}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Document</p>
                            </Col>
                            <Col span={16}>
                                <p style={{
                                    borderRadius: "15px",
                                    textAlign: "end",
                                    cursor: "pointer",
                                }}
                                    onClick={async () => {
                                        try {
                                            const file_upload = item.file_upload
                                            const response = await fetch(file_upload); // Fetch the PDF file
                                            const blob = await response.blob();
                                            const filename = file_upload.substring(file_upload.lastIndexOf('/') + 1);
                                            saveAs(blob, filename); // Save the blob as a PDF file
                                        } catch (error) {
                                            console.error('Error downloading PDF:', error);
                                        }
                                    }}>
                                    <img src={downloadImg} alt="downloadImg" />
                                </p>
                            </Col>
                            <Col span={24}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Description</p>
                            </Col>
                            <Col span={24}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item?.description}</p>
                            </Col>
                        </Row>
                    </div>
                    {
                        index !== employeeReimbursementSummary?.length - 1 && <hr />
                    }

                </div>
            )
        })
    }

    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?

                    <div style={{ background: "white", borderRadius: "4px" }}>
                        <div className='flexSpaceBetween' style={{ padding: "0px 16px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Reimbursement</p>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <img src={add} alt="add" width={35} onClick={() => navigate("/payroll/reimbursement/form", { replace: true })} />
                            </div>
                        </div>
                        <InfiniteScrollComponent
                            apiEndpoint="payroll/get-employee-reimbursement"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                        <div>
                            {

                            }
                        </div>
                    </div>

                    :
                    <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p> */}
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Reimbursement</p>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <img src={add} alt="add" width={35} onClick={() => setEmployeeReimbursement(true)} />
                            </div>
                        </div>
                        <Table
                            columns={reimbursementColumns}
                            dataSource={employeeReimbursementSummary}
                            pagination={false}
                        />
                    </div>
            }

            <Modal
                title="Reimbursement"
                centered
                open={employeeReimbursement}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setEmployeeReimbursement(false)}
                width={1000}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={onFinish} >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter title!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter title"
                                    onChange={(e) => onHandleChange("title", e.target.value)}
                                    value={EmployeeReimbursementData.title}
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChange("date", dateString)}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChange("amount", e.target.value)}
                                    value={EmployeeReimbursementData.amount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Invoice ID"
                                name="InvoiceId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter invoice id!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Invoice Id"
                                    onChange={(e) => onHandleChange("invoice_id", e.target.value)}
                                    value={EmployeeReimbursementData.invoice_id}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Merchant Name"
                                name="merchant_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter merchant name!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Merchant Name"
                                    onChange={(e) => onHandleChange("merchant_name", e.target.value)}
                                    value={EmployeeReimbursementData.merchant_name}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter description!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Description"
                                    onChange={(e) => onHandleChange("description", e.target.value)}
                                    value={EmployeeReimbursementData.description}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="Upload File"
                            name="file"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter your Date (To)!',
                        //     },
                        // ]}
                        >
                            <p>Upload File</p>
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="input-file"
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                        console.log("selectedFile...", selectedFile);
                                        if (selectedFile && selectedFile.type === 'application/pdf') {
                                            onHandleChange("attach_file", selectedFile)
                                        } else {
                                            // Reset the file state and show an error message
                                            alert('Please upload a PDF file.');
                                        }
                                    }}
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <label htmlFor="file-upload" className="file-upload-label">

                                    </label>
                                    <p style={{ zIndex: 1, margin: "0px" }}>{EmployeeReimbursementData?.attach_file.name ? EmployeeReimbursementData?.attach_file.name : "Upload File"}</p>
                                </div>
                            </div>
                            {
                                (formSubmit && EmployeeReimbursementData.attach_file === "") && <p style={{ color: "red", fontSize: "12px", marginTop: "40px" }}>Please Upload File!</p>
                            }
                        </Form.Item>
                    </Col>
                    <Button htmlType="submit"
                        style={{ width: "200px", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                        onClick={() => setFormSubmit(true)}
                    >
                        Submit
                    </Button>
                </Form>


            </Modal>
        </div>
    )
}

export default ReimbursementFullView