import React, { useEffect, useState } from 'react';
import desktopStyle from "./Desktop.module.scss"
import { Button, Col, DatePicker, Form, Modal, Row, Spin, Switch, Table } from 'antd';
import edit from "../../../../assets/edit.svg";
import request from '../../../../utils/axios';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import getScreenSize from "../../../../utils/getScreenSize";
import mobileStyle from "./Mobile.module.scss";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

function TaxSetting({ tabsValue }) {
    const screenSize = getScreenSize();
    const taxSettingsColumn = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'From Date',
            dataIndex: 'from_date',
            key: 'from_date',
        },
        {
            title: 'To Date',
            dataIndex: 'to_date',
            key: 'to_date',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <img src={edit} alt="edit"
                        onClick={() => {
                            setEditTaxSetting(true)
                            setTaxSettingId(record.id)
                            if (record.type === "Regime Window") {
                                setRegimeModel(true)

                                setRegimeWindowFormState({
                                    ...regimeWindowFormState,
                                    from_date: dayjs(record.from_date),
                                    end_date: dayjs(record.to_date),
                                })
                            }
                            else {
                                setTaxDeclarationWindowModel(true)
                                setTaxDeclarationWindowFormState({
                                    ...taxDeclarationWindowFormState,
                                    from_date: dayjs(record.from_date),
                                    end_date: dayjs(record.to_date),
                                })
                            }
                        }}
                    />
                </>
        },
    ]

    const [texDeclarationWindowLoader, setTaxDeclarationWindowLoader] = useState(false)
    const [regimeWindowLoader, setRegimeWindowLoader] = useState(false)
    const [taxSettingData, setTaxSettingData] = useState()
    const [regimeModel, setRegimeModel] = useState(false);
    const [taxDeclarationWindowModel, setTaxDeclarationWindowModel] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const [editTaxSetting, setEditTaxSetting] = useState(false)
    const [taxSettingId, setTaxSettingId] = useState(null)

    const fetchTaxSettingData = async () => {
        try {
            const response = await request({
                method: "get",
                url: `regim_tax_rules/admin/get-regime-window-open-history?page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            setTaxSettingData(response.pagination_data?.data)
            setPagination({
                ...pagination,
                total: response.pagination_data?.total,
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const [taxSetingWindowStatusData, setTaxSettingWindowStatusData] = useState({
        regimeWindow: false,
        taxDeclarationWindow: false
    })

    const [regimeWindowFormState, setRegimeWindowFormState] = useState({
        from_date: "",
        end_date: "",
    })

    const [taxDeclarationWindowFormState, setTaxDeclarationWindowFormState] = useState({
        from_date: "",
        end_date: "",
    })

    const getTaxSettingWindowStatus = async () => {
        try {
            const response = await request({
                method: "get",
                url: `regim_tax_rules/admin/get-windows-status`,
            })

            setTaxSettingWindowStatusData({
                ...taxSetingWindowStatusData,
                regimeWindow: response.data.is_regime_window_open,
                taxDeclarationWindow: response.data.is_tax_declaration_window_open
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const [regimeWindowForm] = Form.useForm()


    const regimeWindow = async (values) => {
        try {
            setRegimeWindowLoader(true)
            if (editTaxSetting) {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/update-windows-status`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                        type: "Regime Window",
                        id: taxSettingId
                    }
                })
            }
            else {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/regim-selection-window-open`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                    }
                })
            }
            getTaxSettingWindowStatus();
            setRegimeWindowLoader(false);
            fetchTaxSettingData()
            setRegimeModel(false)
            setEditTaxSetting(false)
            regimeWindowForm.resetFields()
            toast.success("Regime declaration window updated succesfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Regime declaration window could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    }


    useEffect(() => {
        if (tabsValue === "11") {
            fetchTaxSettingData()
            getTaxSettingWindowStatus()
        }
    }, [tabsValue])

    const renderUI = (dataList) => {

        return dataList?.map((item, index) => {
            return (
                <div key={index}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>{item.type}</p>
                        <img src={edit} alt="" width={16} height={16} style={{ cursor: "pointer" }}
                            onClick={() => {
                                setEditTaxSetting(true)
                                if (item.type === "Regime Window") {
                                    setRegimeModel(true)
                                    setTaxSettingId(item.id)
                                    setRegimeWindowFormState({
                                        ...regimeWindowFormState,
                                        from_date: dayjs(item.from_date),
                                        end_date: dayjs(item.to_date),
                                    })
                                }
                                else {
                                    setTaxDeclarationWindowModel(true)
                                    setTaxDeclarationWindowFormState({
                                        ...taxDeclarationWindowFormState,
                                        from_date: dayjs(item.from_date),
                                        end_date: dayjs(item.to_date),
                                    })
                                }
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>From Date</p>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>{item.from_date}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>To Date</p>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>{item.to_date}</p>
                    </div>
                    {
                        dataList.length - 1 !== index
                        &&
                        <hr />
                    }
                </div>
            )
        })
    }

    const [taxDeclarationWindowForm] = Form.useForm()

    const taxDeclarationWindow = async (values) => {
        try {
            setTaxDeclarationWindowLoader(true)
            if (editTaxSetting) {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/update-windows-status`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                        type: "Tax Declaration Window",
                        id: taxSettingId
                    }
                })
            }
            else {
                await request({
                    method: "post",
                    url: `regim_tax_rules/admin/tax-declaration-window-open`,
                    data: {
                        from_date: dayjs(values.from_date).format('DD-MM-YYYY'),
                        end_date: dayjs(values.end_date).format('DD-MM-YYYY'),
                    }
                })
            }
            getTaxSettingWindowStatus();
            setTaxDeclarationWindowLoader(false)
            setTaxDeclarationWindowModel(false)
            setEditTaxSetting(false)
            taxDeclarationWindowForm.resetFields()
            toast.success("Tax declaration window updated sucessfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Tax declaration window could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    }


    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div>
                        <p style={{
                            fontSize: "24px", color: "#161616", fontWeight: 500, marginLeft: "12px"
                        }}>Tax Setting</p>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", padding: "0px 16px" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616", margin: "0px" }}>
                                    Regime Window
                                </p>
                                <div>
                                    {
                                        taxSetingWindowStatusData.regimeWindow
                                            ?
                                            <Switch
                                                // defaultChecked
                                                checked={true}
                                            />
                                            :
                                            <Switch
                                                // defaultChecked
                                                checked={regimeModel}
                                                onClick={() => {
                                                    setRegimeModel(!regimeModel)
                                                }}
                                            />

                                    }
                                </div>
                            </div>
                            <br />
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", padding: "0px 16px" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616", margin: "0px" }}>
                                    Tax Declaration Window
                                </p>
                                <div>
                                    {
                                        taxSetingWindowStatusData?.taxDeclarationWindow
                                            ?
                                            <Switch
                                                // defaultChecked
                                                checked={true}
                                            />
                                            :
                                            <Switch
                                                // defaultChecked
                                                checked={taxDeclarationWindowModel}
                                                onClick={() => {
                                                    console.log("taxDeclarationWindowModel", taxDeclarationWindowModel)
                                                    setTaxDeclarationWindowModel(!taxDeclarationWindowModel)
                                                }}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                        <InfiniteScrollComponent
                            apiEndpoint="regim_tax_rules/admin/get-regime-window-open-history"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </div>
                    :
                    <div className={desktopStyle.taxSetting}>
                        <div className={desktopStyle.subContainer}>
                            <div style={{ display: "flex" }}>
                                <p className={desktopStyle.heading}>Tax Settings</p>
                                <div style={{ display: "flex" }}>
                                    <div style={{ display: "flex", gap: "20px", padding: "10px 35px" }}>
                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#4A4A4A", margin: "0px" }}>
                                            Regime Window
                                        </p>
                                        <div>
                                            {
                                                taxSetingWindowStatusData.regimeWindow
                                                    ?
                                                    <Switch
                                                        // defaultChecked
                                                        checked={true}
                                                    />
                                                    :
                                                    <Switch
                                                        // defaultChecked
                                                        checked={regimeModel}
                                                        onClick={() => {
                                                            setRegimeModel(!regimeModel)
                                                        }}
                                                    />

                                            }

                                        </div>
                                    </div>
                                    <div style={{ display: "flex", gap: "20px", padding: "10px 35px" }}>
                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#4A4A4A", margin: "0px" }}>
                                            Tax Declaration Window
                                        </p>
                                        <div>
                                            {
                                                taxSetingWindowStatusData?.taxDeclarationWindow
                                                    ?
                                                    <Switch
                                                        // defaultChecked
                                                        checked={true}
                                                    />
                                                    :
                                                    <Switch
                                                        // defaultChecked
                                                        checked={taxDeclarationWindowModel}
                                                        onClick={() => {
                                                            console.log("taxDeclarationWindowModel", taxDeclarationWindowModel)
                                                            setTaxDeclarationWindowModel(!taxDeclarationWindowModel)
                                                        }}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CommonTable
                                columns={taxSettingsColumn}
                                data={taxSettingData}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                        </div>
                    </div>
            }

            {/* Tax Settings */}
            {/* Regime Declaration Window */}
            <Modal
                title="Regime Declaration Window"
                centered
                open={regimeModel}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => {
                    setRegimeModel(false)
                    setEditTaxSetting(false)
                }}
                width={800}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={regimeWindow}
                    form={regimeWindowForm}
                    fields={[
                        {
                            name: "from_date",
                            value: regimeWindowFormState.from_date,
                        },
                        {
                            name: "end_date",
                            value: regimeWindowFormState.end_date,
                        },
                    ]}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="From"
                                name="from_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter employee number!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="To"
                                name="end_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter amount!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        {
                            regimeWindowLoader
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                                :
                                "Submit"
                        }
                    </Button>
                </Form>
            </Modal>

            <Modal
                title="Tax Declaration Window"
                centered
                open={taxDeclarationWindowModel}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => {
                    setTaxDeclarationWindowModel(false)
                    setEditTaxSetting(false)
                }}
                width={800}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={taxDeclarationWindow}
                    form={taxDeclarationWindowForm}
                    fields={[
                        {
                            name: "from_date",
                            value: taxDeclarationWindowFormState.from_date,
                        },
                        {
                            name: "end_date",
                            value: taxDeclarationWindowFormState.end_date,
                        },
                    ]}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="From"
                                name="from_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter employee number!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="To"
                                name="end_date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter amount!',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        {
                            texDeclarationWindowLoader
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                                :
                                "Submit"
                        }
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}

export default TaxSetting