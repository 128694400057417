import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../utils/axios'
import { RightOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom';
import { setPolicies, resetEmployeeDetails } from '../../../redux/reducer/EmployeeReducer';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { toast } from 'react-toastify';
import getScreenSize from "../../../utils/getScreenSize";

function Policies({ prev, next }) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const ref = useRef();
    const params = useParams();
    const screenSize = getScreenSize()
    const policies = useSelector((state) => state.employee.policies)
    const orgDetails = useSelector((state) => state.employee.org)

    const org = useSelector((state) => state.employee.org)
    let personalDetails = useSelector((state) => state.employee.personalDetails)
    let employmentDetails = useSelector((state) => state.employee.employmentDetails)

    const [attendancePolicy, setAttendancePolicy] = useState([])

    const getAttendancePolicy = async () => {
        let data = await request({
            method: "get",
            url: `admin/employee/attendance-policy/all?org=${orgDetails}`,
        })
        console.log("data:", data);
        setAttendancePolicy(data.data)
    }

    const [leavePolicy, setLeavePolicy] = useState([])

    const getLeavePolicy = async () => {
        let data = await request({
            method: "get",
            url: `admin/employee/leave-policy/all?org=${orgDetails}`,
        })
        setLeavePolicy(data.data)
    }

    const [salaryStructure, setSalaryStructure] = useState([])
    const getSalaryStructure = async () => {
        let data = await request({
            method: "get",
            url: `admin/employee/salary-structure/all?org=${orgDetails}`,
        })
        setSalaryStructure(data.data)
    }

    useEffect(() => {
        getLeavePolicy();
        getAttendancePolicy();
        getSalaryStructure();
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const submit = async (values) => {
        try {
            console.log("&&&&&&&&&&&&&&&&&&7", values)
            const dob = moment(personalDetails.dob).format('YYYY-MM-DD');
            personalDetails = { ...personalDetails, dob }

            if (personalDetails?.anniversary) {
                const anniversary = moment(personalDetails?.anniversary).format('YYYY-MM-DD');
                personalDetails = { ...personalDetails, anniversary }
            }
            const joining_date = moment(employmentDetails.joining_date).format('YYYY-MM-DD');
            employmentDetails = { ...employmentDetails, joining_date }

            if (window.location.pathname.split("/").includes("edit")) {
                await request({
                    method: "put",
                    url: `/employee/details/update/${params.id}`,
                    data: {
                        org,
                        ...personalDetails,
                        ...employmentDetails,
                        ...values,
                        "user_id": localStorage.getItem("user_id"),
                    }
                })
            }
            else {
                const data = await request({
                    method: "post",
                    url: '/create-employee',
                    data: {
                        org,
                        ...personalDetails,
                        ...employmentDetails,
                        ...values,
                        "user_id": localStorage.getItem("user_id"),
                    }
                })
            }


            dispatch(resetEmployeeDetails())
            navigate('/admin/employee', { replace: true });
        }
        catch (err) {
            console.log("err...", err)
            toast.error(err.response.data.message);
        }
    };

    const onFinish = async (values) => {
        dispatch(setPolicies(values));
        // submit(values);
        next();
    }

    useEffect(() => {
        console.log("attendancePolicy : ", attendancePolicy);
    }, [attendancePolicy])

    const [filteredPolicies, setFilteredPolicies] = useState(leavePolicy);

    const handlePolicySearch = (value) => {
        if (value) {
            const filteredList = leavePolicy.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredPolicies(filteredList);
        } else {
            setFilteredPolicies(leavePolicy);
        }
    };

    useEffect(() => {
        setFilteredPolicies(leavePolicy);
    }, [leavePolicy])

    const [filteredAttendancePolicies, setFilteredAttendancePolicies] = useState(attendancePolicy);

    const handleAttendancePolicySearch = (value) => {
        if (value) {
            const filteredList = attendancePolicy.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredAttendancePolicies(filteredList);
        } else {
            setFilteredAttendancePolicies(attendancePolicy);
        }
    };

    useEffect(() => {
        setFilteredAttendancePolicies(attendancePolicy);
    }, [attendancePolicy])

    const [filteredSalaryPolicies, setFilteredSalaryPolicies] = useState(salaryStructure);

    const handleSalaryPolicySearch = (value) => {
        if (value) {
            const filteredList = salaryStructure.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredSalaryPolicies(filteredList);
        } else {
            setFilteredSalaryPolicies(salaryStructure);
        }
    };

    useEffect(() => {
        setFilteredSalaryPolicies(salaryStructure);
    }, [salaryStructure])

    return (

        <div ref={ref}>
            {
                screenSize.width < 768 ?
                    // Mobile View 
                    <div className='employeeEmploymentDetailsContainer'>
                        <p className='heading'>Policies</p>
                        <hr />
                        <br />
                        <br />
                        <div>
                            <Form
                                // form={form}
                                layout="vertical"
                                initialValues={policies}
                                onFinish={onFinish}
                            // autoComplete="true"
                            >
                                <Row gutter={32}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Leave Policy"
                                            name="leave_policy"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Leave Policy!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                size='large'
                                            >
                                                {
                                                    leavePolicy?.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>

                                        </Form.Item>

                                        <p
                                            style={{ color: "#9570FF", marginTop: "0px", padding: "0px", fontWeight: "400", fontSize: "13px", cursor: "pointer" }}
                                            onClick={() => navigate('/admin/leave/leave-policy', { replace: true })}
                                        >
                                            View all Policies <RightOutlined style={{ fontSize: "10px" }} />
                                        </p>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Attendance Policy"
                                            name="work_policy"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select attendance_policytype!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                size='large'
                                            >
                                                {
                                                    attendancePolicy?.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>

                                        </Form.Item>
                                        <p
                                            style={{ color: "#9570FF", marginTop: "0px", padding: "0px", fontWeight: "400", fontSize: "13px", cursor: "pointer" }}
                                            onClick={() => navigate('/admin/attendance/attendance-policy', { replace: true })}
                                        >
                                            View all Policies <RightOutlined style={{ fontSize: "10px" }} />
                                        </p>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Salary Structure"
                                            name="salary_structure"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Salary Structure!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                // className='dropDown'
                                                size='large'
                                            >
                                                {
                                                    salaryStructure?.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.id}>{item.structure_name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>

                                        </Form.Item>
                                        <p
                                            style={{ color: "#9570FF", marginTop: "0px", padding: "0px", fontWeight: "400", fontSize: "13px", cursor: "pointer" }}
                                            onClick={() => navigate('/admin/payroll/salary-configuration-form', { replace: true })}
                                        >
                                            View all Policies <RightOutlined style={{ fontSize: "10px" }} />
                                        </p>
                                    </Col>
                                </Row>

                                <div className="buttonContainer">
                                    <Button className='button' onClick={() => prev()} >Previous</Button>
                                    <Button className='button' htmlType='submit' >Next</Button>
                                </div>
                            </Form>
                        </div>
                    </div>

                    :

                    // Desktop View
                    <div className='employeeEmploymentDetailsContainer'>

                        {/* <hr />
                        <br />
                        <br /> */}
                        <div>
                            <Form
                                // form={form}
                                layout="vertical"
                                initialValues={policies}
                                onFinish={onFinish}
                            // autoComplete="true"
                            >
                                <Row gutter={32}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Leave Policy"
                                            name="leave_policy"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Leave Policy!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                size='large'
                                                showSearch
                                                onSearch={handlePolicySearch}
                                                filterOption={false} // To prevent default filtering
                                                style={{ width: '100%' }}
                                                placeholder="Select Leave Policy"
                                            >
                                                <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Leave Policy</p></Select.Option>
                                                {filteredPolicies.map((item, index) => (
                                                    <Select.Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <p
                                            style={{ color: "#9570FF", marginTop: "-20px", padding: "0px", fontWeight: "400", fontSize: "13px", cursor: "pointer" }}
                                            onClick={() => navigate('/admin/leave/leave-policy', { replace: true })}
                                        >
                                            View all Policies <RightOutlined style={{ fontSize: "10px" }} />
                                        </p>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Attendance Policy"
                                            name="work_policy"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select attendance_policytype!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                size='large'
                                                showSearch
                                                onSearch={handleAttendancePolicySearch}
                                                filterOption={false} // To prevent default filtering
                                                style={{ width: '100%' }}
                                            >
                                                <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Attendance Policy</p></Select.Option>
                                                {filteredAttendancePolicies.map((item, index) => (
                                                    <Select.Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <p
                                            style={{ color: "#9570FF", marginTop: "-20px", padding: "0px", fontWeight: "400", fontSize: "13px", cursor: "pointer" }}
                                            onClick={() => navigate('/admin/attendance/attendance-policy', { replace: true })}
                                        >
                                            View all Policies <RightOutlined style={{ fontSize: "10px" }} />
                                        </p>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Salary Structure"
                                            name="salary_structure"
                                        >
                                            <Select
                                                size='large'
                                                showSearch
                                                onSearch={handleSalaryPolicySearch}
                                                filterOption={false} // To prevent default filtering
                                                style={{ width: '100%' }}
                                            >
                                                <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Salary Structure Policy</p></Select.Option>
                                                {filteredSalaryPolicies.map((item, index) => (
                                                    <Select.Option key={index} value={item.id}>
                                                        {item.structure_name}
                                                    </Select.Option>
                                                ))}
                                            </Select>

                                        </Form.Item>
                                        <p
                                            style={{ color: "#9570FF", marginTop: "-20px", padding: "0px", fontWeight: "400", fontSize: "13px", cursor: "pointer" }}
                                            onClick={() => navigate('/admin/payroll/salary-configuration-form', { replace: true })}
                                        >
                                            View all Policies <RightOutlined style={{ fontSize: "10px" }} />
                                        </p>
                                    </Col>
                                </Row>

                                <div className="buttonContainer">
                                    <Button className='button' onClick={() => prev()} >Previous</Button>
                                    <Button className='button' htmlType='submit' >Next</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
            }

        </div>
    )
}

export default Policies