import React, { useEffect, useState } from "react";
import dashboardStyles from "../../Dashboard/Dashboard.module.scss";
import getScreenSize from "../../../../utils/getScreenSize";
import leave from "../../../../assets/Inbox/leave.png";
import documentation from "../../../../assets/Inbox/newDocumentSigning.png";
import resign from "../../../../assets/Inbox/resignation.png";
import attendance from "../../../../assets/Inbox/attendance.png";
import activeAttendance from "../../../../assets/activeInbox.svg";

import LeaveList from "../../Leave/LeaveList/LeaveList";
import Resignation from "../../Resignation/Resignation";
import { useNavigate, useParams } from "react-router-dom";
import desktopStyle from "./Desktop.module.scss";
import mobileStyles from "./Mobile.module.scss";
import { toast } from "react-toastify";
import { Tabs } from "antd";

import request from "../../../../utils/axios";
import training from "../../../../assets/Training-inactive.svg";
import InboxLmsList from "../../Lms/InboxLmsList";

function AttendanceDetail() {
  const { width } = getScreenSize();
  const params = useParams();

  const menu = [
    {
      label: "Leave",
      key: "leave",
      url: "/inbox/leave",
      image: leave,
      // component: <LeaveList />
    },
    {
      label: "New Documentation Signing",
      key: "documentation",
      url: "/inbox/documentation",
      image: documentation,
    },
    {
      label: "Resignation",
      key: "resign",
      url: "/inbox/resign",
      image: resign,
      // component: <Resignation />
    },
    {
      label: "Attendance",
      key: "attendance",
      url: "/inbox/attendance",
      image: attendance,
      activeImage: activeAttendance,
    },
    // {
    //     label: "Performance Management",
    //     key: "performance",
    //     url: "/inbox/performance",
    //     image: performance
    // },
    {
      label: "Training",
      key: "lms",
      url: "/inbox/lms",
      image: training,
      component: <InboxLmsList />,
    },
  ];

  const items = [
    {
      key: "leave",
      label: "Leave",
      // children: <LeaveList />,
    },
    {
      key: "documentation",
      label: "Documentation",
      children: <></>,
    },
    {
      key: "resign",
      label: "Resignation",
      children: <></>,
    },
    {
      key: "attendance",
      label: "Attendance",
      // children: <AttendanceList />,
    },
  ];

  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("attendance");
  const [attendanceDetail, setAttendanceDetail] = useState(null);

  const fetchAttendanceDetail = async () => {
    try {
      if (params?.type === "Excemption") {
        const response = await request({
          method: "get",
          url: `attendance/exemption/details?id=${params?.id}`,
        });
        setAttendanceDetail(response.data);
      }
      if (params?.type === "Regularization") {
        const response = await request({
          method: "get",
          url: `attendance/regularization/details?id=${params?.id}`,
        });
        setAttendanceDetail(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectOrApprove = async (id, isApproved) => {
    try {
      if (params?.type === "Excemption") {
        await request({
          method: "post",
          url: `attendance/exemption/action?id=${id}`,
          data: {
            is_approved: isApproved,
          },
        });
      }

      if (params?.type === "Regularization") {
        await request({
          method: "post",
          url: `attendance/regularization/action?id=${id}`,
          data: {
            is_approved: isApproved,
          },
        });
      }

      // setTimeout(() => {
      //   // setIsListView(true);
      //   window.location.reload();
      // }, 1000);

      if (isApproved)
        toast.success("Excemption Approved Successfully", {
          position: "bottom-left",
        });
      else {
        toast.success("Excemption Rejected Successfully", {
          position: "bottom-left",
        });
      }

      navigate("/inbox/attendance");
    } catch (error) {
      console.log(error);
      if (isApproved) {
        toast.error(
          "Excemption could not be approved, please try after some time",
          {
            position: "bottom-left",
          }
        );
      } else {
        toast.error(
          "Excemption could not be rejected, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  useEffect(() => {
    fetchAttendanceDetail();
  }, []);

  return (
    <div>
      {width < 768 ? (
        <>
          <div style={{ width: "100%" }}>
            <Tabs
              activeKey={selectedMenu}
              items={items}
              style={{ width: "100%" }}
              onChange={(value) => {
                setSelectedMenu(value);
                navigate(`/inbox/${value}`, { replace: true });
              }}
            />
            {/* <div className={mobileStyles.leaveDetailContainer}>

                                <div className={mobileStyles.userImageNameBox}>
                                    <img
                                        loading="lazy"
                                        srcSet={attendanceDetail?.profile_image}
                                        className={mobileStyles.userImage}
                                    />
                                    <div className={mobileStyles.userName}>{attendanceDetail?.employee_name}</div>
                                </div>
                                <div className={mobileStyles.leaveDetails}>
                                    <p>
                                        <span className={mobileStyles.hi}>Hi</span>{" "}
                                        <span className={mobileStyles.reportingManager}>{attendanceDetail?.reporting_manager},</span>
                                    </p>
                                    <p style={{
                                        color: "#565656",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        textAlign: "justify"
                                    }}>{attendanceDetail?.comment}  </p>


                                    <div className={mobileStyles.actions}>
                                        <button className={mobileStyles.approve}
                                            onClick={() => {
                                                rejectOrApprove(attendanceDetail.id, true);
                                            }}
                                        >Approve</button>
                                        <button className={mobileStyles.reject}
                                            onClick={() => {
                                                rejectOrApprove(attendanceDetail.id, false);
                                            }}
                                        >
                                            Reject</button>
                                    </div>
                                    <div className={mobileStyles.regards}>Regards</div>
                                    <div className={mobileStyles.hrTeam}>Team HR</div>
                                </div>
                            </div> */}

            <div className={mobileStyles.leaveDetailContainer}>
              <div className={mobileStyles.userImageNameBox}>
                <img
                  loading="lazy"
                  srcSet={
                    attendanceDetail?.profile_image
                      ? attendanceDetail?.profile_image
                      : `https://ui-avatars.com/api/?name=${attendanceDetail?.employee_name.charAt(
                          0
                        )}`
                  }
                  className={mobileStyles.userImage}
                />
                <div className={mobileStyles.userName}>
                  {attendanceDetail?.employee_name}
                </div>
              </div>
              <div className={mobileStyles.leaveDetails}>
                <p>
                  <span className={mobileStyles.hi}>Hi</span>{" "}
                  <span className={mobileStyles.reportingManager}>
                    {attendanceDetail?.reporting_manager},
                  </span>
                </p>
                <p className={mobileStyles.leaveInfo}>
                  {attendanceDetail?.employee_name} has requested approval for{" "}
                  {params?.type === "Regularization"
                    ? `a regularization request on ${attendanceDetail?.attendance_date}`
                    : "a clock-in exemption"}
                </p>
                <>
                  {params?.type === "Regularization" && (
                    <div>
                      <div className={mobileStyles.leaveDate}>
                        Regularisation Details
                      </div>

                      <div className={mobileStyles.leaveDateContainer}>
                        <p className={mobileStyles.leaveDates}>
                          Old Check In Time:
                        </p>
                        <p className={mobileStyles.leaveDatesValue}>
                          {attendanceDetail?.old_check_in_time}
                        </p>
                      </div>

                      <div className={mobileStyles.leaveDateContainer}>
                        <p className={mobileStyles.leaveDates}>
                          Old Check Out Time:
                        </p>
                        <p className={mobileStyles.leaveDatesValue}>
                          {attendanceDetail?.old_check_out_time}
                        </p>
                      </div>

                      <div className={mobileStyles.leaveDateContainer}>
                        <p className={mobileStyles.leaveDates}>
                          New Check In Time:
                        </p>
                        <p className={mobileStyles.leaveDatesValue}>
                          {attendanceDetail?.new_check_in_time}
                        </p>
                      </div>

                      <div className={mobileStyles.leaveDateContainer}>
                        <p className={mobileStyles.leaveDates}>
                          New Check Out Time:
                        </p>
                        <p className={mobileStyles.leaveDatesValue}>
                          {attendanceDetail?.new_check_out_time}
                        </p>
                      </div>
                    </div>
                  )}
                </>

                <div className={mobileStyles.leaveDates}>Details</div>
                <div className={mobileStyles.leaveDatesValue}>
                  {attendanceDetail?.comment}
                </div>

                <div className={mobileStyles.actions}>
                  <button
                    className={mobileStyles.approve}
                    onClick={() => {
                      rejectOrApprove(attendanceDetail.id, true);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className={mobileStyles.reject}
                    onClick={() => {
                      rejectOrApprove(attendanceDetail.id, false);
                    }}
                  >
                    Reject
                  </button>
                </div>
                <div className={mobileStyles.regards}>Regards</div>
                <div className={mobileStyles.hrTeam}>Team HR</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={dashboardStyles.dashboardContainer}
          style={{ padding: "20px" }}
        >
          <div className={dashboardStyles.dashboardInnerContainer}>
            <div>
              <p className={dashboardStyles.inboxHeading}>Inbox</p>
              {menu.map((item) => {
                return (
                  <div
                    className={
                      selectedMenu === item.key
                        ? dashboardStyles.selectedItemBox
                        : dashboardStyles.itemBox
                    }
                    onClick={() => navigate(item.url)}
                  >
                    <img src={item.image} alt={item.image} />
                    <p className={dashboardStyles.label}>{item.label}</p>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "100%" }}>
              <div className={desktopStyle.leaveDetailContainer}>
                <div className={desktopStyle.backBox} onClick={() => navigate("/inbox/attendance")}>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/05cfa04f1b309ee35d7f2d1c66a1927dcfeded1810a60d950eda8db082c37e71?"
                    className={desktopStyle.img}
                  />
                  <div
                    className={desktopStyle.backText}
                  >
                    Back
                  </div>
                </div>
                <div className={desktopStyle.userImageNameBox}>
                  <img
                    loading="lazy"
                    src={
                      attendanceDetail?.profile_image
                        ? attendanceDetail?.profile_image
                        : `https://ui-avatars.com/api/?name=${attendanceDetail?.employee_name.charAt(
                            0
                          )}`
                    }
                    className={desktopStyle.userImage}
                  />
                  <div className={desktopStyle.userName}>
                    {attendanceDetail?.employee_name}
                  </div>
                </div>
                <div className={desktopStyle.leaveDetails}>
                  <div className={desktopStyle.leaveHeader}>
                    <span className={desktopStyle.leaveInfo}>Hi</span>{" "}
                    <span className={desktopStyle.recipient}>
                      {attendanceDetail?.reporting_manager},
                    </span>
                  </div>
                  <div className={desktopStyle.leaveInfo}>
                    {attendanceDetail?.employee_name} has requested approval for{" "}
                    {params?.type === "Regularization"
                      ? `a regularization request on ${attendanceDetail?.attendance_date}`
                      : "a clock-in exemption"}
                  </div>
                  <>
                    {params?.type === "Regularization" && (
                      <div>
                        <div className={desktopStyle.leaveDate}>
                          Regularisation Details
                        </div>
                        <div className={desktopStyle.leaveInfo}>
                          <div className={desktopStyle.leaveLabel}>
                            Old Check In Time:{" "}
                            {attendanceDetail?.old_check_in_time}
                          </div>

                          <div className={desktopStyle.leaveLabel}>
                            Old Check Out Time:{" "}
                            {attendanceDetail?.old_check_out_time}
                          </div>

                          <div className={desktopStyle.leaveLabel}>
                            New Check In Time:{" "}
                            {attendanceDetail?.new_check_in_time}
                          </div>

                          <div className={desktopStyle.leaveLabel}>
                            New Check Out Time:{" "}
                            {attendanceDetail?.new_check_out_time}
                          </div>
                        </div>
                      </div>
                    )}
                  </>

                  <div className={desktopStyle.leaveDate}>Comments</div>
                  <div
                    className={desktopStyle.leaveLabel}
                    style={{ marginTop: "10px" }}
                  >
                    {attendanceDetail?.comment}
                  </div>

                  <div className={desktopStyle.actions}>
                    <div
                      className={desktopStyle.approve}
                      onClick={() => {
                        rejectOrApprove(attendanceDetail.id, true);
                      }}
                    >
                      Approve
                    </div>
                    <div
                      className={desktopStyle.reject}
                      onClick={() => {
                        rejectOrApprove(attendanceDetail.id, false);
                      }}
                    >
                      Reject
                    </div>
                  </div>
                  <div className={desktopStyle.regards}>Regards</div>
                  <div className={desktopStyle.hrTeam}>Team HR</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AttendanceDetail;
