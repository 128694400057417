import React, { useEffect, useRef, useState } from 'react'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import { Button, Col, Form, Input, Modal, Row, Switch, Table, DatePicker } from 'antd'
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import request from '../../../../utils/axios';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import { toast } from 'react-toastify';
import getScreenSize from "../../../../utils/getScreenSize"
import add from '../../../../assets/Org/add.svg';
import moment from 'moment';
import styles from './HoldSalaryFullView.module.scss'
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function HoldSalaryFullView({ tabsValue }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const ref = useRef(null);

    const holdSalaryColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Employee Number',
            dataIndex: 'employee_number',
            key: 'employee_number',
        },
        {
            title: 'Hold Date',
            dataIndex: 'hold_date',
            key: 'hold_date',
        },
        {
            title: 'Hold status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Switch
                    size="small"
                    defaultChecked={record.status}
                    onChange={(checked) => handleToggleChange(record.id, checked)} // Call handleToggleChange function on change
                />
            )
        }
    ]
    const [EmployeeAdvanceSalaryData, setEmployeeAdvanceSalaryData] = useState({
        employement_number: "",
        name: "",
        amount: "",
        issue_date: "",
    })
    const [searchEmployeeList, setSearchEmployeeList] = useState([])

    const [holdSalaryModal, setHoldSalaryModal] = useState(false)
    const onHandleChangeForAdvance = (key, value) => {
        setEmployeeAdvanceSalaryData({
            ...EmployeeAdvanceSalaryData,
            [key]: value
        });
    }
    const [allEmployeeHoldSalarySummary, setAllEmployeeHoldSalarySummary] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const searchForHoldSalary = async (data) => {
        setHoldSalaryDetail({
            ...holdSalaryDetail,
            name: data
        });

        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleSelectChangeForHoldSalary = async (employement_number, name) => {
        try {
            setHoldSalaryDetail({
                ...holdSalaryDetail,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }


    const fetchemployeesHoldSalaryHistory = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/employees/salary-hold/all?page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            console.log(data)
            setAllEmployeeHoldSalarySummary([...data.pagination_data.data])
            setPagination({
                ...pagination,
                total: data?.pagination_data?.total
            })
            return data
        }
        catch (err) {
            console.log(err)
        }
    }

    const [holdSalaryDetail, setHoldSalaryDetail] = useState({
        employement_number: "",
        name: "",
    })

    const onFinishHoldSalary = async (values) => {
        console.log("HoldSalaryvalues", moment(values.issue_date).format("DD-MM-YYYY"))
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/employee/salary-hold/add`,
                data: {
                    date: moment(values.issue_date).format("YYYY-MM-DD"),
                    employement_number: holdSalaryDetail.employement_number,
                    name: holdSalaryDetail.name
                }
            })
            setHoldSalaryModal(false);
            fetchemployeesHoldSalaryHistory();
            toast.success("Hold salary details added successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
            toast.error("Hold salary details could not be added, please try after some time", {
                position: "bottom-left",
            })
        }

    }

    const handleToggleChange = async (id, is_checked) => {
        try {
            let data = await request({
                method: "post",
                url: `payroll/admin/salary-hold/update?id=${id}`,
                data: {
                    is_checked
                }
            })
            console.log(data)
            fetchemployeesHoldSalaryHistory();
            toast.success("Hold salary details updated successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
            toast.error("Hold salary details could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    useEffect(() => {
        if (tabsValue === "8") {
            fetchemployeesHoldSalaryHistory();
        }
    }, [pagination.current, tabsValue])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const renderUI = (dataList) => {

        return allEmployeeHoldSalarySummary?.map((item, index) => {
            return (
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 16px" }}>
                        <div >
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#161616",
                                margin: "0px",
                            }}>{item.employee_name}</p>
                        </div>
                        <div >
                            <Switch
                                defaultChecked={item.status}
                                onChange={(checked) => handleToggleChange(item.id, checked)} // Call handleToggleChange function on change
                            />
                        </div>
                    </div>
                    {
                        index < allEmployeeHoldSalarySummary.length - 1 && <hr />
                    }
                </div>
            )
        })
    }
    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    <div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 16px" }}>
                                <p
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "#161616",
                                        margin: "0px",
                                        // marginTop: "15px",
                                        // padding: "0px 16px"
                                    }}
                                >Hold Salary</p>
                                <img src={add} alt="add" width={30} onClick={() => setHoldSalaryModal(true)} />
                            </div>
                            <InfiniteScrollComponent
                                apiEndpoint={"payroll/admin/employees/salary-hold/all"}
                                renderUI={renderUI}
                                initialPage={1}
                            />
                            <div>
                                {

                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        {/* <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Payroll</p> */}
                        <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>

                            {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                                <img src={back} alt="back" />
                                <span style={{ paddingLeft: "10px" }}>Back</span>
                            </p> */}
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Hold Salary</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img src={add} alt="add" width={35} onClick={() => setHoldSalaryModal(true)} />
                                    {/* <img src={arrowCircle} alt="arrowCircle" /> */}
                                </div>
                            </div>

                            <CommonTable
                                columns={holdSalaryColumns}
                                data={allEmployeeHoldSalarySummary}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                            {/* <DynamicTable
                    fetchData={fetchemployeesHoldSalaryHistory}
                    columns={holdSalaryColumns}
                    handleRowClick={() => { }}
                    pageSize={5}
                    showPagination={true}
                /> */}
                        </div>


                    </div>
            }

            {/* Hold Salary */}
            <Modal
                title="Hold Salary"
                centered
                open={holdSalaryModal}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setHoldSalaryModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishHoldSalary} >
                    <Row gutter={[32, 12]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={holdSalaryDetail.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForHoldSalary(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForHoldSalary(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChangeForAdvance("date", dateString)}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>

            </Modal>
        </div>

    )
}

export default HoldSalaryFullView