import React, { useState, useEffect } from 'react';
import styles from './LeaveDetail.module.scss';
import request from '../../../../utils/axios';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import leave from "../../../../assets/Inbox/Activeleave.png";
// import performance from "../../../assets/Inbox/Increase.png";
import resign from "../../../../assets/Inbox/resignation.png";
import LeaveList from '../LeaveList/LeaveList';
import Resignation from '../../Resignation/Resignation';
import documentation from "../../../../assets/Inbox/newDocumentSigning.png";
import dashboardStyles from "../../Dashboard/Dashboard.module.scss"
import getScreenSize from "../../../../utils/getScreenSize";
import { Tabs } from 'antd';
import Documentation from '../../Documentation/Documentation';
import AttendanceList from '../../Attendance/AttendanceList/AttendanceList';
import attendance from "../../../../assets/Inbox/attendance.png";
import training from "../../../../assets/Training-inactive.svg";
import InboxLmsList from '../../Lms/InboxLmsList';


function LeaveDetail({ leaveId, isListView, setIsListView }) {
    const { width } = getScreenSize();

    const menu = [
        {
            label: "Leave",
            key: "leave",
            url: "/inbox/leave",
            image: leave,
            component: <LeaveList />
        },
        {
            label: "New Documentation Signing",
            key: "documentation",
            url: "/inbox/documentation",
            image: documentation,
            component: <Documentation />,
        },
        {
            label: "Resignation",
            key: "resign",
            url: "/inbox/resign",
            image: resign,
            component: <Resignation />,
        },
        {
            label: "Attendance",
            key: "attendance",
            url: "/inbox/attendance",
            image: attendance,
            component: <AttendanceList />,
        },
        // {
        //     label: "Performance Management",
        //     key: "performance",
        //     url: "/inbox/performance",
        //     image: performance
        // },
        {
            label: "Training",
            key: "lms",
            url: "/inbox/lms",
            image: training,
            component: <InboxLmsList />,
        },
    ];

    const items = [
        {
            key: 'leave',
            label: 'Leave',
            // children: <LeaveList />,
        },
        {
            key: 'documentation',
            label: 'Documentation',
            // children: <Documentation />,
        },
        {
            key: 'resign',
            label: 'Resignation',
            // children: <Resignation />,
        },
        {
            key: 'attendance',
            label: 'Attendance',
            // children: <AttendanceList />,
        }
    ];


    const navigate = useNavigate();
    const [leaveDetail, setLeaveDetail] = useState(null);
    const params = useParams();
    const [selectedMenu, setSelectedMenu] = useState("leave");

    useEffect(() => {
        if (params.id) {
            fetchLeaveDetail(params.id);
        }
    }, [params.id]);

    const fetchLeaveDetail = async (id) => {
        try {
            const data = await request({
                method: "get",
                url: `inbox/leave-application/details/?leave_id=${id}`,
            });

            setLeaveDetail(data?.data);
            // console.log(data.data);
        } catch (error) {
            console.log(error);
        }
    };
    const rejectOrApproveLeave = async (id, isRejected) => {
        try {
            await request({
                method: "post",
                url: `leave/my-team/leave_application/approval/?id=${id}`,
                data: {
                    is_rejected: isRejected
                }
            });

            setTimeout(() => {
                // setIsListView(true);
                window.location.reload();
            }, 1000);

            if (isRejected)
                toast.success("Leave Rejected", {
                    position: "bottom-left",
                });
            else {
                toast.success("Leave Approved", {
                    position: "bottom-left",
                });
            }

        } catch (error) {
            console.log(error);
            if (isRejected) {
                toast.error("Leave could not be rejected, please try after some time", {
                    position: "bottom-left",
                });
            } else {
                toast.error("Leave could not be approved, please try after some time", {
                    position: "bottom-left",
                });
            }

        }
    };
    // function LeaveDetail({ isListView, setIsListView }) {
    return (
        <>
            {
                width < 768
                    // mobile view
                    ?
                    <>
                        <div style={{ width: "100%" }}>
                            <Tabs activeKey={selectedMenu} items={items} style={{ width: "100%" }}
                                onChange={(value) => {
                                    setSelectedMenu(value);
                                    navigate(`/inbox/${value}`, { replace: true })
                                }}
                            />
                            <div className={styles.leaveDetailContainer}>

                                <div className={styles.userImageNameBox}>
                                    <img
                                        loading="lazy"
                                        srcSet={leaveDetail?.profile_image}
                                        className={styles.userImage}
                                    />
                                    <div className={styles.userName}>{leaveDetail?.employee_name}</div>
                                </div>
                                <div className={styles.leaveDetails}>
                                    <p>
                                        <span className={styles.hi}>Hi</span>{" "}
                                        <span className={styles.reportingManager}>{leaveDetail?.reporting_manager},</span>
                                    </p>
                                    <p className={styles.leaveInfo}>
                                        {leaveDetail?.employee_name} has requested for leave {leaveDetail?.leave_type} From {leaveDetail?.start_date}
                                        &nbsp;to {leaveDetail?.end_date}
                                    </p>
                                    <div className={styles.leaveDuration}>
                                        <p className={styles.leaveLabel}>No. of Leaves</p>
                                        <p className={styles.leaveValue}>{leaveDetail?.leave_day} Leaves</p>
                                    </div>
                                    <div className={styles.leaveDate}>Details</div>
                                    {leaveDetail?.leave_dates?.map((dateInfo, index) => (
                                        <div key={index} className={styles.leaveDateContainer}>
                                            <p key={index} className={styles.leaveDay}>
                                                {dateInfo.date}
                                            </p>
                                            <p className={styles.leaveType}>{dateInfo.leave_for === 'full_day' ? 'Full Day' : 'Half Day'}</p>
                                        </div>
                                    ))}
                                    <div className={styles.actions}>
                                        <button className={styles.approve}
                                            onClick={() => {
                                                rejectOrApproveLeave(leaveDetail.id, false);
                                            }}
                                        >Approve</button>
                                        <button className={styles.reject}
                                            onClick={() => {
                                                rejectOrApproveLeave(leaveDetail.id, true);
                                            }}
                                        >
                                            Reject</button>
                                    </div>
                                    <div className={styles.regards}>Regards</div>
                                    <div className={styles.hrTeam}>Team HR</div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className={dashboardStyles.dashboardContainer}>
                        <div className={dashboardStyles.dashboardInnerContainer}>
                            <div>
                                <p className={dashboardStyles.inboxHeading}>Inbox</p>
                                {
                                    menu?.map((item) => {
                                        return (
                                            <div
                                                className={selectedMenu === item.key ? dashboardStyles.selectedItemBox : dashboardStyles.itemBox}
                                                onClick={() => navigate(item.url)}
                                            >
                                                <img src={item.image} alt={item.image} />
                                                <p className={dashboardStyles.label}>{item.label}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ width: "100%" }}>
                                <div className={styles.leaveDetailContainer}>
                                    <div className={styles.backBox} onClick={() => navigate('/inbox/leave')}>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/05cfa04f1b309ee35d7f2d1c66a1927dcfeded1810a60d950eda8db082c37e71?"
                                            className={styles.img}
                                        />
                                        <div className={styles.backText}
                                        >Back</div>
                                    </div>
                                    <div className={styles.userImageNameBox}>
                                        <img
                                            loading="lazy"
                                            srcSet={leaveDetail?.profile_image}
                                            className={styles.userImage}
                                        />
                                        <div className={styles.userName}>{leaveDetail?.employee_name}</div>
                                    </div>
                                    <div className={styles.leaveDetails}>
                                        <div className={styles.leaveHeader}>
                                            <span className={styles.leaveInfo}>Hi</span>{" "}
                                            <span className={styles.recipient}>{leaveDetail?.reporting_manager},</span>
                                        </div>
                                        <div className={styles.leaveInfo}>
                                            {leaveDetail?.employee_name} has requested for leave {leaveDetail?.leave_type} From {leaveDetail?.start_date}
                                            &nbsp;to {leaveDetail?.end_date}
                                        </div>
                                        <div className={styles.leaveDuration}>
                                            <div className={styles.leaveLabel}>No. of Leaves</div>
                                            <div className={styles.leaveValue}>{leaveDetail?.leave_day}</div>
                                        </div>
                                        <div className={styles.leaveDate}>Details</div>
                                        {leaveDetail?.leave_dates?.map((dateInfo, index) => (
                                            <div key={index} className={styles.leaveDay}>
                                                {dateInfo.date},{' '}
                                                <span className={styles.leaveType}>{dateInfo.leave_for === 'full_day' ? 'Full Day' : 'Half Day'}</span>
                                            </div>
                                        ))}
                                        <div className={styles.actions}>
                                            <div className={styles.approve}
                                                onClick={() => {
                                                    rejectOrApproveLeave(leaveDetail.id, false);
                                                }}
                                            >Approve</div>
                                            <div className={styles.reject}
                                                onClick={() => {
                                                    rejectOrApproveLeave(leaveDetail.id, true);
                                                }}
                                            >
                                                Reject</div>
                                        </div>
                                        <div className={styles.regards}>Regards</div>
                                        <div className={styles.hrTeam}>Team HR</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>



    )
}

export default LeaveDetail