import React, { useEffect, useRef, useState } from "react";

import "./Dashboard.scss";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Popover,
  Progress,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import { CircularProgressbar } from "react-circular-progressbar";
import casualLeave from "../../../../assets/Leave/casualLeaves.svg";
import profileImg from "../../../../assets/Leave/profileImg.svg";
import {
  DownOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import fullView from "../../../../assets/fullView.svg";
import getScreenSize from "../../../../utils/getScreenSize";
import request from "../../../../utils/axios";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Apply from "../../../../assets/Leave/Apply.svg";
import casualleaves from "../../../../assets/casualleaves.svg";
import moment from "moment";
import MyTeamSearchBar from "../MyTeamSearchBar/MyTeamSearchBar";
import Error from "../../../../assets/Leave/Error.svg";
import arrowCircle from "../../../../assets/fullView.svg";
import searchImage from "../../../../assets/searchImage.svg";
import filter from "../../../../assets/filter.svg";
import filterCalendar from "../../../../assets/filterCalendar.svg";
import { toast } from "react-toastify";

function LeaveDashboardView({ changeTabsValue }) {
  const ref = useRef(null);
  const [applyLeaveLoader, setApplyLeaveLoader] = useState(false)
  const leaveDetailPopUp = (record) => {
    return (
      <div>
        <div style={{ display: "flex", gap: "10px" }}>
          {record.leave_dates.map((item, index) => {
            return (
              <>
                <span>
                  {item.leave_for === "full_day" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "white",
                        padding: "3px",
                        borderRadius: "50%",
                        background: "#008080",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : record.from_second_half_start_date ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to left, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  )}
                </span>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const myLeaveDetailColumns = [
    {
      title: "From",
      dataIndex: "start_date",
      key: "start_date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "To",
      dataIndex: "end_date",
      key: "end_date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "No. Of Leave",
      dataIndex: "leave_day",
      key: "leave_day",
      render: (text, record) => (
        <>
          <span>
            {text}{" "}
            <Popover
              placement="top"
              title="Leaves"
              content={leaveDetailPopUp(record)}
            >
              <InfoCircleOutlined />
            </Popover>
          </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // width: "200px",
      render: (text, record) => (
        <span>
          {moment(new Date()).format("YYYY-MM-DD") < record.start_date && record.is_pending && ( // Checking if current date is before end date
            <>
              <span
                onClick={() => {
                  setAppliedLeaveId(record.id);
                  setApplyLeaveModel(true); // Triggering edit action
                }}
                style={{ marginRight: "10px" }}
              >
                Edit
              </span>

              <span
                onClick={() => {
                  withdrawLeaveApplication(record.id); // Triggering withdraw action
                }}
              >
                Withdraw
              </span>
            </>)}

        </span>
      ),
    }
  ];

  const myTeamAppliedLeaveDetailsColumns = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "From",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "To",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "No. Of Leave",
      dataIndex: "leave_day",
      key: "leave_day",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{ background: "green", color: "white", border: "none" }}
              onClick={() => {
                rejectOrApproveLeave(record.id, false);
              }}
            >
              Approve
            </button>
            <button
              style={{ background: "red", color: "white", border: "none" }}
              onClick={() => {
                rejectOrApproveLeave(record.id, true);
              }}
            >
              Reject
            </button>
          </div>
        </>
      ),
    },
  ];
  const holidayColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: " Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Holiday",
      dataIndex: "name",
      key: "name",
    },
  ];

  const isAdmin = JSON.parse(localStorage.getItem("is_admin"));

  const { Option } = Select;
  const screenSize = getScreenSize();
  const navigate = useNavigate();
  const { TextArea } = Input;

  const semiProgress = () => {
    const rows = [];
    const itemsPerRow = 4;

    for (let i = 0; i < personalLeaveDetails.length; i += itemsPerRow) {
      const rowItems = personalLeaveDetails.slice(i, i + itemsPerRow);

      const row = (
        <div key={i} className="semiProgressContainer">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontWeight: "500", fontSize: "24px", color: "#161616" }}>My Leaves</p>

            <Button
              onClick={() => {
                setApplyLeaveModel(true);
                fetchPersonalLeaveLeftDetails();
              }}
              style={{
                background: "#008080",
                color: "white",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                height: "40px",
                marginBottom: "20px",
                // width: "100%",
              }}
            >
              Apply Leave
              <img src={Apply} alt="" />
            </Button>
          </div>
          <Row>
            {rowItems.map((item, index) => {
              const color = item.color;
              const icon = item.image;

              return (
                <Col
                  xs={12}
                  md={6}
                  key={index}
                  className="semiProgressCardContainer"
                >
                  <div className="semiProgressCard">
                    <CircularProgressbar
                      value={item.leave_taken_percentage}
                      circleRatio={0.5}
                      styles={{
                        root: {
                          transform: "rotate(0.75turn)",
                        },
                        path: { stroke: color },
                        trail: { stroke: "#EEEEEE" },
                      }}
                    />
                    <div className="leaveNameImgContainer">
                      <p className="leaveName">{item.leave_name}</p>
                      <img src={icon} alt="leaveType" className="leaveImg" />
                    </div>
                    <center>
                      <Row gutter={32} className="leaveNumberContainer">
                        <Col span={8}>
                          <p className="leaveNumberHeading">Availed</p>
                          <p className="leaveNumberText">{item.leave_taken}</p>
                        </Col>
                        <Col span={8}>
                          <p className="leaveNumberHeading">Annual</p>
                          <p className="leaveNumberText">{item.total_leave}</p>
                        </Col>
                        <Col span={8}>
                          <p className="leaveNumberHeading">Balance</p>
                          <p className="leaveNumberText">
                            {item.balance_leave}
                          </p>
                        </Col>
                      </Row>
                    </center>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      );

      rows.push(row);
    }

    return rows;
  };

  const myTeamLeaveDetails = () => {
    return (
      <div className="myTeamLeaveDetailsContainer">
        {myTeamEmployeeDetails.map((item) => {
          return (
            <Row className="employeeDetailsContainer">
              <Col xs={24} md={6} style={{ display: "flex" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      item.profile_image
                    }
                    alt="profileImg"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div>
                    <p className="employeeName">{item.employee_name}</p>
                    <p className="employeeDept">{item.designation}</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={18}>
                {item?.leves_details?.map((leavesItem) => {
                  return (
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col xs={8} md={4}>
                        <p className="employeeLeaveName">
                          {leavesItem.leave_name}
                        </p>
                      </Col>
                      <Col xs={15} md={19}>
                        <Progress
                          percent={leavesItem.leave_taken_percentage}
                          size={["95%", 20]}
                          strokeWidth={"10%"}
                          showInfo={false}
                          strokeColor={leavesItem.color}
                        />
                      </Col>
                      <Col xs={1} md={1}>
                        {leavesItem.leave_taken}/{leavesItem.total_leave}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const [applyLeaveModel, setApplyLeaveModel] = useState(false);

  const onFinish = async (values) => {
    try {
      const start_date = dayjs(values.start_date).format("YYYY-MM-DD");

      const end_date = dayjs(values.end_date).format("YYYY-MM-DD");

      // editLeaveDetails.start_date = start_date;
      // editLeaveDetails.end_date = end_date;

      const formData = new FormData();
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("leave_type", editLeaveDetails.leave_type);
      formData.append("leave_left", selectedLeaveTypeCount);
      formData.append("from_second_half_start_date", editLeaveDetails.from_second_half_start_date);
      formData.append("till_first_half_end_date", editLeaveDetails.till_first_half_end_date);
      formData.append("reason", editLeaveDetails.reason);
      if (editLeaveDetails.document) {
        formData.append("document", editLeaveDetails.document);
      }
      setApplyLeaveLoader(true);
      if (appliedLeaveId) {
        await request({
          method: "post",
          url: `/leave/leave/update/?id=${appliedLeaveId}`,
          // data: {
          //   ...editLeaveDetails,
          //   start_date,
          //   end_date,
          //   leave_left: selectedLeaveTypeCount,
          // },
          data: formData
        });
        setAppliedLeaveId(null);

        toast.success("Leave Application updated", {
          position: "bottom-left",
        });
      } else {
        await request({
          method: "post",
          url: `/leave/leave_application/create/`,
          // data: {
          //   ...editLeaveDetails,
          //   start_date,
          //   end_date,
          //   leave_left: selectedLeaveTypeCount,
          // },
          data: formData
        });
        setAppliedLeaveId(null);
        setApplyLeaveLoader(false);
        toast.success("Leave Application Submitted", {
          position: "bottom-left",
        });
      }

      setApplyLeaveModel(false);
      fetchPersonalLeaveDetails();
      fetchLeaveDetails();
      setEditLeaveDetails({
        leave_type: "",
        start_date: "",
        from_second_half_start_date: false,
        end_date: "",
        till_first_half_end_date: false,
        reason: "",
        document: null,
      });
    } catch (error) {
      console.log(error);
      toast.error("Leave Application could not be submitted", {
        position: "bottom-left",
      });
    }
  };

  const [personalLeaveDetails, setPersonalLeaveDetails] = useState([]);
  const fetchPersonalLeaveDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/employee/stats/`,
      });

      setPersonalLeaveDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [personalLeaveLeftDetails, setPersonalLeaveLeftDetails] = useState([]);
  const fetchPersonalLeaveLeftDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/employee/leave/stats/`,
      });

      setPersonalLeaveLeftDetails(data);
    } catch (error) {
      console.log(error);
    }
  };
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState([]);
  const [myTeamFilter, setMyTeamFilter] = useState(7);
  const [myTeamEmployeeDetails, setMyTeamEmployeeDetails] = useState([]);
  const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });
  const [open, setOpen] = useState(false)
  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };
  const fetchMyTeamEmployeeDetails = async () => {
    try {
      let data;
      if (myTeamFilter === "custom") {
        data = await request({
          method: "get",
          url: `leave/my-team/employee/stats/?page=1&page_size=2&start_date=${attendanceReportCustomDate?.start_date}&end_date=${attendanceReportCustomDate?.end_date}`,
        })

      } else {
        data = await request({
          method: "get",
          url: `leave/my-team/employee/stats/?page=1&page_size=2&days=${myTeamFilter}`,
        })
      }
      if (data && data?.pagination_data && data?.pagination_data?.data) {
        setMyTeamEmployeeDetails([...data.pagination_data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDaysChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      // setMyTeamFilter(value);
      setOpen(true);

    } else {
      // setShowDatePicker(false)f
      setMyTeamFilter(value);
      fetchMyTeamEmployeeDetails();
    }
  };

  const handleDateChange = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setDateRange(value);
    setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
  };

  useEffect(() => {
    fetchMyTeamEmployeeDetails();
  }, [myTeamFilter]);
  useEffect(() => {
    console.log("myTeamEmployeeDetails", myTeamEmployeeDetails);
  }, [myTeamEmployeeDetails]);

  console.log("myTeamEmployeeDetails", myTeamEmployeeDetails);

  const [myTeamAppliedLeaveDetails, setMyTeamAppliedLeaveDetails] = useState(
    []
  );
  const fetchMyTeamAppliedLeaveDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/my-team/leave_application/all/?page=1&page_size=3`,
      });
      if (data && data?.pagination_data && data?.pagination_data?.data) {
        setMyTeamAppliedLeaveDetails(data?.pagination_data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("myTeamAppliedLeaveDetails", myTeamAppliedLeaveDetails);

  const [holidayData, setHolidayData] = useState([]);
  const [holidayDataMobile, setHolidayDataMobile] = useState([]);

  const fetchHolidayData = async () => {
    try {
      if (screenSize.width < 768) {
        const data = await request({
          method: "get",
          url: `/leave/m-web/holiday/list/all`,
        });

        if (data && data?.data) {
          setHolidayDataMobile(data?.data);
        }
      }
      else {
        const data = await request({
          method: "get",
          url: `/leave/holiday/list/all`,
        });

        if (data && data?.data) {
          setHolidayData(data?.data);
        }
      }




    } catch (error) { }
  };

  useEffect(() => {
    console.log("changesing");
    setHolidayData([])
    fetchHolidayData();
  }, [screenSize.width]);

  const [reportingManager, setReportingManager] = useState("");
  const fetchReportingManager = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/get/reporting_manager/`,
      });

      setReportingManager(data?.reporting_manager);
    } catch (error) {
      console.log(error);
    }
  };

  const [myAppliedLeaveDetails, setMyAppliedLeaveDetails] = useState([]);
  const fetchLeaveDetails = async () => {
    const data = await request({
      method: "get",
      url: `leave/leave-application/all/details/?page=1&page_size=4`,
    });

    if (data && data?.pagination_data && data?.pagination_data?.data) {
      setMyAppliedLeaveDetails([...data?.pagination_data?.data]);
    }
  };

  const [appliedLeaveId, setAppliedLeaveId] = useState(null);
  const [editLeaveDetails, setEditLeaveDetails] = useState({
    leave_type: "",
    start_date: "",
    from_second_half_start_date: false,
    end_date: "",
    till_first_half_end_date: false,
    reason: "",
    document: null,
  });

  const [selectedLeaveTypeCount, setSelectedLeaveTypeCount] = useState(null);

  const fetchAppliedLeaveDetails = async () => {
    let data = await request({
      method: "get",
      url: `leave/leave-application/details/?id=${appliedLeaveId}`,
    });

    // setFromSecondHalfStartDate(data.from_second_half_start_date);
    // setTillFirstHalfEndDate(data.till_first_half_end_date)

    const start = new Date(data.start_date).toISOString();
    data = { ...data, start_date: dayjs(start) };

    const end = new Date(data.end_date).toISOString();
    data = { ...data, end_date: dayjs(end) };

    setEditLeaveDetails({
      leave_type: data.leave_type,
      start_date: data.start_date,
      from_second_half_start_date: data.from_second_half_start_date,
      end_date: data.end_date,
      till_first_half_end_date: data.till_first_half_end_date,
      reason: data.reason,
    });
  };

  const handleFormChange = (key, value) => {
    setEditLeaveDetails({
      ...editLeaveDetails,
      [key]: value
    });
  };

  useEffect(() => {
    console.log("editLeaveDetails", editLeaveDetails)
  }, [editLeaveDetails])

  const rejectOrApproveLeave = async (id, isRejected) => {
    try {
      await request({
        method: "post",
        url: `leave/my-team/leave_application/approval/?id=${id}`,
        data: {
          is_rejected: isRejected,
        },
      });

      setTimeout(() => {
        fetchMyTeamAppliedLeaveDetails();
      }, 1000);
      if (isRejected)
        toast.success("Leave Rejected", {
          position: "bottom-left",
        });
      else {
        toast.success("Leave Approved", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
      if (isRejected) {
        toast.error("Leave could not be rejected, please try after some time", {
          position: "bottom-left",
        });
      } else {
        toast.error("Leave could not be approved, please try after some time", {
          position: "bottom-left",
        });
      }
    }
  };

  const withdrawLeaveApplication = async (id) => {
    try {
      await request({
        method: "post",
        url: `leave/employee/leave-application/withdraw?leave_application_id=${id}`,
      });
      fetchPersonalLeaveDetails();
      fetchLeaveDetails();
      fetchPersonalLeaveLeftDetails()
    }
    catch (error) {

    }
  }

  useEffect(() => {
    fetchPersonalLeaveDetails();
    if (isAdmin) {
      fetchMyTeamEmployeeDetails();
      fetchMyTeamAppliedLeaveDetails();
    }
    fetchHolidayData();
    fetchReportingManager();
    fetchLeaveDetails();
    fetchPersonalLeaveLeftDetails();
  }, []);

  useEffect(() => {
    if (appliedLeaveId) {
      fetchAppliedLeaveDetails();
    }
  }, [appliedLeaveId]);

  const [leaveRule, setLeaveRule] = useState({
    back_dated: "",
    doc_requried: "",
    leave_type_eligible: "",
  });
  const [leaveRuleByDate, setLeaveRuleByDate] = useState({
    back_dated: "",
    doc_requried: "",
    leave_type_eligible: "",
  });
  const checkLeaveRule = async (leaveType) => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_type/check?leave_type_id=${leaveType}`,
      });

      //   console.log("data....", data);
      setLeaveRule(data);
    } catch (error) {
      console.log(error);
      setLeaveRule(error.response.data);
    }
  };
  useEffect(() => {
    if (editLeaveDetails.leave_type) {
      checkLeaveRule(editLeaveDetails.leave_type);
    }
  }, [editLeaveDetails.leave_type]);

  const checkRuleByDate = async () => {
    try {
      const data = await request({
        method: "post",
        url: `leave/leave/application/check?leave_type_id=${editLeaveDetails.leave_type}`,
        data: {
          start_date: dayjs(editLeaveDetails.start_date).format("YYYY-MM-DD"),
          end_date: dayjs(editLeaveDetails.end_date).format("YYYY-MM-DD"),
          from_second_half_start_date:
            editLeaveDetails.from_second_half_start_date,
          till_first_half_end_date: editLeaveDetails.till_first_half_end_date,
          leave_left: selectedLeaveTypeCount,
        },
      });

      setLeaveRuleByDate(data);
    } catch (error) {
      console.log(error);
      setLeaveRuleByDate(error.response.data);

    }
  };
  useEffect(() => {
    if (editLeaveDetails.start_date && editLeaveDetails.end_date) {
      checkRuleByDate();
    }
  }, [
    editLeaveDetails.start_date,
    editLeaveDetails.end_date,
    editLeaveDetails.from_second_half_start_date,
    editLeaveDetails.till_first_half_end_date,
  ]);

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    console.log("myTeamFilter...", myTeamFilter);
  }, [myTeamFilter])

  return (
    <div className="leaveDashboardContainer" ref={ref}>
      {screenSize.width < 768 ? (
        <>
          <div style={{ padding: "0px 16px" }}>

            <button
              onClick={() => {
                // setApplyLeaveModel(true);
                changeTabsValue("5");
              }}
              className="button"
            >
              Apply Leave
            </button>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {personalLeaveDetails.map((item, index) => (
                <div
                  key={index}
                  style={{ width: "calc(50% - 10px)", marginBottom: "20px" }}
                >
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      height: "80px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h2 className="cardHeading">
                        {item.leave_taken}/{item?.total_leave}
                      </h2>
                      <img
                        src={item.image}
                        alt="icon"
                        style={{ width: "25px", height: "25px" }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          background: item.color,
                          height: "8px",
                          width: "6%",
                          borderRadius: "100px",
                          marginTop: "8px",
                        }}
                      ></div>
                      <p className="subHeading">{item.leave_name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "10px",
            }}
          />

          <div>
            <div style={{ padding: "0px 16px" }}>
              {myAppliedLeaveDetails.length > 0 && (
                <div className="teamContainer">
                  <div className="teamText">Leave Requests</div>
                  <img
                    onClick={() => changeTabsValue("3")}
                    src={arrowCircle}
                    alt="arrow"
                    className="arrowImage"
                  />
                </div>
              )}
            </div>
            {myAppliedLeaveDetails.map((item, index) => {
              return (
                <div key={index}>
                  <div style={{ padding: "0px 16px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <p className="name">{item?.employee_name}</p>
                      <div
                        className="pendingContainer"
                        style={{
                          borderColor: item?.is_approved ? "#28B15F" : "red",
                        }}
                      >
                        <p
                          className="status"
                          style={{ color: item?.is_approved ? "#28B15F" : "red" }}
                        >
                          {item?.is_pending && "Pending"}
                          {item?.is_approved || item?.is_predefined_leaves
                            ? "Approved"
                            : ""}

                          {item?.is_rejected && "Rejected"}
                        </p>
                      </div>
                    </div>

                    <p className="leaveText">{item?.leave_type}</p>

                    <div>
                      <p className="date">
                        {moment(item?.start_date).format("DD-MM-YYYY")} To{" "}
                        {moment(item?.end_date).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <div className="leavesText">
                      {item?.leave_day} leaves
                      <Popover
                        placement="topRight"
                        title="Leaves"
                        content={leaveDetailPopUp(item)}
                      >
                        <DownOutlined />
                      </Popover>

                    </div>
                  </div>
                  {index !== myAppliedLeaveDetails?.length - 1 && (
                    <hr style={{
                      height: "1px",
                      backgroundColor: "#F0F0F0",
                      border: "none",
                    }} />
                    // <hr className="divider" />
                  )}
                </div>
              );
            })}
          </div>

          {
            myAppliedLeaveDetails.length > 0 &&
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "20px",
              }}
            />
          }


          <div className="myTeamContainerNew">
            <div className="myTeamTextNew">My Team</div>
            <div className="imageContainer">
              <img src={searchImage} alt="search" className="searchImage" />
              <img src={filter} alt="filter" className="filterImage" />
              <img
                onClick={() => changeTabsValue("2")}
                src={arrowCircle}
                alt="arrow"
                className="arrowImage"
              />
            </div>
          </div>
          {myTeamEmployeeDetails.map((item, index) => {
            return (
              <div>
                <div className="teamPersonContainer">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.profile_image}
                      alt="profileImg"
                      style={{ width: "60px", height: "60px" }}
                    />
                    <p className="teamPerson">{item?.employee_name}</p>
                  </div>

                  {item?.leves_details?.map((leaveItem, leaveIndex) => {
                    return (
                      <div className="myLeaveTextContainer">
                        <p className="myTeamLeaveText">{leaveItem?.leave_name}</p>
                        <p className="myLeaveValue">
                          {leaveItem?.leave_taken}/{leaveItem?.total_leave}
                        </p>
                      </div>
                    );
                  })}

                </div>
                {index !== myTeamEmployeeDetails?.length - 1 && (
                  <hr style={{
                    height: "1px",
                    backgroundColor: "#F0F0F0",
                    border: "none",
                  }} />
                  // <hr className="divider" />
                )}
              </div>
            );
          })}

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "0px 16px",
            }}
          >
            <p style={{ fontWeight: "500", fontSize: "24px" }}>Holidays</p>
            <img
              onClick={() => changeTabsValue("4")}
              src={arrowCircle}
              alt="arrow"
              className="employeeArrowImage"
            />
            {/* <div className="calendarContainer">
              <p className="calendarText">Calendar</p>
              <img
                src={filterCalendar}
                alt="filter"
                className="filterCalendarImage"
              />
            </div> */}
          </div>

          {Object.entries(holidayDataMobile)?.slice(0, 2).map(([month, holidays]) => (
            <div key={month} >
              <div style={{ padding: "0px 16px" }}>
                <div>
                  <p className="month">{month}</p>
                </div>
                {holidays.map((item) => (
                  <div key={item.id} className="hStack">
                    <div className="ocassionDateContainer">
                      <p className="ocassionDate">
                        {moment(item.date).format("DD")}
                      </p>
                    </div>
                    <div className="vStack">
                      <div>
                        <p className="occasion">{item.name}</p>
                      </div>
                      <div>
                        <p className="day">{item.day}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <hr className="divider" /> */}
              <hr style={{
                height: "1px",
                backgroundColor: "#F0F0F0",
                border: "none",
              }} />
            </div>
          ))}

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          <div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px", marginTop: "20px" }}>
              <p style={{ fontWeight: "500", fontSize: "24px", margin: "0px" }}>Team Leave Request</p>
              <img
                onClick={() => changeTabsValue("6")}
                src={arrowCircle}
                alt="arrow"
                className="employeeArrowImage"
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              {
                myTeamAppliedLeaveDetails?.map((item) => {
                  return (
                    <div>
                      <div style={{ padding: "0px 16px" }}>
                        <p style={{ fontSize: "18px", color: "#161616", fontWeight: "600" }}>{item?.employee_name}</p>
                        <p>{item?.start_date} - {item?.end_date}</p>
                        <p>{item?.leave_day} day</p>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "20px" }}>
                          <button
                            style={{ background: "green", color: "white", border: "none", width: "100%", height: "40px", borderRadius: "5px", fontWeight: "600" }}
                            onClick={() => {
                              rejectOrApproveLeave(item.id, false);
                            }}
                          >
                            Approve
                          </button>
                          <button
                            style={{ background: "red", color: "white", border: "none", width: "100%", height: "40px", borderRadius: "5px", fontWeight: "600" }}
                            onClick={() => {
                              rejectOrApproveLeave(item.id, true);
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="headerContainer">

          </div>
          <div className="myLeaveDetailContainer">{semiProgress()}</div>
        </>
      )}

      {myTeamEmployeeDetails.length > 0 && (
        <>
          {screenSize.width > 768 && (
            // mobile
            <div className="myTeamContainer">
              <div className="flexSpaceBetween">
                <div className="myTeamHeader">
                  <p className="myTeamText">My Team</p>
                  <MyTeamSearchBar
                    setTableDataForSearchEmployee={setMyTeamEmployeeDetails}
                    fetchTableDataFromApi={fetchMyTeamEmployeeDetails}
                    placeholderText="Search for employees"
                    empType="my_team"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    style={{ width: "150px", height: "40px" }}
                    placeholder="Select an option"
                    onChange={handleDaysChange}
                    dafaultValue={myTeamFilter}
                  // defaultValue={days ? days : selectedDateRange}
                  >
                    <Option value={7}>Last 7 days</Option>
                    <Option value={14}>Last 14 days</Option>
                    <Option value={30}>Last 30 days</Option>
                    <Option value="custom">
                      <Popover
                        title=""
                        content={
                          <div className="modal-content">
                            <RangePicker
                              value={dateRange}
                              onChange={handleDateChange}
                              format="YYYY-MM-DD"
                              disabledDate={(current) =>
                                current && current > moment().endOf("day")
                              }
                            />
                          </div>
                        }
                        getPopupContainer={getPopupContainer}
                        placement="right"
                      >
                        <p
                          style={{
                            cursor: "pointer",
                            width: "90%",
                            margin: "0px",
                          }}
                        >
                          Custom Date
                        </p>
                      </Popover>
                    </Option>
                  </Select>
                  <img
                    src={fullView}
                    alt="fullView"
                    width={35}
                    height={35}
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("2");
                    }}
                  />
                </div>
              </div>
              {myTeamLeaveDetails()}
            </div>
          )}
        </>
        // </div>
      )}

      {screenSize.width > 768 && (
        <>
          <div className="holidayContainer">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="holidayText">Leave Details</p>
              <img
                src={fullView}
                alt="fullView"
                width={35}
                height={35}
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  changeTabsValue("3");
                }}
              />
            </div>
            <Table
              columns={myLeaveDetailColumns}
              dataSource={myAppliedLeaveDetails ? myAppliedLeaveDetails : []}
              onRow={() => ({})}
              pagination={false}
            />
          </div>
          {myTeamEmployeeDetails.length > 0 && (
            <div className="holidayContainer">
              <p className="holidayText">My Team Leave Requests</p>
              <Table
                columns={myTeamAppliedLeaveDetailsColumns}
                dataSource={
                  myTeamAppliedLeaveDetails ? myTeamAppliedLeaveDetails : []
                }
                onRow={() => ({})}
                pagination={false}
              />
            </div>
          )}

          <div className="holidayContainer">
            <p className="holidayText">Holidays</p>
            <Table
              columns={holidayColumns}
              dataSource={holidayData}
              onRow={() => ({})}
              pagination={false}
            />
          </div>
        </>
      )}
      <div className="ok">
        {/* { */}
        <Modal
          title="Apply Leave"
          centered
          open={applyLeaveModel}
          // onOk={() => setOpen(false)}
          onCancel={() => {
            setApplyLeaveModel(false);
            setAppliedLeaveId(null);
            setEditLeaveDetails({
              leave_type: "",
              start_date: "",
              from_second_half_start_date: false,
              end_date: "",
              till_first_half_end_date: false,
              reason: "",
            });
            setLeaveRule({
              back_dated: "",
              doc_requried: "",
              leave_type_eligible: "",
            })
            setLeaveRuleByDate({
              back_dated: "",
              doc_requried: "",
              leave_type_eligible: "",
            })
          }}
          width={1200}
          footer={null}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            // onValuesChange={handleFormChange}
            initialValues={editLeaveDetails}
            fields={[
              {
                name: ["leave_type"],
                value: editLeaveDetails.leave_type,
              },
              {
                name: ["reporting_manager"],
                value: reportingManager,
              },
              {
                name: ["start_date"],
                value: editLeaveDetails.start_date,
              },
              {
                name: ["end_date"],
                value: editLeaveDetails.end_date,
              },
              {
                name: ["reason"],
                value: editLeaveDetails.reason,
              },
            ]}
          >
            <Row>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Leave Type"
                  name="leave_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select Leave Type!",
                    },
                  ]}
                >
                  <Select className="leaveInputField"
                    onChange={(value) => handleFormChange("leave_type", value)}
                  >
                    {personalLeaveLeftDetails?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.leave_id}>
                          <div
                            onClick={() =>
                              setSelectedLeaveTypeCount(item.balance_leave)
                            }
                          >
                            {item.leave_name} ( {item.balance_leave} leave left
                            )
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {/* <p style={{ color: "red" }}>{leaveRule?.message?.map((item, index) => {
                                    return (
                                        <p>{item}</p>
                                    )
                                })}</p> */}
                {leaveRule?.message?.length > 0 && (
                  <div
                    style={{
                      background: "#FDF3CF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <img src={Error} alt="" />
                      <p style={{ margin: "0px" }}>{leaveRule?.message[0]}</p>
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="reporting_manager" label="Approval Authority">
                  <Input className="leaveInputField" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (From)"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  {/* leaveRule.back_dated */}
                  <DatePicker
                    className="leaveInputField"
                    disabledDate={
                      !leaveRule.back_dated &&
                      function disabled(current) {
                        // Disable dates after today
                        // Disable dates before today
                        return current && current < moment().startOf("day");
                      }
                    }
                    disabled={editLeaveDetails.leave_type ? false : true}
                    onChange={(value) => handleFormChange("start_date", value)}
                  />
                </Form.Item>
                {/* <br /> */}
                <div
                  style={{
                    marginBottom: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Form.Item name="from_second_half_start_date">
                    {" "}
                    <Switch
                      checked={editLeaveDetails.from_second_half_start_date}
                      onChange={() =>
                        setEditLeaveDetails({
                          ...editLeaveDetails,
                          from_second_half_start_date:
                            !editLeaveDetails.from_second_half_start_date,
                        })
                      }
                    />
                    Second half day
                  </Form.Item>
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (To)"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (To)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leaveInputField"
                    disabled={editLeaveDetails.leave_type ? false : true}
                    // initialValues={employmentDetails?.joining_date ? dayjs(employmentDetails["joining_date"], "YYYY-MM-DD") : null}
                    onChange={(value) => handleFormChange("end_date", value)}
                  />
                </Form.Item>
                <div
                  style={{
                    marginBottom: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Form.Item
                    // label="Date (From)"
                    name="till_first_half_end_date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Date (From)!",
                      },
                    ]}
                  >
                    <Switch
                      checked={editLeaveDetails.till_first_half_end_date}
                      name="from_second_half_start_date"
                      onChange={() =>
                        setEditLeaveDetails({
                          ...editLeaveDetails,
                          till_first_half_end_date:
                            !editLeaveDetails.till_first_half_end_date,
                        })
                      }
                    />
                    First half day
                  </Form.Item>
                </div>
              </Col>
              {typeof leaveRuleByDate?.message === "array" && leaveRuleByDate?.message?.length > 0 && (
                <div
                  style={{
                    background: "#FDF3CF",
                    height: "40px",
                    borderRadius: "5px",
                    marginBottom: "10px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <img src={Error} alt="" />
                    <p style={{ margin: "0px" }}>
                      {leaveRuleByDate?.message[0]}
                    </p>
                  </div>
                </div>

              )}
              {leaveRuleByDate?.message && (
                <div
                  style={{
                    background: "#FDF3CF",
                    height: "40px",
                    borderRadius: "5px",
                    marginBottom: "20px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      padding: "10px",
                      height: "fit-content",
                    }}
                  >
                    <img src={Error} alt="" />
                    <p style={{ margin: "0px" }}>
                      {leaveRuleByDate?.message}
                    </p>
                  </div>
                </div>

              )}
            </Row>

            <Row gutter={[32, 32]}>
              {
                leaveRule?.doc_requried
                &&
                <Col span={22}>
                  <p>Upload File</p>
                  <Form.Item
                    label="Upload File"
                    name="document"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Date (To)!',
                      },
                    ]}
                  >
                    <div className="file-upload-container">
                      <input
                        type="file"
                        id="file-upload"
                        className="input-file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];

                          if (selectedFile && selectedFile.type === 'application/pdf') {
                            // if (selectedFile.size <= maxSizeInBytes) {
                            console.log('editLeaveDetails../../../../', selectedFile)
                            setEditLeaveDetails({
                              ...editLeaveDetails,
                              document: selectedFile
                            })
                          } else {
                            alert('Please upload a PDF file.');
                          }
                        }
                        }
                      />
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <label htmlFor="file-upload" className="file-upload-label">

                        </label>
                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                        <p style={{ zIndex: 1, margin: "0px" }}>{editLeaveDetails.document ? editLeaveDetails.document.name : 'Drop Your File Here'}</p>
                      </div>
                    </div>
                  </Form.Item>
                </Col>

              }

              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  label="Reason"
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: "Please add Reason!",
                    },
                  ]}
                >
                  <TextArea
                    rows={5}
                    placeholder="Enter Your Reason"
                    className="leaveTextArea"
                    onChange={(e) =>
                      handleFormChange("reason", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button htmlType="submit" className="applyLeave"
              style={{ width: "120px" }}
              disabled={
                editLeaveDetails.leave_type === "" || editLeaveDetails.start_date === "" || editLeaveDetails.end_date === "" || editLeaveDetails.reason === ""
                || ((typeof leaveRuleByDate?.message === "array" && leaveRuleByDate?.message?.length === 0) || leaveRuleByDate?.message) || (leaveRule?.message?.length)
              }
            >
              {
                applyLeaveLoader
                  ?
                  <Spin
                    indicator={
                      <LoadingOutlined spin style={{ color: 'white' }} />
                    }
                  />
                  :
                  <span>
                    {appliedLeaveId ? "Edit Leave" : "Apply Leave"}
                  </span>
              }
            </Button>
          </Form>
        </Modal>
        {/* }  */}
      </div>
    </div>
  );
}

export default LeaveDashboardView;

