import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import getScreenSize from "../../../../utils/getScreenSize";
import ContainerLayout from "../../../../component/ContainerLayout/ContainerLayout";

function LmsInstruction() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const screenSize = getScreenSize();

  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div
            style={{
              padding: "0px 16px",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                fontWeight: "500",
                color: "#161616",
                marginTop: "70px",
              }}
            >
              Instructions Before Starting the Training Module
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#8A8A8A",
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Aenean vulputate risus
              laoreet ornare neque id eu euismod. Leo urna sit nunc purus enim
              lectus porta. Pulvinar libero malesuada sit tincidunt morbi.
              Posuere. Lorem ipsum dolor sit amet consectetur.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#8A8A8A",
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Aenean vulputate risus
              laoreet ornare neque id eu euismod. Leo urna sit nunc purus enim
              lectus porta. Pulvinar libero malesuada sit tincidunt morbi.
              Posuere. Lorem ipsum dolor sit amet consectetur.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#8A8A8A",
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Aenean vulputate risus
              laoreet ornare neque id eu euismod. Leo urna sit nunc purus enim
              lectus porta. Pulvinar libero malesuada sit tincidunt morbi.
              Posuere. Lorem ipsum dolor sit amet consectetur.{" "}
            </p>

            <button
              style={{ width: "100%", height: "40px", border: "none", borderRadius: "5px" }}
              onClick={() => navigate(`/lms/video/${id}`)}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <ContainerLayout>
          <div>
            <div
              style={{
                padding: "30px",
                background: "white",
                borderRadius: "10px",
              }}
            >
              <p
                style={{ fontSize: "20px", fontWeight: "600", color: "#161616" }}
              >
                Instructions Before Starting the Training Module
              </p>
              <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>Welcome to your training module! Before you begin, please read the following instructions carefully:</p>
              <ol>
                <li>
                  <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                    <span style={{ fontWeight: "600", color: "#161616" }}>Quiet Environment : </span> Ensure you are in a quiet and comfortable environment where you can focus without interruptions.
                  </p>
                </li>
                <li>
                  <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                    <span style={{ fontWeight: "600", color: "#161616" }}> Full Attention :</span> This video contains important information that is crucial for your role. Please give it your full attention. Take notes if necessary.
                  </p>
                </li>
                <li>
                  <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                    <span style={{ fontWeight: "600", color: "#161616" }}>Technical Requirements : </span>
                    <ul>
                      <li>Ensure your device is connected to a stable internet connection to avoid buffering.</li>
                      <li>Use headphones if you're in a shared space to ensure you can hear the content clearly.</li>
                      <li>Make sure your video player is updated to avoid technical issues.</li>
                    </ul>
                  </p>
                </li>
                <li>
                  <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                    <span style={{ fontWeight: "600", color: "#161616" }}>  Completion : </span> The module is designed to be completed in one sitting. If you exit before finishing, you may need to start over from the beginning.
                  </p>
                </li>
                <li>
                  <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                    <span style={{ fontWeight: "600", color: "#161616" }}> Assessment : </span> At the end of the video, there may be an assessment to test your understanding. Make sure to pay close attention to all details in the video.
                  </p>
                </li>
                <li>
                  <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                    <span style={{ fontWeight: "600", color: "#161616" }}>  Support : </span> If you experience any technical difficulties or have questions during the module, please contact the HR support team
                  </p>
                </li>
              </ol>
              <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>Once you have read and understood these instructions, you may begin the training module by clicking the "Start Video" button below.
              </p>
              <p style={{ fontSize: "14px", color: "#393939", lineHeight: "30px" }}>
                Good luck!
              </p>
              <button style={{ background: "#008080", fontSize: "18px", color: "white", border: "none", borderRadius: "5px", width: "100px", height: "40px" }} onClick={() => navigate(`/lms/video/${id}`)}>Next</button>
            </div>

          </div>
        </ContainerLayout>
      )}
    </div>
  );
}

export default LmsInstruction;
