import React, {useEffect, useState} from 'react';
import request from '../../../utils/axios';
import './Search.scss';
import {Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons';

function Search({
  setTableDataForSearchEmployee,
  fetchTableDataFromApi,
  placeholderText,
  empType,
  setEmployeeId,
}) {
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);

  const handleSelectChange = async value => {
    console.log(value);
    try {
      setEmployeeId(value.employement_number);
      const response = await request({
        method: 'get',
        url: `tracking/admin/reporting-manager/meeting-details?employee_id=${value.employement_number}&page=1&page_size=10&from_date=&to_date=`,
      });
      console.log(response);
      setTableDataForSearchEmployee([...response?.data.pagination_data?.data]);
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  const search = async data => {
    try {
      const response = await request({
        method: 'get',
        url: `tracking/reporting-manager/employee/search?employee_name=${data}`,
      });

      setSearchEmployeeList(response);
      if (data === '') {
        fetchTableDataFromApi();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="search-container">
      <Input
        size="small"
        placeholder="Search for employees"
        prefix={<SearchOutlined />}
        allowClear
        onChange={e => search(e.target.value)}
        // Use style for inline width
      />

      {searchEmployeeList.length > 0 && (
        <ul>
          {searchEmployeeList.map((item, index) => (
            <li key={index} onClick={() => handleSelectChange(item)}>
              {item.employee_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Search;
