import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../component/CommonTable/CommonTable'
import getScreenSize from "../../../../utils/getScreenSize"
import info from "../../../../assets/info.png"
import { Button, DatePicker, Form, Input, Modal, Popover, Select } from 'antd';
import request from '../../../../utils/axios';
import dayjs from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContainerLayout from '../../../../component/ContainerLayout/ContainerLayout';
function OvertimeReport() {
    const screenSize = getScreenSize();
    const [overtimeForm] = Form.useForm();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })


    const [overtimeInfoModal, setOvertimeInfoModal] = useState(false);
    const [overtimeFormModal, setOvertimeFormModal] = useState(false)
    const [selectedOverTimeData, setSelectedOverTimeData] = useState(null)
    const [eligibleDays, setEligibleDays] = useState(0)
    const [numberOfDays, setNumberOfDays] = useState([])
    const fetchEligibleDays = async (empId) => {
        try {
            const response = await request({
                method: "get",
                url: `/attendance/admin/overtime-attendance/eligible-days?employment_number=${empId}`,
            });

            setEligibleDays(response?.eligible_days)
            const options = [];

            for (let i = 1; i <= response?.eligible_days; i++) {
                options.push(i);
            }
            setNumberOfDays(options)
        }
        catch (e) {
            console.log(e)
        }
    }

    const overtimeReportColumns = [
        {
            title: "Employee name",
            dataIndex: "employee_name",
            key: "employee_name"
        },
        {
            title: "Hours",
            dataIndex: "total_overtime_duration",
            key: "total_overtime_duration",
            render: (text, record) => <p>
                {text}
                <InfoCircleOutlined
                    style={{ color: "#008080", marginLeft: "10px" }}
                    onClick={() => {
                        setOvertimeInfoModal(true)
                        setSelectedOverTimeData(record)
                    }}
                />
            </p>
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <button
                style={{
                    border: "none",
                    background: "#008080",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "4px",
                    height: "28px",
                    padding: "0px 10px"
                }}
                onClick={() => {
                    fetchEligibleDays(record.employee)
                    setOvertimeFormModal(true)
                    setSelectedOverTimeData(record)
                }}
            >
                Comp Off
            </button>
        },
    ]

    const [leaveList, setLeaveList] = useState([]);
    const fetchLeaveList = async () => {
        try {
            const response = await request({
                method: "get",
                url: "/leave/admin/comp-off/leaves-types/all",
            })
            setLeaveList(response.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const [overtimeCalendar, setOvertimeCalendar] = useState({
        month: null,
        year: null
    });
    const fetchDataWithRespectedMonth = async (data) => {
        const [calendarYear, calendarMonth] = dayjs(data)
            .format("YYYY-MM")
            .split("-");

        setOvertimeCalendar({
            month: calendarMonth,
            year: calendarYear,
        })
        setPage(1);
        setItems([]);
    };

    const overtimeContent = (
        <div>
            <p>Content</p>
            <p>Content</p>
        </div>
    );

    const [overTimeReportList, setOverTimeReportList] = useState([]);

    const fetchOverTimeReportList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `/attendance/admin/overtime-attendance/all?page=${pagination.current}&page_size=${pagination.pageSize}&${overtimeCalendar.month && `month=${overtimeCalendar.month}`}&${overtimeCalendar.year && `year=${overtimeCalendar.year}`}`,
            })
            setPagination({
                ...pagination,
                total: response?.pagination_data?.count
            })
            setOverTimeReportList([...response.pagination_data.results])
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchOverTimeReportList();
    }, [pagination.current, overtimeCalendar.month, overtimeCalendar.year])

    useEffect(() => {
        fetchLeaveList();

        const today = new Date();
        setOvertimeCalendar({
            month: today.getMonth() + 1,
            year: today.getFullYear()
        })

    }, [])



    const onFinishOvertime = async (values) => {
        try {
            await request({
                method: "post",
                url: `/leave/employee/leave-type/add/`,
                data: {
                    employee: selectedOverTimeData?.employee,
                    leave_type: values?.leave_type,
                    leaves_number: values?.leaves_number,
                    month: +overtimeCalendar.month,
                    year: +overtimeCalendar.year
                }
            })
            overtimeForm.resetFields();
            setOvertimeFormModal(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const renderUI = (dataList) => {
        return dataList.map((item) => {
            return (
                <div>
                    <div style={{ padding: "0px 16px" }}>
                        <div className='flexSpaceBetween' style={{ marginBottom: "10px" }}>
                            <p style={{ fontSize: "18px", color: "#161616", fontWeight: "600", margin: "0" }}>{item?.employee_name}</p>
                            <button style={{ border: "none", background: "#008080", color: "white", borderRadius: "5px", padding: "0px 10px", height: "30px" }}
                                onClick={() => {
                                    fetchEligibleDays(item.employee)
                                    setOvertimeFormModal(true)
                                    setSelectedOverTimeData(item)
                                }}
                            >Comp off</button>
                        </div>
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "16px", color: "#4A4A4A", fontWeight: "400", margin: "0", marginBottom: "10px" }}>Over time</p>
                            <p style={{ fontSize: "16px", color: "#4A4A4A", fontWeight: "400", margin: "0", marginBottom: "10px" }}>{item?.total_overtime_duration}
                                {/* <Popover content={overtimeContent} title="Title" placement="bottomRight"> */}
                                <img src={info} alt="info" width={16} height={16} style={{ marginLeft: "5px" }}
                                    onClick={() => {
                                        setOvertimeInfoModal(true)
                                        setSelectedOverTimeData(item)
                                    }}
                                />
                                {/* </Popover> */}
                            </p>
                        </div>
                    </div>
                    <hr />
                </div>
            )
        })
    }

    const [dataLength, setDataLength] = useState(null);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchMoreData();
    }, [overtimeCalendar.month, overtimeCalendar.year]);

    const fetchMoreData = async () => {

        try {
            const res = await request({
                method: "get",
                url: `/attendance/admin/overtime-attendance/all?page=${page}&page_size=15&${overtimeCalendar.month && `month=${overtimeCalendar.month}`}&${overtimeCalendar.year && `year=${overtimeCalendar.year}`}`,
            })

            setItems([...items, ...res?.pagination_data?.results]);
            setDataLength(res?.pagination_data?.count);
            setPage(page + 1);
            if (res?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
        }
        catch (error) {
            console.error("Error fetching data: ", error);
            setHasMore(false);
        }
    };

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div style={{ background: "white" }}>

                        <p
                            style={{ fontSize: "20px", fontWeight: "600", padding: "0px 16px", margin: "0px", marginTop: "10px", marginBottom: "10px" }}
                        >Overtime Attendance</p>
                        <div style={{ padding: "0px 16px", marginBottom: "10px" }}>
                            <DatePicker
                                size="large"
                                picker="month"
                                style={{
                                    // border: "none",
                                    outline: "none",
                                    // background: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#000000",
                                }}
                                onChange={(e) => {
                                    fetchDataWithRespectedMonth(e);
                                }}
                                // can we set default value as month name
                                // defaultValue={moment().format("MMMM")}
                                defaultValue={dayjs().startOf('month')}
                                placeholder="Month"
                                format={"MMMM YYYY"}
                            />
                        </div>

                        <div
                            id="scrollableDiv"
                            style={{
                                height: "auto",
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column-reverse',
                            }}
                        >
                            <InfiniteScroll
                                dataLength={dataLength - (items.length)}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                loader={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Loading...</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv"
                                height="70vh"
                            >
                                {
                                    renderUI(items)
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                    :
                    <ContainerLayout>
                        <div style={{ padding: "30px", background: "white" }}>
                            <div className='flexSpaceBetween' style={{ marginBottom: "20px" }}>

                                <p
                                    style={{ fontSize: "20px", fontWeight: "600", margin: "0" }}
                                >Overtime Report</p>
                                <div>
                                    <DatePicker
                                        size="large"
                                        picker="month"
                                        style={{
                                            // border: "none",
                                            outline: "none",
                                            // background: "#FFFFFF",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: "#000000",
                                        }}
                                        onChange={(e) => {
                                            fetchDataWithRespectedMonth(e);
                                        }}
                                        // can we set default value as month name
                                        // defaultValue={moment().format("MMMM")}
                                        defaultValue={dayjs().startOf('month')}
                                        placeholder="Month"
                                        format={"MMMM YYYY"}
                                    />
                                </div>
                            </div>

                            <CommonTable
                                columns={overtimeReportColumns}
                                data={overTimeReportList}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                        </div>
                    </ContainerLayout>
            }

            <Modal
                centered
                open={overtimeInfoModal}
                onOk={() => setOvertimeInfoModal(false)}
                onCancel={() => setOvertimeInfoModal(false)}
                footer={null}
            >
                <p style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}>Overtime hour</p>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px", marginTop: "20px" }}>
                    <p style={{ fontSize: "16px", fontWeight: "600", color: "#008080" }}>S.no</p>
                    <p style={{ fontSize: "16px", fontWeight: "600", color: "#008080" }}>Date</p>
                    <p style={{ fontSize: "16px", fontWeight: "600", color: "#008080" }}>Overtime</p>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px", marginTop: "20px", height: "400px", overflowY: "auto" }}>
                    {
                        selectedOverTimeData?.overtime_details?.map((item, index) => {
                            return (
                                <>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>{index + 1}</p>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>{item.date}</p>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>{item.duration}</p>
                                </>
                            )
                        })
                    }
                </div>

            </Modal>

            <Modal
                centered
                open={overtimeFormModal}
                onOk={() => setOvertimeFormModal(false)}
                onCancel={() => setOvertimeFormModal(false)}
                footer={null}

            >
                <p style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}>Compensation off</p>
                <Form
                    onFinish={onFinishOvertime}
                    layout="vertical"
                    fields={[
                        {
                            name: "eligible",
                            value: eligibleDays,
                        }
                    ]}
                    form={overtimeForm}
                >
                    <p style={{ fontSize: "20px", fontWeight: "500" }}>Eligible days : {eligibleDays}</p>
                    {
                        eligibleDays !== 0
                        &&
                        <>
                            <Form.Item
                                label="Leave type"
                                name="leave_type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select leave type",
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                    style={{ width: "100%" }}
                                // onChange={(value) => setSelectedDate(value)}
                                >
                                    {
                                        leaveList?.map((item, index) => {
                                            return (
                                                <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Select number of days"
                                name="leaves_number"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select number of days",
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    size='large'
                                // onChange={(value) => setSelectedDate(value)}
                                >
                                    {
                                        numberOfDays?.map((date, index) => {
                                            return (
                                                <Select.Option value={date} key={index}>{date}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Button
                                style={{ color: "white", background: "#008080" }}
                                htmlType="submit"
                            >
                                Apply
                            </Button>
                        </>
                    }
                </Form>

            </Modal>
        </div>
    )
}

export default OvertimeReport