import React, { useState, useEffect } from "react";
// import styles from './DocumentationDetails.scss';
import request from "../../../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import leave from "../../../../assets/Inbox/leave.png";
// import performance from "../../../assets/Inbox/Increase.png";
import resign from "../../../../assets/Inbox/ActiveResignation.png";
import LeaveList from "../../../Inbox/Leave/LeaveList/LeaveList";
import Resignation from "../../Resignation/Resignation";
import documentation from "../../../../assets/Inbox/newDocumentSigning.png";
import dashboardStyles from "../../Dashboard/Dashboard.module.scss";
import getScreenSize from "../../../../utils/getScreenSize";
import { Col, Row, Tabs } from "antd";
import Documentation from "../../Documentation/Documentation";
import AttendanceList from "../../Attendance/AttendanceList/AttendanceList";
import InboxLmsList from "../../Lms/InboxLmsList";
import attendance from "../../../../assets/Inbox/attendance.png";
import training from "../../../../assets/Training-inactive.svg";

import desktopStyle from "./Desktop.module.scss";
import mobileStyles from "./Mobile.module.scss";

function ResignationDetail() {
  const styles = {};
  const { width } = getScreenSize();
  const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

  const menu = [
    {
      label: "Leave",
      key: "leave",
      url: "/inbox/leave",
      image: leave,
      component: <LeaveList />,
    },
    {
      label: "New Documentation Signing",
      key: "documentation",
      url: "/inbox/documentation",
      image: documentation,
      component: <Documentation />,
    },
    {
      label: "Resignation",
      key: "resign",
      url: "/inbox/resign",
      image: resign,
      component: <Resignation />,
    },
    {
      label: "Attendance",
      key: "attendance",
      url: "/inbox/attendance",
      image: attendance,
      component: <AttendanceList />,
    },
    // {
    //     label: "Performance Management",
    //     key: "performance",
    //     url: "/inbox/performance",
    //     image: performance
    // },
    {
      label: "Training",
      key: "lms",
      url: "/inbox/lms",
      image: training,
      component: <InboxLmsList />,
    },
  ];

  const items = [
    {
      key: "leave",
      label: "Leave",
      children: <LeaveList />,
    },
    {
      key: "documentation",
      label: "Documentation",
      children: <Documentation />,
    },
    {
      key: "resign",
      label: "Resignation",
      children: <Resignation />,
    },
    {
      key: "attendance",
      label: "Attendance",
      children: <AttendanceList />,
    },
    {
      key: "training",
      label: "Training",
      children: <InboxLmsList />,
    },
  ];

  const navigate = useNavigate();
  const [resignDetail, setResignDetail] = useState(null);
  const params = useParams();
  const [selectedMenu, setSelectedMenu] = React.useState("leave");

  useEffect(() => {
    let splitValue = window.location.href
      ?.split(frontend_base_url)[1]
      ?.split("/")[1];
    const companyName = splitValue === "app" ? "" : splitValue;

    const selectedMenu = window.location.href
      ?.split(frontend_base_url)[1]
      ?.split(`${companyName}/inbox/`)[1]
      ?.split("/")[0];

    setSelectedMenu(selectedMenu);
  }, [window.location.href]);

  useEffect(() => {
    if (params.id) {
      fetchResignationDetails(params.id);
    }
  }, [params.id]);

  const fetchResignationDetails = async (id) => {
    try {
      const data = await request({
        method: "get",
        url: `resignation/details/?id=${id}`,
      });
      console.log(
        "data............................................",
        data.data
      );
      setResignDetail(data?.data);
      // console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const rejectOrApprove = async (id, value) => {
    try {
      const data = await request({
        method: "post",
        url: `my-team/resignation/approval?sepration_id=${id}`,
        data: {
          is_approved: value,
        },
      });
      setTimeout(() => {
        fetchResignationDetails();
      }, 1000);
      if (value)
        toast.success("Approved Successfully", {
          position: "bottom-left",
        });
      else {
        toast.success("Rejected Successfully", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {width < 768 ? (
        <>
          <div style={{ width: "100%" }}>
            {/* <Tabs
              activeKey={selectedMenu}
              items={items}
              style={{ width: "100%" }}
              onChange={(value) => {
                setSelectedMenu(value);
                navigate(`/inbox/${value}`, { replace: true });
              }}
            /> */}
            <div className={mobileStyles.leaveDetailContainer}>
              <div className={mobileStyles.userImageNameBox}>
                <img
                  loading="lazy"
                  srcSet={
                    resignDetail?.profile_image
                      ? resignDetail?.profile_image
                      : `https://ui-avatars.com/api/?name=${resignDetail?.employee.charAt(
                          0
                        )}`
                  }
                  className={mobileStyles.userImage}
                />
                <div className={mobileStyles.userName}>
                  {resignDetail?.employee}
                </div>
              </div>
              <div className={mobileStyles.leaveDetails}>
                <p>
                  <span className={mobileStyles.hi}>Hi</span>{" "}
                  <span className={mobileStyles.reportingManager}>
                    {resignDetail?.reporting_manager},
                  </span>
                </p>
                <p className={mobileStyles.leaveInfo}>
                  {resignDetail?.employee} has initated a request for sepration.
                </p>

                <div>
                  <div className={mobileStyles.leaveDate}>Details</div>

                  <div className={mobileStyles.leaveDateContainer}>
                    <p className={mobileStyles.leaveDates}>
                      Last Working Days:
                    </p>
                    <p className={mobileStyles.leaveDatesValue}>
                      {/* {attendanceDetail?.old_check_in_time} */}
                    </p>
                  </div>

                  <div className={mobileStyles.leaveDateContainer}>
                    <p className={mobileStyles.leaveDates}>
                      Requested Last Working Date:
                    </p>
                    <p className={mobileStyles.leaveDatesValue}>
                      {/* {attendanceDetail?.old_check_out_time} */}
                    </p>
                  </div>

                  <div className={mobileStyles.leaveDateContainer}>
                    <p className={mobileStyles.leaveDates}>Sepration Date:</p>
                    <p className={mobileStyles.leaveDatesValue}>
                      {/* {attendanceDetail?.new_check_in_time} */}
                    </p>
                  </div>

                  <div className={mobileStyles.leaveDateContainer}>
                    <p className={mobileStyles.leaveDates}>Recovery Days:</p>
                    <p className={mobileStyles.leaveDatesValue}>
                      {/* {attendanceDetail?.new_check_out_time} */}
                    </p>
                  </div>
                </div>

                <div
                  className={mobileStyles.leaveDates}
                  style={{ marginTop: "20px" }}
                >
                  Reason
                </div>
                <div className={mobileStyles.leaveDatesValue}>
                  {resignDetail?.reason}
                </div>

                <div className={mobileStyles.actions}>
                  <button
                    className={mobileStyles.approve}
                    onClick={() => {
                      rejectOrApprove(resignDetail.id, true);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className={mobileStyles.reject}
                    onClick={() => {
                      rejectOrApprove(resignDetail.id, false);
                    }}
                  >
                    Reject
                  </button>
                </div>
                <div className={mobileStyles.regards}>Regards</div>
                <div className={mobileStyles.hrTeam}>Team HR</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={dashboardStyles.dashboardContainer}>
          <div className={dashboardStyles.dashboardInnerContainer}>
            <div>
              <p className={dashboardStyles.inboxHeading}>Inbox</p>
              {menu.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      selectedMenu === item.key
                        ? dashboardStyles.selectedItemBox
                        : dashboardStyles.itemBox
                    }
                    onClick={() => navigate(item.url)}
                  >
                    <img src={item.image} alt={item.image} />
                    <p className={dashboardStyles.label}>{item.label}</p>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "100%" }}>
              <div className={desktopStyle.leaveDetailContainer}>
                <div className={desktopStyle.backBox}  onClick={() => navigate("/inbox/attendance")}>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/05cfa04f1b309ee35d7f2d1c66a1927dcfeded1810a60d950eda8db082c37e71?"
                    className={desktopStyle.img}
                  />
                  <div
                    className={desktopStyle.backText}
                  >
                    Back
                  </div>
                </div>
                <div className={desktopStyle.userImageNameBox}>
                  <img
                    loading="lazy"
                    src={
                      resignDetail?.profile_image
                        ? resignDetail?.profile_image
                        : `https://ui-avatars.com/api/?name=${resignDetail?.employee.charAt(
                            0
                          )}`
                    }
                    className={desktopStyle.userImage}
                  />
                  <div className={desktopStyle.userName}>
                    {resignDetail?.employee}
                  </div>
                </div>
                <div className={desktopStyle.leaveDetails}>
                  <div className={desktopStyle.leaveHeader}>
                    <span className={desktopStyle.leaveInfo}>Hi</span>{" "}
                    <span className={desktopStyle.recipient}>
                      {resignDetail?.reporting_manager},
                    </span>
                  </div>
                  <div className={desktopStyle.leaveInfo}>
                    {resignDetail?.employee} has initated a request for
                    sepration.
                  </div>
                  <div>
                    <div className={desktopStyle.leaveDate}>Details</div>
                    <div
                      className={desktopStyle.leaveInfo}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className={desktopStyle.leaveLabel}>
                        Sepration Date: {resignDetail?.resignation_date}
                      </div>

                      <div className={desktopStyle.leaveLabel}>
                        Requested Last Working Date:{" "}
                        {resignDetail?.requested_date}
                      </div>

                      <div className={desktopStyle.leaveLabel}>
                        Actual Last Working Date:{" "}
                        {resignDetail?.last_working_date}
                      </div>

                      <div className={desktopStyle.leaveLabel}>
                        Recovery Days: {resignDetail?.recovery_days}
                      </div>
                    </div>
                  </div>

                  <div className={desktopStyle.leaveDate}>Reason</div>
                  <div
                    className={desktopStyle.leaveLabel}
                    style={{ marginTop: "10px" }}
                  >
                    {resignDetail?.reason}
                  </div>

                  <div className={desktopStyle.actions}>
                    <div
                      className={desktopStyle.approve}
                      onClick={() => {
                        rejectOrApprove(resignDetail.id, true);
                      }}
                    >
                      Approve
                    </div>
                    <div
                      className={desktopStyle.reject}
                      onClick={() => {
                        rejectOrApprove(resignDetail.id, false);
                      }}
                    >
                      Reject
                    </div>
                  </div>
                  <div className={desktopStyle.regards}>Regards</div>
                  <div className={desktopStyle.hrTeam}>Team HR</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResignationDetail;
