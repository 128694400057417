import React, {useRef, useEffect} from 'react';
import Location from '../Location/Location';
const TeamLocation = () => {
  const ref = useRef();
  useEffect(() => {
    ref?.current?.scrollIntoView({behavior: 'instant'});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return <Location />;
};

export default TeamLocation;
