import React, {useEffect, useState} from 'react';
import request from '../../../../utils/axios';
import moment from 'moment';
import getScreenSize from '../../../../utils/getScreenSize';
const MeetingOverview = ({month}) => {
  const screenSize = getScreenSize();
  const [SummaryData, setSummaryData] = useState({});
  console.log(month);
  useEffect(() => {
    LoadSummaryData();
  }, [month]);

  const LoadSummaryData = async () => {
    const MonthPayload = month.split('-');
    const apiUrl = `tracking/admin/employee-meeting/summary?month=${MonthPayload[1]}&year=${MonthPayload[0]}`;

    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      console.log(res.data);
      setSummaryData(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  return screenSize.width < 768 ? (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
      }}>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px',
        }}>
        <div style={{font: '500', fontSize: '24px', color: ' #161616'}}>
          {' '}
          {SummaryData.total_meeting}
        </div>
        <div style={{font: '400', fontSize: '16px', color: ' #161616'}}>
          Total Meeting
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px',
        }}>
        <div style={{font: '500', fontSize: '24px', color: ' #161616'}}>
          {' '}
          {SummaryData.attended_meeting}
        </div>
        <div style={{font: '400', fontSize: '16px', color: ' #161616'}}>
          Attended
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px',
        }}>
        <div style={{font: '500', fontSize: '24px', color: ' #161616'}}>
          {SummaryData.pending_meeting}
        </div>
        <div style={{font: '400', fontSize: '16px', color: ' #161616'}}>
          Pending
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '50px',
      }}>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px',
          width: '248px',
        }}>
        <div style={{font: '500', fontSize: '24px', color: ' #161616'}}>
          {' '}
          {SummaryData.total_meeting}
        </div>
        <div style={{font: '400', fontSize: '16px', color: ' #161616'}}>
          Total Meeting
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px',
          width: '248px',
        }}>
        <div style={{font: '500', fontSize: '24px', color: ' #161616'}}>
          {' '}
          {SummaryData.attended_meeting}
        </div>
        <div style={{font: '400', fontSize: '16px', color: ' #161616'}}>
          Attended
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px',
          width: '248px',
        }}>
        <div style={{font: '500', fontSize: '24px', color: ' #161616'}}>
          {SummaryData.pending_meeting}
        </div>
        <div style={{font: '400', fontSize: '16px', color: ' #161616'}}>
          Pending
        </div>
      </div>
    </div>
  );
};

export default MeetingOverview;
