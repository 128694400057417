import React, { useEffect, useRef, useState } from 'react'
import { Button, Radio, Table, Tabs } from "antd"
import "./Dashboard.scss"

import fullView from "../../../../assets/fullView.svg";
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import request from '../../../../utils/axios';
// import sheduling from "../../../assets/Recruitment/sheduling.svg";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ApplyJob from '../ApplyJob/ApplyJob';
// import useScreenSize from '../../../utils/getScreenSize';
import calendar from "../../../../assets/calendar.svg";
import location from "../../../../assets/location.svg";
import ApplyReferCardList from '../../../../component/ApplyReferCardList/ApplyReferCardList';
import AddRequisition from '../AddRequisition/AddRequisition';
import getScreenSize from "../../../../utils/getScreenSize";
import mobileStyle from "./Dashboard.module.scss";
import dayjs from 'dayjs';
import time from "../../../../assets/time.svg"
import rupee from "../../../../assets/activePayrollImg.svg"
import workMode from "../../../../assets/workMode.png"
import RecruitmentDashboardView from '../DashboardView/DashboardView';
import ReferralOpeningList from '../ReferralOpening/ReferralOpening';
import Requisition from '../Requisition/Requisition ';
import InternalJobOpening from '../InternalJobOpening/InternalJobOpening';
import InterviewFullView from '../InterviewScheduleFullView/InterviewFullView';
import ContainerLayout from "../../../../component/ContainerLayout/ContainerLayout"
function RecruitmentDashboard() {
  const screenSize = getScreenSize();
  const ref = useRef(null);
  const navigate = useNavigate();

  const changeTabsValue = (e) => {
    console.log("e", e);
    setTabsValue(e)
  }
  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <ContainerLayout>
        <RecruitmentDashboardView changeTabsValue={changeTabsValue} />
      </ContainerLayout>,
    },
    {
      key: '2',
      label: 'Referrals',
      children: <ContainerLayout>
        <ReferralOpeningList />
      </ContainerLayout>
    },
    {
      key: '5',
      label: 'Interview Schedule',
      children: <ContainerLayout>
        <InterviewFullView />
      </ContainerLayout>,
    },
    {
      key: '4',
      label: 'Internal Job postings',
      children: <ContainerLayout>
        <InternalJobOpening />
      </ContainerLayout>,
    },
    {
      key: '3',
      label: 'Requisitions',
      children: <ContainerLayout>
        <Requisition />
      </ContainerLayout>,
    },

  ];
  const [tabsValue, setTabsValue] = useState("1")

  const referralColumns = [
    {
      title: 'Date',
      dataIndex: 'applied_on',
      key: 'applied_on',
      render: (text, record) => <span>
        {
          moment(text).format('DD-MM-YYYY')
        }
      </span>
    },
    {
      title: 'Candidate Name',
      dataIndex: 'candidate_name',
      key: 'candidate_name',
    },
    {
      title: 'Job Title',
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record.location.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <button className={`${text}StatusButton`}>{text}</button>
      ),
    }
  ]

  const openingColumns = [
    {
      title: 'Date',
      dataIndex: 'posting_date',
      key: 'posting_date',
      render: (text, record) => <span>
        {
          moment(text).format('DD-MM-YYYY')
        }
      </span>
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record.location.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <ApplyJob title={"Refer a Candidate"} jobId={record.id} btnContent="Refer" loginUserData="" />
    }
  ]

  const schedulingColumns = [
    {
      title: 'Date',
      dataIndex: 'interview_date',
      key: 'interview_date',
    },
    {
      title: 'Candidate Name',
      dataIndex: 'candidate',
      key: 'candidate',
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },

    // {
    //   title: 'Scheduled By',
    //   dataIndex: 'scheduled_by',
    //   key: 'scheduled_by',
    // },
    {
      title: 'Timing',
      dataIndex: 'interview_time',
      key: 'interview_time',
    }
  ]

  const internalJobColumns = [
    {
      title: 'Date',
      dataIndex: 'posting_date',
      key: 'posting_date',
      render: (text, record) => <span>
        {
          moment(text).format('DD-MM-YYYY')
        }
      </span>
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Posted By',
      dataIndex: 'posted_by',
      key: 'posted_by',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Funtion',
      dataIndex: 'job_function',
      key: 'job_function',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record.location.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <ApplyJob title={"Apply"} jobId={record.id} btnContent="Apply" loginUserData={loginUserDetails} />
    }
  ]

  const requisitionColumns = [
    {
      title: 'Job Ttile',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Salary Range',
      dataIndex: 'salary_range',
      key: 'salary_range',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record.location.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Function',
      dataIndex: 'job_function',
      key: 'job_function',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <button className={`${text}StatusButton`}>{text}</button>
      ),
    }
  ]

  const [reload, setReload] = useState(false)

  const [view, setView] = useState("referral");
  const [internalJobOpening, setInternalJobOpening] = useState([])
  const fetchInternalJobOpening = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/active-jobs?page=${page}&page_size=${page_size}`,
    })

    console.log("internal", data.pagination_data.data)
    setInternalJobOpening([...data.pagination_data.data])

  }

  const [openingList, setOpeningList] = useState([])
  const fetchRowOpeningData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/referral-jobs?page=${page}&page_size=${page_size}`,
    })
    setOpeningList([...data.pagination_data.data])
    return data
  }

  const [interviewScheduleList, setInterviewScheduleList] = useState([])
  const fetchRowSchedulingData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/interviews/all?page=${page}&page_size=${page_size}`,
    })
    setInterviewScheduleList([...data.pagination_data.data])
    // return data
  }

  const [refferalList, setRefferalList] = useState([])

  const fetchRowMyReferralData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/employee/my-referrals?page=${page}&page_size=${page_size}`,
    })

    setRefferalList([...data.pagination_data.data])
    // return data
  }

  const [requisitionList, setRequisitionList] = useState([])
  const fetchRequisitionData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/employee/requisitions/all?page=${page}&page_size=${page_size}`,
    })
    setRequisitionList([...data.pagination_data.data])
    return data
  }

  const [loginUserDetails, setLoginUserDetails] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_contact: "",
    current_company: "",
    candidate_resume: "",
    linkedin_url: ""
  });
  const fetchUserData = async () => {
    const employee_number = localStorage.getItem("employee_number");

    let data = await request({
      method: "get",
      url: `profile/details/`,
    })

    setLoginUserDetails({
      ...loginUserDetails,
      candidate_name: data.data.data.employee_name,
      candidate_email: data.data.data.official_email,
      candidate_contact: data.data.data.contact,
      current_company: data.data.data.current_company,

    })
  }

  const [data, setData] = useState()
  const fetchDataForMobileView = async (page, page_size) => {

    if (view === "referral") {
      const data = await fetchRowMyReferralData(page, page_size);

    } else {
      const data = await await fetchRowOpeningData(page, page_size)
      setData(...data.pagination_data.data)
    }
  }

  useEffect(() => {


    fetchDataForMobileView(1, 4)
    fetchInternalJobOpening(1, 4);
    fetchRowSchedulingData(1, 4)
    fetchRequisitionData(1, 4)
  }, [])

  useEffect(() => {
    fetchDataForMobileView(1, 1)
  }, [view])



  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    fetchUserData();
  }, [])

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          ?
          <>
            <Tabs
              activeKey={tabsValue}
              items={items}
              onChange={changeTabsValue}
            />
          </>

          :
          <>
            <Tabs
              activeKey={tabsValue}
              items={items}
              onChange={changeTabsValue}
            />
          </>

      }
    </div>

  )
}

export default RecruitmentDashboard