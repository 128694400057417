import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios';
import "./EmployeeSearchBar.scss"
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

function EmployeeSearchBar({
    setEmployeeListTable, // set table data for search employee 
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
    selectedOrganisation,
    setHasMore
}) {
    const [employeeList, setEmployeeList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const handleSelectChange = async (employement_number, employee_name) => {
        try {
            setSearchValue(employee_name)
            const response = await request({
                method: "get",
                url: `search/employee/details/?employement_number=${employement_number}`,
            })

            console.log("response", response);

            setEmployeeListTable([response.data])
            setEmployeeList([])
            setHasMore(false)
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            setSearchValue(data)
            let response

            if (selectedOrganisation) {
                response = await request({
                    method: "get",
                    url: `leave/search/employees/?employee_name=${data}&&type=admin&&org=${selectedOrganisation}`,
                })
            } else {
                response = await request({
                    method: "get",
                    url: `leave/search/employees/?employee_name=${data}&&type=admin`,
                }
                )
            }

            setEmployeeList(response)
            if (data === "") {
                setHasMore(true)
                const newPagination = {
                    current: 1,
                    pageSize: 12,
                    total: 0,
                };
                const dataList = []
                fetchTableDataFromApi(newPagination, dataList)
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <Input
                type="text"
                placeholder="Search Employee Name"
                onChange={(e) => search(e.target.value)}
                size='small'
                value={searchValue}
                prefix={<SearchOutlined />}
                allowClear
            />
            {employeeList.length > 0 && (
                <ul>
                    {employeeList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.employement_number, item.employee_name)}>
                            {item.employee_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default EmployeeSearchBar