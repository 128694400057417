import React, { useEffect, useRef, useState } from "react";

import {
  Tabs,
} from "antd";
import getScreenSize from "../../../../utils/getScreenSize";
import LeaveDashboardView from "../Dashboard/Dashboard";
import MyTeamLeaveFullView from "../MyTeam/MyTeam";
import LeaveDetailsFullView from "../LeaveDetails/LeaveDetails";
import UserHolidayFullView from "../Holiday/UserHolidayFullView";
import ApplyLeave from "../ApplyLeave/ApplyLeave";
import TeamLeaveRequests from "../TeamLeaveRequests/TeamLeaveRequests";
import ContainerLayout from "../../../../component/ContainerLayout/ContainerLayout";
import request from "../../../../utils/axios";

function Dashboard() {
  const ref = useRef(null);
  const screenSize = getScreenSize();

  const changeTabsValue = (e) => {
    setTabsValue(e)
  }

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <ContainerLayout><LeaveDashboardView changeTabsValue={changeTabsValue} /></ContainerLayout>,
    },
    {
      key: '3',
      label: 'My Leave Details',
      children: <ContainerLayout><LeaveDetailsFullView /></ContainerLayout>,
    },
  ];

  const reportingManagerTabs = [
    {
      key: '1',
      label: 'Dashboard',
      children: <ContainerLayout><LeaveDashboardView changeTabsValue={changeTabsValue} /></ContainerLayout>,
    },
    {
      key: '2',
      label: 'My team Summary',
      children: <ContainerLayout><MyTeamLeaveFullView /></ContainerLayout>,
    },
    {
      key: '3',
      label: 'My Leave Details',
      children: <ContainerLayout><LeaveDetailsFullView /></ContainerLayout>,
    },
    {
      key: '4',
      label: 'Team Leave Requests',
      children: <ContainerLayout><TeamLeaveRequests /></ContainerLayout>,
    }
  ]

  const mweb_items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <ContainerLayout><LeaveDashboardView changeTabsValue={changeTabsValue} /></ContainerLayout>,
    },
    {
      key: '5',
      label: 'Apply Leave',
      children: <ContainerLayout><ApplyLeave changeTabsValue={changeTabsValue} /></ContainerLayout>,
    },
    {
      key: '3',
      label: 'Leave Requests',
      children: <ContainerLayout><LeaveDetailsFullView /></ContainerLayout>,
    },
    {
      key: '2',
      label: 'My team Summary',
      children: <ContainerLayout><MyTeamLeaveFullView /></ContainerLayout>,
    },
    {
      key: '4',
      label: 'Holiday List',
      children: <ContainerLayout><UserHolidayFullView /></ContainerLayout>,
    },
    {
      key: '6',
      label: 'Team Leave Requests',
      children: <ContainerLayout><TeamLeaveRequests /></ContainerLayout>,
    }
  ];

  const [tabsValue, setTabsValue] = useState("1")
  const [isReporting, setIsReporting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const myTeamDetail = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/widget/`,
    });

    setIsReporting(data.is_reporting);
    setIsLoading(false);
  };

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    myTeamDetail();
  }, []);

  return (
    <div className="" ref={ref}>
      {screenSize.width < 768 ? (
        <>
          <Tabs
            activeKey={tabsValue}
            items={mweb_items}
            onChange={changeTabsValue}
          />
        </>
      ) : (
        <>
          {
            isLoading
              ?
              <>
              </>
              :
              <Tabs
                activeKey={tabsValue}
                items={isReporting ? reportingManagerTabs : items}
                onChange={changeTabsValue}
              />
          }
        </>
      )}
    </div>
  );
}

export default Dashboard;