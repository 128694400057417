import { Col, Row, Table, Tabs, Modal, Form, Input, Button, DatePicker, Select } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import request from '../../../../utils/axios'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import { useNavigate } from 'react-router-dom';
import getScreenSize from '../../../../utils/getScreenSize';
import back from "../../../../assets/Recruitment/back.svg"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import { toast } from "react-toastify";
import add from "../../../../assets/Org/add.svg"
import styles from "./LoanFullView.module.scss"

function LoanFullView({ tabsValue }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();

    const emoloyeeLoanColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Issue Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
        {
            title: 'Tenure',
            dataIndex: 'tenure',
            key: 'tenure',
        },
        {
            title: 'Intrest Rate',
            dataIndex: 'interest',
            key: 'interest',
        }
    ]
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const [employeeLoan, setEmployeeLoan] = useState(false)

    const [allEmployeeLoanSummary, setAllEmployeeLoanSummary] = React.useState()

    const fetchemployeesLoanHistory = async () => {
        try {
            let data = await request({
                method: "get",
                url: `loan/admin/get-all-employee-loan-history?page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            console.log(data)
            setAllEmployeeLoanSummary([...data.pagination_data.data])
            setPagination({
                ...pagination,
                total: data?.pagination_data?.total
            })
            return data
        }
        catch (err) {
            console.log(err)
        }
    }
    const [searchEmployeeList, setSearchEmployeeList] = useState([])

    // #####################################################################
    // Loan
    // #####################################################################
    const handleSelectChangeForLoan = async (employement_number, name) => {
        try {
            setEmployeeLoanData({
                ...EmployeeLoanData,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const searchForLoan = async (data) => {
        onHandleChange('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }

    const [EmployeeLoanData, setEmployeeLoanData] = useState({
        employement_number: "",
        name: "",
        amount: "",
        tenure: "",
        issue_date: "",
        interest: ""
    })

    const onHandleChange = (key, value) => {
        setEmployeeLoanData({
            ...EmployeeLoanData,
            [key]: value
        });
    }
    const onFinishLoan = async (values) => {
        const formData = new FormData();
        try {
            formData.append("employement_number", EmployeeLoanData.employement_number);
            formData.append("name", EmployeeLoanData.name);
            formData.append("loan_amount", EmployeeLoanData.amount);
            formData.append("tenure", EmployeeLoanData.tenure);
            formData.append("issue_date", EmployeeLoanData.issue_date);
            formData.append('interest', EmployeeLoanData.interest)
            console.log("Form_data : ", formData)

            await request({
                method: "post",
                url: `loan/admin/create-employee-loan`,
                data: formData
            })
            // Close the modal after the request is successful
            setEmployeeLoan(false);
            fetchemployeesLoanHistory();
            toast.success("Loan details added successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log("error")
            toast.error("Loan details could not be added, please try after some time", {
                position: "bottom-left",
            })
        }

    }



    useEffect(() => {
        if (tabsValue === "7") {
            fetchemployeesLoanHistory();
        }
    }, [pagination.current, tabsValue])

    const ref = useRef(null);

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <div>
                    <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                        <Row>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Month</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>₹ {item.tenure}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Year</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.issue_date}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Loan Amount</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.amount}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Interest</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.interest}</p>
                            </Col>

                        </Row>
                    </div>
                    {
                        index !== allEmployeeLoanSummary.length - 1 && <hr />
                    }

                </div>
            )
        })
    }
    return (
        <>
            {
                screenSize.width < 768
                    ?
                    <div>

                        <div style={{ background: "white", borderRadius: "4px" }}>
                            <div className='flexSpaceBetween' style={{ padding: "0px 16px" }}>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Loan</p>

                            </div>
                            <InfiniteScrollComponent
                                apiEndpoint={"loan/admin/get-all-employee-loan-history"}
                                renderUI={renderUI}
                                initialPage={1}
                            />
                            <div>
                                {

                                }
                            </div>
                        </div>
                    </div>

                    :

                    <div>
                        {/* Loan */}
                        <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Loan</p>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <img src={add} alt="add" width={35} onClick={() => setEmployeeLoan(true)} />
                                    {/* <img src={arrowCircle} alt="arrowCircle" /> */}
                                </div>
                            </div>
                            <CommonTable
                                columns={emoloyeeLoanColumn}
                                data={allEmployeeLoanSummary}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                        </div>
                    </div>
            }
            {/* Employee Loan */}
            <Modal
                title="Loan"
                centered
                open={employeeLoan}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setEmployeeLoan(false)}
                width={1000}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={onFinishLoan}

                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={EmployeeLoanData.name}
                                        type="text"
                                        placeholder="Search and Select Employee Name"
                                        onChange={(e) => searchForLoan(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForLoan(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChange("amount", e.target.value)}
                                    value={EmployeeLoanData.amount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="issue_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChange("issue_date", dateString)}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Tenure(In Months)"
                                name="tenure"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tenure!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                    placeholder="Enter tenure"
                                    onChange={(e) => onHandleChange("tenure", e)}
                                    value={EmployeeLoanData.tenure}
                                >
                                    {
                                        [...Array(36).keys()].map((item, index) => {
                                            return (
                                                <Select.Option value={index + 1}>{index + 1}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>

                                {/* <Input
                                    size='large'
                                    placeholder="Enter tenure"
                                    onChange={(e) => onHandleChange("tenure", e.target.value)}
                                    value={EmployeeLoanData.tenure}
                                /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Interest(In %)"
                                name="interest"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter interest value!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter interest"
                                    onChange={(e) => onHandleChange("interest", e.target.value)}
                                    value={EmployeeLoanData.interest}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>


        </>
    )
}

export default LoanFullView