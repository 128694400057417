import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, DatePicker, Space, Modal, Select, Spin, Input } from "antd";
import CalenderIcon from "../../../../../assets/tracking/calendericon.svg";
import LocationIcon from "../../../../../assets/tracking/LocationIcomn.svg";
import request from "../../../../../utils/axios";
import SearchBar from "../../../SearchBar/Search";
import { useNavigate } from "react-router-dom";
import Add from "../../../../../assets/tracking/add.svg";
import fullView from "../../../../../assets/fullView.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import getScreenSize from "../../../../../utils/getScreenSize";
import MobileStyle from "./MoileStyle.module.scss";
import TimeIcon from "../../../../../assets/tracking/time.svg";
import clientIcon from "../../../../../assets/tracking/Client.svg";
import { toast } from "react-toastify";
import update from "immutability-helper";
import { SearchOutlined } from "@ant-design/icons";

const TeamMeeting = ({ changeTabsValue, tabsValue }) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const navigate = useNavigate();
  const screenSize = getScreenSize();

  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [days, setDays] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4, // Number of items per page
    total: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  useEffect(() => {
    LoadData();
  }, [days]);

  const LoadData = async (employement_number) => {
    let emp_Id = employement_number || "";
    const apiUrl = `tracking/admin/reporting-manager/meeting-details?employee_id=${emp_Id}&page=${pagination.current}&page_size=${pagination.pageSize}&days=${days}`;

    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      });
      setDataList(res.data?.pagination_data?.data);

      // console.log(res.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleFilterSelectChange = (value) => {
    console.log("value", value);
    setDataList([]);
    if (value === "custom") {
      setOpen(true);
    } else {
      setOpen(false);
      setDays(value);
    }
  };
  const handleDateChange = (value) => {
    setDateRange(value);
  };
  const handleDelete = async (record, index) => {
    console.log(record, index);
    setLoading(true);
    const apiUrl = `tracking/employee/employee-meeting/delete-meeting`;

    try {
      const res = await request({
        method: "post",
        url: apiUrl,
        data: {
          id: record.id,
        },
      });

      if (res && res.message) {
        toast.success(res.message, { position: "bottom-left" });
      }

      const updatedArray = update(dataList, { $splice: [[index, 1]] });
      setDataList(updatedArray);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Something went wrong";
      toast.error(errorMessage, { position: "bottom-left" });
      console.error("Error deleting meeting: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value, record) => {
    if (value == "Setting") {
      changeTabsValue("3");
    }
    if (value == "Delete") {
      handleDelete(record);
    }
    if (value == "Edit") {
      navigate(`/tracking/editmeeting/teammeeting/${record.id}`);
    }
  };
  const internalJobColumns = [
    {
      title: "Employee name",
      dataIndex: "employee_name",
      key: "employee_name",
      //   render: (text, record) => (
      //     <span>{moment(text).format("DD-MM-YYYY")}</span>
      //   ),
    },
    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Time Duration",
      dataIndex: "meeting_time",
      key: "meeting_time",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      // render: (text, record) => (
      //   <div
      //     style={{
      //       display: 'flex',
      //       gap: '10px',
      //     }}>
      //     <div>{text}</div>
      //   </div>
      // ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          style={{
            backgroundColor: "#FFDBDB",
            color: "#FD3434",
            borderRadius: "24px",
            padding: "6px 15px",
            fontSize: "16px",
            font: "400",
            textAlign: "center",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (text, record) => (
        <Select
          defaultValue="Select"
          style={{
            width: 180,
          }}
          onChange={(value) => handleSelectChange(value, record)}
          options={[
            {
              value: "Delete",
              label: "Delete",
            },
            {
              value: "Edit",
              label: "Edit",
            },
          ]}
        />
      ),
    },
  ];
  const search = async (data) => {
    try {
      setSearchValue(data);
      const response = await request({
        method: "get",
        url: `tracking/reporting-manager/employee/search?employee_name=${data}`,
      });

      setSearchEmployeeList(response);
      if (data === "") {
        LoadData();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchSelectChange = async (
    employement_number,
    employee_name
  ) => {
    try {
      LoadData(
        employement_number,
        {
          current: 1,
          pageSize: 4, // Number of items per page
          total: 0,
        },
        []
      );

      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {screenSize.width < 768 ? (
        <div className={MobileStyle.container}>
          <div className={MobileStyle.headerContainer}>
            <h2>Team Meeting</h2>
            <div className={MobileStyle.headerleftcontainer}>
              <Select
                style={{ width: 120, height: 44 }}
                placeholder="Select an option"
                onChange={handleFilterSelectChange}
                value={days}
              >
                <Option value="" disabled>
                  Select Dates
                </Option>
                <Option value={7}>Last 7 days</Option>
                <Option value={14}>Last 14 days</Option>
                <Option value={30}>Last 30 days</Option>
                <Option value="custom">
                  <span
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Custom Date
                  </span>
                </Option>
              </Select>
              <img
                src={Add}
                alt="add"
                onClick={() => {
                  navigate("/tracking/addmeeting/teammeeting", {
                    state: { MeetingType: "TeamMeeting" },
                  });
                }}
              />
              {tabsValue != "3" && (
                <img
                  src={fullView}
                  alt="fullviewmoile"
                  onClick={() => {
                    changeTabsValue("3");
                  }}
                  // className={MobileStyle.icon}
                />
              )}
            </div>
          </div>
          <div className="search-container">
            <Input
              size="small"
              type="text"
              placeholder="Search for employees"
              onChange={(e) => search(e.target.value)}
              value={searchValue}
              prefix={<SearchOutlined />}
              allowClear
            />
            {searchEmployeeList.length > 0 && (
              <ul>
                {searchEmployeeList.map((item, index) => (
                  <li
                    key={index}
                    onClick={() =>
                      handleSearchSelectChange(
                        item.employement_number,
                        item.employee_name
                      )
                    }
                  >
                    {item.employee_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={MobileStyle.contentContainer}>
            <div
              id="scrollableDiv"
              style={{
                height: "auto",
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              {dataList.map((item, index) => {
                return (
                  <div className={MobileStyle.meetingDiv} key={index}>
                    <div className={MobileStyle.top}>
                      <div className={MobileStyle.namecontainer}>
                        <div className={MobileStyle.name}>
                          {item.employee_name}
                        </div>
                        <div
                          className={MobileStyle.status}
                          style={{
                            backgroundColor:
                              item?.status == "Attended"
                                ? "#D4EDDA"
                                : "#FFDBDB",
                            color:
                              item.status == "Attended" ? "#28B15F" : "#FD3434",
                          }}
                        >
                          {item.status}
                        </div>
                      </div>
                      <div className={MobileStyle.selectcontainer}>
                        <Select
                          defaultValue="Select"
                          style={{ width: 120, height: 44 }}
                          onChange={(value) =>
                            handleSelectChange(value, item, index, "status")
                          }
                          options={[
                            {
                              value: "Setting",
                              label: "Setting",
                            },
                            {
                              value: "Delete",
                              label: "Delete",
                            },
                            {
                              value: "Edit",
                              label: "Edit",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className={MobileStyle.bottom}>
                      <div className={MobileStyle.clientContainer}>
                        <img src={clientIcon} alt="clientIcon" />
                        <span>{item.client_name}</span>
                      </div>
                      <div className={MobileStyle.dateContainer}>
                        <img src={CalenderIcon} alt="calendericon" />
                        <span>{moment(item.date).format("DD-MM-YYYY")}</span>
                      </div>
                      <div className={MobileStyle.duration}>
                        {" "}
                        <img src={TimeIcon} alt="calendericon" />
                        <span>{item.meeting_time}</span>
                      </div>
                      <div className={MobileStyle.containerlocation}>
                        <div className={MobileStyle.leftcontainer}>
                          <img src={LocationIcon} alt="locationicon" />
                          <div className={MobileStyle.location}>
                            <div>{item.location}</div>
                            {/* <div>Gurugram, Haryana 122018</div> */}
                          </div>
                        </div>
                        <div className={MobileStyle.rightcontainer}>
                          {/* <img src={LocationIcon} alt="locationicon" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {
              const page = {
                current: 1,
                pageSize: 4,
                total: 0,
              }; // reset pagination

              const apiUrl = `tracking/admin/reporting-manager/meeting-details?employee_id=&page=${
                page.current
              }&page_size=${page.pageSize}&from_date=${dateRange[0]?.format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1]?.format("YYYY-MM-DD")}`;

              const res = await request({
                method: "get",
                url: apiUrl,
              });
              setDataList(res.data?.pagination_data?.data);

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Modal>
        </div>
      ) : (
        <div style={{ background: "white", padding: "30px 30px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // border: "1px solid red",
              // padding: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <div
                style={{ display: "flex", gap: "24px", alignItems: "center" }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#161616",
                  }}
                >
                  Team Meeting
                </div>
                <div style={{ width: "350px" }}>
                  <div className="search-container">
                    <Input
                      size="small"
                      type="text"
                      placeholder="Search for employees"
                      onChange={(e) => search(e.target.value)}
                      value={searchValue}
                      prefix={<SearchOutlined />}
                      allowClear
                    />
                    {searchEmployeeList.length > 0 && (
                      <ul>
                        {searchEmployeeList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handleSearchSelectChange(
                                item.employement_number,
                                item.employee_name
                              )
                            }
                          >
                            {item.employee_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#008080",
                    color: "#fff",
                    fontSize: "16px",
                    font: 400,
                    borderRadius: "5px",
                    padding: "9px 40px",
                    display: "flex",
                    gap: "10px",
                    justifyCointent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/tracking/addmeeting/teammeeting", {
                      state: { MeetingType: "TeamMeeting" },
                    });
                  }}
                >
                  Add Meeting
                </div>

                <Select
                  style={{ width: 120, height: 44 }}
                  placeholder="Select an option"
                  onChange={handleFilterSelectChange}
                  value={days}
                >
                  <Option value="" disabled>
                    Select Dates
                  </Option>
                  <Option value={7}>Last 7 days</Option>
                  <Option value={14}>Last 14 days</Option>
                  <Option value={30}>Last 30 days</Option>
                  <Option value="custom">
                    <span
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Custom Date
                    </span>
                  </Option>
                </Select>
                {tabsValue != "3" && (
                  <img
                    src={fullView}
                    alt="fullView"
                    width={40}
                    height={40}
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("3");
                    }}
                  />
                )}
              </div>
            </div>
            <div
              id="scrollableDiv"
              style={{
                height: "auto",
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Table
                columns={internalJobColumns}
                dataSource={dataList}
                pagination={false}
              />
            </div>
          </div>
          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {
              const page = {
                current: 1,
                pageSize: 4,
                total: 0,
              }; // reset pagination

              const apiUrl = `tracking/admin/reporting-manager/meeting-details?employee_id=&page=${
                page.current
              }&page_size=${page.pageSize}&&from_date=${dateRange[0]?.format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1]?.format("YYYY-MM-DD")}`;

              const res = await request({
                method: "get",
                url: apiUrl,
              });
              setDataList(res.data?.pagination_data?.data);

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default TeamMeeting;
