import React, { useState } from 'react'
import {
    Tabs,
} from "antd";
import MailSetting from '../MailSetting/MailSetting';
import useScreenSize from '../../../../../utils/getScreenSize';


function AttendanceSettingTabs() {
    const items = [
        {
            key: '1',
            label: 'Mail Setting',
            children: <MailSetting />,
        },
    ];
    const screenSize = useScreenSize();

    const changeTabsValue = (e) => {
        setTabsValue(e)
    }

    const [tabsValue, setTabsValue] = useState("1")


    return (
        <>
            {
                screenSize.width < 768 ?  // mobile view

                    <div style={{ background: "white" }}>
                        <div style={{ padding: "0px 16px", display: "flex", alignItems: "center", flexWrap: "nowrap", overflow: "auto" }}>
                            {
                                items.map((item) => {
                                    return (
                                        <div key={item.key} style={{
                                            padding: "5px 10px",
                                            cursor: "pointer",
                                            border: item.key === tabsValue ? "1px solid #008080" : "1px solid #C3C3C3",
                                            width: "fit-content",
                                            borderRadius: "21px",
                                            fontSize: "14px",
                                            color: item.key === tabsValue ? "#008080" : "#C3C3C3",
                                            float: "left",
                                            marginRight: "8px",
                                            marginBottom: "8px",
                                            fontSize: "14px",
                                            whiteSpace: "nowrap",
                                        }}
                                            onClick={() => setTabsValue(item.key)}
                                        >
                                            {item.label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                items[tabsValue - 1].children
                            }
                        </div>
                    </div>
                    :
                    <div>
                        <div style={{ padding: "20px", background: "white" }}>
                            <Tabs
                                activeKey={tabsValue}
                                items={items}
                                onChange={changeTabsValue}
                            />
                        </div>
                    </div>
            }
        </>

    )
}

export default AttendanceSettingTabs