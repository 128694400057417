import { Navigate } from 'react-router-dom';
// Login
import Login from '../container/Login/Login';
// OnBoarding
import OnBoarding from '../container/OnBoarding/OnBoarding';
// UserProfile
import UserProfile from '../container/UserProfile/UserProfile';
// Dashboard
import Dashboard from '../container/Dashboard/User/Dashboard';
import AdminDashboard from '../container/Dashboard/Admin/Dashboard';
// PayRoll
import PayRollDashboard from '../container/PayRoll/User/Tabs';
import PayRollSummary from '../container/PayRoll/User/PayrollSummary/PayrollSummary';
// Employee
import Employee from '../container/Employee/Employee';
// Job
import AdminJobList from '../container/Recruitment/Admin/Tabs/Tabs';
import PostJob from '../container/Recruitment/Admin/PostJob/PostJob';
import AdminJobDetails from '../container/Recruitment/Admin/AdminJobDetails/AdminJobDetails';
import UserJobList from '../container/ExternalRecruitment/User/JobList/UserJobList';
import ExternalJobDetailsForUser from '../container/ExternalRecruitment/User/JobDetails/UserJobDetails';
import JobDetail from '../container/ExternalRecruitment/JobDetail/JobDetail';
// Recruitment
import RecruitmentDashboard from '../container/Recruitment/User/Dashboard/Dashboard';
import ReferralOpening from '../container/Recruitment/User/ReferralOpening/ReferralOpening';
import InternalJobOpening from '../container/Recruitment/User/InternalJobOpening/InternalJobOpening';
import ExternalRecruitment from '../container/ExternalRecruitment/ExternalRecruitment';
import ExternalRecruitmentLogin from '../container/ExternalRecruitment/ExternalRecruitmentLogin/ExternalRecruitmentLogin';

import Org from '../container/Org/Org';
import Requisition from '../container/Recruitment/User/Requisition/Requisition ';
import ViewFeedBack from '../container/Job/FeedBack/ViewFeedBack/ViewFeedBack';
import MyTeam from '../container/Attendance/User/ReportingManager/MyTeam/MyTeam';
// Attendance
// import Attendance from '../container/Attendance/User/Tabs/Tabs';
import AttendanceAdminDashBoard from '../container/Attendance/Admin/Tabs/Tabs';
import UserLogsFullView from '../container/Attendance/User/Employee/LogsFullView/LogsFullView';
import ManagerLogsFullView from '../container/Attendance/User/ReportingManager/LogsFullView/LogsFullView';
import ManagerDetailFullView from '../container/Attendance/User/ReportingManager/DetailFullView/DetailFullView';
import PolicyForm from '../container/Attendance/Admin/PolicyForm/PolicyForm';
import AttendancePolicyFullView from '../container/Attendance/Admin/AttendancePolicyFullView/AttendancePolicyFullView';
import AttendanceReportFullView from '../container/Attendance/Admin/AttendanceReportFullView/AttendanceReportFullView';
// Leave
import MyTeamLeaveFullView from '../container/Leave/User/MyTeam/MyTeam';
import UserDashboard from '../container/Leave/User/Tabs/Tabs';
import LeaveAdminDashboard from '../container/Leave/Admin/Dashboard/Dashboard';
import LeavePolicyFullView from '../container/Leave/Admin/LeavePolicyFullView/LeavePolicyFullView';
import LeaveReportFullView from '../container/Leave/Admin/LeaveReportFullView/LeaveReportFullView';
import LeaveTypeFullView from '../container/Leave/Admin/LeaveTypeFullView/LeaveTypeFullView';
import EmployeeList from '../container/Employee/EmployeeList/EmployeeList';
import ChangePassword from '../container/ChangePassword/ChangePassword';
import EmployeeLeavesFullView from '../container/Leave/Admin/EmployeeLeavesFullView/EmployeeLeavesFullView';
import LeaveTypeForm from '../container/Leave/Admin/LeaveTypeForm/LeaveTypeForm';
import EditPersonalDetails from '../container/UserProfile/EditPersonalDetails/EditPersonalDetails';
import EditEmployeeDetails from '../container/UserProfile/EditEmployeeDetails/EditEmployeeDetails';
import HolidayForm from '../container/Leave/Admin/HolidayForm/HolidayForm';
import DocumentsDashboard from '../container/Documents/Dashboard/DocumentsDashboard';
import HolidayFullView from '../container/Leave/Admin/HolidayFullView/HolidayFullView';
import DocumentsHrLetterForm from '../container/Documents/DocumentsHrLetterForm/DocumentsHrLetterForm';
import DocumentPolicyForm from '../container/Documents/DocumentPolicyForm/DocumentPolicyForm';
import AddOrEditAdminMobile from '../container/Org/AddOrEditAdminMobile/AddOrEditAdminMobile';
import AddOrEditOrgMobile from '../container/Org/AddOrEditOrgMobile/AddOrEditOrgMobile';
import PayRollDashBoard from '../container/PayRoll/Admin/Dashboard/Dashboard';
import AdminPayRollProcessing from '../container/PayRoll/Admin/PayRollProcessing/PayRollProcessing';
import SalaryConfigurationForm from '../container/PayRoll/Admin/SalaryConfigurationForm/SalaryConfigurationForm';
import HRLetterFullView from '../container/Documents/HRLetterFullView/HRLetterFullView';
import PoliciesFullView from '../container/Documents/PoliciesFullView/PoliciesFullView';
import PayRollSalaryDetails from '../container/PayRoll/User/SalaryDetails/SalaryDetails';
import PayRollTaxDeclarationForm from '../container/PayRoll/User/TaxDeclarationForm/TaxDeclarationForm';
import InboxDashboard from '../container/Inbox/Dashboard/Dashboard';
import ApplyLeave from '../container/Leave/User/ApplyLeave/ApplyLeave';
import LeaveRequestFullView from '../container/Leave/User/LeaveRequest/LeaveRequestFullView';
import UserHolidayFullView from '../container/Leave/User/Holiday/UserHolidayFullView';
import AdminLeaveTypeFullView from '../container/Leave/Admin/AdminLeaveTypeFullView/AdminLeaveTypeFullView';
import Form24Q from '../container/PayRoll/Admin/Fom24Q/Form24Q';
// import Form24Setting from '../container/PayRoll/Admin/Form24QSettting/Form24Setting';
import ChallanForm from '../container/PayRoll/Admin/Fom24Q/ChallanForm/ChallanForm';
import ForcedDocument from '../container/ForcedDocument/ForcedDocument';
import PayrollAdminTabs from '../container/PayRoll/Admin/PayrollAdminTabs/PayrollAdminTabs';
import EmployeeAttendancePolicyManagerFullView from '../container/Attendance/Admin/EmployeeAttendancePolicyManagerFullView/EmployeeAttendancePolicyManagerFullView';
import LeaveDetail from '../container/Inbox/Leave/LeaveDetail/LeaveDetail';
import LoanFullView from '../container/PayRoll/Admin/LoanFullView/LoanFullView';
import AdvanceSalaryFullView from '../container/PayRoll/Admin/AdvanceSalaryFullView/AdvanceSalaryFullView';
import ReimbursementFullView from '../container/PayRoll/Admin/ReimbursementFullView/ReimbursementFullView';

import AdminJobListFullView from '../container/Recruitment/Admin/Jobs/Jobs';
import PostRequisitionToJob from '../container/Recruitment/Admin/RequisitionToJob/PostRequisitionToJob';
import HoldSalaryFullView from '../container/PayRoll/Admin/HoldSalaryFullView/HoldSalaryFullView';

import EmployeeSalaryManager from '../container/PayRoll/Admin/EmployeeSalaryManager/EmployeeSalaryManager';

import LeavePolicyManagerFullView from '../container/Leave/Admin/LeavePolicyManagerFullView/EmployeeLeavesFullView';
import RequisitionFullView from '../container/Recruitment/Admin/Requisition/Requisition Fullview';
import RecruiterFullView from '../container/Recruitment/Admin/ExternalRecruiters/RecruiterFullView';
import InterviewFullView from '../container/Recruitment/User/InterviewScheduleFullView/InterviewFullView';
import DocsDetail from '../container/Inbox/Documentation/DocumentationDetails/DocumentationDetails';

import UserReimbursementFullView from '../container/PayRoll/User/Reimbursement/Reimbursement';
import UserReimbursementForm from '../container/PayRoll/User/ReimbursementForm/ReimbursementForm';
import UserLoanFullView from '../container/PayRoll/User/Loan/Loan';
import UserPayRollSummaryFullView from '../container/PayRoll/User/PayRollSummaryFullView/PayRollSummaryFullView';
import OrgAdminFullView from '../container/Org/AdminsFullView/AdminsFullView';
import LeavePolicyForm from '../container/Leave/Admin/LeavePolicyForm/LeavePolicyForm';
import TaxSummaryFullView from '../container/PayRoll/User/TaxSummary/TaxSummaryFullView';
import EmployeeDeclarationDetail from '../container/PayRoll/Admin/EmployeeDeclaration/EmployeeDeclarationDetail/EmployeeDeclarationDetail';
import SalaryStructureFullView from '../container/PayRoll/Admin/SalaryStructureFullView/salaryStructureFullView';
import EmployeeDeclarationFullView from '../container/PayRoll/Admin/EmployeeDeclaration/EmployeeDeclarationFullView/EmployeeDeclarationFullView';
import PayrollSummary from '../container/PayRoll/Admin/PayrollSummary/PayrollSummary';
import ResignationDetail from '../container/Inbox/Resignation/ResignationDetail/ResignationDetail';
import CompOffForm from '../container/Leave/Admin/CompOffForm/CompOffForm';
import CompOffFullView from '../container/Leave/Admin/CompOffFullView/CompOffFullView';
import ReferForm from '../container/Recruitment/ReferForm/ReferForm';
import FeedBackForm from '../container/Recruitment/User/FeedBackForm/FeedBackForm';
import ApplyForm from '../container/Recruitment/ApplyForm/ApplyForm';
import RequisitionForm from '../container/Recruitment/User/RequisitionForm/RequisitionForm';
import CompanyName from '../container/CompanyName/CompanyName';
import AttendanceList from '../container/Inbox/Attendance/AttendanceList/AttendanceList';
import OrgConfigDashboard from '../container/Org/OrgConfig/Dashboard/Dashboard';
import ReimbursementDetail from '../container/PayRoll/Admin/ReimbursementDetail/ReimbursementDetail';
import AttendanceDetail from '../container/Inbox/Attendance/AttendanceDetail/AttendanceDetail';
import EditBankDetails from '../container/UserProfile/EditBankDetails/EditBankDetails';
import PrivacyPolicy from '../container/PrivacyPolicy/PrivacyPolicy';
import PerformanceTabs from '../container/PerformanceManagement/User/PerformanceTabs/PerformanceTabs';
import GoalForm from '../container/PerformanceManagement/User/GoalForm/GoalForm';
import Goal from '../container/PerformanceManagement/User/Goal/Goal';

//tracking

import TrackingTabs from '../container/Tracking/User/TrackingTabs/TrackingTabs';
import AdminTrackingTabs from '../container/Tracking/Admin/TrackingTabs/TrackingTabs';
import AddMeeting from '../container/Tracking/AddMeeting/AddMeeting';
import EditMetting from '../container/Tracking/EditMeeting/EditMeeting';
import AttendMeeting from '../container/Tracking/AttendMeeting/AttendMeeting';

import Google from '../container/Google/Google';
import LmsList from '../container/lms/user/LmsList/LmsList';
import LmsInstruction from '../container/lms/user/LmsInstruction/LmsInstruction';
import LmsVideo from '../container/lms/user/LmsVideo/LmsVideo';
import AdminLmsList from '../container/lms/admin/LmsList/LmsList';
import TrainingForm from '../container/lms/admin/TrainingForm/TrainingForm';
import LmsQuiz from '../container/lms/user/LmsQuiz/LmsQuiz';
import InboxLmsList from '../container/Inbox/Lms/InboxLmsList';
import InboxLmsDetail from '../container/Inbox/Lms/LmsDetails/LmsDetails';
import LmsDetail from '../container/lms/admin/LmsDetail/LmsDetail';
import BulkAttendancePolicy from '../container/Attendance/Admin/BulkAttendancePolicy/BulkAttendancePolicy';
import BulkPayrollProcessing from '../container/PayRoll/Admin/BulkPayrollProcessing/BulkPayrollProcessing';
import BulkLeavePolicy from '../container/Leave/Admin/BulkLeavePolicy/BulkLeavePolicy';
import PaidEmployee from '../container/PayRoll/Admin/PayrollSummaryInsight/PaidEmployee/PaidEmployee';
import Feeds from '../container/Feeds/User/Feeds';
import AddPost from '../container/Feeds/User/AddPost/AddPost';
import IntegarationStatus from '../container/Attendance/Admin/IntegarationStatus/IntegarationStatus';
import FeedsTab from '../container/Feeds/Admin/Tabs/Tabs';
import EditFeed from '../container/Feeds/Admin/EditFeed/EditFeed';
import AddFeedPost from '../container/Feeds/Admin/Posts/AddPost/AddPost';
import DisplayPost from '../container/Feeds/Admin/Posts/DisplayPost/DisplayPost';
import { Suspense, lazy } from 'react';
import TimeLine from '../container/Tracking/User/TimeLine/TimeLine';
const Attendance = lazy(() => import('../container/Attendance/User/Tabs/Tabs'));

const routeList = [
  {
    path: '/login',
    exact: true,
    protected: false,
    element: <Login />,
    menuTitle: '',
    children: [],
  },

  {
    path: '/app',
    exact: true,
    protected: false,
    element: <CompanyName />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/privacy-policy',
    exact: true,
    protected: false,
    element: <PrivacyPolicy />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/:companyName/app',
    exact: true,
    protected: false,
    element: <CompanyName />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/:companyName/login',
    exact: true,
    protected: false,
    element: <Login />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/reset-password/:secure_token',
    exact: true,
    protected: false,
    element: <ChangePassword />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/onboarding',
    exact: true,
    protected: false,
    element: <OnBoarding />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/dashboard',
    exact: true,
    protected: true,
    element: <Dashboard />,
    menuTitle: 'Dashboard',
    children: [],
  },
  {
    path: '/forced-document',
    exact: true,
    protected: false,
    element: <ForcedDocument />,
    menuTitle: 'Dashboard',
    children: [],
  },
  {
    path: '/admin/dashboard',
    exact: true,
    protected: true,
    element: <AdminDashboard />,
    menuTitle: 'Dashboard',
    children: [],
  },
  // Leave
  // user
  {
    path: '/leave',
    exact: true,
    protected: true,
    element: <UserDashboard />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/my-team',
    exact: true,
    protected: true,
    element: <MyTeamLeaveFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/holiday-list',
    exact: true,
    protected: true,
    element: <UserHolidayFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  // leave admin
  {
    path: '/admin/leave',
    exact: true,
    protected: true,
    element: <LeaveAdminDashboard />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/:componentId',
    exact: true,
    protected: true,
    element: <LeaveAdminDashboard />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/comp-off/form',
    exact: true,
    protected: true,
    element: <CompOffForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-type-form',
    exact: true,
    protected: true,
    element: <LeaveTypeForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-policy/form',
    exact: true,
    protected: true,
    element: <LeavePolicyForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-policy/form/:id',
    exact: true,
    protected: true,
    element: <LeavePolicyForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-type-form/:id',
    exact: true,
    protected: true,
    element: <LeaveTypeForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/employee-leaves',
    exact: true,
    protected: true,
    element: <EmployeeLeavesFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  // HolidayForm
  {
    path: '/admin/leave/add-holiday',
    exact: true,
    protected: true,
    element: <HolidayForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/holiday',
    exact: true,
    protected: true,
    element: <HolidayFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave_policy_manager',
    exact: true,
    protected: true,
    element: <LeavePolicyManagerFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-policy',
    exact: true,
    protected: true,
    element: <LeavePolicyFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-report',
    exact: true,
    protected: true,
    element: <LeaveReportFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-type',
    exact: true,
    protected: true,
    element: <LeaveTypeFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-full',
    exact: true,
    protected: true,
    element: <AdminLeaveTypeFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/apply-leave',
    exact: true,
    protected: true,
    element: <ApplyLeave />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/leave-request',
    exact: true,
    protected: true,
    element: <LeaveRequestFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/comp-off/full-view',
    exact: true,
    protected: true,
    element: <CompOffFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/attendance',
    exact: true,
    protected: true,
    element: <Suspense fallback={""}><Attendance /></Suspense>,
    menuTitle: 'Attendance',
    children: [],
  },
  {
    path: '/attendance/logs',
    exact: true,
    protected: true,
    element: <UserLogsFullView />,
    menuTitle: 'Logs',
    children: [],
  },
  {
    path: '/attendance/my-team',
    exact: true,
    protected: true,
    element: <MyTeam />,
    menuTitle: 'My Team',
    children: [],
  },
  {
    path: '/attendance/my-team/logs',
    exact: true,
    protected: true,
    element: <ManagerLogsFullView />,
    menuTitle: 'Logs',
    children: [],
  },
  {
    path: '/attendance/my-team/details',
    exact: true,
    protected: true,
    element: <ManagerDetailFullView />,
    menuTitle: 'Logs',
    children: [],
  },
  {
    path: '/admin/attendance',
    exact: true,
    protected: true,
    element: <AttendanceAdminDashBoard />,
    menuTitle: 'Attendance',
    children: [],
  },
  {
    path: '/admin/attendance/:componentId',
    exact: true,
    protected: true,
    element: <AttendanceAdminDashBoard />,
    menuTitle: 'Attendance',
    children: [],
  },
  {
    path: '/admin/attendance/attendance-policy',
    exact: true,
    protected: true,
    element: <AttendancePolicyFullView />,
    menuTitle: 'Attendance Policy',
    children: [],
  },
  {
    path: '/admin/attendance/attendance-report',
    exact: true,
    protected: true,
    element: <AttendanceReportFullView />,
    menuTitle: 'Attendance Report',
    children: [],
  },
  {
    path: '/admin/attendance/employee-attendance-policy-manager',
    exact: true,
    protected: true,
    element: <EmployeeAttendancePolicyManagerFullView />,
    menuTitle: 'Attendance Report',
    children: [],
  },
  {
    path: '/admin/attendance/policy-form',
    exact: true,
    protected: true,
    element: <PolicyForm />,
    menuTitle: 'Policy Form',
    children: [],
  },
  {
    path: '/admin/attendance/policy-form/:id/:action?',
    exact: true,
    protected: true,
    element: <PolicyForm />,
    menuTitle: 'Policy Form Edit',
    children: [],
  },
  // BulkAttendancePolicy
  {
    path: '/admin/attendance/bulk-attendance-policy',
    exact: true,
    protected: true,
    element: <BulkAttendancePolicy />,
    menuTitle: 'Bulk Attendance Policy',
    children: [],
  },
  {
    path: '/admin/attendance/integaration-status/:id',
    exact: true,
    protected: true,
    element: <IntegarationStatus />,
    menuTitle: 'Integaration Status',
    children: [],
  },
  {
    path: '/admin/leave/bulk-leave-policy',
    exact: true,
    protected: true,
    element: <BulkLeavePolicy />,
    menuTitle: 'Bulk Leave Policy',
    children: [],
  },
  {
    path: '/admin/payroll/selective-payroll-processing',
    exact: true,
    protected: true,
    element: <BulkPayrollProcessing />,
    menuTitle: 'Bulk Attendance Policy',
    children: [],
  },
  {
    path: '/admin/payroll/insight/paid-employee',
    exact: true,
    protected: true,
    element: <PaidEmployee />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll',
    exact: true,
    protected: true,
    element: <PayRollDashboard />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/tax-summary',
    exact: true,
    protected: true,
    element: <TaxSummaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },

  {
    path: '/payroll/salary-details',
    exact: true,
    protected: true,
    element: <PayRollSalaryDetails />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/salary-details/:userId',
    exact: true,
    protected: true,
    element: <PayRollSalaryDetails />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/salary-structure/full-view',
    exact: true,
    protected: true,
    element: <SalaryStructureFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/employee-declaration',
    exact: true,
    protected: true,
    element: <EmployeeDeclarationFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/tax-declaration/form',
    exact: true,
    protected: true,
    element: <PayRollTaxDeclarationForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/salary-details/salary-breakup/:date',
    exact: true,
    protected: true,
    element: <PayRollSummary />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/salary-details/salary-breakup/:userId/:date',
    exact: true,
    protected: true,
    element: <PayRollSummary />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement',
    exact: true,
    protected: true,
    element: <UserReimbursementFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement/form',
    exact: true,
    protected: true,
    element: <UserReimbursementForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/loan',
    exact: true,
    protected: true,
    element: <UserLoanFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/payroll-summary',
    exact: true,
    protected: true,
    element: <UserPayRollSummaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll',
    exact: true,
    protected: true,
    element: <PayrollAdminTabs />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/:componentId',
    exact: true,
    protected: true,
    element: <PayrollAdminTabs />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/payroll-summary',
    exact: true,
    protected: true,
    element: <PayrollSummary />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/employee-salary-manager',
    exact: true,
    protected: true,
    element: <EmployeeSalaryManager />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/processing',
    exact: true,
    protected: true,
    element: <AdminPayRollProcessing />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/form24Q',
    exact: true,
    protected: true,
    element: <Form24Q />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/form24Q/challan',
    exact: true,
    protected: true,
    element: <ChallanForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/form24Q/challan/:id',
    exact: true,
    protected: true,
    element: <ChallanForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  // {
  //     path: '/admin/payroll/form24Q/setting',
  //     exact: true,
  //     protected: true,
  //     element: <Form24Setting />,
  //     menuTitle: "Payroll",
  //     children: []
  // },
  //
  {
    path: '/admin/payroll/salary-configuration-form',
    exact: true,
    protected: true,
    element: <SalaryConfigurationForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/salary-configuration-form/:id/:action?',
    exact: true,
    protected: true,
    element: <SalaryConfigurationForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/loan',
    exact: true,
    protected: true,
    element: <LoanFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/advance-salary',
    exact: true,
    protected: true,
    element: <AdvanceSalaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/reimbursement',
    exact: true,
    protected: true,
    element: <ReimbursementFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/hold-salary',
    exact: true,
    protected: true,
    element: <HoldSalaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/employee-declaration/:id',
    exact: true,
    protected: true,
    element: <EmployeeDeclarationDetail />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/recruitment',
    exact: true,
    protected: true,
    element: <RecruitmentDashboard />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/recruitment/refer-form/:id',
    exact: true,
    protected: true,
    element: <ReferForm />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/recruitment/apply-form/:id',
    exact: true,
    protected: true,
    element: <ApplyForm />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/recruitment/referral',
    exact: true,
    protected: true,
    element: <ReferralOpening />,
    menuTitle: 'Referral',
    children: [],
  },
  {
    path: '/recruitment/opening',
    exact: true,
    protected: true,
    element: <ReferralOpening />,
    menuTitle: 'Opening',
    children: [],
  },
  {
    path: '/recruitment/internal-job',
    exact: true,
    protected: true,
    element: <InternalJobOpening />,
    menuTitle: 'Opening',
    children: [],
  },
  {
    path: '/recruitment/requisition',
    exact: true,
    protected: true,
    element: <Requisition />,
    menuTitle: 'Requisition',
    children: [],
  },
  {
    path: '/recruitment/requisition-form',
    exact: true,
    protected: true,
    element: <RequisitionForm />,
    menuTitle: 'Requisition',
    children: [],
  },

  {
    path: '/recruitment/interview-schedule/feedback',
    exact: true,
    protected: true,
    element: <FeedBackForm />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/admin/employee',
    exact: true,
    protected: true,
    element: <EmployeeList />,
    menuTitle: 'Employee List',
    children: [],
  },

  {
    path: '/admin/employee/add',
    exact: true,
    protected: true,
    element: <Employee />,
    menuTitle: 'Employee',
    children: [],
  },
  {
    path: '/admin/employee/edit/:id',
    exact: true,
    protected: true,
    element: <Employee />,
    menuTitle: 'Employee',
    children: [],
  },
  {
    path: '/user-profile',
    exact: true,
    protected: true,
    element: <UserProfile />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/user-profile/:id',
    exact: true,
    protected: true,
    element: <UserProfile />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/admin/employee/detail/:id',
    exact: true,
    protected: true,
    element: <UserProfile />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/user-profile/personal-details/:id',
    exact: true,
    protected: true,
    element: <EditPersonalDetails />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/user-profile/bank-details/:id',
    exact: true,
    protected: true,
    element: <EditBankDetails />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/admin/employee/employee-details/:id',
    exact: true,
    protected: true,
    element: <EditEmployeeDetails />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/admin/recruitment',
    exact: true,
    protected: true,
    element: <AdminJobList />,
    menuTitle: 'Latest Jobs',
    children: [],
  },

  {
    path: '/admin/recruitment/post-job',
    exact: true,
    protected: true,
    element: <PostJob />,
    menuTitle: 'Post a Job',
    children: [],
  },
  {
    path: '/admin/recruitment/post-job/:id',
    exact: true,
    protected: true,
    element: <PostJob />,
    menuTitle: 'Job Form',
    children: [],
  },
  {
    path: '/admin/recruitment/details/:id',
    exact: true,
    protected: true,
    element: <AdminJobDetails />,
    menuTitle: 'Job Details',
    children: [],
  },
  {
    path: '/admin/recruitment/details/:id/feedback',
    exact: true,
    protected: true,
    element: <ViewFeedBack />,
    menuTitle: 'Feedback',
    children: [],
  },
  {
    path: '/admin/org',
    exact: true,
    protected: true,
    element: <Org />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/add',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/parent/edit/:id',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/child/add',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/child/edit/:id',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/admin/add',
    exact: true,
    protected: true,
    element: <AddOrEditAdminMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/admin/edit/:id',
    exact: true,
    protected: true,
    element: <AddOrEditAdminMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/config/:id',
    exact: true,
    protected: true,
    element: <OrgConfigDashboard />,
    menuTitle: 'Organization',
    children: [],
  },
  // DocumentsDocuments
  {
    path: '/admin/docs',
    exact: true,
    protected: true,
    element: <DocumentsDashboard />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/hr-letter',
    exact: true,
    protected: true,
    element: <HRLetterFullView />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/policies',
    exact: true,
    protected: true,
    element: <PoliciesFullView />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/add/policies',
    exact: true,
    protected: true,
    element: <DocumentPolicyForm />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/add/hr-letter',
    exact: true,
    protected: true,
    element: <DocumentsHrLetterForm />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/inbox/leave',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/attendance',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/attendance/detail/:type/:id',
    exact: true,
    protected: true,
    element: <AttendanceDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/leave/detail/:id',
    exact: true,
    protected: true,
    element: <LeaveDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/documentation/detail/:id',
    exact: true,
    protected: true,
    element: <DocsDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/documentation',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/documentation/:id',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },

  {
    path: '/inbox/resign',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/resign/:id',
    exact: true,
    protected: true,
    element: <ResignationDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/lms',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/lms/detail/:id',
    exact: true,
    protected: true,
    element: <InboxLmsDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/recruitment/job-list/full-view',
    exact: true,
    protected: true,
    element: <AdminJobListFullView />,
    menuTitle: 'Latest Jobs',
    children: [],
  },
  {
    path: '/admin/recruitment/requisition/job-form/:id',
    exact: true,
    protected: true,
    element: <PostRequisitionToJob />,
    menuTitle: 'Requisition Job Form',
    children: [],
  },

  {
    path: '/admin/org/admins/full-view',
    exact: true,
    protected: true,
    element: <OrgAdminFullView />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/recruitment/requisition/full-view',
    exact: true,
    protected: true,
    element: <RequisitionFullView />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/payroll/reimbursement/:id',
    exact: true,
    protected: true,
    element: <ReimbursementDetail />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/recruitment/job-interview/full-view',
    exact: true,
    protected: true,
    element: <InterviewFullView />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/recruitment/recruiter/full-view',
    exact: true,
    protected: true,
    element: <RecruiterFullView />,
    menuTitle: 'Inbox',
    children: [],
  },

  // #########################################################################################################
  // External Recruitment
  // #########################################################################################################

  // user routes for those user which are not part of the company (external user)
  {
    path: '/:company_name/careers/jobs',
    exact: true,
    protected: false,
    element: <UserJobList />,
    menuTitle: 'Latest Jobs',
    children: [],
  },
  {
    path: '/:company_name/careers/jobs/job-detail/:id',
    exact: true,
    protected: false,
    element: <ExternalJobDetailsForUser />,
    menuTitle: 'Latest Jobs',
    children: [],
  },
  // external recruitment routes
  {
    path: 'recruitment/external-recruitment/login',
    exact: true,
    protected: false,
    element: <ExternalRecruitmentLogin />,
    menuTitle: 'External Recruitment Login',
    children: [],
  },
  {
    path: '/recruitment/external-recruitment',
    exact: true,
    protected: false,
    element: <ExternalRecruitment />,
    menuTitle: 'External Recruitment',
    children: [],
  },
  {
    path: '/recruitment/external-recruitment/detail/:id',
    exact: true,
    protected: false,
    element: <JobDetail />,
    menuTitle: 'External Recruitment',
    children: [],
  },
  {
    path: '/recruitment/external-recruitment/detail/:id/feedback',
    exact: true,
    protected: false,
    element: <ViewFeedBack />,
    menuTitle: 'External Recruitment',
    children: [],
  },

  // PerformanceManagement

  {
    path: '/performance-management',
    exact: true,
    protected: true,
    element: <PerformanceTabs />,
    menuTitle: 'Performance Management',
    children: [],
  },
  {
    path: '/performance-management/goal',
    exact: true,
    protected: true,
    element: <Goal />,
    menuTitle: 'Performance Management',
    children: [],
  },
  {
    path: '/performance-management/goal-form',
    exact: true,
    protected: true,
    element: <GoalForm />,
    menuTitle: 'Performance Management',
    children: [],
  },

  // Tracking
  {
    path: '/tracking',
    exact: true,
    protected: true,
    element: <TrackingTabs />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/tracking/:id',
    exact: true,
    protected: true,
    element: <TrackingTabs />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/admin/tracking',
    exact: true,
    protected: true,
    element: <AdminTrackingTabs />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/tracking/addmeeting/:type',
    exact: true,
    protected: true,
    element: <AddMeeting />,
    menuTitle: 'Tracking',
  },
  {
    path: '/tracking/attendmeeting/:id',
    exact: true,
    protected: true,
    element: <AttendMeeting />,
    menuTitle: 'Tracking',
  },

  {
    path: '/tracking/editmeeting/:type/:id',
    exact: true,
    protected: true,
    element: <EditMetting />,
    menuTitle: 'Tracking',
  },
  // Timeline
  {
    path: '/timeline/:employement_number',
    exact: true,
    protected: true,
    element: <TimeLine />,
    menuTitle: 'TimeLine',
    children: [],
  },

  // google
  {
    path: '/google/:token',
    exact: true,
    protected: false,
    element: <Google />,
    menuTitle: 'Tracking',
    children: [],
  },

  // lms
  {
    path: '/lms',
    exact: true,
    protected: true,
    element: <LmsList />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/lms/instruction/:id',
    exact: true,
    protected: true,
    element: <LmsInstruction />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/lms/video/:id',
    exact: true,
    protected: true,
    element: <LmsVideo />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/lms/quiz/:id',
    exact: true,
    protected: true,
    element: <LmsQuiz />,
    menuTitle: 'Tracking',
    children: [],
  },

  // lms admin
  {
    path: '/admin/lms/list',
    exact: true,
    protected: true,
    element: <AdminLmsList />,
    menuTitle: 'Tracking',
    children: [],
  },

  {
    path: '/admin/lms/training-form',
    exact: true,
    protected: true,
    element: <TrainingForm />,
    menuTitle: 'Tracking',
    children: [],
  },
  // {
  //     path: '/lms/quiz/:id',
  //     exact: true,
  //     protected: true,
  //     element: <LmsQuiz />,
  //     menuTitle: "Tracking",
  //     children: []
  // },
  {
    path: '/admin/lms/details/:id',
    exact: true,
    protected: true,
    element: <LmsDetail />,
    menuTitle: 'Tracking',
    children: [],
  },

  // Feeds
  {
    path: '/feeds',
    exact: true,
    protected: true,
    element: <Feeds />,
    menuTitle: 'Feeds',
    children: [],
  },
  {
    path: '/feeds/add-post',
    exact: true,
    protected: true,
    element: <AddPost />,
    menuTitle: 'Feeds',
    children: [],
  },

  {
    path: '/admin/feeds',
    exact: true,
    protected: true,
    element: <FeedsTab />,
    menuTitle: 'Feeds',
    children: [],
  },
  {
    path: '/admin/feeds/edit-feed/:id',
    exact: true,
    protected: false,
    element: <EditFeed />,
    menuTitle: 'Feeds',
    children: [],
  },
  {
    path: '/admin/feeds/add-feed',
    exact: true,
    protected: true,
    element: <AddFeedPost />,
    menuTitle: 'Feeds',
    children: [],
  },

  {
    path: '/admin/feeds/display-post/:id',
    exact: true,
    protected: true,
    element: <DisplayPost />,
    menuTitle: 'Feeds',
    children: [],
  },

  {
    path: '*',
    exact: true,
    protected: false,
    element: <Navigate to="/app" />,
    children: [],
  },
];

export default routeList;
