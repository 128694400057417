import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import request from "../../../../utils/axios";
import playBtn from "../../../../assets/play_button.svg";
import pendingPlayBtn from "../../../../assets/pending_play_btn.svg";
import "./LmsList.scss";
import getScreenSize from "../../../../utils/getScreenSize";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";
import ContainerLayout from "../../../../component/ContainerLayout/ContainerLayout"
function LmsList() {
  const navigate = useNavigate();
  const screenSize = getScreenSize();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <button className={`${text}Button`}>{text}</button>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          {record.status === "Pending" ? (
            <img
              src={pendingPlayBtn}
              alt="playBtn"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/lms/instruction/" + record.id, { replace: true })
              }
            />
          ) : (
            <img
              src={playBtn}
              alt="playBtn"
              style={{ cursor: "not-allowed" }}
            />
          )}
        </>
      ),
    },
  ];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5, // Number of items per page
    total: 0,
  });

  const [trainingList, setTrainingList] = useState([]);

  const fetchData = async () => {
    const response = await request({
      method: "get",
      url: `/lms/employee/training-videos/all?page=${pagination.current}&page_size=${pagination.pageSize}`,
    });
    console.log(response?.pagination_data?.data);
    setTrainingList(response?.pagination_data?.data);
    setPagination({
      ...pagination,
      total: response?.pagination_data?.total,
    });
  };

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  useEffect(() => {
    console.log("trainingList.............................", trainingList);
  }, [trainingList]);

  const renderUI = (dataList) => {
    return (
      <div>
        {dataList?.map((item, index) => {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 16px",
                  marginBottom: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#161616",
                    margin: "0px",
                  }}
                >
                  {item.title}
                </p>
                {/* <img src={playBtn} alt="playBtn"
                
                /> */}
                {item.status === "Pending" ? (
                  <img
                    src={pendingPlayBtn}
                    alt="playBtn"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/lms/instruction/" + item.id, {
                        replace: true,
                      })
                    }
                  />
                ) : (
                  <img
                    src={playBtn}
                    alt="playBtn"
                    style={{ cursor: "not-allowed" }}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#4A4A4A",
                  }}
                >
                  Date
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#161616",
                  }}
                >
                  {item.created_at}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#4A4A4A",
                  }}
                >
                  Duration
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#161616",
                  }}
                >
                  {item.duration}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#4A4A4A",
                  }}
                >
                  Action
                </p>
                <button className={`${item.status}Button`}>
                  {item.status}
                </button>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 16px",
              marginTop: "70px",
              marginBottom: "10px",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#4A4A4A",
                margin: "0",
              }}
            >
              Training
            </p>
          </div>
          <InfiniteScrollComponent
            apiEndpoint="/lms/employee/training-videos/all"
            renderUI={renderUI}
            initialPage={1}
          />
        </div>
      ) : (
        <ContainerLayout>
          <div>
            <div
              style={{
                padding: "30px",
                background: "white",
                borderRadius: "10px",
              }}
            >
              <p
                style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}
              >
                Training
              </p>

              <CommonTable
                columns={columns}
                data={trainingList}
                pagination={pagination}
                setPagination={setPagination}
                handleRowClick={() => { }}
              />
            </div>

            {/* <button
        onClick={() => navigate("/lms/instruction")}
      >Next</button> */}
          </div>
        </ContainerLayout>
      )}
    </div>
  );
}

export default LmsList;
