import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import getScreenSize from "../../../utils/getScreenSize";
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import { setBankDetails } from '../../../redux/reducer/OnboardingReducer';

function BankDetails({ prev, next }) {
    const screenSize = getScreenSize();
    let bankDetails = useSelector((state) => state.onboarding.bankDetails)
    const ref = useRef();
    const dispatch = useDispatch()

    const onFinish = (values) => {

        dispatch(setBankDetails(values));

        next();
    };

    const bankList = [
        "Bank of Baroda",
        "Bank of India",
        "Bank of Maharashtra",
        "Canara Bank",
        "Central Bank of India",
        "Indian Bank",
        "Indian Overseas Bank",
        "Punjab and Sind Bank",
        "Punjab National Bank",
        "State Bank of India",
        "UCO Bank",
        "Union Bank of India",
        "Axis Bank",
        "Bandhan Bank",
        "CSB Bank",
        "City Union Bank",
        "DCB Bank",
        "Dhanlaxmi Bank",
        "Federal Bank",
        "HDFC Bank",
        "ICICI Bank",
        "IDBI Bank",
        "IDFC First Bank",
        "IndusInd Bank",
        "Jammu & Kashmir Bank",
        "Karnataka Bank",
        "Karur Vysya Bank",
        "Kotak Mahindra Bank",
        "Nainital Bank",
        "RBL Bank",
        "South Indian Bank",
        "Tamilnad Mercantile Bank",
        "Yes Bank"
    ]

    const [bankNameList, setBankNameList] = useState(bankList)

    const handleSearch = (value) => {
        if (value) {
            const filteredList = bankList?.filter((item) =>
                item.toLowerCase().includes(value?.toLowerCase())
            );
            console.log(filteredList)
            setBankNameList(filteredList);
        } else {
            setBankNameList(bankList);
        }
    };

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div ref={ref}>
            {
                screenSize.width < 768 ?
                    <div>
                        <div >
                            <Form
                                layout="vertical"
                                initialValues={bankDetails}
                                onFinish={onFinish}
                                autoComplete="true"
                            >
                                <Row>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Bank Name"
                                            name="bank_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your bank name!',
                                                },
                                            ]}
                                        >
                                            {/* <Input
                                                size='large'
                                            /> */}
                                            <Select
                                                size='large'
                                            // onChange={handleMaritalStatusChange}
                                            >
                                                {
                                                    bankNameList.map((item, index) => {
                                                        return <Select.Option value={item} key={index}>{item}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label="IFSC Code"
                                            name="ifsc_code"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your IFSC code!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                size='large'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Account No."
                                            name="account_no"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Account No.!',
                                                }
                                            ]}
                                        >
                                            <Input
                                                size='large'
                                                type="number"
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Mode Of Payment"
                                            name="mode_of_payment"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Mode Of Payment!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                size='large'
                                            // onChange={handleMaritalStatusChange}
                                            >
                                                <Select.Option value="0">Bank Transfer</Select.Option>
                                                <Select.Option value="1">Cheques</Select.Option>
                                                <Select.Option value="2">Cash</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Pan No."
                                            name="pan_no"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your pan no!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                size='large'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label="UAN No."
                                            name="uan_no"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enter your uan no!',
                                        //     },
                                        // ]}
                                        >
                                            <Input
                                                size='large'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: "10px", display: "flex", gap: "20px" }}>
                                    <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} onClick={() => prev()} >Previous</Button>
                                    <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} htmlType='submit' >Next</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    :
                    <div style={{ marginTop: "50px" }}>
                        <p className='heading'>Bank & Salary Details</p>
                        <p className='subHeading'>Fill up your Bank details</p>
                        <Form
                            layout="vertical"
                            initialValues={bankDetails}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Bank Name"
                                        name="bank_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your bank name!',
                                            },
                                        ]}
                                    >
                                        {/* <Input
                                            size='large'
                                            style={{ width: "80%" }}
                                        /> */}
                                        {/* <Select
                                            size='large'
                                            style={{ width: "80%" }}
                                        // onChange={handleMaritalStatusChange}
                                        >
                                            {
                                                bankList.map((item, index) => {
                                                    return <Select.Option value={item} key={index}>{item}</Select.Option>
                                                })
                                            }
                                        </Select> */}
                                        <Select
                                            className="dropDown"
                                            showSearch
                                            onSearch={handleSearch}
                                            filterOption={false} // To prevent default filtering
                                            style={{ width: "80%" }}
                                            placeholder="Select Bank"
                                            size='large'
                                        >
                                            <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Search or Select Bank</p></Select.Option>
                                            {
                                                bankNameList.map((item, index) => {
                                                    return <Select.Option value={item} key={index}>{item}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="IFSC Code"
                                        name="ifsc_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your IFSC code!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            style={{ width: "80%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Account No."
                                        name="account_no"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your Account No.!',
                                            }
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            style={{ width: "80%" }}
                                            type='number'
                                        />
                                    </Form.Item>

                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Mode Of Payment"
                                        name="mode_of_payment"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your Mode Of Payment!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            size='large'
                                            style={{ width: "80%" }}
                                        // onChange={handleMaritalStatusChange}
                                        >
                                            <Select.Option value="0">Bank Transfer</Select.Option>
                                            <Select.Option value="1">Cheques</Select.Option>
                                            <Select.Option value="2">Cash</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Pan No."
                                        name="pan_no"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your pan no!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            style={{ width: "80%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="UAN No."
                                        name="uan_no"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please enter your uan no!',
                                    //     },
                                    // ]}
                                    >
                                        <Input
                                            size='large'
                                            style={{ width: "80%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <div style={{ marginTop: "10px", display: "flex", gap: "20px" }}>
                                <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} onClick={() => prev()} >Previous</Button>
                                <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} htmlType='submit' >Next</Button>
                            </div>
                        </Form>
                    </div>
            }

        </div>
    )
}

export default BankDetails