import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios'
import { useParams } from 'react-router-dom'
import { DatePicker, Switch, Timeline } from 'antd'
import mylocation from "../../../../assets/mylocation.png"
import {
    GoogleMap,
    LoadScript,
    Marker,
    Polyline,
    InfoWindow,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import dayjs from 'dayjs'

function TimeLine() {
    const param = useParams()

    const [employeeDetails, setEmployeeDetails] = useState({
        employee_name: "",
        designation: "",
        meeting_details: [],
        total_distance: "",
        total_duration: "",
        total_visits: "",
    })
    const [coordinates, setCoordinates] = useState([]);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

    const fetchEmployeeTimeLine = async () => {
        try {
            const response = await request({
                method: "get",
                url: `tracking/admin/employee-tracking/details?employement_number=${param.employement_number}&date=${date}`,
            })
            console.log("response", response.data);
            setEmployeeDetails({
                ...employeeDetails,
                ...response.data,
                meeting_details: response.data.meeting_details.map((item, index) => {
                    return {
                        dot: <div style={{ width: "24px", height: "24px", backgroundColor: "#008080", borderRadius: "50%", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</div>,
                        children: <div>
                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px", marginBottom: "5px" }}>{item.client_name}</p>
                            <div style={{ display: "flex", alignItems: "start", gap: "10px" }}>
                                <img src={mylocation} alt="mylocation" width={17} height={17} />
                                <p>{item.address}</p>
                            </div>
                        </div>
                    }
                })
            });
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const fetchMapCoordinates = async (employement_number) => {

        const apiUrl = `tracking/reporting-manager/employee/tracking-list?employee_id=${param.employement_number}&date=${date}`;
        try {
            const res = await request({
                method: "get",
                url: apiUrl,
            });

            console.log("dd", res.data[0].cordinates);
            setCoordinates(res.data[0].cordinates);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        fetchEmployeeTimeLine()
        fetchMapCoordinates()
    }, [date])

    return (
        <div>
            <div style={{ padding: "16px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <p>TimeLine</p>
                    <DatePicker
                        style={{
                            width: "130px"
                        }}
                        defaultValue={dayjs(new Date())}
                        onChange={(date) => setDate(dayjs(date).format("YYYY-MM-DD"))}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div style={{ width: "28px", height: "28px", backgroundColor: "#E6F9F5", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ color: "#008080" }}>{employeeDetails?.employee_name?.slice(0, 1).toUpperCase()}</span>
                        </div>
                        <div>
                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px", marginTop: "18px" }}>{employeeDetails?.employee_name}</p>
                            <p style={{ fontSize: "14px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>{employeeDetails?.designation}</p>
                        </div>
                    </div>
                    <div >
                        <Switch
                            size='medium'
                            onChange={() => setShowMap(!showMap)}
                        />
                        <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px", marginLeft: "10px" }}>Map view</span>
                    </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "7px", marginTop: "20px" }}>
                    <div style={{ backgroundColor: "#FAFAFA", padding: "10px", borderRadius: "5px" }}>
                        <p style={{ fontSize: "14px", fontWeight: "500", color: "#008080", margin: "0px" }}>{Math.ceil(employeeDetails?.total_distance * 100) / 100}</p>
                        <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>Dist. Travelled</span>
                    </div>
                    <div style={{ backgroundColor: "#FAFAFA", padding: "10px", borderRadius: "5px" }}>
                        <p style={{ fontSize: "14px", fontWeight: "500", color: "#008080", margin: "0px" }}>{employeeDetails?.total_duration}</p>
                        <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>Time Duration</span>
                    </div>
                    <div style={{ backgroundColor: "#FAFAFA", padding: "10px", borderRadius: "5px" }}>
                        <p style={{ fontSize: "14px", fontWeight: "500", color: "#008080", margin: "0px" }}>{employeeDetails?.total_visits}</p>
                        <span style={{ fontSize: "12px", fontWeight: "500", color: "#616161", margin: "0px", marginTop: "5px" }}>Client Visit</span>
                    </div>
                </div>
            </div>

            {
                showMap
                    ?
                    <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    >
                        <GoogleMap
                            mapContainerStyle={{ height: "550px", width: "100%" }}
                            center={coordinates && coordinates[0]} // Center the map around the first coordinate
                            zoom={6}
                        >
                            {coordinates.length >= 1 && (
                                <DirectionsService
                                    options={{
                                        origin: coordinates[0],
                                        destination: coordinates[coordinates.length - 1],
                                        waypoints: coordinates.slice(1, -1).map((point) => ({
                                            location: { lat: point.lat, lng: point.lng },
                                            stopover: true,
                                        })),
                                        travelMode: "DRIVING", // Driving mode for road route
                                    }}
                                    callback={(result, status) => {
                                        if (status === "OK") {
                                            setDirectionsResponse(result);
                                        } else {
                                            console.error(
                                                "Directions request failed due to " + status
                                            );
                                        }
                                    }}
                                />
                            )}
                            {directionsResponse && (
                                <DirectionsRenderer directions={directionsResponse} />
                            )}
                        </GoogleMap>
                    </LoadScript>

                    :

                    <div style={{ padding: "16px" }}>
                        <Timeline
                            items={employeeDetails?.meeting_details}
                        />
                    </div>
            }

        </div>
    )
}

export default TimeLine