import React, { useEffect, useState } from "react"
import styles from "./BonusAndSalaryRevision.module.scss"
import { Button, Col, Form, Input, Modal, Row, Select, Table, DatePicker } from "antd";
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import request from '../../../../../utils/axios';
import moment from "moment";
import { toast } from "react-toastify";
import CommonTable from "../../../../../component/CommonTable/CommonTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
function BonusAndSalaryRevison({ processingDate, setSelectProcessingType, reviewed, fetchReviewDetails }) {
    const selectedEmployees = useSelector((state) => state.selectedEmployeePayrollProcessing);
    const [bonusModal, setBonusModal] = useState(false)
    const [salaryIncrementsReview, setSalaryIncrementsReview] = useState([])
    const [bonusReview, setBonusReview] = React.useState()
    const [employeeAdvanceSalary, setEmployeeAdvanceSalary] = useState(false)
    const [hasMore1, setHasMore1] = useState(true);
    const [hasMore2, setHasMore2] = useState(true);
    const [hasMore3, setHasMore3] = useState(true);


    // Salary Increment Pagination
    const [salaryIncrementPagination, setSalaryIncrementPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const fetchSalaryIncrementsData = async () => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/salary-increments-data?page=${salaryIncrementPagination.current}&page_size=${salaryIncrementPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            setSalaryIncrementsReview([
                ...salaryIncrementsReview,
                ...data.pagination_data.data
            ])
            // if (salaryIncrementPagination.total !== data.pagination_data.total) {
            setSalaryIncrementPagination({
                ...salaryIncrementPagination,
                total: data.pagination_data.total,
                current: salaryIncrementPagination.current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore1(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore1(false);
        }
    }
    // useEffect(() => {
    //     fetchSalaryIncrementsData();
    // }, [salaryIncrementPagination.current])

    // Bonus Pagination
    const [bonusPagination, setBonusPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const fetchEmployeeBonusData = async (paginationParam, dataList) => {
        const { current } = paginationParam || bonusPagination;

        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-employees-bonus?page=${current}&page_size=${bonusPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if(paginationParam){
                setBonusReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else{
                setBonusReview([
                    ...bonusReview,
                    ...data.pagination_data.data
                ])
            }
            
            // if (bonusPagination.total !== data.pagination_data.total) {
            setBonusPagination({
                ...bonusPagination,
                total: data.pagination_data.total,
                current: bonusPagination.current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore3(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore3(false);
        }
    }
    // useEffect(() => {
    //     fetchEmployeeBonusData();
    // }, [])

    const onFinish = async (values) => {
        console.log("values : ", values)
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-employee-bonus`,
                data: {
                    ...employeeBonus,
                    // date: moment(values.date).format("YYYY-MM-DD")
                }

            })
            setBonusModal(false);
            const pagination = {
                current: 1,
                pageSize: 5, // Number of items per page
                total: 0,
            }
            const dataList = [];
            fetchEmployeeBonusData(pagination, dataList);
            // fetchEmployeeBonusData();
        }
        catch (err) {
            console.log(err)
        }

    }
    const onFinishAdvanceSalary = async (values) => {
        console.log("################ : ", EmployeeAdvanceSalaryData)
        try {
            const data = await request({
                method: "post",
                url: `loan/admin/create-employee-advance-salary`,
                data: {
                    ...EmployeeAdvanceSalaryData,
                    // date: moment(values.date).format("YYYY-MM-DD")
                }
            })
            setEmployeeAdvanceSalary(false);
            const pagination = {
                current: 1,
                pageSize: 5, // Number of items per page
                total: 0,
            }
            const dataList = [];
            fetchemployeesAdvanceSalaryHistory(pagination, dataList);
            // fetchemployeesAdvanceSalaryHistory();
            toast.success("New advance salary added successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log("error")
            toast.error("Advance salary details could not be added, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const [allEmployeesAdvanceSummary, setAllEmployeesAdvanceSummary] = useState([])

    // Advance Salary Pagination
    const [advanceSalaryPagination, setAdvanceSalaryPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const fetchemployeesAdvanceSalaryHistory = async (paginationParam, dataList) => {
        const { current } = paginationParam || advanceSalaryPagination;

        try {
            let data = await request({
                method: "get",
                url: `loan/admin/advance-salary-for-processed?page=${current}&page_size=${advanceSalaryPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if(paginationParam){
                setAllEmployeesAdvanceSummary([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else{
                setAllEmployeesAdvanceSummary([
                    ...allEmployeesAdvanceSummary,
                    ...data.pagination_data.data
                ])

            }
           
            // if (advanceSalaryPagination.total !== data.pagination_data.total) {
            setAdvanceSalaryPagination({
                ...advanceSalaryPagination,
                total: data.pagination_data.total,
                current: advanceSalaryPagination.current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore2(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore2(false);
        }
    }

    // useEffect(() => {
    //     fetchemployeesAdvanceSalaryHistory();
    // }, [])

    const bonusRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/delete-employee-bonus?id=${id}`,
            })
            console.log(data)
            fetchEmployeeBonusData();
        }
        catch (err) {
            console.log(err)
        }
    }
    const advanceSalaryRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "get",
                url: `loan/admin/delete-advance-salary?id=${id}`,
            })
            console.log(data)
            fetchemployeesAdvanceSalaryHistory();
        }
        catch (err) {
            console.log(err)
        }
    }
    // useEffect(() => {
    //     fetchSalaryIncrementsData();
    //     fetchemployeesAdvanceSalaryHistory();
    //     fetchEmployeeBonusData();
    // }, [processingDate])

    const [shouldFetch1, setShouldFetch1] = useState(false);
    const [shouldFetch2, setShouldFetch2] = useState(false);
    const [shouldFetch3, setShouldFetch3] = useState(false);


    useEffect(() => {
        // 1
        setSalaryIncrementPagination({
            ...salaryIncrementPagination,
            current: 1
        });
        setSalaryIncrementsReview([]);
        setHasMore1(true);
        setShouldFetch1(true);

        // 2
        setAdvanceSalaryPagination({
            ...advanceSalaryPagination,
            current: 1
        });
        setAllEmployeesAdvanceSummary([]);
        setHasMore2(true);
        setShouldFetch2(true);

        // 3
        setBonusPagination({
            ...bonusPagination,
            current: 1,
        });
        setBonusReview([]);
        setHasMore3(true);
        setShouldFetch3(true);
    }, [processingDate]);

    useEffect(() => {
        if (shouldFetch1) {
            fetchSalaryIncrementsData();
            setShouldFetch1(false);
        }
        if (shouldFetch2) {
            fetchemployeesAdvanceSalaryHistory();
            setShouldFetch2(false);
        }
        if (shouldFetch3) {
            fetchEmployeeBonusData();
            setShouldFetch3(false);
        }
    }, [shouldFetch1, shouldFetch2, shouldFetch3]);


    const salaryIncrementColumns = [
        {
            title: "Employee",
            dataIndex: "employement_number",
            key: "employement_number",
        },
        {
            title: "Old Salary",
            dataIndex: "previous_salary",
            key: "previous_salary",
        },
        {
            title: "New Salary",
            dataIndex: "current_salary",
            key: "current_salary",
        },
        {
            title: "Increment %",
            dataIndex: "percent_increase",
            key: "percent_increase",
        }
    ]
    const advanceSalaryColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Employee Number',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Issue Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <DeleteOutlined style={{ color: 'red', fontSize: '20px' }}
                onClick={() => advanceSalaryRecordDelete(record.id)} />,


        }

    ]
    const bonusColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Bonus Type",
            dataIndex: "bonus_type",
            key: "bonus_type",
        },
        {
            title: "Payout Date",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <DeleteOutlined style={{ color: 'red', fontSize: '20px' }}
                onClick={() => bonusRecordDelete(record.id)} />,
        }
    ]

    const bonusAndSalaryRevisonTabs = [
        {
            name: "salary Increments",
            key: "salaryIncrements",
            value: 1,
            reviewed: reviewed.salary_increments
        },
        {
            name: "Advance Salary",
            key: "advanceSalary",
            value: 2,
            reviewed: reviewed.advance_salary
        },
        {
            name: "Bonus",
            key: "bonus",
            value: 3,
            reviewed: reviewed.bonus
        }
    ]


    const [selectTab, setSelectTab] = useState(1)
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const [employeeBonus, setemployeeBonus] = useState({
        employement_number: "",
        name: "",
        amount: "",
        bonus_type: "",
        date: ""
    })
    const [EmployeeAdvanceSalaryData, setEmployeeAdvanceSalaryData] = useState({
        employement_number: "",
        name: "",
        amount: "",
        issue_date: "",
    })
    const onHandleBonusChange = (key, value) => {
        setemployeeBonus({
            ...employeeBonus,
            [key]: value
        });
    }
    const searchForEmployeeBonus = async (data) => {
        onHandleBonusChange('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }
    const handleSelectChangeForEmployeeBonus = async (employement_number, name) => {
        try {
            setemployeeBonus({
                ...employeeBonus,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }
    const onHandleChangeForAdvance = (key, value) => {
        setEmployeeAdvanceSalaryData({
            ...EmployeeAdvanceSalaryData,
            [key]: value
        });
    }
    const handleSelectChangeForAdvanceSalary = async (employement_number, name) => {
        try {
            setEmployeeAdvanceSalaryData({
                ...EmployeeAdvanceSalaryData,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }
    const searchForAdvanceSalary = async (data) => {
        onHandleChangeForAdvance('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }

    const payrollSummaryReviewed = async () => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-review-history`,
                data: {
                    payroll_review_tab: "bonusSalaryRevision",
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
            setSelectProcessingType("reimbursement")
        }
        catch (error) {
            console.log(error)
        }
    }

    const payrollSummaryChild = async (childName) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-tabs-history`,
                data: {
                    parent_tab: "bonusSalaryRevision",
                    child_tab: childName,
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
        }
        catch (error) {
            console.log(error)
        }
    }

    const renderUI1 = (dataList) => {
        return <Table
            columns={salaryIncrementColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI2 = (dataList) => {
        return <Table
            columns={advanceSalaryColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI3 = (dataList) => {
        return <Table
            columns={bonusColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    return (
        <div className={styles.bonusSalaryRevisionContainer}>
            <div className={styles.container}>
                <div className={styles.tabButtonContainer}>
                    {
                        bonusAndSalaryRevisonTabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={
                                        item?.reviewed
                                            ?
                                            styles.viewedTabButton + " " + styles.tabButton
                                            :
                                            selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}

                                >
                                    <div className={
                                        item?.reviewed
                                            ?
                                            styles.viewedIndexBox
                                            :
                                            selectTab === index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                item?.reviewed
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    // selectTab > index + 1
                                                    //     ?
                                                    //     <CheckOutlined />
                                                    //     :
                                                    item.value
                                            }

                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            {/* <CommonTable
                                columns={salaryIncrementColumns}
                                data={salaryIncrementsReview}
                                pagination={salaryIncrementPagination}
                                setPagination={setSalaryIncrementPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("salary_increments")
                                        setSelectTab(2)
                                    }}>Next</button>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={salaryIncrementPagination.total - (salaryIncrementsReview?.length)}
                                    next={fetchSalaryIncrementsData}
                                    hasMore={hasMore1}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI1(salaryIncrementsReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 2
                        &&

                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    style={{ background: "#008080", color: "white", border: "none", padding: "0px 20px", borderRadius: "5px", height: "40px", marginBottom: "20px" }}
                                    onClick={() => setEmployeeAdvanceSalary(true)}>Create</button>

                                <div>
                                    <button
                                        className={styles.btn}
                                        onClick={() => setSelectTab(1)}
                                    >Previous</button>
                                    <button
                                        className={styles.btn}
                                        onClick={() => {
                                            payrollSummaryChild("advance_salary")
                                            setSelectTab(3)
                                        }}
                                    >Next</button>
                                </div>
                            </div>
                            {/* <CommonTable
                                columns={advanceSalaryColumns}
                                data={allEmployeesAdvanceSummary}
                                pagination={advanceSalaryPagination}
                                setPagination={setAdvanceSalaryPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={salaryIncrementPagination.total - (allEmployeesAdvanceSummary?.length)}
                                    next={fetchemployeesAdvanceSalaryHistory}
                                    hasMore={hasMore2}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI2(allEmployeesAdvanceSummary)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 3
                        &&
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    style={{ background: "#008080", color: "white", border: "none", padding: "0px 20px", borderRadius: "5px", height: "40px", marginBottom: "20px" }}
                                    onClick={() => setBonusModal(true)}>Create</button>

                                <div>
                                    <button
                                        className={styles.btn}
                                        onClick={() => setSelectTab(2)}
                                    >Previous</button>

                                    <button className={styles.btn} onClick={() => {
                                        payrollSummaryChild("bonus")
                                        payrollSummaryReviewed()
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                            {/* <CommonTable
                                columns={bonusColumns}
                                data={bonusReview}
                                pagination={bonusPagination}
                                setPagination={setBonusPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={bonusPagination.total - (bonusReview?.length)}
                                    next={fetchEmployeeBonusData}
                                    hasMore={hasMore3}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI3(bonusReview)
                                    }
                                </InfiniteScroll>
                            </div>



                        </>
                    }
                </div>
            </div>
            <Modal
                title="Advance Salary"
                centered
                open={employeeAdvanceSalary}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setEmployeeAdvanceSalary(false)}
                width={1000}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={onFinishAdvanceSalary} >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={EmployeeAdvanceSalaryData.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForAdvanceSalary(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForAdvanceSalary(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChangeForAdvance("amount", e.target.value)}
                                    value={EmployeeAdvanceSalaryData.amount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="issue_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChangeForAdvance("issue_date", dateString)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>

            <Modal
                title="Add Bonus"
                centered
                open={bonusModal}
                onCancel={() => setBonusModal(false)}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={employeeBonus.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForEmployeeBonus(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForEmployeeBonus(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleBonusChange("date", dateString)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Bonus type"
                                name="bonus_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter bonus type!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter bonus type"
                                    onChange={(e) => onHandleBonusChange("bonus_type", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleBonusChange("amount", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>



        </div>
    )

}
export default BonusAndSalaryRevison