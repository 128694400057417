import React, { useEffect, useRef, useState } from 'react';
import getScreenSize from '../../../../utils/getScreenSize';
import add from "../../../../assets/Org/add.svg"
import downloadImg from "../../../../assets/downlaod.svg";
import back from "../../../../assets/Recruitment/back.svg"
import { Col, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import request from '../../../../utils/axios';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function LoanFullView() {
    const ref = useRef(null);
    const screenSize = getScreenSize();
    const navigate = useNavigate();
    const loanColumns = [
        {
            title: 'Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
        // {
        //   title: 'Year',
        //   dataIndex: 'date',
        //   key: 'date',
        // },
        {
            title: 'Loan Amount ',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Tenure',
            dataIndex: 'tenure',
            key: 'tenure',
        },
        {
            title: 'Interest ',
            dataIndex: 'interest',
            key: 'interest',
        },
    ]

    const [employeeLoanSummary, setEmployeeLoanSummary] = useState()

    const fetchemployeeLoanSummary = async () => {
        try {
            let data = await request({
                method: "get",
                url: `loan/get-employee-loan-history?page=1&page_size=5`,
            })
            console.log(data)
            setEmployeeLoanSummary([...data.pagination_data.data])
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        fetchemployeeLoanSummary()
    }, []);

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <div>
                    <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                        <Row>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Month</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>₹ {item.tenure}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Year</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.issue_date}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Loan Amount</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.amount}</p>
                            </Col>
                            <Col span={8}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Interest</p>
                            </Col>
                            <Col span={16}>
                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.interest}</p>
                            </Col>

                        </Row>
                    </div>
                    {
                        index !== employeeLoanSummary.length - 1 && <hr />
                    }

                </div>
            )
        })
    }

    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?

                    <div style={{ background: "white", borderRadius: "4px" }}>
                        <div className='flexSpaceBetween' style={{ padding: "0px 16px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Loan</p>
                            {/* <div style={{ display: "flex", gap: "20px" }}>
                                <img src={arrowCircle} alt="arrowCircle" />
                            </div> */}
                        </div>
                        <InfiniteScrollComponent
                            apiEndpoint="loan/get-employee-loan-history"
                            renderUI={renderUI}
                            initialPage={1}
                        />

                    </div>

                    :

                    <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p> */}
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Loan</p>
                            <div style={{ display: "flex", gap: "20px" }}>
                                {/* <img src={add} alt="add" width={35} height={35} /> */}
                                {/* <img src={arrowCircle} alt="arrowCircle" /> */}
                            </div>
                        </div>
                        <Table
                            columns={loanColumns}
                            dataSource={employeeLoanSummary}
                            pagination={false}
                        />
                    </div>
            }

        </div>
    )
}

export default LoanFullView