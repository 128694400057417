import React, {useEffect, useState} from 'react';
import MeetingForm from './EditMeetingForm';
import {useNavigate, useParams} from 'react-router-dom';
import back from '../../../assets/Recruitment/back.svg';
import getScreenSize from '../../../utils/getScreenSize';
import {toast} from 'react-toastify';
import request from '../../../utils/axios';
const EditMeeting = () => {
  const screenSize = getScreenSize();
  const {type, id} = useParams();
  const [userRecord, setUserRecord] = useState();
  console.log(type, id);
  const navigate = useNavigate();
  const loadUserData = async id => {
    try {
      const apiUrl = `tracking/employee/meeting/edit-details?id=${id}`;
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      setUserRecord(res.data);
      console.log(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  useEffect(() => {
    if (id) {
      loadUserData(id);
    }
  }, [id]);

  return (
    <>
      {screenSize.width < 768 ? (
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}>
          {userRecord && <MeetingForm MeetingType={type} record={userRecord} />}
        </div>
      ) : (
        <div style={{padding: '50px'}}>
          <p
            onClick={() => {
              navigate(-1);
            }}
            style={{cursor: 'pointer'}}>
            <img src={back} alt="back" />
            <span style={{paddingLeft: '10px'}}>Back</span>
          </p>

          <p style={{fontSize: '32px', font: 500}}>{'Edit Meeting'}</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}>
            {userRecord && (
              <MeetingForm MeetingType={type} record={userRecord} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EditMeeting;
