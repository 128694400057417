import React, { useEffect, useRef, useState } from "react";
import "./MyTeam.scss";
import { Button, Col, DatePicker, Form, Input, Modal, Popover, Row, Select, Switch, Table, TimePicker } from "antd";
import moment from "moment";
import edit from "../../../../../assets/edit.svg";
import fullView from "../../../../../assets/fullView.svg";
import CalendarScheduler from "../../../../../component/CalendarScheduler/CalendarScheduler";
import { SearchOutlined } from "@ant-design/icons";
import request from "../../../../../utils/axios";
import dayjs from "dayjs";
import getScreenSize from "../../../../../utils/getScreenSize";
import { useNavigate } from "react-router-dom";
import LogsSearchBar from "../LogsSearchBar/LogsSearchBar";
import Vector from '../../../../../assets/Vector.svg';
import CalendarSearchBar from "../CalendarSearchBar/CalendarSearchBar";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
function MyTeam() {
  const ref = useRef(null);
  const { RangePicker } = DatePicker;

  const [openModal, setOpenModal] = useState(false);

  const [regularizationForm] = Form.useForm();

  const [selectedDate, setSelectedDate] = useState(new Date())

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)

  const [selectedShift, setSelectedShift] = useState(null)


  const detailsColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Attendance Policy",
      dataIndex: "work_mode",
      key: "work_mode",
    },
    {
      title: "On Time %",
      dataIndex: "on_time_percent",
      key: "on_time_percent",
    },
    {
      title: "Leaves",
      dataIndex: "total_absent_days",
      key: "total_absent_days",
    },
    // {
    //   title: "Edit",
    //   dataIndex: "edit",
    //   key: "edit",
    //   render: (text, record) => <img src={edit} alt="edit" />,
    // },
  ];

  const logsColumns = [
    {
      title: 'Id',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: ' Date',
      dataIndex: 'attendance_date',
      key: 'attendance_date',
    },
    {
      title: 'Employee',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Role/Department',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => <>
        {
          record.designation + " / " + record.department
        }
      </>
    },
    {
      title: ' Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <>
        {
          text === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "On Leave" && <span style={{ backgroundColor: record.color, padding: "5px 10px", color: "white", borderRadius: "5px" }}>{record.leave_type}</span>
        }
        {
          text === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>Pending</span>
        }
        {
          text === "Late ClockIn" && <span style={{ backgroundColor: "#f53444", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Early Departure" && <span style={{ backgroundColor: "rgb(0, 96, 255)", padding: "5px 10px", color: "rgb(233, 241, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Short Duration</span>
        }
      </>
    },
    {
      title: 'Check-in',
      dataIndex: 'check_in_time',
      key: 'check_in_time',
    },
    {
      title: 'Check-out',
      dataIndex: 'check_out_time',
      key: 'check_out_time',
      render: (text, record) => <span>
        {
          text === null ? "Pending..." : text === "no_checkout" ? "No Check Out" : text
        }
      </span>
    },
    {
      title: 'Work hours',
      dataIndex: 'working_duration',
      key: 'working_duration',
    },
    // {
    //   title: "Comment",
    //   dataIndex: "comment",
    //   key: "comment",
    //   render: (text, record) => (
    //     <span>
    //       {text ? (
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <p>{text}</p>
    //           <img src={edit} alt="edit" />{" "}
    //         </div>
    //       ) : (
    //         <span
    //           style={{
    //             color: "#3E3D3D",
    //             backgroundColor: "#EBEBEB",
    //             padding: "10px",
    //             borderRadius: "3px",
    //             fontSize: "13px",
    //           }}
    //         >
    //           Add Comment
    //         </span>
    //       )}
    //     </span>
    //   ),
    // },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          {
            !record.is_holiday && !record.is_weekly_holiday && !record.on_leave && !record.is_pending &&

            <Button className="button"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setSelectedDate(record.attendance_date)
                setSelectedEmployeeId(record.employee_id)
                setSelectedShift(record.shift_type)
                setOpenModal(true)
              }}>
              Regularise
            </Button>


          }
        </div>)
    },

  ];

  const screenSize = getScreenSize();
  const [logsDetails, setLogsDetails] = useState();
  // const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [days, setDays] = useState(7);
  const [open, setOpen] = useState(false);


  const [calendarView, setCalendarView] = useState(false);
  const [detailList, setDetailList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [myTeamAttendanceDetails, setMyTeamAttendanceDetails] = useState({
    on_late: "",
    on_time: "",
    absent_count: "",
    present_count: "",
  });
  const [calendarData, setCalendarData] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [specificDateForLogs, setSpecificDateForLogs] = useState("");
  const [page, setPage] = useState(1);
  const [teamList, setTeamList] = useState([]);
  const page_size = 5;
  const [calendarMonth, setCalendarMonth] = useState('');
  const [calendarYear, setCalendarYear] = useState('');
  const navigate = useNavigate();
  const handleChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setDays(value);
    }
  };
  const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });
  const [attendanceReportDays, setAttendanceReportDays] = useState(7);
  const [dateRange, setDateRange] = useState([]);
  const handleSwitchChange = (checked) => {
    setCalendarView(checked);
  };

  const regularizeRequest = async (values) => {
    console.log("selectedDate........", selectedDate)
    // console.log("SelectedDate",dayjs(selectedDate, "DD-MM-YYYY").format("YYYY-DD-MM"))
    console.log("values .....", values);
    const data = await request({
      method: "post",
      url: `attendance/manager/regularization/apply?shift=${selectedShift}&employement_number=${selectedEmployeeId}`,
      data: {
        // attendance_date: dayjs(selectedDate, "DD-MM-YYYY").format("YYYY-DD-MM"),
        attendance_date: selectedDate,
        ...values,
        check_in_time: dayjs(values.check_in_time).format(" HH:mm:ss"),
        check_out_time: values.check_out_time ? dayjs(values.check_out_time).format(" HH:mm:ss") : null,

      },
    });
    setOpenModal(false);
    fetchLogsData();
    regularizationForm.resetFields();
    toast.success("Regularization request submitted successfully", {
      position: "bottom-left",
    });
  }

  const fetchEmployeeList = async () => {
    const data = await request({
      method: "get",
      url: `attendance/team/employees/all/?page=${page}&page_size=${page_size}`,
    });
    if (data) {
      setDetailList(data?.pagination_data?.data);
    }
    // setLoading(false)
  };
  // const fetchTableLogs = async () => {
  //   const data = await request({
  //     method: "get",
  //     url: `attendance/employee/attendance/details/?days=${days}`,
  //   });
  //   setTableData(data?.data?.attendance_data);

  //   // setLoading(false)
  // };

  const myTeamEmployee = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/employees/`,
    });

    setEmployeeList(data);
  };

  const myTeamDetail = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/widget/`,
    });

    setMyTeamAttendanceDetails({
      ...myTeamAttendanceDetails,
      absent_count: data?.absent_count,
      present_count: data?.present_count,
      on_late: data?.on_late,
      on_time: data?.on_time,
      work_from_home: data?.work_from_home,
      on_holiday: data?.on_holiday,
    });
  };

  const fetchCalendarData = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/employee/calender/monthly/?employement_number=${employeeId}&month=${calendarMonth}&year=${calendarYear}`,
    });
    if (data && data?.data && data?.data?.monthly_attendance) {
      setCalendarData(data?.data?.monthly_attendance);
    }
  };

  const handleSelectChange = (value) => {
    setEmployeeId(value);
  };

  const fetchLogsData = async () => {
    if (attendanceReportDays === "custom") {
      if (attendanceReportCustomDate.start_date && attendanceReportCustomDate.end_date) {
        const data = await request({
          method: "get",
          url: `attendance/my-team/employees/attendance/details/?page=${page}&page_size=${page_size}&start_date=${attendanceReportCustomDate.start_date}&end_date=${attendanceReportCustomDate.end_date}`,
        });
        if (data && data?.pagination_data) {
          setLogsDetails(data?.pagination_data?.data);
        }
      }
    } else {
      const data = await request({
        method: "get",
        url: `attendance/my-team/employees/attendance/details/?page=${page}&page_size=${page_size}&days=${attendanceReportDays}`,
      });
      if (data && data?.pagination_data) {
        setLogsDetails(data?.pagination_data?.data);
      }
    }
  };


  const fetchTeamList = async () => {
    const data = await request({
      method: "get",
      url: `attendance/team/employees/all/?page=${page}&page_size=${page_size}`,
    });
    if (data) {
      setTeamList(data?.pagination_data?.data);
    }
    // setLoading(false)
  };

  useEffect(() => {
    myTeamDetail();
    fetchEmployeeList();
    myTeamEmployee();
    fetchTeamList();
    // fetchTableLogs();
  }, []);

  useEffect(() => {
    if (calendarView) {
      fetchCalendarData();
      // myTeamEmployee();
    } else {
      setCalendarData([]);
      setEmployeeId("");
    }
  }, [calendarView, employeeId, calendarMonth, calendarYear]);

  const handleDateChange = (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setDateRange(value);
    setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
  };

  useEffect(() => {
    fetchLogsData();
  }, [specificDateForLogs, attendanceReportDays, attendanceReportCustomDate.start_date, attendanceReportCustomDate.end_date]);

  // useEffect(() => {
  //   ref?.current?.scrollIntoView({ behavior: 'instant' })
  //   document.body.scrollTop = document.documentElement.scrollTop = 0;
  // }, []);



  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  const handleDaysChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setAttendanceReportDays(value);
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setAttendanceReportDays(value);
    }
  };

  return (
    <div ref={ref}>
      <div className="myTeamContainer" >


        {screenSize.width < 768 ? (
          <>
            <div style={{ padding: "0px 16px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                  alignSelf: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    color: "#161616",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  My Team
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                  alignSelf: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    width: "45%",
                    backgroundColor: "#f2f2f2",
                  }}
                  className="borderred"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    Late Arrival Today
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      margin: "0px",
                    }}
                  >
                    {myTeamAttendanceDetails?.on_late}
                  </p>
                </div>
                <div
                  style={{
                    padding: "20px",
                    width: "45%",
                    backgroundColor: "#f2f2f2",
                  }}
                  className="bordergreen"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    On Time Today
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      margin: "0px",
                    }}
                  >
                    {myTeamAttendanceDetails?.on_time}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                  alignSelf: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    width: "45%",
                    backgroundColor: "#f2f2f2",
                  }}
                  className="borderblue"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    Work From Home
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      margin: "0px",
                    }}
                  >
                    {myTeamAttendanceDetails?.work_from_home}
                  </p>
                </div>
                <div
                  style={{
                    padding: "20px",
                    width: "45%",
                    backgroundColor: "#f2f2f2",
                  }}
                  className="borderpink"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    Total Absent
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      margin: "0px",
                    }}
                  >
                    {myTeamAttendanceDetails?.absent_count}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                  alignSelf: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    width: "45%",
                    backgroundColor: "#f2f2f2",
                  }}
                  className="borderpurple"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    Total Present
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      margin: "0px",
                    }}
                  >
                    {myTeamAttendanceDetails?.present_count}
                  </p>
                </div>
                <div
                  style={{
                    padding: "20px",
                    width: "45%",
                    backgroundColor: "#f2f2f2",
                  }}
                  className="bordergreen2"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    On Holiday
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      margin: "0px",
                    }}
                  >
                    {myTeamAttendanceDetails?.on_holiday}
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "20px",
              }}
            />

            <div
              style={{
                // padding: "10px",
                backgroundColor: "white",
                marginTop: "20px",

              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 16px"
                }}
              >
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Attendance Details</p>
                <img
                  src={fullView}
                  alt="fullView"
                  width={35}
                  height={35}
                  style={{ marginLeft: "20px" }}
                  onClick={() => navigate("/attendance/my-team/details", { replace: true })}
                />
              </div>

              {teamList?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        alignSelf: "center",
                        marginTop: "15px",
                        padding: "0px 16px"
                      }}
                    >
                      <h2
                        style={{
                          fontSize: 18,
                          color: "black",
                        }}
                      >
                        {item?.employee_name}
                      </h2>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          Attendance Policy
                        </p>
                        <p
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: 16,
                            color: "#8A8A8A",
                          }}
                        >
                          {item?.work_mode}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          On Time %
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            color: "#8A8A8A",
                          }}
                        >
                          {item?.on_time_percent}%
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          Leaves
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            color: "#8A8A8A",
                          }}
                        >
                          {item?.total_leaves_taken}
                        </p>
                      </div>
                    </div>
                    {
                      index !== teamList?.length - 1 && <hr />
                    }
                  </>
                );
              })}
            </div>

            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",

              }}
            />

            <div className="logsContainer" style={{ padding: "0px 16px" }}>
              <div
                className="flexSpaceBetween"
                style={{ marginBottom: "30px", width: "100%" }}
              >
                <span style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Logs</span>
                <img
                  src={fullView}
                  alt="fullView"
                  width={35}
                  height={35}
                  style={{ marginLeft: "20px" }}
                  onClick={() => navigate("/attendance/my-team/logs", { replace: true })}
                />
              </div>

              <div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                  <Switch
                    checked={calendarView}
                    onChange={handleSwitchChange}
                  />
                  <p style={{ fontSize: "16px", margin: "0px" }}>
                    {!calendarView ? "Calendar View" : "List View"}
                  </p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  {calendarView ? (
                    <CalendarSearchBar
                      setEmployeeId={setEmployeeId}
                      setCalendarData={setCalendarData}
                      empType="my_team"
                    />
                  ) : (
                    <>
                      {/* <DatePicker
                      className="datePicker"
                      onChange={(value) => {
                        handleDateChange(value);
                      }}
                      style={{ width: "100%", height: "40px" }}
                    /> */}
                      <Select
                        style={{ width: "100%", height: 40 }}
                        placeholder="Select an option"
                        onChange={handleDaysChange}
                        value={attendanceReportDays}
                      >
                        <Select.Option value={7}>Last 7 days</Select.Option>
                        <Select.Option value={14}>Last 14 days</Select.Option>
                        <Select.Option value={30}>Last 30 days</Select.Option>
                        <Select.Option value="custom">
                          <span
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            Custom Date
                          </span>
                        </Select.Option>
                      </Select>

                      <div style={{ marginTop: "20px" }}>
                        <LogsSearchBar
                          setTableDataForSearchEmployee={setLogsDetails}
                          fetchTableDataFromApi={fetchLogsData}
                          placeholderText="Search for employees"
                          empType="my_team"
                        />
                      </div>
                    </>
                  )}
                </div>

                {calendarView ? (
                  <CalendarScheduler
                    data={calendarData}
                    employeeId={employeeId}
                    setCalendarMonth={setCalendarMonth}
                    setCalendarYear={setCalendarYear}
                  />
                ) : (
                  <div >
                    {logsDetails?.map((item, index) => {
                      return (
                        <div>
                          <p
                            style={{
                              fontSize: "18px",
                              fontFamily: "Poppins-Medium",
                              color: "black",
                              marginTop: "5px",
                            }}
                          >
                            {item?.attendance_date}
                          </p>
                          <p style={{ fontSize: "16px" }}>
                            {item.employee_name}
                          </p>
                          <p style={{ fontSize: "16px" }}>
                            {item.department}
                          </p>
                          {item?.is_absent ||
                            item?.is_holiday ||
                            item?.is_weekly_holiday
                            ?
                            (
                              <div>
                                <p>
                                  {item?.is_holiday ? (
                                    item?.is_holidayName
                                  ) : item?.is_weekly_holiday ? (
                                    <div className="weeklyOff">
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          color: "#008080",
                                          padding: "4px",
                                          fontWeight: 400,
                                          textAlign: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        Weekly Off
                                      </p>
                                    </div>
                                  ) : (
                                    <div style={{
                                      display: "flex",
                                      padding: "0px 8px",
                                      border: "1px solid red",
                                      borderRadius: "21px",
                                      height: "fit-content",
                                      width: "fit-content",
                                      lineHeight: "1px",
                                    }}>
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          color: "red",
                                          padding: "4px",
                                          fontWeight: 400,
                                          textAlign: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        {item?.is_absent && "Absent"}
                                      </p>
                                    </div>
                                  )}
                                </p>
                              </div>
                            )
                            :
                            (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Poppins-Regular",
                                      color: "black",
                                    }}
                                  >
                                    Shift 1
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontFamily: "Poppins-Regular",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                        color: "#8A8A8A",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {item.check_in_time}

                                    </p>
                                    <p style={{ fontFamily: "Poppins-Regular", fontSize: "12px", color: "#8A8A8A" }}>- - -</p>
                                    <p
                                      style={{
                                        fontFamily: "Poppins-Regular",
                                        fontSize: "12px",
                                        color: "#8A8A8A",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {item.check_out_time === null ? "Pending..." : item.check_out_time === "no_checkout" ? "No Check Out" : item.check_out_time}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    alt="noimg"
                                    src={Vector}
                                    style={{
                                      width: "11px",
                                      height: "15px",
                                      marginBottom: "10px",
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontFamily: "Poppins-Regular",
                                      fontSize: "12px",
                                      color: "#616161",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {item?.location}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Poppins-Regular",
                                      color: "black",
                                    }}
                                  >
                                    Comment
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "Poppins-Regular",
                                      color: "#8A8A8A",
                                    }}
                                  >
                                    {item?.comment}
                                  </p>
                                </div>
                              </>
                            )}
                          <hr
                            style={{
                              height: "1px",
                              backgroundColor: "#F0F0F0",
                              border: "none",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>
            <p className="heading">My Team</p>
            <Row gutter={[32, 32]}>
              <Col lg={8} xs={12}>
                <div className="lateArrivalsToday">
                  <div className="innerDiv">
                    <p className="cardTitle">Late Arrivals Today</p>
                    <br />
                    <span className="cardValue">
                      {myTeamAttendanceDetails?.on_late}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="onTimeToday">
                  <div className="innerDiv">
                    <p className="cardTitle">On Time Today</p>
                    <br />
                    <span className="cardValue">
                      {myTeamAttendanceDetails.on_time}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="workFromHome">
                  <div className="innerDiv">
                    <p className="cardTitle">Work From Home</p>
                    <br />
                    <span className="cardValue">
                      {myTeamAttendanceDetails.work_from_home}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[32, 32]} style={{ marginTop: "30px" }}>
              <Col lg={8}>
                <div className="totalAbsent">
                  <div className="innerDiv">
                    <p className="cardTitle">Total Absent</p>
                    <br />
                    <span className="cardValue">
                      {myTeamAttendanceDetails.absent_count}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="totalPresent">
                  <div className="innerDiv">
                    <p className="cardTitle">Total Present</p>
                    <br />
                    <span className="cardValue">
                      {myTeamAttendanceDetails.present_count}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="onHoliday">
                  <div className="innerDiv">
                    <p className="cardTitle">On Holiday</p>
                    <br />
                    <span className="cardValue">
                      {myTeamAttendanceDetails.on_holiday}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <div
              style={{
                padding: "30px",
                backgroundColor: "white",
                marginTop: "60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "28px", fontWeight: "600" }}>Attendance Details</p>
                <img
                  src={fullView}
                  alt="fullView"
                  width={35}
                  height={35}
                  style={{ marginLeft: "20px" }}
                  onClick={() => navigate("/attendance/my-team/details", { replace: true })}
                />
              </div>
              <Table
                columns={detailsColumns}
                dataSource={detailList}
                // loading={loading}
                pagination={false}
              />
            </div>



            <div style={{ padding: "30px", backgroundColor: "white", marginTop: "30px" }}>
              <div className="logsContainer">
                <div className="flexSpaceBetween">
                  <div
                    className="flexSpaceBetween"
                    style={{ marginBottom: "30px" }}
                  >
                    <span className="logs">Logs</span>
                    <Switch checked={calendarView} onChange={handleSwitchChange} />
                    <span className="calendarViewText">Calendar View</span>
                  </div>
                </div>
                <div
                  className="flexSpaceBetween"
                  style={{ marginBottom: "30px" }}
                >


                  <div style={{ width: "100%" }}>
                    <div className="flexSpaceBetween">
                      {calendarView ? (
                        // <Select
                        //   className="dropDown"
                        //   placeholder="Select a person"
                        //   onChange={handleSelectChange}
                        // >
                        //   {employeeList?.map((item, index) => {
                        //     return (
                        //       <Select.Option
                        //         key={index}
                        //         value={item.employement_number}
                        //       >
                        //         {item.employee_name}
                        //       </Select.Option>
                        //     );
                        //   })}
                        // </Select>
                        <>
                          <CalendarSearchBar
                            setEmployeeId={setEmployeeId}
                            setCalendarData={setCalendarData}
                            empType="my_team"
                          />
                          <img
                            src={fullView}
                            alt="fullView"
                            width={35}
                            height={35}
                            style={{ marginLeft: "20px" }}
                            onClick={() => navigate("/attendance/my-team/logs", { replace: true })}
                          />
                        </>

                      ) : (
                        <>
                          <LogsSearchBar
                            setTableDataForSearchEmployee={setLogsDetails}
                            fetchTableDataFromApi={fetchLogsData}
                            placeholderText="Search for employees"
                            empType="my_team"
                          />
                          <Select
                            style={{ width: "300px", height: 40 }}
                            placeholder="Select an option"
                            onChange={handleDaysChange}
                            value={attendanceReportDays}
                          >
                            <Select.Option value={7}>Last 7 days</Select.Option>
                            <Select.Option value={14}>Last 14 days</Select.Option>
                            <Select.Option value={30}>Last 30 days</Select.Option>
                            <Select.Option value="custom">
                              <span
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                Custom Date
                              </span>
                            </Select.Option>
                          </Select>
                          <img
                            src={fullView}
                            alt="fullView"
                            width={35}
                            height={35}
                            style={{ marginLeft: "20px" }}
                            onClick={() => navigate("/attendance/my-team/logs", { replace: true })}
                          />
                        </>
                      )}

                    </div>

                  </div>
                </div>
                {calendarView ? (
                  <CalendarScheduler
                    data={calendarData}
                    employeeId={employeeId}
                    setCalendarMonth={setCalendarMonth}
                    setCalendarYear={setCalendarYear}
                  />
                ) : (
                  <Table
                    columns={logsColumns}
                    dataSource={logsDetails}
                    // loading={loading}
                    pagination={false}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <Modal
          title="Select Date Range"
          centered
          open={open}
          onCancel={() => setOpen(false)}
          onOk={async () => {

            setOpen(false);
          }}
          footer={null}
        >
          <RangePicker
            value={dateRange}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
            style={{ color: "black !important" }}
          />
        </Modal>

        {/* regularization request */}
        <Modal
          centered
          open={openModal}
          footer={null}
          onCancel={() => {
            setOpenModal(false);
            setSelectedEmployeeId(null);
            setSelectedShift(null);
            regularizationForm.resetFields();
          }}

        >
          <p style={{ fontSize: "20px", lineHeight: "30px", fontWeight: "500" }}>Regularisation Request</p>
          <Form
            form={regularizationForm}
            onFinish={regularizeRequest}
            layout="vertical"
          // fields={[
          //   {
          //     name: ['start_date'],
          //     value: GetJoiningDateForEmployee ? dayjs(GetJoiningDateForEmployee) : null
          //   }
          // ]}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label="Check In"
                  name="check_in_time"
                  rules={[
                    { required: true, message: "Required" },
                  ]}
                >
                  <TimePicker
                    hourStep={1}
                    minuteStep={15}
                    format="HH:mm"
                    // placeholder="-ve Earliest Time Allowed"
                    size='large'
                    style={{ width: "100%" }}
                    onChange={(time, timeString) => {
                      console.log(time, "time.....................");
                      // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                      // const totalMinutes = hours * 60 + minutes;
                    }}
                  />
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item
                  label="Check Out"
                  name="check_out_time"
                >
                  <TimePicker
                    hourStep={1}
                    minuteStep={15}
                    format="HH:mm"
                    // placeholder="-ve Earliest Time Allowed"
                    size='large'
                    style={{ width: "100%" }}
                    onChange={(time, timeString) => {
                      console.log(time, "time.....................22222222222222");
                      // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                      // const totalMinutes = hours * 60 + minutes;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please add Reason!",
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  placeholder="Enter Your Reason"
                  className="leaveTextArea"
                  onChange={(e) =>
                    "reason"
                    // handleFormChange("reason", e.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Button
              htmlType="submit"
              className="forgotPasswordButton"
              style={{ fontSize: "14px" }}
            >
              Update
            </Button>
          </Form>
        </Modal>


      </div>
    </div>
  );
}

export default MyTeam;
