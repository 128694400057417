import React, { useState, useEffect, useRef } from "react";
import {
  TimePicker,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import mapLocation from "../../../assets/location.svg";
import "./meeting.scss";
import request from "../../../utils/axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  WarningFilled,
} from "@ant-design/icons";
import getScreenSize from "../../../utils/getScreenSize";
import { toast } from "react-toastify";
const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const containerStyle = {
  width: "100%",
  height: "400px",
};

const inputStyle = {
  width: "100%",
  padding: "0.5rem",
  boxSizing: "border-box",
  marginBottom: "10px",
  borderRadius: "8px",
  borderColor: "#d9d9d9", // Corrected property name
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
const MeetingForm = ({ MeetingType }) => {
  const screenSize = getScreenSize();
  console.log(MeetingType);
  const format = "HH:mm"; // Define the time format for TimePicker
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const autocompleteRef = useRef(null);
  const [tabs, setTabs] = useState(1);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [EmployeeDetails, setEmployeeDetails] = useState({
    employement_number: "",
    employeeName: "",
    ClientName: "",
    AddLocation: "",
    date: "",
    endTime: "",
    startTime: "",
    description: "",
    latitude: "",
    longitude: "",
  });
  const [selectedAddress, setSelectedAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);
  const [loading, setLoading] = useState(false);
  const onFinish1 = () => {
    setTabs(2);
  };
  const onFinish = async (values) => {
    console.log(values);

    if (selectedAddress == "") {
      toast.error("Please Add Location", { position: "bottom-left" });
      return;
    }
    console.log(values);
    setLoading(true);
    const payload = {
      employement_number: EmployeeDetails.employement_number,
      client_name: EmployeeDetails.ClientName,
      location: selectedAddress,
      description: EmployeeDetails.description,
      date: extractDateString(EmployeeDetails.date),
      from_meeting_time: extractTimeString(EmployeeDetails.startTime),
      to_meeting_time: extractTimeString(EmployeeDetails.endTime),
      longitude: markerPosition.lng,
      latitude: markerPosition.lat,
    };

    console.log(payload);
    const apiUrl = `tracking/employee/employee-meeting/add`;

    try {
      const res = await request({
        method: "post",
        url: apiUrl,
        data: payload,
      });
      console.log(res);
      setLoading(false);
      toast.success(res.message, { position: "bottom-left" });
      let reporting_manager = JSON.parse(
        localStorage.getItem("Is_Reporting_Mannger")
      );
      console.log(reporting_manager);
      if (MeetingType == "teammeeting") {
        navigate("/tracking/3");
      } else {
        if (reporting_manager) {
          navigate("/tracking/4");
        } else {
          navigate("/tracking");
        }
      }

      // console.log(res.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }

    // Navigation on success
  };

  const handleFormChange = (changedValues, allValues) => {
    console.log(allValues);

    setEmployeeDetails(allValues);
  };

  const extractDateString = (obj) => {
    // Extract year, month, and day from the object
    const year = obj.$y;
    const month = obj.$M + 1; // Months are 0-based in JavaScript, so add 1
    const day = obj.$D;

    // Format the month and day to ensure they are always two digits
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const extractTimeString = (obj) => {
    // Extract hours and minutes from the object
    const hours = obj.$H;
    const minutes = obj.$m;

    // Format hours and minutes to ensure they are always two digits
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}`;
  };
  const searchEmployeefunction = async (data) => {
    try {
      const response = await request({
        method: "get",
        url: `leave/search/employees/?employee_name=${data}&type=admin`,
      });
      console.log(response);
      setSearchEmployeeList(response);
    } catch (err) {
      console.log(err);
    }
  };

  // console.log('selected address', selectedAddress, markerPosition);
  const onPlaceChanged = () => {
    const autocomplete = autocompleteRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setMarkerPosition({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setSelectedAddress(place.formatted_address);
      }
    }
  };
  return (
    <>
      {screenSize.width < 768 ? (
        <>
          {tabs == 1 && (
            <>
              <div style={{ fontSize: "20px", fontWeight: "500" }}>
                Add Meeting
              </div>
              <div style={{ margin: "20px 0px" }}>
                <Form
                  onFinish={onFinish1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  layout="vertical"
                  autoComplete="true"
                  onValuesChange={handleFormChange}
                  form={form}
                >
                  {MeetingType == "teammeeting" && (
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Employee Name"
                          name="employement_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Designation",
                            },
                          ]}
                        >
                          <Select
                            className="dropDown"
                            showSearch
                            size="large"
                            onSearch={searchEmployeefunction}
                            filterOption={false} // To prevent default filtering
                            style={{ width: "100%" }}
                            placeholder="Select Employee"
                          >
                            <Select.Option value="" disabled>
                              <p style={{ color: "gray", margin: "0px" }}>
                                Search or Select Employee
                              </p>
                            </Select.Option>
                            {searchEmployeeList?.map((item, index) => (
                              <Select.Option
                                key={index}
                                value={item.employement_number}
                              >
                                {item.employee_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#161616",
                            }}
                          >
                            Client Name
                          </span>
                        }
                        name="ClientName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Client Name !",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Please enter Client Name"
                          value={EmployeeDetails.ClientName}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#161616",
                            }}
                          >
                            Description
                          </span>
                        }
                        name="description"
                        rules={[
                          { required: true, message: "Enter Description!" },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="maxLength is 100"
                          maxLength={100}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#161616",
                            }}
                          >
                            Date
                          </span>
                        }
                        name="date"
                        rules={[
                          { required: true, message: "Please enter date!" },
                        ]}
                      >
                        <DatePicker size="large" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {/* Start Time */}
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#161616",
                            }}
                          >
                            Start Time
                          </span>
                        }
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: "Please select Start Time!",
                          },
                        ]}
                      >
                        <TimePicker size="large" format={format} />
                      </Form.Item>
                    </Col>

                    {/* End Time */}
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#161616",
                            }}
                          >
                            End Time
                          </span>
                        }
                        name="endTime"
                        rules={[
                          {
                            required: true,
                            message: "Please select End Time!",
                          },
                        ]}
                      >
                        <TimePicker
                          // defaultValue={dayjs('12:00', format)}
                          format={format}
                          size="large"
                          disabled={EmployeeDetails.startTime ? false : true}
                          disabledTime={(current) => {
                            const currentHour =
                              parseInt(EmployeeDetails?.startTime.$H) + 1;

                            return {
                              disabledHours: () => [
                                ...Array(currentHour).keys(),
                              ],
                              // disabledMinutes: () => [...Array(60).keys()],
                              // disabledSeconds: () => [...Array(60).keys()],
                            };
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button className="button" htmlType="submit">
                          Add Location
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </>
          )}
          {tabs == 2 && (
            <>
              <div style={{ fontSize: "20px", fontWeight: "500" }}>
                Add Location
              </div>
              <div
                style={{
                  margin: "20px 0px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Row>
                  <Col xs={{ span: 24 }}>
                    <LoadScript
                      googleMapsApiKey={
                        process.env.REACT_APP_GOOGLE_MAP_API_KEY
                      }
                      libraries={["places"]}
                    >
                      <div style={{ position: "relative" }}>
                        <Autocomplete
                          onLoad={(autocomplete) => {
                            autocompleteRef.current = autocomplete;
                          }}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <Input
                            type="text"
                            placeholder="Search for an address"
                            style={inputStyle}
                            value={selectedAddress}
                            onChange={(e) => {
                              setSelectedAddress(e.target.value);
                            }}
                          />
                        </Autocomplete>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={markerPosition}
                          zoom={15}
                        >
                          <Marker
                            position={markerPosition}
                            Icon={mapLocation}
                          />
                        </GoogleMap>
                      </div>
                    </LoadScript>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Button className="button" onClick={onFinish}>
                      {loading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined spin style={{ color: "white" }} />
                          }
                        />
                      ) : (
                        "Done"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </>
      ) : (
        <div style={{ padding: "50px", background: "white" }}>
          <div>
            <Form
              onFinish={onFinish}
              layout="vertical"
              autoComplete="true"
              onValuesChange={handleFormChange}
              form={form}
            >
              {MeetingType == "teammeeting" && (
                <Form.Item
                  label="Employee Name"
                  name="employement_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Designation",
                    },
                  ]}
                >
                  <Select
                    className="dropDown"
                    showSearch
                    size="large"
                    onSearch={searchEmployeefunction}
                    filterOption={false} // To prevent default filtering
                    style={{ width: "100%" }}
                    placeholder="Select Employee"
                  >
                    <Select.Option value="" disabled>
                      <p style={{ color: "gray", margin: "0px" }}>
                        Search or Select Employee
                      </p>
                    </Select.Option>
                    {searchEmployeeList?.map((item, index) => (
                      <Select.Option
                        key={index}
                        value={item.employement_number}
                      >
                        {item.employee_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                // <Form.Item
                //   label="Employee Name"
                //   name="employeeName"
                //   rules={[
                //     {
                //       required: true,
                //       message: 'Please enter employee number!',
                //     },
                //   ]}>
                //   <div className="searchContainer">
                //     <Input
                //       size="large"
                //       className="searchInput"
                //       value={searchEmployeeDetails.name}
                //       type="text"
                //       placeholder="Enter Employee Name"
                //       onChange={e => searchEmployeefunction(e.target.value)}
                //     />
                //     {searchEmployeeList.length > 0 && (
                //       <ul>
                //         {searchEmployeeList.map((item, index) => (
                //           <li
                //             key={index}
                //             onClick={() =>
                //               handleSelectChangeEmployeeName(
                //                 item.employement_number,
                //                 item.employee_name,
                //               )
                //             }>
                //             {item.employee_name}
                //           </li>
                //         ))}
                //       </ul>
                //     )}
                //   </div>
                // </Form.Item>
              )}

              <Row gutter={[32, 32]}>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        Client Name
                      </span>
                    }
                    name="ClientName"
                    rules={[
                      { required: true, message: "Please enter Client Name !" },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Please enter Client Name"
                      value={EmployeeDetails.ClientName}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: "Enter Description!" }]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="maxLength is 100"
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[32, 32]}>
                <Col>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        Date
                      </span>
                    }
                    name="date"
                    rules={[{ required: true, message: "Please enter date!" }]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>

                {/* Start Time */}
                <Col>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        Start Time
                      </span>
                    }
                    name="startTime"
                    rules={[
                      { required: true, message: "Please select Start Time!" },
                    ]}
                  >
                    <TimePicker format={format} />
                  </Form.Item>
                </Col>

                {/* End Time */}
                <Col>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        End Time
                      </span>
                    }
                    name="endTime"
                    rules={[
                      { required: true, message: "Please select End Time!" },
                    ]}
                  >
                    <TimePicker
                      // defaultValue={dayjs('12:00', format)}
                      format={format}
                      disabled={EmployeeDetails.startTime ? false : true}
                      disabledTime={(current) => {
                        const currentHour =
                          parseInt(EmployeeDetails?.startTime.$H) + 1;

                        return {
                          disabledHours: () => [...Array(currentHour).keys()],
                          // disabledMinutes: () => [...Array(60).keys()],
                          // disabledSeconds: () => [...Array(60).keys()],
                        };
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        Add location
                      </span>
                    }
                    name="ClientName"
                    rules={[
                      { required: true, message: "Please enter Location !" },
                    ]}
                  >
                    <LoadScript
                      googleMapsApiKey={
                        process.env.REACT_APP_GOOGLE_MAP_API_KEY
                      }
                      libraries={["places"]}
                    >
                      <div style={{ position: "relative" }}>
                        <Autocomplete
                          onLoad={(autocomplete) => {
                            autocompleteRef.current = autocomplete;
                          }}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <Input
                            type="text"
                            placeholder="Search for an address"
                            style={inputStyle}
                            value={selectedAddress}
                            onChange={(e) => {
                              setSelectedAddress(e.target.value);
                            }}
                          />
                        </Autocomplete>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={markerPosition}
                          zoom={15}
                        >
                          <Marker
                            position={markerPosition}
                            Icon={mapLocation}
                          />
                        </GoogleMap>
                      </div>
                    </LoadScript>
                  </Form.Item>
                </Col>
              </Row>

              <div className="btncontainer">
                <Button className="button" htmlType="submit">
                  {loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined spin style={{ color: "white" }} />
                      }
                    />
                  ) : (
                    "Done"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default MeetingForm;
