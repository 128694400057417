import React, { useEffect, useState, useRef } from 'react'
import request from '../../../../utils/axios'
import { Button, Col, DatePicker, Form, Input, Modal, Row, Table } from 'antd'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import mobileStyle from "../../Admin/AdvanceSalaryFullView/MobileAdvanceSalaryFullView.module.scss"
import getScreenSize from "../../../../utils/getScreenSize";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import add from "../../../../assets/Org/add.svg"
import { toast } from "react-toastify";
import styles from "./AdvanceSalaryFullView.module.scss"

function AdvanceSalaryFullView({ tabsValue }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const emoloyeeAdvanceSalaryColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Issue Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
    ]
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const [allEmployeesAdvanceSummary, setAllEmployeesAdvanceSummary] = React.useState()
    const [employeeAdvanceSalary, setEmployeeAdvanceSalary] = useState(false)
    const fetchemployeesAdvanceSalaryHistory = async (page, pageSize) => {
        try {
            let data = await request({
                method: "get",
                url: `loan/admin/get-all-employee-advance-history?page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            console.log(data)
            setAllEmployeesAdvanceSummary([...data.pagination_data.data])
            setPagination({
                ...pagination,
                total: data?.pagination_data?.total
            })
            return data
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (tabsValue === "6") {
            fetchemployeesAdvanceSalaryHistory()
        }
    }, [pagination.current, tabsValue])
    // Advance Salary
    // #####################################################################

    const handleSelectChangeForAdvanceSalary = async (employement_number, name) => {
        try {
            setEmployeeAdvanceSalaryData({
                ...EmployeeAdvanceSalaryData,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const searchForAdvanceSalary = async (data) => {
        onHandleChangeForAdvance('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }


    const [EmployeeAdvanceSalaryData, setEmployeeAdvanceSalaryData] = useState({
        employement_number: "",
        name: "",
        amount: "",
        issue_date: "",
    })
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const onHandleChangeForAdvance = (key, value) => {
        setEmployeeAdvanceSalaryData({
            ...EmployeeAdvanceSalaryData,
            [key]: value
        });
    }

    const onFinishAdvanceSalary = async (values) => {
        const formData = new FormData();
        try {
            formData.append("employement_number", EmployeeAdvanceSalaryData.employement_number);
            formData.append("name", EmployeeAdvanceSalaryData.name);
            formData.append("amount", EmployeeAdvanceSalaryData.amount);
            formData.append("issue_date", EmployeeAdvanceSalaryData.issue_date);

            await request({
                method: "post",
                url: `loan/admin/create-employee-advance-salary`,
                data: formData
            })
            // Close the modal after the request is successful
            setEmployeeAdvanceSalary(false);
            fetchemployeesAdvanceSalaryHistory();
            toast.success("New advance salary added successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log("error")
            toast.error("Advance salary details could not be added, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const ref = useRef(null);
    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const renderUI = () => {
        return allEmployeesAdvanceSummary?.map((item, index) => {
            return (
                <div>
                    <div className={mobileStyle.card}>
                        <div className={mobileStyle.header}>
                            <p className={mobileStyle.name}>{item.employee_name}</p>
                        </div>
                        <div className={mobileStyle.box}>
                            <p className={mobileStyle.title}>Issue Date</p>
                            <p className={mobileStyle.value}>₹{item.issue_date}</p>
                        </div>
                        <div className={mobileStyle.box}>
                            <p className={mobileStyle.title}>Amount</p>
                            <p className={mobileStyle.value}>₹{item.amount}</p>
                        </div>
                    </div>
                    <hr />
                </div>
            )
        })
    }
    return (
        <>
            {
                screenSize.width < 768
                    ?
                    <div>


                        {/* Advance Salary */}
                        <div className={mobileStyle.advanceSalary}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading} style={{ marginBottom: "10px", }}>Advances</p>
                                {/* <div>
                                    <img
                                        onClick={() => navigate("/admin/payroll/advance-salary")}
                                        src={arrowCircle}
                                        alt="arrow"
                                        className="employeeArrowImage"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div> */}
                            </div>
                            <InfiniteScrollComponent
                                apiEndpoint={"loan/admin/get-all-employee-advance-history"}
                                renderUI={renderUI}
                                initialPage={1}
                            />
                            <div>
                                {

                                }
                            </div>
                        </div>

                    </div>
                    :
                    <div>

                        <div>
                            {/* <p className='heading'>Payroll</p>
                            <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                                <img src={back} alt="back" />
                                <span style={{ paddingLeft: "10px" }}>Back</span>
                            </p> */}

                            <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>
                                <div className='flexSpaceBetween'>
                                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Advance Salary</p>
                                    <div style={{ display: "flex", gap: "20px" }}>
                                        <img src={add} alt="add" width={35} onClick={() => setEmployeeAdvanceSalary(true)} />
                                    </div>
                                </div>
                                <CommonTable
                                    columns={emoloyeeAdvanceSalaryColumn}
                                    data={allEmployeesAdvanceSummary}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    handleRowClick={() => { }}
                                />
                                {/* <DynamicTable
                    fetchData={fetchemployeesAdvanceSalaryHistory}
                    columns={emoloyeeAdvanceSalaryColumn}
                    handleRowClick={() => { }}
                    pageSize={5}
                    showPagination={true}
                /> */}
                            </div>
                        </div>
                    </div>
            }
            {/* Advance Salary */}
            <Modal
                title="Advance Salary"
                centered
                open={employeeAdvanceSalary}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setEmployeeAdvanceSalary(false)}
                width={1000}
                footer={null}>
                <Form
                    layout="vertical"
                    onFinish={onFinishAdvanceSalary} >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employment Number"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={EmployeeAdvanceSalaryData.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForAdvanceSalary(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForAdvanceSalary(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleChangeForAdvance("amount", e.target.value)}
                                    value={EmployeeAdvanceSalaryData.amount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="issue_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleChangeForAdvance("issue_date", dateString)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>

        </>
    )
}

export default AdvanceSalaryFullView